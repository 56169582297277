import React, { useEffect, useState,useCallback } from 'react';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { useDispatch, useSelector } from 'react-redux';
import { getAllTeam, createTeam, updateTeam, teamDelete, getTeam } from "../../services/contactMemberService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, FormGroup } from "react-bootstrap";
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Select from "react-select";
import { dateSplit } from "../../common/commonfns";
import RightSidebar from "../../common/rightSidebar";
import Avatar from "react-avatar";
import { Label } from "reactstrap";
import BizTable  from "../../common/BizTable";
import {Row,Col} from 'reactstrap';
// import SearchInput from "../../common/searchInput";
import SearchInput from "../../common/searchComponent";
import { ErrorImage } from "../../common/ErrorMessage";
import axios from '../../utils/axios';

interface SelectBtnOption {
    value: string;
    label: string;
  }
  

const CreateGroup = () => {
    const [allTeam, setTeam] = useState([]);
    const [teamCounts,setTeamCount] = useState(0);
    const business = useSelector((state: any) => state.cartreducer.business);
    const businessId = useSelector(
      (state: any) => state.cartreducer.business?.business?.uid
    );
    const channel: any = useSelector((state: any) => state.cartreducer.channelUid);
    let members :any = useSelector ((state: any) => state.cartreducer.mappedMemberDataWithUid);
    let allmembers :any = useSelector ((state: any) => state.cartreducer.memberData);
    const channelData: any = useSelector((state: any) => state.cartreducer.channelData);
    const [open, openModal] = useState<boolean>(false);
    const [sideBar, setSideBar] = useState<boolean>(false);
    const [rowData, setRowData] = useState<any>([]);
    const [memberdata , setMemberData] =  useState<any>([]);
    const [selectedRows, setSelectedRows] = useState<any>([]);
    const [channelList, setChannel] = useState<any>([]);
    const [teamCount, setTeamCnt] = useState(0);
    const [teamEditCount, setTeamEdit] = useState(0);
    const [descCount, setDesc] = useState(0);
    const [descEditCount, setEditDesc] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [teamId, setTeamId] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState<string | null>();
    const dispatch = useDispatch();
    const idNameMap: Map<number, string> = new Map();
    const idNameMapWithUid: Map<number, string> = new Map();

    // const getAll = async () => {
    //     setIsLoading(true);
    //     const payload ={
    //         businessUid: business?.business?.uid,
    //         page:0,
    //         limit:100,
    //     }
    //     try {
    //         const response = await getAllTeam(payload);
    //         const data = response.data;
    //         const {count} = response;
    //         setTeam(data);
    //         setTeamCount(count);
    //         setIsLoading(false);
    //     } catch (error) {
    //         console.error('Error:', error);
    //         setIsLoading(false);
    //     }
    // }
    const selectedTableData = useSelector(
      (state: any) => state.tableReducer.selectedUids
    );
    const updateMembers = () => {
      const MemberList = {
        // params: {
        businessUid: businessId,
        limit: 1000,
        page: 0,
        status: ['ACTIVE', 'PENDING']
        // }
      };
      axios.post('member/getMembers', MemberList)
        .then((res) => {
          if (businessId) {
            const memberData = res?.data?.dataDTO;
            dispatch({ type: 'MEMBER_DATA', payload: memberData });
            memberData.forEach((item: any) => {
              idNameMap.set(item.id, item.name);
            });
            memberData.forEach((item: any) => {
              idNameMapWithUid.set(item.uid, item.name);
            })
            dispatch({ type: 'MAPPED_MEMBER_DATA', payload: idNameMap });
            dispatch({ type: "MAPPED_MEMBER_DATA_WITH_UID", payload: idNameMapWithUid });
          }
        })
  
    }
  
    useEffect(() => {
      updateMembers();
    }, []);

  const fetchData = useCallback(async (
      pageIndex: number,
      pageSize: number,
      searchText: string,
      currentPage: number
    ): Promise<{ data: any; totalPages: number }> => {
    
      const listPayload = {
        businessUid: businessId,
        page:  currentPage,
        limit: pageSize,
        search: searchTerm
      };
  
      const response = await getAllTeam(listPayload);
      if(response){
        const data = response.data;
        const {count} = response;
        setTeam(data);
        const countData = searchTerm ? data?.length : count;
        setTeamCount(countData);
        setIsLoading(false);
        return {data:data,totalPages:countData}
      }
      else{
        return {data:[],totalPages:0}
      }
    }, [businessId,searchTerm]);
   
    
 const  columns = [
  {
    Header: (
      <input
        type="checkbox"
        hidden={true}
        // checked={this.state.allChecked}
        // onChange={this.toggleAllCheckboxes}
      />
    ),
    accessor: "checkbox",
    // Cell: this.checkboxCellRenderer,
    sticky: "left",
  },
    {
      Header: "Name",
      accessor: "name",
      id: "teamName",
      sticky: "left",
      Cell: ({ cell, row }: any) => cell.value? <p title={cell.value}>{cell.value?.length > 30? cell.value?.slice(0,20)+ '...' : cell.value}</p> : '',
      width: '200',
    },
    {
      Header: "Created Date",
      accessor: "createdAt",
      Cell: ({ cell, row }: any) => dateSplit(cell.value),
      width:'30',
    },
    {
      Header: "No: of Members",
      accessor: "memberCount",
      // Cell: ({ cell, row }: any) => <div>{cell.value.length}</div>,
    },
    {
      Header: "Actions",
      accessor: "edit",
      Cell: ({ cell, row }: any) => renderEditColumn(cell.value, row.original),
    }
  ] as any;

  const memberColumn = [
    {
      id: "selection",
      Header: ({
        getToggleAllRowsSelectedProps,
      }: {
        getToggleAllRowsSelectedProps: any;
      }) => (
        <div>
          <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
        </div>
      ),
      Cell: ({ row }: { row: any }) => (
        <div>
          <input type="checkbox" {...row.getToggleRowSelectedProps()} />
        </div>
      ),
      accessor: "checkbox",
    },
    {
      Header: "Name",
      accessor: "name"
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ cell, row }: any) => <span title={cell.value}>{cell.value?.length > 25? cell.value?.slice(0,24) + '...': cell.value}</span>,
    },
  ] as any

    useEffect(() => {
        setTeamCnt(0);
        setTeamEdit(0);
        setDesc(0);
        setEditDesc(0);
        // getAll();
    }, []);

    // const getSingleTeam = async(uid: any) =>{
    //     try {
    //         const response = await getTeam(uid);
    //         const data = response;
    //         setTeamCnt(0);
    //         setTeamEdit(data?.name?.length);
    //         setDesc(0);
    //         setEditDesc(data?.description?.length);
    //         setRowData(data);
    //         setMemberData(data?.members)
    //     } catch (error) {
    //         console.error('Error:', error);
    //     }
    // }

    const getSingleTeam = useCallback(async (pageIndex: number, pageSize: number, searchText: string, currentPage: number) => {
      try {
        const response = await getTeam(teamId);
        const data = response; // Data should be an array of member objects
        const totalPages = Math.ceil(data?.members?.length / pageSize); // Calculate total pages
        setTeamCnt(0);
        setTeamEdit(data?.name?.length);
        setDesc(0);
        setEditDesc(data?.description?.length);
        setRowData(data);
        setMemberData(data?.members)
        return {
          data: data?.members, // Member data to display in the table
          totalPages: totalPages, // Total number of pages
        };
      } catch (error) {
        console.error('Error fetching team data:', error);
        return {
          data: [], // Return empty data in case of error
          totalPages: 0,
        };
      }
    }, [teamId]);

    const initiateGroup = async (values: any) => {
        const memberselected = values.selectedMember.map((item: any ) => item.value);
        const payload = {
            businessUid: business?.business.uid,
            name: values.name,
            description: values.description,
            channelsUid: values.channels.map((item:any) => item.value),
            membersUid: memberselected,            
        }
        try {
            await createTeam(payload);
            fetchData(0,20,'',0)
            openModal(false);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const closeModal = () => {
        openModal(false);
        setSideBar(false);

    }

    const validation = Yup.object({
        name: Yup.string().trim().required('Name is required').max(60, "Team name must not exceed 60 characters"),
        description: Yup.string().max(60, "Description must not exceed 60 characters"),
        selectedMember: Yup.array().min(1, 'At least one member must be selected'),
        channels: Yup.array().min(1, 'At least one channel must be selected'),

    });

    const validationEdit = Yup.object({
        teamName: Yup.string().trim().required('Name is required').max(60, "Team name must not exceed 60 characters"),
        description: Yup.string().max(60, "Description must not exceed 60 characters"),
        channels: Yup.array().min(1, 'At least one channel must be selected'),
  });

    const deleteTeam = async(uid: any) => {
        try {
             await teamDelete(uid);
            // getAll();
            fetchData(0,20,'',0)
        } catch (error) {
            console.error('Error:', error);
        }
    }
  //  const renderEditColumn = (cell: any, rowData: any) => {
  //     return (
  //       <div className="d-flex justify-content-center ">
  //         <svg
  //           width="16"
  //           height="16"
  //           // className={`${
  //           //   (this.state.profileDetail?.uid === rowData?.uid &&
  //           //     rowData.role.name === "OWNER") ||
  //           //   (this.state.profileDetail?.business?.email === rowData.email &&
  //           //     rowData.role.name === "OWNER")
  //           //     ? "me-5 ms-1"
  //           //     : ""
  //           // }`}
  //           // onClick={() => this.handleClickEdit(cell, rowData)}
  //           viewBox="0 0 16 16"
  //           fill="none"
  //           xmlns="http://www.w3.org/2000/svg"
  //         >
  //           <path
  //             d="M7.5 3H3.22222C2.89807 3 2.58719 3.12877 2.35798 3.35798C2.12877 3.58719 2 3.89807 2 4.22222V12.7778C2 13.1019 2.12877 13.4128 2.35798 13.642C2.58719 13.8712 2.89807 14 3.22222 14H11.7778C12.1019 14 12.4128 13.8712 12.642 13.642C12.8712 13.4128 13 13.1019 13 12.7778V8.5"
  //             stroke="#666E7D"
  //             stroke-width="1.25"
  //             stroke-linecap="round"
  //             stroke-linejoin="round"
  //           />
  //           <path
  //             d="M11.9485 2.35199C12.1738 2.12661 12.4795 2 12.7982 2C13.117 2 13.4226 2.12661 13.648 2.35199C13.8734 2.57737 14 2.88304 14 3.20177C14 3.5205 13.8734 3.82617 13.648 4.05155L8.26608 9.43348L6 10L6.56652 7.73392L11.9485 2.35199Z"
  //             stroke="#666E7D"
  //             stroke-width="1.25"
  //             stroke-linecap="round"
  //             stroke-linejoin="round"
  //           />
  //         </svg>
  //         <svg
  //             width="16"
  //             height="16"
  //             className="ms-4"
  //             // onClick={() => this.DeleteSelect(cell, rowData)}
  //             viewBox="0 0 16 16"
  //             fill="none"
  //             xmlns="http://www.w3.org/2000/svg"
  //           >
  //             <path
  //               d="M2 4H3.33333H14"
  //               stroke="#666E7D"
  //               stroke-width="1.25"
  //               stroke-linecap="round"
  //               stroke-linejoin="round"
  //             />
  //             <path
  //               d="M12.6673 4.00016V13.3335C12.6673 13.6871 12.5268 14.0263 12.2768 14.2763C12.0267 14.5264 11.6876 14.6668 11.334 14.6668H4.66732C4.3137 14.6668 3.97456 14.5264 3.72451 14.2763C3.47446 14.0263 3.33398 13.6871 3.33398 13.3335V4.00016M5.33398 4.00016V2.66683C5.33398 2.31321 5.47446 1.97407 5.72451 1.72402C5.97456 1.47397 6.3137 1.3335 6.66732 1.3335H9.33398C9.68761 1.3335 10.0267 1.47397 10.2768 1.72402C10.5268 1.97407 10.6673 2.31321 10.6673 2.66683V4.00016"
  //               stroke="#666E7D"
  //               stroke-width="1.25"
  //               stroke-linecap="round"
  //               stroke-linejoin="round"
  //             />
  //             <path
  //               d="M6.66602 7.3335V11.3335"
  //               stroke="#666E7D"
  //               stroke-width="1.25"
  //               stroke-linecap="round"
  //               stroke-linejoin="round"
  //             />
  //             <path
  //               d="M9.33398 7.3335V11.3335"
  //               stroke="#666E7D"
  //               stroke-width="1.25"
  //               stroke-linecap="round"
  //               stroke-linejoin="round"
  //             />
  //           </svg>
  //         {/* {(this.state.profileDetail?.uid === rowData?.uid &&
  //           rowData.role.name === "OWNER") ||
  //         (this.state.profileDetail?.business?.email === rowData.email &&
  //           rowData.role.name === "OWNER") ? (
  //           ""
  //         ) */}
  //          {/* : (
           
  //         )} */}
  //       </div>
  //     );
  //   };
    const renderEditColumn = (cell: any, row: any) => {
        return (
          <div className='d-flex justify-content-center'>
              <svg
               onClick={
                () => {
                  setRowData(row);
                  setTeamId(row?.uid)
                  // getSingleTeam(0,20,'',0);
                  setSideBar(true);
                  }}
              className='me-3'
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 3H3.22222C2.89807 3 2.58719 3.12877 2.35798 3.35798C2.12877 3.58719 2 3.89807 2 4.22222V12.7778C2 13.1019 2.12877 13.4128 2.35798 13.642C2.58719 13.8712 2.89807 14 3.22222 14H11.7778C12.1019 14 12.4128 13.8712 12.642 13.642C12.8712 13.4128 13 13.1019 13 12.7778V8.5"
                stroke="#666E7D"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.9485 2.35199C12.1738 2.12661 12.4795 2 12.7982 2C13.117 2 13.4226 2.12661 13.648 2.35199C13.8734 2.57737 14 2.88304 14 3.20177C14 3.5205 13.8734 3.82617 13.648 4.05155L8.26608 9.43348L6 10L6.56652 7.73392L11.9485 2.35199Z"
                stroke="#666E7D"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
                <svg
                 onClick={
                  () => {
                      setRowData(row);
                      setTeamId(row?.uid)
                      deleteTeam(row.uid);
                  }} className=""
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                    d="M2 4H3.33333H14"
                    stroke="#666E7D"
                    stroke-width="1.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    />
                    <path
                    d="M12.6673 4.00016V13.3335C12.6673 13.6871 12.5268 14.0263 12.2768 14.2763C12.0267 14.5264 11.6876 14.6668 11.334 14.6668H4.66732C4.3137 14.6668 3.97456 14.5264 3.72451 14.2763C3.47446 14.0263 3.33398 13.6871 3.33398 13.3335V4.00016M5.33398 4.00016V2.66683C5.33398 2.31321 5.47446 1.97407 5.72451 1.72402C5.97456 1.47397 6.3137 1.3335 6.66732 1.3335H9.33398C9.68761 1.3335 10.0267 1.47397 10.2768 1.72402C10.5268 1.97407 10.6673 2.31321 10.6673 2.66683V4.00016"
                    stroke="#666E7D"
                    stroke-width="1.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    />
                    <path
                    d="M6.66602 7.3335V11.3335"
                    stroke="#666E7D"
                    stroke-width="1.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    />
                    <path
                    d="M9.33398 7.3335V11.3335"
                    stroke="#666E7D"
                    stroke-width="1.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    />
                </svg>
            </div>       
        );
      };

      const handleCancel = () => {
        setSideBar(false);
        openModal(false);
        setSelectedRows([]);
      }

      const  handleMemAdd = (data: any) => {
        setSelectedRows([]);
        const newData = [...memberdata, data]; 
        setMemberData(newData); 
      }

      const handleMemDelete = (data: any) =>{
        let unmatchedUids;
        unmatchedUids = memberdata?.filter((member:any) => !data?.TEAMS0?.some((row:any) => row.uid === member.uid));
        setMemberData(unmatchedUids);  
        setSelectedRows([]);
    }

      const CustomOption = ({
        innerProps,
        innerRef,
        data,
        isSelected,
        isFocused,
      }: any) => (
        <div
          ref={innerRef}
          {...innerProps}
          className={`custom-option font ${isSelected ? "selected" : ""} ${isFocused ? "focused" : ""
            }`}
            onClick={() => {
                if (!memberdata?.some((member: any) => member?.uid === data?.uid) && !open) {
                  handleMemAdd(data);
                }
              }}
        >
          <span className="fontWeight">
            <Avatar
              fgColor="#4c5a80"
              name={data.label}
              round
              size="23"
              textSizeRatio={2.5}
            />
          </span>
          <span className={`ml-1 large text-muted`}>{data.label}</span>
          { !memberdata?.some((member: any) => member?.uid === data?.uid) && (
            !open && <span><button className='addBUttons float-right' onClick={() => handleMemAdd(data)}>Add</button></span>
            )}

            { memberdata?.some((member: any) => member?.uid === data?.uid) && (
            !open && <span><button className='addedBUttons float-right'>Added</button></span>
            )}
        </div>
    );

    const CustomOptions = ({
        innerProps,
        innerRef,
        data,
        isSelected,
        isFocused,
      }: any) => (
        <div
          ref={innerRef}
          {...innerProps}
          className={`custom-option font ${isSelected ? "selected" : ""} ${isFocused ? "focused" : ""
            }`}
        >
          <span className="fontWeight">
            <Avatar
              fgColor="#4c5a80"
              name={data.label}
              round
              size="23"
              textSizeRatio={2.5}
            />
          </span>
          <span className={`ml-1 large text-muted`}>{data.label}</span>
        </div>
    );
      
    const selectRowProp = {
        mode: 'checkbox',
        onSelect: (row: any, isSelected: any) => {
            if (isSelected) {
                setSelectedRows([...selectedRows, row]);
            } else {
                setSelectedRows(selectedRows.filter((r:any) => r.id !== row.id));
            }
        },
        onSelectAll: (isSelected:any, rows:any) => {
            if (isSelected) {
                setSelectedRows(rows);
            } else {
                setSelectedRows([]);
            }
        },
        bgColor: 'rgb(242, 255, 163)',
        width: '10px',
    };

    const handleUpdate = async() => {
        // let unmatchedUids = [];
        // let membersUids= [];
        // if(method === 'delete'){
        //     unmatchedUids = rowData?.members?.filter((member:any) => !selectedRows.some((row:any) => row.uid === member.uid));
        //     membersUids = unmatchedUids.map((item:any) => item.uid)
        // }else if(method === 'add'){
        //     let memberArray: { uid: string, label: string }[] = [];
        //     members.forEach((label: any, value: string) => {
        //         memberArray.push({ uid: value, label });
        //     });
        //     unmatchedUids = memberArray?.filter((member:any) => rowData?.members?.some((row:any) => row.uid === member.uid));
        //     membersUids = unmatchedUids.map((item:any) => item.uid)
        
        //     if (addUid && addUid.trim() !== '') {
        //         membersUids.push(addUid);
        //     }
        // }

        const payload = {
            businessUid: business?.business.uid,
            name: rowData.name,
            description: rowData.description,
            channelsUid: channelList? channelList.map((item:any) => item.value) : rowData?.channel?.map((item:any) => item.value),
            membersUid: memberdata?.map((item:any) => item.uid), 
        } 
        try {
            await updateTeam(rowData.uid, payload);            
            getSingleTeam(0,20,'',0);
            // getAll();
            fetchData(0,20,'',0)
        } catch (error) {
            console.error('Error:', error);
        }
        handleCancel();
    };

    const handleForm = (values: any, { setSubmitting }: any) => {
        rowData.name = values?.teamName;
        rowData.description = values?.description;
        handleUpdate();
        setSubmitting(false);
    };

    const onSearchChange = (searchTerm: string) => {
      const searchTermCheck = searchTerm.length > 0 ? searchTerm : null;
      setSearchTerm(searchTermCheck);
    };
  
    return (
        <>
        {isLoading ? (
            <div className="centerItemsWithWidth">
              <div className="spinner-border text-success" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
        <div>
            <div>
              <Row className='pt-3 pb-2'>
                <Col md={3}>
                <SearchInput
                      component="Team"
                      onSearchChange={onSearchChange}
                      placeHolder="Search Team"
                    />
                </Col>
                <Col></Col>
                <Col md={2}><Button
                    onClick={() => { openModal(true) 
                        setTeamCnt(0);
                        setTeamEdit(0);
                        setDesc(0);
                        setEditDesc(0);
                     }}
                    variant=""
                    type="button"
                    className="sendButton w-100"
                ><FontAwesomeIcon icon={faPlus} />  Add Team
                </Button>
                </Col>
              </Row>
                
            </div>
            
            {(sideBar || open) && (
                <RightSidebar handleCancel={handleCancel} title={open? 'Create Team' :'Edit Team'} width={open? "425px" : "555px"}>
                    {open?
                    <>
                      <Formik initialValues={{
                        name: '',
                        description: '',
                        selectedUser: [],
                        channels: [],
                        selectedMember: [],
                    }}
                        validationSchema={validation}
                        enableReinitialize={true}
                        onSubmit={initiateGroup}
                    >
                        {({ values, setFieldValue }) => (
                            <Form className='ml-3 mr-2'>
                                <FormGroup>
                                    <Label>Team Name<span className='required'></span></Label>
                                    <Field type='text' id='name' className='form-control' name='name' placeholder='Enter team name'
                                     onChange={(event: any) => {
                                        let text = event.target.value;
                                        setFieldValue('name', text)
                                        setTeamCnt(text.length);
                                      }} />
                                    <div className='float-right text-muted small'>{teamCount} / 60</div>
                                    <ErrorMessage name='name' className='errorMsg' component='div'></ErrorMessage>
                                </FormGroup>
                                <FormGroup>
                                    <Label className='mt-3'>Description</Label>
                                    <Field type='text' id='description' className='form-control' name='description' placeholder='Enter description' 
                                    onChange={(event: any) => {
                                        let text = event.target.value;
                                        setFieldValue('description', text)
                                        setDesc(text.length);
                                      }}/>
                                      <div className='float-right text-muted small'>{descCount} / 60</div>
                                    <ErrorMessage name='description' className='errorMsg' component='div'></ErrorMessage>
                                </FormGroup>
                                <FormGroup>
                                <Label className="mt-3">Select Members<span className='required'></span></Label>
                                <Field name="selectedMember" as="select" multiple>
                                {({ field, form }: any) => (
                                  <Select
                                    {...field}
                                    isMulti
                                    name="product"
                                    options={Array.from(members, ([value, label]) => ({ value, label }))}
                                    onChange={(selectedOptions: SelectBtnOption[] | null) => {
                                      form.setFieldValue("selectedMember", selectedOptions);
                                    }}
                                    components={{ Option: CustomOptions }}
                                    styles={{
                                        menu: (provided) => ({
                                            ...provided,
                                            width: '400px', 
                                          }),
                                      }}
                                  />
                                )}
                               </Field>
                               <ErrorMessage name='selectedMember' className='errorMsg' component='div'></ErrorMessage>
                                </FormGroup>
                                <FormGroup>
                                <Label className="mt-3">Select Channel<span className='required'></span></Label>
                                <Field name="channels">
                                {({ field, form }: any) => (
                                  <Select
                                    {...field}
                                    isMulti
                                    closeMenuOnSelect={true}
                                    name="channels"
                                    options={channelData.map((channel: any) => ({
                                      value: channel.uid,
                                      label: channel.name,
                                    }))}
                                    onChange={(selectedOptions: SelectBtnOption[] | null) => {
                                        form.setFieldValue("channels", selectedOptions);
                                      }}
                                      styles={{
                                        menu: (provided) => ({
                                            ...provided,
                                            width: '400px', 
                                          }),
                                      }}
                                  />
                                )}
                              </Field>
                              <ErrorMessage name='channels' className='errorMsg' component='div'></ErrorMessage> 
                                </FormGroup>
                                <div className='p-2 float-right mt-4'>
                                <Button type='button' onClick={closeModal} className='cancelButton'>Cancel</Button>
                                <Button type='submit' className='sendButton ml-2'>Create</Button>
                                </div>
                               
                            </Form>
                        )}
                    </Formik>
                    </>
                    :
                    <div className='ml-4'>
                        <Formik initialValues={{
                        teamName: rowData?.name? rowData?.name : '',
                        description: rowData?.description? rowData?.description : '',
                        channels:  rowData?.channels? rowData?.channels?.map((item: any) => ({ label: item.name, value: item.uid })) :'',
                        }}
                        validationSchema={validationEdit}
                        enableReinitialize={true}
                        onSubmit={handleForm}
                        >
                        {({ values, setFieldValue, isSubmitting }) => (
                         <Form className='mb-3 mr-2'>
                             <FormGroup>
                                <Label>Team Name<span className='required'></span></Label>
                                <Field type='text' id='teamName' className='form-control' style={{width: '350px'}} name='teamName' placeholder='Enter team name' 
                                  onChange={(event: any) => {
                                    let text = event.target.value;
                                    setFieldValue('teamName', text)
                                    setTeamEdit(text.length);
                                  }}/>
                                 <div className='margincount text-muted small'>{teamEditCount} / 60</div>
                                <ErrorMessage name='teamName' className='errorMsg' component='div'></ErrorMessage>
                            </FormGroup>
                            <FormGroup>
                                    <Label className='mt-1'>Description</Label>
                                    <Field type='text' id='description' style={{width: '350px'}} className='form-control' name='description' placeholder='Enter description' 
                                    onChange={(event: any) => {
                                        let text = event.target.value;
                                        setFieldValue('description', text)
                                        setEditDesc(text.length);
                                      }}/>
                                      <div className='margincount text-muted small'>{descEditCount} / 60</div>
                                    <ErrorMessage name='description' className='errorMsg' component='div'></ErrorMessage>
                                </FormGroup>
                            <FormGroup>
                                <Label className='mt-2'>Channel<span className='required'></span></Label>
                                <Field name="channels">
                                {({ field, form }: any) => (
                                    <Select
                                    {...field}
                                    isMulti
                                    closeMenuOnSelect={true}
                                    name="channels"
                                    options={channelData?.map((channel: any) => ({
                                        value: channel.uid,
                                        label: channel.name,
                                  }))}
                                    onChange={(channel: any) => {
                                        setFieldValue("channels", channel);
                                        setChannel(channel);
                                      }}
                                    styles={{
                                        menu: (provided) => ({
                                        ...provided,
                                        width: '350px', 
                                        }),
                                        control: (provided) => ({
                                           ...provided,
                                           width: '350px',
                                       }),
                                        }}
                                    />
                                )}
                                </Field>
                                <ErrorMessage name='channels' className='errorMsg' component='div'></ErrorMessage>
                            
                            </FormGroup>
                            <div>
                    <div className='mt-4'>
                    <Label>Add Member</Label>
                    <div className='d-flex'>
                    <Select
                     name="product"
                     className='mb-2'
                     closeMenuOnSelect={true}
                     placeholder='Search and add member'
                     options={allmembers?.map((channel: any) => ({
                        uid: channel.uid,
                        label: channel.name,
                        email: channel.email,
                        name: channel.name,
                        id: channel.id
                  }))}
                    //  options={Array.from(members, ([value, label]) => ({ value, label }))}                    
                     components={{ Option: CustomOption }}
                     styles={{
                     menu: (provided) => ({
                     ...provided,
                     width: '350px', 
                     zIndex: 9999, 
                     }),
                     control: (provided) => ({
                        ...provided,
                        width: '350px',
                    }),
                     }}
                     value={null}
                     />
                    {selectedTableData?.TEAMS0?.length > 0? <button type='button' onClick={() => handleMemDelete(selectedTableData)} className='sendButton w-25  p-0 ms-4 mb-2'>Delete</button> : ''}
                    </div>
                     {memberdata.length <= 0 ? <p className='text-danger'>At least one member must be selected</p> : ''}

                    </div>
                        {/* <BootstrapTable
                            data={memberdata}
                            pagination={memberdata?.length > 10}
                            version="4"
                            striped
                            hover
                            containerStyle={{ textAlign: "left", marginTop: '10px' }}
                            selectRow={selectRowProp as any}
                            options={{
                                headerAlign: "center",
                                noDataText: "No records found",
                                sortIndicator: true,
                                hidePageListOnlyOnePage: true,
                                clearSearch: false,
                                alwaysShowAllBtns: true,
                                sizePerPageList: [10, 25, 50, 100, { text: "All", value: memberdata.length }],
                                withFirstAndLast: true,
                                sizePerPage: 10,
                                paginationPosition: "bottom",
                              } as any}
                        >
                            <TableHeaderColumn dataField="id" isKey hidden width='10'>
                                #
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="name"
                                dataAlign="left"
                                width="160"
                                dataFormat={(cell:any, row:any) => row?.name? row?.name : row?.label}
                                columnClassName="text-wrap"
                            >
                                Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="email"
                                dataAlign="left"
                                width="250"
                                columnClassName="text-wrap"
                            >
                            Email
                        </TableHeaderColumn>
                        </BootstrapTable>  */}
                        <BizTable
                      columns={memberColumn}
                      fetchData={getSingleTeam}
                      counts={0}
                      refetchData={memberdata}  
                      tableName='TEAMS'
                    /> 
                      </div>
                            <div className='p-2 float-right mt-4'>
                                <Button type='button' onClick={closeModal} className='cancelButton'>Cancel</Button>
                                <Button type='submit' className='sendButton ml-2' disabled={isSubmitting || memberdata.length <= 0}>Update</Button>
                                </div>
                         </Form>
                        )}
                        </Formik>
                      

                    </div>
                }
                </RightSidebar>
            )}
            {/* <BootstrapTable
                data={allTeam}
                search
                pagination={allTeam?.length > 10}
                version="4"
                striped
                hover
                containerStyle={{ textAlign: "left", marginTop: '4px' }}
                options={{
                    headerAlign: "center",
                    noDataText: "No records found",
                    sortIndicator: true,
                    hidePageListOnlyOnePage: true,
                    clearSearch: false,
                    alwaysShowAllBtns: true,
                    sizePerPageList: [10, 25, 50, 100, { text: "All", value: allTeam.length }],
                    withFirstAndLast: true,
                    sizePerPage: 10,
                    paginationPosition: "bottom",
                  } as any}
            >
                <TableHeaderColumn dataField="id" isKey hidden>
                    #
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="name"
                    dataAlign="left"
                    width="130"
                    columnClassName="text-wrap"
                >
                    Name
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="createdAt"
                    dataAlign="center"
                    width="80"
                    columnClassName="text-wrap"
                    dataFormat={dateSplit}
                >
                    Created Date
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="memberCount"
                    dataAlign="center"
                    width="200"
                    columnClassName="text-wrap"
                >
                   <div className="d-flex justify-content-center">No:&nbsp;<div style={{textTransform:'lowercase'}}>of</div>&nbsp;Members</div>
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="name"
                    dataAlign="left"
                    width="40"
                    columnClassName="text-wrap"
                    dataFormat={(cell:any, row:any) => renderEditColumn(cell,row)}
                >
                    Actions
                </TableHeaderColumn>
            </BootstrapTable> */}
            <BizTable
              columns={columns}
              fetchData={fetchData}
              counts={0}
              refetchData={allTeam}
              tableName='GROUPCREATE_2'
            /> 
            {/* {teamCounts > 0 && allTeam.length > 0 ? '' : <ErrorImage/>} */}
        </div>
        )}
        </>
    )

}

export default CreateGroup;
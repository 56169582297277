import React, { useEffect, useMemo, useState } from 'react';
import { IEditorProps } from '../types';
import { ErrorMessage, Formik } from 'formik';
import * as yup from 'yup';
import { ACTION_LIST, ActionsListItem, IIntegrationActionListId } from '../../../../entity/integration/actionList';
import { IIntegrationApp } from '../../../../entity/integration/integrationApp';
import { useElmentEditor } from '../hooks';
import EditorCaption from '../editorCaption';
import { openAiIcon } from '../../../../icons';
import { Button, Offcanvas, Form, FormControl, FormCheck } from 'react-bootstrap';
import IntegrationConnection from './integrationConnection';
import ActionsField, { StorageVariable } from '../../../../entity/integration/actionsField';
import { FormGroup, Label } from 'reactstrap';
import SelectIntegrationAction from './selectIntegrationAction';
import bg from '../catalogue/sendProducts.module.scss';
import AddVariable from '../addVariable';
import ReactSelect from 'react-select';
import VariableInputLabel from '../variableInputLabel';
import { SelectVariable } from '../setVariable';
import { getAllRAGFolder } from '../../../../../services/ragService';
import { useAppSelector } from '../../../../hooks';
import styles from "../integration/openAI.module.scss";


interface KeyValue {
    key: string,
    value: string
}

interface OpenaiData {
    model: string,
    maxTokens: string,
    temperature: string,
    systemContent: string,
    ragUid: string,
    userPrompt: string
}

interface FormData {
    integrationData: {
        app: IIntegrationApp,
        scope: 'COM' | 'IN'
        connectionId: number | null
        action: IIntegrationActionListId | null
        storageVariable: string
        openaiData: OpenaiData
    },
    isSameFlow: boolean,
    storageVariable: { name: string, type: 'TEXT' }
}



function OpenAI(props: IEditorProps) {

    const [isCreateScreen, setIsCreateScreen] = useState(false);
    const [isEditScreen, setEditScreen] = useState(true);
    const [isOrderBased, setIsOrderBased] = useState(true);
    const [folders, setFolders] = useState([]);
    const businessUid = useAppSelector((state) => state.meta.businessUid);
    const [formData, setFormData] = useState<FormData>({
        integrationData: {
            app: 'OpenAI',
            scope: 'COM',
            connectionId: null,
            action: null,
            storageVariable: '',
            openaiData: {
                model: '',
                maxTokens: '250',
                temperature: '0.8',
                systemContent: '',
                ragUid: '',
                userPrompt: ''
            }
        },
        isSameFlow: false,
        storageVariable: {
            name:"",
            type: "TEXT"
        }
    })
    const actionClenup = () => {
        (window as any).actionFields = null;
        (window as any).fieldsInit = false;
        (window as any).selectedModule = null;
        (window as any).selectedModuleId = null;
        // zohoModuleFieldsQuery.remove()
    }
    useEffect(() => {
        init(setFormData)
        return actionClenup
    }, [])

    const { init, saveElementChanges } = useElmentEditor({
        type: 'integration/OpenAI',
        data: formData
    }, props)

    const schema = yup.object().shape({
        integrationData: yup.object().shape({
            connectionId: yup.string().required('Connection is required'),
            action: yup.string().required('Action is required'),
            openaiData: yup.object().shape({
                systemContent: yup.string().required("Context is required"),
                userPrompt: yup.string().required("User Prompt is required"),
                model: yup.string().required("Model is required"),
                ragUid: yup.string().required("Knowledge Folder is requried"),
                maxTokens: yup.number().required("Max Tokens is required"),
                temperature: yup.number().required("Temperature is required")
            })

        }),
        storageVariable: yup.object().shape({
            name: yup.string().required("Variable is required")
        })
    });

    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >

            {({ handleSubmit, setFieldValue, values, errors, touched, setValues, handleChange }) => {

                const [code, setCode] = useState<string>('91');
                const onGoBackHandler = () => {
                    if (values.integrationData.action) {
                        setFieldValue('integrationData', {
                            app: 'OpenAI',
                            scope: 'COM',
                            connectionId: values?.integrationData?.connectionId,
                            openaiData: values?.integrationData?.openaiData
                        })
                        actionClenup();
                    } else if (values.integrationData.connectionId) {
                        setFieldValue('integrationData.connectionId', null)
                    } else {
                        props.onClose()
                    }
                }

                const myAction: ActionsListItem | null = useMemo(() => {
                    if (values.integrationData.connectionId) {
                        (window as any).appConnectionId = values.integrationData.connectionId
                    }
                    if ('action' in values.integrationData && values.integrationData.action) {
                        return ACTION_LIST['OpenAI'].find(action => action.id === values.integrationData.action) || null
                    }
                    return null
                }, [values])

                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues])

                const getAllRAG = async () => {
                    const body = {
                        businessUid: businessUid,
                        page: 0,
                        limit: 10
                    }
                    const allRAG = await getAllRAGFolder(body);
                    const folders = allRAG?.data
                    ?.filter((folder: any) => folder?.status === "COMPLETED") 
                    ?.map((folder: any) => ({
                      label: folder?.name,
                      value: folder?.uid,
                    }));
                  
                    setFolders(folders);
                }

                useEffect(() => {
                    getAllRAG();
                }, [])

                const mapFieldsHandler = (field: ActionsField) => {

                    const isError = Boolean(errors.integrationData
                        && field.id in (errors.integrationData as any)
                        && Boolean((errors.integrationData as any)[field.id]))
                    const isTouched = Boolean(touched.integrationData
                        && field.id in (touched.integrationData as any)
                        && Boolean((touched.integrationData as any)[field.id]))

                    return (
                        <>
                            {field.id === "model" || field.id === "ragUid" ? <>

                                <Form.Group className='mb-3'>
                                    <Form.Label>{field.label}{<span className='required'></span>}</Form.Label>
                                    <ReactSelect
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9800 }),
                                            menu: base => ({ ...base, width: 410 })
                                        }}
                                        options={field.id === "model" ? [{ label: "gpt-4o-mini", value: "gpt-4o-mini" }] : folders}
                                        className="w-100"
                                        placeholder={field.placeholder}
                                        onChange={(selected: any) => { 
                                            setFieldValue(`integrationData.openaiData.${field.id}`, selected.value)
                                        }}
                                        value={field.id === 'model' ?  values?.integrationData?.openaiData?.model ? {label: values?.integrationData?.openaiData?.model, value:  values?.integrationData?.openaiData?.model} : [] : folders?.find((folder: any)=> folder?.value === values?.integrationData?.openaiData?.ragUid)}
                                        maxMenuHeight={120}
                                    />
                                     <ErrorMessage name={`integrationData.openaiData.${field.id}`} component="div" className='invalid-feedback d-block' />
                                </Form.Group>
                            </> : field.id === "variable" ? <>
                                    <Label>Select or Create variable to store Open AI response<span className='required'></span></Label>
                                    <SelectVariable
                                        placeholder="Create or Select variable"
                                        name="storageVariable"
                                        value={values?.storageVariable?.name}
                                        onChange={handleChange}
                                        type="TEXT"
                                    />
                                    <ErrorMessage name={`storageVariable.name`} component="div" className='invalid-feedback d-block' />
                            </> :
                                <FormGroup className="mb-3">
                                    <Label>{field.label}{<span className='required'></span>}</Label>
                                    {field.id === "temperature" && <><br /><small className={styles.smallColor}> <i>Hints:</i> {"What sampling temperature to use, between 0 and 2. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic."} </small></>}
                                    <FormControl
                                        name={`integrationData.openaiData.${field.id}`}
                                        as='input'
                                        value={(values.integrationData.openaiData as any)[field.id] || ''}
                                        onChange={handleChange}
                                        placeholder={field.placeholder || ''}
                                        required={true}
                                    />
                                    <ErrorMessage name={`integrationData.openaiData.${field.id}`} component="div" className='invalid-feedback d-block' />
                                    <AddVariable static fieldName={'integrationData.openaiData.' + field.id} fieldValue={values.integrationData.openaiData ? (values.integrationData.openaiData as any)[field.id] : ''} limit={1024} />
                                </FormGroup>
                            }
                        </>
                    )
                }

                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption
                            onHide={onGoBackHandler}
                            caption="OpenAI (ChatGPT Token)"
                            icon={<img alt='' src={openAiIcon} style={{ width: 37 }} />}
                        />
                        <Offcanvas.Body>

                            {myAction ? <h5 className='mb-3'>{myAction.label}</h5> : null}
                            {values.integrationData.connectionId ? (
                                values.integrationData.action ? (
                                    <>
                                        {myAction ?
                                            <>
                                                {myAction.fields
                                                    .filter(field => field.required)
                                                    .map(mapFieldsHandler)}
                                                {myAction.fields
                                                    .filter(field => !field.required)
                                                    .length > 0 && (
                                                        <>
                                                            <FormCheck
                                                                name='isSameFlow'
                                                                type='switch'
                                                                id='fields-shopify-switch'
                                                                label="Optional Fields"
                                                                checked={values.isSameFlow}
                                                                onChange={event => {
                                                                    setFieldValue('isSameFlow', event.target.checked);
                                                                }}
                                                            />
                                                        </>
                                                    )
                                                }
                                                {values?.isSameFlow && myAction.fields
                                                    .filter(field => !field.required)
                                                    .length > 0 ? (
                                                    <div className={bg.background}>
                                                        {myAction.fields
                                                            .filter(field => !field.required)
                                                            .map(mapFieldsHandler)}
                                                    </div>
                                                ) : null}
                                            </> : null}
                                    </>
                                ) : <SelectIntegrationAction app='OpenAI' isOrder={isOrderBased} />) : (
                                <IntegrationConnection app='OpenAI' scope="COM" onScreenChange={(screen: any) => { setIsCreateScreen(screen === 'create'); setEditScreen(screen !== 'edit'); }} />
                            )}

                        </Offcanvas.Body>

                        {(isEditScreen && !isCreateScreen) && values?.integrationData?.connectionId && (
                            <div className='editor-footer'>
                                <Button variant='outline-dark' onClick={props.onClose}>
                                    Cancel
                                </Button>
                                <Button className='sendButton' type='submit' disabled={isCreateScreen}>
                                    Save
                                </Button>
                            </div>)}
                    </Form>
                )
            }}
        </Formik >
    )
}

export default OpenAI;
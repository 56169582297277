import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from '../../utils/axios';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import './conversation.scss';
import { getTextBasedOnSubType, getTextBasedOnnfmReplyMsg } from '../../common/commonfns';

interface ReplyMessage {
    messageType: string;
    textMessage?: {
        text: string;
    };
    media?: {
        mediaType?: string;
    };
    componentMessage?: {
        subType: string;
        messageName: string;
    };
    assignId: string;
    assignTo: number;
    status: number;
}

interface ReplyMessageComponentProps {
    replyMessageId: string;
    message: any;
    selectedConversation: any;
    allBots: any;
}

const ReplyMessageComponent: React.FC<ReplyMessageComponentProps> = ({ replyMessageId, message, selectedConversation,allBots }) => {
    const [repliedMessage, setRepliedMessage] = useState<ReplyMessage | null>(null);
    const mappedMemberData = useSelector((state: any) => state.cartreducer.mappedMemberData);
    const business = useSelector((state: any) => state.cartreducer.business);

    useEffect(() => {
        setRepliedMessage(message?.message);
    }, [replyMessageId]);


    return (
        <>
            {repliedMessage && (
                <div className={`${repliedMessage.status === -1 ? 'failedMsgBg' : 'replyMsgBg'}`}>
                    <div className='assign'>
                        {repliedMessage.assignId ?
                            repliedMessage.assignTo===1?allBots.get(repliedMessage.assignId):
                            repliedMessage.assignId === business?.id ? 'You' : mappedMemberData.get(repliedMessage.assignId) :
                            selectedConversation?.contact?.name}
                    </div>
                    {repliedMessage.messageType === 'text' || repliedMessage.messageType === 'interactive' ? (
                        <div className='pl-1'>{repliedMessage.textMessage?.text}</div>
                    ) : repliedMessage.messageType === 'media' ? (
                        <div>
                            {repliedMessage.media?.mediaType?.includes('image') ? (
                                <span>
                                    <img src={`${process.env.PUBLIC_URL}/images/mediaIcons/Camera.svg`} alt="Camera Icon" height={17} width={17} />
                                </span>
                            ) : repliedMessage.media?.mediaType?.includes('video') ? (
                                <span>
                                    <FontAwesomeIcon icon={faVideo} />
                                </span>
                            ) : (
                                <span>
                                    <img src={`${process.env.PUBLIC_URL}/images/mediaIcons/Doc.svg`} alt="Camera Icon" height={17} width={17} />
                                </span>
                            )}
                            <span className="pl-1">
                                {repliedMessage.media?.mediaType?.includes('image') ? 'Photo' : repliedMessage.media?.mediaType?.includes('video') ? 'Video' : 'Document'}
                            </span>
                        </div>
                    ) : repliedMessage.messageType === 'component' ? (
                        <div>
                            <span>
                                <img src={`${process.env.PUBLIC_URL}/images/mediaIcons/doc_fill.svg`} alt="Camera Icon" height={17} width={17} />
                            </span>
                            <span className="pl-1">
                                {repliedMessage.componentMessage?.subType !== 'nfm_reply' ?
                                    <>
                                        {getTextBasedOnSubType(repliedMessage.componentMessage?.subType)}
                                    </> :
                                    <>
                                        {getTextBasedOnnfmReplyMsg(repliedMessage.componentMessage?.messageName)}
                                    </>
                                }
                            </span>
                        </div>
                    ) : null}
                </div>
            )}
        </>
    );
};

export default ReplyMessageComponent;

// FacebookLogin.tsx

import React, { useEffect } from 'react';
import { connectFB } from '../services/catalogueService';
import { useSelector } from 'react-redux';

interface fbProps {
  freeTrial?: boolean;
}

export const FacebookLogin: React.FC<fbProps> = ({freeTrial}) => {
  const channel: any = useSelector((state: any) => state.cartreducer.channelUid);
// console.log("free trial", freeTrial)
  useEffect(() => {
    const loadFacebookSDK = () => {
      // Load Facebook SDK asynchronously
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: '642875634705410',
          autoLogAppEvents: true,
          xfbml: true,
          cookie: true,
          version: 'v19.0',
        });

        window.FB.getLoginStatus(function (response:any) {
          console.log('getLoginStatus', response);   
        });

        window.FB.getAuthResponse(function (response: any) {
          console.log('getAuthResponse', response);
        });

        window.FB.getUserID(function (response: any) {
          console.log('getUserID', response);
        });
      };

      // Initialize Facebook SDK
      (function (d, s, id) {
    
        var js: HTMLScriptElement,
        fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s) as HTMLScriptElement;
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs!.parentNode!.insertBefore(js, fjs);
    
        
      })(document, 'script', 'facebook-jssdk');
    };

    loadFacebookSDK();

    // Clean up
    return () => {
        window.addEventListener("message", sessionInfoListener as EventListener);
        window.addEventListener("wa_embedded_signup", sessionInfoListener as EventListener);
        window.addEventListener("finish", sessionInfoListener as EventListener);
        
    };
  }, []);

  const sessionInfoListener = (event: MessageEvent) => {
    // console.log('sessionInfoListener', event);
    if (event.origin !== 'https://www.facebook.com') return;
    console.log([...new URLSearchParams(event.data).entries()]);
    try {
      const data = JSON.parse(event.data);
      if (data.type === 'WA_EMBEDDED_SIGNUP') {
        if (data.event === 'FINISH') {
          const { phone_number_id, waba_id } = data.data;
        } else {
          const { current_step } = data.data;
        }
      }
    } catch {
      console.log('Non JSON Response', event.data);
    }
  };

  const launchWhatsAppSignup = () => {
    window.FB.login(
      function (response: any) {
        console.log('login', response); 

        if (response.authResponse) {
          const accessToken = response.authResponse.accessToken;
              try {
              connectFB(channel?.channelAllData?.uid , response?.authResponse?.code);
            } catch (error) {
              console.error('Error connecting to Facebook:', error);
            }
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      {
        config_id: '1136762987498349',
        response_type: 'code',
        override_default_response_type: true,
        scope: 'catalog_management,business_management',
        extras: {
          feature: 'whatsapp_embedded_signup',
          setup: {
            business: {
              name: 'Acme Inc.',
              email: 'johndoe@acme.com',
            },
          },
        },
      }
    );
  };

//   const startEmbeddedSignupFlowManualUrl = () => {
//     const oauth2Endpoint = 'https://www.facebook.com/v18.0/dialog/oauth';

//     const params = {
//       client_id: '642875634705410',
//       redirect_uri: 'https://fb-onboard.vamosys.com/',
//       response_type: 'code',
//       config_id: '185203927973689',
//       state: 'business_management,whatsapp_business_management,whatsapp_business_messaging',
//     };

//     const oauthURL = `${oauth2Endpoint}?${new URLSearchParams(params).toString()}`;

//     window.location.replace(oauthURL);
//   };

  return (
    <div className="facebook-login">
      <button
        onClick={launchWhatsAppSignup}
        className="facebook-login-button"
        // disabled={freeTrial}
      >
        Login with Facebook
      </button>
    </div>
  );
};


import React, { useEffect, useRef, useState } from 'react'
import { Alert, Button, Container, Input } from 'reactstrap'
import { BsCalendar } from 'react-icons/bs'
import * as originalMoment from 'moment'
import { extendMoment } from 'moment-range'
import DateRangePicker from 'react-daterange-picker'
import { Overlay, Popover } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel, faCheck, faWarning } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import excelIcon from '../../../assets/img/excel.png';

import styles from './Dashboard.module.scss'
import * as appointmentService from '../../../services/appointmentService'
import getAdjustedDateRange from '../../../utils/getAdjustedDateRange'
import Loader from '../../../shade/Loaders/smallLoader'
import { SOMETHING_WENT_WRONG } from '../../../constants/errorMessage'
import { Cell, Pie, PieChart, Sector } from 'recharts'
import { Background } from 'reactflow'
import { ErrorImage } from '../../../common/ErrorMessage'
import downloadExcelforDashboard from './ExcelforDashboard'
import downloadExcel from '../downloadExcel'
import { all } from 'axios'

const moment = extendMoment(originalMoment)

type Props = {}

enum DateFilterType {
  Past7Days = 'Past7Days',
  Past30Days = 'Past30Days',
  Past90Days = 'Past90Days',
  ThisWeek = 'ThisWeek',
  PreviousWeek = 'PreviousWeek',
  ThisMonth = 'ThisMonth',
  PreviousMonth = 'PreviousMonth',
  CustomRange = 'CustomRange',
}

function Dashboard({ }: Props) {
  //Daterange
  const today = moment().clone().startOf('day')
  const [dateRangeValue, setDateRangeValue] = useState(
    moment.range(today.clone().subtract(7, 'days').startOf('day'), today.endOf('day'))
  )


  const [dateFilter, setDateFilter] = useState(DateFilterType.Past7Days)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const datepickertarget = useRef(null)
  const businessName=useSelector((state:any)=>state?.cartreducer?.channelUid.label);
  
  const userProfile = useSelector((state: any) => state.cartreducer.business)
  const businessUid = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );

  const memid=useRef(userProfile.uid);
  

  const { start: adjustedStart, end: adjustedEnd } = getAdjustedDateRange(dateRangeValue.start, dateRangeValue.end)
  const analyticsQuery = useQuery({
    queryKey: ['appointment-analytics', memid.current, JSON.stringify({ start: dateRangeValue.start.toISOString(), end: dateRangeValue.end.toISOString() })],
    queryFn: () => appointmentService.getAppointmentAnalytics(memid.current, adjustedStart.format('YYYY-MM-DD'), adjustedEnd.format('YYYY-MM-DD'))
  })
  const analyticsQueryBasedonBusiness = useQuery({
    queryKey: ['appointment-analytics-business', businessUid, JSON.stringify({ start: dateRangeValue.start.toISOString(), end: dateRangeValue.end.toISOString(),type:'business' })],
    queryFn: () => appointmentService.getAppointmentAnalyticsBasedonBusiness(businessUid, adjustedStart.format('YYYY-MM-DD'), adjustedEnd.format('YYYY-MM-DD'),'business')
  })
  const appointmentQueryBasedonBusiness = useQuery({
    queryKey: ['appointment-business', businessUid, JSON.stringify({ start: dateRangeValue.start.toISOString(), end: dateRangeValue.end.toISOString() })],
    queryFn: () => appointmentService.getAppointmentBasedonBusiness(businessUid, adjustedStart.format('YYYY-MM-DD'), adjustedEnd.format('YYYY-MM-DD'))
  })
  const getAllMemberConfigration = useQuery({
    queryKey: ["get-memberconfig", businessUid],
    queryFn: () => appointmentService.getAllMemberConfiguration(businessUid),
    retry: false,
  });



 
  const role = useSelector(
    (state: any) => state.cartreducer.business?.role?.type
  );
  const [isPiChartHovering, setisPiChartHovering] = useState(false);
  const [piChartHover, setPiChartHover] = useState(0);
  const [member,setmember]=useState("all");
  const [memberName,setmemberName]=useState(userProfile?.name);
  const datepickerTarget = useRef<HTMLDivElement | null>(null);



  const handleDownload=()=>{
    downloadExcelforDashboard(appointmentQueryBasedonBusiness.data,userProfile.name);
  }

  const dataQuery=appointmentQueryBasedonBusiness?.data?.filter((data:any)=>data.memberUid===member)

  console.log("ee",analyticsQueryBasedonBusiness.data?.appointmentCount);
  console.log("ss",analyticsQuery?.data?.appointmentCount)

  const handleMemberDowload=()=>{
    downloadExcel(dataQuery,memberName)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (datepickerTarget.current && !datepickerTarget.current.contains(event.target as Node)) {
        setShowDatePicker(false);
      }
    };
    if (showDatePicker) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDatePicker]);

  const changeDateFilterHandler = (event: any) => {
    const getStartDate = (subtractDays: number, unit: moment.unitOfTime.DurationConstructor = 'days') => {
      return today.clone().subtract(subtractDays, unit).startOf(unit)
    }


 


    const filter = event.target.value as DateFilterType

    switch (filter) {
      case DateFilterType.Past7Days:
        setDateRangeValue(moment.range(today.clone().subtract(7, "days").startOf('day'), today.endOf('day')))
        setShowDatePicker(false)
        break

      case DateFilterType.Past30Days:
        setDateRangeValue(moment.range(getStartDate(30), today.endOf('day')))
        setShowDatePicker(false)
        break

      case DateFilterType.Past90Days:
        setDateRangeValue(moment.range(getStartDate(90), today.endOf('day')))
        setShowDatePicker(false)
        break

      case DateFilterType.ThisWeek:
        setDateRangeValue(moment.range(today.clone().startOf('week'), today.endOf('day')))
        setShowDatePicker(false)
        break

      case DateFilterType.PreviousWeek:
        setDateRangeValue(moment.range(getStartDate(1, 'week'), getStartDate(1, 'week').endOf('week')))
        setShowDatePicker(false)
        break

      case DateFilterType.ThisMonth:
        setDateRangeValue(moment.range(today.clone().startOf('month'), today.endOf('day')))
        setShowDatePicker(false)
        break

      case DateFilterType.PreviousMonth:
        setDateRangeValue(moment.range(getStartDate(1, 'month'), getStartDate(1, 'month').endOf('month')))
        setShowDatePicker(false)
        break

      case DateFilterType.CustomRange:
        setShowDatePicker(true)
        break

      default:
        break
    }
    setDateFilter(filter)
  }
  const changeMemberFilter=(event :any)=>{
    const target=event.target.value
    if(target==="all"){
      setmember("all");
      analyticsQueryBasedonBusiness.refetch();      
    }
    else{
    setmember(target);
    setmemberName(event.target.options[event.target.selectedIndex].text);
    memid.current=target;
    analyticsQuery.refetch();
   }
  }

  let analyticPanel = <Loader />

  if ( role !=="OWNER" &&!analyticsQuery.isLoading && analyticsQuery.isError || role==="OWNER" &&!analyticsQueryBasedonBusiness.isLoading && analyticsQueryBasedonBusiness.isError ) {
    analyticPanel = (
      <Alert color='danger'>
        <FontAwesomeIcon icon={faWarning} className='mr-1' />
        {SOMETHING_WENT_WRONG}
      </Alert>
    )
  }
  else if (!analyticsQuery.isLoading && role!=="OWNER" ) {
    // && (role!=="OWNER"||member!=="all")

    const piChartData = [
      { name: 'Pending', value: (analyticsQuery?.data?.totalPendingAppointments) },
      { name: 'Cancelled', value: (analyticsQuery?.data?.totalCancelledAppointments) },
      { name: 'Completed', value: (analyticsQuery?.data?.totalCompletedAppointments) },
    ]
    const piChartColours = ['#FB8C00', '#dc3545', '#28a745']
    const today = moment().clone().startOf('day');

    analyticPanel = (
      <>
        <div className={styles.analyticsPanel}>
          <div className={styles.numbers}>
            <div className={styles.numberItem}>
              <div className={styles.numberCount}>{analyticsQuery?.data?.appointmentCount}</div>
              <div className={styles.numberLabel}>
                Total
              </div>
            </div>
            <div className={styles.numberItem}>
              <div className={styles.numberCount}>{analyticsQuery?.data?.totalPendingAppointments}</div>
              <div className={styles.numberLabel}>
                <FontAwesomeIcon icon={faWarning} className='mr-1' />
                Pending
              </div>
            </div>
            <div className={styles.numberItem}>
              <div className={styles.numberCount}>{analyticsQuery?.data?.totalCompletedAppointments}</div>
              <div className={styles.numberLabel}>
                <FontAwesomeIcon icon={faCheck} className='mr-1' />
                Completed
              </div>
            </div>
            <div className={styles.numberItem}>
              <div className={styles.numberCount}>{analyticsQuery?.data?.totalCancelledAppointments}</div>
              <div className={styles.numberLabel}>
                <FontAwesomeIcon icon={faCancel} className='mr-1' />
                Cancelled
              </div>
            </div>
          </div>

          {/* {role==="OWNER" &&member!=="all" &&(
          <img src={excelIcon} alt='' className="me-3" onClick={handleDownload} style={{cursor:'pointer'}} title="Download excel" width={30} height={30}/>)} */}
         
          <div className={styles.daterange}>
          {/* {role==="OWNER" &&member!=="all" &&(
            <Input
        className={styles.dateFilter}
        type='select'
        size={1} 
        onChange={changeMemberFilter}
      >
        <option value="all">All</option>
        {getAllMemberConfigration?.data?.map((item:any, index:any) => (
          <option key={index} value={item.memberUid}>
            {item?.memberName.substring(0, 17)}
          </option>
        ))}
      </Input>)} */}
            <Input
              className={styles.dateFilter}
              type='select'
              size={1}
              value={dateFilter}
              onChange={changeDateFilterHandler}
            >
              <option value={DateFilterType.Past7Days}>Past 7 days</option>
              <option value={DateFilterType.Past30Days}>Past 30 days</option>
              <option value={DateFilterType.Past90Days}>Past 90 days</option>
              <option value={DateFilterType.ThisWeek}>This week</option>
              <option value={DateFilterType.PreviousWeek}>Previous week</option>
              <option value={DateFilterType.ThisMonth}>This month</option>
              <option value={DateFilterType.PreviousMonth}>Previous month</option>
              <option value={DateFilterType.CustomRange}>Custom range</option>
            </Input>
            <div className={'form-control ' + styles.calenderInput} ref={datepickertarget} onClick={() => setShowDatePicker(!showDatePicker)}>
              <BsCalendar />
              <span className='value ml-2'>
                {dateRangeValue.start.format('DD/MM/YYYY')}
                {' - '}
                {dateRangeValue.end.format('DD/MM/YYYY')}
              </span>
            </div>
            <Overlay target={datepickertarget.current} show={showDatePicker} placement='bottom' ref={datepickerTarget}>
              <Popover id='popover-basic' style={{ maxWidth: '350px' }}>
                <DateRangePicker
                  value={dateRangeValue}
                  onSelect={(value: any) => {
                    const startDate = moment(value.start).isAfter(moment()) ? moment() : moment(value.start);
                    const endDate = moment(value.end).isAfter(moment()) ? moment() : moment(value.end);
                    setDateRangeValue(moment.range(startDate.startOf('day'), endDate.endOf('day')));
                    setDateFilter(DateFilterType.CustomRange)
                    setShowDatePicker(false)
                  }}
                  singleDateRange={true}
                />
              </Popover>
            </Overlay>
          </div>
        </div>
        {analyticsQuery?.data?.appointmentCount === 0 && (
            <ErrorImage />
          )}
        {analyticsQuery?.data?.appointmentCount !== 0  && <main>
          <PieChart width={420} height={400} style={{ width: "700px" }}>
            <Pie
              data={piChartData}
              style={{ zIndex: 1000 }}
              cx={200}
              cy={200}
              innerRadius={70}
              outerRadius={100}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
              activeIndex={piChartHover}
              onMouseEnter={(_, index) => {
                setPiChartHover(index);
                setisPiChartHovering(true);
              }}
              onMouseOut={() => {
                setPiChartHover(0);
                setisPiChartHovering(false)
              }}
              activeShape={(props: any) => {
                const RADIAN = Math.PI / 180;
                const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, percent, payload, value } = props;

                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';


                return (
                  <g>
                    <text x={cx} y={cy} dy={8} font-size={"35"} font-weight="600" textAnchor="middle" fill="#00b598">
                      {analyticsQuery?.data?.appointmentCount}
                    </text>
                    <text x={cx} y={cy + (25)} dy={8} font-size={"16"} font-weight="400" textAnchor="middle" fill="#959595">
                      Total
                    </text>
                    <Sector
                      cx={cx}
                      cy={cy}
                      innerRadius={innerRadius}
                      outerRadius={outerRadius}
                      startAngle={startAngle}
                      endAngle={endAngle}
                      fill={fill}
                    />
                    {isPiChartHovering ? (
                      <>
                        <Sector
                          cx={cx}
                          cy={cy}
                          startAngle={startAngle}
                          endAngle={endAngle}
                          innerRadius={outerRadius + 6}
                          outerRadius={outerRadius + 10}
                          fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333" font-size={14}>{payload.name}</text>
                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999" font-size={12}>
                          {`(${(percent * 100).toFixed(2)}%)`}
                        </text>
                      </>
                    ) : null}
                  </g>
                );
              }}
            >
              {piChartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={piChartColours[index % piChartColours.length]} />
              ))}
            </Pie>
          </PieChart>
        </main> }
      </>
    )
  }
  else if (!analyticsQueryBasedonBusiness.isLoading && role==="OWNER") {
    const piChartData = [
      { name: 'Pending', value: ( member==="all"? analyticsQueryBasedonBusiness?.data?.totalPendingAppointments:analyticsQuery?.data?.totalPendingAppointments) },
      { name: 'Cancelled', value: (  member==="all"? analyticsQueryBasedonBusiness?.data?.totalCancelledAppointments:analyticsQuery?.data?.totalCancelledAppointments) },
      { name: 'Completed', value: (  member==="all"?  analyticsQueryBasedonBusiness?.data?.totalCompletedAppointments:analyticsQuery?.data?.totalCompletedAppointments) },
    ]
    const piChartColours = ['#FB8C00', '#dc3545', '#28a745']

    analyticPanel = (
      <>
        <div className={styles.analyticsPanel}>
          <div className={styles.numbers}>
            <div className={styles.numberItem}>
              <div className={styles.numberCount}>{ member==="all" ?  analyticsQueryBasedonBusiness?.data?.appointmentCount:analyticsQuery?.data?.appointmentCount}</div>
              <div className={styles.numberLabel}>
                Total
              </div>
            </div>
            <div className={styles.numberItem}>
              <div className={styles.numberCount}>{ member==="all" ? analyticsQueryBasedonBusiness?.data?.totalPendingAppointments:analyticsQuery.data?.totalPendingAppointments}</div>
              <div className={styles.numberLabel}>
                <FontAwesomeIcon icon={faWarning} className='mr-1' />
                Pending
              </div>
            </div>
            <div className={styles.numberItem}>
              <div className={styles.numberCount}>{ member=="all" ?   analyticsQueryBasedonBusiness?.data?.totalCompletedAppointments:analyticsQuery?.data?.totalCompletedAppointments}</div>
              <div className={styles.numberLabel}>
                <FontAwesomeIcon icon={faCheck} className='mr-1' />
                Completed
              </div>
            </div>
            <div className={styles.numberItem}>
              <div className={styles.numberCount}>{ member==="all" ?  analyticsQueryBasedonBusiness?.data?.totalCancelledAppointments :analyticsQuery.data?.totalCancelledAppointments}</div>
              <div className={styles.numberLabel}>
                <FontAwesomeIcon icon={faCancel} className='mr-1' />
                Cancelled
              </div>
            </div>
          </div>
          {  analyticsQuery?.data && analyticsQuery?.data?.appointmentCount>0 && member!=="all" &&(
          <img src={excelIcon} alt='' className="me-3" onClick={ member==="all" ? handleDownload :handleMemberDowload} style={{cursor:'pointer'}} title="Download excel" width={30} height={30}/>)}

{analyticsQueryBasedonBusiness?.data && analyticsQueryBasedonBusiness?.data?.appointmentCount>0 && member==="all" &&(
          <img src={excelIcon} alt='' className="me-3" onClick={ member==="all" ? handleDownload :handleMemberDowload} style={{cursor:'pointer'}} title="Download excel" width={30} height={30}/>)}
          <div className={styles.daterange}>
          <Input
        className={styles.dateFilter}
        type='select'
        size={1} 
        onChange={changeMemberFilter}
      >
        <option value="all">All</option>
        {getAllMemberConfigration?.data?.map((item:any, index:any) => (
          <option key={index} value={item.memberUid}>
            {item?.memberName.substring(0, 17)}
          </option>
        ))}
      </Input>
            <Input
              className={styles.dateFilter}
              type='select'
              size={1}
              value={dateFilter}
              onChange={changeDateFilterHandler}
            >
              <option value={DateFilterType.Past7Days}>Past 7 days</option>
              <option value={DateFilterType.Past30Days}>Past 30 days</option>
              <option value={DateFilterType.Past90Days}>Past 90 days</option>
              <option value={DateFilterType.ThisWeek}>This week</option>
              <option value={DateFilterType.PreviousWeek}>Previous week</option>
              <option value={DateFilterType.ThisMonth}>This month</option>
              <option value={DateFilterType.PreviousMonth}>Previous month</option>
              <option value={DateFilterType.CustomRange}>Custom range</option>
            </Input>
            <div className={'form-control ' + styles.calenderInput} ref={datepickertarget} onClick={() => setShowDatePicker(!showDatePicker)}>
              <BsCalendar />
              <span className='value ml-2'>
                {dateRangeValue.start.format('DD/MM/YYYY')}
                {' - '}
                {dateRangeValue.end.format('DD/MM/YYYY')}
              </span>
            </div>
            <Overlay target={datepickertarget.current} show={showDatePicker} placement='bottom' ref={datepickerTarget}>
              <Popover id='popover-basic' style={{ maxWidth: '350px' }}>
                <DateRangePicker
                  value={dateRangeValue}
                  onSelect={(value: any) => {
                    const startDate = moment(value.start).isAfter(moment()) ? moment() : moment(value.start);
                    const endDate = moment(value.end).isBefore(startDate) ? startDate : moment(value.end);
                    setDateRangeValue(moment.range(startDate.startOf('day'), endDate.endOf('day')));
                    setDateFilter(DateFilterType.CustomRange)
                    setShowDatePicker(false)
                  }}
                  singleDateRange={true}
                />
              </Popover>
            </Overlay>
          </div>
        </div>
        {(analyticsQueryBasedonBusiness?.data?.appointmentCount === 0 || (analyticsQuery?.data?.appointmentCount === 0 && member!=="all"))&& (
          <div className='mt-5'>
            <ErrorImage />
            </div>
          )}
          {/* {analyticsQuery?.data?.appointmentCount === 0 && member!=="all"&&(
                        <div className='mt-5'><ErrorImage /></div>

          )} */}
       {analyticsQueryBasedonBusiness?.data?.appointmentCount !== 0 && analyticsQuery?.data?.appointmentCount !== 0 && <main>
    
           {( member==="all" && analyticsQueryBasedonBusiness.data?.appointmentCount && analyticsQueryBasedonBusiness?.data?.appointmentCount >0  &&(
          <PieChart width={420} height={400} style={{ width: "700px" }}>
            <Pie
              data={piChartData}
              style={{ zIndex: 1000 }}
              cx={200}
              cy={200}
              innerRadius={70}
              outerRadius={100}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
              activeIndex={piChartHover}
              onMouseEnter={(_, index) => {
                setPiChartHover(index);
                setisPiChartHovering(true);
              }}
              onMouseOut={() => {
                setPiChartHover(0);
                setisPiChartHovering(false)
              }}
              activeShape={(props: any) => {
                const RADIAN = Math.PI / 180;
                const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, percent, payload, value } = props;

                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';


                return (
                  <g>
                    <text x={cx} y={cy} dy={8} font-size={"35"} font-weight="600" textAnchor="middle" fill="#00b598">
                      {member==="all" ? analyticsQueryBasedonBusiness?.data?.appointmentCount:analyticsQuery?.data?.appointmentCount}
                    </text>
                    <text x={cx} y={cy + (25)} dy={8} font-size={"16"} font-weight="400" textAnchor="middle" fill="#959595">
                      Total
                    </text>
                    <Sector
                      cx={cx}
                      cy={cy}
                      innerRadius={innerRadius}
                      outerRadius={outerRadius}
                      startAngle={startAngle}
                      endAngle={endAngle}
                      fill={fill}
                    />
                    {isPiChartHovering ? (
                      <>
                        <Sector
                          cx={cx}
                          cy={cy}
                          startAngle={startAngle}
                          endAngle={endAngle}
                          innerRadius={outerRadius + 6}
                          outerRadius={outerRadius + 10}
                          fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333" font-size={14}>{payload.name}</text>
                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999" font-size={12}>
                          {`(${(percent * 100).toFixed(2)}%)`}
                        </text>
                      </>
                    ) : null}
                  </g>
                );
              }}
            >
              {piChartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={piChartColours[index % piChartColours.length]} />
              ))}
            </Pie>
          </PieChart>))}
          { member!=="all" && analyticsQuery.data?.appointmentCount && analyticsQuery?.data?.appointmentCount>0&&(
          <PieChart width={420} height={400} style={{ width: "700px" }}>
            <Pie
              data={piChartData}
              style={{ zIndex: 1000 }}
              cx={200}
              cy={200}
              innerRadius={70}
              outerRadius={100}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
              activeIndex={piChartHover}
              onMouseEnter={(_, index) => {
                setPiChartHover(index);
                setisPiChartHovering(true);
              }}
              onMouseOut={() => {
                setPiChartHover(0);
                setisPiChartHovering(false)
              }}
              activeShape={(props: any) => {
                const RADIAN = Math.PI / 180;
                const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, percent, payload, value } = props;

                const sin = Math.sin(-RADIAN * midAngle);
                const cos = Math.cos(-RADIAN * midAngle);
                const sx = cx + (outerRadius + 10) * cos;
                const sy = cy + (outerRadius + 10) * sin;
                const mx = cx + (outerRadius + 30) * cos;
                const my = cy + (outerRadius + 30) * sin;
                const ex = mx + (cos >= 0 ? 1 : -1) * 22;
                const ey = my;
                const textAnchor = cos >= 0 ? 'start' : 'end';


                return (
                  <g>
                    <text x={cx} y={cy} dy={8} font-size={"35"} font-weight="600" textAnchor="middle" fill="#00b598">
                      {analyticsQuery?.data?.appointmentCount}
                    </text>
                    <text x={cx} y={cy + (25)} dy={8} font-size={"16"} font-weight="400" textAnchor="middle" fill="#959595">
                      Total
                    </text>
                    <Sector
                      cx={cx}
                      cy={cy}
                      innerRadius={innerRadius}
                      outerRadius={outerRadius}
                      startAngle={startAngle}
                      endAngle={endAngle}
                      fill={fill}
                    />
                    {isPiChartHovering ? (
                      <>
                        <Sector
                          cx={cx}
                          cy={cy}
                          startAngle={startAngle}
                          endAngle={endAngle}
                          innerRadius={outerRadius + 6}
                          outerRadius={outerRadius + 10}
                          fill={fill}
                        />
                        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333" font-size={14}>{payload.name}</text>
                        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999" font-size={12}>
                          {`(${(percent * 100).toFixed(2)}%)`}
                        </text>
                      </>
                    ) : null}
                  </g>
                );
              }}
            >
              {piChartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={piChartColours[index % piChartColours?.length]} />
              ))}
            </Pie>
          </PieChart>)}
        </main> }
      </>
    )
  }

  return (
    <Container>
      <h3 className={styles.caption}>{businessName}-Appointments</h3>
      {analyticPanel}
    </Container>
  )
}

export default Dashboard
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { Button, Col, Container, Label, Row } from "reactstrap";
import txtIcon from "../../assets/img/text.png";
import docIcon from "../../assets/img/doc.png";
import pdfIcon from "../../assets/img/pdf.png";
import { MdArrowBack } from "react-icons/md";
import { toast } from "../../common/alert";
import { getRAG, trainRAG, updateRAG, uploadRAGDocument } from "../../services/ragService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { showAlertYesNo } from "../../common/alertYesNo";
import { faCloudUpload } from "@fortawesome/free-solid-svg-icons";
import styles from './rag.module.scss';

interface FolderPageProps {
    folders: {
        uid: string;
        name: string;
        documents: any;
        status: string;
    };
}

const MAX_FILES = 5;
const MAX_FILE_SIZE_MB = 10;

const FolderDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { folders } = (location.state as FolderPageProps) || {};
    const [folder, setFolder] = useState(folders);
    const [files, setFiles] = useState<any[]>(folder?.documents || []);
    const [status, setStatus] = useState(folder?.status);
    const [isLoading, setIsLoading] = useState(false);
    const [isTrainLoading, setTrainLoading] = useState(false);

    const handleFileDrop = (acceptedFiles: File[]) => {
        if (files.length + acceptedFiles.length > MAX_FILES) {
            toast('error', `You can only upload up to ${MAX_FILES} files.`);
            return;
        }

        const validFiles: File[] = [];
        acceptedFiles.forEach((file: File) => {
            if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                toast('error', `Please upload files that are ${MAX_FILE_SIZE_MB}MB or smaller.`);
            } else {
                (file as any).fileName = file.name;
                validFiles.push(file);
            }
        });

        setFiles([...files, ...validFiles]);
    };

    const uploadRAG = async () => {
        if (files.length === 0) {
            toast('error', "Please select files to upload");
            return;
        }

        if (files?.length === folders?.documents?.length) {
            toast("error", "Files uploaded already. Please select new files to upload")
            return;
        }
        setIsLoading(true);

        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }

        const filesWithoutType = files.filter(item => !item.mediaType);
        if (filesWithoutType?.length > 0) {
            const response = await uploadRAGDocument(folder?.uid, formData);
            folder.documents = [...folder.documents, ...response];
            const updatedFolder = await updateRAG(folder?.uid, folder);
            setFiles(folder.documents);
        }
        else {
            folder.documents = files;
            await updateRAG(folder?.uid, folder);
            toast('success', 'Folder updated successfully');
            setFiles(folder.documents);
        }
        setIsLoading(false);
    }

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: handleFileDrop,
        accept: {
            "text/plain": [".txt"],
            "application/msword": [".doc", ".docx"],
            "application/pdf": [".pdf"],
        },
    });

    const getFileIcon = (file: any) => {
        const extension = file?.fileName?.split(".").pop();
        switch (extension) {
            case "txt":
                return txtIcon;
            case "doc":
            case "docx":
                return docIcon;
            case "pdf":
                return pdfIcon;
            default:
                return null;
        }
    };

    const trainModel = async () => {
        if (folder?.documents?.length === 0) {
            toast('error', 'Please upload the files to train');
            return;
        }
        setTrainLoading(true);
        const res = await trainRAG(folder?.uid)
        if (res === "SUCCESS") {
            const rag = await getRAG(folder?.uid);
            setStatus(rag?.status);
        }
        setTrainLoading(false);
    }

    const handleDelete = async (file: any, index: any) => {
        const alertResponse = await showAlertYesNo(
            "question",
            `Are you sure you want to delete ${file.fileName}?`
        );
        if (alertResponse.isConfirmed) {
        const updatedFiles = files.filter((_, i) => i !== index);
        setFiles(updatedFiles);
        }
    }

    return (
        <Container>
            <Row className="align-items-center">
                <Col xs="auto">
                    <div onClick={() => navigate(-1)} className={styles.arrowDiv}>
                        <MdArrowBack className={styles.arrow} />
                    </div>
                </Col>
                <Col>
                    <h5 className="pt-3">
                        Knowledge Folder: {folder?.name}
                    </h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label className={styles.status}>Status:  <span
                        className="badge badge-pill badge-info"
                        style={{
                            backgroundColor:
                                status === 'COMPLETED'
                                    ? '#07c760'
                                    : status === 'UPLOADED'
                                        ? '#f0ad4e'
                                        : status === 'ERROR'
                                            ? '#dc3545'
                                            : '#17a2b8',
                            padding: '7px 12px',
                            color: 'white',
                            fontSize: '14px'
                        }}
                    >
                        {status}
                    </span></Label>
                </Col>
                <Col className="d-flex justify-content-end mr-2">
                    <Button
                        className="sendButton mr-2"
                        onClick={uploadRAG}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Loading...' : 'Save'}
                    </Button>
                    <Button className="sendButton" onClick={trainModel} disabled={isTrainLoading}>
                        {isTrainLoading ? 'Loading...' : 'Train'}
                    </Button>
                </Col>
            </Row>
            <Row className="pt-4 pb-2">
                <Col>
                    <div className={styles.dropArea} {...getRootProps()}>
                        <input {...getInputProps()} />

                        <p className={styles.dropText}>
                            Drag & drop files here, or click to upload more files
                        </p>

                        <p className={styles.allowedType}>
                            Allowed files: .txt, .pdf, .doc & .docx <span className="ml-4"> Max file size: 5MB </span>  <span className="ml-4"> Max files limit: 5 </span>
                        </p>

                        <label className={styles.uploadFiles}>
                            <span>
                                Upload Files
                                <FontAwesomeIcon
                                    icon={faCloudUpload}
                                    className="ml-2"
                                    id={styles.left}
                                />
                            </span>
                        </label>
                    </div>
                </Col>
            </Row>
            <Row className="mt-5">
                {files?.map((file, index) => (
                    <Col key={index} md="auto" className="text-center" id={styles.relative}>
                        <div className={styles.fileDiv}>
                            <FontAwesomeIcon
                                icon={faTimesCircle}
                                className={styles.faTimes}
                                onClick={() => handleDelete(file, index)}
                            />
                            <img
                                src={getFileIcon(file) || ""}
                                alt={file?.fileName}
                                className={styles.image}
                            />
                            <p className={styles.file}>
                                {file?.fileName}
                            </p>
                        </div>
                    </Col>
                ))}
            </Row>
        </Container >
    );
};

export default FolderDetails;

import React, { useEffect, useState } from "react";
import styles from "./Sequence.module.scss";
import { Container, Row, Col, Label, Button } from "reactstrap";
import {
  faPlus,
  faTrashCan,
  faPauseCircle,
  faCalendar,
  faSort,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SyncButton from "../../common/syncButton";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Popover,
  PopoverBody,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import {
  getAllSequence,
  deleteSequence,
  getOverallSequenceFlow,
  updateSequenceStatus,
} from "../../services/SequenceService";
import async from "react-select/dist/declarations/src/async/index";
import moment from "moment";
import { useSelector } from "react-redux";
import { showAlert, confirmAlert, toast } from "../../common/alert";
import TooltipReference from "../../common/tooltip";
import { sliceText } from "../../common/sliceText";
import { IoCopyOutline } from "react-icons/io5";
import Avatar from "react-avatar";
import "./sequence.scss";
import { planCodes } from "../../common/commonfns";
import { ErrorImage } from "../../common/ErrorMessage";


type Props = {};

const Sequence = (props: Props) => {
  const navigate = useNavigate();
  const [sequenceData, getSequence] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const createSequencePage = (e: any) => {
    navigate("creatsequence");
  };
  const channelUid = useSelector(
    (state: any) => state.cartreducer.channelUid.value
  );

  let profile: any = useSelector((state: any) => state.cartreducer.business);

  // const member = useSelector((state: any) => state.cartreducer.memberData)
  const member = useSelector((state: any) => state.cartreducer.memberData);
  const getAll = async () => {
    if (channelUid) {
      setIsLoading(true);

      const getAllSeq = await getAllSequence(channelUid);
      if (getAllSeq) getSequence(getAllSeq);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAll();
  }, [channelUid]);

  const data = () => {

  };

  const updateSequence = async (element: any, method: string) => {
    const newStatus = method === "PUBLISH" ? "Paused" : "Publish";
    const updatedElement = { ...element, status: newStatus };
    const response = await updateSequenceStatus(updatedElement, channelUid);
    if (response) {
      getAll();
    }
  };

  const [copiedUid, setCopiedUid] = useState(null);

  const handleCopy = (uid: any) => {
    navigator.clipboard
      .writeText(uid)
      .then(() => {
        setCopiedUid(uid);
      })
      .catch((error) => {
        console.error("Failed to copy UID:", error);
      });
  };

  const SequenceCRUD = async (element: any, method: string) => {
    const { uid } = element;
    switch (
      method // Corrected syntax here
    ) {
      case "UPDATE":
        navigate(`${uid}/updatesequence`);
        break;
      case "DELETE":
        confirmAlert(
          "warning",
          "Are you sure you want to delete this sequence?",
          async () => {
            const response = await deleteSequence(uid);
            if (sequenceData.length === 1) {
              getSequence([]);
            } else if (response) {
              toast('success','Sequence deleted successfully')
              getAll();
            }
          },
          () => {}
        );
        break;
      case "LOGS":
        navigate(`${element?.uid}/logs`);
        break; // Added break for consistency
      case "PUBLISH":
        confirmAlert(
          "warning",
          "Are you sure you want to Pause?",
          async () => {
            updateSequence(element, method);
          },
          () => {}
        );
        break;
      case "DRAFT":
        confirmAlert(
          "warning",
          "Are you sure you want to publish?",
          async () => {
            updateSequence(element, method);
          },
          () => {}
        );
        break;
      case "PAUSE":
        confirmAlert(
          "warning",
          "Upon publishing the sequence, your contacts will start receiving the messages based on the configurations",
          async () => {
            updateSequence(element, method);
          },
          () => {}
        );
        break;
      default:
      // Default action if method doesn't match any case
    }
  };

  const sequenceLogs = async (element: any) => {
    navigate(`${element?.uid}/logs`);
  };

  const memberFind = (element: any) => {
    const match = member.find((m: any) => m?.uid === element);
    return match;
  };

  return (
    <Container className={styles.sequenceContainer}>
      <Row className={styles.sequenceHeader}>
        <Col md="1">
          <h6>Sequence</h6>
        </Col>
        <Col md={7}></Col>
        <Col className="d-flex justify-content-end">
          <SyncButton onClick={()=>{getAll()
setTimeout(()=>{toast("success", "Sequence synced successfully");
},1000)}
          } />
          {/* {(profile?.business?.plan?.code === planCodes("proMonthly") ||
            profile?.business?.plan?.code === planCodes("proYearly")) &&
          sequenceData?.length >= 5 ? (
            <TooltipReference
              placement="right"
              content={`You cannot add more than 5 sequence in ${profile?.business?.plan?.name}`}
              tooltipId="sequence"
            >
              <Button
                className="signUpBtn mt-0 ms-2"
                onClick={createSequencePage}
                disabled={true}
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Create New Sequence
              </Button>
            </TooltipReference>
          ) : ( */}
            <Button
              className="signUpBtn mt-0 ms-2"
              onClick={createSequencePage}
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Create New Sequence
            </Button>
          {/* )} */}
        </Col>
      </Row>
      <Row className="mt-2">
        {isLoading ? (
          <div className="centerItemsWithWidth">
            <div className="spinner-border text-success" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            {sequenceData?.length ? (
              sequenceData?.map((element: any, index: number) => {
                // Find the corresponding member object
                let matchingMember = member.find(
                  (m: any) => m?.uid === element?.sequenceCreatedBy
                );
                // Extract the first letter of the name key
                let firstLetter = matchingMember
                  ? matchingMember.name.charAt(0)
                  : "";

                return (
                  <>
                    <Col md={3}>
                      <Card className={`${element.status}_card`}>
                        <CardHeader className="bg-white">
                          <div className={styles.cardBodyHeader}>
                            <div key={index} className="text-muted">
                              {element.sequenceName.length > 10 ? (
                                <TooltipReference
                                  placement="right"
                                  content={element.sequenceName}
                                  tooltipId={`nameData_${element.id}`}
                                >
                                  {sliceText(element.sequenceName, 15)}
                                </TooltipReference>
                              ) : (
                                element.sequenceName
                              )}
                            </div>
                            <div>
                              {element.status === "Publish" && (
                                <>
                                  <>
                                    {/* <IoCopyOutline
                                  className="copy-icon me-2"
                                  style={{ cursor: 'pointer' }}
                                  title={copiedUid === element.uid ? 'Copied' : `Copy Sequence Id`}
                                  onClick={() => handleCopy(element.uid)}
                                /> */}
                                  </>
                                  <>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      className="me-2"
                                      fill="currentColor"
                                      viewBox="0 0 16 16"
                                      style={{ cursor: "pointer" }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        SequenceCRUD(element, "PUBLISH");
                                      }}
                                    >
                                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                                      <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z" />
                                    </svg>
                                  </>
                                </>
                              )}
                              {element.status === "Paused" && (
                                <>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    className="me-2"
                                    fill="currentColor"
                                    viewBox="0 0 16 16"
                                    style={{ cursor: "pointer" }}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      SequenceCRUD(element, "PAUSE");
                                    }}
                                  >
                                    {" "}
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />{" "}
                                    <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />{" "}
                                  </svg>
                                </>
                              )}
                              <TooltipReference
                                placement="bottom"
                                content={
                                  copiedUid === element.uid
                                    ? "Copied"
                                    : `Copy Sequence id`
                                }
                                tooltipId={`copy-to-clipboard_${element.uid}`}
                              >
                                <IoCopyOutline
                                  className="copy-icon me-2"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleCopy(element.uid)}
                                />
                              </TooltipReference>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                                style={{ cursor: "pointer" }}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  SequenceCRUD(element, "DELETE");
                                }}
                              >
                                {" "}
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />{" "}
                                <path
                                  fill-rule="evenodd"
                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                />{" "}
                              </svg>
                            </div>
                          </div>
                        </CardHeader>
                        <CardBody
                          style={{ cursor: "pointer" }}
                          // className={`${element.logAvaliable &&'p-2'}`}
                          onClick={() => SequenceCRUD(element, "UPDATE")}
                          className={styles.cardbody}
                        >
                          <div
                          // className={`${element.logAvaliable &&'pt-1'}`}
                          >
                            <Row>
                              <Col md={6}>
                                <FontAwesomeIcon
                                  icon={faCalendar}
                                  className="text-success mr-1 ml-0"
                                ></FontAwesomeIcon>
                                {element.createdAt
                                  ? moment(element.createdAt).format(
                                      "DD MMM YYYY"
                                    )
                                  : "-"}
                              </Col>
                              <Col
                                md={6}
                                className="d-flex justify-content-end"
                              >
                                {/* {
                              firstLetter && (
                                <TooltipReference
                                  placement="right"
                                  content={matchingMember.name}
                                  tooltipId='memerName'
                                >
                                  <div
                                    className="d-flex justify-content-center align-items-center"
                                    style={{
                                      fontSize: '10px',
                                      color: 'white',
                                      background: '#00B5D8',
                                      borderRadius: "50%",
                                      padding: '2px 10px',
                                      width: '35%'
                                    }}
                                  >
                                    {firstLetter}
                                  </div>
                                </TooltipReference>
                              )
                            } */}
                                {memberFind(element.sequenceCreatedBy) ? (
                                  <Avatar
                                    fgColor="#4c5a80"
                                    name={
                                      memberFind(element.sequenceCreatedBy)
                                        ?.name
                                    }
                                    round
                                    size="23"
                                    textSizeRatio={2.5}
                                  />
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Row>
                          </div>
                          {/* <div className={styles.logStatus}> */}
                          <Row className={`pt-3 align-items-center`}>
                            <Col
                              className={`${styles.status} ${element.status}`}
                            >
                              {element.status === "Publish"
                                ? "Published"
                                : element.status}
                            </Col>
                            <Col className={styles.status}>
                              {element.totalSteps <= 1
                                ? element.totalSteps + " step"
                                : element.totalSteps + " steps"}
                            </Col>
                            <Col>
                              <div className={styles.logs}>
                                {element.logAvaliable && (
                                  <>
                                    <Button
                                      className="sendButton"
                                      onClick={(event) => {
                                        event.stopPropagation(); // This stops the event from propagating to parent elements
                                        SequenceCRUD(element, "LOGS");
                                      }}
                                    >
                                      Logs
                                    </Button>
                                  </>
                                )}
                              </div>
                            </Col>
                          </Row>
                          {/* </div> */}
                        </CardBody>
                      </Card>
                    </Col>
                  </>
                );
              })
            ) : (
              <div className={`centerItemsWithWidth ${styles.noSequenceFound}`}>
                {/* <div>
                  <FontAwesomeIcon icon={faSort} />
                </div>
                <div className="pl-2">No Sequences Found</div> */}
                <ErrorImage/>
              </div>
            )}
          </>
        )}
      </Row>
    </Container>
  );
};

export default Sequence;

import React, { useState, useCallback, useRef } from "react";
import {
  DeleteCommunity,
  getAllCommunity,
} from "../../services/campaignService";
import { useSelector } from "react-redux";
import TooltipReference from "../../common/tooltip";
import { sliceText } from "../../common/sliceText";
import { Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { FormikHelpers } from "formik";
import moment from "moment";
import './../Campaign/Campaign.scss'
import BizTable from "../../common/BizTable";
import SearchInput from "../../common/searchComponent";


type Props = {};

const Exist = (props: Props) => {
  const [communityData, setCommunityData] = useState([]);
  const navigate = useNavigate()

  const [multipleCommunity, setMultipleCommunity] = useState<string[]>([]);
  const [newCommunity, setCommunity] = useState<string | undefined>("");
  // const [community_uid, setCommunityUid] = useState<string[]>([]);
  //   const [community_uid, setCommunityUid] = useState<Contact[]>([]);
  const [checkboxSelected, setCheckboxSelected] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState<string | null>();
  const [paginationPaload, setPaginationPayload] = useState({
    // page: 0,
    // limit: 10,
    // search: null,
    pageIndex: 0,
    pageSize: 10,
    searchText: searchTerm,
    currentPage: 0
  })

  const [communityCount, setCommunityCount] = useState(0);

  const currentPage = useRef(0);
  const paginationLimit = useRef(10);

  const memberData = useSelector((state: any) => state.cartreducer.memberData);
  const channelData = useSelector((state: any) => state.cartreducer.channelUid);



  const columns = [
    {
      Header: (
        <input
          type="checkbox"
          hidden={true}
        // checked={this.state.allChecked}
        // onChange={this.toggleAllCheckboxes}
        />
      ),
      accessor: "checkbox",
      // Cell: this.checkboxCellRenderer,
      sticky: "left",
    },
    {
      Header: "Community Name",
      accessor: "name",
      sticky: "left",
      Cell: ({ cell, row }: any) => communityName(cell.value, row.original),
    },
    {
      Header: "Created on",
      accessor: "createdAt",
      Cell: ({ cell, row }: any) => formatCreatedOn(cell.value),
      grid: "third-column",
    },
    {
      Header: "Created by",
      accessor: "memberName",
      Cell: ({ cell, row }: any) => createdBy(cell.value, row.original),
      grid: "third-column",
    },
    {
      Header: "Action",
      accessor: "actions",
      Cell: ({ cell, row }: any) => renderEditColumn(cell.value, row.original),
      grid: "col-md-1",
    },
  ] as any;

  const totalPages = Math.ceil(communityCount / paginationLimit.current);

  const fetchData = useCallback(async (
    pageIndex: number,
    pageSize: number,
    searchText: string,
    currentPage: number
  ): Promise<{ data: any; totalPages: number }> => {
    // setPaginationPayload({
    //   pageIndex: pageIndex,
    //   pageSize:  pageSize,
    //   searchText: searchTerm,
    //   currentPage: currentPage
    // })
    const channelId = channelData?.value;
    if (channelId) {
      const communityPayload = {
        page: searchTerm ? 0 : currentPage,
        limit: pageSize,
        search: searchTerm,
      }
      const community = await getAllCommunity(channelId, communityPayload);
      if (community) {
        const { communities, count } = community;
        const countData = searchTerm ? communities?.length : count;
        setCommunityCount(countData);
        const communityData = communities.filter((memberId: any) => !memberId.deleted);
        setCommunityData(communityData);
        return { data: communityData, totalPages: Math.ceil(countData / pageSize) };
      }
    }
    return { data: [], totalPages: 0 };
  }, [channelData, searchTerm]);

  // useEffect(()=>{
  //   if(paginationPaload){
  //     const { pageIndex, pageSize, searchText, currentPage } = paginationPaload;
  //     fetchData(pageIndex, pageSize, searchText, currentPage)
  //   }
  //   // communityList()
  // },[channelData])


  // const handleRowSelect = (row: any, isSelected: any) => {
  // if (isSelected) {
  //   setCommunityUid((prevRow: any) => [...prevRow, row]);
  //   const contacts = row.contacts;
  //   setMultipleCommunity((prevMultipleCommunity) => {
  //     const uniqueContacts = contacts.filter((contact: any) => {
  //       return !prevMultipleCommunity.some(
  //         (existingContact: any) => existingContact.id === contact.id
  //       );
  //     });
  //     return [...prevMultipleCommunity, ...uniqueContacts];
  //   });
  //   setCheckboxSelected([...checkboxSelected, row?.id] as any);
  // } else {
  //   setCommunityUid((prevRow: any) => {
  //     const updatedCommunityUid = prevRow.filter(
  //       (item: any) => item.id !== row.id
  //     );
  //     return updatedCommunityUid;
  //   });
  //   setMultipleCommunity((prevMultipleCommunity) =>
  //     prevMultipleCommunity.filter(
  //       (contact) => !row.contacts.includes(contact)
  //     )
  //   );
  //   setCheckboxSelected((prevRow: any) =>
  //     prevRow.filter((item: any) => item !== row.id)
  //   );
  // }
  // };

  // const handleRowSelectAll = (isSelected: boolean, rows: any[]) => {
  // if (isSelected) {
  //   const allRowIds = rows.map(row => row.id) as any;
  //   const allContacts = rows.reduce((acc: any[], row: any) => [...acc, ...row.contacts], []);
  //   const uniqueContacts = allContacts.filter((contact, index, self) =>
  //     index === self.findIndex((t) => t.id === contact.id)
  //   );

  //   setCommunityUid(rows);
  //   setMultipleCommunity(uniqueContacts);
  //   setCheckboxSelected(allRowIds) ;
  // } else {
  //   setCommunityUid([]);
  //   setMultipleCommunity([]);
  //   setCheckboxSelected([]);
  // }
  //   return true;
  // };

  //   useEffect(() => {
  //     communityList();
  //   }, []);

  //   if (loading) {
  //     return <Loader />;
  //   }



  const renderEditColumn = (cell: any, rowData: object) => {
    const handleDeleteClick = async () => {
      const response = await DeleteCommunity(rowData as any);
      if (response === "SUCCESS") {
        fetchData(0, 20, '', 0);
      }
    };

    const handleEditClick = async () => {
      if (rowData) {
        const { uid, name } = rowData as any;
        if (name) {
          navigate(`${uid}`, { state: { name } });
        }
      }
    };

    return (
      <div className="d-flex justify-content-center ">
        <svg
          width="16"
          height="16"
          className="me-3"
          onClick={handleEditClick}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.5 3H3.22222C2.89807 3 2.58719 3.12877 2.35798 3.35798C2.12877 3.58719 2 3.89807 2 4.22222V12.7778C2 13.1019 2.12877 13.4128 2.35798 13.642C2.58719 13.8712 2.89807 14 3.22222 14H11.7778C12.1019 14 12.4128 13.8712 12.642 13.642C12.8712 13.4128 13 13.1019 13 12.7778V8.5"
            stroke="#666E7D"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.9485 2.35199C12.1738 2.12661 12.4795 2 12.7982 2C13.117 2 13.4226 2.12661 13.648 2.35199C13.8734 2.57737 14 2.88304 14 3.20177C14 3.5205 13.8734 3.82617 13.648 4.05155L8.26608 9.43348L6 10L6.56652 7.73392L11.9485 2.35199Z"
            stroke="#666E7D"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <svg
          width="16"
          height="16"
          onClick={handleDeleteClick}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 4H3.33333H14"
            stroke="#666E7D"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.6673 4.00016V13.3335C12.6673 13.6871 12.5268 14.0263 12.2768 14.2763C12.0267 14.5264 11.6876 14.6668 11.334 14.6668H4.66732C4.3137 14.6668 3.97456 14.5264 3.72451 14.2763C3.47446 14.0263 3.33398 13.6871 3.33398 13.3335V4.00016M5.33398 4.00016V2.66683C5.33398 2.31321 5.47446 1.97407 5.72451 1.72402C5.97456 1.47397 6.3137 1.3335 6.66732 1.3335H9.33398C9.68761 1.3335 10.0267 1.47397 10.2768 1.72402C10.5268 1.97407 10.6673 2.31321 10.6673 2.66683V4.00016"
            stroke="#666E7D"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.66602 7.3335V11.3335"
            stroke="#666E7D"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.33398 7.3335V11.3335"
            stroke="#666E7D"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  };

  // const selectRowProp: SelectRow = {
  // mode: "checkbox",
  // clickToSelect: false,
  // clickToSelectAndEditCell: false,
  // clickToExpand: false,
  // selected: checkboxSelected,
  // onSelect: handleRowSelect,
  // onSelectAll: handleRowSelectAll,
  // };

  const create_community_field = (e: any) => {
    const { value } = e.target;
    // setCommunity(value);
  };

  const clearSelect = () => {
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      'input[type="checkbox"]'
    );
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        checkbox.click();
      }
    });
  };

  const handleSubmit = (values: any, { setSubmitting }: FormikHelpers<any>) => {
    //   if (community_uid) {
    //   if(props.selectedContactData && checkboxSelected?.length === 0){
    //     showAlert('error','Atleast one contact should be selected')
    // }
    // else{
    //   const { communitySelect, selectedContact } = props;
    //   if (communitySelect && selectedContact) {
    //     if (props.selectedContactData) {
    //       const communityId = community_uid.map((item: any) => {
    //         const matchingContact = props.selectedContactData.find((contact: any) => contact.id === item.id);

    //         if (matchingContact) {
    //           return {
    //             ...item,
    //             ...matchingContact,
    //           };
    //         } else {
    //           toast("success", 'Community updated successfully');
    //           return item;
    //         }

    //       });
    //       selectedContact(communityId);
    //     }
    //     else{
    //       selectedContact(community_uid);
    //     }
    //     communitySelect("");
    //   }
    //   toast("success", 'Community updated successfully');
    // }
    // }

    setSubmitting(false);
  };

  const formatCreatedOn = (date: string) => {
    return <span>{moment(date).format("DD MMM YYYY")}</span>;
  };

  const createdBy = (cell: any, row: any) => {
    return <div><span className="">{channelData?.label}</span> </div>;
  };

  const communityName = (cell: any, row: any) => {
    return (
      <div>
        {cell?.length > 11 ? (
          <Row>
            {/* <Col md="4"></Col> */}
            <Col md="1" className="p-0 ">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="23"
                  fill="#dee2e6"
                  className="bi bi-person-circle"
                  viewBox="0 0 16 16"
                >
                  {" "}
                  <path
                    d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
                    fill="#dee2e6"
                  ></path>{" "}
                  <path
                    fill-rule="evenodd"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                    fill="#dee2e6"
                  ></path>{" "}
                </svg>
              </div>
              {"  "}
              <div className="bi-person-circle-count"> {row?.contactCount > 99 ? 
               <TooltipReference
               placement="right"
               content={row?.contactCount}
               tooltipId={`countsData${row.id}`}
             >
              99+
             </TooltipReference>
               : row?.contactCount}</div>
            </Col>
            <Col md="5" className="ps-4">
              <TooltipReference
                placement="right"
                content={cell}
                tooltipId={`nameData_${row.id}`}
              >
                {sliceText(cell, 12)}
              </TooltipReference>
            </Col>
          </Row>
        ) : (
          <Row>
            {/* <Col md="4"></Col> */}
            <Col md="1" className="p-0">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="23"
                  fill="#dee2e6"
                  className="bi bi-person-circle"
                  viewBox="0 0 16 16"
                >
                  {" "}
                  <path
                    d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"
                    fill="#dee2e6"
                  ></path>{" "}
                  <path
                    fill-rule="evenodd"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                    fill="#dee2e6"
                  ></path>{" "}
                </svg>
              </div>
              <div className="bi-person-circle-count">{row?.contactCount}</div>
            </Col>
            <Col md="5" className="ps-4">
              {sliceText(cell, 12)}
              {/* <FontAwesomeIcon icon={faUser}  style={{color:'#dee2e6'}} className="pe-1" /> */}
            </Col>
          </Row>
        )}
      </div>
    );
  };

  const handleKeyDown = (event: any) => {
    const { value } = event.target;
    if (event.key === " " && value.trim() === "") {
      event.preventDefault();
    }
  };
  const options = {
    headerAlign: "center",
    noDataText: "No records found",
    sortIndicator: true,
    hidePageListOnlyOnePage: true,
    clearSearch: false,
    alwaysShowAllBtns: true,
    withFirstAndLast: true,
    sizePerPage: 10,
    sizePerPageList: [10, 25, 50, 100, { text: 'All', value: communityData.length }],
    paginationPosition: "bottom", // default is bottom, top and both is all available
  };

  const onSearchChange = (searchTerm: string) => {
    const searchTermCheck = searchTerm.length > 0 ? searchTerm : null;
    setSearchTerm(searchTermCheck);
  };

  return (
    <div>
      {/* {loading ? (
    <div>
      <Loader />
    </div>
    ):(
    <> */}
      <Row className="pt-2">
        <Col md={3}>
          <SearchInput component="company" onSearchChange={onSearchChange}
            placeHolder="Search Community"
          />
        </Col>
      </Row>
      <Row className="selected-contacts pt-2 pb-2" >
        <Col>

          Community :{" "}
          <span>
            {communityCount}{" "}
            Communities{" "}
          </span>
        </Col>
      </Row>
      <BizTable
        columns={columns}
        fetchData={fetchData}
        counts={communityCount}
        refetchData={communityData}
        tableName='EXIST'
      />
      {/* <BootstrapTable
      data={communityData}
      // selectRow={selectRowProp}
      search
      pagination={communityData.length > 10}
      version="4"
      striped
      hover
      options={options as any}
      containerStyle={{ textAlign: "left" }}
    >
      <TableHeaderColumn dataField="id" isKey hidden>
        #
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="name"
        dataAlign="center"
        dataFormat={communityName}
      >
        Community name
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="createdAt"
        dataAlign="center"
        dataFormat={formatCreatedOn}
      >
        Created on
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="memberName"
        dataAlign="center"
        dataFormat={createdBy}
      >
        Created by
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="edit"
        dataFormat={renderEditColumn}
        dataAlign="center"
      >
        Actions
      </TableHeaderColumn>
    </BootstrapTable> */}

      {/* </>)} */}

    </div>
  );
}

export default Exist;

import React, { useEffect, useState } from "react";
import { Form, Offcanvas, Button, FormControl } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { MdAdd } from "react-icons/md";
import { PiMinusCircleFill } from "react-icons/pi";
import { useFormikContext } from "formik";

import styles from "./updateConversationTag.module.scss";
import { IEditorProps } from "../types";
import { useElmentEditor } from "../hooks";
import EditorCaption from "../editorCaption";
import { uuidv4 } from "../../../../utils/uuid";
import { updateContactFields } from "../../../../icons";
import { SelectMember } from "../assignConversation";
import { ContactTagsInput, IContactTag } from "./updateConversationTag";
import { Label } from "reactstrap";

type IContactUpdateType = "update_contactName" | "update_contactEmail" | "update_contactOwner" | "update_contactTags" | "update_contactMarketOptIn";
type IActionState = "append" | "replace" | "addIfEmpty" | "remove";

interface FormData {
    contactUpdateType: IContactUpdateType;
    contact: {
        name: null | string;
        email: null | string;
        memberUid: null | string;
        tags: null | IContactTag[];
        optState: null | boolean;
    }
    actionStatus: null | IActionState;
};


function UpdateContactFieldsEditor(props: IEditorProps) {
    const [formData, setFormData] = useState<FormData>({
        contactUpdateType: "update_contactName",
        contact: {
            name: "",
            email: null,
            memberUid: null,
            tags: null,
            optState: null
        },
        actionStatus: null
    });
    const [contactUpdateType, setContactUpdateType] = useState<IContactUpdateType>("update_contactName");
    const { init, saveElementChanges } = useElmentEditor({
        type: "actions/update_contact",
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);

    const tagScheme = yup.object().shape({
        id: yup.string(),
        name: yup.string().required("Tag is required")
    });
    const schema = yup.object().shape({
        contactUpdateType: yup
            .string()
            .required("Update type is required")
            .oneOf(["update_contactName", "update_contactEmail", "update_contactOwner", "update_contactTags", "update_contactMarketOptIn"], "Update type is not valid"),
        contact: yup.object().shape({
            name: contactUpdateType === "update_contactName" ? yup.string().required("Contact Name is required") :
                yup.string().nullable(),
            email: contactUpdateType === "update_contactEmail" ? yup.string().required("Contact Email is required").matches(/^(?:{{.*}}|[^{}]*@(?:gmail\.com|\.in|\.edu|\.org)$)/, "Invalid format").trim() :
                yup.string().nullable(),
            memberUid: contactUpdateType === "update_contactOwner" ? yup.string().required("Contact Owner is required") :
                yup.string().nullable(),
            tags: contactUpdateType === "update_contactTags" ? yup.array()
                .of(tagScheme)
                .min(1, "At least one tag is required")
                : yup.array().of(tagScheme).nullable()
        })
    });

    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, handleChange, values, touched, errors, setValues, setFieldValue }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);
                useEffect(() => {
                    switch (values.contactUpdateType) {
                        case "update_contactName":
                            setFieldValue("contact", {
                                name: "",
                                email: null,
                                memberUid: null,
                                tags: null,
                                optState: null
                            });
                            setFieldValue("actionStatus", null);
                            break;
                        case "update_contactEmail":
                            setFieldValue("contact", {
                                name: null,
                                email: formData.contact.email? formData.contact.email : "",
                                memberUid: null,
                                tags: null,
                                optState: null
                            });
                            setFieldValue("actionStatus", null);
                            break;
                        case "update_contactTags":
                            setFieldValue("contact", {
                                name: null,
                                email: null,
                                memberUid: null,
                                tags: formData.contact.tags ? formData.contact.tags : [],
                                optState: null
                            });
                            setFieldValue("actionStatus", "append");
                            break;
                        case "update_contactMarketOptIn":
                            setFieldValue("contact", {
                                name: null,
                                email: null,
                                memberUid: null,
                                tags: null,
                                optState: formData.contact.optState !== null ? formData.contact.optState : true
                            });
                            setFieldValue("actionStatus", null);
                            break;
                        case "update_contactOwner":
                            setFieldValue("contact", {
                                name: null,
                                email: null,
                                memberUid: formData.contact.memberUid ? formData.contact.memberUid : "",
                                tags: null,
                                optState: null
                            });
                            setFieldValue("actionStatus", null);
                            break;
                        default:
                            break;
                    }                    
                    setContactUpdateType(values.contactUpdateType);
                }, [values.contactUpdateType])
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption="Update Contact Fields" icon={<img src={updateContactFields} alt="" width={16} />} />
                        <Offcanvas.Body>
                            <Form.Group className="mb-3">
                                <Form.Label>Field name <span className="required"></span></Form.Label>
                                <Form.Select
                                    name="contactUpdateType"
                                    value={values.contactUpdateType}
                                    isInvalid={(touched.contactUpdateType && errors.contactUpdateType) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched.contactUpdateType && !errors.contactUpdateType}
                                >
                                    <option value="update_contactName">Update Contact Name</option>
                                    <option value="update_contactEmail">Update Contact Email</option>
                                    <option value="update_contactOwner">Update Contact Contact Owner</option>
                                    {/* <option value="update_contactTags">Update Contact Tags</option> */}
                                    <option value="update_contactMarketOptIn">Update Contact Marketing Opt In</option>
                                </Form.Select>
                            </Form.Group>
                            {values.contactUpdateType === "update_contactName" && values.contact.name !== null ? (
                                <>
                                   <Label>Contact Name<span className="required"></span></Label>
                                    <FormControl
                                        placeholder="Enter the contact name here"
                                        name="contact.name"
                                        as="input"
                                        value={values.contact.name}
                                        isInvalid={(touched.contact?.name && errors.contact?.name) ? true : false}
                                        onChange={handleChange}
                                        isValid={touched.contact?.name && !errors.contact?.name}
                                    />
                                    {(touched.contact?.name && errors.contact?.name) ? (
                                        <div className="invalid-feedback" style={{ display: "block" }}>
                                            {errors.contact?.name}
                                        </div>
                                    ) : null}
                                </>
                            ) : null}
                            {values.contactUpdateType === "update_contactEmail" && values.contact.email !== null ? (
                                <>
                                    <Form.Label>Contact Email <span className="required"></span></Form.Label>
                                    <FormControl
                                        name="contact.email"
                                        placeholder="Enter the email name here"
                                        as="input"
                                        value={values.contact.email}
                                        isInvalid={(touched.contact?.email && errors.contact?.email) ? true : false}
                                        onChange={handleChange}
                                        isValid={touched.contact?.email && !errors.contact?.email}
                                    />
                                    {(touched.contact?.email && errors.contact?.email) ? (
                                        <div className="invalid-feedback" style={{ display: "block" }}>
                                            {errors.contact?.email}
                                        </div>
                                    ) : null}
                                </>
                            ) : null}
                            {values.contactUpdateType === "update_contactOwner" && values.contact.memberUid !== null ? (
                                <>
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Contact Owner <span className="required"></span></Form.Label>
                                        <SelectMember
                                            field="uid"
                                            value={values.contact.memberUid || ''}
                                            setValue={(memberUid: string) => {
                                                setFieldValue('contact.memberUid', memberUid);
                                            }}
                                            isInvalid={(touched.contact?.memberUid && errors.contact?.memberUid) ? true : false}
                                            isValid={touched.contact?.memberUid && !errors.contact?.memberUid}
                                        />
                                    </Form.Group>
                                    {(touched.contact?.memberUid && errors.contact?.memberUid) ? (
                                        <div className="invalid-feedback" style={{ display: "block" }}>
                                            {errors.contact?.memberUid}
                                        </div>
                                    ) : null}
                                </>
                            ) : null}
                            {values.contactUpdateType === "update_contactMarketOptIn" && values.contact.optState !== null ? (
                                <Form.Group className='mb-3'>
                                    <Form.Label>Marketing Opt In <span className="required"></span></Form.Label>
                                    <Form.Select
                                        value={values.contact.optState ? "YES" : "NO"}
                                        isInvalid={(touched.actionStatus && errors.actionStatus) ? true : false}
                                        onChange={(e) => {
                                            setFieldValue("contact.optState", e.target.value === "YES");
                                        }}
                                        isValid={touched.actionStatus && !errors.actionStatus}
                                    >
                                        <option value="YES">Yes</option>
                                        <option value="NO">No</option>
                                    </Form.Select>
                                    {(touched.contact?.optState && errors.contact?.optState) ? (
                                        <div className="invalid-feedback" style={{ display: "block" }}>
                                            {errors.contact?.optState}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            ) : null}
                            {/* {values.contactUpdateType === "update_contactTags" && Array.isArray(values.contact.tags) && values.actionStatus !== null ? (
                                <>
                                    <ContactTagsInput
                                        value={values.contact.tags}
                                        error={errors.contact?.tags as undefined | { value: string }[]}
                                        touched={touched.contact?.tags as undefined | { value: boolean }[]}
                                    />
                                    {(touched.contact?.tags && errors.contact?.tags && !Array.isArray(errors.contact?.tags)) ? (
                                        <div className='invalid-feedback' style={{ display: 'block' }}>
                                            {errors.contact?.tags}
                                        </div>
                                    ) : null}
                                    <Form.Group className="mb-3">
                                        <Form.Label>Operation <span className="required"></span></Form.Label>
                                        <Form.Select
                                            name="actionStatus"
                                            value={values.actionStatus}
                                            isInvalid={(touched.actionStatus && errors.actionStatus) ? true : false}
                                            onChange={handleChange}
                                            isValid={touched.actionStatus && !errors.actionStatus}
                                        >
                                            <option value="append">Append</option>
                                            <option value="replace">Replace</option>
                                            <option value="addIfEmpty">Add if empty</option>
                                            <option value="remove">Remove</option>
                                        </Form.Select>
                                        {(touched.actionStatus && errors.actionStatus) ? (
                                            <div className="invalid-feedback" style={{ display: "block" }}>
                                                {errors.actionStatus}
                                            </div>
                                        ) : null}
                                    </Form.Group>
                                </>
                            ) : null} */}
                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant="outline-dark" onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type="submit">
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}
export default UpdateContactFieldsEditor;
import reportWebVitals from './reportWebVitals';
import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Auth from "./Authentication/auth";
import "./index.scss";
import { Provider } from "react-redux";
import Store from "./redux/store/store";
import TemplateList from './components/Template/templateList';
import Contacts from './components/Contacts/RootContact';
import Members from './components/MemberPage/Members';
import WhatsappLink from './whatsapp/WhatsappLink';
import QuickReply from './components/QuickReply/QuickReply';
import Tag from './components/Tag/tag'
import Assignment from './components/assignment/assignment';
// import WhatsappLink from './whatsapp/WhatsappLink';
import Profile from './components/Profile/Profile';
// import ContactEdit from './Authentication/Contacts/AddEdit';
import Conversation from './components/Conversations/rootConversation';
import WhatsAppMarket from './components/Widget/WhatsAppChatMarket';
import WhatsAppChannel from './components/WhatsAppChannel/WhatsAppChannel';
import { DataProvider } from './contexts/tempContext';
import AuthenticatedRoutes from './routeAuthentication';
import { UserLoginProvider } from './contexts/userLoginContext';
import CreditIndex from './components/PaymentGateway/CreditIndex';
import ChatGpt from './components/ChatGpt/ChatGpt';
import FacebookOnboarding from './components/facebookOnboard/fbOnboard';
import NotFoundPage from './common/NotFoundPage';
import OpenSalesConversationContextProvider from './contexts/openSalesConversation';
import SubscribePage from './common/subscribePage';
import SupportInbox from './components/supportInbox/supportInbox';
import WhatsappFlow from './components/WhatsappFlow/WhatsappFlow';
import APIKey from './components/ApiKey/ApiKey';
import ApiKeySettings from './components/ApiKey/ApiKeySettings';
import Catalogue from './components/Catalogue/Catalogue';
import CatalogueMeta from './components/Catalogue/MetaCatalogue';
import Payment from './components/Payment/payment';
import Order from './components/Order/order';
import ChangePassword from './components/ChangePassword/ChangePassword';
import ChatBackUp from './components/ChatBackUp/ChatBackUp';
import Analytics from './components/Analytics/DashBoard';
import CTWA from './components/CTWA/CTWA';
import BotFlows from './botFlows';
import Campaign from './components/Campaign/Campaign';
import DashboardV2 from './components/Welcome/DashboardV2';
import Appointments from './components/Appointments/Appointments';
import HolidayList from './components/Appointments/HolidayList/HolidayList';
import AppointmentDashboard from './components/Appointments/Dashboard/Dashboard';
import Subscription from './components/Subscription/SubscriptionPage';
import CampaignList from './components/Campaign/Campaign';
import Sequence from './components/Sequence/Sequence';
import CreateSequenece from './components/Sequence/CreateSequenece';
import SequenceLogs from './components/Sequence/SequenceLogs';
import Community from './components/Contacts/Community';
import EditCommunity from './components/Contacts/EditCommunity';
import Companies from './components/Contacts/Companies';
import ImportCompanies from './components/Contacts/ImportCompanies';
import ActivityLogs from './components/ActivityLogs/ActivityLogs';
import ContactsForCompany from './components/Contacts/Contacts';
import Welcome from './components/Welcome/welcome';
import TicketingList from './components/Ticketing/ticketingList';
import TicketFields from './components/Ticketing/TicketFields/ticketFields';
import SLA from './components/Ticketing/SLA/RootSLA';
import TicketsDashboard from './components/Ticketing/Dashboard/index';

import CreateTicket from './components/Ticketing/createTicket';
import ConversationReport from './components/Conversations/conversationReport';
import ViewTicket from './components/Ticketing/ViewTicketV2';
import TicketConfiguration from './components/Ticketing/TicketConfiguration';
import Integration from './components/Integrations';
import IntegrationDetails from './components/Integrations/Details';
import FieldForceTracking from './components/geofenceTracking/geofenceTrack';
import FieldForceReport from './components/geofenceReport/geoReport';
// import ViewTicket from './components/Ticketing/ViewTicket/ViewTicket';
import OTP from './components/OTP/OTP';
import TemplateAnalytics from './components/Template/TemplateAnalytics';
import RAG  from './components/RAG/RAGKnowledge';
import FolderDetails from './components/RAG/FolderDetails';

// const CampaignList = React.lazy(() => import("./components/Campaign/Campaign"));
const App = React.lazy(() => import("../src/shade/layouts/App"));
const Switcherapp = React.lazy(() => import("../src/shade/layouts/Switcherapp"));
const Layout = React.lazy(() => import("./Authentication/layout"));
// const Welcome = React.lazy(() => import("./components/Welcome/welcome"));
// const DashboardV2 = React.lazy(() => import("./components/Welcome/DashboardV2"));
// const Campaign = React.lazy(() => import("./components/Campaign/Campaign"));
// const BotFlows = React.lazy(() => import('./botFlows'));
// const CTWA = React.lazy(() => import('./components/CTWA/CTWA'));
// const Subscription = React.lazy(() => import('./components/Subscription/SubscriptionPage'));
// const Appointments = React.lazy(() => import('./components/Appointments/Appointments'));
// const HolidayList = React.lazy(() => import('./components/Appointments/HolidayList/HolidayList'));
// const AppointmentDashboard = React.lazy(() => import('./components/Appointments/Dashboard/Dashboard'));

// const Integrations = React.lazy(() => import('./components/Integrations/IntegrationsRoot'));
// const Sequence = React.lazy(() => import('./components/Sequence/Sequence'));
// const CreateSequenece = React.lazy(() => import('./components/Sequence/CreateSequenece'));
// const SequenceLogs = React.lazy(() => import('./components/Sequence/SequenceLogs'));
// const Community = React.lazy(() => import('./components/Contacts/Community'));
// const EditCommunity = React.lazy(() => import('./components/Contacts/EditCommunity'));
// const Companies = React.lazy(() => import('./components/Contacts/Companies'));
// const ImportCompanies = React.lazy(() => import('./components/Contacts/ImportCompanies'));
// const ActivityLogs = React.lazy(() => import('./components/ActivityLogs/ActivityLogs'));
// const ContactsForCompany = React.lazy(() => import('./components/Contacts/Contacts'));

//Form
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

root.render(
  <QueryClientProvider client={queryClient}>
    <UserLoginProvider>
      <DataProvider>
        <OpenSalesConversationContextProvider>

          <BrowserRouter>
            <React.Suspense >
              <Routes>
                <Route path={`${process.env.PUBLIC_URL}/`} element={<Provider store={Store}><Auth /></Provider>}>
                  <Route
                    path="/"
                    element={
                      <AuthenticatedRoutes>
                        <App />
                      </AuthenticatedRoutes>
                    }
                  />
                  {/* <Route index element={<Layout component='login' />} /> */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/login`}
                    element={<Layout component='login' />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/forgotpassword`}
                    element={<Layout component='forgotpassword' />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/signup`}
                    element={<Layout component='register' />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/"id": 1004,
              "uid": "dab176cb-a89e-3556-93ae-ff435254b76f",
              "name": "open Note",
              "webSite": "https://open-note.com",
              "category": null,
              "email": "arunkumarxyz.vamosys@gmail.com",
              "mobileNumber": "8248092273",
              "timeZone": null,
              "industry": null,
              "employees": null,
              "createdAt": "2023-05-30T07:07:01.175+00:00",
              "updatedAt": "2023-05-30T12:45:37.890+00:00"`}
                    element={<Layout component='register' />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/invite`}
                    element={<Layout component='invite' />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/ResetPassword`}
                    element={<Layout component='resetPassword' />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/layout`}
                    element={<Layout component='resetPassword' />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/mobilelogin`}
                    element={<Layout component='mobilelogin' />}
                  />
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/`} element={<Provider store={Store}><App /></Provider>}>
                  <Route
                    path={`${process.env.PUBLIC_URL}/whatsappTemplate`}
                    element={<TemplateList setSelectTemplateId={function (templateId: string): string {
                      throw new Error('Function not implemented.');
                    }} setRemainderTemplateId={function (templateId: string): string {
                      throw new Error('Function not implemented.');
                    }} />}
                  />
                  {/* <Route
                    path={`${process.env.PUBLIC_URL}/whatsappTemplate/:templateId`}
                    element={<TemplateAnalytics />}
                  /> */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/whatsappTemplate/:templateId/:templateName`}
                    element={<TemplateAnalytics />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/whatsappFlow`}
                    element={<WhatsappFlow />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/settings/QuickReply`}
                    element={<QuickReply />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/settings/QuickReply`}
                    element={<QuickReply />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/settings/tag`}
                    element={<Tag />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/settings/assignment`}
                    element={<Assignment />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/settings/chatbackup`}
                    element={<ChatBackUp />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/Catalogue`}
                    element={<Catalogue />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/metacatalogue`}
                    element={<CatalogueMeta />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/Payment`}
                    element={<Payment />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/Order`}
                    element={<Order />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/ApiKey`}
                    element={<APIKey />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/ApiKey/:apiKey`}
                    element={<ApiKeySettings />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/OTP`}
                    element={<OTP />}
                  />
                  {/* <Route>
              <Route
                path={`${process.env.PUBLIC_URL}/whatsapp/WhatsappLink`}
                element={<WhatsappLink />}
              />
            </Route> */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/whatsapp/WhatsappLink`}
                    element={<WhatsappLink />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/common/subscribePage`}
                    element={<SubscribePage />}
                  />
                  {/* <Route> */}
                  {/* <Route
                path={`${process.env.PUBLIC_URL}/Whatsapp/WhatsappLink`}
                element={<WhatsappLink />}
              /> */}
                  {/* </Route> */}
                  <Route
                    path={`${process.env.PUBLIC_URL}/contacts/*`}
                    element={<Contacts />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/companies/:companyId`}
                    element={<ContactsForCompany />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/community/*`}
                    element={<Community />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/community/:communityId`}
                    element={<EditCommunity />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/companies/*`}
                    element={<Companies />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/companies/import`}
                    element={<ImportCompanies />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/activities`}
                    element={<ActivityLogs />}
                  />
                  <Route path={`${process.env.PUBLIC_URL}/campaign`} element={<CampaignList />}>
                    <Route index element={<CampaignList />} />
                    <Route path="newcampaign" element={<Campaign />} />
                  </Route>

                  <Route path={`${process.env.PUBLIC_URL}/sequence`} element={<Sequence />} />
                  <Route path={`${process.env.PUBLIC_URL}/sequence/creatsequence`} element={<CreateSequenece />} />
                  <Route path={`${process.env.PUBLIC_URL}/sequence/:sequenceId/updatesequence`} element={<CreateSequenece />} />
                  <Route path={`${process.env.PUBLIC_URL}/sequence/:sequenceId/logs`} element={<SequenceLogs />} />


                  <Route path="*" element={<NotFoundPage />} />
                  <Route
                    path={`${process.env.PUBLIC_URL}/facebookOnboard`}
                    element={<FacebookOnboarding />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/credit`}
                    element={<CreditIndex />}
                  />
                  <Route path={`${process.env.PUBLIC_URL}/bots/*`} element={(
                    <Provider store={Store}>
                      <BotFlows />
                    </Provider>
                  )} />
                  <Route
                    path={`${process.env.PUBLIC_URL}/ctwa`}
                    element={<CTWA />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/appointment`}
                    element={<Appointments />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/appointments/dashboard/`}
                    element={<AppointmentDashboard />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/appointments/holiday-list/`}
                    element={<HolidayList />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/conversations/*`}
                    element={<Conversation />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/integrations`}
                    element={<Integration />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/RAG`}
                    element={<RAG />}
                  />
                   <Route
                    path={`${process.env.PUBLIC_URL}/RAG/:name`}
                    element={<FolderDetails/>}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/integrations/:name`}
                    element={<IntegrationDetails />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/integrations/:name/edit/:integrationUid`}
                    element={<IntegrationDetails />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/supportInbox`}
                    element={<SupportInbox />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/ticketing`}
                    element={<TicketingList />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/ticketing/dashboard`}
                    element={<TicketsDashboard />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/ticketing/viewTicket/createTicket`}
                    element={<CreateTicket />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/ticketing/viewTicket`}
                    element={<ViewTicket />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/ticketFields`}
                    element={<TicketFields />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/sla_policies/*`}
                    element={<SLA />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/configuration`}
                    element={<TicketConfiguration />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/fieldForceTracking`}
                    element={<FieldForceTracking />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/fieldForceReport`}
                    element={<FieldForceReport />}
                  />
                  <Route path={`${process.env.PUBLIC_URL}/bots/*`} element={<BotFlows />} />
                  <Route path={`${process.env.PUBLIC_URL}/settings/memberTemplate`}
                    element={<Members />}
                  />
                  <Route path={`${process.env.PUBLIC_URL}/settings/whatsappchannel`}
                    element={<WhatsAppChannel />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/settings/whatsappwidget`}
                    element={<WhatsAppMarket />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/chatgpt/`}
                    element={<ChatGpt />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/pages/switcher/switcher-1`}
                    element={<Switcherapp />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/welcome`}
                    element={<Welcome />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/dashboard`}
                    element={<DashboardV2 />}
                  />
                  <Route path={`${process.env.PUBLIC_URL}/profile`}
                    element={<Profile />}
                  />
                  <Route path={`${process.env.PUBLIC_URL}/changepassword`}
                    element={<ChangePassword />}
                  />
                  <Route path={`${process.env.PUBLIC_URL}/analytics/graph`}
                    element={<Analytics />}
                  />
                  <Route path={`${process.env.PUBLIC_URL}/analytics/conversationReport`}
                    element={
                      <ConversationReport
                        component="conversationReport"
                        handleclose={() =>
                          (window.location.href = "/analytics/dashboard")
                        }
                      />
                    }
                  />
                  {/* 
                  <Route
                    path={`${process.env.PUBLIC_URL}/ticket-fields`}
                    element={<TicketFields />}
                  /> */}

                  {/* <Route
                    path={`${process.env.PUBLIC_URL}/tickets/*`}
                    element={<Tickets />}
                  /> */}

                  <Route
                    path={`${process.env.PUBLIC_URL}/subscription/*`}
                    element={<Subscription />}
                  />
                  <Route path={`${process.env.PUBLIC_URL}/logout`}
                  />
                </Route>
                <Route></Route>
              </Routes>
            </React.Suspense>
          </BrowserRouter>
        </OpenSalesConversationContextProvider>
      </DataProvider>
    </UserLoginProvider>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

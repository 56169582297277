import { addNotes, createLead, createRecord, openAiIcon, retrieve, shopifyOrder, shopifyTag } from "../../icons"
import { IIntegrationApp } from "./integrationApp"
import ActionsField, * as actionsFields from "./actionsField"

export type IIntegrationActionListId = 'createALead'
    | 'dynamicModule'
    | 'getARecord'
    | 'createPaymentLink'
    | 'addNewRow'
    | 'replaceOrderTag'
    | 'createOrder'
    | 'createDraftOrder'
    | 'completeDraftOrder'
    | 'updateRecord'
    | 'fetchRecord'
    | 'addMetaData'
    | 'addNotes'
    | 'chatCompletionKnowledgeBase'

export type ActionsListItem = {
    id: IIntegrationActionListId,
    label: string,
    description: string,
    icon: string,
    fields: ActionsField[]
}


export const ACTION_LIST: Record<IIntegrationApp, ActionsListItem[]> = {
    'RazorPay': [
        {
            id: 'createPaymentLink',
            label: 'Create Payment Link',
            description: 'Create Payment Link',
            icon: createRecord,
            fields: [
                actionsFields.RAZOR_PAY_amount,
                actionsFields.RAZOR_PAY_currency,
                actionsFields.RAZOR_PAY_accept_partial,
                actionsFields.RAZOR_PAY_first_min_partial_amount,
                actionsFields.RAZOR_PAY_connection_id,
                actionsFields.RAZOR_PAY_payment_link,

            ]
        }
    ],
    'ZohoCRM': [
        {
            id: 'createALead',
            label: 'Create A Lead',
            description: 'Create a Lead in Zoho CRM.',
            icon: createLead,
            fields: [
                actionsFields.ZOHO_CRM_Last_name.setRequired(true),
                actionsFields.ZOHO_CRM_Company.setRequired(true),
                actionsFields.ZOHO_CRM_Description,
                actionsFields.ZOHO_CRM_First_Name,
                actionsFields.ZOHO_CRM_State,
                actionsFields.ZOHO_CRM_Email,
                actionsFields.ZOHO_CRM_Email_Opt_Out,
                actionsFields.ZOHO_CRM_Email_2,
                actionsFields.ZOHO_CRM_Mobile,
                actionsFields.ZOHO_CRM_Lead_Source,
                actionsFields.ZOHO_CRM_Lead_Status,
                actionsFields.ZOHO_CRM_Fax,
                actionsFields.ZOHO_CRM_Skype_ID,
                actionsFields.ZOHO_CRM_Twitter,
                actionsFields.ZOHO_CRM_Website,
                actionsFields.ZOHO_CRM_NO_OF_EMPLOYESS
            ]
        },
        {
            id: 'dynamicModule',
            label: 'Create Dynamic Module',
            description: 'Create a Lead, Contacts, Tasks, Events, etc.',
            icon: createRecord,
            fields: []
        },
        {
            id: 'getARecord',
            label: 'Get A Record',
            description: 'Retrieve a record of Lead, Contacts, Tasks, Events, etc.',
            icon: retrieve,
            fields: []
        },

    ],

    'GoogleSheets': [
        {
            id: 'addNewRow',
            label: 'Add New Row',
            description: 'Add a new row to sheet',
            icon: createLead,
            fields: [
                actionsFields.GoogleSheets_SpreadSheet.setRequired(true),
                actionsFields.GoogleSheets_Sheet.setRequired(true),
                actionsFields.GoogleSheets_Column.setRequired(true)
            ]
        },
        {
            id: 'updateRecord',
            label: 'Update Existing Row',
            description: 'Update an existing row',
            icon: retrieve,
            fields: [
                actionsFields.GoogleSheets_SpreadSheet.setRequired(true),
                actionsFields.GoogleSheets_Sheet.setRequired(true),
                actionsFields.GoogleSheets_Column.setRequired(true)
            ]
        },
        {
            id: 'fetchRecord',
            label: 'Fetch Row',
            description: 'Fetch an existing row',
            icon: retrieve,
            fields: [
                actionsFields.GoogleSheets_SpreadSheet.setRequired(true),
                actionsFields.GoogleSheets_Sheet.setRequired(true),
                actionsFields.GoogleSheets_Column.setRequired(true)
            ]
        }
    ],
    'Shopify': [

        {
            id: 'replaceOrderTag',
            label: 'Replace Order Tag',
            description: 'Replace your order tag to different tracking of orders ',
            icon: shopifyTag,
            fields: [
                actionsFields.SHOPIFY_orderId,
                actionsFields.SHOPIFY_tags
            ]
        },
        {
            id: 'createOrder',
            label: 'Create Order',
            description: 'Create your new order ',
            icon: shopifyOrder,
            fields: [
                actionsFields.SHOPIFY_name.setRequired(true),
                actionsFields.SHOPIFY_orderTag,
                actionsFields.SHOPIFY_orderNote,
                actionsFields.SHOPIFY_sourceName,
                actionsFields.SHOPIFY_address,
                actionsFields.SHOPIFY_city,
                actionsFields.SHOPIFY_state,
                actionsFields.SHOPIFY_country,
                actionsFields.SHOPIFY_pin,
                actionsFields.SHOPIFY_shippingAddress,
                actionsFields.SHOPIFY_shippingCity,
                actionsFields.SHOPIFY_shippingState,
                actionsFields.SHOPIFY_shippingCountry,
                actionsFields.SHOPIFY_shippingPinCode

            ]
        },
        {
            id: 'createDraftOrder',
            label: 'Create Draft Order',
            description: 'Create your draft order before payment by customers',
            icon: shopifyOrder,
            fields: [
                actionsFields.SHOPIFY_draft_name,
                actionsFields.SHOPIFY_draft_phone,
                actionsFields.SHOPFY_draft_discount_amount,
                actionsFields.SHOPIFY_draft_discount_type,
                actionsFields.SHOPIFY_draft_discount_value,
                actionsFields.SHOPIFY_draft_discount_title,
                actionsFields.SHOPIFY_draft_discount_description,
                actionsFields.SHOPIFY_draft_address,
                actionsFields.SHOPIFY_draft_city,
                actionsFields.SHOPIFY_draft_state,
                actionsFields.SHOPIFY_draft_country,
                actionsFields.SHOPIFY_draft_pin,
                actionsFields.SHOPIFY_draft_shipping_address,
                actionsFields.SHOPIFY_draft_shipping_city,
                actionsFields.SHOPIFY_draft_shipping_state,
                actionsFields.SHOPIFY_draft_shipping_country,
                actionsFields.SHOPIFY_draft_shipping_pin_code,
                actionsFields.SHOPIFY_draft_order_id,
                actionsFields.SHOPIFY_draft_invoice_url,
            ]
        },
        {
            id: 'completeDraftOrder',
            label: 'Complete Draft Order',
            description: 'Complete your draft order after payment by customers',
            icon: shopifyOrder,
            fields: [
                actionsFields.SHOPIFY_complete_draft_order_id,
                actionsFields.SHOPIFY_complete_order_id,
                actionsFields.SHOPIFY_complete_draft_payment_pending
            ]
        },

    ],
    'SangamCRM': [
        {
            id: 'createALead',
            label: 'Create A Lead',
            description: 'Create a Lead in Sangam CRM.',
            icon: createLead,
            fields: [
                actionsFields.Sangam_firstName,
                actionsFields.Sangam_lastName,
                actionsFields.Sangam_designation,
                actionsFields.Sangam_department,
                actionsFields.Sangam_otherSource,
                actionsFields.Sangam_nextFollowupDate,
                actionsFields.Sangam_lostReason,
                actionsFields.Sangam_nextFollowupType,
            ]
        }
    ],
    'Woocommerce': [
        {
            id: 'addMetaData',
            label: 'Add Meta Data',
            description: 'Update meta data to your woocommerce online store',
            icon: createLead,
            fields: [
                actionsFields.WooCommerce_orderId
            ]
        },
        {
            id: 'addNotes',
            label: 'Add Notes',
            description: 'Add notes to your woocommerce online store',
            icon: addNotes,
            fields: [
                actionsFields.WooCommerce_orderId
            ]
        }
    ],
    'OpenAI' : [
        {
            id: 'chatCompletionKnowledgeBase',
            label: 'Chat Completion Knowledge Base',
            description: 'Given a prompt, the model will return one or more predicted completions, and can also return the  probabilities of alternative tokens at each position.',
            icon: openAiIcon,
            fields: [
                actionsFields.OpenAI_Context,
                actionsFields.OpenAI_UserPrompt,
                actionsFields.OpenAI_Model,
                actionsFields.OpenAI_KnowledgeBase,
                actionsFields.OpenAI_MaxTokens,
                actionsFields.OpenAI_temperature,
                actionsFields. OpenAI_variable                
            ]
        }
    ]
}
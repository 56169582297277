import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FormGroup, Label, Button } from 'reactstrap';
import * as Yup from 'yup';
import Select from 'react-select';
import { updateQr, createQr }  from '../../services/dashboardService';
import { toastAlert } from '../../common/alert';
import './welcome.scss';

interface PropsType {
values: any;
channels: any;
isopen: any;
fetchQRDataHandler: () => Promise<void>;
}
interface SelectOption {
  value: string;
  label: string;
}


const QrCode : React.FC<PropsType> = ({ values, channels, isopen, fetchQRDataHandler}) => {  
const [channelName,setChannel]= React.useState<SelectOption | null>(null);

  const initialValues = {
    name: values?.name,
    message: values[0]?.content,
    link: values[0]?.name,
  };

 const validationSchema = Yup.object({
    message: Yup.string().required('Message is required'),
    link: Yup.string().required('Link name is required'),
  })

  const handleSubmit = (value : any) => {
    if(values.length > 0){
    values[0].content = value.message;
     values[0].name = value.link;
     if(channels.value){
    updateQr(channels.value, values[0]) 
    .then(data => { 
        console.log('Response data:', data);
        isopen(false);        
        toastAlert('top-end', `Edited successfully`, 'success');
          fetchQRDataHandler();
        
    })
    .catch(error => {
        console.error('Error:', error);
    });
  }
}else {
  const payload = {
    "name": value.link,
    "content": value.message
  }
  createQr(channels.value, payload).then(data => { 
    console.log('Response data:', data);
    isopen(false);        
    toastAlert('top-end', `Created successfully`, 'success');
  })
  .catch(error => {
      console.error('Error:', error);
  });
  }
  };

  const channelOptions : SelectOption[] = [ 
    {value: channels.value ,label: channels.label}
   ]

   const handleChange = (selectedOption: SelectOption | null) => {
    setChannel(selectedOption);
  }

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: '#FFFFFF',
      border: 'none',
      boxShadow: 'none', 
      cursor: 'pointer', 
      outline: 'none',
      color: 'var(--primary-gray, #324054)',
    }),   
    indicatorSeparator: () => ({
      display: 'none', 
    }),
  };
  const linkName = values[0].link.split(`/${values[0].name}`)[0];

  return (
    <div>
   <Formik initialValues={initialValues} onSubmit={handleSubmit}  validationSchema={validationSchema} validateOnChange={true}>
  <Form className='formwidth'>
   <FormGroup>
  <Label className='text-dark'> WhatsApp Channel </Label>
  <Select
    name="channel"
    options={channelOptions.length > 1  ? channelOptions : []}
    className="mt-2 textSize textarea"
    styles={customStyles}
    value={channelName ? channelName : channelOptions[0]}
    onChange={handleChange}
  />
</FormGroup>
<FormGroup>
<Label className='text-dark'>Message</Label>
<Field name="message">
  {({ field} : any) => (
    <div>
      <textarea
        {...field}
        placeholder="Send your WhatsApp message for enquiries"
        className='textarea form-control pb-5 pt-2'
      />
    </div>
  )}
</Field>
<ErrorMessage name="message" component="div" className="text-danger pt-1" />
</FormGroup>
<FormGroup>
  <Label className='text-dark'>Customize your link</Label><br/>
  <div className='d-flex'>
  <span className='h6 mt-2 mr-2'>{linkName}</span>
  <Field
  name="link"
   placeholder="Enter link name"
   className="form-control textarea ml-1" 
  />
  </div>
  <ErrorMessage name="link" component="div" className="text-danger linkerror" />
</FormGroup>
<Button variant='' type='submit' className='signUpBtn float-right pl-5 pr-5'>Save</Button>
    </Form>
   </Formik>
    </div>
  );
};

export default QrCode;


import React, { useEffect, useState } from 'react';
import { Form, Offcanvas, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useFormikContext } from 'formik';

import styles from './delayEditor.module.css';
import { IEditorProps } from './types';
import { useElmentEditor } from './hooks';
import EditorCaption from './editorCaption';
import { delayIcon } from '../../../icons';
import ReactSelect from 'react-select';

interface FormData {
    attempt: number;
    delay: string;
};

interface DelayInputProps {
    name?: string;
    value: string;
    isInvalid: boolean | undefined;
    isValid: boolean | undefined;
    error: any;
};

export const DelayInput: React.FC<DelayInputProps> = ({ value, isInvalid, isValid, error, name }) => {
    const { setFieldValue } = useFormikContext();
    const [unit, setUnit] = useState<'seconds' | 'minutes' | 'hours' >('seconds');
    const [localValue, setLocalValue] = useState<string>(value);
    useEffect(() => {
        const hours = Number(value) / 3600000;
        let formattedValue;
        if (hours % 1 !== 0) { 
            formattedValue = hours.toFixed(2);
        } else { 
            formattedValue = hours.toString();
        }
    
        if (hours >= 1) {
            setUnit('hours');
            setLocalValue(formattedValue);
        } else { 
            const minutes = Number(value) / 60000;
            if (minutes >= 1) {
                setUnit('minutes');
            if (minutes % 1 !== 0) { 
                formattedValue = minutes.toFixed(2);
            } else { 
                formattedValue = minutes.toString();
            }
            setLocalValue(formattedValue);
            } else {
                const seconds = Number(value) / 1000;
                if (seconds >= 1) {
                    setUnit('seconds');
                if (seconds % 1 !== 0) { 
                    formattedValue = seconds.toFixed(2);
                } else { 
                    formattedValue = seconds.toString();
                }
                setLocalValue(formattedValue);
            }
            }
        }
    }, [value]);

    const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = Number(e.target.value);
        let valueInMilliseconds: number;

        if (unit === 'minutes') {
            valueInMilliseconds = inputValue * 60000;
        } else if(unit === 'hours'){
            valueInMilliseconds = inputValue * 3600000;
        } else {
            valueInMilliseconds = inputValue * 1000;
        }

        setLocalValue(inputValue.toString());
        setFieldValue(name || 'delay', valueInMilliseconds);
    }

    const handleUnitChange = (value: 'seconds' | 'minutes' | 'hours') => {
        switch (value) {
            case 'hours':
                localValue === '5' ? setFieldValue(name || 'delay', (Number('1') * 3600000).toString()) : setFieldValue(name || 'delay', (Number(localValue) * 3600000).toString());
                localValue === '5' && setLocalValue('1');
                break;
            case 'minutes':
                localValue === '1' ?  setFieldValue(name || 'delay', (Number('5') * 60000).toString()) : setFieldValue(name || 'delay', (Number(localValue) * 60000).toString());
                localValue === '1' && setLocalValue('5');
                break;
            case 'seconds':
                localValue === '30' ? setFieldValue(name || 'delay', (Number('30') * 1000).toString()) : setFieldValue(name || 'delay', (Number(localValue) * 1000).toString());
                localValue === '30' && setLocalValue('30');
                break;
            default:
                break;
        }
        setUnit(value);
    };



    interface OptionType {
        value: 'seconds' |'minutes' | 'hours';
        label: string;
    }

    const options: OptionType[] = [
        { value: 'seconds', label: 'Seconds'},
        { value: 'minutes', label: 'Minutes' },
        { value: 'hours', label: 'Hours' },
    ];

    return (
        <>
            <div className={styles.delayCotainer}>
                <ReactSelect
                    styles={{
                        menuPortal: base => ({ ...base, zIndex: 9800 }),
                        menu: base => ({ ...base, width: 200 }),
                    }}
                    className={styles.dropdownBox}
                    value={{ value: unit, label: unit.charAt(0).toUpperCase() + unit.slice(1) }}
                    options={options}
                    onChange={(selectedOption) => handleUnitChange(selectedOption?.value || 'seconds')}
                />
                <Form.Control
                    type='text'
                    className={styles.valueInput}
                    value={localValue}
                    onChange={handleValueChange}
                    isInvalid={isInvalid}
                    isValid={isValid}
                    onKeyDown={(event) => {
                        const validKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Control', 'Unidentified'];
                        if (!/[0-9]/.test(event.key) && !validKeys.includes(event.key)) {
                            event.preventDefault();
                        }
                    }}
                    maxLength={2}
                    defaultValue={5}
                />
            </div>
            {isInvalid ? (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                    {error} {unit.substring(0, unit.length - 1)}
                </div>
            ) : null}
        </>
    );
}

DelayInput.defaultProps = {
    name: 'delay'
}

function DelayEditor(props: IEditorProps) {
    const [formData, setFormData] = useState<FormData>({
        attempt: 1,
        delay: "30000"
    });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'delay',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);

    // const schema = yup.object().shape({
    //     delay: yup.string()
    //         .test('is-number', 'Invalid Delay', (value) => {
    //             if (value !== undefined && value.trim() !== '') {
    //                 return !isNaN(parseFloat(value));
    //             }    
    //             return false;
    //         })
    //         .min(1, 'Delay should be minimum 1').required('Delay is a required'),
    // });
    const schema = yup.object().shape({
        delay: yup.number()
            .typeError('Delay must be a number')
            .min(1000, 'Delay must be at least 1 second') 
            .max(24 * 60 * 60 * 1000, 'Delay cannot exceed 24 hours') 
            .required('Delay is required')
    });
    
    
    

    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, values, touched, errors, setValues }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption='Delay' icon={<img alt='' src={delayIcon} style={{ width: 35 }} />} />
                        <Offcanvas.Body>
                            <Form.Group className='mb-3'>
                                <DelayInput
                                    value={values.delay}
                                    isInvalid={(touched.delay && errors.delay) ? true : false}
                                    isValid={touched.delay && !errors.delay}
                                    error={errors?.delay}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.delay}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit'>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default DelayEditor;
import { useEffect, useState } from 'react';
import { Form, Offcanvas, Button, FormGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import { BsFillTrashFill, BsUpload } from 'react-icons/bs';
import * as yup from 'yup';
import { IEditorProps } from './types';
import { useElmentEditor } from './hooks';
import OptionsInput, { IOption } from './optionsInput';
import BodyInput from './bodyInput';
import { IFile } from './sendMediaEditor/fileUpload';
import FilePlaceholder from '../nodes/sendMedia/filePlaceholder';
import EditorCaption from './editorCaption';
import { uuidv4 } from '../../../utils/uuid';
import FileInput, { isValidLink } from './fileInput';
import { buttonIcon } from '../../../icons';
import VariableInputLabel from './variableInputLabel';
import { SelectVariable } from './setVariable';
import AdvancedSettings from './askType/advancedSettings';
import * as mediaService from '../../../services/media';
import DynamicOptionType from './dynamicOptionType';
import { IStorageVariable } from '../../../entity/Variable';

export interface FormData {
    header: {
        headerType: 'none' | 'text' | 'image' | 'video' | 'document';
    },
    media: IFile | null;
    headerText: string;
    bodyText: string;
    footerText: string;
    options: IOption[];
    attempt: number;
    mediaUrl: string;
    storageVariable: IStorageVariable<'TEXT'>;
    errorMessage: string;
    endFlow: boolean;
    dynamic: boolean;
    dynamicFields: {
        iterateVariable: IStorageVariable<'ARRAY'>,
        idPath: string,
        titlePath: string,
    } | null;
};

function toMediaType(mediaType: string): 'image' | 'video' | 'document' {
    let result: 'image' | 'video' | 'document' = 'document';
    if (mediaType.startsWith('image/')) {
        result = 'image';
    } else if (mediaType.startsWith('video/')) {
        result = 'video';
    }
    return result;
}

function SendReplyButtons(props: IEditorProps) {
    const [dynamic, setDynamic] = useState(false)
    const [formData, setFormData] = useState<FormData>({
        header: {
            headerType: 'none'
        },
        headerText: '',
        bodyText: '',
        footerText: '',
        errorMessage: '',
        options: [
            {
                uid: uuidv4(),
                id: null,
                optionText: '',
                order: 1
            }
        ],
        endFlow: true,
        media: null,
        mediaUrl: '',
        attempt: 1,
        storageVariable: {
            name: '',
            type: 'TEXT'
        },
        dynamic: false,
        dynamicFields: null
    });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'send_reply_buttons',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);

    const optionSchema = yup.object().shape({
        uid: yup.string().required(),
        optionText: yup.string().required('Button text required')
            .max(20, 'Button text should be within 20 characters long')
    });
    const storageVariableSchema = yup.object().shape({
        name: yup.string(),
    });
    const schema = yup.object().shape({
        errorMessage: yup.string().notRequired(),
        bodyText: yup.string().required('Question Text is a required field').test('is-double-space', 'Question Text is a required field', value => value !== '  '),
        headerText: yup.string().when('header.headerType', {
            is: (headerType: string) => headerType === 'text',
            then: schema => schema.required('Header is required'),
            otherwise: schema => schema.notRequired()
        }),
        footerText: yup.string(),
        attempt: yup.number().min(1, 'Attempt should be minimum 1').required('Attempt is a required'),
        options: dynamic ? yup.array().notRequired() :
            yup.array().of(optionSchema).min(1, 'At least one button is required'),
        header: yup.object().shape({
            headerType: yup.string().required('Header type is required')
        }),
        endFlow: yup.boolean().required('Action on Invalid Response required'),
        media: yup.object().nullable(),
        mediaUrl: yup.string().test('is-valid-link', () => 'Invalid media URL', function (mediaUrl) {
            const headerType = this.parent.header.headerType;
            if ((headerType !== 'none' && headerType !== 'text') && mediaUrl && mediaUrl.trim() !== '') {
                const isValid = isValidLink(this.parent.header.headerType, mediaUrl);
                return isValid;
            }
            return true;
        }),
        storageVariable: storageVariableSchema,
        dynamic: yup.boolean(),
        dynamicFields: dynamic ? yup.object().shape({
            iterateVariable: yup.object().shape({
                name: yup.string().required('Iterate Variable is required'),
            }),
            idPath: yup.string().required('Path to ID is required'),
            titlePath: yup.string().required('Path to Title is required'),
        }).required() : yup.object().nullable()
    }).test('media-or-mediaUrl', function (obj) {
        const { media, mediaUrl, header } = obj;
        if ((header.headerType !== 'none' && header.headerType !== 'text') &&
            (!media && (!mediaUrl || mediaUrl.trim() === ''))) {
            return this.createError({
                path: 'media',
                message: 'Media is required'
            });
        }
        return true;
    });

    const removeFileHandler = async (media: any) => {
        try {
            await mediaService.deleteMedia(media);
        } catch (error) {
            console.error(error);
        }
    }


    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, handleChange, values, touched, errors, setValues, setFieldValue }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);
                useEffect(() => {
                    setDynamic(values.dynamic)
                    if (values.dynamic && !values.dynamicFields) {
                        setFieldValue('dynamicFields', formData.dynamic ? formData.dynamicFields : {
                            iterateVariable: { name: '' },
                            idPath: '',
                            titlePath: ''
                        })
                    }
                }, [values.dynamic]);

                const headerTypes = [
                    { label: 'None', value: 'none' },
                    { label: 'Text', value: 'text' },
                    { label: 'Video', value: 'video' },
                    { label: 'Image', value: 'image' },
                    { label: 'Document', value: 'document' }
                ];
                let allowedFormats: string[] = [];
                if (values.header.headerType === 'image') {
                    allowedFormats = ['image/jpeg', 'image/png'];
                } else if (values.header.headerType === 'video') {
                    allowedFormats = ['video/mp4', 'video/3gpp']
                }

                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption='Ask Button Option' icon={<img style={{ width: 16 }} alt='' src={buttonIcon} />} />
                        <Offcanvas.Body>
                            <FormGroup className='mb-3'>
                                <Form.Label>Header Type <span className='required'></span>{' '} {values.header.headerType !== 'none' && values.header.headerType !== 'text' ? <span className='dimmed'>(File size allowed: {values.header.headerType === 'image' ? '5 MB' : '16 MB'})</span> : null}</Form.Label>
                                <div>
                                    {headerTypes.map(type => (
                                        <Form.Check
                                            key={type.value}
                                            inline
                                            label={type.label}
                                            name='header.headerType'
                                            type='radio'
                                            value={type.value}
                                            onChange={event => {
                                                setFieldValue('media', null);
                                                setFieldValue('mediaUrl', '');
                                                handleChange(event);
                                            }}
                                            isInvalid={touched.header?.headerType && !!errors.header?.headerType}
                                            checked={values.header.headerType === type.value}
                                        />
                                    ))}
                                </div>
                            </FormGroup>
                            {values.header.headerType !== 'none' && values.header.headerType !== 'text' ? (
                                <Form.Group className='mb-3'>
                                    {!values.media ? (
                                        <FileInput
                                            media={values.media}
                                            mediaType={values.header.headerType}
                                            acceptType={allowedFormats.join(',')}
                                            setFile={(media) => {
                                                setFieldValue('media', media);
                                                setFieldValue('header.headerType', toMediaType(media.mediaType))
                                            }}
                                            mediaUrl={values.mediaUrl}
                                            type = 'media'
                                        />
                                    ) : (
                                        <>
                                            <div style={{ display: 'flex', alignItems: 'end' }}>
                                                <FilePlaceholder
                                                    size='large'
                                                    mediaType={values.header.headerType}
                                                    fileName={values.media.fileName}
                                                    url={values.media.url}
                                                />
                                                <Button onClick={() => {
                                                    removeFileHandler(values.media);
                                                    setFieldValue('media', null);
                                                }} className='deleteButton' style={{ marginLeft: '12px' }}>
                                                    <BsFillTrashFill />
                                                </Button>
                                            </div>
                                            <br />
                                            <Button onClick={() => setFieldValue('media', null)}><BsUpload />&nbsp;Change file</Button>
                                        </>
                                    )}
                                    {(touched.media && (errors.media && typeof errors.media) === 'string') ? (
                                        <div className='invalid-feedback' style={{ display: 'block' }}>
                                            {errors.media}
                                        </div>
                                    ) : null}
                                </Form.Group>
                            ) : null}
                            {values.header.headerType === 'text' ? (
                                <Form.Group className='mb-3' style={{ position: 'relative' }}>
                                    <Form.Label>Header <span className='required'></span></Form.Label>
                                    <Form.Control
                                        placeholder='Enter the header text'
                                        name='headerText'
                                        value={values.headerText}
                                        isInvalid={(touched.headerText && errors.headerText) ? true : false}
                                        onChange={handleChange}
                                        isValid={touched.headerText && !errors.headerText}
                                        maxLength={60}
                                    />
                                    <div style={{
                                        position: 'absolute',
                                        right: 0,
                                        bottom: -20, 
                                        fontSize: '.8rem',
                                        fontWeight: 400,
                                        color: '#A3A3A3',
                                        padding: '1px 3px'
                                    }}>
                                        {values.headerText.length}/60
                                    </div>
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.headerText}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            ) : null}
                            <BodyInput
                                name='bodyText'
                                placeholder='What do you think?'
                                label={<>Question Text <span className='required'></span></>}
                                value={values.bodyText}
                                isInvalid={(touched.bodyText && errors.bodyText) ? true : false}
                                onChange={handleChange}
                                isValid={touched.bodyText && !errors.bodyText}
                                limit={1024}
                            />
                            {(touched.bodyText && errors.bodyText) ? (
                                <div className='invalid-feedback' style={{ display: 'block' }}>
                                    {errors.bodyText}
                                </div>
                            ) : null}
                            <Form.Group className='my-3' style={{ position: 'relative' }}>
                                <Form.Label>Footer <span className='dimmed'>(Optional)</span></Form.Label>
                                <Form.Control
                                    name='footerText'
                                    value={values.footerText}
                                    placeholder='Enter the footer text'
                                    isInvalid={(touched.footerText && errors.footerText) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched.footerText && !errors.footerText}
                                    maxLength={60}
                                />
                                <div style={{
                                    position: 'absolute',
                                    right: 0,
                                    bottom: -20,
                                    fontSize: '.8rem',
                                    fontWeight: 400,
                                    color: '#A3A3A3',
                                    padding: '1px 3px'
                                }}>
                                    {values.footerText.length}/60
                                    </div>
                                <Form.Control.Feedback type='invalid'>
                                    {errors.footerText}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <DynamicOptionType>
                                <OptionsInput
                                    options={values.options}
                                    placeholder='Enter button text'
                                    error={errors.options}
                                    isInvalid={(touched.options && errors.options) ? true : false}
                                    isValid={(touched.options && !errors.options) ? true : false}
                                    touched={touched.options}
                                />
                            </DynamicOptionType>
                            <AdvancedSettings />
                            <VariableInputLabel
                                error={(
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.storageVariable?.name}
                                    </Form.Control.Feedback>
                                )}>
                                <SelectVariable
                                    placeholder='Create or Select variable'
                                    name='storageVariable'
                                    value={values.storageVariable?.name}
                                    onChange={handleChange}
                                    type='TEXT'
                                />
                            </VariableInputLabel>
                        </Offcanvas.Body>
                        <div className='editor-footer'>
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit'>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default SendReplyButtons;
import axios from '../utils/axios';
import { showAlert, toast } from '../common/alert';
import Swal from 'sweetalert2';

//-*---------------*--  WHATSAPP PROFILE  -*---------------*-//

export const getWhatsappProfile = async (channelId:string) => {
    try {
      const response = await axios.get(
        `channel/${channelId}/profile`
      );
      return response.data;
    } catch (error: any) {
      toast("error", error.message);
    }
  };

export const updateWhatsappProfile = async(whatsappProfileData:any,channelId:string)=>{
  try{
    const response = await axios.patch(
      `channel/${channelId}/profile`,whatsappProfileData
    );
    toast('success','Profile Updated Successfully')
  }
  catch (error: any) {
  toast("error", error.message);
  }
}

export const updateWhatsAppPicture = async(whatsappProfile:any,channelId:string)=>{
  try{
    const formData = new FormData();
    formData.append("file", whatsappProfile); 
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const mediaPayload = {
      file: whatsappProfile,
    };

    const response = await axios.post(
      `channel/profile/photo?channelId=${channelId}`,
      mediaPayload,
      config
    );
    return response
  }
  catch (error: any) {
    toast("error", 'File size exceeded more than the limit');
  }
}


//-*---------------*--  COMPLIANCE PROFILE  -*---------------*-//

export const getComplianceProfile =async(channelId:string)=>{
  try{
   const response =   await axios.get(`channel/${channelId}/compliance_info`)
   return response.data 
  }
  catch (error: any) {
    toast("error", error.message);
  }
}

export const updateComplainceData =async(complainceData:any,channelId:string)=>{
  try{    
   const response =   await axios.patch(`channel/${channelId}/compliance_info`,complainceData)
   toast('success',' Compliance Info Updated Successfully')
   return response.data 
  }
  catch (error: any) {
    console.log('err',error)
    toast("error", error.message);
  }
}

//-*---------------*--  WHATSAPP WIDGET   -*---------------*-//

export const getWidgetData =async(channelId:string)=>{
  try{
   const response =   await axios.get(`channel/${channelId}/widget`)
   return response.data 
  }
  catch (error: any) {
    toast("error", error.message);
  }
}
export const widgetProfileUpdate=async(wid_logo:any,wid_name:string,channelId:string)=>{
  try{
    const apiUrl = `media/saveMedia?uid=${channelId}&name=${wid_name}`;
    const formData = new FormData();
    formData.append('content', wid_logo);
    const response = await axios.post(apiUrl, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
  //  const response =   await axios.patch(`channel/${channelId}/widget`,widgetData,config)
   return response.data
  }
  catch (error: any) {
    toast("error", error.message);
  }
}


export const updateWidgetData=async(widgetData:any,channelId:string)=>{
  try{

   const response =   await axios.patch(`channel/${channelId}/widget`,widgetData)
   return response.data 
  }
  catch (error: any) {
    toast("error", error.message);
  }
}

export const syncProfile = async(channelId:string)=>{
  try{
    const response = await axios.post(`channel/${channelId}/syncChannel`)
    return response.data;
  }
  catch (error: any) {
    toast("error", error.message);
  }
}


//-*---------------*--  CHANNEL SETTINGS  -*---------------*-//
 
export const updateChannelSetting = async(channelId:string,payload:any)=>{
  try{
    const response = await axios.post(`channel/${channelId}/updateChannelSetting`,payload);
  }
  catch (error: any) {
    toast("error", error.message);
  }
}

export const getConfig = async(channelId:string)=>{
  try{
    const response = await axios.get(`channel/${channelId}/configuration`);
    return response.data
  }
  catch (error: any) {
    toast("error", error.message);
  }
}

export const ChannelDelete = async(channelId:string)=>{
  try{
    const response = await axios.delete(`channel/deleteChannel?channelUid=${channelId}`);
    // toast('success','Channel Deleted Successfully')
    return response
  }
  catch (error: any) {
    toast("error", error.message);
  }
}
import React, { useEffect, useState } from "react";
import SearchInput from "../../common/searchComponent";
import { Row, Col, Button } from "reactstrap";
import {
  getAllTags,
  contactBulkUpdate,
} from "../../services/contactMemberService";
import { useSelector } from "react-redux";
import Avatar from "react-avatar";
import { toast } from "../../common/alert";
import { useDispatch } from "react-redux";

type Props = {
  selectedContact: any;
  fetchContacts: () => void;
  closeAssignModal: () => void;
  clearSelect: () => void;
};

const AddTags = (props: Props) => {
  const { selectedContact, fetchContacts, closeAssignModal, clearSelect } =
    props;

  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [tagsList, setTagsList] = useState<any>([]);
  const [count, setCount] = useState(0);
  const [selectedTag, setSelectedTag] = useState(new Set());
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);

  const businessId = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );

  const dispatch = useDispatch();

  const fetchTags = async () => {
    setLoading(true);
    if (tagsList.length >= count && count !== 0) return;
    const tagsPayload = {
      businessUid: businessId,
      limit: 20,
      page: searchTerm ? 0 : page,
      search: searchTerm,
    };
    const tagsData = await getAllTags(tagsPayload);
    if (tagsData) {
      setLoading(false);
      const { tagsDto, count } = tagsData;
      setCount(count);
      if (searchTerm) {
        setTagsList(tagsDto);
      } else {
        setTagsList((prevTags: any) => [...prevTags, ...tagsDto]);
      }
      setPage((prev) => prev + 1); // Increment the page number
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTags();
  }, [businessId, searchTerm]);

  const onSearchChange = (searchTerm: string) => {
    const searchTermCheck = searchTerm.length > 0 ? searchTerm : null;
    if (searchTermCheck === null) {
      setTagsList([]);
    }
    setSearchTerm(searchTermCheck);
    setPage(0);
  };

  const checkTags = (tag: any) => {
    const tagId = tag.id;
    setSelectedTag((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(tagId)) {
        newSelected.delete(tagId);
      } else {
        newSelected.add(tagId);
      }
      return newSelected;
    });
  };

  const handleScroll = (event: any) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (
      !loading &&
      scrollTop + clientHeight >= scrollHeight - 20 &&
      tagsList.length < count
    ) {
      fetchTags();
    }
  };

  const updateTags = async () => {
    setLoading(true);
    const uidsArray = selectedContact?.map((row: any) => row.uid);
    const updatePayload = {
      uid: businessId,
      contactIds: uidsArray,
      tagIds: Array.from(selectedTag),
    };
    const response = await contactBulkUpdate(updatePayload);
    if (response) {
      toast("success", "tag update successfully");
      closeAssignModal();
      fetchContacts();
      setSelectedTag(new Set());
      // clearSelect();
      dispatch({ type: 'TABLE_RESET' });
    }
  };

  return (
    <div>
      <div
        style={{
          position: "sticky",
          top: "0px",
          zIndex: "9999",
          background: "white",
        }}
      >
        <Row className="pt-1 pb-1">
          <Col>
            Selected :{" "}
            <span style={{ color: "#007EC3" }}>
              {" "}
              {selectedTag.size} of {count}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="pe-0">
            <SearchInput
              component="community"
              onSearchChange={onSearchChange}
              placeHolder={"Search Tags"}
            />
          </Col>
          {selectedTag.size > 0 && (
            <>
              <Col md={2} className="p-0">
                <Button
                  className="clearSelection w-100"
                  onClick={() => setSelectedTag(new Set())}
                >
                  Clear
                </Button>
              </Col>
              <Col md={2}>
                <Button className="sendButton" onClick={updateTags}>
                  Update
                </Button>
              </Col>
            </>
          )}
        </Row>
      </div>
      <Row
        className="tagContainer-AddTag"
        onScroll={handleScroll}
        style={{ maxHeight: "400px", overflowY: "auto" }}
      >
        <Col>
        {tagsList.length === 0 && <div
          className="text-center p-3"
          >No Tags Found!</div>}
          {tagsList?.map((tags: any) => (
            <>
              <Row className="d-flex align-items-center pt-2 pb-2">
                <Col md={1} className="d-flex pe-0">
                  <input
                    type="checkbox"
                    onClick={() => {
                      checkTags(tags);
                    }}
                    checked={selectedTag.has(tags.id)}
                  />
                </Col>
                <Col md={1} className="p-0 ">
                  <Avatar
                    name={tags.name}
                    round
                    size="35"
                    textSizeRatio={2.5}
                    color="#00b598"
                  />
                </Col>
                <Col className="p-0">{tags.name}</Col>
              </Row>
              <hr />
            </>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default AddTags;

import React, { useEffect, useState } from 'react';
import { Form, Offcanvas, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import { IEditorProps } from '../types';
import { useElmentEditor } from '../hooks';
import EditorCaption from '../editorCaption';
import { updateChatStatusIcon } from '../../../../icons';

type IConversationStatus = 'PENDING' | 'NEW' | 'SPAM' | 'QUALIFIED';

interface FormData {
    conversation: {
        conversationStatus: IConversationStatus;
    }
};


function UpdateConversationStatusEditor(props: IEditorProps) {
    const [formData, setFormData] = useState<FormData>({
        conversation: {
            conversationStatus: "NEW"
        }
    });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'actions/update_conversationStatus',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);

    const schema = yup.object().shape({
        conversation: yup.object().shape({
            conversationStatus: yup
                .string()
                .required('Conversation Status is required')
                .oneOf(['PENDING', 'NEW', 'SPAM', 'QUALIFIED'], 'Conversation Status is not valid'),
        })
    });

    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, handleChange, values, touched, errors, setValues }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption='Update Chat Status' icon={<img alt='' width={18} src={updateChatStatusIcon} />} />
                        <Offcanvas.Body>
                            <Form.Group className='mb-3'>
                                <Form.Label>Chat Status <span className="required"></span></Form.Label>
                                <Form.Select
                                    name='conversation.conversationStatus'
                                    value={values.conversation?.conversationStatus}
                                    isInvalid={(touched.conversation?.conversationStatus && errors.conversation?.conversationStatus) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched.conversation?.conversationStatus && !errors.conversation?.conversationStatus}
                                >
                                    <option value="NEW">New</option>
                                    <option value="PENDING">Pending</option>
                                    <option value="SPAM">Spam</option>
                                    <option value="QUALIFIED">Qualified</option>
                                </Form.Select>
                                {(touched.conversation?.conversationStatus && errors.conversation?.conversationStatus) ? (
                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        {errors.conversation?.conversationStatus}
                                    </div>
                                ) : null}
                            </Form.Group>
                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit'>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}
export default UpdateConversationStatusEditor;
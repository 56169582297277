import { Button, Form, InputGroup, Offcanvas } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import styles from "./createBot.module.scss";
import * as botService from '../../services/bots';
import { MdArrowBack } from 'react-icons/md';

interface EditData { nameInput: string, timeoutMsgInput: string, sessionTTL: string };

interface Props {
    id?: string;
    show: boolean;
    onHide: () => void;
    onCreate: () => void;
    editData?: EditData;
    bot?: any;
    showDelete?: () => void;
};

function CreateBot(props: Props) {
    async function createBotHandler(data: botService.BotData) {
        await botService.createBot(data);
        props.onHide();
        props.onCreate();
    }

    async function updateBotHandler({ nameInput, timeoutMsgInput, sessionTTL }: botService.BotData) {
        if (props.id) {
            await botService.updateBot(props.id, {
                ...props.bot,
                name: nameInput,
                sessionTimeoutMessage: timeoutMsgInput,
                sessionTTL: parseInt(sessionTTL) * 60000,
            });
            props.onHide();
            props.onCreate();
        }
    }

    const schema = yup.object().shape({
        nameInput: yup.string().required('Bot Name is Required'),
        timeoutMsgInput: yup.string().required('Time-out message is required'),
        sessionTTL: yup.string()
            .required('Timer is a required')
            .test('is-positive-integer', 'Timer must be greater than 0', (value: any) => {
                const intValue = parseInt(value, 10);
                return !isNaN(intValue) && intValue > 0;
            })
            .test('is-whole', "Timer must be a whole number", (value: any) => {
                return parseInt(value, 10).toString() === value;
            }),
    });

    return (
        <Offcanvas style={{ width: '30vw' }} show={props.show} onHide={props.onHide} placement='end'>
            <Offcanvas.Header>
                <Offcanvas.Title>
                    <Button variant='default' onClick={props.onHide}>
                        <MdArrowBack />
                    </Button>{' '}
                    {props.editData ? 'Update' : 'Create'} Bot
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Formik
                validationSchema={schema}
                onSubmit={props.editData ? updateBotHandler : createBotHandler}
                initialValues={{
                    nameInput: props.editData ? props.editData.nameInput : '',
                    timeoutMsgInput: props.editData ? props.editData.timeoutMsgInput : 'Session Expired',
                    sessionTTL: props.editData ? `${parseFloat(props.editData.sessionTTL) / 60000}` : '3',
                }}
            >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Offcanvas.Body className={styles.createBot}>
                            <Form.Group>
                                <Form.Label>ChatBot Name</Form.Label>
                                <Form.Control
                                    type='text'
                                    name='nameInput'
                                    placeholder='Enter your name'
                                    value={values.nameInput}
                                    isInvalid={(touched.nameInput && errors.nameInput) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched.nameInput && !errors.nameInput}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.nameInput}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='mt-3'>
                                <Form.Label>Timer Settings</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        name='sessionTTL'
                                        type="number"
                                        placeholder='Enter your minutes'
                                        value={values.sessionTTL}
                                        onChange={handleChange}
                                        isInvalid={(touched.sessionTTL && errors.sessionTTL) ? true : false}
                                        isValid={touched.sessionTTL && !errors.sessionTTL}
                                    />
                                    <InputGroup.Text style={{ padding: '8.5px 12px' }}>Minutes</InputGroup.Text>
                                </InputGroup>
                                <div className='invalid-feedback' style={{ display: 'block' }}>
                                    {errors.sessionTTL}
                                </div>
                                <Form.Text className="text-muted">
                                    Set the time interval for bot queries when there's no response.
                                </Form.Text>
                            </Form.Group>

                            <Form.Group className='mt-3'>
                                <Form.Label>Delayed Response Notification</Form.Label>
                                <Form.Control
                                    as='textarea'
                                    placeholder='Enter your delayed response text here...'
                                    name='timeoutMsgInput'
                                    value={values.timeoutMsgInput}
                                    onChange={handleChange}
                                    isInvalid={(touched.timeoutMsgInput && errors.timeoutMsgInput) ? true : false}
                                    isValid={touched.timeoutMsgInput && !errors.timeoutMsgInput}
                                    style={{ height: '100px' }}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.timeoutMsgInput}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <div style={{ marginTop: 12, textAlign: 'right' }}>
                                <Button variant='outline-dark' onClick={props.onHide} className='mr-3'>
                                    Cancel
                                </Button>
                                <Button type='submit' className='signUpBtn mt-0'>{props.editData ? 'Update Bot' : 'Create New Bot'}</Button>
                            </div>
                        </Offcanvas.Body>
                    </Form>
                )}

            </Formik>
        </Offcanvas>
    );
}

CreateBot.defaultProps = {
    onCreate: () => { }
}

export default CreateBot;
import React, { useEffect, useState, useCallback, useRef  } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {  Row, Col } from "reactstrap";
import { Formik, Form } from "formik";
import {
  SelectRow,
} from "react-bootstrap-table";
import moment from "moment";
import { Button } from "react-bootstrap";
import {
  DeleteCommunity,
  getAllCommunity,
} from "../../services/campaignService";
import Campaign from "./Campaign";
import { confirmAlert, toast } from "../../common/alert";
import { sliceText } from "../../common/sliceText";
import TooltipReference from "../../common/tooltip";
import { FormikHelpers } from "formik"
import BizTable  from "../../common/BizTable";
import SearchInput from "../../common/searchComponent";
import { useDispatch , useSelector } from 'react-redux';


interface SelectCommunityProps {
  communitySelect?: (edit: any) => void;
  backToCampaign?: () => void;
  selectedContact?: (selectCommunity: any) => void;
  selectedContactData?: any;
  callbackChecked:any;
  checkboxExistCommunity:any;
  check_community_uid:any;
  iscreateCampaign:boolean;
  contactUpdate:any;
}

type Contact = {
  id: string;
  name: string;
};

interface RowData {
  id: number; // Assuming id is a number
  // Other properties of rowData
}

interface DataExistCommunity {
  rowData: object;
}

function ExistCommunity(props: SelectCommunityProps) {
  const [communityData, setCommunityData] = useState([]);
  const [multipleCommunity, setMultipleCommunity] = useState<string[]>([]);
  const [newCommunity, setCommunity] = useState<string | undefined>("");
  // const [community_uid, setCommunityUid] = useState<string[]>([]);
  const [community_uid, setCommunityUid] = useState<Contact[]>([]);
  const [EditFieldCommunity, setEditCommunity] = useState(false);
  const [EditCommunityData, setEditCommunityData] = useState();
  const [checkboxSelected, setCheckboxSelected] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const memberData = useSelector((state: any) => state.cartreducer.memberData);
  const channelData = useSelector((state: any) => state.cartreducer.channelUid);
  const existCommunityData = useSelector((state: any) => state.cartreducer.existCommunity);
  // console.log('existCommunityData',existCommunityData)
  const [paginationPayload, setPaginationPayload] = useState({
    pageIndex: 0,
    pageSize: 10,
    searchText: '',
    currentPage: 0
  });
  const dispatch = useDispatch();



  const communityList = async () => {
    if (props.selectedContactData) {
      const contact_id = props?.selectedContactData.flatMap(
        (contactList: any) => contactList.id
      );
      setCheckboxSelected(contact_id);
      setCommunityUid(props.selectedContactData);
    }
    if(props?.checkboxExistCommunity?.length > 0){
        setCheckboxSelected(props.checkboxExistCommunity)
        if(props?.contactUpdate?.contacts?.length > 0){
              const contactId = props.contactUpdate?.id;
              const updatedSelectedContact = props.check_community_uid.map((item: any) => {
                if (item?.id === contactId) {
                  return {
                    ...item,
                    contacts: [...props.contactUpdate.contacts],
                  };
                } else {
                  return {
                    ...item,
                  };
                }
              });
              setCommunityUid(updatedSelectedContact)
      }
      else{
        setCommunityUid(props.check_community_uid)
      }
      }
    const channelId = channelData?.value;
    // if (channelId) {
    //   const community = await groupList(channelId);
    //   setLoading(false);
    //   const communityData = community.filter(
    //     (memberId: any) => !memberId.deleted
    //   );

    //   const modifycommunity = communityData.map((memberId: any) => {
    //     const { createdMemberId } = memberId;
    //     if (createdMemberId) {
    //       const matchingMember = memberData.find(
    //         (memberObj: any) => memberObj.uid === createdMemberId
    //       );
    //       if (matchingMember) {
    //         const { name } = matchingMember;
    //         return { ...memberId, memberName: name };
    //       }
    //       return memberId;
    //     }
    //   });
    //   setCommunityData(modifycommunity);
    // }
  };

  // const handleRowSelect = (row: any, isSelected: any) => {
  //   if (isSelected) {
  //     setCommunityUid((prevRow: any) => [...prevRow, row]);
  //     const contacts = row?.contacts;
  //     setMultipleCommunity((prevMultipleCommunity) => {
  //       const uniqueContacts = contacts?.filter((contact: any) => {
  //         return !prevMultipleCommunity.some(
  //           (existingContact: any) => existingContact.id === contact.id
  //         );
  //       });
  //       return [...prevMultipleCommunity, ...uniqueContacts];
  //     });

  //     setCheckboxSelected([...checkboxSelected, row?.id] as any);
  //   } else {
  //     setCommunityUid((prevRow: any) => {
  //       const updatedCommunityUid = prevRow.filter(
  //         (item: any) => item.id !== row.id
  //       );
  //       return updatedCommunityUid;
  //     });

  //     setMultipleCommunity((prevMultipleCommunity) =>
  //       prevMultipleCommunity.filter(
  //         (contact) => !row.contacts.includes(contact)
  //       )
  //     );
  //     setCheckboxSelected((prevRow: any) =>
  //       prevRow.filter((item: any) => item !== row.id)
  //     );
  //   }
  // };
  useEffect(() => {
    if (Array.isArray(existCommunityData) && existCommunityData.length > 0) {
      setCommunityUid(existCommunityData);
      setCheckboxSelected(existCommunityData.map((item: RowData) => item.id));
    }
  }, [existCommunityData]);


  const handleRowSelect = (row: any, isSelected: any) => {

    if (isSelected) {
      setCommunityUid((prevRow:any) => {
        const newCommunityUid = [...prevRow, row];
        dispatch({ type: 'EXIST_COMMUNITY', payload: newCommunityUid });
        return newCommunityUid;
      });

      // const contacts = row?.contacts;
      // setMultipleCommunity((prevMultipleCommunity) => {
      //   const uniqueContacts = row?.filter((contact: any) => {
      //     return !prevMultipleCommunity.some(
      //       (existingContact: any) => existingContact.id === contact.id
      //     );
      //   });
      //   console.log('')
      //   return [...prevMultipleCommunity, ...uniqueContacts];
      // });
      setCheckboxSelected([...checkboxSelected, row?.id] as any);
    } else {
      setCommunityUid((prevRow: any) => {
        const updatedCommunityUid = prevRow.filter(
          (item: any) => item.id !== row.id
        );
        dispatch({ type: 'EXIST_COMMUNITY', payload: updatedCommunityUid });
        return updatedCommunityUid;
      });

      setMultipleCommunity((prevMultipleCommunity) =>
        prevMultipleCommunity.filter(
          (contact) => !row.contacts.includes(contact)
        )
      );
      setCheckboxSelected((prevRow: any) =>
        prevRow.filter((item: any) => item !== row.id)
      );
    }
  };

  const handleRowSelectAll = (isSelected: boolean, rows: any[]) => {
    if (isSelected) {
      const allRowIds = rows.map(row => row.id) as any;
      const allContacts = rows.reduce((acc: any[], row: any) => [...acc, ...row.contacts], []);
      const uniqueContacts = allContacts.filter((contact, index, self) => 
        index === self.findIndex((t) => t.id === contact.id)
      );
  
      setCommunityUid(rows);
      setMultipleCommunity(uniqueContacts);
      setCheckboxSelected(allRowIds) ;
    } else {
      setCommunityUid([]);
      setMultipleCommunity([]);
      setCheckboxSelected([]);
    }
    return true;
  };


  
const columns = [
  {
    Header: (
      <input
        type="checkbox"
        // onChange={(e) => {
        //   if (e.target.checked) {
        //     handleRowSelectAll(true, communityData);
        //   } else {
        //     handleRowSelectAll(false, communityData);
        //   }
        // }
        // }
        hidden={true}
      />
    ),
    accessor: "checkbox",
    Cell: ({ cell, row }: any) => checkboxCellRenderer(cell?.value, row?.original),
    sticky: "left",
  },
    {
      Header: "Community Name",
      accessor: "name",
      sticky: "left",
      Cell: ({ cell, row }: any) => communityName(cell?.value, row?.original),
    },
    {
      Header: "Created on",
      accessor: "createdAt",
      Cell: ({ cell, row }: any) => formatCreatedOn(cell?.value),
      // grid: "third-column",
    },
    {
      Header: "Created by",
      accessor: "memberName",
      Cell: ({ cell, row }: any) => createdBy(cell?.value, row?.original),
      // grid: "third-column",
    },
    {
      Header: "Action",
      accessor: "actions",
      Cell: ({ cell, row }: any) => renderEditColumn(cell?.value, row?.original),
      // grid: "col-md-1",
    },
  ] as any;
  const [searchTerm, setSearchTerm] = useState<string | null>();
  const [communityCount, setCommunityCount] = useState(0);
  const currentPage = useRef(0);
  const paginationLimit = useRef(10);
  const totalPages = Math.ceil(communityCount / paginationLimit.current);

  const fetchData = useCallback(async (
    pageIndex: number,
    pageSize: number,
    searchText: string ,
    currentPage: number
  ): Promise<{ data: any; totalPages: number }> => {
    // setPaginationPayload({
    //   pageIndex: pageIndex,
    //   pageSize:  pageSize,
    //   searchText: searchText,
    //   currentPage: currentPage
    // })
    const channelId = channelData?.value;
    if (channelId) {
      const communityPayload = {
        page: searchTerm ? 0 : currentPage,
        limit: pageSize,
        search: searchTerm,
      }
      // const community = await getAllCommunity(channelId, communityPayload);
    
      const community = await getAllCommunity(channelId,communityPayload);
      const { communities, count } = community;
      
    
      if (communities) {

        setLoading(false);
        const communityData = communities.filter(
          (memberId: any) => !memberId.deleted
        );
  
        const modifycommunity = communityData.map((memberId: any) => {
          const { createdMemberId } = memberId;
          if (createdMemberId) {
            const matchingMember = memberData.find(
              (memberObj: any) => memberObj.uid === createdMemberId
            );
            if (matchingMember) {
              const { name } = matchingMember;
              return { ...memberId, memberName: name };
            }
            return memberId;
          }
        });
        setCommunityData(modifycommunity);
        const countData =   searchTerm ? communities?.length : count;
        setCommunityCount(countData);
        // const { communities, count } = community;
        // const countData =   searchTerm ? communities?.length : count;
        // setCommunityCount(countData);
        // const communityData = communities.filter((memberId: any) => !memberId.deleted);
        // setEditCommunityData(communityData);
        // setEditCommunity(!EditFieldCommunity);
        return { data: communities, totalPages: Math.ceil(count / paginationLimit.current) };
      }
      else{
        return { data: [], totalPages: 0 };
      
      }
    }
    else{
      return { data: [], totalPages: 0 };
    }
  }, [channelData,searchTerm]);

  // useEffect(() => {
  //   communityList();
  // }, []);
  
  // if (loading) {
  //   return <Loader />;
  // }

  const EditCommunity = async(rowData: any) => {
    // console.log('row',rowData)
    if (rowData) {
      const channelId = channelData?.value;
      // const communityPayload={
      //   page: 0,
      //   limit: 20,
      //   search: null
      // }
      // const response = await getCommunityContacts(rowData.uid,communityPayload)
    //  const response = await GetCommunity(rowData.uid,channelId)
    //  if(response){
  
       setEditCommunityData(rowData);
       setEditCommunity(!EditFieldCommunity);
    //  }
    }
  };

  const renderEditColumn = (cell: any, rowData: object) => {
    const handleDeleteClick = async () => {
      const response = await DeleteCommunity(rowData as any);
      if (response === "SUCCESS") {
        // await communityList();
        // getAllCommunity();
        // const { pageIndex, pageSize, searchText, currentPage } = paginationPayload;
        // fetchData(pageIndex, pageSize, searchText , currentPage)
        fetchData(0,20,'',0)
        dispatch({ type: 'EXIST_COMMUNITY', payload: [] });
        setCheckboxSelected([]);
      }
    };
    const handleEditClick = async () => {
      await EditCommunity(rowData as any,);
    };

    return (
      <div className="d-flex justify-content-center ">
        <svg
          width="16"
          height="16"
          className="me-3"
          onClick={handleEditClick}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.5 3H3.22222C2.89807 3 2.58719 3.12877 2.35798 3.35798C2.12877 3.58719 2 3.89807 2 4.22222V12.7778C2 13.1019 2.12877 13.4128 2.35798 13.642C2.58719 13.8712 2.89807 14 3.22222 14H11.7778C12.1019 14 12.4128 13.8712 12.642 13.642C12.8712 13.4128 13 13.1019 13 12.7778V8.5"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.9485 2.35199C12.1738 2.12661 12.4795 2 12.7982 2C13.117 2 13.4226 2.12661 13.648 2.35199C13.8734 2.57737 14 2.88304 14 3.20177C14 3.5205 13.8734 3.82617 13.648 4.05155L8.26608 9.43348L6 10L6.56652 7.73392L11.9485 2.35199Z"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          width="16"
          height="16"
          onClick={handleDeleteClick}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 4H3.33333H14"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.6673 4.00016V13.3335C12.6673 13.6871 12.5268 14.0263 12.2768 14.2763C12.0267 14.5264 11.6876 14.6668 11.334 14.6668H4.66732C4.3137 14.6668 3.97456 14.5264 3.72451 14.2763C3.47446 14.0263 3.33398 13.6871 3.33398 13.3335V4.00016M5.33398 4.00016V2.66683C5.33398 2.31321 5.47446 1.97407 5.72451 1.72402C5.97456 1.47397 6.3137 1.3335 6.66732 1.3335H9.33398C9.68761 1.3335 10.0267 1.47397 10.2768 1.72402C10.5268 1.97407 10.6673 2.31321 10.6673 2.66683V4.00016"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.66602 7.3335V11.3335"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.33398 7.3335V11.3335"
            stroke="#666E7D"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    );
  };

  const selectRowProp: SelectRow = {
    mode: "checkbox",
    // clickToSelect: false,
    clickToSelectAndEditCell: false,
    clickToExpand: false,
    selected: checkboxSelected,
    onSelect: handleRowSelect,
    onSelectAll: handleRowSelectAll,
  };

  const create_community_field = (e: any) => {
    const { value } = e.target;
    setCommunity(value);
  };

  const clearSelect = () => {
    // const checkboxes = document.querySelectorAll<HTMLInputElement>(
    //   'input[type="checkbox"]'
    // );
    // checkboxes.forEach((checkbox) => {
    //   if (checkbox.checked) {
    //     checkbox.click();
    //   }
    // });
    setCommunityUid([]);
    setCheckboxSelected([]);
    dispatch({ type: 'EXIST_COMMUNITY', payload: [] });
  };

  const onSearchChange = (searchTerm: string) => {
    const searchTermCheck = searchTerm?.length > 0 ? searchTerm : null;
    setSearchTerm(searchTermCheck);
  };

  const handleSubmit = ( values: any,
    { setSubmitting }: FormikHelpers<any>) => {
      
      if (community_uid) {
      if(props.selectedContactData && checkboxSelected?.length === 0){
        toast('error','Atleast one contact should be selected')
    }
    else{
      const { communitySelect, selectedContact } = props;
      if (communitySelect && selectedContact) {
        // if (props.selectedContactData?.length > 0) {
        //   const communityId = community_uid.map((item: any) => {
        //     const matchingContact = props.selectedContactData.find((contact: any) => contact.id === item.id);
        //     if (matchingContact) {
        //       return {
        //         ...item,
        //         ...matchingContact,
        //       };
        //     }
        //      else {
        //       toast("success", 'Community updated successfully');
        //       return item;
        //     }

        //   });
      //  const contactCount =  .map((item: any) => {item.contactCount})
          // selectedContact(community_uid?.length);
        // }
        // else{
        //    Math.ceil(communityCount / paginationLimit.current)(community_uid);
        // } 
        communitySelect("");
      }
      toast("success", 'Community updated successfully');
    }
    }

    setSubmitting(false);

  };

  const back_Campaign = () => {
    let oldData;
    let filteredContact = [];
    
    if (checkboxSelected?.length >= 0) {
      if (props?.selectedContactData) {
        filteredContact =
          checkboxSelected &&
          checkboxSelected?.filter(
            (id: any) =>
              !props?.selectedContactData?.some((item: any) => item.id === id)
          );
        const changeContact = props?.selectedContactData?.flatMap(
          (contactList: any) => contactList.id
        );
        oldData =
          JSON.stringify(checkboxSelected) === JSON.stringify(changeContact);
      }
      else{
        if(checkboxSelected?.length > 0){
          oldData = false
        }
      }
      

      if (filteredContact?.length > 0 || oldData === false ) {
        confirmAlert(
          "warning",
          "Your changes will be lost. Are you sure you want to continue?",
          () => {
            if (props.backToCampaign) {
              props.backToCampaign();
              dispatch({ type: 'EXIST_COMMUNITY', payload: [] }); 
            }
          },
          () => {}
        );
      } else {
        if (props.backToCampaign) {
          props.backToCampaign();
        }
      }
    } else if (props.backToCampaign) {
      props.backToCampaign();
    }
  };
  const formatCreatedOn = (date: string) => {
    return <span>{moment(date).format("DD MMM YYYY")}</span>;
  };

  const createdBy = (cell: any, row: any) => {
    return (
      <div>
        <span className="">{channelData?.label}</span>
      </div>
    );
  };


  const communityName = (cell: any, row: any) => {
    const contactCount = row?.contactCount;
    return (
      <div>
        {cell?.length > 11 ? (
          <Row>
            {/* <Col  md='4'></Col> */}
              <Col md='1' className="p-0 ">
              <div>  
              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="#dee2e6" className="bi bi-person-circle" viewBox="0 0 16 16"> <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" fill="#dee2e6"></path> <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" fill="#dee2e6"></path> </svg>
                </div>            
             {'  '}
             <div className="bi-person-circle-count">
              {/* {row.contacts.length} */}
              {contactCount}
             </div>
              </Col>
              <Col md='5' className="ps-4">  
              <TooltipReference
                placement="right"
                content={cell}
                tooltipId={`nameData_${row.id}`}
              >
            {sliceText(cell, 12)}
          </TooltipReference>
              </Col>
            </Row>
        ) : (
          <Row>
            {/* <Col  md='4'></Col> */}
            <Col md='1' className="p-0">
            <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="#dee2e6" className="bi bi-person-circle" viewBox="0 0 16 16"> <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" fill="#dee2e6"></path> <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" fill="#dee2e6"></path> </svg>
              </div>
              <div className="bi-person-circle-count">
             {/* {row.contacts.length} */}
             {contactCount}
              </div>
            </Col>
            <Col md='5' className="ps-4">
            {sliceText(cell, 12)}
             {/* <FontAwesomeIcon icon={faUser}  style={{color:'#dee2e6'}} className="pe-1" /> */}
            </Col>
           
          </Row>
        )}
      </div>
    );
  };



  const checkboxCellRenderer = (cell: any, rowData: { id: number }) => {
    const id  = rowData?.id;
    return (
      <input
        type="checkbox"
        checked={checkboxSelected.includes(id)}
        onChange={() => handleRowSelect(rowData, !checkboxSelected.includes(id))}
      />
    );
  };
  

  const handleKeyDown = (event: any) => {
    const { value } = event.target;
    if (event.key === " " && value.trim() === "") {
      event.preventDefault();
    }
  };
  const options = {
    headerAlign: "center",
    noDataText: "No records found",
    sortIndicator: true,
    hidePageListOnlyOnePage: true,
    clearSearch: false,
    alwaysShowAllBtns: true,
    withFirstAndLast: true,
    sizePerPage: 10,
    sizePerPageList: [10,25, 50, 100,{ text: 'All', value: communityData?.length }],
    paginationPosition: "bottom", // default is bottom, top and both is all available
  };
  return (
    <div className="SelectCommunityContainer">
      {EditFieldCommunity ? (
        <>
          <Campaign EditCommunityData={EditCommunityData}
          selectedContactData={props.selectedContactData}
          checkboxExistCommunity={checkboxSelected}
          checkboxCommunityUid={community_uid}
          callbackChecked={props.callbackChecked}
          createCampaign = {props.iscreateCampaign}
          />
        </>
      ) : (
        <>
          <div className="global-table-title">
            <h5>
              <FontAwesomeIcon icon={faArrowLeft} onClick={back_Campaign} />
              {"  "}
              Select from Existing Community
            </h5>
          </div>
          <div className="exist-community-container">
            <Formik initialValues={[]} onSubmit={handleSubmit}>
              {({ isSubmitting, setFieldValue, values }) => (
                <Form>
                  <Row className="pt-3 pb-3">
                    <Col md={3}>
                    <SearchInput
                          component="existCommunity"
                          onSearchChange={onSearchChange}
                          placeHolder={"Search Community"}
                        />
                    </Col>
                    <Col>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        {checkboxSelected?.length > 0 ? (
                          <div>
                            <Button
                              variant=""
                              type="button"
                              onClick={back_Campaign}
                              className="cancelButton me-2"
                            >
                              Cancel
                            </Button>
                            <Button
                              variant=""
                              type="button"
                              onClick={clearSelect}
                              className="cancelButton me-2"
                            >
                              Clear Selection
                            </Button>
                            <Button
                              variant=""
                              type="submit"
                              disabled={isSubmitting}
                              className="sendButton"
                            >
                              Continue
                            </Button>
                          </div>
                        ) : (
                          <>
                            <Button
                              variant=""
                              type="button"
                              onClick={back_Campaign}
                              className="cancelButton"
                            >
                              Cancel
                            </Button>
                            {community_uid?.length > 0 ? (
                              <Button
                                variant=""
                                type="button"
                                onClick={clearSelect}
                                className="cancelButton"
                              >
                                Clear Selection
                              </Button>
                            ) : null}
                            {props?.selectedContactData?.length > 0 && (
                              <Button
                                variant=""
                                type="submit"
                                disabled={isSubmitting}
                                className="sendButton ms-2"
                              >
                                Continue
                              </Button>
                            )}
                          </>
                        )}
                      </Col>
                  </Row>
                    <div className="selected-contacts pb-1">
                      Selected :{" "}
                      <span>
                        {checkboxSelected?.length} of {communityCount}{" "}
                        Communities{" "}
                      </span>
                    </div>
                    {/* <div className="global-top-table-button">
                      <>
                        {checkboxSelected.length > 0 ? (
                          <div>
                            <Button
                              variant=""
                              type="button"
                              onClick={back_Campaign}
                              className="cancelButton me-2"
                            >
                              Cancel
                            </Button>
                            <Button
                              variant=""
                              type="button"
                              onClick={clearSelect}
                              className="cancelButton me-2"
                            >
                              Clear Selection
                            </Button>
                            <Button
                              variant=""
                              type="submit"
                              disabled={isSubmitting}
                              className="sendButton"
                            >
                              Continue
                            </Button>
                          </div>
                        ) : (
                          <>
                            <Button
                              variant=""
                              type="button"
                              onClick={back_Campaign}
                              className="cancelButton"
                            >
                              Cancel
                            </Button>
                            {community_uid.length > 0 ? (
                              <Button
                                variant=""
                                type="button"
                                onClick={clearSelect}
                                className="cancelButton"
                              >
                                Clear Selection
                              </Button>
                            ) : null}
                            {props?.selectedContactData.length > 0 && (
                              <Button
                                variant=""
                                type="submit"
                                disabled={isSubmitting}
                                className="sendButton ms-2"
                              >
                                Continue
                              </Button>
                            )}
                          </>
                        )}
                      </>
                    </div> */}

                </Form>
              )}
            </Formik>
            
                      {/* <BootstrapTable
                        data={communityData}
                        selectRow={selectRowProp}
                        search
                        pagination={communityData.length > 10}
                        version="4"
                        striped
                        hover
                        options={options as any}
                        containerStyle={{ textAlign: "left" }}
                      >
                        <TableHeaderColumn dataField="id" isKey hidden>
                          #
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="name"
                          dataAlign="center"
                          dataFormat={communityName}
                        >
                          Community name 
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="createdAt"
                          dataAlign="center"
                          dataFormat={formatCreatedOn}
                        >
                          Created on
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="memberName"
                          dataAlign="center"
                          dataFormat={createdBy}
                        >
                          Created by
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          dataField="edit"
                          dataFormat={renderEditColumn}
                          dataAlign="center"
                        >
                          Actions
                        </TableHeaderColumn>
                      </BootstrapTable> */}
                      <BizTable
                      columns={columns}
                      fetchData={fetchData}
                      counts={communityCount}
                      refetchData={communityData}
                      tableName='EXISTCOMMUNITY'
                    /> 
                  </div>
                  
        </>
        
      )}
    </div>
  );
}

export default ExistCommunity;

import React, { useEffect, useState } from "react";
import "../styles.scss";

interface TypeListComponentProps {
  typeOptions: { id: string; label: string }[];
  typeCount?: any;
  selectedTypes: string[];
  onApply: (selectedTypes: string[]) => void;
}

const TypeListComponent: React.FC<TypeListComponentProps> = ({
  typeOptions,
  typeCount,
  selectedTypes,
  onApply,
}) => {

  const [localSelectedTypes, setLocalSelectedTypes] =
    useState<string[]>(selectedTypes);

  const handleTypeClick = (typeId: string) => {
    const isSelected = localSelectedTypes.includes(typeId);
    let updatedSelectedTypes;

    if (isSelected) {
      // Deselect
      updatedSelectedTypes = localSelectedTypes.filter((id) => id !== typeId);
    } else {
      // Select
      updatedSelectedTypes = [typeId];
    }

    setLocalSelectedTypes(updatedSelectedTypes);
    onApply(updatedSelectedTypes); // Apply immediately after selection
  };


  return (
    <div className="type-list-component">
      <div className="type-list-header">Types</div>
      <div
        style={{ height: "1px", backgroundColor: "#ccc", marginBottom: "10px" }}
      ></div>

      <div className="type-list">
        {typeOptions.map((option: any) => {
          const fieldCount =
            typeCount?.find(
              (type: any) =>
                type.types.toLowerCase() === option.label.toLowerCase()
            )?.openCount +
              typeCount?.find(
                (type: any) =>
                  type.types.toLowerCase() === option.label.toLowerCase()
              )?.pendingCount || 0;
          return (
            <div
              key={option.id}
              onClick={(e: any) => {
                if (fieldCount > 0) {
                  handleTypeClick(option.label);
                } else {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              className={`${
                localSelectedTypes.includes(option.label)
                  ? "type-list-item-selected"
                  : "type-list-item"
              }`}
              style={{
                opacity: fieldCount > 0 ? 1 : 0.5,
                cursor: fieldCount > 0 ? "pointer" : "not-allowed",
              }}
            >
              <span>{option.label}</span>
              <span className="type-count ms-2">
                
                {typeCount?.find(
                  (type: any) =>
                    type.types.toLowerCase() === option.label.toLowerCase()
                )?.openCount +
                  typeCount?.find(
                    (type: any) =>
                      type.types.toLowerCase() === option.label.toLowerCase()
                  )?.pendingCount || 0}
                
              </span>
              {/* {localSelectedTypes.includes(option.label) && (
              <span style={{ color: 'green', fontWeight: 'bold' }}>✔</span>
            )} */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TypeListComponent;

import React, { useState, useEffect, Component, ErrorInfo, ReactNode, useRef } from 'react';
import { Button, Form, FormGroup, Offcanvas, Spinner } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import Select from 'react-select';
import { Formik, useFormikContext } from 'formik';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';

import styles from './jumpTo.module.scss';
import * as flowService from '../../../services/flows';
import * as designFlowService from '../../../services/designFlow';
import { IEditorProps } from './types';
import { BackendFlow, INode, transformBackendFlow } from '../../../store/graphSlice';
import { useElmentEditor } from './hooks';
import EditorCaption from './editorCaption';
import { jumpToIcon } from '../../../icons';
import nodeTypes, { INodeType } from '../nodes/nodeTypes';
import ErrorFallback from '../../ui/errorFallback';
import SelectBot from '../../bots/selectBotInput';

class JumptoPreviewErrorBoundary extends Component<{ children: ReactNode }> {
    state = { hasError: false };

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error(error, errorInfo);
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return <ErrorFallback />;
        }
        return <div>{this.props.children}</div>;
    }
}


const SelectFlow: React.FC<any> = props => {
    const query = useQuery({
        queryKey: ['bots', props.botId, 'v2'],
        queryFn: () => flowService.getFlows(`${props.botId}`)
    });
    if (query.isLoading) {
        return <Spinner />
    }
    return (
        <>
            <Select
                options={
                    query.data
                        .map((flow: any) => {
                            return {
                                label: flow?.flowName,
                                value: flow?.uid
                            }
                        })
                }
                value={props.value ? { value: props.value, label: query.data.find((flow: any) => flow.uid === props.value)?.flowName } : ''}
                onChange={(selected: any) => {
                    props.setValue(selected.value);
                }}
            />
            {props.isInvalid ? (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                    {props.error}
                </div>
            ) : null}
        </>
    );
}

SelectFlow.defaultProps = {
    restrictFlows: []
};

const SelectElement: React.FC<any> = props => {
    const query = useQuery({
        queryKey: ['designFlow', props.flowId],
        queryFn: () => designFlowService.loadFlowElements(props.flowId)
    });

    if (query.isLoading) {
        return (
            <>
                <Form.Label>Block Reference</Form.Label>
                <Spinner />
            </>
        );
    }


    let firstElement: null | BackendFlow = null;
    const startElement: BackendFlow = query.data.flowElements.find((element: BackendFlow) => element.elementData?.type === 'start');
    if (startElement && startElement.targetId) {
        firstElement = query.data.flowElements.find((element: BackendFlow) => element.uid === startElement.targetId);
    }

    // if (props.value === '') {
    //     if (firstElement)
    //         props.setValue(firstElement.uid);
    // }

   

    function setValueHandler(value: string) {
        if (value.trim() === '' && firstElement) {
            props.setValue(firstElement.uid);
        } else {
            props.setValue(value);
        }
    }
    const flowElement: BackendFlow = query.data.flowElements.find((flowElement: any) => flowElement.uid === props.value);
    let blockPreview = null;

    if (props.value && flowElement && flowElement.elementData) {
        const nodeData: INode = transformBackendFlow(flowElement, { x: 0, y: 0, zoom: 0 }, false);
        const NodeComponent = nodeTypes[nodeData.type as INodeType];
        blockPreview = (
            <div className={styles.blockPreview}>
                <JumptoPreviewErrorBoundary>
                    <NodeComponent
                        readOnly
                        {...nodeData}
                    />
                </JumptoPreviewErrorBoundary>
            </div>
        );
    }
    return (
        <>
            <Form.Label className="mb-2">
                Block Reference
                {/* {flowElement ? (
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>Block ID: {flowElement.uid}</Tooltip>}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                            <path d="M8.55519 1.5C10.4118 1.5 12.1924 2.23755 13.5053 3.5504C14.8181 4.86325 15.5557 6.64385 15.5557 8.5005C15.5557 10.3571 14.8181 12.1378 13.5053 13.4506C12.1924 14.7634 10.4118 15.501 8.55519 15.501C6.69854 15.501 4.91793 14.7634 3.60509 13.4506C2.29224 12.1378 1.55469 10.3571 1.55469 8.5005C1.55469 6.64385 2.29224 4.86325 3.60509 3.5504C4.91793 2.23755 6.69854 1.5 8.55519 1.5ZM9.60519 5.798C10.1252 5.798 10.5472 5.437 10.5472 4.902C10.5472 4.367 10.1242 4.006 9.60519 4.006C9.08519 4.006 8.66519 4.367 8.66519 4.902C8.66519 5.437 9.08519 5.798 9.60519 5.798ZM9.78819 11.425C9.78819 11.318 9.82519 11.04 9.80419 10.882L8.98219 11.828C8.81219 12.007 8.59919 12.131 8.49919 12.098C8.45382 12.0813 8.4159 12.049 8.39226 12.0068C8.36861 11.9646 8.36078 11.9154 8.37019 11.868L9.74019 7.54C9.85219 6.991 9.54419 6.49 8.89119 6.426C8.20219 6.426 7.18819 7.125 6.57119 8.012C6.57119 8.118 6.55119 8.382 6.57219 8.54L7.39319 7.593C7.56319 7.416 7.76119 7.291 7.86119 7.325C7.91046 7.34268 7.95083 7.37898 7.97364 7.42609C7.99645 7.47321 7.99988 7.52739 7.98319 7.577L6.62519 11.884C6.46819 12.388 6.76519 12.882 7.48519 12.994C8.54519 12.994 9.17119 12.312 9.78919 11.425H9.78819Z" fill="#666E7D" />
                        </svg>
                    </OverlayTrigger>
                ) : null} */}
                {flowElement ? (
                    <div className="dimmed" style={{ fontSize: 12 }}>
                        <b>Block ID:</b> {flowElement.uid}
                    </div>
                ) : null}
            </Form.Label>

            {blockPreview}
            <Form.Select value={props.value} onChange={e => setValueHandler(e.target.value)}>
                <option value='' disabled>Select Block Reference</option>
                {firstElement ? (
                    <option value={firstElement.uid}>{firstElement.elementData?.type}</option>
                ) : null}
                {query.data.flowElements
                    .filter((element: BackendFlow) => element.elementType === 'block' && element.uid !== firstElement?.uid)
                    .map((element: BackendFlow) => {
                        return (
                            <option key={element.uid} value={element.uid}>{element.elementData?.type}</option>
                        );
                    })}
            </Form.Select>
            {props.isInvalid ? (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                    {props.error}
                </div>
            ) : null}
        </>
    )
}

interface FormData {
    jumpTo: {
        flowId: string;
        botUid: string;
        blockId: string;
    }
};

const JumpToEditor: React.FC<IEditorProps> = props => {
    const { flowId } = useParams();
    const [formData, setFormData] = useState<FormData>({
        jumpTo: {
            flowId: '',
            botUid: '',
            blockId: ''
        }
    });
    const { init, saveElementChanges, deleteElement } = useElmentEditor({
        type: 'jump_to',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);


    const initialFetchCompleted = useRef(false);
    useEffect(() => {
        // Define the async function inside the effect
        const fetchFlowElements = async () => {

            if( !formData.jumpTo.flowId && !props.id && !formData.jumpTo.botUid) 
            {
                if (flowId) {
                            const fetchFlowElements = async () => {
                                try {
                                    const data = await designFlowService.loadFlowElements(flowId);
                                    const botId = data.bot.uid;
                                    setFormData(oldState => { 
                                        return {
                                            ...oldState,
                                            jumpTo: {
                                                ...oldState.jumpTo,
                                                botUid: botId,
                                                flowId: flowId
                                            }
                                        }
                                    });
                                    console.log('BotsId', botId);
                                    console.log('Data from getElements', data);
                                } catch (error) {
                                    console.error('Error loading flow elements:', error);
                                }
                            };
                
                            fetchFlowElements();
                        }
            }
            if(flowId===formData.jumpTo.flowId)
            {
            try {
                const data = await designFlowService.loadFlowElements(flowId);
                // Assuming data.bot.uid exists and is the desired value
                const botId = data.bot.uid;

                // Only set formData if it's the first fetch and formData.jumpTo.flowId is still empty
                if (!initialFetchCompleted.current && !formData.jumpTo.flowId) {
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        jumpTo: {
                            ...prevFormData.jumpTo,
                            botUid: botId,
                            flowId: flowId,
                        },
                    }));
                }
                // Mark the initial fetch as completed
                initialFetchCompleted.current = true;
            } catch (error) {
                console.error('Error loading flow elements:', error);
            }
        }
        };

        fetchFlowElements();
    }, [flowId, formData.jumpTo.flowId]); // Depend on flowId and check for formData.jumpTo.flowId

    function setJumptoValue(key: 'flowId' | 'botUid' | 'blockId'): (value: string) => void {
        return function (value: string) {
            setFormData(oldState => {
                switch (key) {
                    case 'botUid':
                        return {
                            ...oldState,
                            jumpTo: {
                                botUid: value,
                                flowId: '',
                                blockId: ''
                            }
                        };
                    case 'flowId':
                        return {
                            ...oldState,
                            jumpTo: {
                                ...oldState.jumpTo,
                                flowId: value,
                                blockId: ''
                            }
                        };
                    case 'blockId':
                        return {
                            ...oldState,
                            jumpTo: {
                                ...oldState.jumpTo,
                                blockId: value
                            }
                        };
                    default:
                        return {
                            ...oldState,
                            jumpTo: {
                                ...oldState.jumpTo,
                                [key]: value
                            }
                        };
                }
            });
        }
    }

    function formSubmitHandler() {
        // event.preventDefault();
        saveElementChanges(formData);
    }

    const schema = yup.object().shape({
        jumpTo: yup.object().shape({
            flowId: yup.string().required('Flow is required'),
            botUid: yup.string().required('Bot is required'),
            blockId: yup.string().required('Block Reference is required'),
        })
    });

    return (
        <Formik
            validationSchema={schema}
            onSubmit={formSubmitHandler}
            initialValues={{
                jumpTo: {
                    flowId: '',
                    botUid: '',
                    blockId: ''
                }
            }}
        >
            {({ handleSubmit, touched, errors, setValues, handleChange }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption='Jump to' icon={<img style={{ width: 20 }} alt='' src={jumpToIcon} />} />
                        <Offcanvas.Body>
                            <FormGroup className='mb-3'>
                                <Form.Label>Bot</Form.Label>
                                <SelectBot
                                    value={formData.jumpTo.botUid}
                                    setValue={setJumptoValue('botUid')}
                                    isInvalid={(touched.jumpTo?.botUid && errors.jumpTo?.botUid) ? true : false}
                                    isValid={touched.jumpTo?.botUid && !errors.jumpTo?.botUid}
                                    error={errors.jumpTo?.botUid}
                                />
                                <input style={{ display: 'none' }} onChange={handleChange} name='jumpTo.botUid' value={formData.jumpTo.botUid} />
                            </FormGroup>
                            {formData.jumpTo.botUid !== '' ? (
                                <FormGroup className='mb-3'>
                                    <Form.Label>Flow</Form.Label>
                                    <SelectFlow
                                        botId={formData.jumpTo.botUid}
                                        value={formData.jumpTo.flowId}
                                        setValue={setJumptoValue('flowId')}
                                        isInvalid={(touched.jumpTo?.flowId && errors.jumpTo?.flowId) ? true : false}
                                        isValid={touched.jumpTo?.flowId && !errors.jumpTo?.flowId}
                                        error={errors.jumpTo?.flowId}
                                        restrictFlows={[flowId]}
                                    />
                                    <input style={{ display: 'none' }} onChange={handleChange} name='jumpTo.flowId' value={formData.jumpTo.flowId} />
                                </FormGroup>
                            ) : null}
                            {formData.jumpTo.flowId !== '' ? (
                                <FormGroup className='mb-3'>
                                    <SelectElement
                                        botId={formData.jumpTo.botUid}
                                        flowId={formData.jumpTo.flowId}
                                        value={formData.jumpTo.blockId}
                                        setValue={setJumptoValue('blockId')}
                                        isInvalid={(touched.jumpTo?.blockId && errors.jumpTo?.blockId) ? true : false}
                                        isValid={touched.jumpTo?.blockId && !errors.jumpTo?.blockId}
                                        error={errors.jumpTo?.blockId}
                                    />
                                    <input style={{ display: 'none' }} onChange={handleChange} name='jumpTo.blockId' value={formData.jumpTo.blockId} />
                                </FormGroup>
                            ) : null}
                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit'>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik >
    );
};

export default JumpToEditor;

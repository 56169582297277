import { useContext, useEffect, useState } from 'react';
import { Form, Offcanvas, Button } from 'react-bootstrap';
import { ErrorMessage, Formik } from 'formik';
import * as yup from 'yup';

import { IEditorProps } from './types';
import { useElmentEditor } from './hooks';
import EditorCaption from './editorCaption';
import { location } from '../../../icons';
import { Label } from 'reactstrap';
import SearchLocationInput from './autocompletePlace';
import initialContext from './useContext';
import './sendLocationEditor.scss';

interface FormData {
    location: {
        latitude: any | null,
        longitude: any | null,
        address: string | null,
        name: string | null,
        label: string | null
    },
    isSameFlow: boolean
};

function SendLocationEditor(props: IEditorProps) {
    const [isSearch, setIsSearch] = useState(true);
    const [isLat, setIsLat] = useState(false);
    const [staticLatLon, setStaticLatLon] =  useState({
        lat: null,
        lng: null
    });
    const [formData, setFormData] = useState<FormData>({
        location: {
            latitude: null,
            longitude: null,
            address: null,
            name: null,
            label: null
        },
        isSameFlow: false
    });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'send_location',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);

    const [selectedName, setSelectedName] = useState("");

    const schema = yup.object().shape({
        location: yup.object().shape({
            latitude: yup.number().nullable().required('Latitude is required'),
            longitude: yup.number().nullable().required('Longitude is required'),
            address: yup.string().nullable().required('Address is required'),
            name: yup.string().nullable().required('Location Name is required'),
            label: yup.lazy(value => {
                return yup.string().nullable().when(['latitude', 'longitude'], {
                    is: (latitude: any, longitude: any) => !latitude && !longitude,
                    then: yup.string().nullable().required('Location is required'),
                    otherwise: yup.string().nullable()
                });
            })
        }),
        isSameFlow: yup.boolean().nullable()
    });

    const initial = useContext(initialContext);
    const [selectedLocation, setSelectedLocation] = useState({
        lat: null,
        lng: null
    });


    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
            enableReinitialize
        >
            {({ handleSubmit, handleChange, values, touched, errors, setValues, setFieldValue }) => {

                useEffect(() => {
                    setValues(formData);
                }, [formData]);

                useEffect(() => {   

                    if(values.isSameFlow)
                    {
                        setIsLat(true);
                        setIsSearch(false);
                    }
                }, [values.isSameFlow]);

                useEffect(() => {

                    if (selectedLocation.lat && selectedLocation.lng) {
                        setFieldValue('location.latitude', selectedLocation.lat);
                        setFieldValue('location.longitude', selectedLocation.lng);
                    }
                    setFieldValue('location.label', selectedName);
                    if (selectedName === null) {
                        setFieldValue('location.latitude', null);
                        setFieldValue('location.longitude', null);
                    }

                }, [selectedLocation, selectedName]);

                let mapUrl = `https://www.google.com/maps?q=${values.location.latitude},${values.location.longitude}`;

                useEffect(() => {
                    if (isSearch) {
                        setStaticLatLon({
                            lat: values.location.latitude,
                            lng: values.location.longitude
                        });
                    }
                }, [values.location.label]);

                const labelUrl = `https://www.google.com/maps?q=${staticLatLon.lat},${staticLatLon.lng}`;

                const handleNull = (value: any) => {
                    setFieldValue('location.label', value);
                    setFieldValue('location.latitude', null);
                    setFieldValue('location.longitude', null);
                }
                
                const handleSubmission = () => {

                   if(values.location.address===null)
                    {
                        setFieldValue('location.address', values.location.label);
                    }
                   if(Object.keys(errors)?.length===0)
                   {
                      initial.edit = false;
                   }

                   if(isLat)
                   {
                       setFieldValue('isSameFlow', true);
                   }
                   else {
                          setFieldValue('isSameFlow', false);
                   }
                }

                useEffect(() => {
                    if(values.location.latitude === '') {
                        setFieldValue('location.latitude', null);
                    }
                    if(values.location.longitude === '') {
                        setFieldValue('location.longitude', null);
                    }
                }, [values.location.latitude, values.location.longitude]);

                return (
                    <Form noValidate onSubmit={handleSubmit} style={{ position: "fixed" }}>
                        <EditorCaption onHide={props.onClose} caption='Send Location' icon={<img style={{ width: 16 }} alt='' src={location} />} />
                        <Offcanvas.Body >

                            <Form.Group className='mb-3'>
                                <Label>Name <span className="required"></span></Label>
                                <Form.Control
                                    type='text'
                                    name='location.name'
                                    value={values.location.name || ''}
                                    onChange={handleChange}
                                    placeholder="Enter landmark or location name here"
                                />
                                <ErrorMessage
                                    name="location.name"
                                    component="div"
                                    className="text-danger pt-1"
                                />
                            </Form.Group>

                            <Form.Group className='mb-3'>
                                <Label>Address <span className="required"></span>
                                <div> <small>Please enter a valid address or select from search location</small></div>
                                </Label>
                                <Form.Control
                                    type='text'
                                    name='location.address'
                                    value={values.location.address || ''}
                                    onChange={handleChange}
                                    placeholder="Enter address here"
                                />
                                <ErrorMessage
                                    name="location.address"
                                    component="div"
                                    className="text-danger pt-1"
                                />
                            </Form.Group>


                            <Form.Group className='mb-2'>
                                <Form.Label>Location <span className='required'></span></Form.Label>

                                <div className="checkIcon">
                                    <br></br>
                                    <Form.Check
                                        inline
                                        type='radio'
                                        checked={isSearch}
                                        onChange={(event) => {
                                            setIsSearch(true);
                                            setIsLat(false);
                                        }}
                                    />
                                    <label className='searchLocation' >Search Location</label>

                                    <Form.Check
                                        inline
                                        type='radio'
                                        checked={isLat}
                                        onChange={(event) => {
                                            setIsLat(true);
                                            setIsSearch(false);
                                        }}
                                    />
                                    <label className='latLon'> Latitude & Longitude</label>
                                </div>

                                {initial.edit && values.location.label && isSearch && (
                                    <div>
                                        <SearchLocationInput setSelectedLocation={setSelectedLocation} setSelectedName={setSelectedName} initialValue={values.location.label} setField={(value: any) =>  handleNull(value)} />
                                        <ErrorMessage
                                            name="location.label"
                                            component="div"
                                            className="text-danger pt-1"
                                        />
                                    </div>
                                )}

                                {!initial.edit && isSearch && (
                                    <div>
                                        <SearchLocationInput setSelectedLocation={setSelectedLocation} setSelectedName={setSelectedName} initialValue={values.location.label} setField={(value: any) => handleNull(value)} />
                                        <ErrorMessage
                                            name="location.label"
                                            component="div"
                                            className="text-danger pt-1"
                                        />
                                    </div>
                                )}

                                {isLat && (
                                    <Form.Group>
                                        <Form.Control
                                            type='text'
                                            name='location.latitude'
                                            value={values.location.latitude || ''}
                                            onChange={handleChange}
                                            placeholder="Enter latitude here"
                                            onKeyDown={(event) => {
                                                const validKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Control', 'Unidentified', '-', '.'];
                                                if (!/[0-9]/.test(event.key) && !validKeys.includes(event.key)) {
                                                    event.preventDefault();
                                                } else if (event.key === '.' && event.currentTarget.value.includes('.')) {
                                                    event.preventDefault();
                                                }
                                                else if (event.key === '0' && (event.currentTarget.value === '' || event.currentTarget.value === '0')) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                        {!values.location.latitude && (
                                            <ErrorMessage
                                                name="location.latitude"
                                                component="div"
                                                className="text-danger pt-1"
                                            />)}
                                        <br></br>
                                        <Form.Control
                                            type='text'
                                            name='location.longitude'
                                            value={values.location.longitude || ''}
                                            onChange={handleChange}
                                            placeholder="Enter longitude here"
                                            onKeyDown={(event) => {
                                                const validKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Control', 'Unidentified', '-', '.'];
                                                if (!/[0-9]/.test(event.key) && !validKeys.includes(event.key)) {
                                                    event.preventDefault();
                                                } else if (event.key === '.' && event.currentTarget.value.includes('.')) {
                                                    event.preventDefault();
                                                }
                                                else if (event.key === '0' && (event.currentTarget.value === '' || event.currentTarget.value === '0')) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                        {!values.location.longitude && (
                                            <ErrorMessage
                                                name="location.longitude"
                                                component="div"
                                                className="text-danger pt-1"
                                            />
                                        )}
                                    </Form.Group>
                                )}
                            </Form.Group>

                            <Form.Group>
                                    { (values.location.latitude && isLat && values.location.longitude) || ( isSearch && values.location.label ) ? (
                                        <a href={isSearch ? labelUrl : mapUrl} className='custom-link' target="_blank" rel="noopener noreferrer" > View in Map</a>
                                    ) : (
                                        <span className="disabled-link" > View in Map</span>
                                    )}
                            </Form.Group>

                        </Offcanvas.Body>
                        <div className="editor-footer" style={{ overflow: "hidden !important", marginLeft: "1px" }} onClick={handleSubmission}>
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit'>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default SendLocationEditor;
import React, { createContext, useContext, useState } from 'react';

interface DataContextProps {
    data: Record<string, any> | any;
    setData: React.Dispatch<React.SetStateAction<Record<string, any> | any>>;
    clearData: () => void;
    setComponentName: React.Dispatch<React.SetStateAction<string | undefined>>;
    componentName: string | undefined; // Add componentName to the interface
}

const DataContext = createContext<DataContextProps | undefined>(undefined);

export const useData = () => {
    const context = useContext(DataContext);
    if (!context) throw new Error('useData must be used within a DataProvider');
    return context;
};

export const DataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [data, setData] = useState<Record<string, any> | any>({});
    const [componentName, setComponentName] = useState<string | undefined>(undefined);

    const clearData = () => {
        setData({});
        setComponentName(undefined);
    };

    return (
        <DataContext.Provider value={{ data, setData, clearData, setComponentName, componentName }}>
            {children}
        </DataContext.Provider>
    );
};







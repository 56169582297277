import { useEffect, useState } from 'react';
import { Offcanvas, Button, Form, FormGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BsFillTrashFill } from 'react-icons/bs';
import { Formik } from 'formik';
import * as yup from 'yup';
import { MdPreview } from 'react-icons/md';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { IEditorProps } from '../types';
import { useElmentEditor } from '../hooks';
import { IFile } from './fileUpload';
import FilePlaceholder from '../../nodes/sendMedia/filePlaceholder';
import EditorCaption from '../editorCaption';
import FileInput, { isValidLink } from '../fileInput';
import BodyInput from '../bodyInput';
import { mediaIcon } from '../../../../icons';
import removeVariables from '../../../../utils/removeVariables';
import * as mediaService from '../../../../services/media';

interface FormData {
    mediaType: 'image' | 'audio' | 'video' | 'document',
    attempt: number,
    caption: string;
    media: IFile | null;
    mediaUrl: string;
};

function toMediaType(mediaType: string): 'image' | 'audio' | 'video' | 'document' {
    let result: 'image' | 'audio' | 'video' | 'document' = 'document';
    if (mediaType.startsWith('audio/')) {
        result = 'audio';
    } else if (mediaType.startsWith('image/')) {
        result = 'image';
    } else if (mediaType.startsWith('video/')) {
        result = 'video';
    } else if (mediaType.startsWith('application/pdf') || mediaType.startsWith('application/msword')) {
        result = 'document';
    }
    return result;
}

function SendMediaEditor(props: IEditorProps) {
    const [formData, setFormData] = useState<FormData>({
        mediaType: 'image',
        caption: '',
        media: null,
        attempt: 1,
        mediaUrl: ''
    });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'send_media',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);

    const schema = yup.object().shape({
        mediaType: yup.string().required(),
        caption: yup.string(),
        attempt: yup.number().min(1, 'Attempt should be minimum 1').required('Attempt is a required'),
        media: yup.object().nullable(),
        mediaUrl: yup.string().test('is-valid-link', () => 'Invalid media URL', function (mediaUrl) {
            if (mediaUrl && mediaUrl.trim() !== '') {
                const isValid = isValidLink(this.parent.mediaType, mediaUrl);
                return isValid;
            }
            return true;
        }),
    }).test('media-or-mediaUrl', 'Media is required', function ({ media, mediaUrl }) {
        if (!media && (!mediaUrl || `${mediaUrl}`.trim() === '')) {
            return this.createError({
                path: 'media',
                message: 'Media is required',
            });
        }
        return true;
    });

    const removeFileHandler = async (media: any) => {
        try {
            await mediaService.deleteMedia(media);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, handleChange, values, touched, errors, setValues, setFieldValue }) => {
                const MySwal = withReactContent(Swal);
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);

                let allowedFormats = ['image/jpeg', 'image/png'];
                if (values.mediaType === 'audio') {
                    allowedFormats = ['audio/aac', 'audio/mp4', 'audio/amr', 'audio/mpeg', 'audio/ogg']
                } else if (values.mediaType === 'video') {
                    allowedFormats = ['video/mp4', 'video/3gpp']
                } else if (values.mediaType === 'document') {
                    allowedFormats = [];
                }

                function changeMediaType(mediaType: 'image' | 'audio' | 'video' | 'document') {
                    setFieldValue('mediaType', mediaType);
                    setFieldValue('mediaUrl', '');
                    setFieldValue('media', null);
                }

                function videoPreviewHandler() {
                    const url = values.media ? values.media.url : removeVariables(values.mediaUrl).trim();
                    MySwal.fire({
                        confirmButtonText: "Close",
                        title: (
                            <div>
                                {!url ? <p>No video found.</p> : (
                                    <video
                                        src={url}
                                        style={{ width: '100%' }}
                                        controls
                                        autoPlay
                                    />
                                )}
                            </div>
                        )
                    });
                }

                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption='Send Media' icon={<img style={{ width: 16 }} alt='' src={mediaIcon} />} />
                        <Offcanvas.Body>
                            <FormGroup className='mb-3'>
                                <Form.Label>Media Type  <span className='dimmed'>(File size allowed: {values.mediaType === 'image' ? '5 MB' : '16 MB'})</span></Form.Label>
                                <div>
                                    <Form.Check
                                        inline
                                        label='Audio'
                                        name='mediaType'
                                        type='radio'
                                        value='audio'
                                        disabled={values.media ? true : false}
                                        onChange={() => changeMediaType('audio')}
                                        isInvalid={touched.mediaType && !!errors.mediaType}
                                        checked={values.mediaType === 'audio'}
                                    />
                                    <Form.Check
                                        inline
                                        label='Image'
                                        name='mediaType'
                                        type='radio'
                                        value='image'
                                        disabled={values.media ? true : false}
                                        onChange={() => changeMediaType('image')}
                                        isInvalid={touched.mediaType && !!errors.mediaType}
                                        checked={values.mediaType === 'image'}
                                    />
                                    <Form.Check
                                        inline
                                        label='Video'
                                        name='mediaType'
                                        type='radio'
                                        value='video'
                                        disabled={values.media ? true : false}
                                        onChange={() => changeMediaType('video')}
                                        isInvalid={touched.mediaType && !!errors.mediaType}
                                        checked={values.mediaType === 'video'}
                                    />
                                    <Form.Check
                                        inline
                                        label='Document'
                                        name='mediaType'
                                        type='radio'
                                        value='document'
                                        disabled={values.media ? true : false}
                                        onChange={() => changeMediaType('document')}
                                        isInvalid={touched.mediaType && !!errors.mediaType}
                                        checked={values.mediaType === 'document'}
                                    />
                                </div>
                            </FormGroup>
                            <Form.Group className='mb-3'>
                                {values.media === null ? (
                                    <>
                                        <FileInput
                                            mediaType={values.mediaType}
                                            acceptType={allowedFormats.join(',')}
                                            setFile={(media) => {
                                                setFieldValue('media', media);
                                                setFieldValue('mediaType', toMediaType(media.mediaType))
                                            }}
                                            mediaUrl={values.mediaUrl}
                                            media={values.media}
                                            type='media'
                                        />
                                    </>
                                ) : (
                                    <>

                                        <div style={{ display: 'flex', alignItems: 'end' }}>
                                            <FilePlaceholder
                                                size='medium'
                                                mediaType={values.mediaType}
                                                fileName={values.media.fileName}
                                                url={values.media.url}
                                            />
                                            <Button onClick={() => {
                                                removeFileHandler(values.media);
                                                setFieldValue('media', null);
                                                setFieldValue('mediaUrl','');
                                            }} className='deleteButton' style={{ marginLeft: '12px' }}>
                                                <BsFillTrashFill />
                                            </Button>
                                            {values.mediaType === 'video' ? (
                                                <OverlayTrigger
                                                    overlay={<Tooltip>Video preview</Tooltip>}
                                                    placement='bottom'
                                                    trigger='hover'
                                                >
                                                    <Button onClick={videoPreviewHandler} variant='link' style={{ fontSize: '1.3em' }}>
                                                        <MdPreview />
                                                    </Button>
                                                </OverlayTrigger>
                                            ) : null}
                                        </div>
                                    </>
                                )}
                                {(touched.media && (errors.media && typeof errors.media) === 'string') ? (
                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        {errors.media}
                                    </div>
                                ) : null}
                            </Form.Group>
                            {values.mediaType !== 'audio' && values.mediaType !== 'document' ? (
                                <Form.Group className='mb-3'>
                                    <Form.Label>Caption <span className='dimmed'>(Optional)</span></Form.Label>
                                    <BodyInput
                                        name='caption'
                                        value={values.caption || ''}
                                        isInvalid={(touched.caption && errors.caption) ? true : false}
                                        onChange={handleChange}
                                        isValid={touched.caption && !errors.caption}
                                        error={errors.caption}
                                        label=''
                                        placeholder='Enter your caption here'
                                        limit={4096}
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.caption}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            ) : null}
                            {/* <Form.Group className='mb-3'>
                                <Form.Label>Attempt</Form.Label>
                                <Form.Control
                                    name='attempt'
                                    value={values.attempt}
                                    isInvalid={(touched.attempt && errors.attempt) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched.attempt && !errors.attempt}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.attempt}
                                </Form.Control.Feedback>
                            </Form.Group> */}

                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton mb-1' type='submit'>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default SendMediaEditor;
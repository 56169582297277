import axios from '../../utils/axios';
import { toast } from '../../common/alert';

export const getMessages = (uid : string): Promise<any> => {
    const MessageList = {
        params: {
          channelId: uid,
        }
      };
    return new Promise((resolve, reject) => {
        axios.get('canned/getAllCannedResponse', MessageList).then(res => {
            resolve(res);
        }).catch((err: any) => {
            toast('error', err.response);
        })
    });
};

export const getAssignment = (uid : string): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get(`channel/${uid}/assignment`).then(res => {
            resolve(res);
        }).catch((err: any) => {
            toast('error', err.response);
        })
    });
};

export const setAssignment = (uid:string, payload: any) =>{
    return new Promise((resolve, reject) => {
    axios.patch(`channel/${uid}/assignment`, payload).then(() => {
      toast('success', 'Assigned Successfully');
      resolve({ success: true });
    }).catch(err => {
        toast('error', err.response.data.message);
    })
})
}

export const getConfiguration = (uid : string): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get(`channel/${uid}/configuration`).then(res => {
            resolve(res);
        }).catch((err: any) => {
            toast('error', err.response);
        })
    });
};

export const setConfiguration = (uid:string, payload: any) =>{
    return new Promise((resolve, reject) => {
    axios.patch(`channel/${uid}/configuration`, payload).then(() => {
        toast('success', 'Configured Successfully');
        resolve({ success: true });
    }).catch(err => {
        toast('error', err.response.data.message);
    })
})
}
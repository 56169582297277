import { useFormikContext } from "formik";
import React from "react";

interface DaySelectionProps {
  daysList: string[];
  field: any;
  scheduledTimes: any;
  addChangeFlow?: any;
  flowIndex?: number;
}

const DaySelection: React.FC<DaySelectionProps> = ({
  daysList,
  field,
  scheduledTimes,
  addChangeFlow,
  flowIndex,
}) => {
  const { name, value } = field;
  console.log("values",field,scheduledTimes);
  const { setFieldValue } = useFormikContext<any>(); 

  const toggleDaySelection = (dayIndex: any) => {
    let newValue = [...scheduledTimes];
    if (Array.isArray(newValue[dayIndex])) {
      let innerIndex = daysList.indexOf(dayIndex); // get the inner index
      newValue[dayIndex][innerIndex] = !newValue[dayIndex][innerIndex];
    } else {
      newValue[dayIndex] = !newValue[dayIndex];
    }
    setFieldValue(name, newValue);
    addChangeFlow &&
      addChangeFlow(`scheduledTimes`, newValue, setFieldValue, flowIndex);
  };

  const toggleAllDays = () => {
    let allSelected = scheduledTimes?.every((day: any) => {
      if (Array.isArray(day)) {
        return day.every((d: any) => d);
      }
      return day;
    });
    let newValue = Array(scheduledTimes.length).fill(!allSelected);
    setFieldValue(name, newValue);
    addChangeFlow &&
      addChangeFlow(`scheduledTimes`, newValue, setFieldValue, flowIndex);
  };

  const allDaysSelected = scheduledTimes?.every((day: any) => {
    if (Array.isArray(day)) {
      return day.every((d: any) => d);
    }
    return day;
  });

  return (
    <div className="d-flex justify-content-start align-items-center">
      <div
        style={{
          marginRight: "10px",
          padding: "4px 8px",
          borderRadius: "4px",
          backgroundColor: allDaysSelected ? "#c6f6d5" : "white",
          color: allDaysSelected ? "#2f855a" : "black",
          border: "1px solid #E2E8F0",
          cursor: "pointer",
          fontSize: "smaller",
        }}
        onClick={toggleAllDays}
      >
        All days
      </div>

      {daysList.map((day: any, dayIndex: number) => (
        <div
          key={`day-${dayIndex}`}
          style={{
            marginRight: "10px",
            padding: "4px 8px",
            borderRadius: "4px",
            backgroundColor: Array.isArray(scheduledTimes[dayIndex])
              ? scheduledTimes[dayIndex][dayIndex]
                ? "#c6f6d5"
                : "white"
              : scheduledTimes[dayIndex]
              ? "#c6f6d5"
              : "white",
            color: Array.isArray(scheduledTimes[dayIndex])
              ? scheduledTimes[dayIndex][dayIndex]
                ? "#2f855a"
                : "black"
              : scheduledTimes[dayIndex]
              ? "#2f855a"
              : "black",
            border: "1px solid #E2E8F0",
            cursor: "pointer",
            fontSize: "smaller",
            width: "max",
          }}
          onClick={() => toggleDaySelection(dayIndex)}
        >
          {day}
        </div>
      ))}
    </div>
  );
};

export default DaySelection;

import Avatar from "react-avatar";
import "../styles.scss";
import { Row, Col } from "reactstrap";
import moment from "moment";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { sliceText } from "../../../../common/sliceText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import Modall from "../../../../common/modall";
import TooltipReference from "../../../../common/tooltip";
import ConversationFile from "../../../Conversations/conversationFile";
import SingleTemplate from "../../../Template/singleTemplate";
import { VscNote } from "react-icons/vsc";

interface TicketConversationProps {
  conversation: any[];
  selectedTicket: any;
}

interface Media {
  url: string;
  originalName: string;
  mediaType: string;
  fileSize: number
}

const TicketConversation: React.FC<TicketConversationProps> = (props) => {
  const members: any = useSelector(
    (state: any) => state.cartreducer.mappedMemberData
  );
  const [modalShow, setModalShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState<Media | null>(null);
  const [templateModal, setTemplateModal] = useState(
    props?.conversation.map(() => false)
  );

  const toggleTemplateModal = (index: number) => {
    // close cart details
    const newModal_s = [...templateModal];
    newModal_s[index] = !newModal_s[index];
    setTemplateModal(newModal_s);
  };

  const convertCustomFormatToHTML = (text: string): string => {
    let html = text;
    // Replace combinations of bold, italic, and strikethrough first
    html = html.replace(/\*~(.*?)~\*/g, "<strong><s>$1</s></strong>");
    html = html.replace(/_~(.*?)~_/g, "<em><s>$1</s></em>");
    html = html.replace(/\*_(.*?)_\*/g, "<strong><em>$1</em></strong>");
    html = html.replace(
      /~\*_(.*?)_\*~/g,
      "<s><strong><em>$1</em></strong></s>"
    );

    // Replace individual bold, italic, and strikethrough
    html = html.replace(/\*(.*?)\*/g, "<strong>$1</strong>");
    html = html.replace(/_(.*?)_/g, "<em>$1</em>");
    html = html.replace(/~(.*?)~/g, "<s>$1</s>");

    return html;
  };

  const formatDate = (date: Date | string): string => {
    if (moment(date).isSame(moment(), "day")) {
      return `Today ${moment(date).format("hh:mm A")}`;
    } else if (moment(date).isSame(moment().subtract(1, "days"), "day")) {
      return `Yesterday ${moment(date).format("hh:mm A")}`;
    } else {
      return moment(date).format("DD MMM hh:mm A");
    }
  };

  const closeModal = () => {
    setModalShow(false);
  };

  const downloadImage = async (urls: any) => {
    setLoader(true);
    try {
      const response = await fetch(urls);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "downloaded_image.png";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error downloading image:", error);
    }
  };

  const handleImageClick = (media: any) => {
    window.open(media.url, '_blank');
    // if (!media.mediaType.includes('application/pdf')) {
    //   setSelectedImage(media);
    //   setModalShow(true);
    // }
    // else {
    // }
  };

  // const handleTemplateModal = (index: number, template: any) => {
  //   setModalShow(true);
  //   setSelectedImage(template.url);
  // }

  const showMessagesBasedOnMessageType = (item: any, index: number) => {
    switch (item.messageType) {
      case "text":
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: convertCustomFormatToHTML(item.textMessage.text),
            }}
          ></div>
        );
      case "interactive":
        return (<>
          {item?.componentMessage?.subType === 'nfm_reply' ?
            <div>{item.componentMessage.bodyText}</div> :
            <div>{item.flowData?.description}</div>}
        </>);
      case "media":
        return (
          <>
            <div
              className="mediaFiles"
              onClick={() => handleImageClick(item?.media)}
            >
              <span>
                <FontAwesomeIcon icon={faPaperclip} color="rgb(77, 73, 73)" />
              </span>
              <span className="pl-1">{item?.media?.originalName}</span>
              {
                item?.media?.caption?.length > 0 ? (
                  <div className="caption">{item?.media?.caption}</div>
                ) : <></>
              }
            </div>
          </>
        );
      case "component":
        return (
          <>
            {item?.componentMessage?.subType === 'button_message' ?
              <div
                dangerouslySetInnerHTML={{
                  __html: convertCustomFormatToHTML(item?.componentMessage?.bodyText),
                }}
              ></div> :
              <>
                <div
                  className="templateComponent"
                  onClick={() => toggleTemplateModal(index)}
                >
                  <div className="me-1">
                    <VscNote />
                  </div>
                  <div>{`Template - ${item?.componentMessage?.messageName} `}</div>
                </div>

                <div className="display-template">
                  <Modall
                    isOpen={templateModal[index]}
                    onClose={() => toggleTemplateModal(index)}
                    size="md"
                    component={`template`}
                    title={`Template - ${item?.componentMessage?.messageName} `}
                  >
                    <SingleTemplate
                      singleTempData={item?.componentMessage}
                      component="conversation"
                      selectedConversation={props?.selectedTicket}
                    // status={m.status}
                    />
                  </Modall>
                </div>
              </>
            }
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div key={props?.selectedTicket?.id}>
      {props?.conversation?.map((item: any, ind: number) => (
        <>
          {/* <div className="vertical-line"></div> */}
          <div
            key={item.id}
            className={`conversation-item ${ind === 0 ? "mt-3" : ""}`}
          >
            <div className="message-avatar">
              <Avatar
                fgColor="#fff"
                name={
                  item.direction === 0
                    ? props.selectedTicket.contact.name
                    : members.get(item.assignId)
                }
                round
                size="35"
                textSizeRatio={2.5}
              />
            </div>
            {/* <div className="conversation-body" style={{ background: item.note ? "#fff8d5" : item.direction === 0 ? 'rgb(10 132 233 / 10%)' : '#fff' }}> */}
            <div
              className="conversation-body"
              style={{
                border: item.note
                  ? "2px dashed #f9e395"
                  : item.direction === 0
                    ? "2px dashed #008cff5e"
                    : "2px solid #ededed",
                background: item.note
                  ? "#fffef7"
                  : item.direction === 0
                    ? "#96cfff1a"
                    : "#fff",
              }}
            >
              <div className="conversation-header">
                <span className="sender">
                  {item.direction === 0
                    ? props.selectedTicket.contact.name
                    : members.get(item.assignId)}
                </span>
                <span className="sent-time">{formatDate(item.createdAt)}</span>
              </div>
              <div className="conversation-message">
                {showMessagesBasedOnMessageType(item, ind)}
                {modalShow && selectedImage && (
                  <Modall
                    isOpen={modalShow}
                    onClose={closeModal}
                    size="md"
                    component="msginput"
                    title={
                      loader ? (
                        <p className="small mb-0 loadertxt">Downloading...</p>
                      ) : (
                        <>
                          <div className="imgName">{selectedImage.originalName}</div>
                          <div
                            onClick={() => downloadImage(selectedImage)}
                            className="cursor-pointer ticketImg mt-0"
                          >
                            <TooltipReference
                              placement="bottom"
                              content="Download"
                              tooltipId="download"
                            >
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 1024 1024"
                                aria-hidden="true"
                                focusable="false"
                                height="17"
                                width="17"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M505.7 661a8 8 0 0 0 12.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V168c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path>
                              </svg>
                            </TooltipReference>
                          </div>
                        </>
                      )
                    }
                  >
                    <div className="imageContainer">
                      {
                        selectedImage?.mediaType?.includes('image') ?
                          <img
                            src={selectedImage.url}
                            alt="modalImg"
                          /> : selectedImage?.mediaType?.includes('video') ?
                            <video
                              src={selectedImage.url}
                              controls /> : null
                      }
                    </div >
                  </Modall >
                )}
              </div >
            </div >
          </div >
        </>
      ))}
    </div >
  );
};

export default TicketConversation;

import React, { useEffect, useState, useRef , useCallback } from "react";
import styles from "./Sequence.module.scss";
import {
  Container,
  Row,
  Col,
  Label,
  Button,
  Alert,
  Collapse,
  Input,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useTable, Column, StringKey } from "react-table";
import { Card, CardBody, CardHeader, CardFooter} from "reactstrap";
import SequenceTable from "./SequenceTable";
import {
  BootstrapTable,
  TableHeaderColumn,
  SelectRow,
} from "react-bootstrap-table";
import { getSequenceLogs, dropSequence, getSequenceLogsDashboard, getSequenceLogsV2 } from "../../services/SequenceService";
import async from "react-select/dist/declarations/src/async/index";
import { useNavigate, useParams, Link } from "react-router-dom";
import * as originalMoment from "moment";
import { extendMoment } from "moment-range";
import {
  Dropdown,
  Spinner,
  OverlayTrigger,
  Overlay,
  Popover,
  Form,
  ListGroup,
  Tooltip,
} from "react-bootstrap";
import DateRangePicker from "react-daterange-picker";
import {
  BsCalendar,
  BsFunnel,
  BsFunnelFill,
  BsInfoCircleFill,
  BsThreeDotsVertical,
} from "react-icons/bs";
import styleLog from "./Sequence.module.scss";
import TooltipReference from "../../common/tooltip";
import { sliceText } from "../../common/sliceText";
import Modall from "../../common/modall";
import {
  faInfoCircle,
  faUser,
  faCheck,
  faSpinner,
  faQuestionCircle,
  faTimesCircle,
  faEllipsisVertical,
  faXmarkCircle,
  faBlog,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "react-avatar";
import SyncButton from "../../common/syncButton";
import { HiDotsVertical } from "react-icons/hi";
import { useSelector } from "react-redux";
import "./sequence.scss";
import { ErrorImage } from '../../common/ErrorMessage';
import { toast } from "../../common/alert";
import BizTable from "../../common/BizTable";
import SearchInput from "../../common/searchComponent";

type Props = {};

const moment = extendMoment(originalMoment);

enum DateFilterType {
  Past7Days = "Past7Days",
  Past30Days = "Past30Days",
  Past90Days = "Past90Days",
  ThisWeek = "ThisWeek",
  PreviousWeek = "PreviousWeek",
  ThisMonth = "ThisMonth",
  PreviousMonth = "PreviousMonth",
  CustomRange = "CustomRange",
}

const SequenceLogs = () => {
  // Assuming each status is paired with a corresponding value
  const { sequenceId } = useParams();
  const navigate = useNavigate();

  const [dateFilter, setDateFilter] = useState(DateFilterType.Past7Days);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadTable, setLoadingForTable] = useState(false);
  const [isLoading,setIsLoading] = useState(false); 
  const [searchTerm,setSearchTerm] = useState<string | null>();


  const today = moment().clone().startOf("day");
  const [dateRangeValue, setDateRangeValue] = useState(
    moment.range(
      today.clone().subtract(7, "days").startOf("day"),
      today.endOf("day")
    )
  );
  const [getSequencelogsData, setSequenceLogsData] = useState({
    // enrolledCount: 0,
    // completeCount: 0,
    // inProgressCount: 0,
    // successCount: 0,
    // failedCount: 0,
    // dropedCount: 0,
    sequenceLogs: [
      {
        sequence: {
          sequenceName: "",
          status: "",
        },
      },
    ],
  });
  const [logsData,setLogsData] = useState([]);

  const [sequenceDashboard, setSequenceDashboard] = useState({
    enrolledCount: 0,
    completeCount: 0,
    inProgressCount: 0,
    successCount: 0,
    failedCount: 0,
    dropedCount: 0,
  })

  const {
    enrolledCount,
    completeCount,
    inProgressCount,
    successCount,
    failedCount,
    dropedCount,
    // sequenceLogs,
  } = sequenceDashboard;
  const { sequenceLogs } = getSequencelogsData;
  console.log('getSequencelogsData',getSequencelogsData,sequenceLogs)


  const sequenceName = sequenceLogs[0]?.sequence?.sequenceName ?? "";
  const sequenceStatus = [
    { status: "Enrolled", value: enrolledCount },
    { status: "Completed", value: completeCount },
    { status: "In Progress", value: inProgressCount },
    { status: "Dropped", value: dropedCount },
    { status: "Success", value: successCount },
    { status: "Failed", value: failedCount },
  ];
  
  const dashboardData = async()=>{
    setLoading(true);
    setIsLoading(true);
    if (sequenceId) {
      const logsPayload = {
        fromTimeUTC: dateRangeValue.start.valueOf(),
        toTimeUTC: dateRangeValue.end.valueOf(),
      };
    const dashboard = await getSequenceLogsDashboard(sequenceId, logsPayload);
    const response = await getSequenceLogsV2(sequenceId, logsPayload);
    setSequenceLogsData(response);
    const sequenceLogs = response?.sequenceLogs;
    setLogsData(sequenceLogs);
    setSequenceDashboard(dashboard);
    setLoading(false);
    setIsLoading(false);
    }
  }

  const getLogsData = async () => {
    setLoading(true);
    if (sequenceId) {
      const logsPayload = {
        fromTimeUTC: dateRangeValue.start.valueOf(),
        toTimeUTC: dateRangeValue.end.valueOf(),
      };
      dashboardData()
      // const sequenceLogs = await getSequenceLogsV2(sequenceId, logsPayload);
      // setSequenceLogsData(sequenceLogs);
      setLoading(false);
     
    }
  };

  const [isLogs, setIsLogs] = useState(false);
  const [singleSequence, setSingleSequence] = useState([]);

  const onRowClick = (row: any) => {
    const { messageLogs } = row;  // Assuming messageLogs is an array of logs
    setIsLogs(!isLogs);
    const updatedMessageLogs = messageLogs.map((log:any) => ({
        ...log,
        id: log.messagesId,  // Create a new 'id' key from 'messageId'
    }));

    setSingleSequence(updatedMessageLogs);
};


  const handleCloseLogs = () => {
    setIsLogs(false);
  };

  // const options = {
  //   headerAlign: "center",
  //   noDataText: "No records found",
  //   sortIndicator: true,
  //   hidePageListOnlyOnePage: true,
  //   clearSearch: false,
  //   alwaysShowAllBtns: true,
  //   withFirstAndLast: true,
  //   sizePerPage: 10,
  //   onRowClick: onRowClick,
  //   paginationPosition: "bottom", // default is bottom, top and both is all available
  //   rowStyle: { cursor: "pointer" },
  //   // Add this line to set the cursor pointer
  // };
  // const searchKey = (searchTerm: any) => {
  //   if (!searchTerm) {
  //     setAlldata(orgData);
  //     return;
  //   }
  //   const searchTermCheck = searchTerm.length > 0 ? searchTerm.toLowerCase() : null;

  //   if (allData) {
  //     const filteredData = allData.filter((item: any) =>
  //       item.name.toLowerCase().includes(key.toLowerCase())
  //     );

  //     setAlldata(filteredData);

  //   } else {
  //     setAlldata(orgData)
  //   }
  // }


  const nameFormatter = (cell: any, row: any) => {
    return (
      <div>
        {
          <>
            <span className="mr-2 ml-2">
              <Avatar
                fgColor="#4c5a80"
                name={cell}
                round
                size="23"
                textSizeRatio={2.5}
              />
            </span>
            {cell?.length > 11 ? (
              <TooltipReference
                placement="right"
                content={cell}
                tooltipId={`sequence_${row.id}`}
              >
                <span className="mt-1"> {sliceText(cell, 12)}</span>
              </TooltipReference>
            ) : (
              <span className="mt-1">{cell}</span>
            )}
          </>
        }
      </div>
    );
  };

  const mobileFormatter = (cell: any, row: any) => {
    const countryCodeLength = row?.countryCode ? row.countryCode.length : 0;
    return (
      "+" +
      row?.countryCode +
      " " +
      (cell?.slice(countryCodeLength) || "-")
    );
  };

  
  const dateFormat = (date: any) => {
    return (
      <>
        {date ? (
          <>
            {" "}
            <span>{moment(date).format("DD MMM YYYY")}</span>
            <span className="lastLoginTime">
              {moment(date).format("hh:mm A")}
            </span>
          </>
        ) : (
          "-"
        )}
      </>
    );
  }

  // const entrolledFormatter = (date: string) => {
  //   return dateFormat(date)
  // };

  const logsNameFormatter = (cell: any, row: any) => {
    const safeId = `tooltip_${cell?.replace(/[^a-zA-Z0-9]/g, '_')}`; // Replace invalid characters with underscores
    return (
      <div>
        {cell && (
          <TooltipReference
            placement="right"
            content={cell}
            tooltipId={safeId}
          >
            {sliceText(cell, 12)}
          </TooltipReference>
        )}
      </div>
    );
  };

  const timeFormatter = (date: string) => {
    return (
      <>
        {date ? (
          <>
            <span>{moment(date).format("DD MMM YYYY")}</span>
            <span className="lastLoginTime">
              {moment(date).format("hh:mm A")}
            </span>
          </>
        ) : (
          "-"
        )}
      </>
    );
  };

  const statusCode = (code: number) => {
    switch (code) {
      case -1:
        return 'Not Sent';
      case 1:
        return 'sending';
      case 2:
        return 'sent';
      case 3:
        return 'delivered';
      case 4:
        return 'read';
      default:
        return '';
    }
  };

  const statusData = (cell: any, row: any) => {
    const status = statusCode(cell);
    const { messagesId, errorMessages } = row;
    if (status === 'Not Sent') {
      const safeErrorId = `error_${messagesId}`.replace(/[^a-zA-Z0-9]/g, '_'); // Replace invalid characters with underscores
      return (
        <>
          {status} {' '}
          <TooltipReference
            placement="top"
            content={errorMessages}
            tooltipId={safeErrorId}
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </TooltipReference>
        </>
      );
    } else {
      return status;
    }
  };


  const memberUid = useSelector(
    (state: any) => state.cartreducer.business?.uid
  );
  // const [isRemovePregress, setIsRemoveProgress] = useState(false);
  const removeProgress = async (event: any, row: any) => {
    const { uid } = row;
    event.stopPropagation();
    if (sequenceId) {
      const dropPayload = {
        memberUid: memberUid,
        sequenceLogsUid: uid,
      };
      const response = await dropSequence(sequenceId, dropPayload);
      if (response) {
        getLogsData();
      }
    }
  };

  const sequenceStatusData = (cell: any, row: any) => {
    const isDropped = cell === "Dropped";
    const color = getColorsBasedOnStatus(isDropped ? "Dropped" : cell);

    return (
      <div className="d-flex justify-content-md-center align-items-center">
        <div style={{ color }} className="fontWeight">
          {isDropped ? (
            <>
              Dropped{" "}
              <TooltipReference
                placement="bottom"
                icon={faInfoCircle}
                content={row?.error}
                tooltipId={`droped_${row.id}`}
              ></TooltipReference>
            </>
          ) : (
            cell
          )}
        </div>
        {cell && cell === "In Progress" && (
          <div className="ms-3">
            <UncontrolledDropdown group>
              <DropdownToggle
                className="optionBorder p-1"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <FontAwesomeIcon
                  color="#666E7D"
                  fontSize={18}
                  icon={faEllipsisVertical}
                  className="px-2 pt-1"
                />
              </DropdownToggle>
              <DropdownMenu className="ctxMenu">
                <DropdownItem
                  className="ctxMenuItem"
                  onClick={(event) => {
                    removeProgress(event, row);
                  }}
                >
                  <FontAwesomeIcon className="mr-2" icon={faXmarkCircle} />
                  Remove from sequence
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    getLogsData();
  }, [dateRangeValue]);

  const columns = [
    {
      Header: (
        <input
        type="checkbox"
        // checked={this.state.allChecked}
        // onChange={this.toggleAllCheckboxes}
        hidden={true}
        />
      ),
      accessor: "checkbox",
      sticky: "left",
    },
    {
      Header: "Contact Name",
      accessor: "contactName",
      sticky: "left",
      Cell: ({ cell, row }: any) => nameFormatter(cell.value, row.original),
    },
    {
      Header: "Mobile Number",
      accessor: "contactMobileNumber",
      Cell: ({ cell, row }: any) => mobileFormatter(cell.value, row.original),
    },
    {
      Header: "Enrolled Date",
      accessor: "createdAt",
      Cell: ({ cell, row }: any) => dateFormat(cell.value),
    },
    {
      Header: "steps Completed",
      accessor: "stepsCompleted",
      // Cell: ({ cell, row }: any) => formatCreatedOn(cell.value),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ cell, row }: any) => sequenceStatusData(cell.value, row.original),
    },
  ] as any;

  const onSearchChange = (searchTerm: string) => {
    const searchTermCheck = searchTerm.length > 0 ? searchTerm?.trim() : null;
    setSearchTerm(searchTermCheck);
    if (searchTermCheck) {
      const filtered = logsData.filter((item: any) =>
        item.contactName.toLowerCase().includes(searchTermCheck.toLowerCase())
      );
      // Update state with filtered data
      setSequenceLogsData({ ...getSequencelogsData, sequenceLogs: filtered });
    } else {
      setSequenceLogsData({...getSequencelogsData,sequenceLogs:logsData});
    }
};


  const columnStatus = [
    {
      Header: (
        <input
        type="checkbox"
        // checked={this.state.allChecked}
        // onChange={this.toggleAllCheckboxes}
        hidden={true}
        />
      ),
      accessor: "checkbox",
      sticky: "left",
    },
    {
      Header: "Name",
      accessor: "messageName",
      sticky: "left",
      Cell: ({ cell, row }: any) => logsNameFormatter(cell.value, row.original),
    },
    {
      Header: "Time",
      accessor: "time",
      Cell: ({ cell, row }: any) => timeFormatter(cell.value),
    },
    {
      Header: "Status",
      accessor: "messagesStatus",
      Cell: ({ cell, row }: any) => statusData(cell.value, row.original),
    },
  ] as any;

  const trStyle = (row: any, rowIndex: any) => {
    return { cursor: "pointer" };
  };

  const getStartDate = (
    subtractDays: number,
    unit: moment.unitOfTime.DurationConstructor = "days"
  ) => {
    return today.clone().subtract(subtractDays, unit).startOf(unit);
  };

  const changeDateFilterHandler = (event: any) => {
    const filter = event.target.value as DateFilterType;

    switch (filter) {
      case DateFilterType.Past7Days:
        setDateRangeValue(
          moment.range(
            today.clone().subtract(7, "days").startOf("day"),
            today.endOf("day")
          )
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.Past30Days:
        setDateRangeValue(moment.range(getStartDate(30), today.endOf("day")));
        setShowDatePicker(false);
        break;

      case DateFilterType.Past90Days:
        setDateRangeValue(moment.range(getStartDate(90), today.endOf("day")));
        setShowDatePicker(false);
        break;

      case DateFilterType.ThisWeek:
        setDateRangeValue(
          moment.range(today.clone().startOf("week"), today.endOf("day"))
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.PreviousWeek:
        setDateRangeValue(
          moment.range(
            getStartDate(1, "week"),
            getStartDate(1, "week").endOf("week")
          )
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.ThisMonth:
        setDateRangeValue(
          moment.range(today.clone().startOf("month"), today.endOf("day"))
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.PreviousMonth:
        setDateRangeValue(
          moment.range(
            getStartDate(1, "month"),
            getStartDate(1, "month").endOf("month")
          )
        );
        setShowDatePicker(false);
        break;

      case DateFilterType.CustomRange:
        setShowDatePicker(true);
        break;

      default:
        break;
    }
    setDateFilter(filter);
  };

  const backTosequence = () => {
    navigate("/sequence");
  };

  const datepickertarget = useRef(null);
  const currentDate = new Date();

  const getColorsBasedOnStatus = (status: string) => {
    switch (status) {
      case "Enrolled":
        return "#38a169";
      case "Completed":
        return "#38a169";
      case "In Progress":
        return "#e39c28";
      case "Success":
        return "#38a169";
      case "Failed":
        return "rgb(244 109 111)";
      case "Dropped":
        return "#73869b";
    }
  };

  const getIconsBasedOnStatus = (status: string) => {
    switch (status) {
      case "Enrolled":
        return <FontAwesomeIcon icon={faUser} />;
      case "Completed":
        return <FontAwesomeIcon icon={faCheck} />;
      case "In Progress":
        return <FontAwesomeIcon icon={faSpinner} />;
      case "Success":
        return <FontAwesomeIcon icon={faCheck} />;
      case "Failed":
        return <FontAwesomeIcon icon={faTimesCircle} />;
      case "Dropped":
        return <FontAwesomeIcon icon={faQuestionCircle} />;
    }
  };
  const data = () => { };

  return (
    <>
      {loading ? (
        <div className="centerItemsWithWidth">
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div>
          <div className="filter">
            <Row className="pt-4 align-items-center">
              <Col md="6" className={styleLog.logsHeader}>
                <svg
                  onClick={backTosequence}
                  aria-hidden="true"
                  height={20}
                  width={20}
                  className="me-2"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="arrow-left"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
                  ></path>
                </svg>
                <h6>
                  <span>{sequenceName} Logs</span>
                  <span
                    className={`${styles.status} ${sequenceLogs[0]?.sequence?.status} ${styles.logsStatus}`}
                  >
                    {sequenceLogs[0]?.sequence?.status === "Publish"
                      ? "Published"
                      : sequenceLogs[0]?.sequence?.status}
                  </span>
                </h6>
              </Col>
              <Col>
                <div className="sequenceLogsEdit">
                  <Link
                    to={`${process.env.PUBLIC_URL}/sequence/${sequenceId}/updatesequence`}
                  >
                    <svg
                      width="16"
                      height="16"
                      className="me-2"
                      // onClick={() => this.handleClickEdit(cell, rowData)}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 3H3.22222C2.89807 3 2.58719 3.12877 2.35798 3.35798C2.12877 3.58719 2 3.89807 2 4.22222V12.7778C2 13.1019 2.12877 13.4128 2.35798 13.642C2.58719 13.8712 2.89807 14 3.22222 14H11.7778C12.1019 14 12.4128 13.8712 12.642 13.642C12.8712 13.4128 13 13.1019 13 12.7778V8.5"
                        stroke="#FFFFFF"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.9485 2.35199C12.1738 2.12661 12.4795 2 12.7982 2C13.117 2 13.4226 2.12661 13.648 2.35199C13.8734 2.57737 14 2.88304 14 3.20177C14 3.5205 13.8734 3.82617 13.648 4.05155L8.26608 9.43348L6 10L6.56652 7.73392L11.9485 2.35199Z"
                        stroke="#FFFFFF"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <Label
                      style={{ cursor: "pointer", color: "white" }}
                      className="m-0"
                    >
                      Edit
                    </Label>
                  </Link>
                </div>
              </Col>
              <Col md="5">
                <div className="d-flex justify-content-end col">
                  <div className="me-2">
                    <SyncButton onClick={()=>{dashboardData()
                      setTimeout(()=>{toast("success", "Sequence Logs synced successfully");
                      },1000)
    
                    }} />
                  </div>
                  <div className={`mr-2 filters  ${styles.alignDatepicker}`}>
                    <Input
                      className={`dateFilter ${styles.dateFilter}`}
                      type="select"
                      size={1}
                      value={dateFilter}
                      style={{
                        fontFamily: '"Public Sans", sans-serif',
                        fontSize: "14px",
                      }}
                      onChange={changeDateFilterHandler}
                    >
                      <option value={DateFilterType.Past7Days}>
                        Past 7 days
                      </option>
                      <option value={DateFilterType.Past30Days}>
                        Past 30 days
                      </option>
                      <option value={DateFilterType.Past90Days}>
                        Past 90 days
                      </option>
                      <option value={DateFilterType.ThisWeek}>This week</option>
                      <option value={DateFilterType.PreviousWeek}>
                        Previous week
                      </option>
                      <option value={DateFilterType.ThisMonth}>
                        This month
                      </option>
                      <option value={DateFilterType.PreviousMonth}>
                        Previous month
                      </option>
                      <option value={DateFilterType.CustomRange}>
                        Custom range
                      </option>
                    </Input>
                  </div>
                  <Overlay
                    target={datepickertarget.current}
                    show={showDatePicker}
                    placement="bottom"
                  >
                    <Popover id="popover-basic" style={{ maxWidth: "350px" }}>
                      <DateRangePicker
                        value={dateRangeValue}
                        minDate={currentDate}
                        onSelect={(value: any) => {
                          setDateRangeValue(
                            moment.range(
                              moment(value.start).startOf("day"),
                              moment(value.end).endOf("day")
                            )
                          );
                          setDateFilter(DateFilterType.CustomRange);
                          setShowDatePicker(false);
                          // campaignFilterQuery.refetch();
                        }}
                        singleDateRange={true}
                      />
                    </Popover>
                  </Overlay>
                  <div
                    className={`form-control calenderInput ${styles.alignDatepicker}`}
                    ref={datepickertarget}
                    onClick={() => setShowDatePicker((current) => !current)}
                  >
                    <BsCalendar />
                    <span className="value ml-2">
                      {dateRangeValue.start.format("DD/MM/YYYY")}
                      {" - "}
                      {dateRangeValue.end.format("DD/MM/YYYY")}
                    </span>
                  </div>
                </div>
              </Col>
              {/* <Col md="3">
           
          </Col> */}
            </Row>

            {/* {isLogs && singleSequence ? ( */}
              <>
                <Modall
                  isOpen={isLogs}
                  onClose={handleCloseLogs}
                  // title={singleSequence}
                  size="lg"
                >
                        <BizTable
                          columns={columnStatus}
                          counts={0}
                          refetchData={singleSequence}
                          tableName="SEQUENCELOGS_STATUS"
                        />
                </Modall>
              </>
            {/* ) : (
              ""
            )} */}
          </div>

          {/* </div> */}
          <div>
            <Row className="pt-3">
              {sequenceStatus.map((sequence, index) => (
                <Col key={index}>
                  <Card>
                    <CardBody className="text-center">
                      <div
                        style={{
                          color: getColorsBasedOnStatus(sequence.status),
                        }}
                      >
                        {sequence.status}
                      </div>
                      <div
                        className={`${styles.sequenceStatusCount}`}
                        style={{
                          color: getColorsBasedOnStatus(sequence.status),
                        }}
                      >
                        <span>{getIconsBasedOnStatus(sequence.status)}</span>
                        <span className="pl-1">{sequence.value}</span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
           <Row>
              <Col md='4' className="pb-2">
              <SearchInput onSearchChange={onSearchChange} component="sequenceLogs" placeHolder={"Search Sequence"} />
              </Col>
           </Row>
            {/* {isLoading?
             <div className="centerItemsWithWidth">
            <div className="spinner-border text-success" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>: */}
            <Row>
              <BizTable
              columns={columns}
              counts={0}
              refetchData={sequenceLogs}
              tableName="SEQUENCELOGS"
              handleRowClick={onRowClick}
            />
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default SequenceLogs;

import React, { ElementType, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles of ReactQuill
import styles from './bodyInput.module.css';
import AddVariable from './addVariable';
import { Popover, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceSmile } from '@fortawesome/free-regular-svg-icons';
import EmojiPicker, { Theme, EmojiStyle } from 'emoji-picker-react';

interface BodyInputProps {
    name: string;
    as?: ElementType<any> | 'rich-text';
    limit?: number;
    value: string;
    isInvalid: boolean;
    isValid: any;
    label?: string;
    onChange: any;
    error: any;
    placeholder?: string;
    required?: boolean;
}

type ReactQuillInstance = {
    focus(): unknown;
    getEditor: () => any;
    makeUnprivilegedEditor: (editor: any) => { root: HTMLElement };
    editingArea: any;
};

const BodyInput: React.FC<BodyInputProps | any> = ({ limit, as, name, value, isInvalid, onChange, isValid, error, label, placeholder, required }) => {
    const textareaRef = useRef(null);
    const [input, setInput] = useState('');
    const [showAddVariable, setShowAddVariable] = useState(true);
    const [limitExceed, setLimitExceed] = useState(false);
    const [lastInput, setLastInput] = useState<any>(null);
    const [emojiPopupOpen, setEmojiPopupOpen] = useState(false);

    const editableRef = React.useRef<ReactQuillInstance | null>(null);

    showAddVariable && placeholder === "Add Notes here..." && setShowAddVariable(false)

    const toolbarOptions = [
        ['bold', 'italic', 'strike']
    ];

    const insertEmojiHandler = (emoji: string) => {
        if (editableRef.current) {
            const quill = editableRef.current.getEditor();
            const lastLineIndex = quill.getLength() - 1;
            quill.insertText(lastLineIndex, emoji, "user");
            quill.setSelection(lastLineIndex + 1, "silent");
            quill.focus();
        }
    }

    const emojiRef = useRef<HTMLDivElement>(null);
    const triggerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                emojiRef.current && !emojiRef.current.contains(event.target as Node) &&
                triggerRef.current && !triggerRef.current.contains(event.target as Node)
            ) {
                setEmojiPopupOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const markdownToHTML = (markdownText: any) => {
        // Replace **bold** with <strong>bold</strong>
        let htmlText = markdownText?.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
        // Replace _italic_ with <em>italic</em>
        htmlText = htmlText?.replace(/_([^{}_]*)_/g, '<em>$1</em>');
        // Replace ~strikethrough~ with <s>strikethrough</s>
        htmlText = htmlText?.replace(/~(.*?)~/g, '<s>$1 </s>');
        return htmlText;
    };
    useEffect(() => {
        if (value && extractTextFromHTML(input).replace(/\s+/g, '') !== value.replace(/\s+/g, '') && !limitExceed && value.length <= limit) {
            let htmlText = markdownToHTML(value);
            htmlText = htmlText?.replace(/<\/?p>/g, '');
            htmlText = '<p>' + htmlText + '</p>';
            setInput(htmlText);
        }
    }, [value])

    useEffect(() => {
        if (value == "  ") {
            setInput("");
        }
    }, [input]);

    function handleTextareaChange(content: string) {
        let newValue = content;

        // Remove optional spaces between markdown symbols and the text
        newValue = newValue.replace(/\*\*\s?(.*?)\s?\*\*/g, '**$1**');
        newValue = newValue.replace(/_\s?(.*?)\s?_/g, '_$1_');
        newValue = newValue.replace(/~\s?(.*?)\s?~/g, '~$1~');

        if (newValue.length > (limit || 1024)) {
            newValue = newValue.substring(0, (limit || 1024));
        }
        // Custom onChange to handle ReactQuill value
        onChange({ target: { name, value: newValue.trim() } });
    }

    const extractTextFromHTML = (htmlString: string): string => {
        const doc = new DOMParser().parseFromString(htmlString, 'text/html');
        let text = '';

        // Recursive function to process each node
        const processNode = (node: any) => {
            if (node.nodeType === Node.TEXT_NODE) {
                // Directly append text node content
                text += node.nodeValue?.trim();
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                // Handle specific element nodes
                const element = node;

                switch (element.tagName.toLowerCase()) {
                    case 'strong':
                        text += " " + '*' + element.textContent?.trim() + '*' + " ";
                        break;
                    case 'em':
                        // For <em>, wrap the content with *
                        let content = element.textContent?.trim();
                        text += " " + '_' + content + '_' + " ";
                        break;
                    case 's':
                        text += '~' + element.textContent + '~';
                        break;
                    default:
                        // For any other tags, recursively process children
                        element.childNodes.forEach(processNode);
                        break;
                }
                // Append a newline for paragraphs for readability, if the paragraph has a next sibling
                if (element.tagName.toLowerCase() === 'p' && element.nextElementSibling) {
                    text += '\n';
                }
            }
        };

        if (doc.body) {
            doc.body.childNodes.forEach(processNode);
        }

        return text;
    };

    return (
        <Form.Group style={{ width: '100%' }}>
            {label && (
                <Form.Label>
                    {label}
                    {required !== undefined ? (
                        required ? <span className='required'></span> : <span className='text-muted ml-1'>(Optional)</span>
                    ) : null}
                </Form.Label>
            )}
            {as === 'textarea' && (

                <>
                    <div className={`${styles.inputBox} ${isInvalid ? styles.invalid : ''}`}>
                        <ReactQuill
                            ref={editableRef}
                            theme="snow"
                            className={styles.customQuill}
                            value={input}
                            onChange={(content: string) => {
                                if (extractTextFromHTML(input)?.length <= (limit || 4096)) {
                                    if (extractTextFromHTML(input)?.length !== limit) {
                                        setInput(content);
                                    }
                                    handleTextareaChange(extractTextFromHTML(content));
                                    setLimitExceed(false);
                                    if (extractTextFromHTML(content)?.length == limit) {
                                        setLastInput(input);
                                    }
                                }
                                else if (!limitExceed) {
                                    setLimitExceed(true);
                                }
                                else {
                                    setInput(lastInput);
                                }
                            }}
                            placeholder={placeholder || ''}
                            modules={{
                                toolbar: {
                                    container: "#toolbar",
                                }
                            }}
                            style={{ whiteSpace: 'pre-wrap' }}
                        />
                        <div className={styles.Quill}>
                            <div className='d-flex' id="toolbar">
                                <div className='d-flex' id="toolbar">
                                    <UncontrolledTooltip placement="top" target="Tooltip-Bold" >
                                        Bold
                                    </UncontrolledTooltip>
                                    <button id="Tooltip-Bold" className="ql-bold" ></button>
                                    <UncontrolledTooltip placement="top" target="Tooltip-Italic">
                                        Italic
                                    </UncontrolledTooltip>
                                    <button id="Tooltip-Italic" className="ql-italic" ></button>
                                    <UncontrolledTooltip placement="top" target="Tooltip-Strikethrough">
                                        Strikethrough
                                    </UncontrolledTooltip>
                                    <button id="Tooltip-Strikethrough" className="ql-strike" ></button>
                                    {/* <button className="ql-underline"></button> */}
                                </div>
                                <div className="mx-1" style={{ position: 'relative' }}>
                                    {!emojiPopupOpen && (
                                        <UncontrolledTooltip placement="top" target="convAddEmoji" className={styles.emoji}>
                                            Pick an Emoji
                                        </UncontrolledTooltip>
                                    )}
                                    <div ref={triggerRef}>
                                        <FontAwesomeIcon
                                            icon={faFaceSmile}
                                            className={`ml-2 mb-2 mt-1 ${styles.emoji}`}
                                            id="convAddEmoji"
                                            color="#90939F"
                                            fontSize={16}
                                            onClick={() => setEmojiPopupOpen((current) => !current)}
                                        />
                                    </div>
                                    <Popover
                                        placement="bottom"
                                        target="convAddEmoji"
                                        isOpen={emojiPopupOpen}
                                        toggle={() => setEmojiPopupOpen((current) => !current)}
                                        trigger="click"
                                        className={styles.customPopover}
                                    >
                                        <div className={styles.emojiPicker} ref={emojiRef}>
                                            <EmojiPicker
                                                skinTonesDisabled
                                                theme={Theme.LIGHT}
                                                emojiStyle={EmojiStyle.APPLE}
                                                onEmojiClick={({ emoji }) => {
                                                    insertEmojiHandler(emoji);
                                                }}
                                            />
                                        </div>
                                    </Popover>
                                </div>

                                {showAddVariable && (
                                    <div className={styles.addVariable}>
                                        <AddVariable static product fieldName={name} fieldValue={extractTextFromHTML(input)} limit={extractTextFromHTML(input)?.length + 40 > limit ? false : true} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {(input && typeof value === "string") && (
                        <div className={styles.textareaStatus}>
                            <div>{value?.length}/{limit || 4096}</div>
                        </div>
                    )}
                </>
            )}

            <Form.Control.Feedback type='invalid'>
                {error}
            </Form.Control.Feedback>
        </Form.Group>
    );
};

BodyInput.defaultProps = {
    as: 'textarea',
    label: 'Message'
};

export default BodyInput;
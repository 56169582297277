import { useFormikContext } from "formik";
import { Form } from "react-bootstrap";

import ValidationSettings from "./validationSettings"
import BodyInput from "../bodyInput";
import { useEffect } from "react";

interface AdvancedSettingsProps {
};

const AdvancedSettings: React.FC<AdvancedSettingsProps> = () => {
    const { values, touched, errors, handleChange, setFieldValue } = useFormikContext<{
        errorMessage: string;
        attempt: number;
        endFlow: boolean;
        isSameFlow: boolean;
    }>();

    return (
        <ValidationSettings
            initiallyOpen={values.attempt !== 1 || ('errorMessage' in values ? values.errorMessage.trim() !== "" : false) || values.isSameFlow }
            onCancel={() => { }}
            caption='Advanced Settings'
            setFieldValue={setFieldValue}
        >
            <Form.Group className='mb-3'>
                <Form.Label>Attempt <span className="required"></span></Form.Label>
                <Form.Control
                    name='attempt'
                    type='number'
                    value={values.attempt}
                    isInvalid={(touched.attempt && errors.attempt) ? true : false}
                    onChange={handleChange}
                    isValid={touched.attempt && !errors.attempt}
                />
                <span className='dimmed'>Stop chatbot when validation failures exceed a specified count</span>
                <Form.Control.Feedback type='invalid'>
                    {errors.attempt}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-3'>
                <Form.Label>Action on Invalid Response <span className="required"></span></Form.Label>
                <div>
                    <Form.Check
                        inline
                        label='End flow'
                        name='endFlow'
                        type='radio'
                        value='true'
                        checked={values.endFlow}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue("endFlow", event.target.checked);
                        }}

                    />
                    <Form.Check
                        inline
                        label='Skip question'
                        name='endFlow'
                        type='radio'
                        value="false"
                        checked={!values.endFlow}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue("endFlow", !event.target.checked);
                        }}
                    />
                </div>
            </Form.Group>
            <Form.Group className='mb-3'>
                <BodyInput
                    name='errorMessage'
                    value={values.errorMessage}
                    isInvalid={(touched.errorMessage && errors.errorMessage) ? true : false}
                    onChange={handleChange}
                    isValid={touched.errorMessage && !errors.errorMessage}
                    label={<>Validation Error Message <span className="dimmed">(Optional)</span></>}
                    placeholder='Enter the Error Messages'
                    limit={4096}
                />
                <Form.Control.Feedback type='invalid'>
                    {errors.errorMessage}
                </Form.Control.Feedback>
            </Form.Group>
        </ValidationSettings>
    )
}

export default AdvancedSettings;
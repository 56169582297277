import React, { useState, useEffect, useRef } from "react";
import { Field, Form, Formik } from "formik";
import Select, { components } from "react-select";
import makeAnimated from "react-select/animated";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import {
  getTicketFields,
  ticketFilter,
  ticketsDashBoard,
} from "../../../../services/ticketService";
import { IoCloseCircle, IoSearchOutline } from "react-icons/io5";
import { LuFilter, LuInbox } from "react-icons/lu";
import { HiPlus } from "react-icons/hi";
import RightSidebar from "../../../../common/rightSidebar";
import Avatar from "react-avatar";
import TooltipReference from "../../../../common/tooltip";
import { MdOutlineSubject } from "react-icons/md";
import templateicon from "../../../../assets/img/svgs/template.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import "../styles.scss";
import DateRangePicker from "react-daterange-picker";
import { Overlay, Popover } from "react-bootstrap";
import { IoChevronDownOutline } from "react-icons/io5";
import { IoChevronUpOutline } from "react-icons/io5";
import * as originalMoment from "moment";
import { extendMoment } from "moment-range";
import { toast } from "../../../../common/alert";
import { useNavigate } from "react-router-dom";
import { CiCamera } from "react-icons/ci";
import { IoIosVideocam } from "react-icons/io";
import { IoIosCloseCircleOutline } from "react-icons/io";
// import { BsFilter } from "react-icons/bs";
import { FaAngleUp } from "react-icons/fa6";
import {
  getAllTeam,
  getAllTeams,
} from "../../../../services/contactMemberService";

const moment = extendMoment(originalMoment);

interface Ticket {
  id: number;
  uid: string;
  contact: {
    name: string;
  };
  createdAt: string;
  updatedAt: string;
  latestMessage: {
    messageType: string;
    flowData?: {
      description: string;
    };
    media?: {
      mediaType: string;
    };
    messageReceivedTime: string;
  };
}

interface TagResponse {
  id: number;
  name: string;
  createdAt: string;
  memberDTO: {
    uid: string;
    name: string;
  };
}

interface DateRange {
  start: moment.Moment | null;
  end: moment.Moment | null;
}

interface Option {
  label: string;
  value: string | number;
}

interface TicketField {
  id: number;
  name: string;
  label: string;
  position: number;
  type: string;
  dropDown: boolean;
  options: Option[];
}

interface SelectProps {
  data: Ticket[];
  onScrollToEnd: (search: string) => void;
  onSelect: (ticket: Ticket) => void;
  selectedTicket: Ticket | null;
  setSelectedTicket: React.Dispatch<React.SetStateAction<any>>;
  setLoadTicket: (loadTicket: boolean) => void;
  hasMoreTickets: boolean;
  loadTickets: (page: number, search?: string) => void;
  setConvPage: (convPage: number) => void;
  setAllTickets: React.Dispatch<React.SetStateAction<any[]>>;
  statusCounts: any;
  setStatusCounts: React.Dispatch<React.SetStateAction<any>>;
  ticketFields: TicketField[];
  selectedTypes: any;
  setSelectedTypes: React.Dispatch<React.SetStateAction<any[]>>;
  setTicketFields: React.Dispatch<React.SetStateAction<TicketField[]>>;
  typeSelection: any;
  setIsType: React.Dispatch<React.SetStateAction<boolean>>;
  ticketFilterVisible: boolean;
  setTicketFilterVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setTicketTypesReport: React.Dispatch<React.SetStateAction<any[]>>;
  typeStatusSelected: string;
  setTypeStatusSelected: React.Dispatch<React.SetStateAction<string>>;
  callStatusCount: boolean;
  setCallStatusCount: React.Dispatch<React.SetStateAction<boolean>>;
  isFiltering: boolean;
  setIsFiltering: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectTicket: React.FC<SelectProps> = (props) => {
  const initialValues = {
    priority: [],
    status: [],
    type: [],
    team: [],
    members: [],
    created_date: {
      start: null,
      end: null,
    },
    tags: [],
  };

  const [searchInput, setSearchInput] = useState<string>("");
  const [teams, setTeams] = useState<Option[]>([]);
  const [members, setMembers] = useState<Option[]>([]);

  // const [isFiltering, setIsFiltering] = useState(false);
  const [filterValues, setFilterValues] = useState<any>(initialValues);
  const navigate = useNavigate();
  const animatedComponents = makeAnimated();
  const ticketSearchRef = useRef<NodeJS.Timeout | null>(null);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState<string>("");
  const [hasMore, setHasMore] = useState(true);
  const businessId = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );
  const channelUid = useSelector(
    (state: any) => state.cartreducer.channelUid?.channelAllData?.uid
  );
  const [dateRange, setDateRange] = useState<DateRange>({
    start: null,
    end: null,
  });

  const [showDatePicker, setShowDatePicker] = useState(false);
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const currentDate = new Date();
  const datepickertarget = useRef<HTMLDivElement | null>(null);
  const [ticketTypes, setTicketTypes] = useState<string[]>([]);

  // Custom option component for react-select with checkboxes
  const CustomOption = (props: any) => {
    return (
      <components.Option {...props}>
        <div className="d-flex align-items-center">
          <div className="me-1 d-flex align-items-center">
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
              className="m-0"
            />{" "}
          </div>
          <div>
            <label className="m-0">{props.label}</label>
          </div>
        </div>
      </components.Option>
    );
  };

  const formatDate = (date: Date | null) => {
    if (date === null) {
      return "-";
    }

    const today = moment().startOf("day");
    const targetDate = moment(date).startOf("day");

    if (targetDate.isSame(today, "day")) {
      return moment(date).format("h:mm A");
    } else {
      return moment(date).format("DD MMM");
    }
  };

  const lastMessage = (messageType: string, subMessage?: any): any => {
    switch (messageType) {
      case "media":
        switch (true) {
          case subMessage.media.mediaType.includes("image"):
            return (
              <div className="d-flex align-items-center">
                <div className="me-1">
                  <CiCamera />
                </div>
                <div>Photo</div>
              </div>
            );
          case subMessage.media.mediaType.includes("video"):
            return (
              <div className="d-flex align-items-center">
                <div className="me-1">
                  <IoIosVideocam />
                </div>
                <div>Video</div>
              </div>
            );
          default:
            return (
              <div className="d-flex align-items-center">
                <div className="me-1">
                  <FontAwesomeIcon
                    icon={faPaperclip}
                    color="#90939F"
                    fontSize={12}
                  />
                </div>
                <div>Attachment</div>
              </div>
            );
        }
      case "interactive":
        return <div>{subMessage?.flowData?.description}</div>;
      case "component":
        switch (true) {
          case subMessage.componentMessage?.subType === "button_message":
            return (
              <div>
                {subMessage.componentMessage.bodyText > 40
                  ? subMessage.componentMessage.bodyText.substring(0, 40) +
                    "..."
                  : subMessage.componentMessage.bodyText}
              </div>
            );
          default:
            return (
              <div className="d-flex align-items-center">
                <div className="me-1">
                  <img src={templateicon} height={17} width={17} />
                </div>
                <div>Template</div>
              </div>
            );
        }
      default:
        return (
          <div>
            {subMessage?.textMessage?.text.length > 40
              ? subMessage?.textMessage?.text.substring(0, 40) + "..."
              : subMessage?.textMessage?.text}
          </div>
        );
    }
  };

  const customSelectStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isDisabled ? "#FBFBFB" : "white",
      caretColor: "transparent",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isDisabled ? "#FBFBFB" : "white",
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: state.isDisabled ? "black" : provided.color,
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      width: "95%",
      backgroundColor: state.isDisabled ? "#FBFBFB" : "white",
    }),
  };

  const loadFilteredTickets = async (
    values: any,
    pageNumber: number,
    mode: "normal" | "mini" = "normal",
    status?: string,
    check?: string
  ) => {
    props.setIsFiltering(true);

    const filterData = {
      channelUid: channelUid,
      page: pageNumber,
      limit: 10,
      filter: {
        members: values.member?.length > 0 ? values.member : null,
        teams: values.team?.length > 0 ? values.team : null,
        status: values.status?.length > 0 ? values.status : null,
        priority: values.priority?.length > 0 ? values.priority : null,
        type: values.type?.length > 0 ? values.type : null,
        startDate: dateRange?.start,
        endDate: dateRange?.end,
      },
    };

    const miniPayload = {
      channelUid: channelUid,
      page: pageNumber,
      limit: 10,
      filter: {
        members: values.member?.length > 0 ? values.member : null,
        teams: values.team?.length > 0 ? values.team : null,
        status:
          status && status?.length > 0
            ? [status]
            : values.status?.length > 0
            ? values.status
            : null,
        priority: values.priority?.length > 0 ? values.priority : null,
        type: values.length > 0 ? values : null,
      },
    };

    const result = await ticketFilter(
      mode === "mini" ? miniPayload : filterData
    );
    setHasMore(result.data.length > 9); // Continue loading if there are more tickets

    if (result) {
      if (pageNumber === 0) {
        props.setAllTickets(result.data);
        props.setSelectedTicket(result.data[0]);
      } else {
        props.setAllTickets((prevTickets) => [...prevTickets, ...result.data]);
      }

      if (mode === "mini" && values.length > 0 && check != "check") {
        const dashboardPayload = {
          startTime: null,
          endTime: null,
          types: values,
        };

        const dashboardResult = await ticketsDashBoard(
          channelUid,
          dashboardPayload
        );

        if (dashboardResult && dashboardResult.ticketTypesReport) {
          props.setStatusCounts({
            Open: dashboardResult.ticketTypesReport[0]?.openCount || 0,
            Pending: dashboardResult.ticketTypesReport[0]?.pendingCount || 0,
            Resolved: dashboardResult.ticketTypesReport[0]?.resolvedCount || 0,
            Closed: dashboardResult.ticketTypesReport[0]?.closedCount || 0,
          });
        }

        if (values.length === 0) {
          setFilterValues(initialValues); // Reset to the original mode if no types are selected
          loadFilteredTickets(initialValues, 0, "normal");
        }
      }
    }

    // setIsFiltering(false);
    props.setIsType(false);
  };

  const handleResetFilters = async () => {
    setSearchInput("");
    props.setTypeStatusSelected("");
    setDateRange({ start: null, end: null });
    props.loadTickets(0);
    setFilterValues(initialValues);
    props.setTicketFilterVisible(false);
    props.setIsFiltering(false);
    props.loadTickets(0);
    props.setSelectedTypes([]);
    await getOverAllTypesStatusCount(ticketTypes);
  };

  const convertCustomFormatToHTML = (text: string, type: string): string => {
    if (typeof text !== "string") {
      return "";
    }

    let truncatedText = text.length > 10 ? text.slice(0, 10) + "..." : text;

    let html = type === "contacts" ? truncatedText : text;

    html = html.replace(/\*~(.*?)~\*/g, "<strong><s>$1</s></strong>");
    html = html.replace(/_~(.*?)~_/g, "<em><s>$1</s></em>");
    html = html.replace(/\*_(.*?)_\*/g, "<strong><em>$1</em></strong>");
    html = html.replace(
      /~\*_(.*?)_\*~/g,
      "<s><strong><em>$1</em></strong></s>"
    );
    html = html.replace(/\*(.*?)\*/g, "<strong>$1</strong>");
    html = html.replace(/_(.*?)_/g, "<em>$1</em>");
    html = html.replace(/~(.*?)~/g, "<s>$1</s>");

    return html;
  };

  const getMiniFilterTypesStatusCount = async (
    ticketTypes: any,
    selectedTypes: any
  ) => {
    try {
      const dashboardStatusPayload = {
        startTime: null,
        endTime: null,
        types: selectedTypes,
      };

      const dashboardReportPayload = {
        startTime: null,
        endTime: null,
        types: ticketTypes,
      };

      if (selectedTypes.length > 0) {
        const dashboardStatusResult = await ticketsDashBoard(
          channelUid,
          dashboardStatusPayload
        );

        if (dashboardStatusResult && dashboardStatusResult.ticketTypesReport) {
          props.setStatusCounts({
            Open:
              dashboardStatusResult.ticketTypesReport?.reduce(
                (acc: any, current: any) => acc + current.openCount,
                0
              ) || 0,
            Pending:
              dashboardStatusResult.ticketTypesReport?.reduce(
                (acc: any, current: any) => acc + current.pendingCount,
                0
              ) || 0,
            Resolved:
              dashboardStatusResult.ticketTypesReport?.reduce(
                (acc: any, current: any) => acc + current.resolvedCount,
                0
              ) || 0,
            Closed:
              dashboardStatusResult.ticketTypesReport?.reduce(
                (acc: any, current: any) => acc + current.closedCount,
                0
              ) || 0,
          });
          // props.setTicketTypesReport(dashboardResult.ticketTypesReport);
        }
      }

      const dashboarReportResult = await ticketsDashBoard(
        channelUid,
        dashboardReportPayload
      );

      if (dashboarReportResult && dashboarReportResult.ticketTypesReport) {
        props.setTicketTypesReport(dashboarReportResult.ticketTypesReport);

        if (selectedTypes.length === 0) {
          props.setStatusCounts({
            Open:
              dashboarReportResult.ticketTypesReport?.reduce(
                (acc: any, current: any) => acc + current.openCount,
                0
              ) || 0,
            Pending:
              dashboarReportResult.ticketTypesReport?.reduce(
                (acc: any, current: any) => acc + current.pendingCount,
                0
              ) || 0,
            Resolved:
              dashboarReportResult.ticketTypesReport?.reduce(
                (acc: any, current: any) => acc + current.resolvedCount,
                0
              ) || 0,
            Closed:
              dashboarReportResult.ticketTypesReport?.reduce(
                (acc: any, current: any) => acc + current.closedCount,
                0
              ) || 0,
          });
        }
      }
    } catch {
      console.log("error");
    }
  };

  const getOverAllTypesStatusCount = async (ticketTypes: any) => {
    try {
      const dashboardPayload = {
        startTime: null,
        endTime: null,
        types: ticketTypes,
      };

      const dashboardResult = await ticketsDashBoard(
        channelUid,
        dashboardPayload
      );

      if (dashboardResult && dashboardResult.ticketTypesReport) {
        props.setStatusCounts({
          Open:
            dashboardResult.ticketTypesReport?.reduce(
              (acc: any, current: any) => acc + current.openCount,
              0
            ) || 0,
          Pending:
            dashboardResult.ticketTypesReport?.reduce(
              (acc: any, current: any) => acc + current.pendingCount,
              0
            ) || 0,
          Resolved:
            dashboardResult.ticketTypesReport?.reduce(
              (acc: any, current: any) => acc + current.resolvedCount,
              0
            ) || 0,
          Closed:
            dashboardResult.ticketTypesReport?.reduce(
              (acc: any, current: any) => acc + current.closedCount,
              0
            ) || 0,
        });
        props.setTicketTypesReport(dashboardResult.ticketTypesReport);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleTypeSelection = (
    selectedTypes: string[],
    typeStatusSelected?: string
  ) => {
    props.setSelectedTypes(selectedTypes);
    if (selectedTypes.length > 0) {
      if (typeStatusSelected) {
        setPage(0);
        loadFilteredTickets(selectedTypes, 0, "mini", typeStatusSelected);
      } else {
        setPage(0);
        loadFilteredTickets(selectedTypes, 0, "mini");
      }
    } else {
      if (typeStatusSelected) {
        setPage(0);
        loadFilteredTickets(
          ticketTypes,
          0,
          "mini",
          typeStatusSelected,
          "check"
        );
      } else {
        setFilterValues(initialValues);
        props.loadTickets(0);
        getOverAllTypesStatusCount(ticketTypes);
      }
    }
  };

  useEffect(() => {
    if (props.typeSelection) {
      handleTypeSelection(props.typeSelection);
    }
  }, [props.typeSelection]);
  
  useEffect(() => {
    if (props.typeStatusSelected.length > 0) {
      handleTypeSelection(props.typeSelection, props.typeStatusSelected);
    }
  }, [props.typeStatusSelected]);

  const getTeams = async () => {
    try {
      const payload = {
        businessUid: businessId,
        page: 0,
        limit: 1000,
      };
      const team = await getAllTeam(payload, "ticketing");
      const teams = team.data.map((teamMember: any) => ({
        label: teamMember.name,
        value: teamMember.uid,
      }));
      setTeams(teams);
    } catch {
      setTeams([]);
    }
  };

  const getUniqueMembers = (teams: any[]) => {
    const memberIds = new Set();
    const members = teams
      .flatMap((team: any) => team.members)
      .filter((member: any) => {
        const isDuplicate = memberIds.has(member.uid);
        if (!isDuplicate) {
          memberIds.add(member.uid);
          return true;
        }
        return false;
      })
      .map((member: any) => ({
        label: member.name,
        value: member.uid,
      }));
    setMembers(members);
    return members;
  };

  const getMembers = async (teamIds: any) => {
    try {
      const teams = await getAllTeams(teamIds, "ticketing");
      getUniqueMembers(teams);
    } catch {
      setMembers([]);
    }
  };

  const handleTeamChange = async (option: any, field: any, form: any) => {
    const payload = {
      teamUids: option?.map((obj: any) => obj.value),
    };
    await getMembers(payload);
    form.setFieldValue("members", []); // Clear members when team changes
    form.setFieldValue(
      field.name,
      option ? option?.map((obj: any) => obj.value) : []
    );
  };

  const handleFilterSubmit = async (values: any) => {
    setFilterValues(values); // Store filter values for pagination
    setPage(0);
    props.setTypeStatusSelected("");
    // props.loadTickets(0);
    props.setSelectedTypes([]);
    await getOverAllTypesStatusCount(ticketTypes);
    await loadFilteredTickets(values, 0, "normal");
    props.setTicketFilterVisible(false);
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    let formValue =
      props.typeSelection.length > 0
        ? props.typeSelection
        : props.typeStatusSelected.length > 0
        ? ticketTypes
        : filterValues;
    let formMode: "normal" | "mini" =
      (props.typeSelection.length > 0 ||
        props.typeStatusSelected.length > 0) === true
        ? "mini"
        : "normal";
    let checkUp =
      props.typeSelection.length === 0 && props.typeStatusSelected.length > 0
        ? "check"
        : "uncheck";

    if (scrollTop + clientHeight >= scrollHeight - 10) {
      // if (isFiltering || props.typeSelection.length >= 0) {
      if (props.isFiltering) {
        if (hasMore) {
          loadFilteredTickets(
            formValue,
            page + 1,
            formMode,
            props.typeStatusSelected,
            checkUp
          );
        } else {
          return;
        }
      } else {
        props.onScrollToEnd(searchInput);
      }
      setPage((prevPage: number) => prevPage + 1);
    }
  };

  // const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
  //   const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
  //   if (scrollTop + clientHeight >= scrollHeight - 10) {
  //     if (isFiltering) {
  //       loadFilteredTickets(filterValues, page + 1);
  //     } else {
  //       props.onScrollToEnd(search);
  //     }
  //     setPage((prevPage: any) => prevPage + 1);
  //   }
  // };

  useEffect(() => {
    if (channelUid) {
      getTicketFields(channelUid).then(async (res) => {
        const ticketTypes =
          res?.data
            .find((field: any) => field.type === "type")
            ?.options.filter((option: any) => !option.deleted)
            .map((option: any) => option.label) || [];
        props.setTicketFields(res?.data);
        setTicketTypes(ticketTypes);
        getOverAllTypesStatusCount(ticketTypes);
      });
      getTeams();
    }
  }, [channelUid]);

  useEffect(() => {
    if (props.callStatusCount) {
      getMiniFilterTypesStatusCount(ticketTypes, props.selectedTypes);
      props.setCallStatusCount(false);
    }
  }, [props.callStatusCount]);

  const handleClickOutside = (event: MouseEvent) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
      setShowDatePicker(false);
    }
  };

  useEffect(() => {
    if (showDatePicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDatePicker]);

  return (
    <div className="select-ticket">
      <div>
        <div className="select-ticket-header">
          <div className="d-flex align-items-center justify-content-between align-items-center">
            <div>Tickets</div>
            <div className="d-flex align-items-center">
              <div className="me-3">
                <button
                  className="new-ticket"
                  onClick={() => navigate("createTicket")}
                >
                  <HiPlus className="mb-1" size={15} /> New{" "}
                </button>
              </div>
              <div
                className="filter-btn"
                onClick={() => props.setTicketFilterVisible(true)}
              >
                {/* {
                  isFiltering && <div className="isFilterDot"></div>
                } */}
                <LuFilter
                  color="#666E7D"
                  fill={(props.ticketFilterVisible || props.isFiltering) ? "#666e7e" : "white"}
                  size={20}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="select-ticket-body">
          <div className="functionalites">
            <div className="me-3 ms-3">
              <LuInbox size={20} />
            </div>
            <div>
              <div className="ticket-search">
                <div>
                  <IoSearchOutline size={20} />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Search Ticket"
                    value={searchInput}
                    onChange={(e: any) => {
                      const val = e.target.value;
                      setSearchInput(val);
                      props.setAllTickets([]);
                      if (ticketSearchRef.current !== null) {
                        clearTimeout(ticketSearchRef.current);
                      }
                      ticketSearchRef.current = setTimeout(() => {
                        if (val) props.loadTickets(0, val);
                        else props.loadTickets(0, "");
                      }, 200);
                    }}
                  />
                </div>
                <div style={{ width: "30px", cursor: "pointer" }}>
                  {searchInput.length > 0 ? (
                    <IoCloseCircle
                      size={20}
                      onClick={() => {
                        setSearchInput("");
                        if (ticketSearchRef.current !== null) {
                          clearTimeout(ticketSearchRef.current);
                        }
                        ticketSearchRef.current = setTimeout(() => {
                          props.setAllTickets([]);
                          props.loadTickets(0, "");
                        }, 200);
                      }}
                    />
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {props.data?.length > 0 ? (
            <div
              className="tickets"
              onScroll={(e: any) => {
                // setHasMore(true);
                handleScroll(e);
              }}
            >
              {props.data?.map((item: any, index: number) => {
                const senderName =
                  item?.contact?.name.length > 15
                    ? item?.contact?.name.substring(0, 15) + "..."
                    : item?.contact?.name;
                const lastMessageText = item?.latestMessage
                  ? lastMessage(
                      item?.latestMessage.messageType,
                      item?.latestMessage
                    )
                  : "";

                let lastMessageTime = item?.latestMessage?.createdAt
                  ? item?.latestMessage?.createdAt
                  : item?.createdAt;

                const ticketSubject = item?.fields?.filter(
                  (f: any) => f.type === "subject"
                );

                let badgeStatus = item?.fields
                  ?.filter((f: any) => f.type === "status")[0]
                  ?.value.toLowerCase();

                let badgeColor =
                  badgeStatus === "closed"
                    ? "#A7A9B2"
                    : badgeStatus === "pending"
                    ? "#DFCD24"
                    : badgeStatus === "resolved"
                    ? "#6EC77C"
                    : badgeStatus === "open"
                    ? "#007EC3"
                    : "";

                return (
                  <div
                    className="individual-ticket"
                    id={`${
                      props.selectedTicket &&
                      props.selectedTicket.id === item.id
                        ? "selected"
                        : ""
                    }`}
                    key={item.id}
                    onClick={() => {
                      props.onSelect(item);
                      props.setConvPage(0);
                    }}
                  >
                    <div className="d-flex align-items-center w-100">
                      <div className="avatarCenter">
                        <Avatar
                          fgColor="#fff"
                          name={senderName}
                          round
                          size="35"
                          textSizeRatio={2.5}
                        />
                      </div>
                      <div className="last-message w-100">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="sender">
                            <TooltipReference
                              placement="right"
                              tooltipId={`ticket-people-${index}`}
                              content={item?.contact?.name}
                            >
                              {senderName}
                            </TooltipReference>
                          </div>
                          <div
                            className="contact-ticket-id"
                            style={{
                              border: `1px solid ${badgeColor}`,
                              background: badgeColor,
                              color: "white",
                            }}
                            key={item?.id}
                          >
                            # {item?.id}
                          </div>
                        </div>
                        {item?.latestMessage?.messageType !== "text" ? (
                          <div className="last-message-text">
                            {lastMessageText}
                          </div>
                        ) : (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: convertCustomFormatToHTML(
                                lastMessageText?.props?.children,
                                "contacts"
                              ),
                            }}
                            className="last-message-text"
                          ></span>
                        )}
                        <div className="last-message-subject">
                          <div className="me-1">
                            <MdOutlineSubject />
                          </div>
                          <div>
                            {ticketSubject[0]?.value.substring(0, 15) +
                              (ticketSubject[0]?.value.length > 15
                                ? "..."
                                : "")}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="last-message-time">
                      <div>{formatDate(lastMessageTime)}</div>
                      {(badgeStatus !== "closed") && (
                        <TooltipReference
                          component="ticketing"
                          placement="top"
                          listView={item}
                          tooltipId={`ticketingOverdue-${index}`}
                        >
                          <div className="overdueTime">Overdue</div>
                        </TooltipReference>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="centerItemsWithWidth">No Tickets Found</div>
          )}
        </div>
      </div>

      {props.ticketFilterVisible && (
        <RightSidebar
          handleCancel={() => props.setTicketFilterVisible(false)}
          title="Filter"
        >
          <Formik
            initialValues={filterValues}
            onSubmit={handleFilterSubmit}
            enableReinitialize={false}
          >
            {({ resetForm, values, setFieldValue, dirty }) => (
              <Form className="filter-container">
                <div className="d-flex justify-content-end">
                  {(dirty || props.isFiltering) && (
                    <div
                      className="clear-filter"
                      onClick={() => {
                        setFieldValue("priority", []);
                        setFieldValue("status", []);
                        setFieldValue("type", []);
                        setFieldValue("team", []);
                        setFieldValue("member", []);
                        setFieldValue("created_date", {
                          start: null,
                          end: null,
                        });
                        setDateRange({ start: null, end: null });
                      }}
                    >
                      Clear
                    </div>
                  )}
                  {props.isFiltering && (
                    <div
                      className="reset-filter"
                      onClick={() => {
                        setFieldValue("priority", []);
                        setFieldValue("status", []);
                        setFieldValue("type", []);
                        setFieldValue("team", []);
                        setFieldValue("member", []);
                        setFieldValue("created_date", {
                          start: null,
                          end: null,
                        });
                        setDateRange({ start: null, end: null });
                        handleResetFilters();
                      }}
                    >
                      Reset
                    </div>
                  )}
                </div>
                {props.ticketFields.map((field) => {
                  if (!field.dropDown || field.type === "dropdown") {
                    return null;
                  }

                  let options: Option[] = [];
                  if (field.type === "team") {
                    options = teams;
                  } else if (field.type === "member") {
                    options = members;
                  } else {
                    options = field.options?.filter((opt: any) => !opt.deleted).map((opt: any) => ({
                      label: opt.label,
                      value: opt.label,
                    }));
                  }

                  return (
                    <div className="filter-field" key={field.id}>
                      <div className="field-name">{field.name}</div>
                      <Field name={field.name}>
                        {({ field, form }: any) => (
                          <Select
                            isMulti
                            options={options}
                            className="pt-1"
                            value={options?.filter((option) =>
                              (field.value || []).includes(option.value)
                            )}
                            placeholder={`Select ${field.name}`}
                            onChange={(option: any) => {
                              if (field.name === "team") {
                                form.setFieldValue(
                                  field.name,
                                  option
                                    ? option.map((opt: any) => opt.value)
                                    : []
                                );
                                handleTeamChange(option, field, form); // Pass setFieldValue for updating members
                              } else {
                                setFieldValue(
                                  field.name,
                                  option
                                    ? option.map((opt: any) => opt.value)
                                    : []
                                );
                              }
                            }}
                            styles={customSelectStyles}
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                            components={{ Option: CustomOption }}
                          />
                        )}
                      </Field>
                    </div>
                  );
                })}

                <div className="filter-field">
                  <div className="field-name">Created date</div>
                  <div className="field-value">
                    <div>
                      {showDatePicker && (
                        <Popover
                          id="popover-basic"
                          style={{
                            maxWidth: "350px",
                            position: "absolute",
                            top: "230px",
                            left: "30px",
                          }}
                          ref={popoverRef}
                          placement="top"
                        >
                          <DateRangePicker
                            value={dateRange}
                            name="dateRange"
                            minDate={currentDate}
                            maximumDate={moment().endOf("day")}
                            onSelect={(value: any) => {
                              const currentDate = moment();
                              let start = moment(value.start).startOf("day");
                              let end = moment(value.end).endOf("day");

                              if (start.isAfter(currentDate, "day")) {
                                start = currentDate.clone().endOf("day");
                              }
                              if (end.isAfter(currentDate, "day")) {
                                end = currentDate.clone().endOf("day");
                              }

                              const newMomentRange = moment.range(start, end);
                              setDateRange(newMomentRange as any);
                              setShowDatePicker(false);
                              setFieldValue("created_date", newMomentRange);
                            }}
                            singleDateRange={true}
                          />
                        </Popover>
                      )}

                      <div ref={datepickertarget}>
                        {dateRange.start && dateRange.end ? (
                          <div
                            className="p-2"
                            style={{
                              border: "2px solid #E2E2E2",
                              borderRadius: "4px",
                              width: "100%",
                            }}
                          >
                            <div className="d-flex align-items-center">
                              <div>
                                {`${dateRange.start?.format(
                                  "DD/MM/YYYY"
                                )} - ${dateRange.end?.format("DD/MM/YYYY")}`}
                              </div>
                              <div className="ms-2">
                                <IoIosCloseCircleOutline
                                  size={17}
                                  onClick={() => {
                                    setFieldValue("created_date", {
                                      start: null,
                                      end: null,
                                    });
                                    setDateRange({ start: null, end: null });
                                    setShowDatePicker(false);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="p-2"
                            style={{
                              color: "rgb(128 130 132)",
                              border: "2px solid #E2E2E2",
                              borderRadius: "4px",
                              width: "100%",
                            }}
                            onClick={() => setShowDatePicker(true)}
                          >
                            DD/MM/YYYY-DD/MM/YYYY
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ticket-fields-filter">
                  <Button
                    type="submit"
                    style={{ cursor: "pointer", opacity: 1 }}
                  >
                    Filter
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </RightSidebar>
      )}
    </div>
  );
};

export default SelectTicket;
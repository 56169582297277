import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select, { components } from "react-select";
import { Row, Col, FormGroup, Label, Button } from "reactstrap";
import "./template.scss";
import { languageOptions } from "../../common/language";
import { getAllChannels, createTemplate } from "../../services/templateService";
import TemplateBody from "./templateBody";
import TemplatePreview from "./templatePreview";
import { categoryOptions, CustomOption } from "../../common/category";
import TemplateList from "./templateList";
import { customStyles } from "../../common/react-select-style";
import { sliceText } from "../../common/sliceText";
import { showAlert, sweetalert, toast } from "../../common/alert";
import TooltipReference from "../../common/tooltip";
import { countryCode } from "../../services/auth/actions";
import {
  faArrowLeft,
  faCloudUpload,
  faExchangeAlt,
  faInfoCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { ValidateOptions } from "yup/lib/types";
// import PDFViewer from 'pdf-viewer-reactjs';

interface SelectOption {
  value: string;
  label: string;
}

interface ValidationContext {
  websiteName: string;
  websiteName1?:string;
  phoneInput1?:string
}

interface MyComponentProps {
  type: string;
  data: any;
  renderBodyMessage: (text: string, rowData: any) => string;
  viewType: string;
  templateModal?: boolean;
  openModal?: boolean;
  uid?: string;
  component?: string;
  handleClose?: () => void;
  tempIdCollections?: (tempId: any, variables: any, showlink: boolean) => void;
  profile: any;
}

class CreateTemplate extends React.Component<MyComponentProps> {
  fileInput = React.createRef<HTMLInputElement>();
  options: SelectOption[] = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
    { value: "option4", label: "Option 4" },
  ];
  radioOptions: SelectOption[] = [
    { value: "none", label: "None" },
    { value: "text", label: "Add Text" },
    { value: "image", label: "Add Image" },
    { value: "video", label: "Add Video" },
    { value: "document", label: "Add Docs" },
  ];
  state = {
    selectedOption: "none",
    channelOptions: [] as { value: string; label: string }[],
    files: [] as { file: File; imgUrl: string }[],
    redirectToTemplates: false,
    numPages: null,
    pageNumber: 1,
    submitType: "",
    characterCount: 0,
    addTxtCount: 0,
    countrycode: "",
    seperateValue: "",
    header: true,
    isLoading: false,
    isLoadingDraft: false,
    buttonTypes: [],
    carouselMedia: [] as any,
    carouselData: [],
    variable: [],
    countryCodeArr: [],
  };
  category = categoryOptions.filter((option) => option.value !== "all");




  handleOptionChange = (optionValue: string) => {
    this.setState({ selectedOption: optionValue });
  };
  setcode = (code: any) => {
    this.setState({ countrycode: code });
  };

  handleheader = (setFieldValue: any) => {
    this.setState({ header: !this.state.header });
    setFieldValue("options", this.radioOptions[0].value);
  };

  submitType = (Value: string) => {
    this.setState({ submitType: Value });
    // if(Value==="SUBMIT"){
    //   sweetalert(
    //     'warning',
    //     'Are you sure you want to continue?',
    //     () => {
    //       this.setState({ submitType: Value });
    //       this.setState({ redirectToTemplates: true });
    //     },
    //     () => {

    //     }
    //   );

    // }else {
    // this.setState({ submitType: Value });
    // }
  };
  // callsubmit = (values : any) =>{
  //   this.handleSubmit(values);
  // }

  loaderFunc = (value: boolean, btn: string) => {
    // console.log("load", this.state.isLoadingDraft,btn,value);

    
    if (btn === "draft") {
      this.setState({ isLoadingDraft: value });

    } else {
      this.setState({ isLoading: value });
      this.setState({ isLoadingDraft: value });


    }
  };


  componentDidMount() {
    if (
      this.props.type === "view" ||
      this.props.type === "duplicate" ||
      this.props.type === "edit" ||
      this.props.type === "default"
    ) {
      this.setState({ characterCount: this.props.data?.name?.length });
      this.setState({
        addTxtCount: this.props.data?.components[0]?.text?.length,
      });
      const { data } = this.props;
      const extractedCards :any= [];
    
       data?.components.forEach((component: any) => {
         if (component.type === 'CAROUSEL' && component.cards) {
            component.cards.forEach((card: any, cardIndex: number) => {
              extractedCards.push({
                  index: cardIndex,
                      id: cardIndex,
                      card: card
                      });
                  });
                 }
      });
              
      this.setState({carouselData: extractedCards});
      this.setState({variable: data?.carouselColumns});
      this.setState({countryCodeArr: data?.buttonColumns});
        const mediaArray = data?.cards?.map((card: any) => card?.media);
        this.setState({ carouselMedia: mediaArray });

    }
    if (categoryOptions.length > 3) {
      categoryOptions.splice(0, 1);
    }
    getAllChannels().then((res) => {
      const responseData = res.data;
      const channelOptions = responseData.map((option: any) => ({
        label: option?.displayName,
        value: option?.uid,
      }));
      this.setState({ channelOptions });
    });
    if (
      this.props.type === "view" ||
      this.props.type === "duplicate" ||
      this.props.type === "edit" ||
      this.props.type === "default"
    ) {
      this.setState({
        files: [
          {
            imgUrl: this.props.data.components[0]?.format
              ? this.props.data.components[0]?.example?.header_handle[0]
              : "",
            imgName: "",
            image: "",
            type: "",
            size: "",
          },
        ],
      });
    }
  }

  validationSchema = Yup.object({
    templateName: Yup.string()
      .required("Template Name is required")
      .matches(
        /^[a-z0-9_]+$/,
        "Name can only contain lowercase alphanumeric characters & underscores (_)"
      )
      .max(60, "Template Name should not exceed 60 characters"),
    // variable1: Yup.string().when("webUrlDropdown1", {
    //   is: "dynamicUrl",
    //   then: Yup.string().required("Content is required"),
    //   otherwise: Yup.string(),
    // }),
    // category: Yup.mixed().test(
    //   'is-string-or-empty-array',
    //   'Category must be a string or an empty array',
    //   (value:any) => typeof value === 'string' || (Array.isArray(value) && value.length === 0)
    // ).required('Category is required'),
    timeIndicator:
    Yup.number()
    .when('expirationwarning', {
      is: true,
      then: Yup
        .number()
        .min(1, 'Value must be at least 1')
        .max(90, 'Value must be at most 90')
        .required('Expiration value is required'),
      otherwise: Yup.number().notRequired(),
    }),
    authtextButton:Yup.string()
    .when('category',{
      is:"authentication",
      then:Yup.string().required("Button Text is required")
    }),
    variable: Yup.string().when(["webUrlDropdown", "webUrlChecked"], {
      is: (webUrlDropdown: any, webUrlChecked: any) =>
        webUrlDropdown === "dynamicUrl" && webUrlChecked,
      then: Yup.string().required("Content is required"),
      otherwise: Yup.string().nullable(),
    }),
    variable1: Yup.string().when(["webUrlDropdown1", "webUrlChecked1"], {
      is: (webUrlDropdown1: any, webUrlChecked1: any) =>
        webUrlDropdown1 === "dynamicUrl" && webUrlChecked1,
      then: Yup.string().required("Content is required"),
      otherwise: Yup.string().nullable(),
    }),

    couponValue: Yup.string().when('category', {
      is: 'coupon_marketing',
      then: Yup.string().required("Coupon code is required")
        .max(15, "Coupon code must not exceed 15 characters"),

    }),
    category:Yup.string().required('Category is required'),
    // channel: Yup.string().required('Channel is required'),
    language: Yup.string().required("Language is required"),
    file: Yup.mixed().when("options", {
      is: (value: string) =>
        value === "image" || value === "video" || value === "document",
      then: Yup.mixed()
      .required("File is required")
      .test("fileSize", "Video must be less than 10MB", function (file) {
        if (this.parent.options === "video" && file) {
          return file.size <= 10 * 1024 * 1024; // 10MB in bytes
        }
        return true;
      }),      otherwise: Yup.mixed(),
    }),
    content: Yup.string().when("options", {
      is: "text",
      then: Yup.string()
        .required("Header text is required")
        .max(60, "Header text must not exceddd 60 characters"),
      otherwise: Yup.string(),
    }),
    messageBody: Yup.string().when('category', {
      is: (category:any) => category !== 'authentication',
      then: Yup.string()
        .test("is-not-empty", "Message Body is required", (value) => {
          return typeof value !== "undefined" && /\w+/.test(value);
        })
        .max(1024, "Message Body should not exceed 1024 characters"),
      otherwise: Yup.string().nullable(),
    }),
    // carouselBody: Yup.string()
    //   .test("is-not-empty", "Message Body is required", (value: any) => {
    //     return typeof value !== "undefined" && /\w+/.test(value);
    //   })
    //   .max(1024, "Message Body should not exceed 1024 characters"),
    phoneInput1: Yup.string().when("phoneChecked", {
      is: true,
      then: Yup.string().required("Button Text is required").test(
        "unique-values",
        "Enter unique values for Buttons",
        function (value) {
          const { websiteName, websiteName1 } = this.options.context as ValidationContext; // Assuming you pass both values in the context
          return value !== websiteName && value !== websiteName1;
        }
      ),
      otherwise: Yup.string(),
    }),
    phoneInput2: Yup.string().when("phoneChecked", {
      is: true,
      then: Yup.string().required("Mobile number is required"),
      otherwise: Yup.string(),
    }),
    websiteName: Yup.string().when("webUrlChecked", {
      is: true,
      then: Yup.string().required("Website name is required").test(
        "no-equal-web-url",
        "Enter unique values for Buttons",
        function (value) {
          const { phoneInput1 } = this.options.context as ValidationContext; 
          return value !== phoneInput1;
        }
      ),
      otherwise: Yup.string(),
    }),
    websiteName1: Yup.string().when("webUrlChecked1", {
      is: true,
      then: Yup.string().required("Website name is required").test(
        "no-equal-web-url",
"Enter unique values for Buttons",
        function (value) {
          const { websiteName } = this.options.context as ValidationContext; 
          return value !== websiteName;
        }
      ),
      otherwise: Yup.string(),
    }),
    webUrl1: Yup.string().when(["webUrlChecked1", "webUrlDropdown1"], {
      is: (webUrlChecked1: boolean, webUrlDropdown1: string) =>
        true && webUrlChecked1 && webUrlDropdown1 === "dynamicUrl",
      then: Yup.string()
        .required("Website Url is required")
        .matches(
          /^https:\/\/[a-zA-Z]+\..*\{\{1\}\}$/,
          "Please enter a valid dynamic url"
        ) ,
      otherwise: Yup.string().when(["webUrlChecked1", "webUrlDropdown1"], {
        is: (webUrlChecked1: boolean, webUrlDropdown1: string) =>
          true && webUrlChecked1 && webUrlDropdown1 === "staticUrl",
        then: Yup.string()
          .required("Website Url is required")
          .matches(
            /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[^\s@]+\.[^\s@]+|(?:\w+@\w+\.\w+)/,
            "Please enter a valid static url"
          )
          .test(
            "no-template-variable",
            "Please enter a valid static url",
            function (value) {
              return !(value && value.includes("{{1}}"));
            }
          ),
      }),
    }),

    // webUrl: Yup.string().when('webUrlChecked', {
    //   is: true,
    //   then: Yup.string().required('Url is required'),
    //   otherwise: Yup.string()
    // }),
    webUrl: Yup.string().when(["webUrlChecked", "webUrlDropdown"], {
      is: (webUrlChecked: boolean, webUrlDropdown: string) =>
        true && webUrlChecked && webUrlDropdown === "dynamicUrl",
      then: Yup.string()
        .required("Website Url is required")
        .matches(
          /^https:\/\/[a-zA-Z]+\..*\{\{1\}\}$/,
          "Please enter a valid dynamic url"
        ),
      otherwise: Yup.string().when(["webUrlChecked", "webUrlDropdown"], {
        is: (webUrlChecked: boolean, webUrlDropdown: string) =>
          true && webUrlChecked && webUrlDropdown === "staticUrl",
        then: Yup.string()
          .required("Website Url is required")
          .matches(
            /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[^\s@]+\.[^\s@]+|(?:\w+@\w+\.\w+)/,
            "Please enter a valid static url"
          )
          .test(
            "no-template-variable",
            "Please enter a valid static url",
            function (value) {
              return !(value && value.includes("{{1}}"));
            }
          ),
      }),
    }),

    addVariables: Yup.mixed().when("selectedVariable", {
      is: (value: string) => value,
      then: Yup.array().test({
        name: "notEmpty",
        message: "Enter sample values for all variables",
        test: function (value: any) {
          return value.every(
            (sampleVar: { name: string; id: string; value: string }) =>
              sampleVar.value
          );
        },
      }),
      otherwise: Yup.mixed(),
    }),
    replyButtons: Yup.mixed().when("btnOptions", {
      is: (value: string) => value.includes("quickReply"),
      then: Yup.array()
        .test({
          name: "notEmpty",
          message: "Fill all button values",
          test: function (value: any) {
            return value?.every(
              (btn: { label: string; name: string; id: string; value: string }) =>
                btn.value
            );
          },
        })
        .test({
          name: "uniqueValues",
          message: "Button values must be unique",
          test: function (value: any) {
            const values = value?.map((btn: { value: string }) => btn?.value);
            return new Set(values).size === values.length;
          },
        }),
      otherwise: Yup.mixed(),
    }),
    catalogOption: Yup.string().when("category", {
      is: "product_marketing",
      then: Yup.string().required("Catalog option is required"),
      otherwise: Yup.string(),
    }),  
    catalogheader: Yup.string().when("catalogOption", {
      is: "multi-product",
      then: Yup.string().required("Message header is required"),
      otherwise: Yup.string(),
    }),    
    sections: Yup.mixed().when("catalogOption", {
      is: "multi-product",
      then: Yup.array().of(
        Yup.object().shape({
          title: Yup.string().required("Title is required"),
          product_items: Yup.array().of(
            Yup.object().shape({
              // Add validations for product_items if needed
              // For example:
              // name: Yup.string().required("Name is required"),
            })
          ).required("Product items are required").min(1, "At least one product item is required")
        })
      ).test({
        name: "notEmpty",
        message: "Fill all title values and ensure product items are not empty",
        test: function (sections:any) {
          return sections.every(
            (section: { title: string; product_items: any[] }) =>
              section.title && section.product_items.length > 0
          );
        },
      }),
      otherwise: Yup.mixed(),
    }),
    callToAction: Yup.string().when(
      ["btnOptions", "phoneChecked", "webUrlChecked", "webUrlChecked1"],
      {
        is: (
          btnOptions: string,
          phoneChecked: string,
          webUrlChecked: string,
          webUrlChecked1: string
        ) =>
          btnOptions.includes("action") &&
          !(phoneChecked || webUrlChecked || webUrlChecked1),
        then: Yup.string().required(
          "Please fill any one of the details for call to action"
        ),
      }
    ),
    carouselType: Yup.string().when("category", {
      is: "carousel",
      then: Yup.string().required("Carousel type is required"),
      otherwise: Yup.string(),
    }),
    buttonOne: Yup.string().when("category", {
      is: "carousel",
      then: Yup.string().required("Button 1 type is required"),
      otherwise: Yup.string(),
    }),
  });

  handleSubmit = (values: any) => {
   
    if (this.state.submitType === "SUBMIT") {
      // console.log("testing submit");

      sweetalert(
        "warning",
        "Are you sure you want to continue?",
        () => {
          this.setState({ isLoading: true });
          createTemplate(
            this.state.submitType,
            this.state.countrycode,
            values,
            this.props.type,
            this.props.data?.id ? this.props.data?.id : "",
            this.props.uid,
            this.props.data,
            this.loaderFunc,
            this.state.carouselMedia,
            this.state.carouselData,
            this.state.variable,
            this.state.countryCodeArr
          ).then((res) => {
            if (res.success) {
              this.setState({ redirectToTemplates: true });
            }
          });
        },
        () => { }
      );
    } else {
      if (this.state.submitType === "DRAFT") {
        console.log("draft")
        this.setState({ isLoadingDraft: true });
      } else {
        this.setState({ isLoading: true });
      }
      createTemplate(
        this.state.submitType,
        this.state.countrycode,
        values,
        this.props.type,
        this.props.data?.id ? this.props.data?.id : "",
        this.props.uid,
        this.props.data,
        this.loaderFunc,
        this.state.carouselMedia,
        this.state.carouselData,
        this.state.variable,
        this.state.countryCodeArr
      ).then((res) => {
        if (res.success) {
          this.setState({ redirectToTemplates: true });
        }
      });
    }
  };

  handleVariableClick = (variable: string) => { };

  handleFileChange = (
    files: FileList | null,
    setFieldValue: (field: string, value: any) => void,
    setFieldError: (field: string, value: any) => void,
    values: any
  ) => {
    if (files && files.length > 0) {
      const updatedFiles: {
        imgUrl: string;
        imgName: string;
        image: File;
        type: string;
        size: number;
      }[] = [];

      for (let i = 0; i < files.length; i++) {
        const newFile = files[i];
        const imgUrl = URL.createObjectURL(newFile);
        updatedFiles.push({
          imgUrl: imgUrl,
          imgName: newFile.name,
          image: newFile,
          type: newFile.type,
          size: newFile.size,
        });
      }
      const uploadedFile = updatedFiles[0];
      if (values.options === "image") {
        if (
          uploadedFile.type === "image/jpeg" ||
          uploadedFile.type === "image/png" ||
          uploadedFile.type === "image/jpg"
        ) {
          this.setState({ files: updatedFiles });
          setFieldValue("file", updatedFiles[0]);
        } else {
          toast("error", "Invalid file format");
        }
      } else if (values.options === "video") {
        if (
          uploadedFile.type === "video/mp4" ||
          uploadedFile.type === "video/3gpp"
        ) {
          this.setState({ files: updatedFiles });
          setFieldValue("file", updatedFiles[0]);
        } else {
          toast("error", "Invalid file format");
        }
      } else if (values.options === "document") {
        if (uploadedFile.type === "application/pdf") {
          this.setState({ files: updatedFiles });
          setFieldValue("file", updatedFiles[0]);
        } else {
          toast("error", "Invalid file format");
        }
      }
    }
  };

  handleDrop = (
    event: React.DragEvent<HTMLDivElement>,
    setFieldValue: (field: string, value: any) => void,
    setFieldError: (field: string, value: any) => void,
    values: any
  ) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    this.handleFileChange(files, setFieldValue, setFieldError, values);
  };
  extractAndCleanUrl = (originalUrl: string): string | undefined => {
    console.log("url", originalUrl);

    try {
      const url = new URL(originalUrl);

      const redirectUrl = url.searchParams?.get('redirectUrl');
      const cleanedUrl = redirectUrl?.replace('{{1}}', '');
      if (redirectUrl) {
        return cleanedUrl;
      }
      return originalUrl;
    } catch (error) {
      console.error('Invalid URL:', error);
      return undefined;
    }
  };


  extractUrl = (originalUrl: string): string | null => {

    const url = new URL(originalUrl);
    const redirectUrl = url.searchParams?.get('redirectUrl');
    if (redirectUrl) {
      return redirectUrl;
    }
    else {
      return originalUrl;
    }

  }

  getText = (values: any) => {

    switch (values.options) {
      case values.options === "document":
        return "PDF";
      case values.options === "image":
        return "JPG";
      case values.options === "video":
        return "MP4";
      default:
        return "file";
    }
  };

  goToTemplates = () => {
    if (this.props?.type !== "view") {
      sweetalert(
        "warning",
        "Going back will<br> discard your changes.<br>Are you sure?",
        () => {
          this.setState({ redirectToTemplates: true });
        },
        () => { }
      );
    } else {
      this.setState({ redirectToTemplates: true });
    }
  };

  restrictText = (
    event: any,
    setFieldValue: (field: string, value: any) => void,
    resTill: number
  ) => {
    const value = event.target.value;
    const name = event.target.name;
    let cleanValue;
    const specialCharRegex = /[!@#\$%\^\&*\)\(+=._-]+/g;

    const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/gu;
    if (name === 'phoneInput1' || name === 'websiteName' || name === 'webUrl' || name === 'websiteName1' || name === 'webUrl1' ||name=== 'authtextButton' || name === 'buttonOne' || name === 'buttonTwo'   )
      cleanValue = value.replace(emojiRegex, "");
   else if (name === 'couponValue') {
        cleanValue = value.replace(emojiRegex, "").replace(specialCharRegex, "");
      }   
    else
      cleanValue = value;
    if (cleanValue.length < resTill) {
      if (event.target.name === "templateName") {
        setFieldValue(event.target.name, event.target.value.toLowerCase());
        setFieldValue(event.target.name, cleanValue.toLowerCase());
      } else {
        setFieldValue(event.target.name, event.target.value);
        setFieldValue(event.target.name, cleanValue);
      }
    }
  };

  dummy = () => { };
  deleteFiles = (setFieldValue: (field: string, value: any) => void) => {
    this.setState({ files: [] });
    setFieldValue("file", null);
  };

  onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    this.setState({ numPages });
  };

  extractVariableName = (text: string) => {
    const regex = /{{(.*?)}}/g;
    const matches = text?.match(regex);
    if (matches) {
      return matches.map((match: string) => match.replace(/{{|}}/g, ""));
    }
    return [];
  };

  render() {

    const profile = this.props;
    const { redirectToTemplates, pageNumber, numPages } = this.state;
    const { type, data, renderBodyMessage } = this.props;
      
    const categoryOpt = [
      {
        label: 'Marketing',
        options: [
          { value: 'marketing', label: 'Custom Marketing' },
          { value: 'carousel', label: 'Carousel Marketing' },
          { value: 'catalog_marketing', label: 'Product Marketing' },
          { value: 'coupon_marketing', label: 'Coupon Marketing' },
        ],
      },
      {
        label: 'Utility',
        options: [
          { value: 'utility', label: ' Custom  Utility' },
          // { value: 'carousel_utility', label: 'Carousel Utility' },
        ],
      },
      {
        label: 'Authentication',
        options: [
          { value: 'authentication', label: 'Authentication (OTP)' },
        ],
      },
    ];
   
      const CustomOption = (props: any) => {
        return (
          <components.Option {...props}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span >{props.data.label}</span>
                  {['catalog_marketing', 'carousel', 'coupon_marketing', 'authentication'].includes(props.data.value) && (
                      <span style={{ backgroundColor: '#9DDFB4', color: "#008069", borderRadius: '4px', padding: '0 5px', marginLeft: '10px' }}>
                          New 
                      </span>
                  )}
              </div>
          </components.Option>)
      };
    const urlSearchParams = new URLSearchParams(window.location.search);
    const templateModalValue = urlSearchParams.get("templateModal");
    const getCopyCodeExample = () => {
      if (
        data?.components &&
        data.components.length > 0 &&
        data.components[data.components.length - 1]?.buttons
      ) {
        const buttons = data.components[data.components.length - 1].buttons;
        const copyCodeButton = buttons.find((button: any) => button.type === "COPY_CODE");

        return copyCodeButton ? copyCodeButton.example[0] : "";
      }
      return "";
    };
    // Determine the value for the templateModal prop
    const templateModal = templateModalValue === "true";
    if (redirectToTemplates) {
      return (
        <TemplateList
          viewType={this.props.viewType}
          templateModal={false}
          component={this.props.component}
          handleClose={this.props.handleClose}
          tempIdCollections={this.props.tempIdCollections}
          setSelectTemplateId={(templateId: string) => ""}
          setRemainderTemplateId={(templateId: string) => ""}
        />
      );
    }

    const categoryChangeOptions = [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ];
    // let sampleLabelValue: Array<{ name: string, id: string, value: string }> ;
    // sampleLabelValue= type !== 'create' ? data.bodyColumns ?
    //   data.bodyColumns.map((opt: { key: number, value: string }, ind: number) => ({
    //     name: opt.value,
    //     id: opt.value,
    //     value: data.components[0]?.format ? data.components[1].example.body_text[ind] : data.components[0].example.body_text[ind]
    //   })) :  sampleLabelValue = ({name: this.extractVariableName(data.components.text), id: this.extractVariableName(data.components.text).length, value: this.extractVariableName(data.components[0].text)}) : [];

    let sampleLabelValue: { name: string; id: string; value: string }[] = [];

    if (type !== "create") {
      if (data.bodyColumns?.length) {
        sampleLabelValue = data.bodyColumns?.map(
          (opt: { key: number; value: string }, ind: number) => ({
            name: opt.value,
            id: opt.value,
            value: data.components[0]?.format
              ? data.components[1].example?.body_text.length
                ? data.components[1].example?.body_text[0][ind]
                : ""
              : data.components[0].example?.body_text.length
                ? data.components[0].example?.body_text[0][ind]
                : "",
          })
        );
      } else {
        const text = data.components[0].format
          ? this.extractVariableName(data.components[1].text)
          : this.extractVariableName(data.components[0].text);
        sampleLabelValue = text.map((opt: any, ind: number) => ({
          name: opt,
          id: ind.toString(),
          value: data.components[0]?.format
            ? data.components[1].example?.body_text.length
              ? data.components[1].example?.body_text[0][ind]
              : ""
            : data.components[0].example?.body_text.length
              ? data.components[0].example?.body_text[0][ind]
              : "",
        }));
      }
    }

    const repBtnsWithKVPair: Array<{
      label: string;
      name: string;
      id: string;
      value: string;
    }> =
      type !== "create"
        ? data.components[data.components.length - 1]?.buttons?.length
          ? data.components[data.components.length - 1]?.buttons[0]?.type ===
            "QUICK_REPLY"
            ? data.components[data.components.length - 1]?.buttons
              .filter((btn: { type: string }) => btn.type === "QUICK_REPLY")
              .map(
                (
                  btn: {
                    type: string;
                    text: string;
                    phone_number: string;
                    url: string;
                  },
                  ind: number
                ) => ({
                  label: `Button ${ind + 1}`,
                  name: `button${ind + 1}`,
                  id: `button${ind + 1}`,
                  value: btn.text, // Add the value property with the desired value
                })
              )
            : data.components[data.components.length - 1]?.buttons[1]?.type ===
              "QUICK_REPLY"
              ? data.components[data.components.length - 1]?.buttons
                .filter((btn: { type: string }) => btn.type === "QUICK_REPLY")
                .map(
                  (
                    btn: {
                      type: string;
                      text: string;
                      phone_number: string;
                      url: string;
                    },
                    ind: number
                  ) => ({
                    label: `Button ${ind + 1}`,
                    name: `button${ind + 1}`,
                    id: `button${ind + 1}`,
                    value: btn.text, // Add the value property with the desired value
                  })
                )
              : data.components[data.components.length - 1]?.buttons[2]?.type ===
                "QUICK_REPLY"
                ? data.components[data.components.length - 1]?.buttons
                  .filter((btn: { type: string }) => btn.type === "QUICK_REPLY")
                  .map(
                    (
                      btn: {
                        type: string;
                        text: string;
                        phone_number: string;
                        url: string;
                      },
                      ind: number
                    ) => ({
                      label: `Button ${ind + 1}`,
                      name: `button${ind + 1}`,
                      id: `button${ind + 1}`,
                      value: btn.text,
                    })
                  )
                : data.components[data.components.length - 1]?.buttons[3]?.type ===
                  "QUICK_REPLY"
                  ? data.components[data.components.length - 1]?.buttons
                    .filter((btn: { type: string }) => btn.type === "QUICK_REPLY")
                    .map(
                      (
                        btn: {
                          type: string;
                          text: string;
                          phone_number: string;
                          url: string;
                        },
                        ind: number
                      ) => ({
                        label: `Button ${ind + 1}`,
                        name: `button${ind + 1}`,
                        id: `button${ind + 1}`,
                        value: btn.text, // Add the value property with the desired value
                      })
                    )
                  : []
          : []
        : [];
    const mediaFiles =
      type === "view" ||
        type === "duplicate" ||
        type === "edit" ||
        type === "default"
        ? {
          imgUrl: this.props.data.components[0]?.format
            ? this.props.data.components[0]?.example?.header_handle[0]
            : "",
          imgName: "",
          image: "",
          type: "",
          size: "",
        }
        : {};

    const getCategory = (data: any) => {
      if (data.isCatalog) {
        return "catalog_marketing"
      }
      else if (data.isCoupon) {
        return "coupon_marketing"
      }
      else {
        return ""
      }
    }
    const initialValues = {
      templateName:
        type === "view" || type === "edit" || type === "default"
          ? data.name
          : type === "duplicate"
            ? data.name + "_clone"
            : "",
            authtextButton: type === "view" ||
            type === "duplicate" ||
            type === "edit" ||
            type === "default"
            ? data?.category==="AUTHENTICATION" && data.components[data.components.length - 1].buttons[0]?.type ==="otp" ? data.components[data.components.length - 1].buttons[0]?.text:"":"",
      
      category:
        type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          ? data?.components[1]?.type && data?.components[0]?.type === 'CAROUSEL' ||data?.components[1]?.type && data?.components[1]?.type === 'CAROUSEL'? 'carousel' :data.isCatalog === true || data.isCoupon === true ? getCategory(data) : data.category.toLowerCase()
          : "",
      couponValue:
        type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          && data.isCoupon ? getCopyCodeExample() : "",

          catalogheader:
          type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          && data.isCatalog  && data?.sectionColumns?   data.components[0]?.text:""  ,
      catalogImageUrl: type === "view" ||
        type === "duplicate" ||
        type === "edit" ||
        type === "default" ? data?.isCatalog ? data?.buttonColumns[0] : "" : "",

    ttlseconds:
    type === "view" ||
        type === "duplicate" ||
        type === "edit" ||
        type === "default" ? data.category==="authentication"? data?.message_send_ttl_seconds:60:60,

        sections:type === "view" ||
        type === "duplicate" ||
        type === "edit" ||
        type === "default" ? data?.isCatalog && data?.sectionColumns ? data?.sectionColumns :[]:[],


      securitydisclaimer:type === "view" ||
      type === "duplicate" ||
      type === "edit" ||
      type === "default" ?data.category==="AUTHENTICATION"&& data?.components[0]?.add_security_recommendation ? true:false:false  ,
      expirationwarning:type === "view" ||
      type === "duplicate" ||
      type === "edit" ||
      type === "default" ?data.category==="AUTHENTICATION"&& data?.components[1]?.code_expiration_minutes ? true:false:false  ,
      timeIndicator:    type === "view" ||
      type === "duplicate" ||
      type === "edit" ||
      type === "default" ?data.category==="AUTHENTICATION"&& data?.components[1]?.code_expiration_minutes ? data?.components[1]?.code_expiration_minutes:"":""  ,

      catalogOption: type === "view" ||
        type === "duplicate" ||
        type === "edit" ||
        type === "default" ? data?.isCatalog && data?.sectionColumns?.length>0 ? "multi-product" : data.isCatalog? "catalogue":"" : "",
      channel:
        type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          ? "876428be-fe17-3cee-b904-a78d4d514ce1"
          : "",
      language:
        type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          ? data.language
          : languageOptions[14].value,
      options:
        type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          ? data.components[0]?.format && data?.sectionColumns?.length===0
            ? data.components[0]?.format?.toLowerCase()
            : "none"
          : "none",
      file:
        type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          ? mediaFiles
          : null,
      content:
        type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          ? data.sectionColumns?.length===0 ? data.components[0]?.text:""
          : "",
      messageBody:
        type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          ? data.components.some((component: any) => component?.type === 'CAROUSEL')? (data?.components && data?.components[0]?.text?  renderBodyMessage(data.components[0]?.text, data) :  renderBodyMessage(data.components[1]?.text, data) ): data.components[0]?.format
            ? renderBodyMessage(data.components[1]?.text, data)
            : renderBodyMessage(data.components[0]?.text, data)
          : "",
      selectedVariable: "",
      footerMsg:
        type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          ? data.components.some((component: any) => component?.type === 'CAROUSEL')? '' : data.components[0]?.format
            ? data.components[2]?.text
            : data.components[1]?.text
          : "",

       authButton:type === "view" ||
       type === "duplicate" ||
       type === "edit" ||
       type === "default"
       ?    data.components[data.components.length - 1]?.buttons?.length
       && data.components[data.components.length - 1]?.buttons[0]?.type ===
         "otp"?true: true:true,
      btnOptions:
        type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          ? data.components[data.components.length - 1]?.buttons?.length
            ? data.components[data.components.length - 1].buttons[0]?.type ===
              "QUICK_REPLY"
              ? "quickReply"
              : data.components[data.components.length - 1].buttons[1]?.type ===
                "QUICK_REPLY"
                ? "quickReply,action"
                : data.components[data.components.length - 1].buttons[2]?.type ===
                  "QUICK_REPLY"
                  ? "quickReply,action"
                  : data.components[data.components.length - 1].buttons[3]?.type ===
                    "QUICK_REPLY"
                    ? "quickReply,action"
                    : data.components[data.components.length - 1].buttons[0]?.type === "CATALOG"||( data.components[data.components.length - 1].buttons[0]?.type === "COPY_CODE" &&data.components[data.components.length - 1].buttons?.length===1) || data.components[data.components.length - 1].buttons[0]?.type === "MPM" ||data.components[data.components.length - 1].buttons[0]?.type === "otp"? "none" : "action"
            : "none"
          : "none",

      catalogDropdown: type === "view" ||
        type === "duplicate" ||
        type === "edit" ||
        type === "default" ? data.isCatalog && data.retailerId ? data.retailerId : "" : "",

      phoneChecked:
        type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          ? data.components[data.components.length - 1]?.buttons?.length
            ? data.components[data.components.length - 1].buttons[0]?.type ===
              "PHONE_NUMBER"
              ? true
              : data.components[data.components.length - 1].buttons[1]?.type ===
                "PHONE_NUMBER"
                ? true : false
            : false
          : false,
      phoneInput1:
        type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          ? data.components[data.components.length - 1]?.buttons?.length
            ? data.components[data.components.length - 1].buttons[0]?.type ===
              "PHONE_NUMBER"
              ? data.components[data.components.length - 1].buttons[0]?.text
              : data.components[data.components.length - 1].buttons[1]?.type ===
                "PHONE_NUMBER" ? data.components[data.components.length - 1].buttons[1]?.text : ""
            : ""
          : "",
      phoneInput2:
        type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          ? data.components[data.components.length - 1]?.buttons?.length
            ? data.components[data.components.length - 1].buttons[0]?.type ===
              "PHONE_NUMBER"
              ? data.components[
                data.components.length - 1
              ].buttons[0].phone_number?.slice(data.countryCode?.length - 1)
              : data.components[data.components.length - 1].buttons[1]?.type ===
                "PHONE_NUMBER"
                ? data.components[
                  data.components.length - 1
                ].buttons[1].phone_number?.slice(data.countryCode?.length - 1) : ""
            : ""
          : "",
      webUrlChecked:
        type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          ? data.components[data.components.length - 1]?.buttons?.length
            ? data.components[data.components.length - 1].buttons[0].type ===
              "URL"
              ? true
              : data.components[data.components.length - 1].buttons[1]?.type ===
                "URL"
                ? true
                : data.components[data.components.length - 1].buttons[2]?.type ===
                  "URL" ? true : false
            : false
          : false,
      webUrlDropdown:
        type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          ? data.components[data.components.length - 1]?.buttons?.length > 0 &&
          (data.components[data.components.length - 1].buttons[0].type ===
            "URL" &&
            data.components[data.components.length - 1].buttons[0].example && data?.buttonColumns?.includes("URL1")
            ? "dynamicUrl"
            : data.components[data.components.length - 1].buttons[1]?.type ===
              "URL" &&
              data.components[data.components.length - 1].buttons[1].example && data?.buttonColumns?.includes("URL1")
              ? "dynamicUrl"
              : data.components[data.components.length - 1].buttons[2]?.type ===
                "URL" &&
                data.components[data.components.length - 1].buttons[2].example && data?.buttonColumns?.includes("URL1")
                ? "dynamicUrl" : "staticUrl")
          : "staticUrl",
      websiteName:
        type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          ? data.components[data.components.length - 1]?.buttons?.length
            ? data.components[data.components.length - 1].buttons[0].type ===
              "URL"
              ? data.components[data.components.length - 1].buttons[0].text
              : data.components[data.components.length - 1].buttons[1]?.type ===
                "URL"
                ? data.components[data.components.length - 1].buttons[1].text
                : data.components[data.components.length - 1].buttons[2]?.type ===
                  "URL"
                  ? data.components[data.components.length - 1].buttons[2].text : ""
            : ""
          : "",
      webUrl:
        type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          ? data.components[data.components.length - 1]?.buttons?.length
            ? data.components[data.components.length - 1].buttons[0]?.type ===
              "URL" && data.buttonColumns?.includes("URL1")
              ? this.extractUrl(data.components[data.components.length - 1].buttons[0].url)
              : data.components[data?.components?.length - 1].buttons[0]?.type ===
                "URL" && data.templateTracking && !data.buttonColumns?.includes("URL1")
                ? this.extractAndCleanUrl(data.components[data.components.length - 1].buttons[0].url) :
                data.components[data?.components?.length - 1].buttons[0]?.type ===
                  "URL" && !data.templateTracking && !data.buttonColumns?.includes("URL1") ? data.components[data.components.length - 1].buttons[0].url
                  : data.components[data.components.length - 1].buttons[1]?.type ===
                    "URL" && data.buttonColumns?.includes("URL1")
                    ? this.extractUrl(data.components[data.components.length - 1].buttons[1].url)
                    : data.components[data.components.length - 1].buttons[1]?.type ===
                      "URL" && data?.templateTracking && !data.buttonColumns?.includes("URL1")
                      ? this.extractAndCleanUrl(data.components[data.components.length - 1].buttons[1].url) :
                      data.components[data.components.length - 1].buttons[1]?.type ===
                        "URL" && !data.templateTracking && !data.buttonColumns?.includes("URL1") ?
                        data.components[data.components.length - 1].buttons[1].url
                        : data.components[data.components.length - 1].buttons[2]?.type ===
                          "URL" && data.buttonColumns?.includes("URL1")
                          ? this.extractUrl(data.components[data.components.length - 1].buttons[2].url) : data.components[data.components.length - 1].buttons[2]?.type ===
                            "URL" && !data.buttonColumns?.includes("URL1")
                            ? this.extractAndCleanUrl(data.components[data.components.length - 1].buttons[2].url) : ""
            : ""
          : "",
      webUrlChecked1:
        type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          ? data.components[data.components.length - 1]?.buttons?.length
            ? data.components[data.components.length - 1].buttons[2]?.type ===
              "URL" && data.components[data.components.length - 1].buttons[1]?.type === "URL"
              ? true
              : data.components[data.components.length - 1].buttons[3]?.type ===
                "URL"
                ? true
                :data.components[data.components.length - 1].buttons[1]?.type === "URL" &&data?.components[data.components.length - 1]?.buttons[0]?.type === "URL" ?true:false
            : false
          : false,
      webUrlDropdown1:
        type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          ? data.components[data.components.length - 1]?.buttons?.length > 0 &&
          (data.components[data.components.length - 1].buttons[1]?.type ===
            "URL" &&
            data.components[data.components.length - 1].buttons[1]?.example &&
            data.components[data.components.length - 1].buttons[0]?.type ===
            "URL" && data?.buttonColumns?.includes("URL2")
            ? "dynamicUrl"
            : data.components[data.components.length - 1].buttons[2]?.type ===
              "URL" &&
              data.components[data.components.length - 1].buttons[2]?.example &&
              data.components[data.components.length - 1].buttons[1]?.type ===
              "URL" && data?.buttonColumns?.includes("URL2")
              ? "dynamicUrl"
              : data.components[data.components.length - 1].buttons[2]?.type ===
                "URL" &&
                data.components[data.components.length - 1].buttons[3]?.example &&
                data?.buttonColumns?.includes("URL2") &&
                data.components[data.components.length - 1].buttons[2]?.type === "URL" ? "dynamicUrl" : "staticUrl")
          : "staticUrl",
      websiteName1:
        type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          ? data.components[data.components.length - 1]?.buttons?.length
            ? data.components[data.components.length - 1].buttons[1]?.type ===
              "URL" &&
              data.components[data.components.length - 1].buttons[0]?.type ===
              "URL"
              ? data.components[data.components.length - 1].buttons[1]?.text
              : data.components[data.components.length - 1].buttons[2]?.type ===
                "URL" && data.components[data.components.length - 1].buttons[1]?.type ===
                "URL"
                ? data.components[data.components.length - 1].buttons[2]?.text
                : data.components[data.components.length - 1].buttons[3]?.type ===
                  "URL" && data.components[data.components.length - 1].buttons[2]?.type ===
                  "URL"
                  ? data.components[data.components.length - 1].buttons[3]?.text : ""
            : ""
          : "",
      webUrl1:
        type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          ? data.components[data.components.length - 1]?.buttons?.length
            ? data.components[data.components.length - 1].buttons[0]?.type ===
              "URL" &&
              data.components[data.components.length - 1].buttons[1]?.type ===
              "URL" && data.buttonColumns?.includes("URL2")
              ? this.extractUrl(data.components[data.components.length - 1].buttons[1]?.url) :
              data.components[data.components.length - 1].buttons[0]?.type ===
                "URL" &&
                data.components[data.components.length - 1].buttons[1]?.type ===
                "URL" && !data?.buttonColumns?.includes("URL2")
                ? this.extractAndCleanUrl(data.components[data.components.length - 1].buttons[1]?.url)
                : data.components[data.components.length - 1].buttons[2]?.type ===
                  "URL" && data.components[data.components.length - 1].buttons[1]?.type === "URL" && data.buttonColumns?.includes("URL2")
                  ? this.extractUrl(data.components[data.components.length - 1].buttons[2]?.url) :
                  data.components[data.components.length - 1].buttons[2]?.type ===
                    "URL" && data.components[data.components.length - 1].buttons[1]?.type === "URL" && !data.buttonColumns?.includes("URL2")
                    ? this.extractAndCleanUrl(data.components[data.components.length - 1].buttons[2]?.url) :
                    data.components[data.components.length - 1].buttons[3]?.type ===
                      "URL" && data.buttonColumns?.includes("URL2") && data.components[data.components.length - 1].buttons[2]?.type === "URL"
                      ? this.extractUrl(data.components[data.components.length - 1].buttons[3]?.url) :
                      data.components[data.components.length - 1].buttons[3]?.type ===
                        "URL" && !data.buttonColumns?.includes("URL2") && data.components[data.components.length - 1].buttons[2]?.type === "URL"
                        ? this.extractAndCleanUrl(data.components[data.components.length - 1].buttons[3]?.url)
                        : ""
            : ""
          : "",
      quickReplyBtns: "",
      trackLinks: data.templateTracking ? true : false,
      replyButtons:
        type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          ? data.components[data.components.length - 1]?.buttons?.length
            ? data.components[data.components.length - 1]?.buttons[0]?.type ===
              "QUICK_REPLY"
              ? repBtnsWithKVPair
              : data.components[data.components.length - 1]?.buttons[1]
                ?.type === "QUICK_REPLY"
                ? repBtnsWithKVPair
                : data.components[data.components.length - 1]?.buttons[2]
                  ?.type === "QUICK_REPLY"
                  ? repBtnsWithKVPair
                  : data.components[data.components.length - 1]?.buttons[3]
                    ?.type === "QUICK_REPLY"
                    ? repBtnsWithKVPair
                    : []
            : []
          : [],
      addVariables:
        type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          ? sampleLabelValue
          : [],
      callToAction: "",
      categoryChange:
        type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          ? data.allow_category_change
          : categoryChangeOptions[0].value,
      variable:
        type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          ? data.components[data.components.length - 1]?.buttons?.length > 0 &&
          (data.components[data.components.length - 1].buttons[0]?.type ===
            "URL" &&
            data.components[data.components.length - 1].buttons[0]?.example
            ? data.components[data.components.length - 1].buttons[0]
              ?.example[0]
            : data.components[data.components.length - 1].buttons[1]?.type ===
              "URL" &&
              data.components[data.components.length - 1].buttons[1]?.example
              ? data.components[data.components.length - 1].buttons[1]
                .example[0]
              : "")
          : "",
      variable1:
        type === "view" ||
          type === "duplicate" ||
          type === "edit" ||
          type === "default"
          ? data.components[data.components.length - 1]?.buttons?.length > 0 &&
          (data.components[data.components.length - 1].buttons[0]?.type ===
            "URL" &&
            data.components[data.components.length - 1].buttons[1]?.type ===
            "URL" &&
            data.components[data.components.length - 1].buttons[1]?.example
            ? data.components[data.components.length - 1].buttons[1]
              .example[0]
            : data.components[data.components.length - 1].buttons[2]?.type ===
              "URL" &&
              data.components[data.components.length - 1].buttons[2]?.example
              ? data.components[data.components.length - 1].buttons[2]
                .example[0]
              : "")
          : "",
          
    carouselType :(type === "view" || type === "duplicate" || type === "edit" || type === "default") 
    ? data.components?.some((component: any) => component?.cards?.[0]?.components[0]?.format === 'IMAGE')? 'image carousel' : 'video carousel'
    : 'image carousel',

 buttonOne: (type === "view" || type === "duplicate" || type === "edit" || type === "default") 
    ? data.components?.some((component: any) => component?.cards?.[0]?.components[2]?.buttons?.[0]?.type === 'QUICK_REPLY' )? 'quickReply' :
    data.components?.some((component: any) => component?.cards?.[0]?.components[2]?.buttons?.[0]?.type ===  'PHONE_NUMBER' )? 'mobileNumber' : 
     data.components?.some((component: any) => component?.cards?.[0]?.components[2]?.buttons?.[0]?.type === 'URL' )? 'weburl' 
    : 'quickReply' : 'quickReply',

buttonTwo: (type === "view" || type === "duplicate" || type === "edit" || type === "default") 
    ? data.components?.some((component: any) => component?.cards?.[0]?.components[2]?.buttons?.[1]?.type === 'QUICK_REPLY' )? 'quickReply' :
    data.components?.some((component: any) => component?.cards?.[0]?.components[2]?.buttons?.[1]?.type ===  'PHONE_NUMBER' )? 'mobileNumber' : 
    data.components?.some((component: any) => component?.cards?.[0]?.components[2]?.buttons?.[1]?.type === 'URL' )? 'weburl' : '': '',


          // carouselType:   type === "view" ||
          // type === "duplicate" ||
          // type === "edit" ||
          // type === "default"
          // ? data?.components[1]?.cards && (data?.components[0]?.cards[0]?.components[0]?.format === 'IMAGE' || data?.components[1]?.cards[0]?.components[0]?.format === 'IMAGE')? 'image carousel' : data?.components[1]?.cards && (data?.components[0]?.cards[0]?.components[0]?.format === 'VIDEO' || data?.components[1]?.cards[0]?.components[0]?.format === 'VIDEO')? 'video carousel' : '': '',
          // buttonOne:   type === "view" ||
          // type === "duplicate" ||
          // type === "edit" ||
          // type === "default"
          // ? data?.components[1]?.cards && (data?.components[0]?.cards[0]?.components[2]?.buttons[0]?.type === 'QUICK_REPLY' || data?.components[1]?.cards[0]?.components[2]?.buttons[0]?.type === 'QUICK_REPLY')? 'quickReply' : data?.components[1]?.cards && (data?.components[0]?.cards[0]?.components[2]?.buttons[0]?.type === 'PHONE_NUMBER' || data?.components[1]?.cards[0]?.components[2]?.buttons[0]?.type === 'PHONE_NUMBER')? 'mobileNumber' : 
          // data?.components[1]?.cards && (data?.components[0]?.cards[0]?.components[2]?.buttons[0]?.type === 'URL' || data?.components[1]?.cards[0]?.components[2]?.buttons[0]?.type === 'URL')? 'weburl' : '' : '',
          // buttonTwo:   type === "view" ||
          // type === "duplicate" ||
          // type === "edit" ||
          // type === "default"
          // ? data?.components[1]?.cards && (data?.components[0]?.cards[0]?.components[2]?.buttons[1]?.type === 'QUICK_REPLY' || data?.components[1]?.cards[0]?.components[2]?.buttons[1]?.type === 'QUICK_REPLY')? 'quickReply' : data?.components[1]?.cards && (data?.components[0]?.cards[0]?.components[2]?.buttons[1]?.type === 'PHONE_NUMBER' || data?.components[1]?.cards[0]?.components[2]?.buttons[1]?.type === 'PHONE_NUMBER')? 'mobileNumber' : 
          // data?.components[1]?.cards && (data?.components[0]?.cards[0]?.components[2]?.buttons[1]?.type === 'URL' || data?.components[1]?.cards[0]?.components[2]?.buttons[1]?.type === 'URL')? 'weburl' : '' : '',
    };
    // console.log("d",data);


    const countryCode =
      type === "view" ||
        type === "duplicate" ||
        type === "edit" ||
        type === "default"
        ? data.countryCode
          ? data.countryCode
          : "+91"
        : "+91";

    const handleCarousel  = (media:any , values: any, variable: any, countryCodes: any) => {
      this.setState({"carouselMedia": media});
      this.setState({"carouselData": values});
      this.setState({"variable": variable});
      this.setState({"countryCodeArr": countryCodes});
      // console.log("countrycode in create file", countryCodes);
    }


    return (
      <React.Fragment>
        <div className="templateContainer">
          <div className="breadcrumb-header">
            <h5>
              <span className="cursor-pointer">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  aria-hidden="true"
                  onClick={this.goToTemplates}
                />{" "}
              </span>
              <span className="pl-3">
                {type === "default" || type === "duplicate"
                  ? "CREATE"
                  : type.toUpperCase()}{" "}
                TEMPLATE
              </span>
            </h5>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={this.validationSchema}
            onSubmit={this.handleSubmit}
            validateOnChange={true}
          >
            {({
              isSubmitting,
              setFieldValue,
              values,
              handleChange,
              setFieldError,
            }) => (
              
              <Form>
                <Row>
                  <Col sm={6} lg={3}>
                    <FormGroup>
                      <Label htmlFor="templateName">
                        Template Name<span className="required"></span>
                        <span className="ml-1">
                          <TooltipReference
                            placement="right"
                            icon={faInfoCircle}
                            content="Name can only contain lowercase alphanumeric characters & underscores (_)"
                            tooltipId="tempName"
                          ></TooltipReference>
                        </span>
                      </Label>
                      <div className="float-right text-muted small mt-2">
                        {this.state.characterCount} / 60
                      </div>
                      <Field
                        type="text"
                        id="templateName"
                        name="templateName"
                        className="form-control formcontrol"
                        disabled={
                          type === "view" ||
                          data.templateStatus === "APPROVED" ||
                          data.templateStatus === "REJECTED"
                        }
                        placeholder="Enter template name"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          // this.restrictText(e, setFieldValue, '')
                          setFieldValue("templateName", e.target.value);
                          this.setState({
                            characterCount: e.target.value.length,
                          });
                        }}
                      />
                      <ErrorMessage
                        name="templateName"
                        component="div"
                        className="text-danger pt-1"
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={6} lg={3}>
                    <FormGroup>
                      <Label htmlFor="category">
                        Category<span className="required"></span>
                      </Label>
                      <Field name="category">
                        {({ field, form }: any) => (
                          <Select
                            {...field}
                            options={categoryOpt}

                            onChange={(selectedOption: SelectOption) => {
                              form.setFieldValue("sections",[])
                              form.setFieldValue("options","none")
                              // form.setFieldValue("messageBody","")
                              form.setFieldValue("selectedVariable", "");
                              form.setFieldValue("addVariables", []);
                              form.setFieldValue("couponValue","")
                              form.setFieldValue(
                                "category",
                                selectedOption?.value
                              );

                              if(selectedOption?.value==="catalog_marketing"){
                                form.setFieldValue("catalogOption","catalogue")
                                form.setFieldValue("couponValue","")
                              }
                              else if(selectedOption?.value==="authentication"){
                                form.setFieldValue("catalogOption","")
                                form.setFieldValue("couponValue","")
                                form.setFieldValue("messageBody","{VERIFICATION_CODE} is your verification code.");
                                // if(values?.timeIndicator &&values?.expirationwarning){
                                //   setFieldValue("footer",`The code will expire in ${values?.timeIndicator} minutes.`)
                                // }
                              }
                              else{
                                form.setFieldValue("catalogOption","")
                                form.setFieldValue("couponValue","")

                              }
                            }}
                            value={
                              categoryOpt
                                .flatMap(group => group.options)
                                .find(option => option.value === field.value) || null
                            }
                            placeholder="Select Category"
                            isDisabled={type === "view"}
                            styles={customStyles}
                            components={{ Option: CustomOption }}

                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="category"
                        component="div"
                        className="text-danger pt-1"
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={6} lg={3}>
                    <FormGroup>
                      <Label htmlFor="categoryChange">
                        Allow Category Change<span className="required"></span>
                        <span className="ml-1">
                          <TooltipReference
                            placement="right"
                            icon={faInfoCircle}
                            content="Making Yes by default - Avoid the template rejection for miscategorization"
                            tooltipId="category"
                          ></TooltipReference>
                        </span>
                      </Label>
                      <Field name="categoryChange">
                        {({ field, form }: any) => (
                          <Select
                            {...field}
                            options={categoryChangeOptions.map((option) => ({
                              ...option,
                              isDisabled: type === "view",
                            }))}
                            onChange={(selectedOption: SelectOption) => {

                              form.setFieldValue(
                                "categoryChange",
                                selectedOption?.value
                              );
                            }}
                            value={
                              // type === 'view' || type === 'duplicate' || type === 'edit'
                              //   ? categoryChangeOptions.find(option => option.value === data.allow_category_change)
                              //   :
                              categoryChangeOptions.find(
                                (option) => option.value === field.value
                              ) || null
                            }
                            placeholder="Select Category"
                            isDisabled={type === "view" || values.category == "catalog_marketing" || values.category === "coupon_marketing"}
                            styles={customStyles}
                          />
                        )}
                      </Field>
                    </FormGroup>
                  </Col>
                  {/* <Col>
                    <FormGroup>
                      <Label htmlFor="channel">
                        Channel<span className="required"></span>
                      </Label>
                      <Field name="channel">
                        {({ field, form }: any) => (
                          <Select
                            {...field}
                            options={this.state.channelOptions}
                            onChange={(selectedOption: SelectOption) => {
                              form.setFieldValue('channel', selectedOption?.value);
                            }}
                            value={
                              // type === 'view' || type === 'duplicate' || type === 'edit'
                              //   ? { label: this.state.channelOptions[0]?.label }
                              //   : 
                              this.state.channelOptions.find((option) => option.value === field.value) || null
                            }
                            placeholder="Select Channel"
                          />
                        )}
                      </Field>
                      <ErrorMessage name="channel" component="div" className="text-danger pt-1" />
                    </FormGroup>
                  </Col> */}
                  <Col sm={6} lg={3}>
                    <FormGroup>
                      <Label htmlFor="language">
                        Language<span className="required"></span>
                      </Label>
                      <Field name="language">
                        {({ field, form }: any) => (
                          <Select
                            {...field}
                            options={languageOptions.map((option) => ({
                              ...option,
                              isDisabled: type === "view",
                            }))}
                            onChange={(selectedOption: SelectOption) => {
                              form.setFieldValue(
                                "language",
                                selectedOption?.value
                              );
                            }}
                            value={
                              // type === 'view' || type === 'duplicate' || type === 'edit'
                              //   ? { label: (languageOptions.find(opt => opt.value === data.language))?.label }
                              //   :
                              languageOptions.find(
                                (option) => option.value === field.value
                              ) || null
                            }
                            placeholder="Select Language"
                            isDisabled={type === "view"}
                            styles={customStyles}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="language"
                        component="div"
                        className="text-danger pt-1"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md="8">
                    {values.category !== "catalog_marketing" && values.category!=="authentication" && values?.category !== 'carousel'&&  (
                      <>
                      <div className="d-flex">
                        <h6>Header</h6> 
                        <Button
                          type="button"
                          onClick={() => this.handleheader(setFieldValue)}
                          className="optionBtn"
                        >
                          (Optional)
                        </Button>
                        <span className="ml-1">
                          <TooltipReference
                            placement="right"
                            icon={faInfoCircle}
                            content="Add a title or choose which type of media you'll use for this header.
                          Header can't have any of the following special characters \ ~ * _ or emojis."
                            tooltipId="header"
                          />
                        </span>
                      </div>
                      </>
                      )}
                    {this.state.header && values.category!=="catalog_marketing" && values.category!=="authentication"&& values?.category !== 'carousel' ? (
                      <div className="headerbox">
                        <FormGroup className="header-form">
                          <div
                            role="group"
                            aria-labelledby="options-group"
                            className="headerOptions"
                          >
                            {/* <ul>
                            {this.radioOptions.map((option) => (
                              <li key={option.value} className="radioOption">
                                <label>
                                  <Field
                                    type="radio"
                                    name="options"
                                    value={option.value}
                                    className="ml-2"
                                    checked={values.options === option.value}
                                    onChange={() => {
                                      this.setState({ 'addTxtCount': 0 })
                                      setFieldValue('options', option.value);
                                      this.setState({ files: [] });
                                      setFieldValue('file', null);
                                      setFieldValue('content', '');
                                    }}
                                    disabled={type === 'view'}
                                  />
                                  <span className="headerOptnLabel">{option.label}</span>
                                </label>
                              </li>
                            ))}
                          </ul> */}
                            <Row>
                              {this.radioOptions.map((option) => (
                                <Col
                                  xs={6}
                                  sm={6}
                                  md={4}
                                  lg={2}
                                  className="d-flex align-items-center p-1"
                                >
                                  <label className="m-0">
                                    <Field
                                      type="radio"
                                      name="options"
                                      value={option.value}
                                      className="ml-2"
                                      checked={values.options === option.value}
                                      onChange={() => {
                                        this.setState({ addTxtCount: 0 });
                                        setFieldValue("options", option.value);
                                        this.setState({ files: [] });
                                        setFieldValue("file", null);
                                        setFieldValue("content", "");
                                      }}
                                      disabled={type === "view" ||values.catalogOption==="multi-product"}
                                    />
                                    <span className="headerOptnLabel p-0 ml-2">
                                      {option.label}
                                    </span>
                                  </label>
                                </Col>
                              ))}
                            </Row>
                          </div>
                        </FormGroup>
                        {values.options !== "none" ? (
                          <div className="seperationLine"></div>
                        ) : (
                          ""
                        )}
                        {values.options === "text" && (
                          <FormGroup>
                            <Label htmlFor="content" className="pt-2">
                              Content
                            </Label>
                            <div>
                              <Field
                                type="text"
                                id="content"
                                name="content"
                                className="form-control tempInputs"
                                disabled={type === "view"}
                                placeholder="Type your header text here"
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  // this.restrictText(e, setFieldValue, 60);
                                  setFieldValue(e.target.name, e.target.value);
                                  this.setState({
                                    addTxtCount: e.target.value.length,
                                  });
                                }}
                              />
                              <div className="float-right text-muted small mt-2">
                                {this.state.addTxtCount} / 60
                              </div>
                            </div>
                          </FormGroup>
                        )}
                        {(values.options === "image" ||
                          values.options === "video" ||
                          values.options === "document") && (
                            <FormGroup>
                              <div
                                className="dotted-box"
                                onDragOver={(event) => event.preventDefault()}
                                onDrop={(event) =>
                                  this.handleDrop(
                                    event,
                                    setFieldValue,
                                    setFieldError,
                                    values
                                  )
                                }
                              >
                                {this.state.files.length > 0 ? (
                                  this.state.files.map((file: any, index) => (
                                    <>
                                      <div
                                        className={`${values.options === "image"
                                            ? "positionImage"
                                            : "positionVideo"
                                          }`}
                                      >
                                        {values.options === "image" && (
                                          <img
                                            key={index}
                                            id="target"
                                            src={file.imgUrl}
                                            width={200}
                                            height={150}
                                            alt="oldimage"
                                          />
                                        )}
                                        {values.options === "video" && (
                                          <video
                                            key={index}
                                            src={file.imgUrl}
                                            controls
                                            width={300}
                                            height={200}
                                          />
                                        )}
                                        {values.options === "document" && (
                                          <>
                                            <iframe
                                              src={file.imgUrl}
                                              className="pdfSize"
                                            ></iframe>
                                          </>
                                        )}
                                        {type !== "view" && (
                                          <>
                                            <span
                                              title="Delete Image"
                                              className={
                                                values.options === "image"
                                                  ? "deleteImgIcon moveRight"
                                                  : "deleteVidIcon moveRight"
                                              }
                                              onClick={() => {
                                                this.deleteFiles(setFieldValue);
                                              }}
                                            >
                                              <FontAwesomeIcon
                                                icon={faTrash}
                                                className="delIcon"
                                                aria-hidden="true"
                                              />
                                            </span>
                                            <span
                                              title="Change Image"
                                              className={
                                                values.options === "image"
                                                  ? "deleteImgIcon"
                                                  : "deleteVidIcon"
                                              }
                                              onClick={() => {
                                                this.fileInput.current?.click();
                                              }}
                                            >
                                              <FontAwesomeIcon
                                                icon={faExchangeAlt}
                                                className="delIcon"
                                                aria-hidden="true"
                                              />
                                              <input
                                                ref={this.fileInput}
                                                type="file"
                                                className="hideFileInput"
                                                accept=".jpeg,.jpg,.png,.mp4,.3gpp"
                                                onChange={(event) =>
                                                  this.handleFileChange(
                                                    event.target.files,
                                                    setFieldValue,
                                                    setFieldError,
                                                    values
                                                  )
                                                }
                                              />
                                            </span>
                                          </>
                                        )}
                                      </div>
                                      <div className="fileName mt-2 ml-2">
                                        <a href={file.imgUrl} target="blank">
                                          {file.imgName}
                                        </a>
                                      </div>
                                    </>
                                  ))
                                ) : (
                                  <>
                                    <div className="text-center dragHeader h6">
                                      Drag and Drop your files here
                                    </div>
                                    <div className="text-center dragContent">
                                      {values.options === "image" && (
                                        <p>
                                          Accepted file types : .JPEG, .JPG, .PNG
                                          within 5MB size
                                        </p>
                                      )}
                                      {values.options === "video" && (
                                        <p>
                                          Accepted file types : .mp4, .3gpp within
                                          10MB size
                                        </p>
                                      )}
                                      {values.options === "document" && (
                                        <p>
                                          Accepted file types : .pdf within 10MB
                                          size
                                        </p>
                                      )}
                                    </div>
                                    <label className="uploadFiles1">
                                      <span>
                                        Upload{" "}
                                        {values.options === "image"
                                          ? "image"
                                          : values.options === "video"
                                            ? "video File"
                                            : values.options === "document"
                                              ? "PDF File"
                                              : "files"}
                                        <FontAwesomeIcon
                                          icon={faCloudUpload}
                                          className="ml-2"
                                        ></FontAwesomeIcon>
                                      </span>
                                      {values.options === "image" ? (
                                        <input
                                          type="file"
                                          className="hideFileInput"
                                          accept=".jpeg,.jpg,.png"
                                          onChange={(event) =>
                                            this.handleFileChange(
                                              event.target.files,
                                              setFieldValue,
                                              setFieldError,
                                              values
                                            )
                                          }
                                        />
                                      ) : values.options == "video" ? (
                                        <input
                                          type="file"
                                          className="hideFileInput"
                                          accept=".mp4,.3gpp"
                                          onChange={(event) =>
                                            this.handleFileChange(
                                              event.target.files,
                                              setFieldValue,
                                              setFieldError,
                                              values
                                            )
                                          }
                                        />
                                      ) : (
                                        <input
                                          type="file"
                                          className="hideFileInput"
                                          accept="application/pdf"
                                          onChange={(event) =>
                                            this.handleFileChange(
                                              event.target.files,
                                              setFieldValue,
                                              setFieldError,
                                              values
                                            )
                                          }
                                        />
                                      )}
                                    </label>
                                  </>
                                )}
                              </div>
                            </FormGroup>
                          )}
                      </div>
                    ) : (
                      ""
                    )}
                    {values.options === "text" && (
                      <ErrorMessage
                        name="content"
                        component="div"
                        className="text-danger pt-1"
                      />
                    )}
                    {(values.options === "image" ||
                      values.options === "video" ||
                      values.options === "document") && (
                        <ErrorMessage
                          name="file"
                          component="div"
                          className="text-danger pt-1"
                        />
                      )}
                    <TemplateBody
                      values={values}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      type={type}
                      data={data}
                      restrictText={this.restrictText}
                      setcode={this.setcode}
                      countrycode={countryCode}
                      handleCarousel={handleCarousel}
                    ></TemplateBody>
                    {(type === "edit" ||
                      type === "duplicate" ||
                      type === "create" ||
                      type === "default") &&
                      data.templateStatus !== "APPROVED" && (
                        <Button
                          type="submit"
                          onClick={() => this.submitType("SUBMIT")}
                          disabled={profile.profile.business.freeTrial || this.state.isLoading || (this.state.carouselData?.length <= 0 && values.category === 'carousel')}
                          className="signUpBtn float-right mb-2"
                        >
                          {this.state.isLoading
                            ? "Loading..." + " "
                            : "Submit for Approval"}
                          {this.state.isLoading && (
                            <div className="btnloader"></div>
                          )}
                        </Button>
                      )}
                    {(type === "create" ||
                      type === "default" ||
                      data.templateStatus === "DRAFT") &&
                      type !== "view" &&
                      data.templateStatus !== "APPROVED" && (
                        <Button
                          type="submit"
                          onClick={() => this.submitType("DRAFT")}
                          className="signUpBtn float-right mr-2 mb-2"
                          disabled={
                            profile.profile.business.freeTrial || this.state.isLoadingDraft ||
                            type === "view" || (this.state.carouselData?.length <= 0 && values.category === 'carousel')
                          }
                        >
                          {this.state.isLoadingDraft
                            ? "Loading..." + " "
                            : "Save as Draft"}
                          {this.state.isLoadingDraft && (
                            <div className="btnloader"></div>
                          )}
                        </Button>
                      )}
                    {/* approved edit button */}
                    {data.templateStatus === "APPROVED" && (
                      <Button
                        type="submit"
                        onClick={() => this.submitType("APPROVED")}
                        disabled={ this.state.isLoading ||
                          profile.profile.business.freeTrial || type === "view" || (this.state.carouselData?.length <= 0 && values.category === 'carousel')
                        }
                        className="signUpBtn float-right mr-2 mb-2"
                      >
                        {this.state.isLoading
                          ? "Loading..." + " "
                          : "Submit for Approval"}
                        {this.state.isLoading && (
                          <div className="btnloader"></div>
                        )}
                      </Button>
                    )}

                    <Button
                      type="button"
                      className="float-right mr-2 cancelBtn mb-2"
                      onClick={this.goToTemplates}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col sm={12} md="4">
                    <div className="fixed-component">
                      <TemplatePreview
                        values={values}
                        files={this.state.files}
                        component="createtem"
                        type={type}
                        carouselData={this.state.carouselData}
                      ></TemplatePreview>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}
// const createTemplateWithRouter=()=>{
//   const navigate=useNavigate();
//   <CreateTemplate navigate={navigate}/>
// }
const profileProps = (state: any) => {
  const profileData = {
    profile: state.cartreducer.business,
  };
  return profileData;
};

export default connect(profileProps)(CreateTemplate);

import * as React from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import {
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import * as Yup from "yup";
import { connect, useSelector } from "react-redux";
import { confirmAlert, toast } from "../../common/alert";
import Select from "react-select";
import {
  faInfoCircle,
  faCheck,
  faWarning,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, FormGroup, Label } from "reactstrap";
import SwitchButton from "../Contacts/SwitchButton";
import "./Campaign.scss";
import DateTime from "../../common/DateTime";
import PreviewWhatsapp from "./PreviewWhatsapp";
import Modall from "../../common/modall";
import TemplateList from "../Template/templateList";
import {
  createBroadcast,
  campaignCount,
  getBroadcastAmount,
} from "../../services/campaignService";
// import Excel from "../../Authentication/Excel";
import UploadContactExcel from "./UploadContactExcel";
import TooltipReference from "../../common/tooltip";
import campaignContext from "./campaignContext";
import axios from "../../utils/axios";
import moment from "moment";
import { Link } from "react-router-dom";
import { removeExistData } from "../../redux/actions/action";
import { SelectTeam } from "../../botFlows/components/designFlow/editor/assignConversation";

// const validateExcelData = (excelData: any, selectContact: string) => {
//   if (
//     selectContact === "upload" &&
//     (!excelData || Object.entries(excelData)?.length === 0)
//   ) {
//     return "Please upload excel sheet";
//   }
//   return null;
// };

interface CampaignState {
  openModal: boolean;
  whatsappOpt: boolean;
  marketingOpt: boolean;
  campaignModal: boolean;
  templateId: string;
  groupsList: any;
  selectCommunity: string;
  // checkExistCommunity: string;
  checkExistCommunity: any;
  UTCformat: any;
  check: any;
  changeModal: boolean;
  MemberName: any;
  selectMember: any;
  selectedBot: any;
  selectedTeam:any;
  selectedTeamMember:any;
  groupCreated: string;
  uploadExcel: boolean;
  removeCommunity: boolean;
  selectedTemplate: any;
  selectedDataManualsCommunity: any;
  selectedDataExcelCommunity: any;
  selectedSuccess: string;
  communityDataRem: number;
  isChangeExcelStatus: boolean;
  isChecked: [];
  sendCampaignCount: number;
  excelData_Val: string;
  exCommunityValid: string;
  tempVariables: string[];
  modalTitle: string;
  // templateVariables: [];
  templateVariables: any[];
  repeatCampaign: boolean;
  allBots: any[];
  allTeam:any[];
  allTeamMember:any[];
  roundRobin:boolean,
  showMediaLink: boolean;
  broadcastAmount: {
    totalCredits: number;
    broadcastCost: number;
    broadcastAvailable: boolean;
    broadcastCountExceeds: boolean;
  };
  isRepeatContact:boolean;
  modalSize:string;
  // groupedCarousel: any;
}
interface SelectBtnOption {
  value: string;
  label: string;
}
const ScheduleDateOptions: SelectBtnOption[] = [
  { value: "immediately", label: "Send Immediately" },
  { value: "date", label: "Schedule Date" },
];

const AssignOptions: SelectBtnOption[] = [
  { value: "bot", label: "Assign to Bot" },
  { value: "member", label: "Assign to Member" },
  { value: "team", label: "Assign to Team" },

];
const SelectContact: SelectBtnOption[] = [
  { value: "newCommunity", label: "New Community" },
  { value: "existCommunity", label: "Existing Community" },
  { value: "upload", label: "Upload Contacts" },
];
interface CampaignProps {
  communitySelect: (Select: any) => void;
  groupCreated?: string;
  callbackChecked: any;
  selectedContact: any;
  checkedData?: (check: any) => void;
  excelData?: (data: any, fileName: string) => void;
  selectedDataManual: any;
  selectedDataExcel: any;
  channelData: any;
  memberData: any;
  // businessUid:any;
  excelFileName?: string;
  selectedContactData: any;
  // checkboxExistCommunity:any;
  memberUid: string;
  onGoBack: () => void;
  excelContactList: any;
  isCallbackUploadExcel: boolean;
  isCallbackValidExcel: boolean;
  business: any;
  profile: {
    business: {
      freeTrial: boolean;
    };
  };
  existCommunityData: [];
  removeExistData: any;
}

var groupedCarouselData: any= [];

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    width: "250px",
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    width: "250px",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    width: "100%",
  }),
};
class DefaultCampaign extends React.Component<CampaignProps, CampaignState> {
  static contextType = campaignContext;
  context!: React.ContextType<typeof campaignContext>;

  constructor(props: CampaignProps) {
    super(props);
    this.state = {
      whatsappOpt: true,
      marketingOpt: false,
      roundRobin:false,
      campaignModal: false,
      templateId: "",
      openModal: false,
      groupsList: [],
      selectCommunity: "",
      checkExistCommunity: "",
      UTCformat: "",
      check: {},
      changeModal: false,
      MemberName: [],
      selectMember: "",
      selectedTeamMember:"",
      groupCreated: "",
      uploadExcel: false,
      removeCommunity: false,
      selectedTemplate: {},
      selectedDataManualsCommunity: [],
      selectedDataExcelCommunity: [],
      selectedSuccess: "",
      communityDataRem: 0,
      isChangeExcelStatus: false,
      isChecked: [],
      sendCampaignCount: 0,
      excelData_Val: "",
      exCommunityValid: "",
      tempVariables: [],
      modalTitle: "Select Template",
      templateVariables: [],
      repeatCampaign: false,
      allBots: [],
      allTeam:[],
      allTeamMember:[],
      selectedBot: "",
      selectedTeam:"",
      showMediaLink: false,
      broadcastAmount: {
        totalCredits: 0,
        broadcastCost: 0,
        broadcastAvailable: true,
        broadcastCountExceeds: false,
      },
      isRepeatContact:false,
      modalSize:'modal-xxl'
    };
  }
  validationSchema = Yup.object().shape({
    selectPeriod: Yup.string().required("Select period is required"),
    campaign: Yup.string().required("Campaign name is required"),
    selectContact: Yup.string().required("Campaign audience is required"),
    templateId: Yup.string().required("Template is required"),
  });
  

  handleSubmit = async () => {
    try {
      const { channelData } = this.props;
      const {
        UTCformat,
        check,
        checkExistCommunity,
        selectedDataExcelCommunity,
        selectedDataManualsCommunity,
      } = this.state;
      const currentUTC = this.current_milliseconds() as any;
      if (UTCformat > currentUTC || check.selectPeriod === "immediately") {
        const broadCastData = this.state;
        console.log("broadcast", broadCastData);

        let exampleIndex = 0; 
        if(broadCastData?.selectedTemplate?.components[1]?.type === 'CAROUSEL' && broadCastData?.selectedTemplate?.components[1]?.type){
         broadCastData?.selectedTemplate?.components[1]?.cards.forEach((card: any, index: number) => {
            const bodyComponent = card.components.find((c: any) => c.type === "BODY");
    console.log("group sckjnkjcjsknm ", groupedCarouselData)
            if (bodyComponent && bodyComponent.example && exampleIndex < groupedCarouselData.length) {
                bodyComponent.example.body_text = [groupedCarouselData[exampleIndex].values];
                exampleIndex++;
            }
        });
      }

        const channelId = channelData?.value;
        if (
          checkExistCommunity ||
          selectedDataExcelCommunity ||
          selectedDataManualsCommunity
        ) {
          if (channelId) {

            const broadCast = await createBroadcast(
              broadCastData,
              channelId,
              this.props,
              this.state.showMediaLink
            );
            // {
            //   console.log("debug", broadCast);
            // }

            if (broadCast) {
              toast("success", "Campaign created successfully");
              // window.location.reload();
              this.props.onGoBack();
              this.fetchSendCampaignCount(channelId);
              this.handleRemoveAll();
            }
          }
        }
      }
      if (UTCformat <= currentUTC && check.selectPeriod === "date") {
        toast("error", "schedule time error");
      }
    } catch (error) {
      console.log(error);
      toast("error", "campaign catch error");
    }
    // setSubmitting(false);
  };

  async fetchSendCampaignCount(channelId: string) {
    const res = await campaignCount(channelId);
    this.setState({ sendCampaignCount: res });
  }

  UTC_milliseconds = (milliSecond: any) => {
    this.setState({ UTCformat: milliSecond });
  };

  current_milliseconds = () => {
    const date = new Date();
    const dateTimeString = date.toUTCString();
    const dateTime = new Date(dateTimeString);
    const utcMilliseconds = Date.UTC(
      dateTime.getUTCFullYear(),
      dateTime.getUTCMonth(),
      dateTime.getUTCDate(),
      dateTime.getUTCHours(),
      dateTime.getUTCMinutes(),
      dateTime.getUTCSeconds(),
      dateTime.getUTCMilliseconds()
    );
    return utcMilliseconds;
  };

  handleShow = () => {
    const { check } = this.state;
    if (check.selectContact === "upload") {
      this.setState({ uploadExcel: true });
    }
    this.setState({ openModal: true, campaignModal: true,   modalSize:'modal-xxl' });
  };

  handleClose = () => {
    const { check } = this.state;

    if (check.selectContact === "upload") {
      this.setState({ uploadExcel: true });
    }
    this.setState({
      openModal: false,
      changeModal: false,
      // uploadExcel: false,
      campaignModal: false,
      modalSize: 'modal-xxl'
    });
    // if (this.state.uploadExcel === false) {
    // }
  };

  tempIdCollections = (tempId: any, variables: any, showLink: boolean) => {
    this.setState({
      templateId: tempId,
      templateVariables: variables,
      showMediaLink: showLink,
    });
  };
  
  templateVariables = (
    tempVar: any,
    showLink: boolean,
    templateVariables: any,
    groupedCarousel: any,
  ) => {
    console.log("carousellllllllll",groupedCarousel)
    groupedCarouselData = groupedCarousel;
    this.setState({
      tempVariables: templateVariables,
      showMediaLink: showLink,
    });

    // console.log("set grouped", this.state.groupedCarousel)
  };

  formik = null;

  getBots(prevstate?: Readonly<CampaignState>) {
    const botPayload = {
      uid: this.props.business?.business?.uid,
      limit: 1000,
      page: 0,
      channelId: this.props.channelData?.value
    };
    axios
      .post(`bot/getAll`, botPayload)
      .then((res) => {
        this.setState({ allBots: res.data.bots });
        if (this.context.repeatCampaign) {
          const selectedBotDetails = res.data.bots.find(
            (bot: any) => bot.id === this.context.repeatCampaign?.replyAssignId
          );
          this.setState({
            selectedBot: selectedBotDetails
              ? {
                id: selectedBotDetails.id,
                label: selectedBotDetails.name,
                value: selectedBotDetails.name,
                uid: selectedBotDetails.uid,
              }
              : "",
          });
        }
      })
      .catch((err) => {
        this.setState({ allBots: [] });
      });
  }
  getTeam(prevstate?: Readonly<CampaignState>) {
    const botPayload = {
      businessUid: this.props.business?.business?.uid,
      limit: 1000,
      page: 0,
    };
    axios
      .post(`team/getAll`, botPayload)
      .then((res) => {
        console.log("da",res.data?.data)
        this.setState({ allTeam: res.data?.data });
        if (this.context.repeatCampaign) {
          const selectedBotDetails = res.data?.data.find(
            (bot: any) => bot.id === this.context.repeatCampaign?.replyAssignId
          );
          this.setState({
            selectedTeam: selectedBotDetails
              ? {
                id: selectedBotDetails.id,
                label: selectedBotDetails.name,
                value: selectedBotDetails.name,
                uid: selectedBotDetails.uid,
              }
              : "",
          });
        }
      })
      .catch((err) => {
        this.setState({ allTeam: [] });
      });
  }
  // getTeamMember(prevstate?: Readonly<CampaignState>) {
  //   axios
  //     .get(`team/${this.state.selectedTeam?.uid}`)
  //     .then((res) => {
  //       console.log("da",res.data.members)
  //       this.setState({ allTeamMember: res.data.members});
  //       if (this.context.repeatCampaign) {
  //         const selectedBotDetails = res.data?.members.find(
  //           (bot: any) => bot.id === this.context.repeatCampaign?.replyAssignId
  //         );
  //         this.setState({
  //           selectedTeam: selectedBotDetails
  //             ? {
  //               id: selectedBotDetails.id,
  //               label: selectedBotDetails.name,
  //               value: selectedBotDetails.name,
  //               uid: selectedBotDetails.uid,
  //             }
  //             : "",
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       this.setState({ allTeamMember: [] });
  //     });
  // }
  componentDidMount = async () => {
    const {
      memberData,
      // businessUid,
      callbackChecked,
      selectedContact,
      selectedDataManual,
      selectedDataExcel,
      selectMember,
      channelData,
      isCallbackUploadExcel,
      isCallbackValidExcel,
      selectedBot,
      selectedTeam,
      existCommunityData,
    } = this.props as any;
    this.setState({
      check: {
        campaign: moment().format("DD MMM YYYY h:mmA"),
      },
    });
    const channelId = channelData?.value;
    if (channelId) {
      this.fetchSendCampaignCount(channelId);
      this.getBots();
      this.getTeam();
      // this.getTeamMember();
    }
    // if (this.props.business?.business?.uid) this.getBots();
    if (selectedContact?.length > 0) {
      this.setState({ checkExistCommunity: selectedContact });
    } else if (selectedDataManual.success === "MANUAL") {
      this.setState({
        selectedDataManualsCommunity: selectedDataManual.newCommunityData,
        selectedSuccess: selectedDataManual.success,
      });
    } else if (selectedDataExcel.success === "EXCEL") {
      this.setState({
        selectedDataExcelCommunity: selectedDataExcel.newCommunityData,
        selectedSuccess: selectedDataExcel.success,
        uploadExcel: true,
        isChangeExcelStatus: true,
        excelData_Val: "COMPLETED",
        exCommunityValid: "COMPLETED",
      });
    }

    if (callbackChecked?.check) {
      this.setState({ check: callbackChecked.check });
      if (this.formik) {
        (this.formik as any).setFieldValue("isChecked", callbackChecked?.check);
      }
    }

    if (callbackChecked?.check?.selectedDataExcel?.success === "EXCEL") {
      this.setState({
        check: { ...callbackChecked, selectedContact: "upload" },
      });
    }

    if (memberData) {
      this.setState({ MemberName: memberData });
    }

    if (callbackChecked?.templateId) {
      this.setState({
        templateId: callbackChecked.templateId,
      });
    }

    if (callbackChecked?.tempVariables) {
      this.setState({
        tempVariables: callbackChecked.tempVariables,
      });
    }

    if (callbackChecked?.marketingOpt) {
      this.setState({
        marketingOpt: callbackChecked.marketingOpt,
      });
    }
    if (callbackChecked?.roundRobin) {
      this.setState({
        roundRobin: callbackChecked.roundRobin,
      });
    }

    if (callbackChecked?.selectMember) {
      this.setState({
        selectMember: callbackChecked.selectMember,
      });
    }

    if (callbackChecked?.selectedBot) {
      this.setState({
        selectedBot: callbackChecked.selectedBot,
      });
    }
    if (callbackChecked?.selectedTeam) {
      this.setState({
        selectedBot: callbackChecked.selectedTeam,
      });
    }

    if (isCallbackUploadExcel) {
      this.setState({ uploadExcel: isCallbackUploadExcel });
    }
    if (isCallbackValidExcel) {
      this.setState({ excelData_Val: "SUCCESS" });
    }
    if (callbackChecked?.UTCformat) {
      this.setState({ UTCformat: callbackChecked?.UTCformat });
    }
  };

  componentDidUpdate = async (
    prevProps: Readonly<CampaignProps>,
    prevState: Readonly<CampaignState>
  ) => {
    const { communityDataRem, templateId } = this.state;
    const { memberData, channelData } = this.props as any;
    // console.log("member",memberData);
    
    // if (
    //   prevProps.business?.business?.uid !== this.props.business?.business?.uid
    // )

    if (prevState.check !== this.state.check) {
      this.setState({ check: this.state.check });
    }

    const channelId = channelData?.value;
    if (prevProps.channelData !== this.props.channelData) {
      if (channelId) {
        this.fetchSendCampaignCount(channelId);
        this.getBots(prevState);
        this.getTeam(prevState);
      }
    }

    if (communityDataRem === 1) {
      this.setState({
        communityDataRem: 0,
        checkExistCommunity: "",
        selectedSuccess: "",
        selectedDataManualsCommunity: "",
        uploadExcel: false,
        selectedDataExcelCommunity: [],
        // check:{selectContact:''}
      });
      // this.props.removeExistData();
      this.handleRemoveAll();
      this.props.communitySelect("newCommunity");
    }
    if (communityDataRem === 2) {
      this.setState({
        communityDataRem: 0,
        selectedSuccess: "",
        selectedDataManualsCommunity: "",
        uploadExcel: false,
        selectedDataExcelCommunity: [],
      });
      this.props.communitySelect("existCommunity");
    }
    if (communityDataRem === 3) {
      this.setState({
        uploadExcel: true,
        communityDataRem: 0,
        selectedDataManualsCommunity: "",
        checkExistCommunity: "",
        // check:{selectContact:''}
      });
      //  this.props.removeExistData();
      this.handleRemoveAll();
      this.props.communitySelect("uploadContacts");
    }

    const { callbackChecked } = this.props as any;

    if (callbackChecked !== prevProps.callbackChecked) {
      this.setState({
        check: callbackChecked.check,
        templateId: callbackChecked.templateId,
        checkExistCommunity:
          callbackChecked?.checkExistCommunity?.length > 0
            ? callbackChecked.checkExistCommunity
            : this.state.checkExistCommunity, // Use the existing value if the condition is false
        campaignModal: false,
        UTCformat: callbackChecked?.UTCformat,
      });
    }
    if (templateId !== prevState.templateId) {
      if (templateId !== "") {
        this.createCommunity();
      }
    }
    if (prevState.tempVariables !== this.state.tempVariables) {
      this.setState({ templateVariables: this.state.templateVariables });
    }

    if (prevProps?.memberData !== memberData) {
      this.setState({ MemberName: memberData });
    }
    const { selectedContact, selectedDataManual } = this.props;

    if (
      !prevState.repeatCampaign &&
      prevProps.memberData &&
      prevProps.memberData.length > 0 &&
      this.context.repeatCampaign
    ) {
      const selectedMember = prevProps.memberData.find(
        (member: any) =>
          member.id === this.context.repeatCampaign?.replyAssignId
      );
      this.setState({
        templateId: this.context.repeatCampaign.templateId,
        templateVariables: this.context.repeatCampaign.templateVariables
          ? this.context.repeatCampaign.templateVariables.map(
            (variable, index) => {
              return {
                id: "" + (index + 1),
                name: "" + (index + 1),
                value: variable,
              };
            }
          )
          : [],
        selectedTemplate: this.context.repeatCampaign.selectedTemplate,
        tempVariables: this.context.repeatCampaign.templateVariables
          ? this.context.repeatCampaign.templateVariables
          : [],
        check: {
          campaign: this.context.repeatCampaign?.name,
          selectPeriod:
            this.context.repeatCampaign?.type === "immediate"
              ? "immediately"
              : "date",
          assign:
            this.context.repeatCampaign?.replyAssignTo === 0
              ? undefined
              : this.context.repeatCampaign.replyAssignTo === 1
                ? "bot"
                : this.context.repeatCampaign.replyAssignTo === 3?"team":
                "member",
          assignMember: this.context.repeatCampaign.replyAssignTo,
          selectContact: "existCommunity",
        },
        marketingOpt: this.context.repeatCampaign.forceSent,
        selectMember: selectedMember
          ? {
            id: selectedMember.id,
            label: selectedMember.name,
            value: selectedMember.name,
            uid: selectedMember.uid,
          }
          : "",
        checkExistCommunity: this.context.repeatCampaign.communityList,
        UTCformat:
          this.context.repeatCampaign.scheduledAt > 0
            ? this.context.repeatCampaign.scheduledAt
            : "",
        repeatCampaign: true,
      });
    }

    const {
      checkExistCommunity,
      selectedDataManualsCommunity,
      selectedDataExcelCommunity,
    } = this.state;
    const manualCommunity =
      Object.keys(selectedDataManualsCommunity)?.length > 0
        ? [selectedDataManualsCommunity]
        : "";
    const excelCommunity =
      Object.keys(selectedDataExcelCommunity)?.length > 0
        ? [selectedDataExcelCommunity]
        : "";
    if (
      templateId !== prevState.templateId &&
      (checkExistCommunity || manualCommunity || excelCommunity)
    ) {
      const communityList =
        checkExistCommunity?.length > 0
          ? checkExistCommunity
          : excelCommunity?.length > 0
            ? excelCommunity
            : manualCommunity;

      const amountPayload = {
        communities: communityList,
        templateId: templateId,
      };
      const response = await getBroadcastAmount(channelId, amountPayload);
      this.setState({ broadcastAmount: response });
    }
  };

  createCommunity = () => {
    if (this.props.callbackChecked) {
      const callbackChecked = this.props.callbackChecked;
    }
    const check = this.state;

    if (this.props.checkedData) {
      this.props.checkedData(check);
    }
  };

  marketingOptState = (check: boolean) => {
    this.setState({ marketingOpt: check });
  };
  roundRobinOptState = (check: boolean) => {
    this.setState({ roundRobin: check });
  };
  whatsappOptState = (check: boolean) => {
    this.setState({ whatsappOpt: check });
  };
  handleChange = (Option: any) => {
    this.setState({ selectCommunity: Option });
  };
  selectedTemplate = (data: any) => {
    this.setState({ selectedTemplate: data });
  };

  handleCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const { name, value } = event.target;
    const { selectedSuccess, checkExistCommunity, uploadExcel, check } =
      this.state;
    // const { selectedContact } = this.props;
    const { existCommunityData } = this.props;
    // if (check.assign === 'member')
    //   this.setState({ selectMember: null });
    // else {
    //   this.setState({ selectedBot: null });
    // }
    this.setState(
      (prevState) => ({
        check: {
          ...prevState.check,
          [name]: value,
        },
      }),
      () => {
        setFieldValue(name, value);
        
        // if (check.selectContact && check.selectContact === value) {
        switch (value) {
          case "newCommunity":
            if (existCommunityData?.length > 0 || selectedSuccess === "EXCEL") {
              confirmAlert(
                "warning",
                "Your changes will be lost. Are you sure you want to continue?",
                () => {
                  if (
                    existCommunityData?.length > 0 ||
                    selectedSuccess === "EXCEL"
                  ) {
                    this.setState({ communityDataRem: 1 });
                  }
                },
                () => { }
              );
            } else {
              this.createCommunity();
              this.props.communitySelect("newCommunity");
            }
            break;
          case "existCommunity":
            if (
              selectedSuccess === "MANUAL" ||
              selectedSuccess === "EXCEL" ||
              this.state.excelData_Val === "SUCCESS"
            ) {
              confirmAlert(
                "warning",
                "Your changes will be lost. Are you sure you want to continue?",
                () => {
                  if (
                    selectedSuccess === "MANUAL" ||
                    selectedSuccess === "EXCEL" ||
                    this.state.excelData_Val === "SUCCESS"
                  ) {
                    this.setState({ communityDataRem: 2 });
                  }
                },
                () => { }
              );
            } else {
              this.createCommunity();
              this.props.communitySelect("existCommunity");
            }
            break;
          case "upload":
            if (
              selectedSuccess === "MANUAL" ||
              existCommunityData?.length > 0
            ) {
              confirmAlert(
                "warning",
                "Your changes will be lost. Are you sure you want to continue?",
                () => {
                  if (
                    selectedSuccess === "MANUAL" ||
                    existCommunityData?.length > 0
                  ) {
                    this.setState({ communityDataRem: 3 });
                  }
                },
                () => { }
              );
            } else {
              selectedSuccess === "MANUAL" &&
                this.setState({ selectedSuccess: "" });
              this.createCommunity();
              this.setState({ uploadExcel: true });
            }
            break;
          default:
            this.props.communitySelect("");
            break;
        }
      }
    );
    // }
  };

  changeModal = () => {
    const { check } = this.state;
    if (check.selectContact === "upload") {
      this.setState({ uploadExcel: true });
    }
    this.setState({
      changeModal: true,
      campaignModal: true,
      modalTitle: "Select Template",
      modalSize:'modal-xxl',
    });
  };

  handleAssign = (Option: any) => {
    if (this.state.check.assign === "bot") {
      this.setState({ selectedBot: Option });
    } 
    else if(this.state.check.assign === "team" ) {
      this.setState({ selectedTeam: Option });
    } 
    
    else {
      this.setState({ selectMember: Option });
    }
  };

  removeExcelData = () => {
    this.setState({
      selectedDataExcelCommunity: [],
      isChangeExcelStatus: false,
      exCommunityValid: "",
      excelData_Val: "",
    });
  };

  uploadExcelData = () => {
    this.setState({ excelData_Val: "SUCCESS" });
  };

  modalTitle = (title: string) => {
    this.setState({ modalTitle: title });
  };
  setModalSize =(v: string)=>{
    this.setState({ modalSize:v});
  }

  campaignTotal = (value: any) => {
    const numericValue = parseFloat(value.replace(/[^\d.-]/g, ""));
    return isNaN(numericValue) ? 0 * 1000 : numericValue * 1000;
  };

  handleRemoveAll = () => {
    const { removeExistData } = this.props;
    if (removeExistData) {
      removeExistData();
    }
  };


  render() {
    const {
      whatsappOpt,
      marketingOpt,
      roundRobin,
      campaignModal,
      templateId,
      check,
      selectMember,
      selectedBot,
      selectedTeam,
      selectedTeamMember,
      MemberName,
      uploadExcel,
      selectedTemplate,
      selectedDataManualsCommunity,
      selectedDataExcelCommunity,
      sendCampaignCount,
      excelData_Val,
      exCommunityValid,
      modalTitle,
      broadcastAmount,
      checkExistCommunity,
      repeatCampaign,
      isRepeatContact
    } = this.state;
console.log('sss',this.state)
    const numberMaskString = localStorage.getItem('numberMask');
    const numberMask = numberMaskString === 'true';    

    const profile = this.props;
    const {
      totalCredits,
      broadcastCost,
      broadcastAvailable,
      broadcastCountExceeds,
    } = broadcastAmount;
    const { existCommunityData } = this.props;
    const selectedContact = existCommunityData;
    const overallContactCount =
      existCommunityData?.length > 0 &&
      existCommunityData?.reduce((total, item: any) => {
        return total + (item.contactCount || 0);
      }, 0);

    const validationSchema = Yup.object().shape({
      selectPeriod: Yup.string().required("Select period is required"),
      campaign: Yup.string()
      .min(1, "Campaign name is required")
      .required("Campaign name is required"),
      excelData: Yup.string().required("Please upload excel file"),
      selectContact: Yup.string().required("Campaign audience is required"),
      ex_community_valid: Yup.string().required(
        "Atleast one community should be selected"
      ),

      templateId: Yup.string().required("Template is required"),
      assignMember: Yup.string().when("assign", {
        is: (value: any) => value === "member",
        then: Yup.string().required("Select Member"),
        otherwise: Yup.string(),
      }),
      assignBot: Yup.string().when("assign", {
        is: (value: any) => value && value === "bot",
        then: Yup.string().required("Select Bot"),
        otherwise: Yup.string(),
      }),
      assignTeam: Yup.string().when("assign", {
        is: (value: any) => value && value === "team",
        then: Yup.string().required("Select Team"),
        otherwise: Yup.string(),
      }),
    });

    // const memberData = MemberName;
    const data = this.props.selectedContact[0];
    const checkedData = this.props.callbackChecked.check;
    

    const selectedName = selectedDataManualsCommunity?.name;
    // const selectedContactLength =
    //   selectedDataManualsCommunity?.contacts?.length;
    const selectedContactLength = selectedDataManualsCommunity?.contactCount || 0;
    const { selectedDataExcel } = this.props;

    let excelCommunityName: string;
    let excelCommunityContact: number;

    if (selectedDataExcelCommunity) {
      excelCommunityName = selectedDataExcelCommunity?.name;
      excelCommunityContact = selectedDataExcelCommunity?.contactCount;
    }

    let allContacts;

    // if (existCommunityData?.length > 0) {
    // communityName = this.props.existCommunityData.map(
    //   (community: any) => community.name
    // );
    // const cumulativeSums = this.props.selectedContact.flatMap(
    //   (contactList: any) => contactList.contacts
    // );
    // if (cumulativeSums?.length > 0) {
    //   allContacts = cumulativeSums.map((contact: any) => contact);
    // }
    // }
    let assignData;
    let assignMemberData;
    if (selectMember) {
      assignData = selectMember.value;
    }
    if (selectedBot) assignData = selectedBot.value;

    if(selectedTeam){
      assignData=selectedTeam.value;
    }
    if(selectedTeamMember){
      assignMemberData=selectedTeamMember.value;
    }

    return (
      <div className="createCampain">
        <div className="campainHeader">
          <Button
            onClick={() => {
              this.props.onGoBack();
              this.handleRemoveAll();
            }}
            variant="link"
            style={{ outline: "none", color: "black" }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          {this.state.repeatCampaign ? "Repeat" : "Create"} Campaign
        </div>
        <Formik
          initialValues={{
            selectPeriod: checkedData?.selectPeriod,
            campaign: moment().format("DD MMM YYYY h:mmA"),
            assign: checkedData?.assign,
            selectContact: checkedData?.selectContact,
            templateId: templateId,
            assignMember: assignData || "",
            assignBot: assignData || "",
            assignTeam:assignData||"",
            assignTeamMember:assignMemberData||"",
            excelData: uploadExcel ? excelData_Val : false,
            ex_community_valid:
              uploadExcel && excelData_Val === "SUCCESS"
                ? exCommunityValid
                : false,
          }}
          validationSchema={validationSchema}
          enableReinitialize={true}
          innerRef={(formik: any) => (this.formik = formik)}
          onSubmit={this.handleSubmit}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form className="">
              <FormGroup className="MainCampaignContainer">
                <div className="row">
                  <div className="col-md-9">
                    <Row className="alertCon">
                      {this.props.channelData?.channelAllData?.currentLimit && (
                        <div className="limitMsg">
                          <div>
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </div>
                          <div>
                            {sendCampaignCount} Campaign messages sent in the
                            last 24 hours for a limit of{" "}
                            {this.campaignTotal(
                              this.props.channelData?.channelAllData
                                ?.currentLimit
                            )}{" "}
                            messages per day. You can send another{" "}
                            {this.campaignTotal(
                              this.props.channelData?.channelAllData
                                ?.currentLimit
                            ) - sendCampaignCount}{" "}
                            messages for now.
                          </div>
                        </div>
                      )}
                    </Row>
                    {!broadcastAvailable && !broadcastCountExceeds && (
                      <Row>
                        <Col>
                          <div className="amount_credits">
                            <Row>
                              <Col className="d-flex" md={10}>
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  stroke-width="0"
                                  viewBox="0 0 16 16"
                                  focusable="false"
                                  className="css-anu9qi me-2 ms-2"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                                </svg>
                                Your wallet balance is ₹ {totalCredits}. The
                                approximate cost of this campaign for{" "}
                                {selectedContactLength > 0 &&
                                  selectedContactLength}
                                {excelCommunityContact > 0 &&
                                  excelCommunityContact}
                                {selectedContact?.length > 0 &&
                                  allContacts?.length}{" "}
                                contacts will be ₹{" "}
                                {broadcastCost &&
                                  broadcastCost.toString().includes(".")
                                  ? broadcastCost.toFixed(3)
                                  : broadcastCost}
                                . Please recharge your wallet to initiate this
                                campaign
                              </Col>
                              <Col md={1}>
                                <Link
                                  to={`${process.env.PUBLIC_URL}/credit`}
                                  className=""
                                >
                                  <Button className="sendButton text-center">
                                    Recharge Now
                                  </Button>
                                </Link>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    )}
                    {broadcastCountExceeds && (
                      <Row>
                        <Col>
                          <div className="amount_credits">
                            <Row>
                              <Col className="d-flex" md={12}>
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  stroke-width="0"
                                  viewBox="0 0 16 16"
                                  focusable="false"
                                  className="css-anu9qi me-2 ms-2"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                                </svg>
                                The selected audience for the campaign exceeds
                                the maximum limit.
                              </Col>
                              {/* <Col md={1}>
                                <Link
                                  to={`${process.env.PUBLIC_URL}/credit`}
                                  className=""
                                >
                                  <Button className="sendButton text-center">
                                    Recharge Now
                                  </Button>
                                </Link>
                              </Col> */}
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    )}
                    <Row className="m-0">
                      <Col className="campaign">
                        <div className="campaignConent">
                          <div className="campaignTitle">
                            <span className="redstar_required">
                              {" "}
                              Campaign Name
                            </span>
                            <FontAwesomeIcon
                              icon={faCheck}
                              className={
                                check.campaign && check.selectPeriod
                                  ? "check"
                                  : "uncheck"
                              }
                              color="#fff"
                            />
                          </div>
                          <Field
                            type="text"
                            name="campaign"
                            id="campaign"
                            placeholder="Enter campaign name"
                            className={"form-control campianNameInput"}
                            onChange={(e: any) =>
                              this.handleCheck(e, setFieldValue)
                            }
                            value={check.campaign}
                          />
                          <ErrorMessage
                            name="campaign"
                            component="div"
                            className="text-danger mt-1"
                          />
                        </div>
                        <div className="radioContainer  ">
                          <Row className="pt-3">
                            {/* <div className="col-7 mt-2"> */}
                            {/* <ul className="mb-0 mt-0"> */}
                            {ScheduleDateOptions?.map((options) => (
                              // <li
                              //   key={options.value}
                              //   className="radioOption"
                              // >
                              <Col sm="" md="3">
                                <Label
                                  htmlFor={`selectPeriod-${options.value}`}
                                  className="d-flex align-items-center"
                                >
                                  <Field
                                    type="radio"
                                    id={`selectPeriod-${options.value}`}
                                    name="selectPeriod"
                                    value={options.value}
                                    onChange={(e: any) =>
                                      this.handleCheck(e, setFieldValue)
                                    }
                                    checked={
                                      check.selectPeriod === options.value
                                    }
                                  />
                                  <span className="selectPeriod ms-2">
                                    {options.label}
                                  </span>
                                </Label>
                              </Col>
                              // </li>
                            ))}
                            {/* </ul> */}
                            <ErrorMessage
                              name="selectPeriod"
                              component="div"
                              className="text-danger"
                            />
                            {/* </div> */}
                            <Col>
                              {/* <div className="date-time col-5"> */}
                              {check.selectPeriod === "date" ? (
                                <DateTime
                                  UtcMilliSeconds={this.UTC_milliseconds}
                                  UTCformat={
                                    this.props?.callbackChecked?.check?.selectPeriod !== 'immediately' ?
                                      this.props.callbackChecked.UTCformat : undefined
                                  }
                                />
                              ) : null}
                              {/* </div> */}
                            </Col>
                          </Row>
                          <div className="opt-in">
                            <div className="sendOpt">
                              Send message to contacts who opted-in for
                              marketing
                              <span className="radioSw">
                                <SwitchButton
                                  whatsappOptState={this.marketingOptState}
                                  checked={marketingOpt}
                                />
                              </span>
                            </div>
                            <p className="textGrayAlert">
                              When 'Opt-in' is on, messages are sent only to
                              those who agreed to marketing.
                            </p>
                            {!marketingOpt && (
                              <div className="warningText">
                                <div>
                                  <FontAwesomeIcon icon={faWarning} />
                                </div>
                                <div className="ml-2">
                                  Messaging opted-out contacts can lower your
                                  quality rating.
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="m-0">
                      <Col className="campaign">
                        <div className="campaignConent">
                          <div className="campaignTitle campaingNav">
                            <h4>
                              {this.state.repeatCampaign
                                ? "Selected Campaign Audience"
                                : "Select Campaign Audience"}{" "}
                              <span className="redstar_required"></span>
                            </h4>
                            <FontAwesomeIcon
                              icon={faCheck}
                              className={
                                selectedContact?.length > 0 ||
                                  selectedDataExcel.success === "EXCEL" ||
                                  this.props?.selectedDataManual.success ===
                                  "MANUAL" ||
                                  (this.state.repeatCampaign && checkExistCommunity?.length > 0)
                                  ? "check"
                                  : "uncheck"
                              }
                              color="#fff"
                            />
                          </div>
                          <div className="textGrayAlert">
                            Start your personalized campaign messaging by
                            choosing from these options:
                          </div>
                          <div className="radioContainer">
                            {/* <Label className="redioField mb-0"> */}
                            <Row className="redioField">
                              {SelectContact.map((options) => (
                                <Col
                                  key={options.value}
                                  id="community-data"
                                  className={`${this.state.repeatCampaign
                                    ? "disabled-data"
                                    : ""
                                    }`}
                                >
                                  <Label
                                    htmlFor={`selectContact-${options.value}`}
                                  >
                                    <Button
                                      type="button"
                                      id={`selectContact-${options.value}`}
                                      name="selectContact"
                                      className={
                                        ((selectedContact?.length > 0
                                          || checkExistCommunity[0]?.uploadMethod === "Group")
                                          &&
                                          options.value === "existCommunity") ||
                                          (uploadExcel &&
                                            options.value === "upload") ||
                                          ((selectedDataExcel.success ===
                                            "EXCEL"
                                            || checkExistCommunity[0]?.uploadMethod === "Excel")
                                            &&
                                            options.value === "upload") ||
                                          (checkedData?.selectContact ===
                                            "upload" &&
                                            options.value === "upload") ||
                                          (this.props?.excelContactList?.length >
                                            0 &&
                                            options.value === "upload")

                                          ? "checkExistCommunity"
                                          : selectedContactLength > 0 &&
                                            options.value === "newCommunity"
                                            ? "checkExistCommunity"
                                            : "selectContact"
                                      }
                                      value={options.value}
                                      onClick={(e: any) =>
                                        this.handleCheck(e, setFieldValue)
                                      }
                                      disabled={
                                        this.state.repeatCampaign || (numberMask && options.value === "upload") ? true : false
                                      }
                                    >
                                      {options.label}{" "}
                                      {options.value === "existCommunity" ? (
                                        <svg
                                          width="18"
                                          height="17"
                                          viewBox="0 0 18 17"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M5.875 3.625C5.875 2.7962 6.20424 2.00134 6.79029 1.41529C7.37634 0.82924 8.1712 0.5 9 0.5C9.8288 0.5 10.6237 0.82924 11.2097 1.41529C11.7958 2.00134 12.125 2.7962 12.125 3.625C12.125 4.4538 11.7958 5.24866 11.2097 5.83471C10.6237 6.42076 9.8288 6.75 9 6.75C8.1712 6.75 7.37634 6.42076 6.79029 5.83471C6.20424 5.24866 5.875 4.4538 5.875 3.625ZM12.125 6.125C12.125 5.46196 12.3884 4.82607 12.8572 4.35723C13.3261 3.88839 13.962 3.625 14.625 3.625C15.288 3.625 15.9239 3.88839 16.3928 4.35723C16.8616 4.82607 17.125 5.46196 17.125 6.125C17.125 6.78804 16.8616 7.42393 16.3928 7.89277C15.9239 8.36161 15.288 8.625 14.625 8.625C13.962 8.625 13.3261 8.36161 12.8572 7.89277C12.3884 7.42393 12.125 6.78804 12.125 6.125ZM0.875 6.125C0.875 5.46196 1.13839 4.82607 1.60723 4.35723C2.07607 3.88839 2.71196 3.625 3.375 3.625C4.03804 3.625 4.67393 3.88839 5.14277 4.35723C5.61161 4.82607 5.875 5.46196 5.875 6.125C5.875 6.78804 5.61161 7.42393 5.14277 7.89277C4.67393 8.36161 4.03804 8.625 3.375 8.625C2.71196 8.625 2.07607 8.36161 1.60723 7.89277C1.13839 7.42393 0.875 6.78804 0.875 6.125ZM4.25833 10.5975C4.76662 9.80085 5.46757 9.14521 6.29635 8.6912C7.12513 8.23719 8.05501 7.99946 9 8C9.79152 7.99928 10.5743 8.16573 11.297 8.48847C12.0197 8.8112 12.6661 9.28294 13.1939 9.87281C13.7217 10.4627 14.119 11.1573 14.3597 11.9114C14.6004 12.6654 14.6792 13.4618 14.5908 14.2483C14.58 14.3461 14.5463 14.4398 14.4925 14.5221C14.4386 14.6043 14.3661 14.6727 14.2808 14.7217C12.6738 15.6438 10.8528 16.1277 9 16.125C7.14718 16.1279 5.32611 15.644 3.71917 14.7217C3.63391 14.6727 3.5614 14.6043 3.50754 14.5221C3.45367 14.4398 3.41997 14.3461 3.40917 14.2483C3.26921 12.9705 3.56872 11.6831 4.25833 10.5983V10.5975Z"
                                            fill="#71839B"
                                          />
                                          <path
                                            d="M3.23507 9.87842C2.4132 11.1471 2.03028 12.6503 2.14507 14.1576C1.64466 14.0817 1.15249 13.959 0.675067 13.7909L0.579234 13.7576C0.49373 13.7272 0.41886 13.6727 0.363691 13.6007C0.308522 13.5287 0.275423 13.4422 0.268401 13.3518L0.260067 13.2509C0.226405 12.8326 0.277364 12.4117 0.409904 12.0135C0.542444 11.6152 0.753849 11.2478 1.0315 10.933C1.30916 10.6182 1.64737 10.3627 2.02597 10.1815C2.40456 10.0003 2.81577 9.89721 3.23507 9.87842ZM15.8551 14.1576C15.9699 12.6503 15.5869 11.1471 14.7651 9.87842C15.1844 9.89721 15.5956 10.0003 15.9742 10.1815C16.3528 10.3627 16.691 10.6182 16.9686 10.933C17.2463 11.2478 17.4577 11.6152 17.5902 12.0135C17.7228 12.4117 17.7737 12.8326 17.7401 13.2509L17.7317 13.3518C17.7246 13.4421 17.6914 13.5284 17.6362 13.6002C17.5811 13.6721 17.5063 13.7265 17.4209 13.7568L17.3251 13.7901C16.8526 13.9568 16.3617 14.0809 15.8551 14.1576Z"
                                            fill="#71839B"
                                          />
                                        </svg>
                                      ) : options.value === "upload" ? (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                        >
                                          <path
                                            d="M12.2384 9.59375L5.32031 8.375V17.3806C5.32039 17.4785 5.33975 17.5753 5.37726 17.6657C5.41478 17.756 5.46972 17.8381 5.53896 17.9072C5.6082 17.9764 5.69037 18.0312 5.78079 18.0685C5.87121 18.1059 5.9681 18.1251 6.06594 18.125H18.0034C18.1014 18.1252 18.1984 18.1062 18.289 18.0689C18.3795 18.0316 18.4619 17.9768 18.5312 17.9077C18.6006 17.8385 18.6557 17.7564 18.6932 17.666C18.7308 17.5755 18.7502 17.4786 18.7503 17.3806V14.0625L12.2384 9.59375Z"
                                            fill="#185C37"
                                          />
                                          <path
                                            d="M12.2384 1.875H6.06594C5.9681 1.87492 5.87121 1.89411 5.78079 1.93147C5.69037 1.96883 5.6082 2.02364 5.53896 2.09276C5.46972 2.16189 5.41478 2.24397 5.37726 2.33432C5.33975 2.42468 5.32039 2.52154 5.32031 2.61938V5.9375L12.2384 10L15.9016 11.2188L18.7503 10V5.9375L12.2384 1.875Z"
                                            fill="#21A366"
                                          />
                                          <path
                                            d="M5.32031 5.9375H12.2384V10H5.32031V5.9375Z"
                                            fill="#107C41"
                                          />
                                          <path
                                            opacity="0.1"
                                            d="M10.2716 5.125H5.32031V15.2812H10.2716C10.4689 15.2803 10.6578 15.2016 10.7975 15.0623C10.9372 14.9229 11.0163 14.7342 11.0178 14.5369V5.86937C11.0163 5.67209 10.9372 5.48333 10.7975 5.34399C10.6578 5.20466 10.4689 5.12598 10.2716 5.125Z"
                                            fill="#71839B"
                                          />
                                          <path
                                            opacity="0.2"
                                            d="M9.86469 5.53125H5.32031V15.6875H9.86469C10.062 15.6865 10.2509 15.6078 10.3906 15.4685C10.5303 15.3292 10.6095 15.1404 10.6109 14.9431V6.27562C10.6095 6.07834 10.5303 5.88958 10.3906 5.75024C10.2509 5.61091 10.062 5.53223 9.86469 5.53125Z"
                                            fill="#71839B"
                                          />
                                          <path
                                            opacity="0.2"
                                            d="M9.86469 5.53125H5.32031V14.875H9.86469C10.062 14.874 10.2509 14.7953 10.3906 14.656C10.5303 14.5167 10.6095 14.3279 10.6109 14.1306V6.27562C10.6095 6.07834 10.5303 5.88958 10.3906 5.75024C10.2509 5.61091 10.062 5.53223 9.86469 5.53125Z"
                                            fill="#71839B"
                                          />
                                          <path
                                            opacity="0.2"
                                            d="M9.45781 5.53125H5.32031V14.875H9.45781C9.6551 14.874 9.84406 14.7953 9.98375 14.656C10.1234 14.5167 10.2026 14.3279 10.2041 14.1306V6.27562C10.2026 6.07834 10.1234 5.88958 9.98375 5.75024C9.84406 5.61091 9.6551 5.53223 9.45781 5.53125Z"
                                            fill="#71839B"
                                          />
                                          <path
                                            d="M1.99625 5.53125H9.4575C9.65514 5.53109 9.84477 5.6094 9.9847 5.74898C10.1246 5.88856 10.2034 6.07798 10.2038 6.27563V13.7244C10.2034 13.922 10.1246 14.1114 9.9847 14.251C9.84477 14.3906 9.65514 14.4689 9.4575 14.4688H1.99625C1.89836 14.4689 1.8014 14.4498 1.71091 14.4125C1.62042 14.3751 1.53817 14.3203 1.46887 14.2512C1.39956 14.1821 1.34456 14.1 1.30701 14.0096C1.26945 13.9192 1.25008 13.8223 1.25 13.7244V6.27563C1.25008 6.17774 1.26945 6.08083 1.30701 5.99043C1.34456 5.90003 1.39956 5.81792 1.46887 5.74879C1.53817 5.67966 1.62042 5.62487 1.71091 5.58754C1.8014 5.55021 1.89836 5.53109 1.99625 5.53125Z"
                                            fill="url(#paint0_linear_3104_13894)"
                                          />
                                          <path
                                            d="M3.5625 12.4204L5.13188 9.99285L3.69437 7.5791H4.84875L5.63312 9.12473C5.70562 9.27098 5.75813 9.37973 5.78188 9.45223H5.7925C5.84375 9.33473 5.89812 9.2216 5.955 9.11098L6.79375 7.5816H7.85625L6.38188 9.9816L7.89375 12.4222H6.76313L5.85688 10.7279C5.8148 10.655 5.77904 10.5787 5.75 10.4997H5.735C5.70857 10.5767 5.67334 10.6503 5.63 10.7191L4.69688 12.4204H3.5625Z"
                                            fill="white"
                                          />
                                          <path
                                            d="M18.0039 1.875H12.2383V5.9375H18.7502V2.61938C18.7501 2.52149 18.7307 2.42458 18.6931 2.33418C18.6556 2.24378 18.6006 2.16167 18.5313 2.09254C18.462 2.02341 18.3797 1.96862 18.2892 1.93129C18.1988 1.89396 18.1018 1.87484 18.0039 1.875Z"
                                            fill="#33C481"
                                          />
                                          <path
                                            d="M12.2383 10H18.7502V14.0625H12.2383V10Z"
                                            fill="#107C41"
                                          />
                                          <defs>
                                            <linearGradient
                                              id="paint0_linear_3104_13894"
                                              x1="2.80875"
                                              y1="4.94625"
                                              x2="8.645"
                                              y2="15.0538"
                                              gradientUnits="userSpaceOnUse"
                                            >
                                              <stop stop-color="#18884F" />
                                              <stop
                                                offset="0.5"
                                                stop-color="#117E43"
                                              />
                                              <stop
                                                offset="1"
                                                stop-color="#0B6631"
                                              />
                                            </linearGradient>
                                          </defs>
                                        </svg>
                                      ) : (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                        >
                                          <path
                                            d="M6.75 9C7.61195 9 8.4386 8.65759 9.0481 8.0481C9.65759 7.4386 10 6.61195 10 5.75C10 4.88805 9.65759 4.0614 9.0481 3.4519C8.4386 2.84241 7.61195 2.5 6.75 2.5C5.88805 2.5 5.0614 2.84241 4.4519 3.4519C3.84241 4.0614 3.5 4.88805 3.5 5.75C3.5 6.61195 3.84241 7.4386 4.4519 8.0481C5.0614 8.65759 5.88805 9 6.75 9ZM17 6.5C17 7.16304 16.7366 7.79893 16.2678 8.26777C15.7989 8.73661 15.163 9 14.5 9C13.837 9 13.2011 8.73661 12.7322 8.26777C12.2634 7.79893 12 7.16304 12 6.5C12 5.83696 12.2634 5.20107 12.7322 4.73223C13.2011 4.26339 13.837 4 14.5 4C15.163 4 15.7989 4.26339 16.2678 4.73223C16.7366 5.20107 17 5.83696 17 6.5ZM9 14.5C9 12.796 9.775 11.272 10.993 10.263C10.6907 10.09 10.3483 9.9993 10 10H3.5C2.96957 10 2.46086 10.2107 2.08579 10.5858C1.71071 10.9609 1.5 11.4696 1.5 12C1.5 12 1.5 16 6.75 16C7.703 16 8.483 15.868 9.121 15.653C9.04032 15.274 8.99976 14.8875 9 14.5ZM19 14.5C19 15.6935 18.5259 16.8381 17.682 17.682C16.8381 18.5259 15.6935 19 14.5 19C13.3065 19 12.1619 18.5259 11.318 17.682C10.4741 16.8381 10 15.6935 10 14.5C10 13.3065 10.4741 12.1619 11.318 11.318C12.1619 10.4741 13.3065 10 14.5 10C15.6935 10 16.8381 10.4741 17.682 11.318C18.5259 12.1619 19 13.3065 19 14.5ZM15 12.5C15 12.3674 14.9473 12.2402 14.8536 12.1464C14.7598 12.0527 14.6326 12 14.5 12C14.3674 12 14.2402 12.0527 14.1464 12.1464C14.0527 12.2402 14 12.3674 14 12.5V14H12.5C12.3674 14 12.2402 14.0527 12.1464 14.1464C12.0527 14.2402 12 14.3674 12 14.5C12 14.6326 12.0527 14.7598 12.1464 14.8536C12.2402 14.9473 12.3674 15 12.5 15H14V16.5C14 16.6326 14.0527 16.7598 14.1464 16.8536C14.2402 16.9473 14.3674 17 14.5 17C14.6326 17 14.7598 16.9473 14.8536 16.8536C14.9473 16.7598 15 16.6326 15 16.5V15H16.5C16.6326 15 16.7598 14.9473 16.8536 14.8536C16.9473 14.7598 17 14.6326 17 14.5C17 14.3674 16.9473 14.2402 16.8536 14.1464C16.7598 14.0527 16.6326 14 16.5 14H15V12.5Z"
                                            fill="#71839B"
                                          />
                                        </svg>
                                      )}
                                    </Button>
                                  </Label>
                                </Col>
                              ))}
                            </Row>
                            {/* </Label> */}
                            {((excelCommunityContact > 0) || (repeatCampaign && checkExistCommunity[0]?.uploadMethod === 'Excel')) && (
                              <>
                                <Label>
                                  <TooltipReference
                                    placement="bottom"
                                    icon={faInfoCircle}
                                    content={repeatCampaign ? checkExistCommunity[0]?.name : excelCommunityName}
                                    tooltipId="tempName"
                                  ></TooltipReference>
                                  Community Created with {repeatCampaign ? checkExistCommunity[0]?.contactCount : excelCommunityContact}{" "}
                                  Contacts Selected{" "}
                                </Label>
                                {/* <Button
                                  className="ms-2"
                                  onClick={() => {
                                    this.setState({
                                      isChangeExcelStatus:
                                        !this.state.isChangeExcelStatus,
                                    });
                                  }}
                                  style={{ background: "none" }}
                                >
                                  {" "}
                                  <i
                                    className="fas fa-exchange-alt delIcon"
                                    aria-hidden="true"
                                  ></i>
                                </Button> */}
                              </>
                            )}

                            {selectedContactLength > 0 && (
                              <Label>
                                <TooltipReference
                                  placement="bottom"
                                  icon={faInfoCircle}
                                  content={`${selectedName}`}
                                  tooltipId="tempName"
                                ></TooltipReference>
                                Community Created with {selectedContactLength}{" "}
                                Contacts Selected{" "}
                              </Label>
                            )}

                            {((existCommunityData &&
                              selectedContact?.length > 0) || (repeatCampaign && checkExistCommunity[0]?.uploadMethod === 'Group')) && (
                                <Label>
                                  <TooltipReference
                                    placement="bottom"
                                    icon={faInfoCircle}
                                    listView={repeatCampaign ? checkExistCommunity : existCommunityData}
                                    tooltipId="exist-community"
                                  ></TooltipReference>
                                  Community Created with {
                                    repeatCampaign ?
                                      checkExistCommunity?.reduce((total: any, item: any) => {
                                        return total + (item.contactCount || 0);
                                      }, 0)
                                      :
                                      overallContactCount}{" "}
                                  Contacts Selected
                                </Label>
                              )}

                            <div>
                              {(uploadExcel &&
                                selectedDataExcelCommunity?.length === 0) ||
                                this.state.isChangeExcelStatus ||
                                this.props?.excelContactList?.length > 0 ? (
                                <>
                                  <div className="whatsappOpt">
                                    <div>
                                      <img
                                        src="/images/whatsapp-icon.svg"
                                        width={"20px"}
                                      />{" "}
                                      WhatsApp Opt-In
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip>
                                            Opt-in will be automatically enabled
                                            when uploading new contacts.
                                          </Tooltip>
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faInfoCircle}
                                          color="#474546"
                                          size="1x"
                                          className="ml-1"
                                          data-bs-placement="top"
                                          data-bs-toggle="tooltip"
                                        />
                                      </OverlayTrigger>
                                    </div>
                                    <div>
                                      <SwitchButton
                                        whatsappOptState={this.whatsappOptState}
                                        checked={whatsappOpt}
                                      />
                                    </div>
                                  </div>
                                  
                                  <UploadContactExcel
                                    isChangeExcelStatus={
                                      this.state.isChangeExcelStatus
                                    }
                                    getExcelData={this.props.excelData}
                                    removeExcelData={this.removeExcelData}
                                    excelFileName={this.props.excelFileName}
                                    communitySelect={this.props.communitySelect}
                                    selectedDataExcelCommunity={
                                      this.state.selectedDataExcelCommunity
                                    }
                                    excelContactList={
                                      this.props.excelContactList
                                    }
                                    uploadExcelData={this.uploadExcelData}
                                  // check={check}
                                  // checkedData={this.props.checkedData}
                                  />
                                </>
                              ) : null}
                            </div>
                          </div>
                          <ErrorMessage
                            name="selectContact"
                            component="div"
                            className="text-danger"
                          />
                          <ErrorMessage
                            name="excelData"
                            component="div"
                            className="text-danger  mt-1"
                          />
                          <ErrorMessage
                            name="ex_community_valid"
                            component="div"
                            className="text-danger mt-2"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="m-0">
                      <Col className="campaign">
                        <div className="campaignConent">
                          <div className="campaignTitle campaingNav2">
                            <h4>
                              Reply Settings{" "}
                              <span className="Optional">( Optional )</span>{" "}
                              {/* <span className="redstar_required"></span>{" "} */}
                            </h4>
                            <FontAwesomeIcon
                              icon={faCheck}
                              className={check.assign ? "check" : "uncheck"}
                              color="#fff"
                            />
                          </div>
                          <div className="textGrayAlert">
                            Reply settings are triggered when the user clicks
                            the button or swipes right to respond.
                          </div>
                          <div className="radioContainer">
                            <Row className="botNav">
                              <Col className="col-8">
                                <Label className="row">
                                  {AssignOptions.map((assign) => (
                                    <li
                                      key={assign.value}
                                      className="radioOption col-4"
                                    >
                                      <label htmlFor={`assign-${assign.value}`}>
                                        <Field
                                          type="radio"
                                          id={`assign-${assign.value}`}
                                          name="assign"
                                          onChange={(e: any) =>
                                            this.handleCheck(e, setFieldValue)
                                            
                                          }
                                          checked={
                                            check.assign === assign.value
                                          }
                                          value={assign.value}
                                        />
                                        <span className="assign ms-2">
                                          {assign.label}
                                        </span>
                                      </label>
                                    </li>
                                  ))}
                                </Label>
                  
                              </Col>
                              <Col>
                                {check.assign === "member"  ? (
                                  <Field name="assignMember">
                                    {({ field, form }: any) => (
                                      <Select
                                        {...field}
                                        name="assignMember"
                                        options={
                                          MemberName &&
                                          MemberName?.map((member: any) => ({
                                            value: member?.name,
                                            label: member?.name,
                                            uid: member?.uid,
                                            id: member?.id,
                                          }))
                                        }
                                        placeholder="Select Member"
                                        className="memberData"
                                        // value={MemberName.find((options:any) => options.value === values.assignMember)}
                                        value={selectMember}
                                        onChange={(selectedOption: any) => {
                                          this.handleAssign(selectedOption);
                                          setFieldValue(
                                            "assignMember",
                                            selectedOption?.value
                                          );
                                        }}
                                        styles={customStyles}
                                        menuPlacement="top"
                                      />
                                    )}
                                  </Field>
                                ) : null}
                                 {check.assign === "team" ? (
                                  <Field name="assignTeam">
                                    {({ field, form }: any) => (
                                      <Select
                                        {...field}
                                        name="assignTeam"
                                        options={
                                          this.state.allTeam &&
                                          this.state.allTeam?.map(
                                            (member: any) => ({
                                              value: member?.name,
                                              label: member?.name,
                                              uid: member?.uid,
                                              id: member?.id,
                                            })
                                          )
                                        }
                                        placeholder="Select Team"
                                        className="memberData"
                                        // value={MemberName.find((options:any) => options.value === values.assignMember)}
                                        value={selectedTeam}
                                        onChange={(selectedOption: any) => {
                                          this.handleAssign(selectedOption);
                                          // this.getTeamMember();
                                          setFieldValue(
                                            "assignTeam",
                                            selectedOption?.value
                                          );
                                        }}
                                        styles={customStyles}
                                        menuPlacement="top"
                                      />
                                    )}
                                  </Field>
                                ) : null}
                              
                             
                                {check.assign === "bot" ? (
                                  <Field name="assignBot">
                                    {({ field, form }: any) => (
                                      <Select
                                        {...field}
                                        name="assignBot"
                                        options={
                                          this.state.allBots &&
                                          this.state.allBots?.map(
                                            (member: any) => ({
                                              value: member?.name,
                                              label: member?.name,
                                              uid: member?.uid,
                                              id: member?.id,
                                            })
                                          )
                                        }
                                        placeholder="Select Bot"
                                        className="memberData"
                                        // value={MemberName.find((options:any) => options.value === values.assignMember)}
                                        value={selectedBot}
                                        onChange={(selectedOption: any) => {
                                          this.handleAssign(selectedOption);
                                          setFieldValue(
                                            "assignBot",
                                            selectedOption?.value
                                          );
                                        }}
                                        styles={customStyles}
                                        menuPlacement="top"
                                      />
                                    )}
                                  </Field>
                                ) : null}
                                {values.assign === "member" ? (
  <ErrorMessage
    name="assignMember"
    component="div"
    className="text-danger"
  />
) : values.assign === "bot" ? (
  <ErrorMessage
    name="assignBot"
    component="div"
    className="text-danger"
  />
) : values.assign==="team" && (
  <ErrorMessage
    name="assignTeam"
    component="div"
    className="text-danger"
  />
)}

                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="col-md-3">
                    <Row>
                      <Col>
                        {templateId ? (
                          <>
                            {/* <p className="templateName">
                              {selectedTemplate.name}
                            </p> */}
                            {this.state.repeatCampaign ? (
                              ""
                            ) : (
                              <>
                                <div className="text-center">
                                  <Button
                                    type="button"
                                    className="sendButton mb-2 w-75"
                                    onClick={this.changeModal}
                                    disabled={
                                      this.state.repeatCampaign ? true : false
                                    }
                                  >
                                    Change Template
                                  </Button>
                                </div>
                              </>
                            )}
                            <PreviewWhatsapp
                              templateId={this.state.templateId}
                              campaign={this.state.check.campaign}
                              selectedTemplate={this.selectedTemplate}
                              templateVariables={this.state.tempVariables}
                              callbackTemplate={this.props?.callbackChecked}
                            />
                            {this.state.changeModal && (
                              <Modall
                                isOpen={this.state.changeModal}
                                onClose={this.handleClose}
                                title={modalTitle}
                                size={this.state.modalSize}
                              >
                                <TemplateList
                                  templateModal={campaignModal}
                                  component="CAMPAIGN"
                                  tempIdCollections={this.tempIdCollections}
                                  templateVariables={this.templateVariables}
                                  handleClose={this.handleClose}
                                  modalTitle={this.modalTitle}
                                  setModalSize={this.setModalSize}
                                // setTemplateId={(templateId: string) => ''}
                                // callbackChecked = {this.props.callbackChecked}
                                />
                              </Modall>
                            )}
                          </>
                        ) : (
                          <>
                            <h6 className="mt-2">
                              Select Template{" "}
                              <span className="redstar_required"></span>{" "}
                            </h6>
                            <div className="previewTemplate mt-2">
                              <div className="mb-2">
                                {" "}
                                Preview is available once you Select the
                                template
                              </div>
                              <Button
                                className="mt-4 sendButton"
                                name="templateId"
                                onClick={this.handleShow}
                              >
                                Select Template
                              </Button>
                              {this.state.openModal && (
                                <Modall
                                  isOpen={this.state.openModal}
                                  onClose={this.handleClose}
                                  title={modalTitle}
                                  // size="modal-xxl"
                                size={this.state.modalSize}
                                >
                                  <TemplateList
                                    uploadExcel={uploadExcel}
                                    templateModal={campaignModal}
                                    component="CAMPAIGN"
                                    tempIdCollections={this.tempIdCollections}
                                    templateVariables={this.templateVariables}
                                    handleClose={this.handleClose}
                                    modalTitle={this.modalTitle}
                                  setModalSize={this.setModalSize}

                                  //  setTemplateId={(templateId: string) => ''}
                                  // callbackChecked = {this.props.callbackChecked}
                                  />
                                </Modall>
                              )}
                            </div>
                            <ErrorMessage
                              name="templateId"
                              component="div"
                              className="text-danger text-center mt-1"
                            />
                          </>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="sendCampaign col-9">
                  <Button
                    className="cancelButton me-2 ps-4 pe-4"
                    onClick={() => {
                      this.props.onGoBack();
                      this.handleRemoveAll();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className="sendButton"
                    disabled={
                      isSubmitting ||
                      profile?.profile?.business?.freeTrial ||
                      !broadcastAvailable ||
                      broadcastCountExceeds
                    }
                  >
                    Send Campaign
                  </Button>
                </div>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    memberData: state.cartreducer.memberData,
    channelData: state.cartreducer.channelUid,
    profile: state.cartreducer.business,
    business: state.cartreducer.business,
    existCommunityData: state.cartreducer.existCommunity,
    // businessUid: state.cartreducer.business?.business?.uid
  };
};
const mapDispatchToProps = {
  removeExistData,
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultCampaign);

import React, { useState, useEffect, useContext } from "react";
import { previewTemplate } from "../../services/campaignService";
import { useSelector } from "react-redux";
import { useData } from "../../contexts/tempContext";
import campaignContext from "./campaignContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faExternalLinkAlt, faReply } from "@fortawesome/free-solid-svg-icons";
import phone from "../../assets/img/phonesymbol.png";
import { FaRegCopy } from "react-icons/fa";


export interface PreviewWhatsappProps {
  templateId?: string;
  campaign?: string;
  selectedTemplate: any;
  templateVariables: any;
  callbackTemplate?: any;
}

/**
This function replaces the example variable
value with the values that need to be rendered
**/
export const processTemplateVariable = (
  temp: any,
  variables: any[],
  data: any
) => {
  if (variables.length > 0) {
    const value = variables
      .filter((item: any) => item.isButton === false)
      .map((item: any) => {
        return item.value;
      });
     const buttonVariable = variables
       .filter((item:any) => item.isButton === true)
       .map((item:any) => item.value);
           console.log("var", variables,value);

     if (
       temp?.components?.[temp.components.length - 1]?.buttons
     ) {
       const buttons =
         temp.components[temp.components.length - 1].buttons;
       if (
         buttons[0]?.type === "URL" ||
         buttons[1]?.type === "URL" ||
         buttons[2]?.type === "URL"
       ) {
         if (
           buttons[0]?.type === "URL" &&
           temp.buttonColumns?.includes("URL1") &&
           !temp.buttonColumns?.includes("PHONE_NUMBER")
         ) {
           buttons[0].example = [
             buttons[1]?.type === "URL" ? buttonVariable[1] : buttonVariable[0],
           ];
           console.log("Updated variable at index 0:", buttons[0].example);
         }
         if (
           (buttons[1]?.type === "URL" &&
             temp?.buttonColumns?.includes("URL1")) ||
           temp.buttonColumns?.includes("URL2")
         ) {
           buttons[1].example = [
             buttons[0]?.type === "URL" && buttons[1]?.type === "URL"
               ? buttonVariable[1]
               : buttonVariable[0],
           ];
           console.log("Updated variable at index 1:", buttons[1].example);
         }

         if (
           buttons[2]?.type === "URL" &&
           temp?.buttonColumns?.includes("URL2")
         ) {
           buttons[2].example = [
             buttons[1]?.type === "URL" && buttons[2]?.type === "URL"
               ? buttonVariable[1]
               : buttonVariable[0],
           ];
           console.log("Updated variable at index 2:", buttons[2].example);
         }
       } else {
         console.log("No URL buttons available at indices 0, 1, or 2");
       }
     } else {
       console.log("No buttons available");
     }
      if (temp.components[0].format) {
if (
  temp.components[1].example &&
  temp.components[1].example.body_text
) {
  temp.components[1].example.body_text = value;
}    } else {
if (
  temp.components[0].example &&
  temp.components[0].example.body_text
) {
  temp.components[0].example.body_text = value;
}    }
  
    if (
      (temp.components[0].format === "IMAGE" ||
        temp.components[0].format === "VIDEO") &&
      data.components &&
      "example" in data.components &&
      "header_handle" in data.components.example
    ) {
      temp.components[0].example.header_handle =
        data.components[0].example.header_handle;
    }
  }
};

function PreviewWhatsapp(props: PreviewWhatsappProps) {
  const [templateData, setTemplateData] = useState<any[]>([]);
  const { data, setData } = useData();
  const campaignCtx = useContext(campaignContext);
  console.log('data',data.components)

  useEffect(() => {
    if (props.templateId) { 
      loadTemplateData();

    }
  }, [props.templateId, props.templateVariables]);

  const renderMessage = (values: any, text: string) => {
    let renderedText = text;
    if (values?.example?.body_text?.length) {
      const variableNames = extractVariableName(text);
      variableNames.forEach((variableName, index) => {
        const replacement = `<span class="sampleVarColor">${values.example.body_text[index]}</span>`;
        renderedText = renderedText.replace(`{{${variableName}}}`, replacement);
      });
    }
    else if (props?.templateVariables?.length  > 0) {
      const variableNames = extractVariableName(text);
      variableNames.forEach((variableName, index) => {
        const replacement = `<span class="sampleVarColor">${props?.templateVariables[index].bodyText}</span>`;
        renderedText = renderedText.replace(`{{${variableName}}}`, replacement);
      });
    }
    return <div dangerouslySetInnerHTML={{ __html: renderedText }} />;
  };

  const businessId = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );
  const channelData = useSelector((state: any) => state.cartreducer.channelUid);
  const channelId = channelData?.value;

  const loadTemplateData = async () => {
    // For repeat campaign
    if (campaignCtx.repeatCampaign) {
      const templateData = campaignCtx.repeatCampaign.selectedTemplate;
      setData(templateData);
      processTemplateVariable(
        templateData,
        props.templateVariables,
        templateData
      );
      setTemplateData(templateData.components);
      
    }
    // For create campaign
    else {
      try {
        if (businessId) {
          const responseData = await previewTemplate(
            props.templateId,
            channelId
          );          
          if (props.templateVariables.length > 0) {
            processTemplateVariable(
              responseData,
              props.templateVariables,
              data
            );
            if (data) {
              setTemplateData(data.components);
              props.selectedTemplate(data);
            } else {
              setTemplateData(responseData.components);
              props.selectedTemplate(responseData);
            }
          } else {
            if (data) {
              setTemplateData(data.components);
              props.selectedTemplate(data);
            } else {
              setTemplateData(responseData.components);
              props.selectedTemplate(responseData);
            }
          }
        }
      } catch (error) {
        console.error("Error loading template data:", error);
      }
    }
  };
console.log('campaignCtx.repeatCampaign')
  const extractVariableName = (text: string) => {
    const matches = text?.match(/{{(.*?)}}/g);
    if (matches) {
      return matches?.map((match) => match.replace(/[{}]/g, ""));
    }
    return [];
  };

  const [scrollY, setScrollY] = useState(0);
  const scrollThreshold = 150;

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isFixed = scrollY >= scrollThreshold;
// console.log("campaign", data)
  return (
    <div className="ps-3">
      <div className="preview-image">
        <div className="channelName">
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2884_12148)">
                <path
                  d="M0.423595 9.53519C0.42313 11.2156 0.8622 12.8564 1.69708 14.3026L0.34375 19.2438L5.40049 17.9179C6.79912 18.6793 8.36618 19.0783 9.95863 19.0784H9.96282C15.2198 19.0784 19.4991 14.8006 19.5013 9.54271C19.5024 6.99488 18.511 4.59907 16.71 2.79659C14.9092 0.994264 12.5142 0.00116279 9.96243 0C4.70484 0 0.425843 4.27752 0.423672 9.53519"
                  fill="url(#paint0_linear_2884_12148)"
                />
                <path
                  d="M0.0829457 9.87721C0.0824031 11.6181 0.537209 13.3175 1.40186 14.8155L0 19.9339L5.23806 18.5605C6.68132 19.3474 8.30628 19.7622 9.95977 19.7629H9.96403C15.4096 19.7629 19.8426 15.3312 19.845 9.88512C19.8459 7.24574 18.8189 4.7638 16.9535 2.89674C15.0878 1.02992 12.6072 0.00108527 9.96403 0C4.51752 0 0.0851163 4.43101 0.0829457 9.87721ZM3.20233 14.5575L3.00674 14.2471C2.18457 12.9398 1.75062 11.4291 1.75124 9.87783C1.75302 5.35109 5.43705 1.66822 9.96713 1.66822C12.1609 1.66915 14.2226 2.52434 15.7733 4.07597C17.324 5.62775 18.1772 7.69054 18.1767 9.8845C18.1747 14.4112 14.4905 18.0946 9.96403 18.0946H9.96078C8.4869 18.0938 7.0414 17.698 5.78078 16.95L5.48078 16.7721L2.3724 17.5871L3.20233 14.5574V14.5575Z"
                  fill="url(#paint1_linear_2884_12148)"
                />
                <path
                  d="M7.49452 5.74801C7.30956 5.33692 7.11491 5.32863 6.93902 5.32142C6.79499 5.31522 6.63034 5.31568 6.46584 5.31568C6.30119 5.31568 6.03367 5.37762 5.80755 5.62452C5.58119 5.87165 4.94336 6.46886 4.94336 7.68351C4.94336 8.89824 5.82809 10.0721 5.95142 10.237C6.07491 10.4016 7.65941 12.974 10.1689 13.9636C12.2544 14.786 12.6789 14.6224 13.1315 14.5812C13.5842 14.5401 14.5923 13.9841 14.7979 13.4076C15.0037 12.8312 15.0037 12.3371 14.942 12.2338C14.8803 12.131 14.7157 12.0692 14.4688 11.9458C14.2218 11.8223 13.008 11.225 12.7817 11.1426C12.5554 11.0603 12.3908 11.0192 12.2262 11.2664C12.0615 11.5132 11.5887 12.0692 11.4446 12.2338C11.3006 12.3989 11.1565 12.4194 10.9097 12.2959C10.6627 12.172 9.86755 11.9117 8.92421 11.0706C8.19026 10.4162 7.69475 9.60808 7.55072 9.36088C7.40669 9.11405 7.5353 8.98026 7.6591 8.85723C7.77003 8.74661 7.90607 8.56894 8.02964 8.42483C8.15274 8.28064 8.19382 8.17777 8.27615 8.01312C8.35855 7.84832 8.31731 7.70413 8.25568 7.58064C8.19382 7.45715 7.71406 6.23615 7.49452 5.74801Z"
                  fill="white"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_2884_12148"
                  x1="958.224"
                  y1="1924.38"
                  x2="958.224"
                  y2="0"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#1FAF38" />
                  <stop offset="1" stop-color="#60D669" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_2884_12148"
                  x1="992.248"
                  y1="1993.39"
                  x2="992.248"
                  y2="0"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F9F9F9" />
                  <stop offset="1" stop-color="white" />
                </linearGradient>
                <clipPath id="clip0_2884_12148">
                  <rect width="19.845" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="ms-3">{channelData.label}</div>
        </div>
        <div className="layoutFortemp">
          {data?.isCatalog && data?.buttonColumns &&(
            <>
             <img
              src={data?.buttonColumns[0]}
              alt="catalog_image"
              height={125}
              width={215}/>
            </>
          )}
          {data.components &&
            data.components.map((template: any, index: number) => (
              <React.Fragment key={index}>
                {template.format === "IMAGE" && (
                  <div className="fontWeight">
                    {template?.example?.header_handle[0] && (
                      <img
                        key={template.id}
                        src={template?.example?.header_handle[0]}
                        alt={template.id}
                        height={125}
                        width={215}
                      />
                    )}
                  </div>
                )}
                {template.format === "VIDEO" && (
                  <div className="fontWeight">
                    {template?.example?.header_handle[0] && (
                      <video
                        key={template.id}
                        controls
                        src={template?.example?.header_handle[0]}
                        height={125}
                        width={215}
                      />
                    )}
                  </div>
                )}
                {template.format === "DOCUMENT" && (
                  <div className="fontWeight">
                    {template?.example?.header_handle[0] && (
                      <iframe
                        src={template?.example?.header_handle[0]}
                        height={125}
                        width={215}
                      />
                    )}
                  </div>
                )}
         { 
            template?.type === "BUTTONS" &&
            Array.isArray(template.buttons) && template.buttons.length > 0 &&
            template.buttons[0]?.type?.toUpperCase() === "OTP" &&
            template.buttons[0]?.otp_type?.toUpperCase() === "COPY_CODE"&& (() => {
              
              const security = template.type === 'BODY' && template.add_security_recommendation;
              console.log('SEQUpr',security,template)
              const templateVariables = campaignCtx?.repeatCampaign?.templateVariables;
              const bodyTexts = (templateVariables && templateVariables.length > 0)
                ?  templateVariables.map((item:any)=>item.bodyText)
                : template.buttons[0]?.example;
              
              const message = security 
                ? `${bodyTexts} is your verification code.` 
                : `${bodyTexts} is your verification code. For your security, do not share this code.`;
              
              return (
                <div key={template.id} className="pt-2">
                  {renderMessage(template, message)}
                </div>
              );
            })()
          }

       
                <div key={template.id} className="pt-2">
                  {renderMessage(template, template.text)}
                </div>
                
              </React.Fragment>
            ))}
        </div>
                        
        {((data?.components  && data?.components[1]?.type === 'CAROUSEL') || (data?.components && data?.components[0]?.type === 'CAROUSEL')) && data ?
          <div className={`carouselTemps ${isFixed ? "fixedlayout" : ""}`}>
            <div className="d-flex">
              {(data?.components[1].cards || data?.components[0].cards)?.map((e: any, ind: number) => (
                <div key={ind} className="templateRow">
                  <div className="">
                    <span>
                      {e?.components[0]?.format === 'IMAGE' ?
                        <img src={e?.components[0]?.example?.header_handle[0]} className="" width={170} height={90} alt="Header" /> :
                        e?.components[0]?.format === 'VIDEO' ? <video src={e?.components[0]?.example?.header_handle[0]}
                          controls
                          width={170}
                          height={90}
                        /> : ''}
                    </span>
                    {e?.components[1]?.text && <div title={e?.components[1]?.text}>{e?.components[1]?.text.length > 15 && e?.components[1]?.text ? e?.components[1]?.text?.slice(0, 14) + '...' : e?.components[1]?.text}</div>}
                  </div>
                  <div className="pt-2">
                    {e?.components[2]?.buttons?.map((ele: any, buttonIndex: number) => (
                      <p key={buttonIndex} className="quickbtns m-1 text-center" title={ele?.text}>
                        {ele?.type === 'QUICK_REPLY' ? '' : ele?.type === 'PHONE_NUMBER' ? <img src={phone} width={20} /> : ele?.type === 'URL' ? <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-1" /> : ''}
                        {ele?.text?.length > 15 ? ele?.text?.slice(0, 14) + '...' : ele?.text}</p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          : ''}
        <div className={""}>
          {data?.components &&
            data.components.map((template: any) => (
              <React.Fragment key={template.id}>
                {template.buttons &&
                  template.buttons.slice(0, 2).map((button: any) => (
                    
                    <React.Fragment key={button.id}>
                      <div
                        className="replyActionBtnsfortemp mt-2 text-center"
                        style={{ width: "85%" }}
                      >
                  {/* {button?.type?.toUpperCase() === "OTP" &&
                  button?.otp_type?.toUpperCase() === "COPY_CODE" ?
                  <FaRegCopy /> 
                  : */} 
                       <FontAwesomeIcon icon={faReply} className="mr-1"/> 
                       {/* } */}
                       {/* {
                         campaignCtx?.repeatCampaign?.templateVariables && campaignCtx?.repeatCampaign?.templateVariables?.length > 0
                          ?  campaignCtx?.repeatCampaign?.templateVariables?.map((item:any)=>item.bodyText)
                          :  */}
                          {button.text}
                       {/* } */}
                      </div>
                    </React.Fragment>
                  ))}
              </React.Fragment>
            ))}
          {data?.components?.find((component:any)=>component.type==="BUTTONS" && component?.buttons?.length >2) && (
            <div
              className="replyActionBtnsfortemp mt-2 text-center"
              style={{ width: "85%" }}
            >
              <FontAwesomeIcon
                icon={faList}
                widths={80}
                className="me-2"
              ></FontAwesomeIcon>
              Explore More
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PreviewWhatsapp;

import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Label,
  Row,
  Col,
  Button,
} from "reactstrap";
import { Form, Field, ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import styles from "./policy.module.scss";
import { getSLA, updateSLA } from "../../../services/ticketService";
import { useSelector } from "react-redux";
import { FaArrowLeft } from "react-icons/fa6";
import Select from "react-select";
import { customStyles } from "../../../common/react-select-style";
import "./sla.scss";
import TooltipReference from "../../../common/tooltip";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import {
  Breadcrumb,
  Collapse,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { PiInfoLight } from "react-icons/pi";
const initialPolicyState = {
  name: "",
  description: "",
  targets: [
    {
      priority: "",
      businessHour: false,
      resolutionDueTime: "",
      firstResponseTime: "",
      firstResponseTimeData: {
        days: 0,
        hours: 0,
        minutes: 0,
      },
      resolutionDueTimeData: {
        days: 0,
        hours: 0,
        minutes: 0,
      },
    },
  ],
};

const EditPolicies: React.FC = () => {
  const [policyData, setPolicyData] = useState(initialPolicyState);
  const [focusedResponseIndex, setFocusedResponseIndex] = useState<any>(null);

  const channelUid = useSelector(
    (state: any) => state.cartreducer.channelUid.value
  );
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (channelUid) {
        const response = await getSLA(channelUid);
        if (response) {
          setPolicyData(response);
        }
      }
    };
    fetchData();
  }, [channelUid]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        focusedResponseIndex !== null &&
        event.target instanceof HTMLElement &&
        event.target.tagName.toLowerCase() !== "input"
      ) {
        setFocusedResponseIndex(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [focusedResponseIndex]);

  const initialValues = {
    name: policyData.name,
    description: policyData.description,
    targets: policyData.targets.map((target) => ({
      priority: target.priority,
      firstResponseTime: target.firstResponseTime,
      businessHour: target.businessHour,
      resolutionDueTime: target.resolutionDueTime,
      firstResponseTimeData: target.firstResponseTimeData,
      resolutionDueTimeData: target.resolutionDueTimeData,
    })),
  };

  const handleSelectChange = (
    selectedOption: any,
    setFieldValue: any,
    field: string
  ) => {
    setFieldValue(field, selectedOption ? selectedOption.value : null);
  };

  const handleSubmit = async (values: any) => {
    const processedValues = {
      ...values,
      targets: values.targets.map((target: any) => ({
        ...target,
        firstResponseTimeData: {
          days: target.firstResponseTimeData.days || 0,
          hours: target.firstResponseTimeData.hours || 0,
          minutes: target.firstResponseTimeData.minutes || 0,
        },
        resolutionDueTimeData: {
          days: target.resolutionDueTimeData.days || 0,
          hours: target.resolutionDueTimeData.hours || 0,
          minutes: target.resolutionDueTimeData.minutes || 0,
        },
      })),
    };
    const updatePayload = { ...policyData, ...processedValues };
    const updatePolicy = await updateSLA(channelUid, updatePayload);
    if (updatePolicy)
      navigate(-1);
  };

  const capitalize = (str: string) => {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    targets: Yup.array().of(
      Yup.object().shape({
        firstResponseTimeData: Yup.object()
          // .shape({
          //   days: Yup.number()
          //     .required("Days is required")
          //     .min(0, "Days cannot be negative"),
          //   hours: Yup.number()
          //     .required("Hours is required")
          //     .min(0, "Hours cannot be negative")
          //     .max(23, "Hours must be less than 24"),
          //   minutes: Yup.number()
          //     .required("Minutes is required")
          //     .min(0, "Minutes cannot be negative")
          //     .max(59, "Minutes must be less than 60"),
          // })
          .test(
            "firstResponseTimeData",
            "Minimum duration is 15 mins",
            (value: any) => {
              const { days, hours, minutes } = value;
              return days > 0 || hours > 0 || minutes > 0;
            }
          ),
        resolutionDueTimeData: Yup.object()
          // .shape({
          //   days: Yup.number()
          //     .required("Days is required")
          //     .min(0, "Days cannot be negative"),
          //   hours: Yup.number()
          //     .required("Hours is required")
          //     .min(0, "Hours cannot be negative")
          //     .max(23, "Hours must be less than 24"),
          //   minutes: Yup.number()
          //     .required("Minutes is required")
          //     .min(0, "Minutes cannot be negative")
          //     .max(59, "Minutes must be less than 60"),
          // })
          .test(
            "resolutionDueTimeData",
            "Minimum duration is 15 mins",
            (value: any) => {
              const { days, hours, minutes } = value;
              return days > 0 || hours > 0 || minutes > 0;
            }
          ),
      })
    ),
  });

  const handleKeyDown = (event: any) => {
    const { value } = event.target;
    if (event.key === " " && value.trim() === "") {
      event.preventDefault();
    }
  };
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const char = String.fromCharCode(e.which);
    if (!/[0-9]/.test(char)) {
      e.preventDefault();
    }
  };

  return (
    <div className={styles.editPolicyContainer}>
      <div className="d-flex h5">
        <FaArrowLeft
          color="black"
          className="me-2"
          onClick={() => {
            navigate(-1);
          }}
        />
        <div>Edit SLA Policy</div>
      </div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, setFieldValue, values, errors, touched }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <div className="pt-2 d-flex justify-content-between">
                  <Label >
                    Name <span className="required"></span>
                  </Label>
                  <div>
                    <span className={styles.charCount}>
                      {values.name?.length}/100
                    </span>
                  </div>
                </div>
                <Field
                  name="name"
                  type="text"
                  className="form-control"
                  maxLength="100"
                  onKeyDown={handleKeyDown}
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-danger"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label className="pt-2 d-flex justify-content-between">
                  Description
                  <span className={styles.charCount}>
                    {values.description?.length}/250
                  </span>
                </Label>
                <Field
                  as="textarea"
                  className={`form-control ${styles.textarea}`}
                  name="description"
                  placeholder="Enter your description here"
                  rows={2}
                  width={50}
                  maxLength={250}
                  onKeyDown={handleKeyDown}
                />
              </Col>
            </Row>

            <Label>Set SLA target as:</Label>
            <Card className={styles.card}>
              <CardHeader className={styles.cardHeader}>
                <Row>
                  <Col>
                    <Label>Priority</Label>
                  </Col>
                  <Col>
                    <Label>
                      First response time &nbsp;
                      <TooltipReference
                        placement="right"
                        content="The target time for a member to respond to the new ticket"
                        tooltipId="firstResponseTime"
                        icon={faInfoCircle}
                      ></TooltipReference>
                      <div>
                      </div>

                    </Label>
                  </Col>
                  <Col>
                    <Label>
                      Resolution time &nbsp;
                      <TooltipReference
                        placement="right"
                        content="The target time for a ticket to be resolved or closed"
                        tooltipId="resolutionTime"
                        icon={faInfoCircle}
                      ></TooltipReference>
                    </Label>
                  </Col>
                  <Col>
                    <Label>Operational hours</Label>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={3}></Col>
                  {focusedResponseIndex?.FIRST >= 0 && (
                    <Col md={2} className="d-flex justify-content-around">
                      <Label>day</Label>
                      <Label>hrs</Label>
                      <Label>mins</Label>
                    </Col>
                  )}
                  <Col md={3}></Col>
                  {focusedResponseIndex?.SECOND >= 0 && (
                    <Col md={2} className="d-flex justify-content-around">
                      <Label>day</Label>
                      <Label>hrs</Label>
                      <Label>mins</Label>
                    </Col>
                  )}
                </Row>
                {values.targets.map((target: any, index) => (
                  <Row key={index} className="pb-2 d-flex align-items-center">
                    <Col md={3} className="d-flex align-items-center">
                      <span className={`me-2 ${target.priority}`}></span>
                      <Label className="m-0">
                        {capitalize(target.priority)}
                      </Label>
                    </Col>
                    {focusedResponseIndex?.FIRST !== index && (
                      <Col md={2} className="d-block p-0">
                        <Field
                          type="text"
                          className="form-control"
                          value={`${values.targets[index].firstResponseTimeData?.days}d ${values.targets[index].firstResponseTimeData?.hours}h ${values.targets[index].firstResponseTimeData?.minutes}m`}
                          onFocus={() =>
                            setFocusedResponseIndex({ FIRST: index })
                          }
                        />
                        <ErrorMessage
                          name={`targets.${index}.firstResponseTimeData`}
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                    )}
                    {focusedResponseIndex?.FIRST === index && (
                      <Col md={2} className="d-flex p-0">
                        <Field
                          type="number"
                          className="form-control"
                          name={`targets.${index}.firstResponseTimeData.days`}
                          onKeyPress={handleKeyPress}
                        />
                        <Field
                          type="number"
                          className="form-control"
                          name={`targets.${index}.firstResponseTimeData.hours`}
                          onKeyPress={handleKeyPress}
                        />
                        <Field
                          type="number"
                          className="form-control"
                          name={`targets.${index}.firstResponseTimeData.minutes`}
                          onKeyPress={handleKeyPress}
                        />
                      </Col>
                    )}
                    <Col md={1}></Col>
                    {focusedResponseIndex?.SECOND !== index && (
                      <Col md={2} className="d-block p-0">
                        <Field
                          type="text"
                          className="form-control"
                          value={`${values?.targets[index]?.resolutionDueTimeData?.days}d ${values?.targets[index]?.resolutionDueTimeData?.hours}h ${values?.targets[index]?.resolutionDueTimeData?.minutes}m`}
                          onFocus={() =>
                            setFocusedResponseIndex({ SECOND: index })
                          }
                        />
                        <ErrorMessage
                          name={`targets.${index}.resolutionDueTimeData`}
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                    )}
                    {focusedResponseIndex?.SECOND === index && (
                      <Col md={2} className="d-flex p-0">
                        <Field
                          type="number"
                          className="form-control"
                          name={`targets.${index}.resolutionDueTimeData.days`}
                          onKeyPress={handleKeyPress}
                        />
                        <Field
                          type="number"
                          className="form-control"
                          name={`targets.${index}.resolutionDueTimeData.hours`}
                          onKeyPress={handleKeyPress}
                        />
                        <Field
                          type="number"
                          className="form-control"
                          name={`targets.${index}.resolutionDueTimeData.minutes`}
                          onKeyPress={handleKeyPress}
                        />
                      </Col>
                    )}
                    <Col md={1}></Col>
                    <Col>
                      <Select
                        name={`targets.${index}.businessHour`}
                        options={[
                          // { value: true, label: "Business hours" },
                          { value: false, label: "Calendar hours" },
                        ]}
                        styles={customStyles}
                        value={
                          values.targets[index].businessHour !== null
                            ? {
                                value: values?.targets[index]?.businessHour,
                                label: values?.targets[index]?.businessHour
                                  ? "Business hours"
                                  : "Calendar hours",
                              }
                            : null
                        }
                        onChange={(selectedOption) =>
                          handleSelectChange(
                            selectedOption,
                            setFieldValue,
                            `targets.${index}.businessHour`
                          )
                        }
                      />
                    </Col>
                  </Row>
                ))}
              </CardBody>
            </Card>
            <div className="float-right">
              <Button type="submit" className="sendButton me-2">
                Submit
              </Button>
              <Button
                type="button"
                className="cancelButton"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditPolicies;

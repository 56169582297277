import React, { useContext, useEffect, useState } from 'react'
import { faAdd, faTags } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import { AxiosResponse } from 'axios'
import { useSelector } from 'react-redux'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Swal from 'sweetalert2'

import styles from './conversationTags.module.scss'
import ConversationFilterSelect, { IItem } from './conversationFilterSelect'
import { createTag, getTags } from '../../botFlows/services/contacts'
import { toast } from '../../common/alert'
import axios from '../../utils/axios'
import conversationContext from './conversationContext'

interface ConversationTagsProps {
    selectedConversation: any;
    selectedConversations?: string[];
    bulk?: boolean;
    onSelect?: (t: number) => void;
    onSetTags?: (id: number, name: string | null) => void;
};


export default function ConversationTags({ selectedConversation, bulk, onSelect, selectedConversations, onSetTags }: ConversationTagsProps) {
    const conversationCtx = useContext(conversationContext);
    const businessUid = useSelector((state: any) => state.cartreducer.business?.business?.uid);
    const memberUid = useSelector((state: any) => state.cartreducer.business?.uid);
    const channelUid = useSelector(
        (state: any) => state.cartreducer.channelUid?.value
    );

    // Fetching tags
    const [searchQuery, setSearchQuery] = useState("");
    const [data, setData] = useState<any[]>([]);
    const [page, setPage] = useState(0);
    const [shouldInfiniteLoad, setShouldInfiniteLoad] = useState(true);

    const [showTagList, setShowTagList] = useState(false);
    const [showAddTag, setShowAddTag] = useState(false);
    const [tags, setTags] = useState<any[]>([]);
    const [creatable, setCreatable] = useState(false);

    useEffect(() => {
        setTags(selectedConversation?.contact?.tags);
    }, [selectedConversation])

    const mapTagsDataHandler = (tagData: any) => ({
        id: tagData.id,
        value: String(tagData.id),
        label: tagData.name
    })

    const fetchTagsHandler = (q?: string, p?: number) => {
        const query = q || searchQuery;
        const pageNumber = p || page;
        return new Promise<AxiosResponse<any[]>>((resolve) => {
            const queryParams = JSON.stringify([businessUid, pageNumber, query]);
            const cacheName = `Conv_FetchCatch_tags_${queryParams}`;
            if ((window as any).Conv_FetchCatch && Array.isArray((window as any).Conv_FetchCatch)) {
                (window as any).Conv_FetchCatch.push(cacheName);
            } else {
                (window as any).Conv_FetchCatch = [cacheName];
            }
            if ((window as any)[cacheName]) {
                return resolve((window as any)[cacheName]);
            } else {
                getTags(businessUid, pageNumber, query)
                    .then(data => {
                        (window as any)[cacheName] = data;
                        resolve(data);
                    });
            }
        }).then((fetchedTags: AxiosResponse<any>) => {
            const dataArray = fetchedTags?.data?.tagsDto;

            if (dataArray?.length > 0) {
                const newData = [...data, ...dataArray];
                setData(newData);
                return newData;
            } else if (pageNumber < 1) {
                setData([]);
                return [];
            } else {
                setShouldInfiniteLoad(false);
                return data;
            }
        });
    }

    useEffect(() => {
        if (searchQuery.trim() !== "") {
            fetchTagsHandler();
        }
    }, [page]);

    const onSearchHandler = async (q: string) => {
        setShouldInfiniteLoad(true);
        setData([]);
        setPage(0);
        setSearchQuery(q);
        const result = await fetchTagsHandler(q, 0);
        return result.map(mapTagsDataHandler);
    }

    const setTagHandler = async (tagId: number, tagName: string | null = null) => {
        if (bulk) {
            Swal.fire({
                icon: "question",
                title: "Assign chats",
                text: "Are you sure you want to update tags for all selected chats?",
                confirmButtonText: "Confirm",
                showCancelButton: true,
                didOpen: () => {
                    const confirmButton = document.querySelector('.swal2-confirm') as HTMLElement;
                    if (confirmButton) {
                        confirmButton.style.backgroundImage = 'linear-gradient(to right, #00B598, #458d80)';
                        confirmButton.style.border = 'none';
                        confirmButton.style.color = 'white';
                    }
                }
            }).then(async ({ isConfirmed }) => {
                if (isConfirmed && selectedConversations) {
                    await axios.post("conversation/updateBulkConversations", {
                        channelUid,
                        conversationUids: selectedConversations,
                        tags: [
                            { id: tagId }
                        ]
                    });
                    if (onSetTags) onSetTags(tagId, tagName);
                    toast("success", "Chat(s) tags updated");
                }
            });
        } else {
            try {
                const contact = selectedConversation?.contact;
                // const countryCodeLength = contact?.countryCode?.length || 0;
                // contact.mobileNumber = contact?.mobileNumber?.slice(countryCodeLength);
                const sendTags = {
                    tags: [...tags,
                    { id: tagId }],
                    contactType: selectedConversation?.contact?.contactType,
                }
                const updatedContactResponse = await axios.post(`contact/${contact.uid}/modifyContact`,
                    sendTags
                );
                contact.mobileNumber = updatedContactResponse?.data?.mobileNumber;
                let updatedTags = updatedContactResponse.data.tags;
                // for create-tag & assign
                if (tagName) {
                    updatedTags = updatedContactResponse.data.tags.filter((t: any) => t.id !== tagId);
                    updatedTags.push({ id: tagId, name: tagName });
                }
                setTags(updatedTags);
                conversationCtx.setTags(updatedTags);
                contact.tags = updatedContactResponse?.data?.tags;
                toast("success", "Tags added sucessfully");
            } catch (error) {
                toast("error", "Failed to set tag");
            }
        }
        if (onSelect) onSelect(tagId);
    }

    const removeTagHandler = async (tagId: number) => {
        try {
            const contact = selectedConversation?.contact;
            // const countryCodeLength = contact?.countryCode?.length || 0;
            // contact.mobileNumber = contact?.mobileNumber?.slice(countryCodeLength);
            const removeTags = {
                tags: tags.filter((tag: any) => tag.id !== tagId),
                contactType: selectedConversation?.contact?.contactType,
            }
            const updatedContactResponse = await axios.post(`contact/${contact.uid}/modifyContact`,
                // ...contact,
                // uid: businessUid,
                removeTags
            );
            setTags(updatedContactResponse.data.tags);
            contact.mobileNumber = updatedContactResponse?.data?.mobileNumber;
            contact.tags = updatedContactResponse?.data?.tags;
            conversationCtx.setTags(updatedContactResponse.data.tags);
            toast("success", "Tags removed successfully");
        } catch (error) {
            toast("error", "Failed to remove tag");
        }
    }

    const createTagHandler = async (tagName: string) => {
        try {
            const createdTag = await createTag(businessUid, memberUid, tagName);
            await setTagHandler(createdTag.data.id, tagName);
            setShowAddTag(false);
        } catch (error: any) {
            let myMessage = "Failed to create tag"
            if ('message' in error.response.data) {
                myMessage = error.response.data.message
            }
            toast("error", myMessage);
        }
    }

    const tagsDropdown = (
        <DropdownMenu className={styles.addTagPopup}>
            <ConversationFilterSelect
                creatable={creatable}
                options={
                    (data.map(mapTagsDataHandler).filter((tag: any) => tags ? !tags.find((t: any) => t.id === tag.id) : true) as IItem[])
                        .reduce((accumulator: IItem[], currentObject: IItem) => {
                            if (!accumulator.some((obj) => obj.id === currentObject.id)) {
                                accumulator.push(currentObject);
                            }
                            return accumulator;
                        }, [])
                }
                onSearch={async (searchTerm: string) => {
                    const isSearchedTermFound = tags?.some((t: any) => t.name.toLowerCase() === searchTerm.toLowerCase());
                    setCreatable(!isSearchedTermFound);
                    return onSearchHandler(searchTerm);
                }}
                onChange={(item: IItem) => {
                    setTagHandler(item.id);
                    setShowAddTag(false);
                }}
                onLoad={() => setPage(currentPage => currentPage + 1)}
                shouldInfiniteLoad={shouldInfiniteLoad}
                onCreate={createTagHandler}
            />
        </DropdownMenu>
    );

    if (bulk) {
        return (
            <Dropdown
                style={{ display: "inline" }}
                isOpen={showAddTag}
                toggle={() => setShowAddTag(current => !current)}
                direction="down"
            >
                <DropdownToggle className={["btn", "btn-link", styles.tagCount].join(" ")}>
                    <FontAwesomeIcon icon={faTags} />
                </DropdownToggle>
                {tagsDropdown}
            </Dropdown>
        );
    }

    return (
        <span className={styles.convTags}>
            {tags?.length > 0 ? (
                <Dropdown
                    style={{ display: "inline" }}
                    isOpen={showTagList}
                    toggle={() => setShowTagList(current => !current)}
                    direction="down"
                >
                    <OverlayTrigger
                        overlay={<Tooltip>{tags.filter((tag: any) => tag.name).map((tag: any) => tag.name).join(', ')}</Tooltip>}
                        placement='bottom'
                    >
                        <DropdownToggle id="ConvTagTooltip" className={["btn", "btn-link", styles.tagCount].join(" ")}>
                            <FontAwesomeIcon icon={faTags} />
                            <span>+{tags.filter((tag: any) => tag.name).length}</span>
                        </DropdownToggle>
                    </OverlayTrigger>
                    <DropdownMenu className={styles.tagsPopup}>
                        <ul className={styles.tagList}>
                            {tags
                                .map((tag: any) => (
                                    <li className={styles.tagItem} key={tag.id}>
                                        <span className={styles.label}>{tag.name}</span>
                                        <button
                                            onClick={() => {
                                                removeTagHandler(tag.id);
                                                setShowTagList(false);
                                            }}
                                        >
                                            &times;
                                        </button>
                                    </li>
                                ))}
                        </ul>
                    </DropdownMenu>
                </Dropdown>
            ) : null}
            <Dropdown
                // style={{ display: "inline" }}
                isOpen={showAddTag}
                toggle={() => setShowAddTag(current => !current)}
                direction="down"
                className='btn-sm m-0'

            >
                <DropdownToggle className={["btn", "btn-link", styles.addBtn].join(" ")}>
                    <FontAwesomeIcon icon={faAdd} />
                    {' ' + "Add tag"}
                </DropdownToggle>

                {tagsDropdown}
            </Dropdown>
        </span>
    )
}

ConversationTags.defaultProps = {
    bulk: false
};
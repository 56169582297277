import { useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import { Button, FormGroup, Input, Label } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import styles from './HolidayForm.module.scss'
import { FormData } from '../MemberCalenderForm'
import { uuidv4 } from '../../../botFlows/utils/uuid'
import { PiMinusCircleFill } from 'react-icons/pi'
import DateInput from '../../../common/dateInput'

type Props = {
    onCancel: () => void,
    submitButton?: string
}

type HolidayListItem = { name: string; date: string }

type HolidayFormError = {
    name: string;
    description: string;
    list: string | Array<HolidayListItem>;
};


function HolidayForm({
    onCancel,
    submitButton
}: Props) {
    const { values, handleChange, setFieldValue, errors, touched } = useFormikContext<FormData>()

    const addListItemHandler = () => {
        const updatedList = values.holidayForm?.list
        updatedList?.push({
            id: uuidv4(),
            name: '',
            date: ''
        })
        setFieldValue('holidayForm.list', updatedList)
    }

    const removeListItemHandler = (index: number) => {
        const updatedList = values.holidayForm?.list
        updatedList?.splice(index, 1)
        setFieldValue('holidayForm.list', updatedList)
    }

    const isTouched = Boolean(touched.holidayForm)

    return (
        <div>
            <FormGroup className='mt-2'>
                <Label>
                    List Name
                    <small className='required'></small>
                </Label>
                <Input
                    type='text'
                    name='holidayForm.name'
                    value={values.holidayForm?.name}
                    onChange={handleChange}
                />
                {isTouched
                    && errors.holidayForm
                    && typeof errors.holidayForm === 'object'
                    && 'name' in errors.holidayForm ? (
                    <div className='invalid-feedback' style={{ display: 'block' }}>
                        {(errors.holidayForm as HolidayFormError).name}
                    </div>
                ) : null}
            </FormGroup>
            <FormGroup className='mt-2'>
                <Label>
                    List Description
                    <small className='required'></small>
                </Label>
                <Input
                    type='textarea'
                    name='holidayForm.description'
                    value={values.holidayForm?.description}
                    onChange={handleChange}
                />
                {isTouched
                    && errors.holidayForm
                    && typeof errors.holidayForm === 'object'
                    && 'description' in errors.holidayForm ? (
                    <div className='invalid-feedback' style={{ display: 'block' }}>
                        {(errors.holidayForm as HolidayFormError).description}
                    </div>
                ) : null}
            </FormGroup>
            <FormGroup className='mt-2'>
                <Label>
                    Holiday List
                    <small className='required'></small>
                </Label>
                <div className={styles.holidayList}>
                    {Array.isArray(values.holidayForm?.list) ? values.holidayForm?.list.map((holiday, index) => {
                        return (
                            <div
                                className={styles.holidayList__item}
                                key={holiday.id}
                            >
                                <Button
                                    type='button'
                                    color='white'
                                    className={styles.removeBtn}
                                    onClick={() => removeListItemHandler(index)}
                                >
                                    <PiMinusCircleFill />
                                </Button>
                                <div>
                                    <FormGroup className='mt-2'>
                                        <Label>
                                            Name
                                            <small className='required'></small>
                                        </Label>
                                        <Input
                                            type='text'
                                            name={'holidayForm.list[' + index + '].name'}
                                            value={values.holidayForm?.list[index].name}
                                            onChange={handleChange}
                                        />
                                        {isTouched
                                            && errors.holidayForm
                                            && typeof errors.holidayForm === 'object'
                                            && 'list' in errors.holidayForm
                                            && Array.isArray((errors.holidayForm as HolidayFormError).list) ? (
                                            <div className='invalid-feedback' style={{ display: 'block' }}>
                                                {((errors.holidayForm as HolidayFormError).list[index] as HolidayListItem)?.name}
                                            </div>
                                        ) : null}
                                    </FormGroup>
                                    <FormGroup className='mt-2'>
                                        <Label>
                                            Date
                                            <small className='required'></small>
                                        </Label>
                                        <DateInput
                                            name={'holidayForm.list[' + index + '].date'}
                                            value={values.holidayForm?.list[index].date || ''}
                                        />
                                        {isTouched
                                            && errors.holidayForm
                                            && typeof errors.holidayForm === 'object'
                                            && 'list' in errors.holidayForm
                                            && Array.isArray((errors.holidayForm as HolidayFormError).list) ? (
                                            <div className='invalid-feedback' style={{ display: 'block' }}>
                                                {((errors.holidayForm as HolidayFormError).list[index] as HolidayListItem)?.date}
                                            </div>
                                        ) : null}
                                    </FormGroup>
                                </div>
                            </div>
                        )
                    }) : null}
                    <Button
                        color='primary'
                        size='sm'
                        className='addButton'
                        onClick={addListItemHandler}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </div>
                {isTouched
                    && errors.holidayForm
                    && typeof errors.holidayForm === 'object'
                    && 'list' in errors.holidayForm
                    && typeof (errors.holidayForm as HolidayFormError).list === 'string' ? (
                    <div className='invalid-feedback' style={{ display: 'block' }}>
                        {String((errors.holidayForm as HolidayFormError).list)}
                    </div>
                ) : null}
            </FormGroup>
            <FormGroup className='mt-2'>
                <Button
                    type='submit'
                    // color='primary'
                    className='mr-2 sendButton'
                >
                    {submitButton || 'Create'}
                </Button>
                <Button
                    onClick={onCancel}
                    className='cancelButton'>
                    Cancel
                </Button>
            </FormGroup>
        </div>
    )
}

export default HolidayForm
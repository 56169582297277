import React, { useState, useEffect } from 'react';
import './contact.scss';

interface optStateProps {
  whatsappOptState?: (check: boolean) => void;
  checked: boolean;
  menuName?: string;
  smSize?: string;
  variableId?: any;
  TemplateVaribles?: (check: boolean, id: any) => void;
  multiSettings?:(index: number,checked: boolean) => void;
}

const SwitchButton: React.FC<optStateProps> = (props) => {
  const { smSize, variableId } = props;
  const [checked, setChecked] = useState(props.checked);
  const menuname = props.menuName;

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  const handleToggle = (index:any) => {
    const newCheckedState = !checked;
    setChecked(newCheckedState);
    if (props.whatsappOptState) {
      props.whatsappOptState(newCheckedState);
    }
    if(props.multiSettings){
      props.multiSettings(index,newCheckedState);
    }
    if (props.TemplateVaribles) {
      props.TemplateVaribles(newCheckedState, variableId);
    }
    // Pass the new state here, not the previous state  'checked'
  };

  return (
    <div>
      {menuname === 'automation' ?
        <label className={`switch-button ${checked ? 'checked switch-button-auto' : 'switchdisable'}`} onClick={handleToggle}>
          <div className="switch-slider switch-slider-auto"></div> <span className={`textwidth  ${checked ? 'ml-2' : 'marginleft'}`}> {checked ? 'Enabled' : 'Disabled'}</span>
        </label>
        :
        <label className={`switch-button switch-buttons ${checked ? 'checked' : ''} ${smSize ? 'mt-2' : ''}`} onClick={handleToggle}>
          <div className={`switch-slider switch-sliders`}></div>
        </label>
      }
    </div>
  );
};

export default SwitchButton;
import React, { useContext, useEffect, useRef, useState } from "react";
import { Popover, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAt,
  faBullhorn,
  faCheck,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import { Formik, useFormikContext } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { AxiosResponse } from "axios";
import { useEventListener } from "usehooks-ts";

import "./conversation.scss";
import styles from "./conversationFilter.module.scss";
import * as contactService from "../../botFlows/services/contacts";
import * as botService from "../../botFlows/services/bots";
import conversationContext, { IFilterData } from "./conversationContext";
import ConversationFilterSelect, { IItem } from "./conversationFilterSelect";
import { getCampaignList } from "../../services/campaignService";
import { createPortal } from "react-dom";
import DateRangePicker from "react-daterange-picker";
import * as originalMoment from "moment";
import { extendMoment } from "moment-range";
import { getConversationsWithFilters } from "../../services/conversationservice";
import { LuFilter } from "react-icons/lu";
import TooltipReference from "../../common/tooltip";
import { sliceText } from "../../common/sliceText";
import { IoIosCloseCircleOutline } from "react-icons/io";

type ConversationFilterCheckboxProps = {
  label: string;
  checked: boolean;
  onChange: (event: React.MouseEvent<HTMLLabelElement, MouseEvent>) => void;
  id: number;
};

// Define the option type
interface OptionType {
  value: string;
  label: string;
}

function ConversationFilterCheckbox({
  label,
  checked,
  onChange,
  id,
}: ConversationFilterCheckboxProps) {
  return (
    <label
      className={styles.checkboxContainer}
      onClick={onChange}
      style={{ fontSize: "14px", color: "#212529" }}
    >
      <span
        className={`${styles.customCheckbox} ${checked ? styles.checked : ""}`}
      >
        {checked ? (
          <FontAwesomeIcon icon={faCheck} className="thick-icon" />
        ) : null}
      </span>
      <TooltipReference
        placement="right"
        tooltipId={`botMemberCheckbox-${id}`}
        content={label}
      >
        {sliceText(label, 25)}
      </TooltipReference>
    </label>
  );
}

export type IListSourceType =
  | "tags"
  | "member"
  | "bot"
  | "channel"
  | "campaign";

interface FilterListProps {
  listSource: IListSourceType;
  rerender: () => any;
  infiniteLoad?: boolean;
  children?: React.ReactNode;
  setIsBotDisabled?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
}

interface FilterListDataItem {
  label: string;
  id: number;
  value: string;
}

const FilterList: React.FC<FilterListProps> = ({
  listSource,
  infiniteLoad,
  children,
  setIsBotDisabled
}) => {
  const formik = useFormikContext();
  const conversationCtx = useContext(conversationContext);

  const [isDataInitialized, setDataInitialized] = useState(false);
  const [previewData, setPreviewData] = useState<FilterListDataItem[]>(
    (window as any)[`ConvFilter_previewDataCatch_${listSource}`] || []
  );
  const [fetched, setFetched] = useState(false);
  const [page, setPage] = useState(0);
  const [data, setData] = useState<any[]>([]);
  const [shouldInfiniteLoad, setShouldInfiniteLoad] = useState(
    Boolean(infiniteLoad)
  );

  const [searchQuery, setSearchQuery] = useState<null | string>(null);

  useEffect(() => {
    (window as any)[`ConvFilter_Searching_${listSource}`] = false;
    (window as any)[`ConvFilter_ShouldLoadMore_${listSource}`] = true;
    (window as any)[`ConvFilter_ShouldLoadMore_${listSource}_Loading`] = false;
    if ((window as any).ConvFilter_SelectCache) {
      formik.setFieldValue("selected", (window as any).ConvFilter_SelectCache);
      (window as any).ConvFilter_SelectCache = null;
    }
    if ((window as any).ConvFilter_InitiateReset) {
      (window as any).ConvFilter_SelectCache = null;
      formik.setFieldValue("selected", {
        tags: [],
        member: [],
        bot: [],
        channel: [],
        campaign: [],
      });
    }
  }, []);

  const channelUid = useSelector(
    (state: any) => state.cartreducer.channelUid?.value
  ); 

  const businessUid = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );
  const activeChannel = useSelector(
    (state: any) => state.cartreducer.channelUid
  );
  const channels = useSelector((state: any) => state.cartreducer.channelData);
  const members = useSelector((state: any) => state.cartreducer.memberData);

  useEffect(() => {
    (window as any)[`ConvFilter_DataCache_${listSource}`] = [];
    setData([]);
    if (listSource === "bot") {
      fetchDataHandler([], 0, searchQuery);
    }
  }, [channelUid, listSource]);

  /**
   * Fetch data chunk for the given listSource
   * @param d Exising  data
   * @param p Page number
   * @param q Search query
   * @returns Fetched data chunk
   */
  const fetchDataHandler = async (
    d: any[] = [],
    p: number | null = null,
    q: null | string = null
  ) => {
    const myData = d
      ? d
      : (window as any)[`ConvFilter_DataCache_${listSource}`]
      ? (window as any)[`ConvFilter_DataCache_${listSource}`]
      : data;
    const myQuery = q || searchQuery;
    let myPage: any;
    if (q !== null && q !== searchQuery) {
      myPage = 0;
    } else {
      myPage = p !== null ? p : page;
    }
  
    const fetchBotsData = (params: [string, number, number]) => {
      const [query, offset, limit] = params;
      const cacheKey = `ConvFilter_FetchCatch_${listSource}_${JSON.stringify(params)}`;
      
      // Check if data is already cached
      if ((window as any)[cacheKey]) {
        return Promise.resolve((window as any)[cacheKey].bots);
      }
      
      // Perform API call if data is not cached
      return botService.getBots(query, offset, limit).then((data) => {
        if (data.bots.length === 0) {
          if (setIsBotDisabled) setIsBotDisabled(true);
        } else {
          if (setIsBotDisabled) setIsBotDisabled(false);
        }
        
        // Cache the response data
        (window as any)[cacheKey] = data;
        return data.bots;
      });
    };
    
    const handleData = (data: any[]) => {
      // Handle the processed data
      handleProcessData(data);
    };

    
    const handleProcessData = (fetchedData: AxiosResponse<any[]> | any[]) => {
      (window as any)[`ConvFilter_ShouldLoadMore_${listSource}_Loading`] = false;
  
      const dataArray =
        "data" in fetchedData && "tagsDto" in fetchedData.data
          ? fetchedData.data.tagsDto
          : "data" in fetchedData
          ? fetchedData.data
          : fetchedData;
  
      if (!(window as any)[`ConvFilter_DataCache_${listSource}`]) {
        (window as any)[`ConvFilter_DataCache_${listSource}`] = [];
      }
      if (Array.isArray(dataArray)) {
        if (dataArray.length > 0) {
          const newData = [
            ...(window as any)[`ConvFilter_DataCache_${listSource}`],
            ...dataArray,
          ].reduce((uniqueArr, currentObj) => {
            if (
              typeof currentObj === "object" &&
              currentObj.hasOwnProperty("id")
            ) {
              const isDuplicate = uniqueArr.some(
                (obj: any) =>
                  typeof obj === "object" &&
                  obj.hasOwnProperty("id") &&
                  obj.id === currentObj.id
              );
              if (!isDuplicate) {
                uniqueArr.push(currentObj);
              }
            } else {
              uniqueArr.push(currentObj);
            }
            return uniqueArr;
          }, []);
  
          setData(newData);
          (window as any)[`ConvFilter_DataCache_${listSource}`] = newData;
          setFetched(true);
          return Promise.resolve(newData);
        } else {
          (window as any)[`ConvFilter_DataCache_${listSource}`] = [];
          setData([]);
          setFetched(true);
          return Promise.resolve([]);
        }
      } else {
        (window as any)[`ConvFilter_ShouldLoadMore_${listSource}`] = false;
        setDataInitialized(true);
        setShouldInfiniteLoad(false);
        return Promise.resolve(myData);
      }
    };
  
    switch (listSource) {
      case "tags":
        return new Promise<AxiosResponse<any[]> | any[]>((resolve) => {
          const queryParams = JSON.stringify([businessUid, myPage, myQuery]);
          if (
            (window as any)[
              `ConvFilter_FetchCatch_${listSource}_${queryParams}`
            ]
          ) {
            return resolve(
              (window as any)[
                `ConvFilter_FetchCatch_${listSource}_${queryParams}`
              ]
            );
          } else {
            contactService.getTags(businessUid, myPage, myQuery).then((data) => {
              (window as any)[
                `ConvFilter_FetchCatch_${listSource}_${queryParams}`
              ] = data;
              resolve(data);
            });
          }
        }).then(handleProcessData);
      case "campaign":
        return new Promise<AxiosResponse<any[]> | any[]>((resolve) => {
          const queryParams = JSON.stringify([channelUid]);
          if (
            (window as any)[
              `ConvFilter_FetchCatch_${listSource}_${queryParams}`
            ]
          ) {
            return resolve(
              (window as any)[
                `ConvFilter_FetchCatch_${listSource}_${queryParams}`
              ]
            );
          } else {
            getCampaignList(channelUid).then((data) => {
              (window as any)[
                `ConvFilter_FetchCatch_${listSource}_${queryParams}`
              ] = data;
              resolve(data);
            });
          }
        }).then(handleProcessData);
      case "member":
        if (members) {
          return Promise.resolve(handleProcessData(members));
        }
        return Promise.resolve([]);
      case "bot":
        return fetchBotsData(["", 0, 1000]).then(handleData);
        // return new Promise<AxiosResponse<any[]> | any[]>((resolve) => {
        //   const queryParams = JSON.stringify(["", 0, 1000]);
        //   if (
        //     (window as any)[
        //       `ConvFilter_FetchCatch_${listSource}_${queryParams}`
        //     ]
        //   ) {
        //     botService.getBots("", 0, 1000).then((data) => {
        //       if(data.bots.length === 0){
        //         if(setIsBotDisabled) setIsBotDisabled(true)
        //       }else{
        //         if(setIsBotDisabled) setIsBotDisabled(false)
        //       }
        //       (window as any)[
        //         `ConvFilter_FetchCatch_${listSource}_${queryParams}`
        //       ] = data;
        //       resolve(data.bots);
        //     });
        //   } else {
        //     botService.getBots("", 0, 1000).then((data) => {              
        //       if(data.bots.length === 0){
        //         if(setIsBotDisabled) setIsBotDisabled(true)
        //       }else{
        //         if(setIsBotDisabled) setIsBotDisabled(false)
        //       }
        //       (window as any)[
        //         `ConvFilter_FetchCatch_${listSource}_${queryParams}`
        //       ] = data;
        //       resolve(data.bots);
        //     });
        //   }
        // }).then(handleProcessData);
      default:
        return Promise.resolve([]);
    }
  };
  
  useEffect(() => {
    if (listSource === "bot" || listSource === "campaign") {
      (window as any)[`ConvFilter_DataCache_${listSource}`] = [];
      setPage(0);
      setData([]);
      fetchDataHandler([], 0, searchQuery);
    }
  }, [channelUid]);

  useEffect(() => {
    if (
      (window as any)[`ConvFilter_ShouldLoadMore_${listSource}`] &&
      !(window as any)[`ConvFilter_ShouldLoadMore_${listSource}_Loading`]
    ) {
      (window as any)[`ConvFilter_ShouldLoadMore_${listSource}_Loading`] = true;
      fetchDataHandler();
    }
  }, [page]);

  /**
   * Convert data item to react-select option type
   * @param d Data item from data
   * @returns react-select option
   */
  const mapListDataHandler = (d: any) => ({
    id: d.id,
    value:
      listSource === "tags" || listSource === "bot" || listSource === "member"
        ? String(d.id)
        : d.uid,
    label: d.name,
  });

  useEffect(() => {
    let updatedPreviewData = [...previewData];

    if (
      listSource === "tags" ||
      listSource === "bot" ||
      listSource === "member"
    ) {
      if (!isDataInitialized && fetched) {
        const myData = data.map(mapListDataHandler);
        updatedPreviewData = myData.slice(0, 4);
        setDataInitialized(true);
      }
    } else {
      const myData = listSource === "campaign" ? data : channels;
      const newdata: any = myData.map((channel: any) => {
        return {
          id: channel.id,
          value: channel.uid,
          label: channel.name,
        };
      });
      updatedPreviewData = newdata.slice(0, 4);
      setDataInitialized(true);
    }

    if ((formik.values as any).selected[listSource].length > 0) {
      (formik.values as any).selected[listSource].forEach((value: string) => {
        const dataItm = conversationCtx.filterDataCache[listSource].find(
          (d) =>
            (listSource === "tags" ||
            listSource === "bot" ||
            listSource === "member"
              ? String(d.id)
              : d.uid) === value
        );
        if (dataItm && !updatedPreviewData.find((itm) => itm.value === value)) {
          updatedPreviewData.unshift({
            id: dataItm.id,
            value:
              listSource === "tags" ||
              listSource === "bot" ||
              listSource === "member"
                ? value
                : dataItm.uid,
            label: dataItm.name,
          });
        }
      });
    }

    setPreviewData(updatedPreviewData);
    (window as any)[`ConvFilter_previewDataCatch_${listSource}`] =
      updatedPreviewData;
  }, [data, activeChannel, fetched]);

  if (
    !(window as any)[`ConvFilter_previewDataCatch_${listSource}`] ||
    (listSource !== "channel" && !isDataInitialized)
  )
    return <Spinner />;

  const toggleFilterHandler = (
    item: FilterListDataItem,
    append: boolean = false
  ) => {
    (window as any).ConvFilter_InitiateReset = false;
    if (append) {
      setPreviewData((prevList) => {
        const updatedList = [...prevList];
        updatedList.unshift(item);
        (window as any)[`ConvFilter_previewDataCatch_${listSource}`] =
          updatedList;
        return updatedList;
      });
    }

    let updatedSelected = { ...(formik.values as any).selected };

    if (!Array.isArray(updatedSelected[listSource])) {
      updatedSelected[listSource] = [];
    }
    if (updatedSelected[listSource].find((val: string) => val === item.value)) {
      updatedSelected[listSource] = updatedSelected[listSource].filter(
        (val: string) => val !== item.value
      );
    } else {
      updatedSelected[listSource].push(item.value);
    }
    (window as any).ConvFilter_SelectCache = updatedSelected;
    formik.setFieldValue("selected", updatedSelected);

    const updatedCache = conversationCtx.filterDataCache[listSource]
      .concat(data)
      .reduce((uniqueArr, currentObj) => {
        if (currentObj.hasOwnProperty("id")) {
          const isDuplicate = uniqueArr.some(
            (obj: any) => obj.hasOwnProperty("id") && obj.id === currentObj.id
          );
          if (!isDuplicate) {
            uniqueArr.push(currentObj);
          }
        } else {
          uniqueArr.push(currentObj);
        }
        return uniqueArr;
      }, []);
    conversationCtx.setFilterDataCache({
      ...conversationCtx.filterDataCache,
      [listSource]: updatedCache,
    });
    conversationCtx.setTempFilter({
      ...conversationCtx.tempFilters,
      [listSource]: updatedSelected[listSource],
    });
  };

  const onSearchHandler = async (query: string) => {
    const myQuery = query.trim() === "" ? null : query;
    if (myQuery) {
      setSearchQuery(myQuery);
      setPage(0);
      setData([]);
      const result = await fetchDataHandler([], 0, myQuery);
      return result;
    }
    return data;
  };

  return (
    <>
      {previewData.length > 0 ? children : null}
      <div>
        {previewData.length >= 4 ? (
          <ConversationFilterSelect
            options={
              data
                .map(mapListDataHandler)
                .filter(
                  (itm) => !previewData.find((pItm) => pItm.id === itm.id)
                )
                .filter((itm) => {
                  if (searchQuery)
                    return Boolean(
                      itm.label.toLowerCase().includes(searchQuery)
                    );
                  return true;
                }) as IItem[]
            }
            onChange={(item: any) => toggleFilterHandler(item, true)}
            onLoad={() => setPage((currentPage) => currentPage + 1)}
            shouldInfiniteLoad={shouldInfiniteLoad}
            onSearch={onSearchHandler}
          />
        ) : null}
        {previewData.map((item) => {
          const isSelected = Boolean(
            (formik.values as any).selected[listSource].find(
              (val: string) => val === item.value
            )
          );
          return (
            <div className="mt-2 ml-1 cursor-pointer" key={item.id}>
              <ConversationFilterCheckbox
                label={item.label}
                checked={isSelected}
                onChange={() => toggleFilterHandler(item)}
                id={item.id}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

FilterList.defaultProps = {
  infiniteLoad: true,
};

interface FilterConversationsProps {
  component?: string;
  setData?: any;
  tableFilter?: boolean;
  setIsTableFilter?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  tablePage?: number;
  setTablePage?: React.Dispatch<React.SetStateAction<number>> | undefined;
  setTableLoader?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  shouldFetchData?: boolean;
  setShouldFetchData?:
    | React.Dispatch<React.SetStateAction<boolean>>
    | undefined;
  filterDisable?: boolean;
  tableSetup?: string;
  setTableSetup?: React.Dispatch<React.SetStateAction<string>> | undefined;
  filterCount?: number;
  setFilterCount?: React.Dispatch<React.SetStateAction<number>> | undefined;
  setGetAllFilterContacts?:
    | React.Dispatch<React.SetStateAction<Array<any>>>
    | undefined;
}
interface Option {
  label: string;
  value: string;
}

interface DateRange {
  start: moment.Moment | null;
  end: moment.Moment | null;
}

const FilterConversations: React.FC<FilterConversationsProps> = ({
  component,
  setData,
  tableFilter,
  setIsTableFilter,
  tablePage,
  setTablePage,
  setTableLoader,
  shouldFetchData,
  setShouldFetchData,
  filterDisable,
  tableSetup,
  setTableSetup,
  filterCount,
  setFilterCount,
  setGetAllFilterContacts,
}) => {

  const [showPopup, setShowPopup] = useState(false);

  const popupZoneRef = useRef<HTMLDivElement | null>(null);

  const [counter, setCounter] = useState(0);
  const conversationCtx = useContext(conversationContext);
  const channels = useSelector((state: any) => state.cartreducer.channelData);
  const business = useSelector((state: any) => state.cartreducer.business);
  const [reportPage, setReportPage] = useState(0);
  const options: Option[] = [
    { label: "New", value: "open" },
    { label: "Pending", value: "pending" },
    { label: "Qualified", value: "qualified" },
    { label: "Spam", value: "spam" },
  ];

  const popoverRef = useRef<HTMLDivElement | null>(null);
  const [isBotDisabled, setIsBotDisabled] = useState(false);
  const [assignTo, setAssignTo] = useState("0");
  const [selectBot, setSelectBot] = useState<number[]>([]);
  const [selectMember, setSelectMember] = useState<number[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const getConversationBasedOnFilter = async () => {
    const payload = {
      tags: [],
      assignTo: parseInt(assignTo),
      channelUids: [channelUids],
      channelIds: [channelId],
      assignId:
        assignTo === "0" ? [] : assignTo === "1" ? selectBot : selectMember,
      status: selectedOptions,
      unReadMessages: false,
      broadcastUids: [],
      conversationType: component === "supportInbox-tableview" ? "ACCOUNT" : "LEAD",
      businessUid: businessUid,
      limit: 10,
      page: tablePage,
      startDate: startDateFormatted,
      endDate: endDateFormatted,
    };

    try {
      const filterAPI = await getConversationsWithFilters(payload);

      if (filterAPI.data.count > 0) {
        setTableLoader!(true);
        if (setFilterCount) setFilterCount(filterAPI.data.count);

        if (filterAPI.status) {
          setTableLoader!(false);
        }

        if (filterAPI.data.messagesDto.length > 0) {
          if (setShouldFetchData) setShouldFetchData(true);
          setData((prev: any[]) => {
            return [...prev, ...filterAPI.data.messagesDto];
          });
        } else {
          if (setShouldFetchData) setShouldFetchData(false);
        }
      } else {
        if (setShouldFetchData) setShouldFetchData(false);
      }
    } catch (error) {
      if (setShouldFetchData) setShouldFetchData(false);
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (tableSetup === "table-filter") {
      if (tablePage !== undefined && tablePage > 0) {
        if (shouldFetchData) {
          getConversationBasedOnFilter();
        }
      }
    }
  }, [tablePage, tableSetup]);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange>({
    start: null,
    end: null,
  });

  const startDateFormatted =
    dateRange.start != null
      ? dateRange.start.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      : null;
  const endDateFormatted =
    dateRange.end != null
      ? dateRange.end.utc().format("YYYY-MM-DDT23:59:59.000[Z]")
      : null;

  const datepickertarget = useRef<HTMLDivElement | null>(null);

  const currentDate = new Date();
  const moment = extendMoment(originalMoment);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        datepickertarget.current &&
        !datepickertarget.current.contains(event.target as Node) &&
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        setShowDatePicker(false);
      }
    };

    if (showDatePicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDatePicker, popoverRef.current]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSelectedOptions(
      (prev) =>
        prev.includes(value)
          ? prev.filter((item) => item !== value)
          : [...prev, value]
    );
  };

  useEffect(() => {
    (window as any).ContactFilter_POPUP = showPopup;
    (window as any).ContactFilter_POPUP_HIT = showPopup ? 1 : 0;
  }, [showPopup]);

  const rerenderHandler = () => {
    if (counter < 5) setCounter((curr) => curr + 1);
  };

  const channelUids = useSelector(
    (state: any) => state.cartreducer.channelUid?.value
  );

  const businessUid = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );

  const channelId = useSelector(
    (state: any) => state.cartreducer.channelUid?.channelAllData?.id
  );

  const applyFilterHandler = async ({
    selected,
    unReadMessages,
    assignTo,
  }: {
    selected: Record<IListSourceType, any[]>;
    unReadMessages: boolean;
    assignTo: string;
  }) => {

    if ((component === "conversationReport") || (component === "conversation") || (component === "supportInbox-tableview")) {
      const AssignTo = assignTo;
      const selectedBot = selected.bot.map((str) => parseInt(str, 10));
      const selectedMember = selected.member.map((str) => parseInt(str, 10));
      const status = selectedOptions;
      (window as any).ConvFilter_isFilterApplied = true;

      if (setIsTableFilter) setIsTableFilter(true);
      setAssignTo(assignTo);
      setSelectBot(selectedBot);
      setSelectMember(selectedMember);
      setShowPopup(false);
      if (setTableSetup) setTableSetup("table-filter");

      const payload = {
        tags: [],
        assignTo: parseInt(AssignTo),
        channelUids: [channelUids],
        channelIds: [channelId],
        assignId:
          AssignTo === "0"
            ? []
            : AssignTo === "1"
            ? selectedBot
            : selectedMember,
        status: status,
        unReadMessages: false,
        broadcastUids: [],
        conversationType: component === "supportInbox-tableview" ? "ACCOUNT" : "LEAD",
        businessUid: businessUid,
        limit: 10,
        page: 0,
        startDate: startDateFormatted,
        endDate: endDateFormatted,
      };

      try {
        if (setTableLoader) setTableLoader(true);
        if (setTablePage) setTablePage(0);

        const filterAPI = await getConversationsWithFilters(payload);
        if (setFilterCount) setFilterCount(filterAPI.data.count);

        if (filterAPI.status) {
          if (setTableLoader) setTableLoader(false);
        }

        if (
          filterAPI.data.messagesDto &&
          filterAPI.data.messagesDto.length > 0
        ) {
          setData(filterAPI.data.messagesDto);

          if (filterAPI.data.messagesDto.length < 9) {
            if (setShouldFetchData) setShouldFetchData(false);
          } else {
            if (setShouldFetchData) setShouldFetchData(true);
          }
        } else {
          setData([]);
          if (setShouldFetchData) setShouldFetchData(false);
        }
      } catch (error) {
        if (setShouldFetchData) setShouldFetchData(false);
        console.log(error, "error");
      }

      const getAllPayload = {
        tags: [],
        assignTo: parseInt(AssignTo),
        channelUids: [channelUids],
        channelIds: [channelId],
        assignId:
          AssignTo === "0"
            ? []
            : AssignTo === "1"
            ? selectedBot
            : selectedMember,
        status: status,
        unReadMessages: false,
        broadcastUids: [],
        conversationType: "LEAD",
        businessUid: businessUid,
        limit: filterCount,
        page: 0,
        startDate: startDateFormatted,
        endDate: endDateFormatted,
      };

      try {
        const filterAPI = await getConversationsWithFilters(getAllPayload);

        if (setGetAllFilterContacts)
          setGetAllFilterContacts(filterAPI.data.messagesDto);
      } catch (error) {
        console.log(error, "error");
      }
    } else {
      let assignId: number[] = [];

      if (assignTo !== "0") {
        if (assignTo === "1") {
          assignId = selected.bot.map((botid) => parseInt(botid));
        } else {
          assignId = selected.member.map((memberid) => parseInt(memberid));
        }
      }
      const filterData: IFilterData = {
        tags: selected.tags.map((tagId) => ({
          id: parseInt(tagId),
        })),
        assignTo,
        channelUids: [channelUids],
        channelIds: [channelId],
        assignId,
        status: [],
        unReadMessages,
        broadcastUids: selected.campaign,
        conversationType: (component === "supportInbox" || component === "supportInbox-tableview") ? "ACCOUNT" : "LEAD",
      };
      conversationCtx.setFilter(filterData);
    }
    setShowPopup(false);
  };

  const filterSchema = yup.object().shape({
    selected: yup.object(),
    unReadMessages: yup.boolean(),
    assignTo: yup.string(),
  });

  const APPLIED_FILTERS = {
    unReadMessages:
      conversationCtx.filters.unReadMessages,
    assignTo:
      (window as any).ConvFilter_AssignTo || conversationCtx.filters.assignTo,
    selected: {
      tags:
        conversationCtx.filters.tags.length > 0
          ? conversationCtx.filters.tags.map((tag) => String(tag.id))
          : conversationCtx.tempFilters.tags,
      member:
        conversationCtx.filters.assignTo === "2"
          ? conversationCtx.filters.assignId.map((id) => String(id))
          : conversationCtx.tempFilters.member,
      bot:
        conversationCtx.filters.assignTo === "1"
          ? conversationCtx.filters.assignId.map((id) => String(id))
          : conversationCtx.tempFilters.bot,
      channel:
        conversationCtx.filters.channelUids.length > 0
          ? conversationCtx.filters.channelUids
          : conversationCtx.tempFilters.channel,
      campaign:
        conversationCtx.filters.broadcastUids.length > 0
          ? conversationCtx.filters.broadcastUids
          : conversationCtx.tempFilters.campaign,
    },
  };

  const DEFAULT_FILTER = {
    unReadMessages: false,
    assignTo: "0",
    selected: {
      tags: [],
      member: [],
      bot: [],
      channel: [],
      campaign: [],
    },
  };

  const FilterPopup = () => {
    return (
      <Popover
        className={styles.popup}
        style={{
          left: popupZoneRef.current
            ? popupZoneRef.current.getBoundingClientRect().left + window.scrollY
            : 0,
          top: popupZoneRef.current
            ? popupZoneRef.current.getBoundingClientRect().top +
              window.scrollX +
              5
            : 0,
        }}
        ref={ref}
      >
        <Formik
          validationSchema={filterSchema}
          onSubmit={applyFilterHandler}
          initialValues={APPLIED_FILTERS}
        >
          {({ handleSubmit, handleChange, values, setValues }) => {

            const containerRef = useRef<HTMLDivElement>(null);
            useEffect(() => {
              if ((window as any).ConvFilter_Scroll) {
                setTimeout(() => {
                  if (containerRef.current)
                    containerRef.current.scrollTop = (
                      window as any
                    ).ConvFilter_Scroll;
                }, 0);
              }
            }, []);
            useEventListener(
              "scroll",
              (event) => {
                (window as any).ConvFilter_Scroll =
                  containerRef.current?.scrollTop;
              },
              containerRef
            );

            return (
              <>
                {(component === "conversationReport" || component === "conversation" || component === "supportInbox-tableview") ? (
                  <>
                    <Form className={styles.form} onSubmit={handleSubmit}>
                      <Popover.Header className={styles.header}>
                        Filter
                        <Button
                          variant="link"
                          onClick={() => {
                            if (tableFilter) {
                              (window as any).ConvFilter_isFilterApplied =
                                false;
                              (window as any).ConvFilter_InitiateReset = true;
                              setShowPopup(false);
                              if (setTablePage) setTablePage(0);
                              setDateRange({
                                start: null,
                                end: null,
                              });
                              (window as any).ConvFilter_AssignTo = "0";
                              setSelectedOptions([]);
                              if (setTableSetup) setTableSetup("basic-table");
                              if (setShouldFetchData) setShouldFetchData(true);
                              if (setIsTableFilter) setIsTableFilter(false);
                              setData([]);
                            } else {
                              if (setTablePage) setTablePage(0);
                              setDateRange({
                                start: null,
                                end: null,
                              });
                              (window as any).ConvFilter_AssignTo = "0";
                              setSelectedOptions([]);
                              if (setTableSetup) setTableSetup("basic-table");
                              if (setShouldFetchData) setShouldFetchData(true);
                              if (setIsTableFilter) setIsTableFilter(false);
                              setData([]);
                            }
                          }}
                        >
                          {tableFilter ? "Reset" : "Clear"}
                        </Button>
                      </Popover.Header>
                      <div
                        className={[styles.body, "popover-body"].join(" ")}
                        ref={containerRef}
                      >
                        <div className={styles.filter_item}>
                          <div className={styles.filter_header}>
                            <div className={styles.icon}>
                              <FontAwesomeIcon icon={faAt} />
                            </div>
                            <span className={styles.caption}>Assign To</span>
                          </div>
                          <div className={styles.filter_body}>
                            <Row className="my-1">
                              <Col xs={3}>
                                <Form.Check
                                  type="radio"
                                  name="assignTo"
                                  value="1"
                                  label="Bot"
                                  disabled={isBotDisabled}
                                  checked={values.assignTo === "1"}
                                  onChange={(event) => {
                                    (window as any).ConvFilter_AssignTo = "1";
                                    handleChange(event);
                                    setSelectBot(
                                      values.selected.bot.map((str) =>
                                        parseInt(str, 10)
                                      )
                                    );
                                  }}
                                />
                              </Col>
                              {business?.role?.name !== "MEMBER" && (
                                <Col xs={3}>
                                  <Form.Check
                                    type="radio"
                                    name="assignTo"
                                    value="2"
                                    label="Member"
                                    checked={values.assignTo === "2"}
                                    onChange={(event) => {
                                      (window as any).ConvFilter_AssignTo = "2";
                                      handleChange(event);
                                      setSelectMember(
                                        values.selected.member.map((str) =>
                                          parseInt(str, 10)
                                        )
                                      );
                                    }}
                                  />
                                </Col>
                              )}
                            </Row>
                            <div
                              style={{
                                display:
                                  values.assignTo === "1"
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <FilterList
                                listSource="bot"
                                rerender={rerenderHandler}
                                infiniteLoad={false}
                                setIsBotDisabled={setIsBotDisabled}
                              />
                            </div>
                            <div
                              style={{
                                display:
                                  values.assignTo === "2" ? "block" : "none",
                              }}
                            >
                              <FilterList
                                listSource="member"
                                rerender={rerenderHandler}
                                infiniteLoad={false}
                              />
                            </div>
                          </div>
                        </div>
                        <hr />
                        <Form.Group controlId="formMultiSelect">
                          <Form.Label
                            className="fw-bold"
                            style={{ fontSize: "14px", color: "#212529" }}
                          >
                            Status
                          </Form.Label>
                          {options.map((option, index) => (
                            <Form.Check
                              key={index}
                              type="checkbox"
                              id={`status-${index}`}
                              name={`status-${index}`}
                              label={option.label}
                              value={option.value}
                              checked={selectedOptions.includes(option.value)}
                              onChange={handleCheckboxChange}
                            />
                          ))}
                        </Form.Group>
                      </div>
                      <hr />
                      <div className="pt-1 ps-3 pe-3 pb-3">
                        <div className="mb-1">
                          <span className={styles.caption}>Created Date</span>
                        </div>

                        <div>
                          {showDatePicker && (
                            <Popover
                              id="popover-basic"
                              style={{ maxWidth: "350px", marginTop: "4.7rem" }}
                              ref={popoverRef}
                            >
                              <DateRangePicker
                                value={dateRange}
                                name="dateRange"
                                minDate={currentDate}
                                maximumDate={moment().endOf("day")}
                                onSelect={(value: any) => {
                                  const currentDate = moment();
                                  let start = moment(value.start).startOf(
                                    "day"
                                  );
                                  let end = moment(value.end).endOf("day");

                                  if (start.isAfter(currentDate, "day")) {
                                    start = currentDate.clone().endOf("day");
                                  }
                                  if (end.isAfter(currentDate, "day")) {
                                    end = currentDate.clone().endOf("day");
                                  }

                                  const newMomentRange = moment.range(
                                    start,
                                    end
                                  );
                                  setDateRange(newMomentRange as any);
                                  setShowDatePicker(false);
                                }}
                                singleDateRange={true}
                              />
                            </Popover>
                          )}

                          <div
                            className={`d-${
                              dateRange.start !== null ? "flex" : "block"
                            }`}
                          >
                            <div
                              ref={datepickertarget}
                              onClick={() =>
                                setShowDatePicker((current) => !current)
                              }
                            >
                              {dateRange.start && dateRange.end ? (
                                <div
                                  className="p-2"
                                  style={{
                                    border: "2px solid #E2E2E2",
                                    borderRadius: "4px",
                                    width: "120%",
                                  }}
                                >
                                  {`${dateRange.start?.format(
                                    "DD/MM/YYYY"
                                  )} - ${dateRange.end?.format("DD/MM/YYYY")}`}
                                </div>
                              ) : (
                                <div
                                  className="p-2"
                                  style={{
                                    color: "rgb(128 130 132)",
                                    border: "2px solid #E2E2E2",
                                    borderRadius: "4px",
                                    width: "95%",
                                  }}
                                >
                                  DD/MM/YYYY-DD/MM/YYYY
                                </div>
                              )}
                            </div>
                            {dateRange.start !== null && (
                              <div
                                onClick={() => {
                                  setDateRange({
                                    start: null,
                                    end: null,
                                  });
                                }}
                                className="ms-5 d-flex justify-content-center align-items-center"
                              >
                                <IoIosCloseCircleOutline
                                  size={17}
                                  color="#666e7d"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className={styles.footer}>
                        <Button
                          className="cancelButton"
                          size="sm"
                          color="primary"
                          type="button"
                          onClick={() => setShowPopup(false)}
                        >
                          Cancel
                        </Button>
                        {(values.assignTo != "0" ||
                          selectedOptions.length > 0 ||
                          dateRange.start != null) && (
                          <Button
                            type="submit"
                            size="sm"
                            color="primary"
                            className="sendButton w-25 centerItems"
                          >
                            Apply
                          </Button>
                        )}
                      </div>
                    </Form>
                  </>
                ) : (
                  <Form className={styles.form} onSubmit={handleSubmit}>
                    <Popover.Header className={styles.header}>
                      Filter
                      <div className="d-flex align-items-center">
                        {(values.unReadMessages === true ||
                          values.selected.tags.length > 0 ||
                          values.selected.campaign.length > 0 ||
                          (values.assignTo != "0" &&
                            (values.selected.bot.length > 0 ||
                              values.selected.member.length > 0))) && (
                          <Button
                            variant="link"
                            className="p-0 me-2"
                            onClick={(e: any) => {
                              (window as any).ConvFilter_AssignTo = "0";
                              conversationCtx.tempFilters = {
                                tags: [],
                                member: [],
                                bot: [],
                                channel: [],
                                campaign: [],
                              };
                              conversationCtx.filters.tags.length = 0;
                              conversationCtx.filters.assignId.length = 0;
                              conversationCtx.filters.unReadMessages = false;
                              setValues(DEFAULT_FILTER);
                            }}
                          >
                            Clear
                          </Button>
                        )}
                        {conversationCtx.isFilterApplied && (
                          <Button
                            size="sm"
                            color="primary"
                            className="sendButton w-100 h-20 centerItems"
                            style={{
                              fontSize: "12px",
                              padding: "5px",
                              height: "100%",
                            }}
                            onClick={(e: any) => {
                              conversationCtx.clearFilter();
                              setValues(APPLIED_FILTERS);
                            }}
                          >
                            Reset
                          </Button>
                        )}
                      </div>
                    </Popover.Header>
                    <div
                      className={[styles.body, "popover-body"].join(" ")}
                      ref={containerRef}
                    >
                      <Form.Check
                        type="checkbox"
                        label="Unread Chats"
                        name="unReadMessages"
                        onChange={(event) => {
                          (window as any).ConvFilter_isFilterApplied =
                            event.target.checked;
                          handleChange(event);
                        }}
                        checked={values.unReadMessages}
                      />
                      <div className={styles.filter_item}>
                        <div className={styles.filter_body}>
                          <FilterList
                            listSource="tags"
                            rerender={rerenderHandler}
                            infiniteLoad={false}
                          >
                            <hr />
                            <div className={styles.filter_header}>
                              <div className={styles.icon}>
                                <FontAwesomeIcon icon={faTag} />
                              </div>
                              <span className={styles.caption}>Tags</span>
                            </div>
                          </FilterList>
                        </div>
                      </div>
                      <hr />
                      <div className={styles.filter_item}>
                        <div className={styles.filter_header}>
                          <div className={styles.icon}>
                            <FontAwesomeIcon icon={faAt} />
                          </div>
                          <span className={styles.caption}>Assign To</span>
                        </div>
                        <div className={styles.filter_body}>
                          <Row className="my-1">
                            <Col xs={3}>
                              <Form.Check
                                type="radio"
                                name="assignTo"
                                value="1"
                                label="Bot"
                                disabled={isBotDisabled}
                                checked={values.assignTo === "1"}
                                onChange={(event) => {
                                  (window as any).ConvFilter_AssignTo = "1";
                                  handleChange(event);
                                  setSelectBot(
                                    values.selected.bot.map((str) =>
                                      parseInt(str, 10)
                                    )
                                  );
                                }}
                              />
                            </Col>
                            {business?.role?.name !== "MEMBER" && (
                              <Col xs={3}>
                                <Form.Check
                                  type="radio"
                                  name="assignTo"
                                  value="2"
                                  label="Member"
                                  checked={values.assignTo === "2"}
                                  onChange={(event) => {
                                    (window as any).ConvFilter_AssignTo = "2";
                                    handleChange(event);
                                    setSelectMember(
                                      values.selected.member.map((str) =>
                                        parseInt(str, 10)
                                      )
                                    );
                                  }}
                                />
                              </Col>
                            )}
                          </Row>
                          <div
                            style={{
                              display:
                                values.assignTo === "1" ||
                                business?.role?.name === "MEMBER"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <FilterList
                              listSource="bot"
                              rerender={rerenderHandler}
                              infiniteLoad={false}
                              setIsBotDisabled={setIsBotDisabled}
                            />
                          </div>
                          <div
                            style={{
                              display:
                                values.assignTo === "2" ? "block" : "none",
                            }}
                          >
                            <FilterList
                              listSource="member"
                              rerender={rerenderHandler}
                              infiniteLoad={false}
                            />
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className={styles.filter_item}>
                        <div className={styles.filter_body}>
                          <FilterList
                            listSource="campaign"
                            rerender={rerenderHandler}
                            infiniteLoad={false}
                          >
                            <div className={styles.filter_header}>
                              <div className={styles.icon}>
                                <FontAwesomeIcon icon={faBullhorn} />
                              </div>
                              <span className={styles.caption}>Campaign</span>
                            </div>
                          </FilterList>
                        </div>
                      </div>
                    </div>
                    <div className={styles.footer}>
                      <Button
                        className="cancelButton"
                        size="sm"
                        color="primary"
                        type="button"
                        onClick={() => setShowPopup(false)}
                      >
                        Cancel
                      </Button>

                      {(values.unReadMessages === true ||
                        values.selected.tags.length > 0 ||
                        values.selected.campaign.length > 0 ||
                        (values.assignTo != "0" &&
                          (values.selected.bot.length > 0 ||
                            values.selected.member.length > 0))) && (
                        <Button
                          type="submit"
                          size="sm"
                          color="primary"
                          className="sendButton w-25 centerItems"
                        >
                          Apply
                        </Button>
                      )}
                    </div>
                  </Form>
                )}
              </>
            );
          }}
        </Formik>
      </Popover>
    );
  };

  const ref = React.useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {showPopup ? createPortal(<FilterPopup />, document.body) : null}

      <div
        className={styles.wrapper}
        ref={ref}
        id={filterDisable ? "filterDisable" : "filterAble"}
      >
        <div
          className={`${
            showPopup ? "filterIcon-active" : ""
          } filterIcon  ml-1 me-1`}
          onClick={() => {
            if (showPopup) {
              setShowPopup(false);
            } else {
              setShowPopup(true);
            }
          }}
          style={{
            padding: "0.65rem",
            pointerEvents: showPopup ? "none" : "all",
          }}
        >
          {conversationCtx.isFilterApplied ? (
            <div className={styles.dot}></div>
          ) : null}
          <LuFilter
            color="#666E7D"
            size={20}
            fill={
              showPopup || tableSetup === "table-filter" ? "#666E7D" : "none"
            }
          />
        </div>
        <div ref={popupZoneRef}></div>
      </div>
    </>
  );
};

export default React.memo(FilterConversations);
import { createSlice } from '@reduxjs/toolkit';
import { addEdge, applyEdgeChanges, applyNodeChanges, MarkerType } from 'reactflow';

import { uuidv4 } from '../utils/uuid';
import { FormData as ISendTemplateFormData } from '../components/designFlow/editor/sendTemplate';
import { IVariable, Variable } from '../entity/Variable';

// Datatype for flow elements stored in backend
export interface BackendFlow {
    elementType: 'start' | 'block';
    uid: string;
    id: number,
    targetId: string | null;
    referenceIds: string | null;
    elementData: {
        type: string;
        [key: string]: any;
    } | null;
    point: {
        x: number;
        y: number;
    }
};


// Transformed datatype for flow elements stored in frontend (https://reactflow.dev/api-reference/types/node)
export interface INode {
    id: string;
    uid: string;
    backendId?: number,
    type: string;
    position: {
        x: number;
        y: number;
    };
    data: null | any;
    created: boolean;
    selected?: boolean;
};


// React flow edge (https://reactflow.dev/api-reference/types/edge)
interface IEdge {
    type: string;
    source: string;
    sourceHandle: string;
    target: string;
    targetHandle: string;
    id: string;
    markerEnd?: any;
    style?: any;
    animated?: boolean;
};

type IViewport = {
    x: 0,
    y: 0,
    zoom: 0
};

interface State {
    lastEdited: string | null; // last edited timestap for autosave
    nodes: INode[];
    edges: IEdge[];
    readOnly: boolean;
    // update/delete queue
    updatedElements: {
        id?: number;
        uid: string;
        type: 'Dragged' | 'Updated' | 'Created' | 'Deleted';
    }[];
    // React flow viewport (https://reactflow.dev/api-reference/types/viewport)
    viewport: IViewport,
    deletedElements: INode[];
    variables: IVariable[];
};

const initialState: State = {
    lastEdited: null,
    nodes: [],
    edges: [],
    deletedElements: [],
    updatedElements: [],
    variables: [],
    viewport: { x: 0, y: 0, zoom: 0 },
    readOnly: false
};


function scanVariables(state: State): State {
    let updatedState = {
        ...state,
        nodes: [...state.nodes],
        variables: [...state.variables]
    };

    // Rescan variables for ask_type
    updatedState.variables = [];

    updatedState.nodes.forEach(node => {
        const addToVariables = (variable: IVariable) => {
            if (variable.name.trim() === "") {
                return;
            }
            const index = updatedState.variables.findIndex(vrb => vrb.name === variable.name);
            if (index < 0) {
                variable.source = [node.uid];
                updatedState.variables.push(variable);
            } else {
                if (!updatedState.variables[index].source) {
                    updatedState.variables[index].source = [];
                }
                updatedState.variables[index].source?.push(node.uid);
            }
        }
        if (node) {
            if (node.type?.search('ask_type/') === 0 || node.type === 'send_list' || node.type === 'send_reply_buttons'
                || node.type === 'ask_address' || node.type === 'ask_location' || node.type === 'ask_keyword_options'
                || node.type === "ask_currentLocation" || node.type === 'appointment_Flow' || node.type === 'create_Ticket') {
                const variable = node.data.storageVariable;
                if (variable && variable.name.trim() !== '') {
                    addToVariables({
                        name: variable.name,
                        type: variable.type,
                        specialType: node.type == 'ask_type/ask_location' ? 'location' : node.type === 'appointment_Flow' ? 'appointment_Flow' : null,
                    });
                }
            }
            else if(node.type === "integration/GoogleSheets" && node?.data.integrationData.action === 'fetchRecord') {
                const rowValues = node.data.integrationData.rowValues?.slice(1);

                rowValues?.map((rowValue: any) => {
                    addToVariables({
                        name: rowValue,
                        type: 'TEXT',
                        specialType: "integration/GoogleSheets"
                    });
                });    
            }
            else if(node.type === "integration/RazorPay") {
                const variable = node.data.storageVariable;
                if (variable && variable.name.trim() !== '') {
                    addToVariables({
                        name: variable.name,
                        type: variable.type,
                        specialType: null
                    });
                    if(variable?.paymentId) {
                        addToVariables({
                            name: variable.paymentId,
                            type: variable.type,
                            specialType: null
                        });
                    }
                }
            }
            else if(node.type === "integration/OpenAI") {
                const variable = node.data.storageVariable;
                if (variable && variable.name.trim() !== '') {
                    addToVariables({
                        name: variable.name,
                        type: variable.type,
                        specialType: null
                    });
                }
            }
            else if (node.type === 'set_variable') {
                node.data.variables.forEach((variable: IVariable) => {
                    addToVariables({
                        name: variable.name,
                        type: variable.type,
                        specialType: null,
                    });
                })
            } else if (node.type === 'api_call') {
                node.data.variables.forEach((variable: IVariable) => {
                    addToVariables({
                        name: variable.name,
                        type: variable.type,
                        specialType: null,
                    });
                })
            }
        }
    });
    return updatedState;
}

export function transformBackendFlow(flow: BackendFlow, viewport: IViewport, readOnly: boolean): INode {
    const xPos = flow.point.x - (viewport.x / 2);
    let prefix = {
        id: flow.uid,
        uid: flow.uid,
        created: true,
        backendId: flow.id,
        position: { x: xPos, y: flow.point.y },
        type: flow.elementData?.type,
        noUpdate: readOnly
    };
    let node: any = {
        ...prefix,
        data: {
            type: flow.elementData?.type,
            ...flow.elementData
        }
    };

    if (flow.elementType == 'start') {
        node = ({
            ...prefix,
            type: 'startNode',
            data: {
                type: 'start'
            },
        });
    } else if (flow.elementType == 'block' && flow.elementData) {
        if (flow.elementData.type == 'integration') {
            const integrationData = { ...flow.elementData.integrationData };

            // if (integrationData.app === 'Shopify' && (node?.data?.integrationData?.action === "createOrder" || node?.data?.integrationData?.action === "createDraftOrder")) {
            //     integrationData.successTargetId = flow.elementData.successTargetId;
            //     integrationData.failTargetId = flow.elementData.failTargetId;
            // }

            // if (integrationData.app === 'GoogleSheets' || integrationData.app === "Woocommerce") {
            //     integrationData.successTargetId = flow.elementData.successTargetId;
            //     integrationData.failTargetId = flow.elementData.failTargetId;
            // }

            node = ({
                ...prefix,
                type: 'integration/' + integrationData.app,
                data: {
                    ...node.data,
                    integrationData: integrationData
                    ,
                    storageVariable: flow.elementData.storageVariable
                },
            });
        }
        if (flow.elementData.type == 'send_text') {
            node = ({
                ...prefix,
                data: {
                    ...node.data,
                    message: flow.elementData.text,
                    attempt: flow.elementData.attempt,
                    // delay: flow.elementData.delay,
                },
            });
        }
        if (flow.elementData.type == 'open_ai') {
            node = ({
                ...prefix,
                data: {
                    ...node.data,
                    gptData: {
                        openAiUid: flow.elementData.gptData.openAiUid
                    }
                },
            });
        }
        else if (flow.elementData.type == 'send_reply_buttons') {
            const dynamicFields = (() => {
                let iterateVariable: IVariable
                try {
                    iterateVariable = Variable.fromString(flow.elementData.dynamicFields.iterateVariable, 'ARRAY')
                } catch (error) {
                    iterateVariable = { name: '' } as IVariable
                }
                return {
                    ...flow.elementData.dynamicFields,
                    iterateVariable
                }
            })()
            node = ({
                ...prefix,
                data: {
                    ...node.data,
                    bodyText: flow.elementData.bodyText || '',
                    footerText: flow.elementData.footer || '',
                    attempt: flow.elementData.attempt,
                    header: flow.elementData.header ? {
                        headerType: flow.elementData.header.headerType || null,
                        headerText: flow.elementData.header.headerText || ''
                    } : {
                        headerType: 'none',
                    },
                    endFlow: flow.elementData.endFlow,
                    headerText: flow.elementData.header && flow.elementData.header.headerText ? flow.elementData.header.headerText : '',
                    mediaUrl: flow.elementData.mediaUrl || '',
                    media: flow.elementData.media,
                    options: flow.elementData.options.map((option: any, i: number): any => {
                        if (option.buttonMessage.id) {
                            const id = option.buttonMessage.id.split(':')[0]
                            const order = option.buttonMessage.id.split(':')[1]
                            return {
                                id: isNaN(id) ? id : null,
                                uid: uuidv4(),
                                optionText: option.buttonMessage.title,
                                order: parseInt(order)
                            };
                        }
                        return {
                            id: null,
                            uid: uuidv4(),
                            optionText: option.buttonMessage.title,
                            order: i
                        };
                    }),
                    dynamic: flow.elementData.dynamic,
                    dynamicFields,
                },
            });
        }
        else if (flow.elementData.type == 'set_variable') {
            node = ({
                ...prefix,
                data: {
                    ...node.data,
                    variables: flow.elementData.variables.map((variable: IVariable) => {
                        return {
                            ...variable,
                            id: uuidv4()
                        }
                    })
                },
            });
        }
        else if (flow.elementData.type == 'jump_to') {
            node = ({
                ...prefix,
                data: {
                    ...node.data,
                    jumpTo: flow.elementData.jumpTo
                },
            });
        }
        else if (flow.elementData.type == 'switchCondition') {
            node = ({
                ...prefix,
                data: {
                    ...node.data,
                    attempt: flow.elementData.attempt,
                    switchOperation: {
                        input: flow.elementData.switchOperation.input,
                        list: flow.elementData.switchOperation.list.map((option: any, i: number): any => {
                            if (option.id) {
                                return {
                                    id: option.id,
                                    title: option.title,
                                    order: i + 1
                                };
                            }
                            return {
                                id: uuidv4(),
                                title: option.title,
                                order: i + 1
                            };
                        }),
                        defaultTargetId: flow.elementData.switchOperation.defaultTargetId || uuidv4()
                    }
                },
            });
        }
        else if (flow.elementData.type == 'condition') {
            node = ({
                ...prefix,
                data: {
                    ...node.data,
                    conditionOperation: {
                        ruleStatus: `${flow.elementData.conditionOperation.ruleStatus}`,
                        rules: flow.elementData.conditionOperation.rules.map((rules: any): any => {
                            return {
                                id: uuidv4(),
                                ...rules
                            };
                        }),
                        successTargetId: flow.elementData.conditionOperation.successTargetId,
                        failTargetId: flow.elementData.conditionOperation.failTargetId,
                    }
                },
            });
        }
        else if (flow.elementData.type == 'actions' && flow.elementData.subType == 'assign_conversation') {
            node = ({
                ...prefix,
                type: "actions/assign_conversation",
                data: {
                    ...node.data,
                    conversation: {
                        type: flow.elementData.conversation.type,
                        id: flow.elementData.conversation.id,
                        roundRobin: flow.elementData.conversation.roundRobin,
                        memberId: flow.elementData.conversation.memberId
                    }
                },
            });
        }
        else if (flow.elementData.type == 'actions' && flow.elementData.subType == 'unAssign_converation') {
            node = ({
                ...prefix,
                type: "actions/unAssign_converation",
                data: {
                    ...node.data
                },
            });
        }
        else if (flow.elementData.type == 'actions' && flow.elementData.subType == 'resolve_conversation') {
            node = ({
                ...prefix,
                type: "actions/resolve_conversation",
                data: {
                    ...node.data
                },
            });
        }
        else if (flow.elementData.type == 'actions' && flow.elementData.subType == 'update_contact' && flow.elementData.contactUpdateType == 'update_contactTags') {
            node = ({
                ...prefix,
                type: "actions/update_contactTags",
                data: {
                    ...node.data,
                    contact: {
                        ...node.data.contact,
                        tags: node.data.contact.tags ? node.data.contact.tags.map((tag: any) => {
                            return {
                                uid: uuidv4(),
                                ...tag
                            };
                        }) : []
                    }
                },
            });
        }
        else if (flow.elementData.type == 'actions' && flow.elementData.subType == 'update_contact') {
            node = ({
                ...prefix,
                type: "actions/update_contact",
                data: {
                    ...node.data,
                    contact: {
                        ...node.data.contact,
                        tags: node.data.contact.tags ? node.data.contact.tags.map((value: string) => {
                            return {
                                id: uuidv4(),
                                value
                            };
                        }) : []
                    }
                },
            });
        }
        else if (flow.elementData.type == 'actions' && flow.elementData.subType == 'update_conversationStatus') {
            node = ({
                ...prefix,
                type: "actions/update_conversationStatus",
                data: {
                    ...node.data,
                    conversation: {
                        ...node.data.conversation,
                    }
                },
            });
        }
        else if (flow.elementData.type == 'trigger_Sequence') {
            node = ({
                ...prefix,
                type: "actions/trigger_Sequence",
                data: {
                    ...node.data,
                    sequenceUid: flow.elementData.sequenceUid
                },
            });
        }
        else if (flow.elementData.type == 'set_member') {
            node = ({
                ...prefix,
                type: "actions/set_member",
                data: {
                    ...node.data,
                    memberUids: flow.elementData.memberUids,
                    memberDTO: node.data.memberDTO
                },
            });
        }
        else if (flow.elementData.type == 'notes') {
            node = ({
                ...prefix,
                type: "actions/notes",
                data: {
                    ...node.data,
                    text: flow.elementData.text
                },
            });


        }
        else if (flow.elementData.type == 'send_media') {
            node = ({
                ...prefix,
                data: {
                    ...node.data,
                    media: null,
                    mediaUrl: '',
                    ...flow.elementData,
                },
            });
        }
        else if (flow.elementData.type == 'send_location') {
            node = ({
                ...prefix,
                data: {
                    ...node.data,
                    type: 'send_location',

                },
            });
        }
        else if (flow.elementData.type == 'send_wa_template') {
            const elementData: ISendTemplateFormData = flow.elementData as unknown as ISendTemplateFormData;
            if (elementData.template) {
                node = ({
                    ...prefix,
                    data: {
                        ...node.data,
                        ...elementData,
                        templateNodes: (elementData.templateNodes && Object.keys(elementData.templateNodes).length > 0) ? elementData.templateNodes : null
                    },
                });
            }
        }
        else if (flow.elementData.type == 'send_list') {
            const dynamicFields = (() => {
                let iterateVariable: IVariable
                try {
                    iterateVariable = Variable.fromString(flow.elementData.dynamicFields.iterateVariable, 'ARRAY')
                } catch (error) {
                    iterateVariable = { name: '' } as IVariable
                }
                return {
                    ...flow.elementData.dynamicFields,
                    iterateVariable
                }
            })()
            node = ({
                ...prefix,
                data: {
                    ...node.data,
                    storageVariable: "storageVariable" in node.data && node.data.storageVariable ? node.data.storageVariable : {
                        name: "",
                        type: "TEXT"
                    },
                    endFlow: flow.elementData.endFlow,
                    bodyText: flow.elementData.bodyText || '',
                    headerText: flow.elementData.header ? flow.elementData.header.headerText : '',
                    footerText: flow.elementData.footer || '',
                    attempt: flow.elementData.attempt,
                    options: flow.elementData?.options?.map((option: any) => {
                        const id = option.id.split(':')[0]
                        const order = option.id.split(':')[1]
                        return {
                            id,
                            order: parseInt(order),
                            optionTitle: option.title,
                            list: option.listMessage.map((listItem: any, i: number) => {
                                return {
                                    id: listItem.id,
                                    uid: uuidv4(),
                                    title: listItem.title,
                                    description: listItem.description,
                                };
                            })
                        }
                    }),
                    dynamic: flow.elementData.dynamic,
                    dynamicFields
                },
            });
        }
        else if (flow.elementData.type == 'ask_keyword_options') {
            const dynamicFields = (() => {
                let iterateVariable: IVariable
                try {
                    iterateVariable = Variable.fromString(flow.elementData.dynamicFields.iterateVariable, 'ARRAY')
                } catch (error) {
                    iterateVariable = { name: '' } as IVariable
                }
                return {
                    ...flow.elementData.dynamicFields,
                    iterateVariable
                }
            })()
            node = ({
                ...prefix,
                data: {
                    ...node.data,
                    storageVariable: "storageVariable" in node.data && node.data.storageVariable ? node.data.storageVariable : {
                        name: "",
                        type: "TEXT"
                    },
                    endFlow: flow.elementData.endFlow,
                    text: flow.elementData.text || '',
                    attempt: flow.elementData.attempt,
                    options: flow.elementData.options.map((option: any, i: number) => {
                        if (option.id) {
                            return {
                                id: option.id,
                                order: i + 1,
                                title: option.title,
                                keyword: option.keyword
                            }
                        }
                        return {
                            id: uuidv4(),
                            order: i + 1,
                            title: option.title,
                            keyword: option.keyword
                        }
                    }),
                    dynamic: flow.elementData.dynamic,
                    dynamicFields
                },
            });
        }
        else if (flow.elementData.type == 'delay') {
            node = ({
                ...prefix,
                id: flow.uid,
                uid: flow.uid,
                type: flow.elementData.type,
                position: { x: flow.point.x, y: flow.point.y },
                data: {
                    ...node.data,
                    delay: flow.elementData.delay,
                    delayOperation: {
                        completeId: flow.elementData.delayOperation.completeId,
                        interruptId: flow.elementData.delayOperation.interruptId,
                    }
                },
                created: true
            });
        }
        else if (flow.elementData.type == 'ask_type' && flow.elementData.subType == 'ask_text') {
            node = ({
                ...prefix,
                type: 'ask_type/ask_text',
                data: {
                    ...node.data,
                    text: flow.elementData.text,
                    endFlow: flow.elementData.endFlow,
                    attempt: flow.elementData.attempt,
                    errorMessage: flow.elementData.errorMessage,
                    validation: flow.elementData.validation,
                    storageVariable: flow.elementData.storageVariable,
                },
            });
        }

        else if (flow.elementData.type == 'ask_type' && flow.elementData.subType == 'ask_number') {
            node = ({
                ...prefix,
                type: 'ask_type/ask_number',
                data: {
                    ...node.data,
                    text: flow.elementData.text,
                    endFlow: flow.elementData.endFlow,
                    attempt: flow.elementData.attempt,
                    errorMessage: flow.elementData.errorMessage,
                    validation: flow.elementData.validation,
                    storageVariable: flow.elementData.storageVariable,
                },
            });
        }
        else if (flow.elementData.type == 'ask_type' && flow.elementData.subType == 'ask_email') {
            node = ({
                ...prefix,
                type: 'ask_type/ask_email',
                data: {
                    ...node.data,
                    text: flow.elementData.text,
                    endFlow: flow.elementData.endFlow,
                    attempt: flow.elementData.attempt,
                    errorMessage: flow.elementData.errorMessage,
                    storageVariable: flow.elementData.storageVariable,
                },
            });
        }
        else if (flow.elementData.type == 'ask_type' && flow.elementData.subType == 'ask_date') {
            node = ({
                ...prefix,
                type: 'ask_type/ask_date',
                data: {
                    ...node.data,
                    text: flow.elementData.text,
                    endFlow: flow.elementData.endFlow,
                    attempt: flow.elementData.attempt,
                    errorMessage: flow.elementData.errorMessage,
                    validation: flow.elementData.validation,
                    storageVariable: flow.elementData.storageVariable,
                },
            });
        }
        else if (flow.elementData.type == 'ask_type' && flow.elementData.subType == 'ask_url') {
            node = ({
                ...prefix,
                type: 'ask_type/ask_url',
                data: {
                    ...node.data,
                    text: flow.elementData.text,
                    endFlow: flow.elementData.endFlow,
                    attempt: flow.elementData.attempt,
                    errorMessage: flow.elementData.errorMessage,
                    validation: flow.elementData.validation,
                    storageVariable: flow.elementData.storageVariable,
                },
            });
        }
        else if (flow.elementData.type == 'ask_type' && flow.elementData.subType == 'ask_file') {
            node = ({
                ...prefix,
                type: 'ask_type/ask_file',
                data: {
                    ...node.data,
                    text: flow.elementData.text,
                    endFlow: flow.elementData.endFlow,
                    attempt: flow.elementData.attempt,
                    errorMessage: flow.elementData.errorMessage,
                    storageVariable: flow.elementData.storageVariable,
                },
            });
        }
        else if (flow.elementData.type == 'ask_type' && flow.elementData.subType == 'ask_location') {
            node = ({
                ...prefix,
                type: 'ask_type/ask_location',
                data: {
                    ...node.data,
                    text: flow.elementData.text,
                    endFlow: flow.elementData.endFlow,
                    attempt: flow.elementData.attempt,
                    errorMessage: flow.elementData.errorMessage,
                    storageVariable: flow.elementData.storageVariable,
                },
            });
        }
        else if (flow.elementData.type == 'flow') {
            node = ({
                ...prefix,
                type: 'flow',
                data: {
                    ...node.data,
                    bodyText: flow.elementData.bodyText,
                    flowData: {
                        ...flow.elementData.flowData,
                        memberUids: (() => {
                            if (Array.isArray(flow.elementData.flowData.memberUids)) {
                                return flow.elementData.flowData.memberUids.map((memberUid: string) => ({
                                    uid: memberUid,
                                    id: uuidv4()
                                }));
                            }
                            return [];
                        })(),
                        screenName: flow.elementData.flowData.screenName
                    }
                },
            });
        }
        else if (flow.elementData.type == 'appointment_confirmation') {
            node = ({
                ...prefix,
                type: 'appointment_confirmation',
                data: {
                    ...node.data,
                    storageVariable: flow.elementData.storageVariable,
                    successTargetId: flow.elementData.successTargetId,
                    failTargetId: flow.elementData.failTargetId,
                },
            });
        }
        else if (flow.elementData.type == 'appointment_Flow') {
            node = ({
                ...prefix,
                type: 'appointment_Flow',
                data: {
                    ...node.data,
                    bodyText: flow.elementData.bodyText,
                    flowData: {
                        ...flow.elementData.flowData,
                        memberUids: (() => {
                            if (Array.isArray(flow.elementData.flowData.memberUids)) {
                                return flow.elementData.flowData.memberUids.map((memberUid: string) => ({
                                    uid: memberUid,
                                    id: uuidv4()
                                }));
                            }
                            return [];
                        })(),
                        screenName: flow.elementData.flowData.screenName
                    }
                },
            });
        }
        else if (flow.elementData.type == 'send_Flow') {
            node = ({
                ...prefix,
                type: 'send_Flow',
                data: {
                    ...node.data,
                    bodyText: flow.elementData.bodyText,
                    flowData: {
                        ...flow.elementData.flowData,
                        memberUids: (() => {
                            if (Array.isArray(flow.elementData.flowData.memberUids)) {
                                return flow.elementData.flowData.memberUids.map((memberUid: string) => ({
                                    uid: memberUid,
                                    id: uuidv4()
                                }));
                            }
                            return [];
                        })()
                    }
                },
            });
        }

        else if (node.type === 'send_product') {
            return ({
                ...prefix,
                type: 'send_product',
                data: {
                    ...node.data,
                    ...flow.elementData,
                }
            });
        }
        else if (node.type === 'send_OrderDetails') {

            const dynamicFields = (() => {
                let iterateVariable: IVariable
                try {
                    iterateVariable = Variable.fromString(flow.elementData.dynamicFields.iterateVariable, 'ARRAY')
                } catch (error) {
                    iterateVariable = { name: '' } as IVariable
                }
                return {
                    ...flow.elementData.dynamicFields,
                    iterateVariable
                }
            })()


            return ({
                ...prefix,
                type: 'send_OrderDetails',
                data: {
                    ...node.data,
                    ...flow.elementData,
                    digitalItems: flow.elementData.digitalItems.map((p: any) => ({
                        uid: uuidv4(),
                        ...p
                    })),
                    dynamicFields,
                    media: node.data.media,
                    header: { headerType: node.data.headerType },
                    successTargetId: flow.elementData.successTargetId,
                    failTargetId: flow.elementData.failTargetId,
                    tax: node.data.tax,
                    shipping: node.data.shipping,
                    discount: node.data.discount,
                }
            });
        }
        else if (flow.elementData.type == 'api_call') {
            const reverseTransform = (obj: { [key: string]: string } | null): { id: string; key: string; value: string }[] | null => {
                if (obj === null) return [];
                return Object.keys(obj).map((key) => {
                    return {
                        id: uuidv4(),
                        key: key,
                        value: obj[key],
                    };
                });
            }
            node = ({
                ...prefix,
                type: 'api_call',
                data: {
                    ...node.data,
                    apiData: {
                        ...flow.elementData.apiData,
                        headers: reverseTransform(flow.elementData.apiData.headers),
                        params: reverseTransform(flow.elementData.apiData.params),
                        // body: reverseTransform(flow.elementData.apiData.body),
                    },
                    variables: flow.elementData.variables.map((variable: any) => {
                        return {
                            ...variable,
                            id: uuidv4()
                        };
                    })
                },
            });
        }
    }
    return node as INode;
}

export const graphSlice = createSlice({
    name: 'graph',
    initialState,
    reducers: {
        setViewport: (state, action: { payload: IViewport }) => {
            return {
                ...state,
                viewport: action.payload
            };
        },
        createVariable: (state, action: { payload: any }) => {
            return {
                ...state,
                variables: [
                    ...state.variables,
                    action.payload
                ]
            };
        },
        init: (state, action: { payload: { flowData: BackendFlow[], readOnly?: boolean } }) => {
            let updatedState: State = {
                ...state,
                edges: [],
                nodes: [],
                lastEdited: null,
                updatedElements: [],
                variables: [],
                readOnly: Boolean(action.payload.readOnly)
            };
            const specialEdgesId: { source: string, edgeId: string, sourceEdge: string }[] = [];
            action.payload.flowData.forEach(flow => {
                // Create node
                updatedState.nodes.push(transformBackendFlow(flow, state.viewport, Boolean(action.payload.readOnly)));

                // Handle special case
                if (flow.elementData) {
                    if (flow.elementData.type == 'api_call') {
                        if (flow.elementData.apiData.successTargetId)
                            specialEdgesId.push({
                                edgeId: flow.elementData.apiData.successTargetId,
                                source: flow.uid,
                                sourceEdge: 'api_call/success'
                            });
                        if (flow.elementData.apiData.failTargetId)
                            specialEdgesId.push({
                                edgeId: flow.elementData.apiData.failTargetId,
                                source: flow.uid,
                                sourceEdge: 'api_call/failed'
                            });

                    }
                    else if (flow.elementData.type == 'send_OrderDetails') {
                        if (flow.elementData.successTargetId)
                            specialEdgesId.push({
                                edgeId: flow.elementData.successTargetId,
                                source: flow.uid,
                                sourceEdge: 'send_OrderDetails/success'
                            });
                        if (flow.elementData.failTargetId)
                            specialEdgesId.push({
                                edgeId: flow.elementData.failTargetId,
                                source: flow.uid,
                                sourceEdge: 'send_OrderDetails/failed'
                            });
                    }
                    else if (flow.elementData.type == 'integration') {
                        if ((flow.elementData?.integrationData?.action === "createOrder" || flow.elementData?.integrationData?.action === "createDraftOrder") && flow.elementData?.integrationData.successTargetId)
                            specialEdgesId.push({
                                edgeId: flow.elementData?.integrationData?.successTargetId,
                                source: flow.uid,
                                sourceEdge: 'integration/Shopify/success'
                            });
                        if ((flow.elementData?.integrationData?.action === "createOrder" || flow.elementData?.integrationData?.action === "createDraftOrder") && flow.elementData?.integrationData.failTargetId)
                            specialEdgesId.push({
                                edgeId: flow.elementData?.integrationData?.failTargetId,
                                source: flow.uid,
                                sourceEdge: 'integration/Shopify/failed'
                            });
                        if (flow.elementData?.integrationData?.action === "addNewRow" && flow.elementData?.integrationData.successTargetId)
                            specialEdgesId.push({
                                edgeId: flow.elementData?.integrationData?.successTargetId,
                                source: flow.uid,
                                sourceEdge: 'integration/GoogleSheets/success'
                            });
                        if (flow.elementData?.integrationData?.action === "addNewRow" && flow.elementData?.integrationData.failTargetId)
                            specialEdgesId.push({
                                edgeId: flow.elementData?.integrationData?.failTargetId,
                                source: flow.uid,
                                sourceEdge: 'integration/GoogleSheets/failed'
                            });
                            if ((flow.elementData?.integrationData?.action === "addMetaData" || flow.elementData?.integrationData?.action === "addNotes") && flow.elementData?.integrationData.successTargetId)
                            {
                                specialEdgesId.push({
                                    edgeId: flow.elementData?.integrationData?.successTargetId,
                                    source: flow.uid,
                                    sourceEdge: 'integration/Woocommerce/success'
                                });
                            }
                            if ((flow.elementData?.integrationData?.action === "addMetaData" || flow.elementData?.integrationData?.action === "addNotes") && flow.elementData?.integrationData.failTargetId)
                                specialEdgesId.push({
                                    edgeId: flow.elementData?.integrationData?.failTargetId,
                                    source: flow.uid,
                                    sourceEdge: 'integration/Woocommerce/failed'
                            });
                            if ((flow.elementData?.integrationData?.action === "chatCompletionKnowledgeBase") && flow.elementData?.integrationData.successTargetId)
                                {
                                    specialEdgesId.push({
                                        edgeId: flow.elementData?.integrationData?.successTargetId,
                                        source: flow.uid,
                                        sourceEdge: 'integration/OpenAI/success'
                                    });
                            }
                            if ((flow.elementData?.integrationData?.action === "chatCompletionKnowledgeBase") && flow.elementData?.integrationData.failTargetId)
                                {
                                    specialEdgesId.push({
                                        edgeId: flow.elementData?.integrationData?.failTargetId,
                                        source: flow.uid,
                                        sourceEdge: 'integration/OpenAI/failed'
                                    });
                            }
                    }
                    else if (flow.elementData.type == 'appointment_confirmation') {
                        if (flow.elementData.successTargetId)
                            specialEdgesId.push({
                                edgeId: flow.elementData.successTargetId,
                                source: flow.uid,
                                sourceEdge: 'appointment_confirmation/success'
                            });
                        if (flow.elementData.failTargetId)
                            specialEdgesId.push({
                                edgeId: flow.elementData.failTargetId,
                                source: flow.uid,
                                sourceEdge: 'appointment_confirmation/failed'
                            });
                    }
                    else if (flow.elementData.type == 'ticketIt') {
                        if (flow.elementData.ticketFlow.createTicket)
                            specialEdgesId.push({
                                edgeId: flow.elementData.ticketFlow.createTicket,
                                source: flow.uid,
                                sourceEdge: 'ticketIt/create'
                            });
                        if (flow.elementData.ticketFlow.updateTicket)
                            specialEdgesId.push({
                                edgeId: flow.elementData.ticketFlow.updateTicket,
                                source: flow.uid,
                                sourceEdge: 'ticketIt/update'
                            });
                    }
                    else if (flow.elementData.type == 'delay') {
                        if (flow.elementData.delayOperation.completeId)
                            specialEdgesId.push({
                                edgeId: flow.elementData.delayOperation.completeId,
                                source: flow.uid,
                                sourceEdge: 'delay/complete'
                            });
                        if (flow.elementData.delayOperation.interruptId)
                            specialEdgesId.push({
                                edgeId: flow.elementData.delayOperation.interruptId,
                                source: flow.uid,
                                sourceEdge: 'delay/interrupt'
                            });
                    }
                    else if (flow.elementData.type == 'ask_keyword_options') {
                        flow.elementData.options.forEach((option: any, i: number) => {
                            if (option.id) {
                                specialEdgesId.push({
                                    edgeId: option.id,
                                    source: flow.uid,
                                    sourceEdge: 'ask_keyword_options/' + (i + 1)
                                });
                            }
                        });
                    }
                    else if (flow.elementData.type == 'send_list') {
                        flow.elementData.options.forEach((option: any) => {
                            const order = option.id.split(':')[1];
                            option.listMessage.forEach((listItem: any, i: number) => {
                                specialEdgesId.push({
                                    edgeId: listItem.id,
                                    source: flow.uid,
                                    sourceEdge: `send_list/${order}/${i + 1}`
                                });
                            });
                        });
                    }
                    else if (flow.elementData.type == 'send_wa_template') {
                        const elementData: ISendTemplateFormData = flow.elementData as unknown as ISendTemplateFormData;
                        if (elementData.template) {
                            elementData.template.components.forEach(cmp => {
                                if (cmp.type === 'BUTTONS') {
                                    return cmp.buttons.forEach((btn, i) => {
                                        if (btn.type === 'QUICK_REPLY' && 'templateNodes' in elementData && elementData.templateNodes !== null) {
                                            specialEdgesId.push({
                                                edgeId: `${elementData.templateNodes[i]}`,
                                                source: flow.uid,
                                                sourceEdge: 'send_wa_template/' + i
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    }
                    else if (flow.elementData.type == 'condition') {
                        specialEdgesId.push({
                            edgeId: flow.elementData.conditionOperation.successTargetId,
                            source: flow.uid,
                            sourceEdge: 'condition/if'
                        });
                        specialEdgesId.push({
                            edgeId: flow.elementData.conditionOperation.failTargetId,
                            source: flow.uid,
                            sourceEdge: 'condition/else'
                        });
                    }
                    else if (flow.elementData.type == 'switchCondition') {
                        specialEdgesId.push({
                            edgeId: flow.elementData.switchOperation.defaultTargetId,
                            source: flow.uid,
                            sourceEdge: 'switchCondition/default'
                        });
                        flow.elementData.switchOperation.list.forEach((option: any, i: number): any => {
                            if (option.id) {
                                specialEdgesId.push({
                                    edgeId: option.id,
                                    source: flow.uid,
                                    sourceEdge: 'switchCondition/' + (i + 1)
                                });
                            }
                        });
                    }
                    else if (flow.elementData.type == 'set_variable') {
                        flow.elementData.variables.forEach((variable: IVariable) => {
                            if (variable) {
                                updatedState.variables.push({
                                    name: variable.name,
                                    value: variable.value,
                                    type: variable.type,
                                    specialType: null,
                                });
                            }
                        })
                    }
                    else if (flow.elementData.type == 'send_reply_buttons') {
                        flow.elementData.options.map((option: any, i: number): any => {
                            if (option.buttonMessage.id) {
                                const id = option.buttonMessage.id.split(':')[0]
                                const order = option.buttonMessage.id.split(':')[1]
                                specialEdgesId.push({
                                    edgeId: option.buttonMessage.id,
                                    source: flow.uid,
                                    sourceEdge: 'send_reply_buttons/' + order
                                });
                            }
                        });
                    }
                }
                // Create edges
                if (flow.targetId) {
                    updatedState.edges.push({
                        source: flow.uid,
                        sourceHandle: 'b',
                        target: flow.targetId,
                        targetHandle: 'a',
                        type: 'edge',
                        id: `reactflow__edge-${flow.uid}_${flow.targetId}`,
                        markerEnd: {
                            type: MarkerType.Arrow,
                        },
                        animated: true
                    });

                }
            });
            // Special edges
            specialEdgesId.forEach(({ source, edgeId, sourceEdge }) => {
                if (edgeId && action.payload.flowData.find(flow => edgeId?.search(flow.uid) > -1)) {
                    updatedState.edges.push({
                        source,
                        sourceHandle: sourceEdge,
                        target: edgeId.split(':')[0],
                        targetHandle: 'a',
                        type: 'edge',
                        id: `specialedge-${source}-${edgeId}`,
                        markerEnd: {
                            type: MarkerType.Arrow,
                        },
                        animated: true
                    });
                }
            });
            updatedState = scanVariables(updatedState);
            return updatedState;
        },
        scanVariables,
        changeNode: (state, action: { payload: any }) => {
            let updatedState: State = {
                ...state,
                nodes: [...state.nodes],
                updatedElements: [...state.updatedElements],
                lastEdited: new Date().toISOString(),
                variables: [...state.variables]
            };
            updatedState['nodes'] = applyNodeChanges(action.payload, updatedState.nodes);
            // Saving changes when element is dragged
            if (action.payload.length > 0 && action.payload.find(({ type, position }: { type: string, position?: { x: number, y: number } }) => type === 'position' && position)) {
                if (!updatedState['updatedElements'].find(({ uid }) => uid === action.payload[0].id)) {
                    updatedState['updatedElements'].push({
                        uid: action.payload[0].id,
                        type: 'Dragged'
                    });
                }
            }
            updatedState = scanVariables(updatedState);
            return updatedState;
        },
        duplicateNode: (state, action: { payload: { id: string } }) => {
            let updatedState: State = {
                ...state,
                nodes: [...state.nodes],
                updatedElements: [...state.updatedElements],
            };
            const sourceNode = updatedState.nodes.find(node => node.uid === action.payload.id);
            if (sourceNode) {
                const id = uuidv4();
                updatedState.nodes.push({
                    ...sourceNode,
                    id,
                    uid: id,
                    position: {
                        x: sourceNode.position.x,
                        y: sourceNode.position.y + 50
                    },
                    backendId: undefined,
                    created: true,
                    selected: true
                });
                updatedState.nodes = updatedState.nodes.map(node => {
                    if (node.uid === action.payload.id) {
                        return {
                            ...node,
                            selected: false
                        };
                    }
                    return node;
                })

                updatedState.updatedElements.push({
                    uid: id,
                    type: 'Created'
                });
                updatedState.lastEdited = new Date().toISOString();
            }
            const len = updatedState.updatedElements.length;
            localStorage.setItem("dupId", updatedState.updatedElements[len - 1].uid);
            return updatedState;
        },
        changeEdge: (state, action: { payload: any }) => {
            const updatedState = {
                ...state,
                edges: [...state.edges],
                lastEdited: new Date().toISOString()
            };
            updatedState['edges'] = applyEdgeChanges({ ...action.payload, type: 'edge' }, updatedState.edges);
            return updatedState;
        },
        clearUpdatedElements: (state) => {
            const updatedState = {
                ...state,
                updatedElements: []
            };
            return updatedState;
        },
        clearDeletedElements: state => {
            const updatedState = {
                ...state,
                deletedElements: [],
                updatedElements: state.updatedElements.filter(update => update.type !== 'Deleted')

            };
            return updatedState;
        },
        addEdgeToNode: (state, action: { payload: any }) => {
            let updatedState = {
                ...state,
                nodes: [...state.nodes],
                edges: [...state.edges],
                lastEdited: new Date().toISOString(),
                updatedElements: [...state.updatedElements]
            };

            // Allow only one target node
            const myNode = updatedState.nodes.find(n => n.uid === action.payload.source);
            const myEdge = updatedState.edges.find(edge => {
                return edge.source === action.payload.source && edge.sourceHandle === 'b';
            });
            
            const isHavingEdges = () => {
                const edges = updatedState.edges?.filter(edge => edge.sourceHandle !== 'b');
                const isEdge = edges && edges?.some((edge: any) => edge.sourceHandle === action.payload?.sourceHandle &&  action.payload?.source === edge.source);
                return isEdge;
            }

            if(myNode && myNode.type === 'send_list' && isHavingEdges()) {
                return updatedState;
            }

            if (myNode && myEdge && state.nodes.find(n => n.uid === myEdge.target && action.payload.sourceHandle === 'b') ) {
                return updatedState;
            }
            updatedState['updatedElements'].push({
                uid: action.payload.source,
                type: 'Updated'
            });
            updatedState['updatedElements'].push({
                uid: action.payload.target,
                type: 'Updated'
            });
            updatedState['edges'] = addEdge({ ...action.payload, type: 'edge', animated: true, markerEnd: { type: 'arrow' } }, updatedState.edges);
            return updatedState;
        },
        setNodeData: (state, action: { payload: { id: string, data: any, create: boolean } }) => {
            let updatedState: State = {
                ...state,
                nodes: [...state.nodes],
                lastEdited: new Date().toISOString(),
                updatedElements: [...state.updatedElements],
                variables: [...state.variables]
            };
            updatedState['nodes'] = updatedState.nodes.map(node => {
                if (node.id == action.payload.id) {
                    return {
                        ...node,
                        data: action.payload.data
                    };
                }
                return node;
            });
            // Saving changes when element is updated
            if (!updatedState['updatedElements'].find(({ uid }) => uid === action.payload.id)) {
                updatedState['updatedElements'].push({
                    uid: action.payload.id,
                    type: action.payload.create ? 'Created' : 'Updated'
                });
            }
            updatedState = scanVariables(updatedState);
            return updatedState;
        },
        createNode: (state, action: { payload: { uid: string, type: string, data: any } }) => {
            let position = {
                x: 0,
                y: 0,
            };

            if (state.nodes.length > 0) {
                const latestNodeFirst: INode[] = [...state.nodes].sort((a: INode, b: INode) => {
                    if (a.backendId === undefined && b.backendId === undefined) {
                        return 0;
                    } else if (a.backendId === undefined) {
                        return 1;
                    } else if (b.backendId === undefined) {
                        return -1;
                    }
                    return b.backendId - a.backendId;
                });
                position.x = latestNodeFirst[0].position.x + 80;
                position.y = latestNodeFirst[0].position.y + 10;
            }

            const updatedState = {
                ...state,
                nodes: [...state.nodes],
                edges: [...state.edges],
                lastEdited: new Date().toISOString(),
            };

            // For self-loop elements
            if (action.payload.type === "open_ai") {
                updatedState.edges.push({
                    source: action.payload.uid,
                    sourceHandle: 'b',
                    target: action.payload.uid,
                    targetHandle: 'a',
                    type: 'edge',
                    id: `reactflow__edge-${action.payload.uid}_${action.payload.uid}`,
                    markerEnd: {
                        type: MarkerType.Arrow,
                    },
                    animated: true
                })
            }

            updatedState.nodes.push({
                id: action.payload.uid,
                uid: action.payload.uid,
                type: action.payload.type,
                position,
                data: action.payload.data,
                created: false,
            });
            return updatedState;
        },
        completeNodeCreation: (state, action: { payload: { uid: string, } }) => {
            const updatedState = {
                ...state,
                nodes: [...state.nodes],
                lastEdited: new Date().toISOString()
            };
            updatedState['nodes'] = updatedState.nodes.map(node => {
                if (node.uid === action.payload.uid) {
                    return {
                        ...node,
                        created: true
                    };
                }
                return node;
            });
            return updatedState;
        },
        deleteNode: (state, action: { payload: { id: string } }) => {
            let updatedState: State = {
                ...state,
                nodes: [...state.nodes],
                lastEdited: new Date().toISOString(),
                updatedElements: [...state.updatedElements],
                deletedElements: [...state.deletedElements]
            };
            const deletedNode = updatedState.nodes.find(node => {
                return node.id === action.payload.id;
            });
            if (deletedNode) {
                updatedState['deletedElements'].push(deletedNode);
                updatedState['nodes'] = updatedState.nodes.filter(node => {
                    return node.id !== action.payload.id;
                });
                updatedState['updatedElements'].push({
                    uid: action.payload.id,
                    type: 'Deleted'
                });
            }
            updatedState = scanVariables(updatedState);
            return updatedState;
        },
        assignBackendID: (state, action: { payload: { id: number, uid: string } }) => {
            const updatedState = {
                ...state,
                nodes: [...state.nodes],
                lastEdited: new Date().toISOString()
            };
            updatedState['nodes'] = updatedState.nodes.map(node => {
                if (node.uid === action.payload.uid) {
                    return {
                        ...node,
                        backendId: action.payload.id
                    };
                }
                return node;
            });
            return updatedState;
        },
        deleteEdge: (state, action: { payload: { id: string } }) => {
            const updatedState = {
                ...state,
                edges: [...state.edges],
                lastEdited: new Date().toISOString(),
                updatedElements: [...state.updatedElements],
                nodes: [...state.nodes],
            };
            const deleteEdge = updatedState.edges.find(edge => {
                return edge.id === action.payload.id;
            });
            if (deleteEdge) {
                updatedState['edges'] = updatedState.edges.filter(edge => {
                    return edge.id !== deleteEdge.id;
                });
                // Saving changes when element is updated
                updatedState['updatedElements'].push({
                    uid: deleteEdge.source,
                    type: 'Updated'
                });
                updatedState['updatedElements'].push({
                    uid: deleteEdge.target,
                    type: 'Updated'
                });
            }
            return updatedState;
        },
        stopEditing: (state) => {
            const updatedState = {
                ...state,
                lastEdited: null
            };
            return updatedState;
        },
        backendUpdateElement: (state, action: { payload: { id: number, updatedData: BackendFlow } }) => {
            let updatedState = {
                ...state,
                edges: [...state.edges],
                nodes: [...state.nodes],
            };
            updatedState.nodes = updatedState.nodes.map(node => {
                if (node.backendId === action.payload.id) {
                    let updatedNode: INode = JSON.parse(JSON.stringify(node));
                    if (action.payload.updatedData.elementData?.type === 'send_reply_buttons') {
                        action.payload.updatedData.elementData.options.forEach((option: any, i: number) => {
                            updatedNode.data.options[i].id = isNaN(option.buttonMessage.id) ? option.buttonMessage.id.split(':')[0] : null;
                            updatedNode.data.options[i].order = i + 1;
                        });
                    }
                    else if (action.payload.updatedData.elementData?.type === 'send_wa_template') {
                        updatedNode.data.templateNodes = action.payload.updatedData.elementData.templateNodes;
                        const isButtonCmp = updatedNode.data.template.components.find((cmp: any) => {
                            return cmp.type === "BUTTONS"
                                && cmp.buttons.length > 0
                                && cmp.buttons.find((btn: any) => btn.type === "QUICK_REPLY");
                        }) ? true : false;
                        if (!updatedNode.data.templateNodes && isButtonCmp) {
                            updatedState.edges = updatedState.edges.filter(edge => {
                                return edge.source !== node.uid;
                            });
                        }
                    }
                    else if (action.payload.updatedData.elementData?.type === 'send_list') {
                        action.payload.updatedData.elementData?.options.forEach((option: any, i: number) => {
                            option.listMessage.forEach((listItem: any, j: number) => {
                                if (listItem.id) {
                                    updatedNode.data.options[i].list[j].id = isNaN(listItem.id) ? listItem.id.split(':')[0] : null;
                                    updatedNode.data.options[i].list[j].order = j + 1;
                                }
                            })
                        });
                    }
                    else if (action.payload.updatedData.elementData?.type === 'switchCondition') {
                        updatedNode.data.switchOperation.defaultTargetId = action.payload.updatedData.elementData.switchOperation.defaultTargetId;
                        action.payload.updatedData.elementData.switchOperation.list.forEach((listItem: any, i: number) => {
                            if (listItem.id)
                                updatedNode.data.switchOperation.list[i].id = listItem.id;
                        });
                    }
                    else if (action.payload.updatedData.elementData?.type === 'ask_keyword_options') {
                        action.payload.updatedData.elementData.options.forEach((option: any, i: number) => {
                            if (option.id)
                                updatedNode.data.options[i].id = option.id;
                        });
                    }
                    else if (action.payload.updatedData.elementData?.type === 'delay') {
                        if (action.payload.updatedData.elementData.delayOperation.completeId)
                            updatedNode.data.delayOperation.completeId = action.payload.updatedData.elementData.delayOperation.completeId;
                        if (action.payload.updatedData.elementData.delayOperation.interruptId)
                            updatedNode.data.delayOperation.interruptId = action.payload.updatedData.elementData.delayOperation.interruptId;
                    }
                    else if (action.payload.updatedData.elementData?.type === 'condition') {
                        if (action.payload.updatedData.elementData.conditionOperation.successTargetId)
                            updatedNode.data.conditionOperation.successTargetId = action.payload.updatedData.elementData.conditionOperation.successTargetId;
                        if (action.payload.updatedData.elementData.conditionOperation.failTargetId)
                            updatedNode.data.conditionOperation.failTargetId = action.payload.updatedData.elementData.conditionOperation.failTargetId;
                    }
                    else if (action.payload.updatedData.elementData?.type === 'api_call') {
                        if (action.payload.updatedData.elementData.apiData.successTargetId)
                            updatedNode.data.apiData.successTargetId = action.payload.updatedData.elementData.apiData.successTargetId;
                        if (action.payload.updatedData.elementData.apiData.failTargetId)
                            updatedNode.data.apiData.failTargetId = action.payload.updatedData.elementData.apiData.failTargetId;
                    }
                    else if (action.payload.updatedData.elementData?.type === 'send_OrderDetails') {
                        if (action.payload.updatedData.elementData.successTargetId)
                            updatedNode.data.successTargetId = action.payload.updatedData.elementData.successTargetId;
                        if (action.payload.updatedData.elementData.failTargetId)
                            updatedNode.data.failTargetId = action.payload.updatedData.elementData.failTargetId;
                    }
                    else if (action.payload.updatedData.elementData?.type === 'ticketIt') {
                        if (action.payload.updatedData.elementData.ticketFlow.createTicket)
                            updatedNode.data.ticketFlow.createTicket = action.payload.updatedData.elementData.ticketFlow.createTicket;
                        if (action.payload.updatedData.elementData.ticketFlow.updateTicket)
                            updatedNode.data.ticketFlow.updateTicket = action.payload.updatedData.elementData.ticketFlow.updateTicket;
                    }
                    else if (action.payload.updatedData.elementData?.type === 'appointment_confirmation') {
                        if (action.payload.updatedData.elementData.successTargetId)
                            updatedNode.data.successTargetId = action.payload.updatedData.elementData.successTargetId;
                        if (action.payload.updatedData.elementData.failTargetId)
                            updatedNode.data.failTargetId = action.payload.updatedData.elementData.failTargetId;
                    }
                    else if (action.payload.updatedData.elementData?.type === 'integration') {
                        if (action.payload.updatedData.elementData.integrationData?.successTargetId)
                            updatedNode.data.integrationData.successTargetId = action.payload.updatedData.elementData?.integrationData?.successTargetId;
                        if (action.payload.updatedData.elementData.integrationData?.failTargetId)
                            updatedNode.data.integrationData.failTargetId = action.payload.updatedData.elementData?.integrationData?.failTargetId;
                    }
                    return updatedNode;
                }
                return node;
            });
            return updatedState
        }
    },
});

export const {
    changeNode,
    changeEdge,
    addEdgeToNode,
    setNodeData,
    createNode,
    deleteNode,
    deleteEdge,
    init,
    stopEditing,
    assignBackendID,
    completeNodeCreation,
    clearUpdatedElements,
    clearDeletedElements,
    backendUpdateElement,
    duplicateNode,
    createVariable,
    setViewport
} = graphSlice.actions;

export default graphSlice.reducer;

// import React, { useState } from "react";
// import { Tooltip } from "reactstrap";
// import "./common.scss";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import moment from "moment";
// type Placement =
//   | "auto"
//   | "auto-start"
//   | "auto-end"
//   | "top"
//   | "top-start"
//   | "top-end"
//   | "right"
//   | "right-start"
//   | "right-end"
//   | "bottom"
//   | "bottom-start"
//   | "bottom-end"
//   | "left"
//   | "left-start"
//   | "left-end";
// interface tooltipProps {
//   placement: Placement;
//   icon?: any;
//   content?: string;
//   tooltipId: string;
//   children?: React.ReactNode;
//   listView?: any; // Allow children
//   colorVariant?: string;
//   component?: string
// }

// const TooltipReference: React.FC<tooltipProps> = ({
//   placement,
//   icon,
//   content,
//   tooltipId,
//   children,
//   listView,
//   colorVariant, component
// }) => {
//   const [tooltipOpen, setTooltipOpen] = useState(false);
//   const toggle = () => setTooltipOpen(!tooltipOpen);

//   return (
//     <>
//       {icon && (
//         <FontAwesomeIcon icon={icon} className="iconsColor" id={tooltipId} />
//       )}
//       <span id={tooltipId}>
//         {children} {/* use children here */}
//       </span>
//       {listView && listView?.length > 0 ? (
//         <Tooltip
//           placement={"right"}
//           isOpen={tooltipOpen}
//           target={tooltipId}
//           toggle={toggle}
//         >
//           <ul className="horizontal-list p-0">
//             {listView?.map((item: any, index: number) => (
//               <li key={item.id}>
//                 {index +
//                   1 +
//                   "." +
//                   item.name +
//                   " " +
//                   "(" +
//                   // item.contacts.length +
//                   item.contactCount +
//                   ")"}
//               </li>
//             ))}
//           </ul>
//         </Tooltip>
//       ) :
//         colorVariant === "Danger" ? (
//           <>
//             <Tooltip
//               placement={placement}
//               isOpen={tooltipOpen}
//               target={tooltipId}
//               toggle={toggle}
//               id='tooltip-danger'
//             // style={{background: '#fed7d7', fontSize: 'smaller', color: '#c53030', padding: '6px',boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}
//             >
//               <span className="custom-tooltip">{content}</span>
//             </Tooltip>
//           </>
//         ) : component === 'ticketing' ? (
//           <Tooltip
//             placement={placement}
//             isOpen={tooltipOpen}
//             target={tooltipId}
//             toggle={toggle}
//           >
//             <ul className="">
//               {listView?.resolutionTimeDue &&
//                 <li>Resolution Due : {moment(listView.resolutionTimeDue).format(
//                   "DD MMM YYYY hh:mm a"
//                 )}</li>}
//               <br />
//               {listView?.firstResponseTimeDue &&
//                 <li>First Response Due : {moment(listView.firstResponseTimeDue).format(
//                   "DD MMM YYYY hh:mm a"
//                 )}</li>}
//             </ul>
//           </Tooltip>
//         ) : (
//           <>
//             <Tooltip
//               placement={placement}
//               isOpen={tooltipOpen}
//               target={tooltipId}
//               toggle={toggle}
//               className={`commonTooltip`}
//             >
//               <span className="custom-tooltip">{content}</span>
//             </Tooltip>
//           </>
//         )}
//     </>
//   );
// };

// export default TooltipReference;
import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./common.scss";
import { PiInfoLight } from "react-icons/pi";
import { IoInformationCircleOutline } from "react-icons/io5";

import moment from "moment";

type Placement =
  | "auto"
  | "auto-start"
  | "auto-end"
  | "top"
  | "top-start"
  | "top-end"
  | "right"
  | "right-start"
  | "right-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end"
  | "left"
  | "left-start"
  | "left-end";

interface TooltipProps {
  placement: Placement;
  icon?: any;
  content?: string;
  tooltipId: string;
  children?: React.ReactNode;
  listView?: any; // Allow children
  colorVariant?: string;
  component?: string;
}

const TooltipReference: React.FC<TooltipProps> = ({
  placement,
  icon,
  content,
  tooltipId,
  children,
  listView,
  colorVariant,
  component
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div style={{ display: 'inline-block' }}> {/* Optional styling */}
      <OverlayTrigger
        placement={placement}
        overlay={
          <Tooltip id={tooltipId}>
            {component === 'ticketing' ? (
              <ul className="">
                {listView?.resolutionTimeDue && (
                  <li>
                    Resolution Due : {moment(listView.resolutionTimeDue).format("DD MMM YYYY hh:mm a")}
                  </li>
                )}
                <br />
                {listView?.firstResponseTimeDue && (
                  <li>
                    First Response Due : {moment(listView.firstResponseTimeDue).format("DD MMM YYYY hh:mm a")}
                  </li>
                )}
              </ul>
            ) : colorVariant === "Danger" ? (
              <span className="custom-tooltip">{content}</span>
            ) : listView && listView.length > 0 ? (
              <ul className="horizontal-list p-0">
                {listView.map((item: any, index: number) => (
                  <li key={item.id}>
                    {index + 1 + "." + item.name + " " + "(" + item.contactCount + ")"}
                  </li>
                ))}
              </ul>
            ) : (
              <span className="custom-tooltip">{content}</span>
            )}
          </Tooltip>
        }
      >
        <div>
          {icon ?
          //  <FontAwesomeIcon icon={icon} id={tooltipId} />
          <IoInformationCircleOutline size={15} />
          : children}
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default TooltipReference;

import { useEffect, useState } from "react";
import SelectTicket from "./Components/SelectTicket";
import SendTicket from "./Components/SendTicket";
import TicketDetails from "./Components/TicketDetails";
import "./styles.scss";
import { Row, Col } from "reactstrap";
import { getAllTicketBasedOnChannel } from "../../../services/ticketService";
import { useSelector } from "react-redux";
import { socket } from "../../../common/socketconfig";
import Loader from "../../../shade/Loaders/smallLoader";
import { ErrorImage } from "../../../common/ErrorMessage";

interface Ticket {
  id: number;
  uid: string;
  contact: {
    name: string;
  };
  createdAt: string;
  updatedAt: string;
  latestMessage: {
    messageType: string;
    flowData?: {
      description: string;
    };
    media?: {
      mediaType: string;
    };
    messageReceivedTime: string;
  };
}


interface Option {
  label: string;
  value: string | number;
}

interface TicketField {
  id: number;
  name: string;
  label: string;
  position: number;
  type: string;
  dropDown: boolean;
  options: Option[];
}

const ViewTicket = () => {
  const channelUid = useSelector(
    (state: any) => state.cartreducer.channelUid?.channelAllData?.uid
  );

  const [ticketPage, setTicketPage] = useState<number>(0);
  const [allTickets, setAllTickets] = useState<Ticket[]>([]);
  const [isMemberSelected, setIsMemberSelected] = useState(false);
  const [sendPressed, setSendPressed] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<any>(null);
  const business = useSelector((state: any) => state.cartreducer.business);
  const [hasMoreTickets, setHasMoreTickets] = useState(true);
  const [convLoader, setConvLoader] = useState(true);
  const ticketsocket = socket;
  const [loadTicket, setLoadTicket] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [convPage, setConvPage] = useState<number>(0);
  const [searchVal, setSearchVal] = useState('');
  const [statusCounts, setStatusCounts] = useState({
    Open: 0,
    Pending: 0,
    Resolved: 0,
    Closed: 0,
  });
  const [isType, setIsType] = useState(false);
  const [ticketFilterVisible, setTicketFilterVisible] = useState<boolean>(false);
  const [typeSelection, setTypeSelection] = useState([]);
  const [ticketFields, setTicketFields] = useState<TicketField[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [ticketTypesReport, setTicketTypesReport] = useState<any>([]);
  const [typeStatusSelected, setTypeStatusSelected] = useState<string>("");
  const [callStatusCount, setCallStatusCount] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  
  useEffect(() => {
    if(sendPressed){
      setTimeout(() => {
        setSendPressed(false);
      }, 2000);
    }
  },[sendPressed])

  useEffect(() => {
    if (business?.uid) {
      // ticketsocket.connect();
      // ticketsocket.on("connect", () => {
      //   ticketsocket.off("onUserConnect");
      //   ticketsocket.emit("userConnect", business?.uid);
      //   ticketsocket.on("onUserConnect", (data: string) => {
      //     console.log(data);
      //   });
      // });
      ticketsocket.off("onUserConnect");
      ticketsocket.emit("userConnect", business?.uid);
      ticketsocket.on("onUserConnect", (data: string) => {
        console.log(data);
      })
      return () => {
        ticketsocket.off("onUserConnect");
        ticketsocket.off('onLoadTicketMessages');
        ticketsocket.off('onMessage');
        ticketsocket.off("onTicket");
        // ticketsocket.disconnect();
      }
    }
    ticketsocket.on("disconnect", (reason: string) => {
      console.warn("Disconnected from the server:", reason);
      if (reason === "io server disconnect") {
        refetchTickets();
      }
    });

    ticketsocket.on("connect_error", (error: Error) => {
      console.error("Connection error:", error);
      refetchTickets();
    });

    ticketsocket.on("reconnect_attempt", (attemptNumber: number) => {
      console.log("Reconnection attempt:", attemptNumber);
      refetchTickets();
    });

    ticketsocket.on("reconnect", (attemptNumber: number) => {
      console.log("Reconnected successfully on attempt:", attemptNumber);
      refetchTickets();
    });
  }, [business]);

  const refetchTickets = () => {
    setTimeout(() => {
      ticketsocket.connect();
      ticketsocket.off("onUserConnect");
      ticketsocket.emit("userConnect", business?.uid);
      ticketsocket.on("onUserConnect", (data: string) => {
        console.log(data);
      });
      loadTickets(0);
    }, 1000)
  }

  const loadTickets = (page: number, search?: string) => {
    if (isLoading) return;

    setIsLoading(true);
    setSearchVal(search || '');
    let payload = {
      channelUid: channelUid,
      page: page,
      limit: 10,
      search: search || undefined,
      memberUid: business?.role?.name === 'MEMBER' ? [business?.uid] : undefined
    };
    setTicketPage(page);
    getAllTicketBasedOnChannel(payload).then((res: any) => {
      console.log(res.data.data, 'getTik');
      setSelectedTicket(selectedTicket || res.data.data[0]);
      setIsLoading(false);
      if (res.status === 200) {
        setConvLoader(false);
      }
      if (res?.data?.data?.length) {
        setAllTickets((prevTickets) => {
          const newTickets = page === 0 ? res.data.data : [...prevTickets, ...res.data.data];
          if ((newTickets.length > 0 && page === 0) && !search) {
            handleTicketSelect(newTickets[0]);
          }
          return newTickets;
        });
        if (res?.data?.data?.length < 10) {
          setHasMoreTickets(false);
        } else {
          setHasMoreTickets(true);
        }
      } else {
        if (page === 0) {
          setAllTickets([]);
          if (!search)
            setSelectedTicket(null);
        }
        setHasMoreTickets(false);
      }
    }).catch(() => {
      setAllTickets([]);
      if (!search)
        setSelectedTicket(null);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (channelUid){
      loadTickets(ticketPage);
      // setSelectedTicket(selectedTicket);
    }else {
      setAllTickets([]);
      setSelectedTicket(null);
      setIsLoading(false);
      setConvLoader(false);
    }
    //setLoadTicket(false);
  }, [loadTicket]);

  useEffect(() => {
    if (loadTicket) {
      setTimeout(() => {
        setLoadTicket(false);
      }, 1000);
    }
  }, [loadTicket])

  const handleScrollToEnd = (search: string) => {
    setSearchVal(search);
    if (hasMoreTickets && !isLoading) {
      loadTickets(ticketPage + 1, search);
    }
  };

  const handleTicketSelect = (ticket: any) => {
    setSelectedTicket(ticket);
    ticketsocket.off('onLoadTicketMessages');
    ticketsocket.emit('loadTicketMessages', {
      ticketUid: ticket.uid,
      page: 0, // Start from the first page
      limit: 1000
    });
  };

  return (
    <div className="view-ticket">
      {
        convLoader ? <Loader /> : (
          <Row>
            <Col md={3} className="p-0" style={{ borderRight: '1px solid #e6e6e6' }}>
              <SelectTicket
                data={allTickets}
                onScrollToEnd={handleScrollToEnd}
                onSelect={handleTicketSelect}
                selectedTicket={selectedTicket}
                setSelectedTicket={setSelectedTicket}
                setLoadTicket={setLoadTicket}
                hasMoreTickets={hasMoreTickets}
                loadTickets={loadTickets}
                setConvPage={setConvPage}
                setAllTickets={setAllTickets}
                statusCounts={statusCounts}
                setStatusCounts={setStatusCounts}
                ticketFields={ticketFields}
                selectedTypes={selectedTypes}
                setSelectedTypes={setSelectedTypes}
                setTicketFields={setTicketFields}
                typeSelection={typeSelection}
                setIsType={setIsType}
                ticketFilterVisible={ticketFilterVisible}
                setTicketFilterVisible={setTicketFilterVisible}
                setTicketTypesReport={setTicketTypesReport}
                typeStatusSelected={typeStatusSelected}
                setTypeStatusSelected={setTypeStatusSelected}
                callStatusCount={callStatusCount}
                setCallStatusCount={setCallStatusCount}
                isFiltering={isFiltering}
                setIsFiltering={setIsFiltering}
              />
            </Col>
            {selectedTicket ?
              <>
                <Col md={6} className="p-0" style={{ borderRight: '1px solid #e6e6e6' }}>
                  <SendTicket
                    data={selectedTicket}
                    allTickets={allTickets}
                    setAllTickets={setAllTickets}
                    selectedTicket={selectedTicket}
                    setSelectedTicket={setSelectedTicket}
                    page={convPage}
                    setPage={setConvPage}
                    searchVal={searchVal}
                    statusCounts={statusCounts}
                    setStatusCounts={setStatusCounts}
                    setSendPressed={setSendPressed}
                    typeSelection={typeSelection}
                    setTypeSelection={setTypeSelection}
                    ticketFields={ticketFields}
                    isType={isType}
                    setIsType={setIsType}
                    setTicketFilterVisible={setTicketFilterVisible}
                    selectedTypes={selectedTypes}
                    ticketTypesReport={ticketTypesReport}
                    typeStatusSelected={typeStatusSelected}
                    setTypeStatusSelected={setTypeStatusSelected}
                  />
                </Col>
                <Col md={3} className="p-0">
                  <TicketDetails
                    selectedTicket={selectedTicket}
                    setLoadTicket={setLoadTicket}
                    setSelectedTicket={setSelectedTicket}
                    isMemberSelected = {isMemberSelected}
                    setIsMemberSelected = {setIsMemberSelected}
                    sendPressed = {sendPressed}
                    setCallStatusCount = {setCallStatusCount}
                    loadTickets={loadTickets}

                  />
                </Col>
              </> :
              <Col md="9">
                <div className="centerItemsWithWidth createTicketFont">
                  {
                    isFiltering ? <ErrorImage /> : 'Create Tickets to view their conversation'
                  }
                </div>
              </Col>}
          </Row>
        )
      }
    </div>
  );
};

export default ViewTicket;

import React, { useState, useEffect } from 'react';
import { ListGroup, Form, Button, Row, Col } from 'react-bootstrap';

type MemberListProps = {
  memberList: any[]; // List of members
  selectedMembers: any; // Selected members passed from parent
  updateMemberUid: React.Dispatch<React.SetStateAction<Set<string>>>; // Function to update selected members in parent
  onClose: () => void; // Close the filter list
};

const MemberList: React.FC<MemberListProps> = ({
  memberList = [], // Default to empty array if not provided
  selectedMembers,
  updateMemberUid,
  onClose,
}) => {
  // Temporary state to handle selection within the modal
  const [tempSelectedMembers, setTempSelectedMembers] = useState<Set<string>>(new Set(selectedMembers));
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredMembers, setFilteredMembers] = useState<any[]>([]);

  useEffect(() => {
    setFilteredMembers(memberList);
  }, [memberList]);

  const toggleMemberSelection = (memberId: string) => {
    setTempSelectedMembers((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(memberId)) {
        newSelected.delete(memberId);
      } else {
        newSelected.add(memberId);
      }
      return newSelected;
    });
  };

  const handleSearchChange = (term: string) => {
    const trimmedTerm = term.trim().toLowerCase();
    setSearchTerm(trimmedTerm);

    if (trimmedTerm) {
      setFilteredMembers(
        memberList.filter((member) =>
          member.name.toLowerCase().includes(trimmedTerm)
        )
      );
    } else {
      setFilteredMembers(memberList);
    }
  };

  const applyFilterHandler = () => {
    updateMemberUid(tempSelectedMembers); // Commit the temporary selection to parent state
    onClose(); // Close the modal
  };

  const onCancelHandler = () => {
    onClose(); // Close the modal without updating parent state
  };

  const clearSelectedMembers = () => {
    setTempSelectedMembers(new Set());
    updateMemberUid(new Set())
    // Clear the temporary selection
  };

  return (
    <ListGroup variant="flush">
      <ListGroup.Item style={{ position: 'sticky', top: '0px', zIndex: '100000' }}>
        <Form.Control
          autoFocus
          className="w-auto mb-2"
          placeholder="Search Member"
          onChange={(e) => handleSearchChange(e.target.value)}
          value={searchTerm}
          size="sm"
        />
      </ListGroup.Item>

      <Row style={{ position: 'sticky', zIndex: 100000, top: '50px',background:'white'}}>
        <Col>
          Selected: {tempSelectedMembers.size}
        </Col>
        <Col>
          {tempSelectedMembers.size > 0 && (
            <Button onClick={clearSelectedMembers} className="p-0 w-100 sendButton">
              Clear
            </Button>
          )}
        </Col>
      </Row>

      {Array.isArray(filteredMembers) && filteredMembers.map((member: any) => (
        <ListGroup.Item
          key={member.id}
          onClick={() => toggleMemberSelection(member.uid)}
          style={{ cursor: 'pointer' }}
        >
          <Form.Check
            checked={tempSelectedMembers.has(member.uid)}
            label={member.name}
          />
        </ListGroup.Item>
      ))}

      <ListGroup.Item
        className="d-flex"
        style={{ position: 'sticky', bottom: '0px' }}
      >
        <Button size="sm" className="cancelButton w-50 p-1" onClick={onCancelHandler}>
          Cancel
        </Button>
        <Button size="sm" className="applyButton p-1 w-50 ml-2" onClick={applyFilterHandler}>
          Apply
        </Button>
      </ListGroup.Item>
    </ListGroup>
  );
};

export default MemberList;

type VariableType = 'TEXT' | 'OBJECT' | 'STRING' | 'NUMBER' | 'ARRAY'

export type IStorageVariable<Type extends VariableType> = {
    name: string;
    type: Type;
};
export interface IVariable {
    name: string;
    type: VariableType;
    value?: any;
    path?: string;
    specialType: null | 'location' | 'appointment_Flow' | 'integration/GoogleSheets';
    source?: string[];
};

export class Variable implements IVariable {
    name: string;
    type: VariableType;
    value?: any;
    path?: string;
    specialType: null | 'location' | 'appointment_Flow' | 'integration/GoogleSheets';
    source?: string[];

    constructor(variable: IVariable) {
        this.name = variable.name;
        this.type = variable.type;
        this.value = variable.value;
        this.path = variable.path;
        this.specialType = variable.specialType;
        this.source = variable.source;
    }

    toString(): string {
        return `{{variable.${this.name}}}`;
    }

    static fromString(str: string, type: VariableType): Variable {
        const match = str.match(/\{\{variable\.(\w+)\}\}/);

        if (match) {
            const name = match[1];
            return new Variable({
                name,
                type,
                specialType: null,
            });
        } else {
            throw new Error('Invalid variable');
        }
    }
}
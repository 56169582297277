import React, { useEffect, useState, useMemo, useRef } from "react";
import "./sequence.scss";
import styles from "./Sequence.module.scss";
import {
  Button,
  Col,
  Label,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import TooltipReference from "../../common/tooltip";
import { HiMiniArrowSmallRight } from "react-icons/hi2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Formik, Form, Field, useFormikContext, ErrorMessage } from "formik";
import { confirmAlert, toast } from "../../common/alert";
import { customStyles } from "../../common/react-select-style";
import { IoIosFunnel } from "react-icons/io";
// import { SequenceValidation } from "./SequenceValidation";
import * as Yup from "yup";
import {
  sequenceOptions,
  SelectTriger,
  SelectTimes,
  defaultTimeSequence,
  AssignOptions,
} from "../../common/category";
import Select, { components } from "react-select";
import Modall from "../../common/modall";
import AddVariables from "./AddVariables";
import {
  updateWholeSequence,
  createWholeSequence,
  getOverallSequenceFlow,
  getAllBot,
} from "../../services/SequenceService";
import { SequenceContext } from "./SequenceContext";
import { faXmarkCircle, faInfoCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { sliceText } from "../../common/sliceText";
import makeAnimated from "react-select/animated";
import DaySelection from "./DaySelectionComponent";
import Accordion from "react-bootstrap/Accordion";
import { HiPencil } from "react-icons/hi2";
import { FaFile } from "react-icons/fa6";
// import { IoClose } from "react-icons/io5";
import { RiArrowLeftDoubleLine } from "react-icons/ri";
import AddFlow from "./AddFlow";
import { GoClock } from "react-icons/go";
import AddVariablesDub from "./AddVariablesDub";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getAllTeam, getTeam } from "../../services/contactMemberService";
import SwitchButton from "../Contacts/SwitchButton";

type Props = {};

interface FormValues {
  // sequenceFlowStep:{
  // delay: string;
  // delayUnit: string;
  // }
  timePreference: string;
  sequenceName: string;
  triggerOption: string;
  day: any;
  sequenceType: string;
  times: string; // templateName:string;
  sqfromTime: string;
  sqtoTime: string;
  replyAssignTo: string;
  replyAssignId: any;
}

const CreateSequenece = (props: Props) => {
  const daysList = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const navigate = useNavigate();
  // const [isChecked, setIsChecked] = useState<string>("Create");

  const [isTime, setIsTime] = useState<string>("DefaultTime");
  const [isAddModal, setIsAddModal] = useState(false);
  const [variableType, setVariableType] = useState("");
  const [isRepeatContact, setIsRepatContact] = useState(true);
  const [getOverallSequence, setOverAllSequenceFlow] = useState<any>();
  const [error, setError] = useState(false);
  const [replyAssignId, setReplyAssign] = useState(0);


  const { sequenceId } = useParams();

  const [botData, setBotData] = useState<any>([]);
  const [teamData,setTeamData]=useState<any>([]);
  const [teamMemberData,setTeamMemberData]=useState<any>([]);

  const [roundRobin,setroundRobin]=useState<boolean>(false);
  const roundRobinOptState = (check: boolean) => {
    setroundRobin(check)
  };
  // const [conditions, setCondition] = useState<any>([]);

  // const dropStart = async (indexToDrop: number) => {
  //   setOverAllSequenceFlow((prevTags: any) => {

  //   //   // Check if startCondition is an array and update it or return prevTags unchanged
  //     if (Array.isArray(prevTags.startCondition)) {
  //       const updatedStartCondition = prevTags.startCondition.filter(
  //         (item: any, index: number) => index !== indexToDrop
  //       );
  //       return {
  //         ...prevTags, // Spread the rest of prevTags to maintain other state parts
  //         startCondition: updatedStartCondition, // Update startCondition with filtered array
  //       };
  //     }
  //     return prevTags; // Return unchanged prevTags if startCondition is not an array
  //   });
  // };

  // const dropStop = async (indexToDrop: number) => {
  //   setCondition((prevTags: any) => {
  //     const { stopCondition } = prevTags;
  //     if (Array.isArray(stopCondition)) {
  //       const updatedStartCondition = stopCondition.filter(
  //         (item: any, index: number) => index !== indexToDrop
  //       );
  //       return {
  //         ...prevTags, // Spread the rest of prevTags to maintain other state parts
  //         stopCondition: updatedStartCondition, // Update startCondition with filtered array
  //       };
  //     }
  //     return prevTags; // Return unchanged prevTags if startCondition is not an array
  //   });
  // };
  const handleDeselect = (event: any, setFieldValue: any) => {
    setFieldValue("assignTo", ""); // Reset Formik's state
  };

  // const getBotData =async()=>{
  //   const botPayload = {
  //     uid: businessUid,
  //     limit: 1000,
  //     page: 0,
  //   };
  //  const response =  await getAllBot(botPayload);
  //   setBotData(response)
  // }

  useEffect(() => {
    if (sequenceId) {
      updateSequence(sequenceId);
    }
  }, [sequenceId]);

  const updateSequence = async (sequenceId: string) => {
    const sequencePayload = {
      sequenceUid: sequenceId,
    };
    const response = await getOverallSequenceFlow(sequencePayload, channelUid);

    if (response) {
      const updatedSequenceFlows = response.sequenceFlows.map((flow: any) => {
        return {
          ...flow,
          scheduledTimes: getDays(flow.scheduledTimes),
          fromTime: getStartTime(flow.scheduledTimes),
          toTime: getStopTime(flow.scheduledTimes),
        };
      });

      // setOverAllSequenceFlow(response);
      setOverAllSequenceFlow({
        ...response,
        sequence: {
          ...response.sequence,
          replyAssignTo: String(response.sequence.replyAssignTo),
          day: getDays(response.sequence.scheduledTimes),
          sqfromTime: getStartTime(response.sequence.scheduledTimes),
          sqtoTime: getStopTime(response.sequence.scheduledTimes),
        },
        sequenceFlows: updatedSequenceFlows,
      });
      const { scheduledTimes, triggerOption, customTime, repeatForContacts } =
        response?.sequence;

      setIsRepatContact(repeatForContacts);
      // setIsChecked(triggerOption);
      if (customTime) {
        setIsTime("CustomTime");
      } else {
        setIsTime("DefaultTime");
      }
    }
  };

  const currentRepeat = () => {
    setIsRepatContact(!isRepeatContact);
  };

  // const handleTriggerChange = (e: any) => {
  //   const value = e.target.value;
  //   setIsChecked(value);
  // };

  const handleTimeChange = (e: any) => {
    const value = e.target.value;
    setIsTime(value);
  };

  const handleStart_Stop = (variableType: string) => {
    setVariableType(variableType);
    setIsAddModal(!isAddModal);
  };
  const handleConditionClose = () => {
    setIsAddModal(false);
  };

  // const getStartStopCondition = (start_stop: any) => {
  //   // setCondition(start_stop);
  // };

  // useEffect(() => {
  //   if (getOverallSequence?.sequence) {
  //     setCondition(getOverallSequence?.sequence);
  //   }
  // }, [getOverallSequence]);

  const handleKeyDown = (event: any) => {
    const { value } = event.target;
    if (event.key === " " && value.trim() === "") {
      event.preventDefault(); // Prevent space key from having its default behavior
    }
  };

  const [getFlowDataList, setFlowDataList] = useState([]);
  const [showAlert, setAlert] = useState(false);
  const setIsBackAlert = (alert: boolean) => {
    setAlert(alert);
  };

  const backTosequence = () => {
    if (!sequenceId) {
      if (showAlert) {
        confirmAlert(
          "warning",
          "Your changes will be lost. Are you sure you want to continue?",
          () => {
            if (showAlert) {
              navigate("/sequence");
            }
          },
          () => { }
        );
      } else {
        navigate("/sequence");
      }
    } else if (sequenceId) {
      navigate("/sequence");
    }
  };

  const getFlowData = (flowData: any) => {
    setFlowDataList(flowData);
  };
  const channelUid = useSelector(
    (state: any) => state.cartreducer.channelUid.value
  );

  const memberUid = useSelector(
    (state: any) => state.cartreducer.business?.uid
  );
  const businessUid = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );

  const getBotData = async () => {
    const botPayload = {
      uid: businessUid,
      limit: 1000,
      page: 0,
      channelId: channelUid
    };
    const response = await getAllBot(botPayload);
    setBotData(response.bots);
  };

  const getTeamData = async () => {
    const botPayload = {
      businessUid: businessUid,
      limit: 1000,
      page: 0,
    };
    const response = await getAllTeam(botPayload);
    console.log("da",response);
    
    setTeamData(response?.data);
  };

  const getTeamMemberData=async(uid:any)=>{
    const response=await getTeam(uid);
    console.log("r",response?.members);
    setTeamMemberData(response?.members)
    
  }

  useEffect(() => {
    if (businessUid) {
      getBotData();
      getTeamData();
    }
  }, [businessUid])

  const [currentChannel, setCurrentChannel] = useState(channelUid);

  useEffect(() => {
    // Check if the channelUid has changed and navigate if necessary
    if (channelUid !== currentChannel) {
      setCurrentChannel(channelUid);
      navigate('/sequence');
    }
  }, [channelUid, currentChannel, navigate]);

  const memberData = useSelector((state: any) => state.cartreducer.memberData);

  const botDataOption = botData.map((bot: any) => ({
    label: bot.name,
    value: bot.id
  }));

  const teamDataOption = teamData.map((bot: any) => ({
    label: bot.name,
    value: bot.id,
    uid:bot?.uid,
  }));
  const teamMemberDataOption = teamMemberData.map((bot: any) => ({
    label: bot.name,
    value: bot.id,
    uid:bot?.uid,
  }));



  const memberDataOption = memberData.map((member: any) => ({
    label: member.name,
    value: member.id
  }))


  const [action, setAction] = useState("");

  const fullDays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const getSelectedDaysObjects = (
    onTime: string,
    selectedDaysArr: boolean[],
    endTime: string
  ) => {
    return fullDays
      .map((Day, index) => {
        if (selectedDaysArr[index]) {
          return { day: Day, fromTime: onTime, toTime: endTime };
        }
        return null;
      })
      .filter((dayObj) => dayObj !== null);
  };

  const getDays = (sequenceFlows: any[]) => {
    const days = fullDays.map((day) =>
      sequenceFlows.some((flow) => flow.day === day)
    );
    return days;
  };

  const getStartTime = (sequenceFlows: any[]) => {
    return sequenceFlows[0]?.fromTime;
  };

  const getStopTime = (sequenceFlows: any[]) => {
    return sequenceFlows[0]?.toTime;
  };

  const handleSubmit = async (
    event: any
    // { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    // const replyAssign =
    //   event.assignTo === "bot" ? "1" : event.assignTo === "member" ? "2" : "0";
    const Time =
      isTime === "CustomTime"
        ? getSelectedDaysObjects(event.sqfromTime, event.day, event.sqtoTime)
        : defaultTimeSequence;
    const dayTime = isTime === "CustomTime" ? true : false;
    const { sequenceType, sequenceName, triggerOption } = event;
    const start = sequenceType === "event" ? event.startCondition : null;
    const stop = sequenceType === "event" ? event.stopCondition : null;

    const updatedFlowData = getFlowDataList.map(
      (flowData: any, index: number) => {
        let updatedFlowData = { ...flowData };
        updatedFlowData.scheduledTimes = getSelectedDaysObjects(
          updatedFlowData.fromTime,
          updatedFlowData.scheduledTimes,
          updatedFlowData.toTime
        );

        delete updatedFlowData.fromTime;
        delete updatedFlowData.toTime;

        return updatedFlowData;
      }
    );

    const sequencePayload = {
      sequence: {
        sequenceName: sequenceName,
        sequenceBased: "contacts",
        sequenceType: sequenceType,
        customTime: dayTime,
        triggerOption: triggerOption,
        startCondition: start,
        stopCondition: stop,
        scheduledTimes: Time,
        repeatForContacts: isRepeatContact,
        totalSteps: getFlowDataList.length,
        values:{
          roundRobin:roundRobin
        },
        status: action,
        sequenceCreatedBy: memberUid,
        replyAssignTo: Number(event.replyAssignTo), //bot-1 member - 2
        replyAssignId: event.replyAssignId, //id
      },
      sequenceFlows: updatedFlowData,
    };

    if (!sequenceId) {
      const response = await createWholeSequence(sequencePayload, channelUid);
      if (response) {
        toast("success", "sequence created!");
        navigate("/sequence");
      }
    }

    // Merge sequences and sequence flows from getOverallSequence and sequencePayload
    const updatedSequence = {
      ...getOverallSequence.sequence,
      ...sequencePayload.sequence,
    };
    const updatedSequenceFlows = [
      ...getOverallSequence.sequenceFlows,
      ...sequencePayload.sequenceFlows,
    ];

    const uniqueSequenceFlowsMap = new Map(
      updatedSequenceFlows.map((flow) => [flow.id, flow])
    );

    const updateSequencePayload = {
      ...getOverallSequence,
      sequence: updatedSequence,
      // sequenceFlows: Array.from(uniqueSequenceFlowsMap.values()),
      sequenceFlows: updatedFlowData,
    };

    if (sequenceId && sequenceId !== undefined) {
      const response = await updateWholeSequence(
        updateSequencePayload,
        channelUid
      );
      if (response) {
        navigate("/sequence");
      }
    }

    // setSubmitting(false);
  };

  const headerDays = ["M", "T", "W", "T", "F", "S", "S"];

  const initialValues = {
    timePreference: "",
    sequenceName:
      (getOverallSequence && getOverallSequence?.sequence?.sequenceName) ?? "",
    triggerOption:
      (getOverallSequence && getOverallSequence?.sequence?.triggerOption) ??
      "Create",
    startCondition:
      (getOverallSequence && getOverallSequence?.sequence?.startCondition) ??
      [],
    stopCondition:
      (getOverallSequence && getOverallSequence?.sequence?.stopCondition) ?? [],
    day:
      (getOverallSequence && getOverallSequence?.sequence?.day) ??
      Array(daysList.length).fill(false),
    sequenceType:
      (getOverallSequence && getOverallSequence?.sequence?.sequenceType) ??
      "event",
    times:
      getOverallSequence && getOverallSequence?.sequence?.customTime
        ? "CustomTime"
        : "DefaultTime",
    replyAssignTo:
      (getOverallSequence && getOverallSequence?.sequence?.replyAssignTo) ??
      "0",
    replyAssignId:
      (getOverallSequence && getOverallSequence?.sequence?.replyAssignId) ?? 0,

    memberId:(getOverallSequence && getOverallSequence?.sequence?.memberId) ?? 0,

    // roundRobin:(getOverallSequence && getOverallSequence?.sequence?.values.roundRobin) ?? false,
  
    sqfromTime:
      (getOverallSequence && getOverallSequence?.sequence?.sqfromTime) ?? "",
    sqtoTime:
      (getOverallSequence && getOverallSequence?.sequence?.sqtoTime) ?? "",
    sequenceFlowSteps: [
      {
        stepNumber: 1,
        delay: "1",
        delayUnit: "minutes",
        name: "",
        templateText: "",
        scheduledTimes: Array(daysList.length).fill(false),
        customTime: "Default",
        templateId: "",
        template: "",
        fromTime: "",
        toTime: "",
      },
    ],
  };

  const animatedComponents = makeAnimated();

  const addFlowSchema = Yup.object().shape({
    delay: Yup.string().required("Delay is required"),
    delayUnit: Yup.string().required("Delay Unit is required"),
    name: Yup.string().nullable().required("Template is required"),
    scheduledTimes: Yup.array().when("customTime", {
      is: "Custom",
      then: Yup.array().test(
        "has-true-value",
        "Select at least one day",
        function (scheduledTimes: any) {
          return scheduledTimes.some((day: any) => day === true);
        }
      ),
      otherwise: Yup.array(),
    }),
    fromTime: Yup.string().when("customTime", {
      is: "Custom",
      then: Yup.string().required("Time required"),
      otherwise: Yup.string(),
    }),
    toTime: Yup.string().when("customTime", {
      is: "Custom",
      then: Yup.string()
        .required("Time required")
        .test("is-valid-time", "Invalid Time", function (toTime) {
          const fromTime = this.parent.fromTime;
          if (fromTime && toTime) {
            return toTime > fromTime;
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
  });

  const validationSchema = Yup.object({
    sequenceName: Yup.string().required("Sequence name is required"),
    sequenceFlowSteps: Yup.array()
      .of(addFlowSchema)
      .min(1, "At least one variable is required"),
    times: Yup.string().required("At least one variable is required"),
    startCondition: Yup.array().when("sequenceType", {
      is: "event",
      then: Yup.array().when("triggerOption", {
        is: (triggerOption: any) =>
          ["Update", "CreateUpdate"].includes(triggerOption),
        then: Yup.array().test(
          "one-array-not-empty",
          "Add condition",
          function (startCondition: any) {
            return startCondition.length > 0;
          }
        ),
        otherwise: Yup.array(),
      }),
      otherwise: Yup.array(),
    }),
    replyAssignId: Yup.number()
      .when('replyAssignTo', {
        is: '1',
        then: Yup.number()
          .test('check-value', 'Select Bot', value => value !== 0),
      })
      .when('replyAssignTo', {
        is: '2',
        then: Yup.number()
          .test('check-value', 'Select Member', value => value !== 0),
      })
      .when('replyAssignTo', {
        is: '3',
        then: Yup.number()
          .test('check-value', 'Select Team', value => value !== 0),
      })
      ,
    day: Yup.array().when("times", {
      is: "CustomTime",
      then: Yup.array().test(
        "has-true-value",
        "Select at least one day",
        function (daysArr: any) {
          return daysArr.some((day: any) => day === true);
        }
      ),
      otherwise: Yup.array(),
    }),
    sqfromTime: Yup.string().when("times", {
      is: "CustomTime",
      then: Yup.string().required("Time required"),
      otherwise: Yup.string(),
    }),
    sqtoTime: Yup.string().when("times", {
      is: "CustomTime",
      then: Yup.string()
        .required("Time required")
        .test("is-valid-time", "Invalid Time", function (toTime) {
          const fromTime = this.parent.sqfromTime;
          if (fromTime && toTime) {
            return toTime > fromTime;
          }
          return true;
        }),
      otherwise: Yup.string(),
    }),
  });

  const variableTypeDraftSchema = Yup.object().shape({
    delay: Yup.string().required("Delay is required"),
    delayUnit: Yup.string().required("Delay Unit is required"),
    name: Yup.string().nullable().required("Template is required"),
  });

  const validationDraftSchema = Yup.object({
    sequenceName: Yup.string().required("Sequence name is required"),
    sequenceFlowSteps: Yup.array()
      .of(variableTypeDraftSchema)
      .min(1, "At least one variable is required"),
    times: Yup.string().required("At least one variable is required"),
    startCondition: Yup.array().when("sequenceType", {
      is: "event",
      then: Yup.array().when("triggerOption", {
        is: (triggerOption: any) =>
          ["Update", "CreateUpdate"].includes(triggerOption),
        then: Yup.array().test(
          "one-array-not-empty",
          "Add condition",
          function (startCondition: any) {
            return startCondition.length > 0;
          }
        ),
        otherwise: Yup.array(),
      }),
      otherwise: Yup.array(),
    }),
  });

  const [activeIndex, setActiveIndex] = useState("1");
  const [variablesCount,setVariablesCount] = useState(0);
  console.log('vvv',variablesCount)

  const handleSelect = (selectedIndex: any) => {
    if (activeIndex === selectedIndex) {
      setActiveIndex("1");
    } else {
      setActiveIndex(selectedIndex);
    }
  };

  const bottomRef = useRef(null);
  const sequenceHeaderRef = document.getElementsByClassName(
    "sequence-main-header"
  );

  return (
    <>
      <div className={"sequenceContainer"} ref={bottomRef}>
        <Row>
          {/* <div className="row"> */}
          {/* Overall form */}
          <Formik
            initialValues={initialValues}
            validationSchema={
              action === "Publish" ? validationSchema : validationDraftSchema
            }
            onSubmit={(e) => handleSubmit(e)}
            enableReinitialize={true}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form>
                <SequenceContext>
                  <>
                    <Col lg={12} className="sequence-form">
                      <Row>
                        {/* <div className="col-12 d-flex justify-content-between sequence-header"> */}
                        <Col
                          lg={12}
                          className="d-lg-flex justify-content-lg-between sequence-header"
                        >
                          <div className="form-headers">
                            <div>
                              <svg
                                onClick={backTosequence}
                                aria-hidden="true"
                                height={20}
                                width={20}
                                className="me-2 cursor-pointer"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="arrow-left"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                              >
                                <path
                                  fill="currentColor"
                                  d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
                                ></path>
                              </svg>
                            </div>
                            <div>
                              <h6>
                                {sequenceId
                                  ? "Update Sequence"
                                  : "Create Sequence"}
                              </h6>
                            </div>
                          </div>
                          <div className="form-handlers">
                            <div className="sequence-log-buton">
                              {getOverallSequence &&
                                getOverallSequence?.sequence?.logAvaliable && (
                                  <Button
                                    className="form-handler-icon"
                                    // onClick={(e: any) => setAction("Draft")}
                                    type="button"
                                  >
                                    <Link
                                      to={`${process.env.PUBLIC_URL}/sequence/${sequenceId}/logs`}
                                      className="text-white"
                                    >
                                      <div className="d-flex flex-row">
                                        <div>
                                          <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            stroke-width="0"
                                            version="1.1"
                                            viewBox="0 0 16 16"
                                            aria-hidden="true"
                                            focusable="false"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M2 14h14v2h-16v-16h2zM4.5 13c-0.828 0-1.5-0.672-1.5-1.5s0.672-1.5 1.5-1.5c0.044 0 0.088 0.002 0.131 0.006l1.612-2.687c-0.154-0.235-0.243-0.517-0.243-0.819 0-0.828 0.672-1.5 1.5-1.5s1.5 0.672 1.5 1.5c0 0.302-0.090 0.583-0.243 0.819l1.612 2.687c0.043-0.004 0.087-0.006 0.131-0.006 0.033 0 0.066 0.001 0.099 0.004l2.662-4.658c-0.165-0.241-0.261-0.532-0.261-0.845 0-0.828 0.672-1.5 1.5-1.5s1.5 0.672 1.5 1.5c0 0.828-0.672 1.5-1.5 1.5-0.033 0-0.066-0.001-0.099-0.004l-2.662 4.658c0.165 0.241 0.261 0.532 0.261 0.845 0 0.828-0.672 1.5-1.5 1.5s-1.5-0.672-1.5-1.5c0-0.302 0.090-0.583 0.243-0.819l-1.612-2.687c-0.043 0.004-0.087 0.006-0.131 0.006s-0.088-0.002-0.131-0.006l-1.612 2.687c0.154 0.235 0.243 0.517 0.243 0.819 0 0.828-0.672 1.5-1.5 1.5z"></path>
                                          </svg>
                                        </div>
                                        <div>Logs</div>
                                      </div>
                                    </Link>
                                  </Button>
                                )}
                            </div>
                            <div className="save-as-draft">
                              <Button
                                className="form-handler-icon"
                                onClick={(e: any) => {
                                  setAction("Draft");
                                  setError(true);
                                }}
                                type="submit"
                              >
                                <div className="d-flex flex-row">
                                  <div>
                                    <FaFile size={15} />
                                  </div>
                                  <div>Save as Draft</div>
                                </div>
                              </Button>
                            </div>
                            <div className="publish-sq">
                              <Button
                                className="form-handler-icon"
                                onClick={() => {
                                  setAction("Publish");
                                  setError(true);
                                }}
                                type="submit"
                              >
                                <div className="d-flex flex-row">
                                  <div>
                                    <HiPencil size={15} />
                                  </div>
                                  <div>
                                    {getOverallSequence?.sequence?.status ===
                                      "Publish"
                                      ? "Republish"
                                      : "Publish"}
                                  </div>
                                </div>
                              </Button>
                            </div>
                            <div className="cancel-sq">
                              <Button
                                className="form-handler-icon-cancel"
                                onClick={backTosequence}
                              >
                                <div className="d-flex flex-row">
                                  <div id="cancel-icon">
                                    <RiArrowLeftDoubleLine size={20} />
                                  </div>
                                  <div>Cancel</div>
                                </div>
                              </Button>
                            </div>
                          </div>
                        </Col>

                        <Col lg={12} className="sequence-main-header">
                          <>
                            <Accordion
                              activeKey={activeIndex}
                              onSelect={handleSelect}
                            >
                              <Accordion.Item eventKey="1">
                                <Accordion.Header
                                  as="div"
                                  className={
                                    activeIndex === "1" ? "active" : ""
                                  }
                                >
                                  <Row className="accordian-header">
                                    <Col
                                      lg={1}
                                      md={1}
                                      className="p-0 d-flex justify-content-center align-items-center"
                                      id="seq-profile-icon"
                                    >
                                      <img
                                        src="data:image/svg+xml,%3csvg%20width='45'%20height='45'%20viewBox='0%200%2045%2045'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3crect%20x='0.5'%20y='0.5'%20width='44'%20height='44'%20rx='22'%20fill='white'/%3e%3cpath%20d='M22.9474%2024.7111V26.558C22.1376%2026.2753%2021.2709%2026.1885%2020.4201%2026.3051C19.5693%2026.4217%2018.7593%2026.7382%2018.058%2027.2281C17.3568%2027.718%2016.7847%2028.3669%2016.39%2029.1203C15.9952%2029.8738%2015.7893%2030.7098%2015.7895%2031.5581L14%2031.5573C13.9997%2030.4781%2014.2496%2029.4133%2014.7304%2028.4443C15.2112%2027.4752%2015.9102%2026.6278%2016.7739%2025.9669C17.6376%2025.3059%2018.6429%2024.849%2019.713%2024.6312C20.7831%2024.4134%2021.8895%2024.4404%2022.9474%2024.7102V24.7111ZM21.1579%2023.6047C18.1918%2023.6047%2015.7895%2021.2319%2015.7895%2018.3023C15.7895%2015.3728%2018.1918%2013%2021.1579%2013C24.1239%2013%2026.5263%2015.3728%2026.5263%2018.3023C26.5263%2021.2319%2024.1239%2023.6047%2021.1579%2023.6047ZM21.1579%2021.8372C23.1353%2021.8372%2024.7368%2020.2553%2024.7368%2018.3023C24.7368%2016.3493%2023.1353%2014.7674%2021.1579%2014.7674C19.1805%2014.7674%2017.5789%2016.3493%2017.5789%2018.3023C17.5789%2020.2553%2019.1805%2021.8372%2021.1579%2021.8372ZM28.3158%2027.1395H31V28.907H28.3158V32L23.8421%2028.0233L28.3158%2024.0465V27.1395Z'%20fill='black'/%3e%3crect%20x='0.5'%20y='0.5'%20width='44'%20height='44'%20rx='22'%20stroke='%23E2E8F0'/%3e%3c/svg%3e"
                                        className="chakra-image css-0"
                                      />
                                    </Col>
                                    <Col lg={11} className="p-0">
                                      <Row>
                                        <Col lg={12} className="ms-2">
                                          <h6>
                                            Create Sequence & Set Sequence
                                            Triggers and Delivery Preferences
                                          </h6>
                                        </Col>
                                        <Col lg={12}>
                                          {!sequenceId ? (
                                            <div className="ms-2">
                                              <small
                                                style={{ fontSize: "x-small" }}
                                              >
                                                The sequence will be triggered
                                                based on on the set conditions
                                              </small>
                                            </div>
                                          ) : (
                                            // <div className="d-flex flex-row ms-2">
                                            <Row>
                                              <Col sm={3} md={4} lg={3}>
                                                <div>
                                                  <small
                                                    style={{
                                                      fontSize: "smaller",
                                                      marginLeft: "6px",
                                                    }}
                                                  >
                                                    Trigger: {SelectTriger.find((el: any) => el.value === values.triggerOption)?.label}
                                                  </small>
                                                </div>
                                              </Col>
                                              <Col sm={3} md={3} lg={2} className="p-0">
                                                <div>
                                                  <GoClock
                                                    color="#a3a5a9"
                                                    className="ms-2"
                                                  />
                                                  <small
                                                    style={{
                                                      fontSize: "smaller",
                                                    }}
                                                  >
                                                    {values.times ===
                                                      "DefaultTime" ? (
                                                      <> Any Time |</>
                                                    ) : (
                                                      <> Custom Time |</>
                                                    )}
                                                  </small>
                                                </div>
                                              </Col>
                                              <Col sm={12} md={5} lg={7} className="p-0">
                                                <div className="d-flex align-items-center">
                                                  {headerDays.map(
                                                    (
                                                      el: any,
                                                      index: number
                                                    ) => {
                                                      return (
                                                        <small
                                                          style={{
                                                            fontSize: "smaller",
                                                            color:
                                                              values.times ===
                                                                "DefaultTime"
                                                                ? "#2f855a"
                                                                : values.day[
                                                                  index
                                                                ]
                                                                  ? "#2f855a"
                                                                  : "black",
                                                            border:
                                                              "1px solid #E2E8F0",
                                                            background:
                                                              values.times ===
                                                                "DefaultTime"
                                                                ? "#c6f6d5"
                                                                : values.day[
                                                                  index
                                                                ]
                                                                  ? "#c6f6d5"
                                                                  : "white",
                                                            marginLeft: "6px",
                                                            padding: "1px 6px",
                                                            borderRadius: "3px",
                                                          }}
                                                        >
                                                          {el}
                                                        </small>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </Col>
                                            </Row>
                                          )}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Accordion.Header>
                                <Accordion.Body>
                                  {/* <div className="row sequence"> */}
                                  <Row className="sequence">
                                    <Col
                                      sm={12}
                                      md={6}
                                      lg={6}
                                      className="d-flex mb-3"
                                    >
                                      <Row>
                                        <Col
                                          sm={12}
                                          lg={5}
                                          md={5}
                                          className="d-flex justify-content-center align-items-center pe-0"
                                        >
                                          <div className="custom-label">
                                            <Label>Sequence Name</Label>
                                            <span className="redstar_required"></span>
                                          </div>
                                        </Col>
                                        <Col
                                          sm={12}
                                          md={5}
                                          lg={7}
                                          className="ps-2"
                                        >
                                          <div className="custom-field">
                                            <Field
                                              type="text"
                                              name="sequenceName"
                                              id="sequenceName"
                                              onKeyDown={handleKeyDown}
                                              maxLength={60}
                                              className="form-control"
                                              placeholder="Enter sequence name"
                                              style={{ marginLeft: "2px" }}
                                            />
                                            <ErrorMessage
                                              className="text-danger pt-1"
                                              name="sequenceName"
                                              component="div"
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col
                                      sm={12}
                                      md={6}
                                      lg={6}
                                      className="d-flex mb-3"
                                    >
                                      <Row>
                                        <Col
                                          sm={12}
                                          lg={5}
                                          md={5}
                                          className="d-flex justify-content-center align-items-center pe-0"
                                        >
                                          <div className="custom-label">
                                            <Label>Sequence Type</Label>
                                            <span className="redstar_required"></span>
                                          </div>
                                        </Col>
                                        <Col
                                          sm={12}
                                          md={5}
                                          lg={7}
                                          className="ps-2"
                                        >
                                          <div className="custom-field">
                                            <Field name="sequenceType">
                                              {({ field, form }: any) => (
                                                <Select
                                                  options={sequenceOptions}
                                                  className="pt-1"
                                                  components={
                                                    animatedComponents
                                                  }
                                                  value={sequenceOptions.find(
                                                    (option) =>
                                                      option.value ===
                                                      field.value
                                                  )}
                                                  placeholder="Enter a Filter"
                                                  onChange={(option: any) => {
                                                    form.setFieldValue(
                                                      field.name,
                                                      option.value
                                                    );
                                                  }}
                                                  styles={customStyles}
                                                />
                                              )}
                                            </Field>
                                            <ErrorMessage
                                              className="text-danger pt-1"
                                              name="sequenceType"
                                              component="div"
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>

                                  {values.sequenceType === "event" && (
                                    // <div className="row sequence-event mb-2">
                                    <Row className="sequence-event mb-2">
                                      <Col lg={12} className="p-0 mb-2">
                                        <div className="fw-bold">
                                          Set trigger and conditions
                                        </div>
                                      </Col>
                                      <Col sm={12} lg={2} className="p-0 mb-2">
                                        <div className="custom-label">
                                          <label>
                                            Trigger
                                            {values.triggerOption ===
                                              "Update" ||
                                              values.triggerOption ===
                                              "CreateUpdate" ? (
                                              <span className="redstar_required"></span>
                                            ) : (
                                              <></>
                                            )}
                                          </label>
                                        </div>
                                      </Col>
                                      <Col sm={12} lg={10} className="p-0 mb-2">
                                        <div className="d-lg-flex d-md-flex d-sm-block">
                                          {SelectTriger.map((select: any) => (
                                            <div className="mr-4">
                                              <label
                                                htmlFor={`selectTrigger-${select.value}`}
                                                className="d-flex align-items-center"
                                              >
                                                <Field
                                                  type="radio"
                                                  name="triggerOption"
                                                  className="me-1"
                                                  value={select.value} // Set the value based
                                                  id={`selectTrigger-${select.value}`} // Unique id for each radio button
                                                  checked={
                                                    values.triggerOption ===
                                                    select.value
                                                  } // Determine if the radio button should be checked
                                                  onChange={(e: any) => {
                                                    const { name, value } =
                                                      e.target;
                                                    setFieldValue(name, value);
                                                  }}
                                                />
                                                {select.label}
                                              </label>
                                            </div>
                                          ))}
                                        </div>
                                      </Col>
                                      <Col lg={12} className="p-0">
                                        {values.triggerOption === "Trigger" ? (
                                          <></>
                                        ) : (
                                          <>
                                            {/* <div className="row conditions"> */}
                                            <Row className="conditions">
                                              <Col
                                                lg={2}
                                                md={2}
                                                sm={12}
                                                className="mb-2 pe-0 d-flex align-items-center"
                                              >
                                                <div className="custom-label m-0">
                                                  <label>
                                                    Start conditions
                                                    {values.triggerOption ===
                                                      "Update" ||
                                                      values.triggerOption ===
                                                      "CreateUpdate" ? (
                                                      <span className="redstar_required"></span>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </label>
                                                </div>
                                              </Col>
                                              <Col
                                                sm={12}
                                                lg={10}
                                                md={10}
                                                className="mb-2"
                                              >
                                                {isAddModal && (
                                                  <Modal
                                                    isOpen={true}
                                                    size="xl"
                                                    toggle={() =>
                                                      setIsAddModal(!isAddModal)
                                                    }
                                                  >
                                                    <ModalHeader
                                                      toggle={() =>
                                                        setIsAddModal(
                                                          !isAddModal
                                                        )
                                                      }
                                                    ></ModalHeader>
                                                    <ModalBody style={variablesCount > 10 ? { maxHeight: '85vh', overflow: 'scroll' }:{}}>
                                                      <div>
                                                        <AddVariablesDub
                                                          variableType={
                                                            variableType
                                                          }
                                                          onClose={
                                                            handleConditionClose
                                                          }
                                                          forms={
                                                            variableType ===
                                                              "START"
                                                              ? values.startCondition
                                                              : values.stopCondition
                                                          }
                                                          setVariablesCount = {setVariablesCount}
                                                        />
                                                      </div>
                                                    </ModalBody>
                                                  </Modal>
                                                )}

                                                <Row>
                                                  <Col
                                                    lg={1}
                                                    md={1}
                                                    sm={12}
                                                    className="condition-button me-3"
                                                  >
                                                    <button
                                                      type="button"
                                                      onClick={() => {
                                                        handleStart_Stop(
                                                          "START"
                                                        );
                                                      }}
                                                    >
                                                      <div className="d-flex">
                                                        <div className="me-1">
                                                          +
                                                        </div>
                                                        <div>Add</div>
                                                      </div>
                                                    </button>
                                                  </Col>

                                                  <Col
                                                    sm={12}
                                                    md={10}
                                                    lg={10}
                                                    className="p-0"
                                                  >
                                                    <div className="sequence-variables">
                                                      <div className="d-flex">
                                                        {values?.startCondition
                                                          ?.slice(0, 2)
                                                          ?.map(
                                                            (
                                                              item: any,
                                                              index: number
                                                            ) => (
                                                              <div className="variables d-flex">
                                                                <div className="field">
                                                                  {item.field}
                                                                </div>
                                                                <div className="filter">
                                                                  {item.filter}
                                                                </div>
                                                                <div className="value">
                                                                  {sliceText(
                                                                    item.value,
                                                                    10
                                                                  )}
                                                                </div>
                                                                <div className="remove-variable">
                                                                  <FontAwesomeIcon
                                                                    icon={
                                                                      faXmarkCircle
                                                                    }
                                                                    className={
                                                                      styles.fa_circle_xmark
                                                                    }
                                                                    onClick={() => {
                                                                      const updatedStartCondition =
                                                                        values.startCondition.filter(
                                                                          (
                                                                            item: any,
                                                                            _index: number
                                                                          ) =>
                                                                            _index !==
                                                                            index
                                                                        );
                                                                      setFieldValue(
                                                                        "startCondition",
                                                                        updatedStartCondition
                                                                      );
                                                                    }}
                                                                  />
                                                                </div>
                                                              </div>

                                                              // </div>
                                                            )
                                                          )}
                                                        <ErrorMessage
                                                          className="text-danger pt-1"
                                                          name="startCondition"
                                                          component="div"
                                                        />
                                                      </div>
                                                      <div className="funnel-icon m-0">
                                                        {values?.startCondition
                                                          ?.length > 2 && (
                                                            <>
                                                              {/* {showStartDropdown && ( */}
                                                              <UncontrolledDropdown className="ms-3">
                                                                <DropdownToggle className="funnel-button p-1">
                                                                  <IoIosFunnel
                                                                    color="#00b598"
                                                                    size={18}
                                                                    className="icon"
                                                                  />
                                                                  <small>
                                                                    +{" "}
                                                                    {values
                                                                      ?.startCondition
                                                                      .length - 2}
                                                                  </small>
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                  {values?.startCondition &&
                                                                    values?.startCondition?.map(
                                                                      (
                                                                        item: any,
                                                                        index: any
                                                                      ) =>
                                                                        index >
                                                                          1 ? (
                                                                          <DropdownItem
                                                                            key={
                                                                              index
                                                                            }
                                                                          >
                                                                            <Row>
                                                                              <Col>
                                                                                {" "}
                                                                                <div>
                                                                                  <Label>
                                                                                    {
                                                                                      item.field
                                                                                    }
                                                                                  </Label>
                                                                                  <Label>
                                                                                    {
                                                                                      item.filter
                                                                                    }
                                                                                  </Label>
                                                                                  <Label>
                                                                                    {sliceText(
                                                                                      item.value,
                                                                                      10
                                                                                    )}
                                                                                  </Label>
                                                                                  <Label className="ms-2">
                                                                                    <FontAwesomeIcon
                                                                                      icon={
                                                                                        faXmarkCircle
                                                                                      }
                                                                                      className={
                                                                                        styles.fa_circle_xmark
                                                                                      }
                                                                                      onClick={() => {
                                                                                        const updatedStartCondition =
                                                                                          values.startCondition.filter(
                                                                                            (
                                                                                              item: any,
                                                                                              _index: number
                                                                                            ) =>
                                                                                              _index !==
                                                                                              index
                                                                                          );
                                                                                        setFieldValue(
                                                                                          "startCondition",
                                                                                          updatedStartCondition
                                                                                        );
                                                                                      }}
                                                                                    />
                                                                                  </Label>
                                                                                </div>
                                                                              </Col>
                                                                            </Row>
                                                                          </DropdownItem>
                                                                        ) : null
                                                                    )}
                                                                </DropdownMenu>
                                                              </UncontrolledDropdown>
                                                            </>
                                                          )}
                                                      </div>

                                                    </div>
                                                  </Col>
                                                </Row>
                                              </Col>

                                              <Col
                                                md={2}
                                                lg={2}
                                                sm={12}
                                                className="mb-2 pe-0 d-flex align-items-center"
                                              >
                                                <div className="custom-label m-0">
                                                  <label>
                                                    Stop conditions
                                                    {/* {values.triggerOption ===
                                                      "Update" ||
                                                    values.triggerOption ===
                                                      "CreateUpdate" ? (
                                                      <span className="redstar_required"></span>
                                                    ) : (
                                                      <></>
                                                    )} */}
                                                  </label>
                                                </div>
                                              </Col>
                                              <Col
                                                md={10}
                                                lg={10}
                                                sm={12}
                                                className="mb-2"
                                              >
                                                <Row>
                                                  <Col
                                                    md={1}
                                                    lg={1}
                                                    sm={12}
                                                    className="condition-button me-3"
                                                  >
                                                    <button
                                                      type="button"
                                                      onClick={() => {
                                                        handleStart_Stop(
                                                          "STOP"
                                                        );
                                                      }}
                                                    >
                                                      <div className="d-flex">
                                                        <div className="me-1">
                                                          +
                                                        </div>
                                                        <div>Add</div>
                                                      </div>
                                                    </button>
                                                  </Col>

                                                  <Col
                                                    md={10}
                                                    lg={10}
                                                    sm={12}
                                                    className="p-0"
                                                  >
                                                    <div className="sequence-variables">
                                                      <div className="d-flex">
                                                        {values?.stopCondition
                                                          ?.slice(0, 2)
                                                          ?.map(
                                                            (
                                                              item: any,
                                                              index: number
                                                            ) => (
                                                              <div className="variables d-flex">
                                                                <div className="field">
                                                                  {item.field}
                                                                </div>
                                                                <div className="filter">
                                                                  {item.filter}
                                                                </div>
                                                                <div className="value">
                                                                  {sliceText(
                                                                    item.value,
                                                                    10
                                                                  )}
                                                                </div>
                                                                <div className="remove-variable">
                                                                  <FontAwesomeIcon
                                                                    icon={
                                                                      faXmarkCircle
                                                                    }
                                                                    className={
                                                                      styles.fa_circle_xmark
                                                                    }
                                                                    onClick={() => {
                                                                      const updatedStopCondition =
                                                                        values.stopCondition.filter(
                                                                          (
                                                                            item: any,
                                                                            _index: number
                                                                          ) =>
                                                                            _index !==
                                                                            index
                                                                        );
                                                                      setFieldValue(
                                                                        "stopCondition",
                                                                        updatedStopCondition
                                                                      );
                                                                    }}
                                                                  />
                                                                </div>
                                                              </div>

                                                              // </div>
                                                            )
                                                          )}
                                                        {/* <ErrorMessage
                                                          className="text-danger pt-1"
                                                          name="stopCondition"
                                                          component="div"
                                                        /> */}
                                                      </div>
                                                      <div className="funnel-icon m-0">
                                                        {values?.stopCondition
                                                          ?.length > 2 && (
                                                            <>
                                                              {/* {showStartDropdown && ( */}
                                                              <UncontrolledDropdown className="ms-3">
                                                                <DropdownToggle className="funnel-button p-1">
                                                                  <IoIosFunnel
                                                                    color="#00b598"
                                                                    size={18}
                                                                    className="icon"
                                                                  />

                                                                  <small>
                                                                    +{" "}
                                                                    {values
                                                                      ?.stopCondition
                                                                      .length - 2}
                                                                  </small>
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                  {values?.stopCondition &&
                                                                    values?.stopCondition?.map(
                                                                      (
                                                                        item: any,
                                                                        index: any
                                                                      ) =>
                                                                        index >
                                                                          1 ? (
                                                                          <DropdownItem
                                                                            key={
                                                                              index
                                                                            }
                                                                          >
                                                                            <Row>
                                                                              <Col>
                                                                                {" "}
                                                                                <div>
                                                                                  <Label>
                                                                                    {
                                                                                      item.field
                                                                                    }
                                                                                  </Label>
                                                                                  <Label>
                                                                                    {
                                                                                      item.filter
                                                                                    }
                                                                                  </Label>
                                                                                  <Label>
                                                                                    {sliceText(
                                                                                      item.value,
                                                                                      10
                                                                                    )}
                                                                                  </Label>
                                                                                  <Label className="ms-2">
                                                                                    <FontAwesomeIcon
                                                                                      icon={
                                                                                        faXmarkCircle
                                                                                      }
                                                                                      className={
                                                                                        styles.fa_circle_xmark
                                                                                      }
                                                                                      onClick={() => {
                                                                                        const updatedStopCondition =
                                                                                          values.stopCondition.filter(
                                                                                            (
                                                                                              item: any,
                                                                                              _index: number
                                                                                            ) =>
                                                                                              _index !==
                                                                                              index
                                                                                          );
                                                                                        setFieldValue(
                                                                                          "stopCondition",
                                                                                          updatedStopCondition
                                                                                        );
                                                                                      }}
                                                                                    />
                                                                                  </Label>
                                                                                </div>
                                                                              </Col>
                                                                            </Row>
                                                                          </DropdownItem>
                                                                        ) : null
                                                                    )}
                                                                </DropdownMenu>
                                                              </UncontrolledDropdown>
                                                            </>
                                                          )}
                                                      </div>
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </Col>
                                            </Row>
                                          </>
                                        )}
                                      </Col>
                                    </Row>
                                  )}
                                  <div className="row delivery-preference">
                                    <div className="col-12 d-flex mb-1 p-0">
                                      <label className="fw-bold">
                                        Set sequence delivery preference
                                      </label>
                                      <span className="redstar_required"></span>
                                    </div>
                                    {SelectTimes.map((select: any) => (
                                      <div className="col-2 mb-2 p-0">
                                        <label
                                          htmlFor={`SelectTimes-${select.value}`}
                                          className="d-flex  align-items-center"
                                        >
                                          <Field
                                            type="radio"
                                            name="times"
                                            className="me-1"
                                            value={select.value} // Set the value based on
                                            id={`SelectTimes-${select.value}`} // Unique id for each radio button
                                            checked={
                                              values.times === select.value
                                            }
                                            onChange={(e: any) => {
                                              const { name, value } = e.target;
                                              setFieldValue(name, value);
                                              handleTimeChange(e);
                                            }}
                                          />
                                          {select.label}
                                          &nbsp;&nbsp;
                                          {select.value === "DefaultTime" &&
                                            values?.times === "DefaultTime" && (
                                              <TooltipReference
                                                placement="bottom"
                                                content="Messages can be sent anytime between
                              12:00 am to 11:59 pm"
                                                tooltipId="displayName"
                                                icon={faInfoCircle}
                                              ></TooltipReference>
                                            )}
                                        </label>
                                      </div>
                                    ))}

                                    {values?.times === "CustomTime" && (
                                      <div className="col-12">
                                        <div className="row">
                                          <div className="col-2 mb-2">
                                            <div className="custom-label d-flex justify-content-center">
                                              <label>
                                                Days{" "}
                                                <span className="redstar_required"></span>
                                              </label>
                                            </div>
                                          </div>
                                          <div
                                            className="col-10 mb-2"
                                            id="seq-day-selection"
                                          >
                                            {values.day && (
                                              <DaySelection
                                                daysList={daysList}
                                                field={{
                                                  name: "day",
                                                  value: values.day,
                                                }}
                                                scheduledTimes={values.day}
                                              />
                                            )}
                                            <ErrorMessage
                                              className="text-danger pt-1"
                                              name="day"
                                              component="div"
                                            />
                                          </div>
                                          <div className="col-2">
                                            <div className="custom-label d-flex justify-content-center">
                                              <label>
                                                Time{" "}
                                                <span className="redstar_required"></span>
                                              </label>
                                            </div>
                                          </div>
                                          <div className="col-2">
                                            <Field
                                              type="time"
                                              className="form-control cursor-pointer"
                                              name="sqfromTime"
                                              value={values.sqfromTime}
                                              onChange={(e: any) => {
                                                setFieldValue(
                                                  "sqfromTime",
                                                  e.target.value
                                                );
                                              }}
                                            />
                                            <ErrorMessage
                                              className="text-danger pt-1"
                                              name="sqfromTime"
                                              component="div"
                                            />
                                          </div>
                                          <div
                                            className="col-1 p-0 d-flex justify-content-center align-items-center"
                                            style={{ width: "3rem" }}
                                          >
                                            <HiMiniArrowSmallRight size={30} />
                                          </div>
                                          <div className="col-2">
                                            <Field
                                              type="time"
                                              className="form-control cursor-pointer"
                                              name="sqtoTime"
                                              value={values.sqtoTime}
                                              onChange={(e: any) => {
                                                setFieldValue(
                                                  "sqtoTime",
                                                  e.target.value
                                                );
                                              }}
                                            />
                                            <ErrorMessage
                                              className="text-danger pt-1"
                                              name="sqtoTime"
                                              component="div"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    <div className="col-12  pt-3 ps-0">
                                      <label className="fw-bold p-0">
                                        Reply Settings ( Optional )
                                      </label>
                                    </div>
                                    <div className="col-12 ">
                                      <Row className="botNav">
                                        <Col md={6} className="p-0">
                                          <label className="row">
                                            {AssignOptions.map(
                                              (assign: any, index: number) => (
                                                <li
                                                  key={assign.value}
                                                  className={`radioOption col-4 ${assign.value === "2" &&
                                                    "p-0"
                                                    }`}
                                                  id='assignOption'
                                                >
                                                  <label
                                                    htmlFor={`replyAssignTo-${assign.value}`}
                                                    className="d-flex"
                                                  >
                                                    <Field
                                                      type="radio"
                                                      id={`replyAssignTo-${assign.value}`}
                                                      name="replyAssignTo"
                                                      checked={
                                                        values.replyAssignTo ===
                                                        assign.value
                                                      }
                                                      onChange={(e: any) => {
                                                        setFieldValue('replyAssignId', 0)
                                                        const { name, value } =
                                                          e.target;
                                                        setFieldValue(
                                                          name,
                                                          value
                                                        );
                                                        setroundRobin(false);
                                                      }}
                                                      value={assign.value}
                                                    />
                                                    <div className="ms-1">
                                                      {assign.label}
                                                    </div>
                                                  </label>
                                                </li>
                                              )
                                            )}
                                          </label>
                                        </Col>
                                        <Col md={6} >
                                          {values.replyAssignTo !== "0" && values.replyAssignTo !== "3" ? (
                                            <Row>
                                              <Col md={6}>
                                                <>
                                                  <Field name="replyAssignId">
                                                    {({ field, form }: any) => (
                                                      <Select
                                                        {...field}
                                                        name="replyAssignId"
                                                        options={
                                                          values.replyAssignTo ===
                                                            "1"
                                                            ? botDataOption
                                                            : memberDataOption
                                                        }
                                                        placeholder={
                                                          values.replyAssignTo ===
                                                            "1"
                                                            ? "Select Bot"
                                                            : "Select Member"
                                                        }
                                                        className="replyAssignId"
                                                        value={
                                                          values.replyAssignTo ===
                                                            "1"
                                                            ? values.replyAssignId && botDataOption?.find(
                                                              (options: any) =>
                                                                options.value ===
                                                                Number(values.replyAssignId)
                                                            )
                                                            : values.replyAssignId && memberDataOption?.find(
                                                              (options: any) =>
                                                                options.value ===
                                                                Number(values.replyAssignId)
                                                            )
                                                        }
                                                        onChange={(e: any) => {
                                                          setFieldValue(
                                                            "replyAssignId",
                                                            e?.value
                                                          );
                                                        }}
                                                        styles={customStyles}
                                                      />
                                                    )}
                                                  </Field>
                                                  <ErrorMessage
                                                    name="replyAssignId"
                                                    component="div"
                                                    className="text-danger"
                                                  />
                                                </>
                                              </Col>
                                              <Col lg={5}>
                                                <div className="remove-radio mt-2">
                                                  <FontAwesomeIcon
                                                    icon={faXmarkCircle}
                                                    className="x-mark"
                                                    onClick={() => {
                                                      setFieldValue(
                                                        "replyAssignTo",
                                                        "0"
                                                      );
                                                      setFieldValue(
                                                        "replyAssignId", 0
                                                      )
                                                    }}
                                                  />
                                                </div>
                                              </Col>
                                            </Row>

                                          ) : null}
                                           {values.replyAssignTo === "3" ? (
                                            <Row>
                                              <Col lg={6}>
                                                <>
                                                  <Field name="replyAssignId">
                                                    {({ field, form }: any) => (
                                                      <Select
                                                        {...field}
                                                        name="replyAssignId"
                                                        options={
                                                         teamDataOption
                                                        }
                                                        placeholder="Select Team"
                                                        className="replyAssignId"
                                                        value={
                                                         values?.replyAssignId && teamDataOption?.find(
                                                              (options: any) =>
                                                                options.value ===
                                                                Number(values.replyAssignId)
                                                            )
                                                        }
                                                        onChange={(e: any) => {
                                                          
                                                          setFieldValue(
                                                            "replyAssignId",
                                                            e?.value
                                                          );
                                                          getTeamMemberData(e?.uid)
                                                        }}
                                                        styles={customStyles}
                                                      />
                                                    )}
                                                  </Field>
                                                  <ErrorMessage
                                                    name="replyAssignId"
                                                    component="div"
                                                    className="text-danger"
                                                  />
                                                  
                                                </>
                                              </Col>
                                              <Col lg={5}>
                                                <div className="remove-radio mt-2">
                                                  <FontAwesomeIcon
                                                    icon={faXmarkCircle}
                                                    className="x-mark"
                                                    onClick={() => {
                                                      setFieldValue(
                                                        "replyAssignTo",
                                                        "0"
                                                      );
                                                      setFieldValue(
                                                        "replyAssignId", 0
                                                      )
                                                    }}
                                                  />
                                                </div>
                                              </Col>
                                            </Row>

                                          ) : null}
                              
                                        </Col>
                                      </Row>
                                    </div>
                                    <div className="col-12 d-flex p-0">
                                      <Field
                                        type="checkbox"
                                        checked={isRepeatContact}
                                        onChange={currentRepeat}
                                      />{" "}
                                      &nbsp;&nbsp;
                                      <Label className="mt-2">
                                        Enable contacts to engage in this
                                        sequence just once.
                                      </Label>
                                    </div>
                                    
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </>
                        </Col>
                      </Row>
                      <div className="addFlow">
                        <AddFlow
                          setFieldValue={setFieldValue}
                          getFlowData={getFlowData}
                          getOverallSequence={getOverallSequence}
                          updateSequence={updateSequence}
                          setIsBackAlert={setIsBackAlert}
                          daysList={daysList}
                          error={error}
                          bottomRef={bottomRef}
                          sequenceHeaderRef={sequenceHeaderRef}
                        />
                      </div>
                    </Col>

                    {/* <div className="col-2 d-flex justify-content-center align-items-center form-handler p-0">
                      <div className="row">
                        <div className="col-12">
                          
                        </div>
                        <div className="col-12">
                          
                        </div>
                        <div className="col-12">
                          
                        </div>
                        
                        <div className="col-12">
                          
                        </div>
                      </div>
                    </div> */}
                  </>
                </SequenceContext>
              </Form>
            )}
          </Formik>
        </Row>
      </div>
    </>
  );
};

export default CreateSequenece;

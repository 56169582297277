export enum InputType {
    Text,
    YesNo,
    Variable,
    Dropdown,
    PaymentId,
    PaymentLink
}

type QueryParam = {
    field: string,
    condition: 'EQUALS',
    value: any
}

type DropdownOption = {
    label: string,
    value: string,
    disabled?: boolean
}

export interface IActionsField {
    id: string,
    label: string
    display: boolean | QueryParam
    required: boolean | QueryParam
    type: InputType
    dropdownOption: DropdownOption[] | null
    placeholder?: string | null
    index?: number
}

export default class ActionsField implements IActionsField {
    id: string
    label: string
    display: boolean | QueryParam = true
    required: boolean | QueryParam = false
    type: InputType = InputType.Text
    dropdownOption: DropdownOption[] | null = null
    placeholder?: string | null = null
    index?: number = 0

    constructor(idOrEntity: string | IActionsField, label?: string, placeholder?: string, index?: number) {
        if (typeof idOrEntity === 'string') {
            this.id = idOrEntity
            this.label = label || ''
            this.placeholder = placeholder || ''
            this.index = index || 0
        } else {
            const entity = idOrEntity as IActionsField
            this.id = entity.id
            this.label = entity.label
            this.required = entity.required
            this.placeholder = entity.placeholder
            this.index = entity.index
        }
    }

    toString(): string {
        return this.id
    }

    setDisplay(display: boolean | QueryParam) {
        this.display = display
        return this
    }

    setRequired(required: boolean | QueryParam) {
        this.required = required
        return this
    }

    setType(type: InputType) {
        this.type = type
        return this
    }

    setDropdownOption(dropdownOption: DropdownOption[] | null) {
        this.dropdownOption = dropdownOption
        return this
    }
}

// Shared
export const StorageVariable = new ActionsField('storageVariable', 'Create or Select variable')
    .setType(InputType.Variable)
    .setRequired(true)

// Zoho CRM
export const ZOHO_CRM_Last_name = new ActionsField('Last_Name', 'Last Name')
export const ZOHO_CRM_Company = new ActionsField('Company', 'Company')
export const ZOHO_CRM_First_Name = new ActionsField('First_Name', 'First Name')
export const ZOHO_CRM_Email = new ActionsField('Email', 'Email')
export const ZOHO_CRM_Lead_Source = new ActionsField('Lead_Source', 'Lead Source')
export const ZOHO_CRM_Industry = new ActionsField('Industry', 'Lead Source')
export const ZOHO_CRM_Email_Opt_Out = new ActionsField('Email_Opt_Out', 'Email Opt Out')
export const ZOHO_CRM_Fax = new ActionsField('Fax', 'Fax')
export const ZOHO_CRM_Lead_Status = new ActionsField('Lead_Status', 'Lead Status')
export const ZOHO_CRM_Mobile = new ActionsField('Mobile', 'Mobile')
export const ZOHO_CRM_Phone = new ActionsField('Phone', 'Phone')
export const ZOHO_CRM_Email_2 = new ActionsField('Email_2', 'Secondary Email')
export const ZOHO_CRM_State = new ActionsField('State', 'State')
export const ZOHO_CRM_Twitter = new ActionsField('Twitter', 'Twitter')
export const ZOHO_CRM_Website = new ActionsField('Website', 'Website')
export const ZOHO_CRM_Description = new ActionsField('Description', 'Description')
export const ZOHO_CRM_Skype_ID = new ActionsField('Skype_ID', 'Skype ID')
export const ZOHO_CRM_NO_OF_EMPLOYESS = new ActionsField("No_of_Employees","Number of Employees");

// Razorpay
export const RAZOR_PAY_amount = new ActionsField('amount', 'Amount').setRequired(true)
export const RAZOR_PAY_currency = new ActionsField('currency', 'Currency')
    .setType(InputType.Dropdown)
    .setDropdownOption([
        { label: '', value: '', disabled: true },
        { label: 'US Doller (USD)', value: 'USD', disabled: false },
        { label: 'Indian Rupee (INR)', value: 'INR', disabled: false },
    ])
export const RAZOR_PAY_accept_partial = new ActionsField('accept_partial', 'Accept Partial')
    .setType(InputType.YesNo)
    .setRequired(false)
export const RAZOR_PAY_connection_id=new ActionsField('payment_id', 'Save Payment Id')
.setType(InputType.PaymentId)
.setRequired(true)
export const RAZOR_PAY_payment_link=new ActionsField('storageVariable', 'Save Payment Link')
.setType(InputType.Variable)
.setRequired(true)
export const RAZOR_PAY_first_min_partial_amount = new ActionsField('first_min_partial_amount', 'First Minimum Partial Amount')
    .setDisplay({ field: 'accept_partial', condition: 'EQUALS', value: true })
    .setRequired({ field: 'accept_partial', condition: 'EQUALS', value: true })


// Google Sheets 
export const GoogleSheets_SpreadSheet = new ActionsField('spreadSheetId', 'SpreadSheet', 'Select SpreadSheet')
export const GoogleSheets_Sheet = new ActionsField('sheetName', 'Sheet', 'Select Sheet')
export const GoogleSheets_Column = new ActionsField('column', 'Column', 'Select Column')

// Shopify 


// Shopify replace order tag
export const SHOPIFY_orderId = new ActionsField('orderId', 'Order ID','Select Order ID').setRequired(true)
export const SHOPIFY_tags = new ActionsField('tags', 'Tags','Enter Tags').setRequired(true)

// Shopify create order
// export const SHOPIFY_whatsapp_order = new ActionsField('whatsappOrder', 'Whatsapp Order')
export const SHOPIFY_name = new ActionsField('name', 'WhatsApp Order','Enter Whatsapp Order');
export const SHOPIFY_phone = new ActionsField('phone', 'Phone', 'Enter Customer Phone Number');
export const SHOPIFY_financial_status = new ActionsField('financialStatus', 'Financial Status').setDropdownOption([
    { label: "Pending", value: "pending" },
    { label: "Authorized", value: "authorized" },
    { label: "Partially Paid", value: "partially_paid" },
    { label: "Paid", value: "paid" },
    { label: "Partially Refunded", value: "partially_refunded" },
    { label: "Refunded", value: "refunded" }
]);
export const SHOPIFY_orderTag = new ActionsField('tags', 'Tag', 'Enter Tag');
export const SHOPIFY_orderNote = new ActionsField('note', 'Note', 'Enter Note');
export const SHOPIFY_sourceName = new ActionsField('source_name', 'Source Name', 'Enter Source Name');
export const SHOPIFY_address = new ActionsField('address1', 'Address', 'Enter Address');
export const SHOPIFY_city = new ActionsField('city', 'City', 'Enter City');
export const SHOPIFY_state = new ActionsField('province', 'State', 'Enter State');
export const SHOPIFY_country = new ActionsField('country', 'Country', 'Enter Country');
export const SHOPIFY_pin = new ActionsField('zip', 'Pincode', 'Enter Pincode');
export const SHOPIFY_shippingAddress = new ActionsField('shipping_address1', 'Shipping Address', 'Enter Shipping Address');
export const SHOPIFY_shippingCity = new ActionsField('shipping_city', 'Shipping City', 'Enter Shipping City');
export const SHOPIFY_shippingState = new ActionsField('shipping_province', 'Shipping State', 'Enter Shipping State');
export const SHOPIFY_shippingCountry = new ActionsField('shipping_country', 'Shipping Country', 'Enter Shipping Country');
export const SHOPIFY_shippingPinCode = new ActionsField('shipping_zip', 'Shipping Pin Code', 'Enter Shipping Pin Code');



// Shopify draft order 
// export const SHOPIFY_draft_whatsapp_order = new ActionsField('draftWhatsappOrder', 'Whatsapp Order','Enter Whatapp Order').setRequired(true);
export const SHOPIFY_draft_name = new ActionsField('name', 'Name', 'Enter Customer Name').setRequired(true);
export const SHOPIFY_draft_phone = new ActionsField('phone', 'Phone', 'Enter Customer Phone Number').setRequired(true);
// export const SHOPIFY_draft_financial_status = new ActionsField('draftFinancialStatus', 'Financial Status');
export const SHOPIFY_draft_discount_type = new ActionsField('discount_type', 'Discount Type','Enter Discount Type');
export const SHOPIFY_draft_discount_value = new ActionsField('discount_value', 'Discount Value', 'Enter Discount Value');
export const SHOPIFY_draft_discount_title = new ActionsField('discount_title', 'Discount Title', 'Enter Discount Title');
export const SHOPIFY_draft_discount_description = new ActionsField('discount_description', 'Discount Description','Enter Discount Description');
export const SHOPFY_draft_discount_amount = new ActionsField('discount_amount', "Discount Amount", "Enter Discount Amount");
export const SHOPIFY_draft_address = new ActionsField('address1', 'Address', 'Enter Address');
export const SHOPIFY_draft_city = new ActionsField('city', 'City', 'Enter City');
export const SHOPIFY_draft_state = new ActionsField('province', 'State', 'Enter State');
export const SHOPIFY_draft_country = new ActionsField('country', 'Country', 'Enter Country');
export const SHOPIFY_draft_pin = new ActionsField('zip', 'Pincode', 'Enter Pincode');
export const SHOPIFY_draft_shipping_address = new ActionsField('shipping_address1', 'Shipping Address', 'Enter Shipping Address');
export const SHOPIFY_draft_shipping_city = new ActionsField('shipping_city', 'Shipping City', 'Enter Shipping City');
export const SHOPIFY_draft_shipping_state = new ActionsField('shipping_province', 'Shipping State', 'Enter Shipping State');
export const SHOPIFY_draft_shipping_country = new ActionsField('shipping_country', 'Shipping Country', 'Enter Shipping Country');
export const SHOPIFY_draft_shipping_pin_code = new ActionsField('shipping_zip', 'Shipping Pin Code', 'Enter Shipping Pin Code');
export const SHOPIFY_draft_order_id = new ActionsField('draftOrder_id', 'Draft Order ID', 'Create or Select Draft ID',0).setRequired(true);
export const SHOPIFY_draft_invoice_url = new ActionsField('draftOrder_invoiceUrl', 'Invoice URL','Create or Select Invoice URL',1).setRequired(true);

// Shopify complete draft order 
export const SHOPIFY_complete_draft_order_id = new ActionsField('draftOrderId', "Draft Order ID", 'Select Draft Order ID').setRequired(true);
export const SHOPIFY_complete_order_id = new ActionsField('completeOrderId', "Order ID",'Create or Select Order ID').setRequired(true);
export const SHOPIFY_complete_draft_payment_pending = new ActionsField('paymentPending', "Payment Pending", "Select Payment Status").setDropdownOption(
    [{
        label: "True",
        value: "true"
    },{
        label: "False",
        value: "false"
    }]
);


// Sangam CRM 
export const Sangam_firstName = new ActionsField('firstName', 'First Name', 'Enter First Name').setRequired(true);
export const Sangam_moduleName = new ActionsField('moduleName', 'Module Name', 'Enter Module Name').setRequired(true);
export const Sangam_lastName = new ActionsField('lastName', 'Last Name', 'Enter Last Name').setRequired(true);
export const Sangam_designation = new ActionsField('designation', 'Designation', 'Enter Designation').setRequired(true);
export const Sangam_department = new ActionsField('department', 'Department', 'Enter Department').setRequired(true);
export const Sangam_otherSource = new ActionsField('otherSource', 'Other Source', 'Enter Other Source').setRequired(true);
export const Sangam_nextFollowupDate = new ActionsField('nextFollowupDate', 'Next Follow-up Date', 'Enter Next Follow-up Date').setRequired(true);
export const Sangam_lostReason = new ActionsField('lostReason', 'Lost Reason', 'Enter Lost Reason').setRequired(true);
export const Sangam_nextFollowupType = new ActionsField('nextFollowupType', 'Next Follow-up Type', 'Enter Next Follow-up Type').setRequired(true);


// WooCommerce 
export const WooCommerce_orderId = new ActionsField('orderId', 'Order ID','Enter Order ID').setRequired(true)

// Open AI 
export const OpenAI_Context = new ActionsField("systemContent", "Context","Enter Context").setRequired(true);
export const OpenAI_UserPrompt = new ActionsField("userPrompt", "User Prompt","Enter User Prompt").setRequired(true);
export const OpenAI_Model = new ActionsField("model", "Model","Select Model").setRequired(true);
export const OpenAI_KnowledgeBase = new ActionsField("ragUid", "Knowledge Base","Select Knowledge Folder").setRequired(true);
export const OpenAI_MaxTokens = new ActionsField("maxTokens", "Max Tokens","Enter Max Tokens ").setRequired(true);
export const OpenAI_temperature = new ActionsField("temperature", "Temperature","Enter a Value").setRequired(true);
export const OpenAI_variable = new ActionsField("variable", "Variable","Create or Sekect Variable").setRequired(true);



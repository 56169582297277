import { components } from 'react-select';

interface SelectCategory {
    value: string;
    label: string;
}
  
interface SelectState {
  value: boolean;
  label: string;
}
export const categoryOptions: SelectCategory[] = [
    { value: 'all', label: 'All' },
    { value: 'marketing', label: 'Marketing' },
    {value:'catalog_marketing',label:'Product Marketing'},
    {value:'coupon_marketing' ,label:'Coupon Marketing'},
    { value: 'utility', label: 'Utility' },
    { value: 'authentication', label: 'Authentication (OTP)'},
    { value: 'carousel', label: 'Carousel Template'}
]


 export const CustomOption = (props: any) => {
    return (
        <components.Option {...props}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>{props.data.label}</span>
                {props.data.value === 'catalog_marketing' && (
                    <span style={{ backgroundColor: '#9DDFB4',color:"#008069", borderRadius: '4px', padding: '0 5px', marginLeft: '10px' }}>
                        New 
                    </span>
                )}
                 {props.data.value === 'carousel' && (
                    <span style={{ backgroundColor: '#9DDFB4',color:"#008069", borderRadius: '4px', padding: '0 5px', marginLeft: '10px' }}>
                        New 
                    </span>
                )}
                {props.data.value === 'coupon_marketing'&& (
                    <span style={{ backgroundColor: '#9DDFB4',color:"#008069", borderRadius: '4px', padding: '0 5px', marginLeft: '10px' }}>
                        New 
                    </span>
                )}
                {props.data.value === 'authentication'  && (
                    <span style={{ backgroundColor: '#9DDFB4',color:"#008069", borderRadius: '4px', padding: '0 5px', marginLeft: '10px' }}>
                        New 
                    </span>
                )}
                
            </div>
        </components.Option>
    );
};


export const buttonTypes : SelectCategory[]=[
  { value: "quickReply", label: "Quick Reply" },
  {value:'mobileNumber', label:'Mobile Number'},
  { value: "weburl", label: "Web URL" }
]


export const statusOptions: SelectCategory[] = [
    { value: 'all', label: 'All' },
    { value: 'APPROVED', label: 'APPROVED' },
    { value: 'DRAFT', label: 'DRAFT' },
    { value: 'IN_APPEAL', label: 'IN_APPEAL' },
    { value: 'PENDING', label:'PENDING' },
    { value: 'REJECTED', label: 'REJECTED' },
    { value: 'PENDING_DELETION', label: 'PENDING_DELETION' },
    { value: 'DELETED', label:'DELETED' },
    { value: 'DISABLED', label: 'DISABLED' },
    { value: 'PAUSED', label: 'PAUSED' },
    { value: 'LIMIT_EXCEEDED', label:'LIMIT_EXCEEDED' },
    { value: 'SUBMITTED', label: 'SUBMITTED' },
]


export const sequenceOptions : SelectCategory[]=[
  {label:'Event',value:'event'},
  // {label:'Quick Reply',value:'cannedResponse'},
]

export const days : SelectCategory[] = [
  { value: "all", label: "Alldays" },
  { value: "Monday", label: "Monday" },
  { value: "Tuesday", label: "Tuesday" },
  { value: "Wednesday", label: "Wednesday" },
  { value: "Thursday", label: "Thursday" },
  { value: "Friday", label: "Friday" },
  { value: "Saturday", label: "Saturday" },
  { value: "Sunday", label: "Sunday" },
];

export const sequenceVariables : SelectCategory[]=[
  {label:'Tags',value:'tags'},
  {label:'Contact Name',value:'name'},
  {label:'Marketing Optin',value:'optState'}
]

export const sequenceFilters : SelectCategory[]=[
  {label:'Equals',value:'equals'},
  {label:'Equals IgnoreCase',value:'equalsIgnoreCase'},
  {label:'Contains',value:'contains'},
  {label:'Does Not Contains',value:'notcontains'},
]
export const isOptState : SelectCategory[]=[
  {label:'Yes',value:'istrue'},
  {label:'No',value:'isfalse'},
]

export const IsOptState : SelectState[]=[
  {label:'Yes',value:true},
  {label:'No',value:false},
]

export const SourceMedia : SelectCategory[]=[
  {label:'Inbound',value:'Inbound'},
  {label:'Outbound',value:'Outbound'},
  // small letter
  // {label:'facebook',value:'facebook'},
  // {label:'facebook - post',value:'facebook - post'},
  // {label:'facebook - ad',value:'facebook - ad'},
  // {label:'google',value:'google'},
  // {label:'instagram',value:'instagram'},
  // {label:'website',value:'website'},
  // {label:'whatsapp',value:'whatsapp'},
  // Capital letter 
  {label:'Facebook',value:'Facebook'},
  {label:'Facebook - Post',value:'Facebook - Post'},
  {label:'Facebook - Ad',value:'Facebook - Ad'},
  {label:'Google',value:'Google'},
  {label:'Instagram',value:'Instagram'},
  {label:'Website',value:'Website'},
  {label:'whatsapp',value:'whatsapp'},
]

export const defaultTimeSequence = 
[{
    "day": "monday",
    "toTime": "23:59",
    "fromTime": "00:00"
},
{
    "day": "tuesday",
    "toTime": "23:59",
    "fromTime": "00:00"
},
{
    "day": "wednesday",
    "toTime": "23:59",
    "fromTime": "00:00"
},
{
    "day": "thursday",
    "toTime": "23:59",
    "fromTime": "00:00"
},
{
    "day": "friday",
    "toTime": "23:59",
    "fromTime": "00:00"
},
{
    "day": "saturday",
    "toTime": "23:59",
    "fromTime": "00:00"
},
{
    "day": "sunday",
    "toTime": "23:59",
    "fromTime": "00:00"
}
]

export const delayUnits : SelectCategory[]=[
  {label:'Mins',value:'minutes'},
  {label:'Hours',value:'hours'},
  {label:'Days',value:'Days'},
]


export const SelectTriger: SelectCategory[] = [
  { value: "Create", label: "On Create" },
  { value: "Update", label: "On Update" },
  { value: "CreateUpdate", label: "Both (Create & Update)" },
  { value: "Trigger", label: "Trigger"}
];

export const SelectTimes: SelectCategory[] = [
  { value: "DefaultTime", label: "Any Time" },
  { value: "CustomTime", label: "Custom Time" },
];
export const SelectFlowTimes: SelectCategory[] = [
  { value: "Default", label: "Any Time" },
  { value: "Custom", label: "Custom Time" },
];

export const AssignOptions: SelectCategory[] = [
  { value: '1', label: "Assign to Bot" },
  { value: '2', label: "Assign to Member" },
  { value: '3', label: "Assign to Team" },

];


export const StartCondition :SelectCategory[] =[
  { value: "Start", label: "Start"},
  { value: "Start", label: "Start"},
  { value: "Start", label: "Start"},
  { value: "Start", label: "Start"}
]

export const StopCondition :SelectCategory[] =[
  { value: "Start", label: "Start"},
  { value: "Start", label: "Start"},
  { value: "Start", label: "Start"},
  { value: "Start", label: "Start"}

]

export const WhatsAppCategory : SelectCategory[]=[
        {
          label: "OTHER",
          value: "OTHER"
        },
        {
          label: "AUTO",
          value: "AUTO"
        },
        {
          label: "BEAUTY",
          value: "BEAUTY"
        },
        {
          label: "APPAREL",
          value: "APPAREL"
        },
        {
          label: "EDU",
          value: "EDU"
        },
        {
          label: "ENTERTAIN",
          value: "ENTERTAIN"
        },
        {
          label: "EVENT_PLAN",
          value: "EVENT_PLAN"
        },
        {
          label: "FINANCE",
          value: "FINANCE"
        },
        {
          label: "GROCERY",
          value: "GROCERY"
        },
        {
          label: "GOVT",
          value: "GOVT"
        },
        {
          label: "HOTEL",
          value: "HOTEL"
        },
        {
          label: "HEALTH",
          value: "HEALTH"
        },
        {
          label: "NONPROFIT",
          value: "NONPROFIT"
        },
        {
          label: "PROF_SERVICES",
          value: "PROF_SERVICES"
        },
        {
          label: "RETAIL",
          value: "RETAIL"
        },
        {
          label: "TRAVEL",
          value: "TRAVEL"
        },
        {
          label: "RESTAURANT",
          value: "RESTAURANT"
        },
        {
          label: "NOT_A_BIZ",
          value: "NOT_A_BIZ"
        }            
]

// export const WhatsAppCategory: SelectCategory[] = [
//     { label: "Automotive", value: "Automotive" },
//     { label: "Beauty, Spa and Salon", value: "Beauty, Spa and Salon" },
//     { label: "Clothing and Apparel", value: "Clothing and Apparel" },
//     { label: "Education", value: "Education" },
//     { label: "Entertainment", value: "Entertainment" },
//     { label: "Event Planning and Service", value: "Event Planning and Service" },
//     { label: "Finance and Banking", value: "Finance and Banking" },
//     { label: "Food and Grocery", value: "Food and Grocery" },
//     { label: "Public Service", value: "Public Service" },
//     { label: "Hotel and Lodging", value: "Hotel and Lodging" },
//     { label: "Medical and Health", value: "Medical and Health" },
//     { label: "Non-profit", value: "Non-profit" },
//     { label: "Professional Services", value: "Professional Services" },
//     { label: "Shopping and Retail", value: "Shopping and Retail" },
//     { label: "Travel and Transportation", value: "Travel and Transportation" },
//     { label: "Restaurant", value: "Restaurant" },
//     { label: "Other", value: "Other" },
//   ];

  
export const BusinessTypes: SelectCategory[] = [
    {
      label: "LIMITED_LIABILITY_PARTNERSHIP",
      value: "LIMITED_LIABILITY_PARTNERSHIP"
    },
    {
      label: "SOLE_PROPRIETORSHIP",
      value: "SOLE_PROPRIETORSHIP"
    },
    {
      label: "PARTNERSHIP",
      value: "PARTNERSHIP"
    },
    {
      label: "PUBLIC_COMPANY",
      value: "PUBLIC_COMPANY"
    },
    {
      label: "PRIVATE_COMPANY",
      value: "PRIVATE_COMPANY"
    },
    {
      label: "OTHER",
      value: "OTHER"
    }
  ]


  export const SelectTemplateVariables:SelectCategory[] = [
    {
      label: "Contact.Name",
      value: "Contact.Name"
    },
    {
      label: "Contact.Number",
      value: "contact.Number"
    },
    {
      label: "Contact.Email",
      value: "Contact.Email"
    },
    {
      label: "Contact.Company Name",
      value: "Contact.CompanyName"
    },
    {
      label: "Contact.Company Website",
      value: "Contact.CompanyWebsite"
    }    
  ]

  export const contactFilters: SelectCategory[]= [
    {
      label:'optin',value:'optin',
    },
    {
      label:'tags', value:'tags',
    },
    {
      label:'Source', value:'source',
    }
  ]


   
  export const AppointmentVariables: SelectCategory[] = [
    {
      label: "Contact.Name",
      value: "Contact.Name",
    },
    {
      label: "Contact.Number",
      value: "contact.Number",
    },
    {
      label: "Contact.Email",
      value: "Contact.Email",
    },
    {
      label: "Contact.Company Name",
      value: "Contact.CompanyName",
    },
    {
      label: "Contact.Company Website",
      value: "Contact.CompanyWebsite",
    },
    {
      label: "Appointment.Member Name",
      value: "Appointment.Member.Name",
    },
    {
      label: "Appointment.Date",
      value: "Appointment.Date",
    },
    {
      label: "Appointment.Time",
      value: "Appointment.Time",
    },
    {
      label: "Appointment.FromTime",
      value: "Appointment.FromTime",
    },
    {
      label: "Appointment.ToTime",
      value: "Appointment.ToTime",
    },
    {
      label: "Appointment.Name",
      value: "Appointment.Name",
    }
  ]


  export const ShiprocketVariables:SelectCategory[] = [
    {
      label: "Contact.Name",
      value: "Contact.Name"
    },
    {
      label: "Contact.Number",
      value: "contact.Number"
    },
    {
      label: "Contact.Email",
      value: "Contact.Email"
    },
    {
      label: "Contact.Company Name",
      value: "Contact.CompanyName"
    },
    {
      label: "Contact.Company Website",
      value: "Contact.CompanyWebsite"
    },
    {
      label:"Customer.name",
      value:"customer_name"
    },
    {
      label:"Order.id",
      value:"order_id"
    }
    
  ]

import moment from "moment";

export function searchFilter(event: string, searchCol: any[], record: any[]) {
  const value = event.toLowerCase();
  return record.filter(function (d) {
    for (let i = 0; i < searchCol.length; i++) {
      const key = searchCol[i];
      if (key === "components") {
        // Special handling for 'components' key
        const components = d[key];
        for (let j = 0; j < components.length; j++) {
          const component = components[j];
          if (component?.example?.body_text?.length) {
            const isFoundInBodyText = component.example.body_text.some(
              (e: string) => {
                return e.toString().toLowerCase().indexOf(value) !== -1;
              }
            );
            if (isFoundInBodyText) {
              return true;
            }
          }
          if (component?.buttons?.length) {
            const isCallAction = component.buttons.some((e: any) => {
              return e.text.toString().toLowerCase().indexOf(value) !== -1;
            });
            if (isCallAction) {
              return true;
            }
          }
          if (
            component.text &&
            component.text.toString().toLowerCase().indexOf(value) !== -1
          ) {
            return true;
          }
        }
      } else if (key === "createdAt") {
        var colValue = d[key];
        if (
          !value ||
          (!!colValue && moment(colValue).format("DD/MM/YY dddd"))
            .toString()
            .toLowerCase()
            .indexOf(value) !== -1
        ) {
          return true;
        }
      } else {
        var colValue = d[key];
        if (
          !value ||
          (!!colValue &&
            colValue.toString().toLowerCase().indexOf(value) !== -1)
        ) {
          return true;
        }
      }
    }
    return false; // return false at the end of the filter callback
  });
}

function containsNumber(s: string) {
  return /\d/.test(s);
}

export const capitalizeFirstLetter = (text: string) => {
  if (!text) return "";
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

const regex = /{{(.*?)}}/g;

export const extractVariableName = (text: string) => {
  const matches = text?.match(regex);
  if (matches) {
    return matches.map((match: string) => match.replace(/{{|}}/g, ""));
  }
  return [];
};

export const removeVariables = (text: string) => {
  return text.replace(regex, "").trim();
};

const wordsExtractedFromSymbols = (symbol: string, text: string) => {
  var regex;
  if (symbol === "*") {
    regex = /\*(.*?)\*/g;
  } else if (symbol === "_") {
    regex = /_([^_]+)_/g;
  } else {
    regex = /~([^~]+)~/g;
  }
  const extractedWords = [];
  let match;
  while ((match = regex.exec(text)) !== null) {
    extractedWords.push(match[1]);
  }
  return extractedWords;
};

export const checkArrayofArray = (variables: any): any => {
  const isArray = Array.isArray(variables);
  const isArrayOfArrays = isArray && variables.every(Array.isArray);
  if (isArrayOfArrays) {
    return true;
  } else {
    return false;
  }
};

export const renderMessage = (
  values: any,
  text: string,
  component: string,
  status?: number
) => {
  // console.log("tes",values,text);

  let renderedText = text && text?.trim();
  if (renderedText) {
    // Regular expressions for email and URL
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g;
    const urlRegex = /\b(?:https?:\/\/|www\.)[^\s]+(?:\.[^\s]+)+\b/g;
    const phoneRegex = /\b\d{3}[-.]?\d{3}[-.]?\d{4}\b/g;

    // Find matches in the paragraph
    const emailMatches = renderedText.match(emailRegex);
    const urlMatches = renderedText.match(urlRegex);
    const phoneMatches = renderedText.match(phoneRegex);

    // Highlight matches in the paragraph
    if (emailMatches) {
      emailMatches.forEach((email) => {
        renderedText = renderedText.replace(
          email,
          `<a href="mailto:${email}" target="_blank" style="color: #0083ff;">${email}</a>`
        );
      });
    }
    if (urlMatches) {
      urlMatches.forEach((url) => {
        // Check if the URL starts with http:// or https://, if not, add it
        const formattedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : 'http://' + url;
        renderedText = renderedText.replace(
          url,
          `<a href="${formattedUrl}" target="_blank" style="color: #0083ff;">${url}</a>`
        );
      });
    }
    if (phoneMatches) {
      phoneMatches.forEach((phone) => {
        renderedText = renderedText.replace(
          phone,
          `<a href="tel:${phone}" target="_blank" style="color: #0083ff;">${phone}</a>`
        );
      });
    }

    // const links = renderedText.match(linkRegex);
    // if (links) {
    //     links.forEach(link => {
    //         const replaceAsLink = `<a href="${link}" target="_blank" style="color: #0083ff;">${link}</a>`;
    //         renderedText = renderedText.replace(link, replaceAsLink);
    //     });
    // }
    if (component !== "convMessages" && component !== "convNotes") {
      if (values?.example?.body_text?.length) {
        const variableNames = extractVariableName(text);
        variableNames.forEach((variableName: any, index: number) => {
          const replacement = `<span class="${status === -1 ? "sampleFailedVarColor" : "sampleVarColor"
            }">${checkArrayofArray(values.example.body_text)
              ? values.example.body_text[0][index]
              : values.example.body_text[index]
            }</span>`;
          renderedText = renderedText.replace(`{{${variableName}}}`, replacement);
        });
      }
    }
    if (renderedText.includes("*")) {
      const boldWords = wordsExtractedFromSymbols("*", renderedText);
      boldWords.forEach((b) => {
        const replaceAsBold = `<span class="fontWeight">${b}</span>`;
        renderedText = renderedText.replace(`*${b}*`, replaceAsBold);
      });
    }
    if (renderedText.includes("_")) {
      const italicWords = wordsExtractedFromSymbols("_", renderedText);
      italicWords.forEach((i) => {
        const replaceAsItalic = `<i>${i}</i>`;
        renderedText = renderedText.replace(`_${i}_`, replaceAsItalic);
      });
    }
    if (renderedText.includes("~")) {
      const tildeWords = wordsExtractedFromSymbols("~", renderedText);
      tildeWords.forEach((t) => {
        const replaceAsTilde = `<span class="strikeThrough">${t}</span>`;
        renderedText = renderedText.replace(`~${t}~`, replaceAsTilde);
      });
    }
    switch (component) {
      case "convMessages":
      case "convNotes":
        return (
          <span
            style={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={{ __html: renderedText }}
          />
        );
      case "contactMsgs":
        return <span dangerouslySetInnerHTML={{ __html: renderedText }} />;
      default:
        return (
          <div
            style={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={{ __html: renderedText }}
          />
        );
    }
  }
};

export function processMobileNumber(
  countryCode: string,
  mobileNumber: string
): string {
  return countryCode
    ? "+" + countryCode + " " + mobileNumber.slice(countryCode?.length)
    : mobileNumber.slice(countryCode?.length);
}

export function capitalize(word: string): string {
  if (!word) return "";
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export const processChannelLimit = (value: string, component?: string) => {
  const numericValue = parseFloat(value.replace(/[^\d.-]/g, ""));
  return component !== "channelDetails"
    ? numericValue * 1000
    : numericValue + " " + "K";
};

export const clearWindowCache = () => {
  for (const prop in window) {
    if (
      window.hasOwnProperty(prop) &&
      ((Array.isArray(window[prop]) && prop.includes("ConvFilter")) ||
        (typeof window[prop] === "boolean" && prop.includes("ConvFilter")) ||
        (typeof window[prop] === "string" && prop.includes("ConvFilter")) ||
        prop.includes("ConvFilter_FetchCatch_tags"))
    ) {
      delete window[prop];
    }
  }
};

export const planCodes = (
  code: string
): string => {
  if (window.location.hostname === "app.bizmagnets.ai") {
    switch (code) {
      case "free":
        return "1111";
      case "freeMonthly":
        return "1111";
      case "freeYearly":
        return "1112";
      case "proMonthly":
        return "9273";
      case "proYearly":
        return "2893";
      case "ultimateMonthly":
        return "9763";
      case "ultimateYearly":
        return "3613";
      case "proQuarterly":
        return "856237";
      case "ultimateQuarterly":
        return "732463";
      case "freeQuarterly":
        return "477643";
    }
  } else {
    switch (code) {
      case "free":
        return "000";
      case "freeMonthly":
        return "000";
      case "freeYearly":
        return "3442";
      case "proMonthly":
        return "01010";
      case "proYearly":
        return "234567";
      case "ultimateMonthly":
        return "123";
      case "ultimateYearly":
        return "124";
      case "proQuarterly":
        return "885545";
      case "ultimateQuarterly":
        return "863538";
      case "freeQuarterly":
        return "476776";
    }
  }
  return "000";
};

export const noOfChannels = (code: string): number => {
  if (window.location.hostname === "app.bizmagnets.ai") {
    switch (code) {
      case "trial":
        return 1;
      case "1111":
        return 1;
      case "9273":
        return 1;
      case "2893":
        return 1;
      case "9763":
        return 1;
      case "3613":
        return 1;
      case "ownNumber":
        return 1;
    }
  } else {
    switch (code) {
      case "trial":
        return 1;
      case "000":
        return 1;
      case "01010":
        return 1;
      case "234567":
        return 1;
      case "123":
        return 1;
      case "124":
        return 1;
      case "ownNumber":
        return 1;
    }
  }
  return 0;
};

export const getTextBasedOnSubType = (subtype: string | undefined) => {
  switch (subtype) {
    case "list_message":
      return "List Message";
    case "button_message":
      return "List Message";
    case "flow":
      return "Flow Message";
    case "order_details":
      return "Order Message";
    default:
      return "Template";
  }
};

export const getTextBasedOnnfmReplyMsg = (msgName: string) => {
  switch (msgName) {
    case "cta_url":
      return "CTA Message";
    case "address_message":
      return "Address Message";
    case "flow":
      return "Flow Message";
    case "location_request_message":
      return "Location Message";
  }
};


export function rupeesToPaisa(rupees: number): number {
  return rupees * 100;
}

export function paisaToRupees(paisa: number): number {
  return paisa / 100;
}

// export const dateSplit = (date: Date): string => {
//   const today = moment();
//   const targetDate = moment(date);

//   if (targetDate.isSame(today, "day")) {
//       return "<span title='" + targetDate.format("DD MMM, YY hh:mm A") + "'>Today</span>";
//   } else if (targetDate.isSame(today.clone().subtract(1, "days"), "day")) {
//       return "<span title='" + targetDate.format("DD MMM, YY hh:mm A") + "'>Yesterday</span>";
//   } else if (targetDate.isSameOrAfter(today.clone().subtract(7, "days"), "day")) {
//       return "<span title='" + targetDate.format("DD MMM, YY hh:mm A") + "'>" + targetDate.format("dddd") + "</span>";
//   } else {
//       return "<span title='" + targetDate.format("DD MMM, YY hh:mm A") + "'>" + targetDate.format("DD MMM") + "</span>";
//   }
// };
export const dateSplit = (date: Date): string => {
  const today = moment();
  const targetDate = moment(date);

  if (targetDate.isSame(today, "day")) {
    return 'Today';
  } else if (targetDate.isSame(today.clone().subtract(1, "days"), "day")) {
    return 'Yesterday';
  } else if (targetDate.isSameOrAfter(today.clone().subtract(7, "days"), "day")) {
    return targetDate.format("dddd");
  } else {
    return targetDate.format("DD MMM");
  }
};

export const getAddonCode = (addon: string): string => {
  if (window.location.hostname === "app.bizmagnets.ai") {
    switch (addon) {
      case 'whatsappFlow':
        return '07';
      case 'bots':
        return '08';
      case 'channels':
        return '09';
      case 'members':
        return '10';
    }
  }
  else {
    switch (addon) {
      case 'whatsappFlow':
        return '02';
      case 'bots':
        return '03';
      case 'channels':
        return '05';
      case 'members':
        return '04';
    }
  }
  return '';
}

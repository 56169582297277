import axios from "../utils/axios";

export const getSubscriptionPlans = async () => {
    interface SubscriptionPlansResponse {
        id: number;
        name: string;
        description: string;
        amount: number;
        currency: "USD" | "INR";
        period: "MONTH" | "YEAR";
        duration: number;
    };

    try {
        const response = await axios.get<SubscriptionPlansResponse[]>("payment/getSubscriptions");
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getSubscriptionHistory = async (payload: any) => {
    interface SubscriptionHistoryItem {
        id: number;
        paymentId: string;
        amount: number;
        status: "PENDING" | "PAID";
    };
    try {
        // const response = await axios.post<SubscriptionHistoryItem[]>("payment/getPaymentDetails", {
        //     paymentType: 0,
        //     businessId
        // });
        const response = await axios.post("payment/getPaymentDetails", payload);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export interface IBillingData {
    id: null | string,
    name: null | string,
    address: null | string,
    city: null | string,
    state: null | string,
    zip: null | string,
    country: null | string,
    email: null | string,
    phoneNumber: null | string,
    taxID: null | string,
    countryCode: string;
};

export const getBillingDetail = async (businessId: number) => {
    try {
        const response = await axios.get<IBillingData>(`payment/${businessId}/getBillingDetail`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const updateBillingDetail = async (businessId: number, billingData: any) => {
    try {
        const response = await axios.patch<IBillingData>(`payment/${businessId}/updateBillingDetail`, billingData);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getZohoSubscriptions = async () => {
    try {
        const response = await axios.get('payment/zoho/getSubscriptions')
        return response.data
    }
    catch (error: any) {
        return error;
    }

}

export const freeActivation = async (businessUid: string) => {
    try {
        const response = await axios.patch(`business/updatePlanToFree/${businessUid}`)
        return response.data;
    }
    catch (error: any) {
        return error;
    }

}

export const getAddons = async () => {
    try {
        const addons = await axios.get('payment/zoho/getAddonList')
        return addons.data;
    }
    catch (err) {
        return err;
    }
}

export const submitSubscriptionDetails = async (payload: any) => {
    try {
        const details = await axios.post('payment/zoho/createSubcriptionHostingPage', payload);
        return details.data;
    }
    catch (err) {
        return err;
    }
}

export const getStates = async (countryId: any) => {
    try {
        const details = await axios.get(`getStates?countryId=${countryId}`);
        return details.data;
    }
    catch (err) {
        return err;
    }
}

export const getCities = async (stateId: any) => {
    try {
        const details = await axios.get(`getCities?stateId=${stateId}`);
        return details.data;
    }
    catch (err) {
        return err;
    }
}


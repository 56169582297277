import React, { useState, useEffect } from "react";
import { Container, Row, Col, Label, Button } from "reactstrap";
import { Formik, Form } from "formik";
import "./WhatsAppChannel.scss";
import {
  updateWhatsappProfile,
  updateWhatsAppPicture,
  updateComplainceData,
  widgetProfileUpdate,
  updateWidgetData,
  syncProfile
} from "../../services/WhatsAppchannelService";
import ComplainceInfo from "./ComplianceInfo";
import { useSelector, useDispatch } from "react-redux";
import { WhatsAppValidation } from "./WhatsAppValidation";
import WhatsAppPrfile from "./WhatsappProfile";
import { WhatsappDataProvider } from "./whatsappContext";
import { FormikHelpers } from "formik";
import WhatsAppWidget from "../Widget/WhatsAppWidget";
import ChannelDetails from "./ChanelDetails";
// import { ConnectButton } from "360dialog-connect-button";
import { createChannel } from "../../services/dashboardService";
import axios from "../../utils/axios";
import { useNavigate } from 'react-router-dom';
import ChannelConnect from "../../common/connectButton";
import ChannelSettings from "./ChannelSettings";
import { toast } from "../../common/alert";

interface SelectIndustry {
  value: string;
  label: string;
}
interface FormValues {
  businessDescription: string;
  email: string;
  address: string;
  tagline: string;
  websiteUrl: string;
  category: any;
  whatsAppProfilename: string;
  businessType: string;
  customerEmail: string;
  customerMobile: string;
  customerLandline: string;
  grievanceName: string;
  grievanceEmail: string;
  grievanceMobile: string;
  grivanceLandline: string;
  widget_businessName: string;
  widget_tagline: string;
  welcomeTxt: string;
  buttonColor: string;
  btnTextOpt: string;
  widTextOpt: string;
  trigerMsg: string;
}

interface CallbackObject {
  client: string;
  channels: string[];
  revokedChannels?: string[];
}

export const sample: SelectIndustry[] = [{ value: "1-10", label: "1-10" }];

const WhastappProfile = () => {
  const [chooseNav, setNavigate] = useState(0);
  const [whatsappProfile, getWhatsappData] = useState({
    description: "",
    email: "",
    address: "",
    tagline: "",
    website: "",
    vertical: "",
    businessName: "",
    businessType: "",
    customerCareEmail: "",
    customerCareMobilenumber: "",
    customerCareLandline: "",
    grievanceOfficerName: "",
    grievanceOfficeEmail: "",
    grievanceOfficeMobilenumber: "",
    grievanceOfficeLandline: "",
    name: "",
    tag: "",
    welcomeText: "",
    buttonColor: "",
    buttonType: "",
    position: "",
    buttonLabel: "",
  });
 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const businessId = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );
  const Subscriptions = useSelector(
    (state: any) => state.cartreducer.business?.business?.plan?.subscriptions
  );
  const profileDetail = useSelector((state: any) => state.cartreducer.business);

  useEffect(() => {
    localStorage.setItem("businessId", businessId);
  }, [businessId]);

  useEffect(() => {
    const storedBusinessId = localStorage.getItem('businessId');
    axios.get(`channel/getAll?uid=${storedBusinessId}`).then((responseData) => {
      const channelData = responseData?.data;
      // dispatch({ type: 'CHANNEL_DATA', payload: channelData });
    });
  }, [])

  const [isRotating, setIsRotating] = useState(false);
  const [isScriptModal, setIsScriptModal] = useState(false);
  const [wid_logo, widget_Logo] = useState("");
  const [filePathProfile, getFilePath_profile] = useState("");
  const channelData = useSelector((state: any) => state.cartreducer.channelUid);
  const profileData = useSelector((state: any) => state.cartreducer.business);
  const allChannelData = useSelector((state: any) => state.cartreducer.channelData);

  let grievanceMobile = ""; // Initialize grievanceMobile with an empty string or another suitable default value
  let grivanceLandline = "";
  let customerLandline = "";
  let customerMobile = "";

  if (whatsappProfile && whatsappProfile.grievanceOfficeMobilenumber) {
    grievanceMobile = whatsappProfile.grievanceOfficeMobilenumber.slice(3);
    grivanceLandline = whatsappProfile?.grievanceOfficeLandline.slice(3);
    customerLandline = whatsappProfile?.customerCareLandline.slice(3);
    customerMobile = whatsappProfile.customerCareMobilenumber.slice(3);
  }
  // const grievanceMobile = whatsappProfile?.grievanceOfficeMobilenumber.slice(3);
  const initialValues = {
    businessDescription: whatsappProfile.description,
    email: whatsappProfile.email,
    address: whatsappProfile.address,
    tagline: whatsappProfile.tagline,
    websiteUrl: whatsappProfile?.website?.[0],
    category: whatsappProfile?.vertical || "",

    whatsAppProfilename: whatsappProfile.businessName,
    businessType: whatsappProfile.businessType,
    customerEmail: whatsappProfile.customerCareEmail,
    customerMobile: customerMobile,
    customerLandline: customerLandline,
    grievanceName: whatsappProfile.grievanceOfficerName,
    grievanceEmail: whatsappProfile.grievanceOfficeEmail,
    grievanceMobile: grievanceMobile,
    grivanceLandline: grivanceLandline,

    widget_businessName: whatsappProfile.name,
    widget_tagline: whatsappProfile.tag,
    // logoUrl
    welcomeTxt: whatsappProfile.welcomeText,
    buttonColor: whatsappProfile.buttonColor,
    btnTextOpt: whatsappProfile.buttonType,
    widTextOpt: whatsappProfile.position,
    trigerMsg: whatsappProfile.buttonLabel,
  };

  const get_WhatsappProfile = (getData: any) => {
    getWhatsappData(getData);
  };

  const get_ComplianceInfo = (getData: any) => {
    getWhatsappData(getData);
  };

  const getFilepath = (fileData: any) => {
    getFilePath_profile(fileData);
  };

  const get_WhatsAppWidget = (getData: any) => {
    getWhatsappData(getData);
  };

  const handleScriptClose = () => {
    setIsScriptModal(false);
  };

  const getfileData = (file: any) => {
    widget_Logo(file);
  };

  const handleCallback = async (obj: CallbackObject) => {
    try {
      const storedBusinessId = localStorage.getItem("businessId");
      if (storedBusinessId !== null) {
        const payload = {
          uid: storedBusinessId,
          client: obj.client,
          channelId: obj.channels,
          revoked: obj.revokedChannels || [],
        };

        const res = await createChannel(payload);

        const responseData = await axios.get(
          `channel/getAll?uid=${storedBusinessId}`
        );
        const channelData = responseData.data;

        if (channelData && channelData.length > 0) {
          dispatch({ type: "CHANNEL_DATA", payload: channelData });
          const uid = {
            value: channelData[0].uid,
            label: channelData[0].name,
            channelAllData: channelData[0],
          };
          dispatch({ type: "CHANNEL_UID", payload: uid });
        }
      }
    } catch (error) {
      console.error("Error in handleCallback:", error);
    }
  };


  const handleSync = async() => {
    const {dialogChannelId} = channelData?.channelAllData;
    const response = await syncProfile(dialogChannelId);
    if(response){
    const {channelProfile,channelInfo,channelWidget,channelConfiguration} = response;
    getWhatsappData({
      description: channelProfile.description,
      email: channelProfile.email,
      address: channelProfile.address,
      tagline: channelProfile.tagline,
      website: channelProfile.website,
      vertical: channelProfile.vertical,
      businessName: channelInfo.businessName,
      businessType: channelInfo.businessType,
      customerCareEmail: channelInfo.customerCareEmail,
      customerCareMobilenumber: channelInfo.customerCareMobilenumber,
      customerCareLandline: channelInfo.customerCareLandline,
      grievanceOfficerName: channelInfo.grievanceOfficerName,
      grievanceOfficeEmail: channelInfo.grievanceOfficeEmail,
      grievanceOfficeMobilenumber: channelInfo.grievanceOfficeMobilenumber,
      grievanceOfficeLandline: channelInfo.grievanceOfficeLandline,
      name: channelWidget.name,
      tag: channelWidget.tag,
      welcomeText: channelWidget.welcomeText,
      buttonColor: channelWidget.buttonColor,
      buttonType: channelWidget.buttonType,
      position: channelWidget.position,
      buttonLabel: channelWidget.buttonLabel,
    })
    toast('success','Profile Synced Successfully')

    const uid = {
      value: response?.uid,
      label: response?.name,
      channelAllData: response,
    };
    dispatch({ type: 'CHANNEL_UID', payload: uid });
  }
  }
  const GoHome = () => {
    let path = profileData && profileData?.business?.freeTrial ? `${process.env.PUBLIC_URL}/welcome/` : `${process.env.PUBLIC_URL}/dashboard/`
    navigate(path);
  }

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    const channelId = channelData?.value;
    if (chooseNav === 0 && channelId) {
      const whatsappProfilePayload = {
        description: values.businessDescription,
        email: values.email,
        vertical: values.category,
        address: values.address,
        website: [values.websiteUrl],
        tagline: values.tagline,
      };
      const whatsappData = {
        ...whatsappProfile,
        ...whatsappProfilePayload,
      };
      if (filePathProfile) {
        const response = await updateWhatsAppPicture(
          filePathProfile,
          channelId
        );
        if (response) {
          await updateWhatsappProfile(whatsappData, channelId);
          getFilePath_profile("");
        }
      } else {
        await updateWhatsappProfile(whatsappData, channelId);
      }
    }
    if (chooseNav === 1 && channelId) {
      const compliancePayload = {
        businessName: values.whatsAppProfilename,
        businessType: values.businessType,
        customerCareEmail: values.customerEmail,
        customerCareMobilenumber: values.customerMobile
          ? "+91" + values.customerMobile
          : "",
        customerCareLandline: values.customerLandline
          ? "+91" + values.customerLandline
          : "",
        grievanceOfficerName: values.grievanceName,
        grievanceOfficeEmail: values.grievanceEmail,
        grievanceOfficeMobilenumber: values.grievanceMobile
          ? "+91" + values.grievanceMobile
          : "",
        grievanceOfficeLandline: values.grivanceLandline
          ? "+91" + values.grivanceLandline
          : "",
      };
      const complainceData = {
        ...whatsappProfile,
        ...compliancePayload,
      };
      await updateComplainceData(complainceData, channelId);
    }
    if (chooseNav === 2 && channelId) {
      let logo_Url = null;
      if (wid_logo) {
        logo_Url = await widgetProfileUpdate(
          wid_logo,
          values.widget_businessName,
          channelId
        );
      }
      const widgetPayload = {
        name: values.widget_businessName,
        tag: values.widget_tagline,
        logoUrl: logo_Url?.data,
        buttonType: values.btnTextOpt,
        buttonLabel: values.trigerMsg,
        buttonColor: values.buttonColor,
        // themeColor: null,
        position: values.widTextOpt,
        // options: null,
        welcomeText: values.welcomeTxt,
      };
      const widgetData = {
        ...whatsappProfile,
        ...widgetPayload,
      };

      await updateWidgetData(widgetData, channelId);
      setIsScriptModal(true);
    }
    setSubmitting(false);
  };
  return (
    <Container className="container whatsapp-profile-container">
      <Row>
        {profileData && profileData?.business?.freeTrial ? (
          <>
            <div className="whatsapp-nav">
              <h5 className="freeTrialHead">WhatsApp Channel  </h5>
              <div className="whatsappFreeTrial">
                <div>
                  <h5>
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="me-2"
                    >
                      <g clip-path="url(#clip0_2884_12148)">
                        <path
                          d="M0.423595 9.53519C0.42313 11.2156 0.8622 12.8564 1.69708 14.3026L0.34375 19.2438L5.40049 17.9179C6.79912 18.6793 8.36618 19.0783 9.95863 19.0784H9.96282C15.2198 19.0784 19.4991 14.8006 19.5013 9.54271C19.5024 6.99488 18.511 4.59907 16.71 2.79659C14.9092 0.994264 12.5142 0.00116279 9.96243 0C4.70484 0 0.425843 4.27752 0.423672 9.53519"
                          fill="url(#paint0_linear_2884_12148)"
                        />
                        <path
                          d="M0.0829457 9.87721C0.0824031 11.6181 0.537209 13.3175 1.40186 14.8155L0 19.9339L5.23806 18.5605C6.68132 19.3474 8.30628 19.7622 9.95977 19.7629H9.96403C15.4096 19.7629 19.8426 15.3312 19.845 9.88512C19.8459 7.24574 18.8189 4.7638 16.9535 2.89674C15.0878 1.02992 12.6072 0.00108527 9.96403 0C4.51752 0 0.0851163 4.43101 0.0829457 9.87721ZM3.20233 14.5575L3.00674 14.2471C2.18457 12.9398 1.75062 11.4291 1.75124 9.87783C1.75302 5.35109 5.43705 1.66822 9.96713 1.66822C12.1609 1.66915 14.2226 2.52434 15.7733 4.07597C17.324 5.62775 18.1772 7.69054 18.1767 9.8845C18.1747 14.4112 14.4905 18.0946 9.96403 18.0946H9.96078C8.4869 18.0938 7.0414 17.698 5.78078 16.95L5.48078 16.7721L2.3724 17.5871L3.20233 14.5574V14.5575Z"
                          fill="url(#paint1_linear_2884_12148)"
                        />
                        <path
                          d="M7.49452 5.74801C7.30956 5.33692 7.11491 5.32863 6.93902 5.32142C6.79499 5.31522 6.63034 5.31568 6.46584 5.31568C6.30119 5.31568 6.03367 5.37762 5.80755 5.62452C5.58119 5.87165 4.94336 6.46886 4.94336 7.68351C4.94336 8.89824 5.82809 10.0721 5.95142 10.237C6.07491 10.4016 7.65941 12.974 10.1689 13.9636C12.2544 14.786 12.6789 14.6224 13.1315 14.5812C13.5842 14.5401 14.5923 13.9841 14.7979 13.4076C15.0037 12.8312 15.0037 12.3371 14.942 12.2338C14.8803 12.131 14.7157 12.0692 14.4688 11.9458C14.2218 11.8223 13.008 11.225 12.7817 11.1426C12.5554 11.0603 12.3908 11.0192 12.2262 11.2664C12.0615 11.5132 11.5887 12.0692 11.4446 12.2338C11.3006 12.3989 11.1565 12.4194 10.9097 12.2959C10.6627 12.172 9.86755 11.9117 8.92421 11.0706C8.19026 10.4162 7.69475 9.60808 7.55072 9.36088C7.40669 9.11405 7.5353 8.98026 7.6591 8.85723C7.77003 8.74661 7.90607 8.56894 8.02964 8.42483C8.15274 8.28064 8.19382 8.17777 8.27615 8.01312C8.35855 7.84832 8.31731 7.70413 8.25568 7.58064C8.19382 7.45715 7.71406 6.23615 7.49452 5.74801Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <linearGradient
                          id="paint0_linear_2884_12148"
                          x1="958.224"
                          y1="1924.38"
                          x2="958.224"
                          y2="0"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#1FAF38" />
                          <stop offset="1" stop-color="#60D669" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_2884_12148"
                          x1="992.248"
                          y1="1993.39"
                          x2="992.248"
                          y2="0"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#F9F9F9" />
                          <stop offset="1" stop-color="white" />
                        </linearGradient>
                        <clipPath id="clip0_2884_12148">
                          <rect width="19.845" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    {`${channelData?.channelAllData?.name} - Demo`}
                  </h5>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="currentColor"
                  className="bi bi-telephone"
                  viewBox="0 0 16 16"
                >
                  {" "}
                  <path
                    d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                    fill="#007EC3"
                  />{" "}
                </svg>
                <Label>
                  {"+ " + channelData?.channelAllData?.mobileNumber}
                </Label>
                <div>
                  <Label>ID : {channelData?.channelAllData?.uid}</Label>
                </div>
                {profileDetail?.role?.name !== 'MEMBER' &&
                  <div>
                    <ChannelConnect component="whatsappChannel" />
                  </div>
                }
              </div>

            </div>
          </>
        ) : (
          <>
            <Col sm="12" md="9" lg="9" xl="9" className="whatsapp-nav">
              <Row>
                <Col sm="12" md="9" lg="9" xl="9">
                  <h5 className="mt-2">WhatsApp Channel</h5>
                </Col>
                {/* {profileData && profileData?.business.freeTrial ? (
                  ""
                ) : (
                  <>
                    <Col style={{ paddingLeft: "1em", paddingTop: "1em" }} md="5" >
                      {allChannelData?.length < (profileDetail?.business?.freeTrial ? noOfChannels('trial') :
                        (!profileDetail?.business?.plan?.code && !profileDetail?.business?.freeTrial) ? noOfChannels('ownNumber') :
                          noOfChannels(profileDetail?.business?.plan?.code))
                        && profileDetail?.role?.name !== 'MEMBER' &&
                        < ChannelConnect component="channelProfile" />}
                    </Col>
                  </>
                )} */}
              </Row>
              <Formik
                initialValues={initialValues}
                validationSchema={WhatsAppValidation(chooseNav)}
                onSubmit={handleSubmit}
                enableReinitialize={true}
              >
                {({ isSubmitting, setFieldValue, values }) => (
                  <Form>
                    <Row className="pt-3">
                      <Col
                        sm="12"
                        md="5"
                        lg="5"
                        xl="5"
                        className="whatsapp-nav"
                      >
                        <Label
                          onClick={() => setNavigate(0)}
                          className={chooseNav === 0 ? "navItem" : ""}
                        >
                          Profile
                        </Label>
                        <Label
                          onClick={() => setNavigate(1)}
                          className={chooseNav === 1 ? "navItem ml-3" : "ml-3"}
                        >
                          Complaince Info
                        </Label>
                        <Label
                          onClick={() => setNavigate(3)}
                          className={chooseNav === 3 ? "navItem ml-3" : "ml-3"}
                        >
                          Channel Settings
                        </Label>
                        {/* <Label
                  onClick={() => setNavigate(2)}
                  className={chooseNav === 2 ? "navItem" : ""}
                >
                  Widgets
                </Label> */}
                      </Col>
                      <Col sm="" md="3" lg="3" xl="3"></Col>
                      <Col sm="" md="4" lg="4" xl="4">
                        <Row className="justify-content-center" id="refreshIcon">
                          {/* <Col sm="" md="5" lg="5" xl="5" className="pe-0">
                    <Select
                      options={sample.map((sample) => ({
                        label: sample.label,
                        value: sample.value,
                      }))}
                      name="selectChannel"
                    />
                  </Col> */}
                          {/* <Col sm="" md="2" className="ps-4"> */}
                          <Button
                            className="refreshIconHistory col-2"
                            onClick={() => {
                              setIsRotating(true);
                              handleSync();
                            }}
                            disabled={profileDetail.role?.name === 'MEMBER'}
                          >
                            <svg
                              className={isRotating ? "rotate" : "reload"}
                              onAnimationEnd={() => {
                                setIsRotating(false)
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_2402_33684)">
                                <path
                                  d="M19.166 3.33325V8.33325H14.166"
                                  stroke="#666E7D"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M0.833984 16.6667V11.6667H5.83398"
                                  stroke="#666E7D"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M2.92565 7.49998C3.34829 6.30564 4.0666 5.23782 5.01354 4.39616C5.96048 3.55451 7.10521 2.96645 8.34089 2.68686C9.57657 2.40727 10.8629 2.44527 12.08 2.79729C13.297 3.14932 14.405 3.80391 15.3007 4.69998L19.1673 8.33331M0.833984 11.6666L4.70065 15.3C5.59627 16.1961 6.7043 16.8506 7.92133 17.2027C9.13836 17.5547 10.4247 17.5927 11.6604 17.3131C12.8961 17.0335 14.0408 16.4455 14.9878 15.6038C15.9347 14.7621 16.653 13.6943 17.0757 12.5"
                                  stroke="#666E7D"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_2402_33684">
                                  <rect width="20" height="20" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </Button>
                          {/* </Col> */}
                          <Col sm="" md="8" lg="8" xl="8" className="ps-0">
                            {/* <Button className="sendButton temp-btn w-100 p-2" >
                      GREEN TICK
                    </Button> */}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <WhatsappDataProvider>
                      {chooseNav === 0 && (
                        <>
                          <WhatsAppPrfile
                            WhatsappProData={get_WhatsappProfile}
                            getFilepath={getFilepath}
                            values={values}
                          />
                          <div className="whatsapp-submit">
                            <Button className="cancelButton me-2 " type="button" onClick={GoHome} disabled={profileDetail.role?.name === 'MEMBER'}>
                              Cancel
                            </Button>
                            <Button
                              className="sendButton"
                              sm="6"
                              type="submit"
                              disabled={isSubmitting || profileDetail.role?.name === 'MEMBER'}
                            >
                              Submit
                            </Button>
                          </div>
                        </>
                      )}
                      {chooseNav === 1 && (
                        <>
                          <ComplainceInfo
                            ComplainceInfoData={get_ComplianceInfo}
                            values={values}
                          />
                          <Row>
                            <Col
                              className="d-flex justify-content-end mt-3 pb-2"
                              sm=""
                              md="9"
                              lg="9"
                              xl="9"
                            >
                              <Button
                                className="cancelButton me-2"
                                type="button"
                                onClick={GoHome}
                                disabled={profileDetail.role?.name === 'MEMBER'}
                              >
                                Cancel
                              </Button>
                              <Button
                                className="sendButton"
                                type="submit"
                                disabled={isSubmitting || profileDetail.role?.name === 'MEMBER'}
                              >
                                Submit
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                      {chooseNav === 2 && (
                        <>
                          <WhatsAppWidget
                            WhatsappWidgetData={get_WhatsAppWidget}
                            values={values}
                            isScriptModal={isScriptModal}
                            handleScriptClose={handleScriptClose}
                            getfileData={getfileData}
                          />
                          <Row>
                            <Col
                              className="d-flex justify-content-end mt-3 pb-2"
                              sm=""
                              md="9"
                              lg="9"
                              xl="9"
                            >
                              <Button
                                className="sendButton w-25 "
                                type="submit"
                                disabled={isSubmitting}
                              >
                                Generate Widget Code
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                      {chooseNav === 3 && (
                        <>
                          <ChannelSettings />
                        </>
                      )}
                    </WhatsappDataProvider>
                  </Form>
                )}
              </Formik>
            </Col>
            {channelData?.value &&
              <Col sm="12" md="3" lg="3" xl="3" className="whatsapp-nav mb30">
                <ChannelDetails />
              </Col>
            }
          </>
        )}
      </Row>
    </Container>
  );
};

export default WhastappProfile;

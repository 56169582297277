import { createSlice } from "@reduxjs/toolkit";

export interface IChannel {
    id: string;
    uid: string;
    name: string;
    mobileNumber: string;
}

interface State {
    channelUid: string | null;
    businessUid: string | null;
    businessId: number | null;
    memberUid: string | null;
    channels: IChannel[] | null;
    currentChannel: IChannel | null;
    profile: any;
};

const initialState: State = {
    channelUid: null,
    businessUid: null,
    businessId: null,
    memberUid: null,
    currentChannel: null,
    channels: null,
    profile:null
};

export const metaSlice = createSlice({
    name: 'meta',
    initialState,
    reducers: {
        setChannelUid: (state, action: { payload: string }) => {
            return {
                ...state,
                channelUid: action.payload
            };
        },
        setBusinessUid: (state, action: { payload: string }) => {
            return {
                ...state,
                businessUid: action.payload
            };
        },
        setBusinessId: (state, action: { payload: number }) => {
            return {
                ...state,
                businessId: action.payload
            };
        },
        setMemberUid: (state, action: { payload: string }) => {
            return {
                ...state,
                memberUid: action.payload
            };
        },
        setChannels: (state, action: { payload: IChannel[] }) => {
            let updatedState = {...state};
            updatedState.channels = action.payload;
            const currentChannel = updatedState.channels.find(channel => channel.uid === state.channelUid);
            if (currentChannel) {
                updatedState.currentChannel = currentChannel;
            }
            return updatedState;
        },
        setProfile: (state, action: { payload: string }) => {
            return {
                ...state,
                profile: action.payload
            };
        },
    }
});


export const {
    setChannelUid,
    setBusinessUid,
    setBusinessId,
    setChannels,
    setMemberUid,
    setProfile
} = metaSlice.actions;

export default metaSlice.reducer;
export const ADD = (item: any) => {
    alert(item);
    return {
        type: "ADD_CART",
        payload: item
    }
}

// remove iteams
export const Delete = (item: any) => {
    return {
        type: "RMV_CART",
        payload: item
    }
}

// remove individual iteam

export const REMOVE = (item: any) => {
    return {
        type: "RMV_ONE",
        payload: item
    }
}

export const RegisterData = (data: any) => {
    return {
        type: 'REGISTER_DATA',
        payload: data
    };
};

export const BusinessId = (business: any) => {
    return {
        type: 'BUSINESS_ID',
        payload: business
    }
}
export const FlagData = (flag: any) => {
    return {
        type: 'FLAG_DATA',
        payload: flag
    }
}
export const TimeZone = (timeZone: any) => {
    return {
        type: 'TIMEZONE_DATA',
        payload: timeZone
    }
}

export const MemberData = (memberData: any) => {
    return {
        type: 'MEMBER_DATA',
        payload: memberData
    }
}

export const ChannelData = (channelData: any) => {
    return {
        type: 'CHANNEL_DATA',
        payload: channelData
    }
}

export const ChannelUid = (channelUid: any) => {
    return {
        type: 'CHANNEL_UID',
        payload: channelUid
    }
}

export const RoleData = (roleData: any) => {
    return {
        type: 'ROLE_DATA',
        payload: roleData
    }
}

export const resetState = () => {
    return { type: "RESET_STATE" };
};

export const getSubscriptions = (subscriptiondata: any) => {
    return {
        type: 'SUBSCRIPTION_DATA',
        payload: subscriptiondata
    }
}

export const  accountData = (accountData: any) => {
    return {
        type: 'ACCOUNT_DATA',
        payload: accountData
    }
}

export const leadData = (leadData: any) => {
    return {
        type: 'LEAD_DATA',
        payload: leadData
    }
}

export const removeExistData = (data:any) => {
    return {
        type: 'EXIST_COMMUNITY',
        payload: data,
    };
}
export const groupData = (data:any) => {
    return {
        type: 'GROUP_DATA',
        payload: data,
    };
}
export const tableReset = () => {
    return {
        type: 'TABLE_RESET',
        data: [],
        loading: false,
        error: null,
        tableData: [],
        selectedUids:[],
    };
}

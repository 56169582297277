import React, { useEffect, useState , useCallback } from 'react'
import { Alert, Button, Container, Form, Spinner, UncontrolledTooltip } from 'reactstrap'
import { useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus, faWarning } from '@fortawesome/free-solid-svg-icons'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import Avatar from 'react-avatar'
import * as yup from 'yup'
import { faEye, faTrashAlt } from '@fortawesome/free-regular-svg-icons'

import appointmentStyles from '../Appointments.module.scss'
import * as appointmentService from '../../../services/appointmentService'
import SyncButton from '../../../common/syncButton'
import Loader from '../../../shade/Loaders/smallLoader'
import { SOMETHING_WENT_WRONG } from '../../../constants/errorMessage'
import NoDataFallback from '../../../common/noDataFallback'
import RightSidebar from '../../../common/rightSidebar'
import { toast } from '../../../common/alert'
import { Formik } from 'formik'
import HolidayForm from './HolidayForm'
import { showAlertYesNo } from '../../../common/alertYesNo'
import moment from 'moment'
import ExpandableParagraph from '../../../common/expandableParagraph'
import BizTable from '../../../common/BizTable'
import SearchInput from '../../../common/searchComponent'

type HolidayList = { id: any, name: string, date: string }[]

interface FormData {
    holidayForm: {
        name: string,
        description: string,
        list: HolidayList
    }
}

type EditHolidayListProps = {
    listId: number
    onSave: () => void,
    onCancel: () => void
}

const EditHolidayList = ({ onSave, onCancel, listId }: EditHolidayListProps) => {
    const holidayQuery = useQuery({
        queryKey: ['holiady-list-complete', listId],
        queryFn: () => appointmentService.getSingleHolidayList({
            id: listId
        }),
    })

    if (holidayQuery.isLoading) return <Spinner />
    else if (holidayQuery.isError) {
        return (
            <Alert color='danger'>
                <FontAwesomeIcon icon={faWarning} className='mr-1' />
                {SOMETHING_WENT_WRONG}
            </Alert>
        )
    }
    const defaultFormData = {
        holidayForm: {
            name: holidayQuery.data.name,
            description: holidayQuery.data.description,
            list: [...holidayQuery.data.listItem]
        }
    }

    const schema = yup.object().shape({
        holidayForm: yup.object().shape({
            name: yup.string().required('Name is required'),
            description: yup.string().required('Description is required'),
            list: yup.array().of(yup.object().shape({
                name: yup.string().required('Name is required'),
                date: yup.string().required('Date is required'),
            })).min(1, 'Atleast 1 holiday is required')
        }).required()
    })

    const onSubmitHandler = async (values: FormData) => {
        function findDeletedItems(a: HolidayList, b: HolidayList): number[] {
            console.log({
                a,
                b
            });

            const deletedIds: number[] = [];
            const mapB = new Map(b.map(item => [item.id, true]));
            for (const item of a) {
                if (!mapB.has(item.id)) {
                    deletedIds.push(item.id);
                }
            }
            return deletedIds;
        }

        try {
            (window as any).PrevFormData = values
            await appointmentService.updateHolidayList({
                data: {
                    id: holidayQuery.data.id,
                    name: values.holidayForm?.name,
                    description: values.holidayForm?.description
                },
                list: values.holidayForm?.list.map(({ name, date, id }) => ({
                    id: typeof id === 'string' ? undefined : id,
                    name,
                    date
                })),
                deleteList: findDeletedItems(holidayQuery.data.listItem, values.holidayForm.list)
            })
            toast('success', 'Holiday list updated')
            onSave()
        } catch (error) {
            toast('error', 'Failed to update holiday list')
        }
    }

    return (
        <Container>
            <Formik
                validationSchema={schema}
                onSubmit={onSubmitHandler}
                initialValues={defaultFormData as any}
            >
                {({ handleSubmit, setValues }) => {
                    useEffect(() => {
                        setValues({
                            holidayForm: {
                                name: holidayQuery.data.name,
                                description: holidayQuery.data.description,
                                list: [...holidayQuery.data.listItem]
                            }
                        })
                    }, [holidayQuery.data])
                    return (
                        <Form onSubmit={handleSubmit}>
                            <HolidayForm onCancel={onCancel} submitButton='Update' />
                        </Form>
                    )
                }}
            </Formik>
        </Container>
    )
}


type CreateHolidayListProps = {
    onCreate: () => void,
    onCancel: () => void
}

function CreateHolidayList({ onCreate, onCancel }: CreateHolidayListProps) {
    const memberId = useSelector((state: any) => state.cartreducer.business?.id)
    const businessId = useSelector((state: any) => state.cartreducer.business?.business?.id)

    const defaultFormData = {
        holidayForm: {
            name: '',
            description: '',
            list: []
        }
    }

    const schema = yup.object().shape({
        holidayForm: yup.object().shape({
            name: yup.string().required('Name is required'),
            description: yup.string().required('Description is required'),
            list: yup.array().of(yup.object().shape({
                name: yup.string().required('Name is required'),
                date: yup.string().required('Date is required'),
            })).min(1, 'Atleast 1 holiday is required')
        }).required()
    })

    const onSubmitHandler = async (values: FormData) => {
        try {
            (window as any).PrevFormData = values
            await appointmentService.createHolidayList({
                data: {
                    name: values.holidayForm?.name,
                    description: values.holidayForm?.description,
                    creator: { id: memberId },
                    business: { id: businessId }
                },
                list: values.holidayForm?.list.map(({ name, date }) => ({
                    name,
                    date
                }))
            })
            toast('success', 'Holiday list created')
            onCreate()
        } catch (error) {
            toast('error', 'Failed to create holiday list')
        }
    }

    return (
        <Container>
            <Formik
                validationSchema={schema}
                onSubmit={onSubmitHandler}
                initialValues={defaultFormData as FormData}
            >
                {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                        <HolidayForm onCancel={onCancel} />
                    </Form>
                )}
            </Formik>
        </Container>
    )
}

type Props = {}

function HolidayList({ }: Props) {
    const [createList, setCreateList] = useState(false)
    const [editList, setEditList] = useState<null | number>(null)
    const businessId = useSelector(
        (state: any) => state.cartreducer.business?.business?.id
    )
    // const holidayQuery = useQuery({
    //     queryKey: ['holiady-list', 'all'],
    //     queryFn: () => appointmentService.getAllHolidayList(businessId, true),
    // })
    const [holidayQuery,setHolidayQuery] = useState([]);
    const [counts,setCounts] = useState(0)
 

    const deleteListHandler = async (listId: number) => {
        const alertResponse = await showAlertYesNo('question', 'Are you sure you want to delete this list?')
        if (alertResponse.isConfirmed) {
            try {
                await appointmentService.deleteHolidayList(listId)
                toast('success', 'Holiday list deleted')
                // holidayQuery.refetch()
                fetchData(0,20,'',0)
            } catch (error) {
                toast('error', 'Holiday list failed to delete')
            }
        }
    }

    // let content = <Loader />

    // if (holidayQuery.isFetched && !holidayQuery.isError) {
    //     content = (
    //         <BootstrapTable
    //             data={holidayQuery.data as any}
    //             search={false}
    //             pagination
    //             version='4'
    //             striped
    //             hover
    //             containerStyle={{ textAlign: 'left', marginBottom: 80 }}
    //             options={{
    //                 headerAlign: 'center',
    //                 noDataText: (
    //                     <div className='my-4'>
    //                         <NoDataFallback message='No Appointments Found' />
    //                     </div>
    //                 ),
    //                 sortIndicator: true,
    //                 hidePageListOnlyOnePage: true,
    //                 clearSearch: false,
    //                 alwaysShowAllBtns: true,
    //                 withFirstAndLast: true,
    //                 sizePerPage: 10,
    //                 paginationPosition: 'bottom',
    //             } as any}
    //         >
    //             <TableHeaderColumn dataField='id' isKey hidden>
    //                 #
    //             </TableHeaderColumn>
    //             <TableHeaderColumn
    //                 dataFormat={(_, val) => val.name}
    //             >
    //                 Name
    //             </TableHeaderColumn>
    //             <TableHeaderColumn
    //                 dataField='creator'
    //                 dataFormat={val => val ? (
    //                     <>
    //                         <Avatar fgColor="#fff" name={val} round size='25' textSizeRatio={2.5} />
    //                         <span className='ml-1'>{val}</span>
    //                     </>
    //                 ) : <span className='text-muted'> (No Creator)</span>}
    //             >
    //                 Creator
    //             </TableHeaderColumn>
    //             <TableHeaderColumn
    //                 dataFormat={(_, val) => val.description}
    //             >
    //                 Description
    //             </TableHeaderColumn>
    //             <TableHeaderColumn
    //                 dataFormat={(_, val) => ('listItem' in val && Array.isArray(val.listItem)) ? (
    //                     <ExpandableParagraph
    //                         text={val.listItem.map((holidayItem: any) =>
    //                             moment(holidayItem.date, 'YYYY-MM-DD').format('D MMM')
    //                         ).join(', ')}
    //                     />
    //                 ) : <span className='text-muted'>(No Date)</span>}
    //             >
    //                 Dates
    //             </TableHeaderColumn>
    //             <TableHeaderColumn
    //                 dataFormat={(_, holiday) => (
    //                     <div>
    //                         {/* <Button
    //                             id={'view-holiday-' + holiday.id}
    //                             color='white'
    //                             onClick={() => { }}
    //                         >
    //                             <FontAwesomeIcon icon={faEye} />
    //                         </Button>
    //                         <UncontrolledTooltip
    //                             placeholder='bottom'
    //                             target={'view-holiday-' + holiday.id}
    //                         >
    //                             View List
    //                         </UncontrolledTooltip> */}

    //                         <Button
    //                             id={'edit-holiday-' + holiday.id}
    //                             color='white'
    //                             onClick={() => setEditList(holiday.id)}
    //                         >
    //                             <FontAwesomeIcon icon={faEdit} />
    //                         </Button>
    //                         <UncontrolledTooltip
    //                             placeholder='bottom'
    //                             target={'edit-holiday-' + holiday.id}
    //                         >
    //                             Edit List
    //                         </UncontrolledTooltip>

    //                         <Button
    //                             id={'delete-list-' + holiday.id}
    //                             color='white'
    //                             className='text-danger'
    //                             onClick={() => deleteListHandler(holiday.id)}
    //                         >
    //                             <FontAwesomeIcon icon={faTrashAlt} />
    //                         </Button>
    //                         <UncontrolledTooltip
    //                             placeholder='bottom'
    //                             target={'delete-list-' + holiday.id}
    //                         >
    //                             Delete List
    //                         </UncontrolledTooltip>
    //                     </div>
    //                 )}
    //             >
    //                 Action
    //             </TableHeaderColumn>
    //         </BootstrapTable>
    //     )
    // } else if (holidayQuery.isError) {
    //     content = (
    //         <Alert color='danger'>
    //             <FontAwesomeIcon icon={faWarning} className='mr-1' />
    //             {SOMETHING_WENT_WRONG}
    //         </Alert>
    //     )
    // }
    const [searchTerm, setSearchTerm] = useState<string | null>();
    const onSearchChange = (searchTerm: string) => {
        const searchTermCheck = searchTerm.length > 0 ? searchTerm : null;
        setSearchTerm(searchTermCheck);
      };
    const fetchData = useCallback(
        async (
          pageIndex: number,
          pageSize: number,
          searchText: string,
          currentPage: number
        ): Promise<{ data: any; totalPages: number }> => {
          const payload={
            page: currentPage,
            limit: pageSize,
            businessId:businessId,
            withPreview:true,
            search:searchTerm
          }
          const response = await appointmentService.getAllHolidayListV2(payload);
          if(response){
            const { calyHoliday, count } = response?.data;
            setHolidayQuery(calyHoliday)
            const countData = searchTerm ? calyHoliday?.length : count;
            setCounts(countData)
            return { data: calyHoliday, totalPages: count };
          }
          else{
            return { data: [], totalPages: 0 };
          }
        },
        [businessId,searchTerm]
      );
      
    const columns = [
        {
            Header: (
                <input
                  type="checkbox"
                  hidden={true}                 
                />
              ),
              accessor: "checkbox",
              sticky: "left",
        },
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Creator',
            accessor: 'creator',
        },
        {
            Header: 'Description',
            accessor: 'description',
        },
        {
            Header: 'Dates',
            accessor: 'listItem',
            Cell: ({ cell, row }: any) => cell.value ?
                <ExpandableParagraph
                    text={cell.value.map((holidayItem: any) =>
                        moment(holidayItem.date, 'YYYY-MM-DD').format('D MMM')
                    ).join(', ')}
                /> : '-',           
        },
        {
            Header: 'Action',
            accessor: 'id',
            Cell: ({ cell, row }: any) => (
                <div>
                    <Button
                        id={'edit-holiday-' + cell.value}
                        color='white'
                        onClick={() => setEditList(cell.value)}
                    >
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    <UncontrolledTooltip
                        placeholder='bottom'
                        target={'edit-holiday-' + cell.value}
                    >
                        Edit List
                    </UncontrolledTooltip>

                    <Button
                        id={'delete-list-' + cell.value}
                        color='white'
                        className='text-danger'
                        onClick={() => deleteListHandler(cell.value)}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                    <UncontrolledTooltip
                        placeholder='bottom'
                        target={'delete-list-' + cell.value}
                    >
                        Delete List
                    </UncontrolledTooltip>
                </div>
            ),            
        },
    ] as any;

    return (
        <>
            {createList ? (
                <RightSidebar
                    handleCancel={() => setCreateList(false)}
                    title='Create Holiday List'
                    width='30%'
                >
                    <CreateHolidayList
                        onCreate={() => {
                            setCreateList(false)
                            fetchData(0,20,'',0)
                            // holidayQuery.refetch()
                        }}
                        onCancel={() => setCreateList(false)}
                    />
                </RightSidebar>
            ) : null}
            {editList !== null ? (
                <RightSidebar
                    handleCancel={() => setEditList(null)}
                    title='Edit Holiday List'
                    width='30%'
                >
                    <EditHolidayList
                        listId={editList}
                        onSave={() => {
                            setEditList(null)
                            fetchData(0,20,'',0)
                            // holidayQuery.refetch()
                        }}
                        onCancel={() => setEditList(null)}
                    />
                </RightSidebar>
            ) : null}
            <div className={appointmentStyles.appointments}>
                <h5>Holiday List </h5>
                <div className='row mb-2'>
                    <div className='col-md-3'>
                <SearchInput component="company" onSearchChange={onSearchChange} 
                    placeHolder="Search Holidays"
                    />
                    </div>
                    <div className='col-md-9 d-flex justify-content-end align-items-center'>
                        <Button
                            className='sendButton me-2 w-25'
                            onClick={() => setCreateList(true)}
                        >
                            <FontAwesomeIcon icon={faPlus} className='mr-1' />
                            Create Holiday List
                        </Button>
                        <SyncButton onClick={() =>{ fetchData(0,20,'',0)
                            setTimeout(()=>{toast("success", "Holiday List synced successfully");
                            },1000)
          
                        }

                            
                        } />
                    </div>
                </div>
                {/* {content} */}
            </div>
          <BizTable
          columns={columns}
          fetchData={fetchData}
          counts={counts}
          refetchData={holidayQuery}
          tableName='HOLIDAYS'
        />
        </>
    )
}

export default HolidayList
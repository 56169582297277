import React, { useEffect, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Avatar from 'react-avatar';
import { FiCheckCircle } from 'react-icons/fi';
import { MdOutlineDelete, MdOutlineContentCopy, MdAdd } from 'react-icons/md';
import { BsPencilSquare } from 'react-icons/bs';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useQueryClient } from '@tanstack/react-query';
import { PiWarningCircleBold } from 'react-icons/pi';

import { IKeyword } from '../ui/keywordInput';
import CreateFlow from './createFlow';
import DeleteFlow from './deleteFlow';
import styles from './flowList.module.css';
import VerifyBot from '../bots/verifyBot';
import { useAppSelector } from '../../hooks';
import CopyFlow from './copyFlow';
import BizTable from "../../../common/BizTable";
import { ErrorImage } from '../../../common/ErrorMessage';

interface IFlow {
    uid: string;
    flowName: string;
    member?: {
        name: string;
    };
    status: 1 | 0;
    updatedAt: string;
    flowType: string;
    flowInstances: IKeyword[];
    createdAt: Date;
    bot: {
        uid: string;
    };
    welcomeTrigger: any;
    error: any;
};

interface FlowsListProps {
    flows: IFlow[];
    botId: string;
    refetch: () => void;
    isLoading: boolean;
}

const FlowsList: React.FC<FlowsListProps> = ({ flows, refetch, botId, isLoading}) => {
    const MySwal = withReactContent(Swal);
    const queryClient = useQueryClient();
    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [editBotData, setEditBotData] = useState<any>(null);
    const [dropBotData, setDropBotData] = useState<any>(null);

    const currentChannel = useAppSelector(state => state.meta.currentChannel);
    const memberUid = useAppSelector(state => state.meta.memberUid);
    const navigate = useNavigate();

    function generateInstanceText(flowInstances: { payload: string }[]): string {
        let instances: string = flowInstances.map((flowInstance: any) => `${flowInstance.keyword}, `).join('');
        instances = instances.substring(0, instances.length - 2);
        return instances;
    }

    const NameData = (cell: string, row: any) => {
        return (
            <>
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id={`tooltip-${row.id}`}>{row.flowName}</Tooltip>}
                >
                    <div className={styles.ellipsisCell}>
                        {row.flowName}
                    </div>
                </OverlayTrigger>
                {row.welcomeTrigger && (
                    <OverlayTrigger
                        placement='right'
                        overlay={
                            <Tooltip>
                                Welcome Trigger is Enabled for this flow
                            </Tooltip>
                        }
                    >
                        <span>
                            <FiCheckCircle style={{ marginLeft: '5px', color: 'green' }} />
                        </span>
                    </OverlayTrigger>
                )}
            </>
        );
    }

    const CreatedByData = (cell: string, row: any) => {
        const { member } = row;
        return (
            <>
                {member && (
                    <>
                        <Avatar
                            fgColor="#fff"
                            name={member.name}
                            round
                            size="25"
                            textSizeRatio={3.5}
                        />
                        <span style={{ marginLeft: "5px", color: "#2E3967", marginTop: "3px" }}>
                            {" "}
                            {member.name}
                        </span>
                    </>
                )}
            </>
        );
    };

    const StatusData = (cell: string, row: any) => {
        return (
            <h6>
                {row.status === 1 ? (
                    <div className={[styles.badge, styles.success].join(" ")}>Published</div>
                ) : (
                    <div className={[styles.badge, styles.yellow].join(" ")}>Draft</div>
                )}
            </h6>
        );
    }

    const KeywordsData = (cell: string, row: any) => {
        const { flowInstances } = row;
        const allInstances = generateInstanceText(flowInstances);
        let previewInstances = generateInstanceText(flowInstances.slice(0, 2));
        !previewInstances && (previewInstances = "Order based flow");
        return (
            <>
                <span style={{ color: '#FFA523', fontSize: 20 }} className='me-2'>
                    <PiWarningCircleBold />
                </span>
                <span className="mt-2">
                    {previewInstances}
                </span>
                {flowInstances?.length > 2 ? (
                    <>
                        <span className="dimmed">...</span>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>{allInstances}</Tooltip>}
                            trigger="hover"
                        >
                            <Button variant='link' style={{ padding: 0 }}>
                                <MdAdd size={12} color='#000' />
                            </Button>
                        </OverlayTrigger>
                    </>
                ) : null}
            </>
        );
    }


    // const instances = (
    //     <>
    //         {previewInstances}
    //         {flow.flowInstances.length > 2 ? (
    //             <>
    //                 <span className="dimmed">...</span>
    //                 <OverlayTrigger
    //                     placement="top"
    //                     overlay={<Tooltip>{allInstances}</Tooltip>}
    //                     trigger="hover"
    //                 >
    //                     <Button variant='link' style={{ padding: 0 }}>
    //                         <MdAdd size={12} />
    //                     </Button>
    //                 </OverlayTrigger>
    //             </>
    //         ) : null}
    //     </>
    // );

    const updateList = () => {
        setShowEdit(false);
        setShowDelete(false);
        refetch();
    }

    const onVerifyBotHandler = (event: any, bot: any, flowUid: string) => {
        event.stopPropagation();
        MySwal.fire({
            title: <VerifyBot currentChannel={currentChannel} bot={bot} flowUid={flowUid} />,
            showConfirmButton: false,
            showCloseButton: true
        });
    }


    const copyFlowHandler = async (event: any, flow: any) => {
        event.stopPropagation();
        if (memberUid) {
            MySwal.fire({
                title: (
                    <CopyFlow
                        flow={flow}
                        queryClient={queryClient}
                        memberUid={memberUid}
                        onUpdate={refetch}
                    />
                ),
                showConfirmButton: false,
                showCloseButton: true
            });
        }
    }

    const goToDesignFlowHandler = (flowUid: string) => {
        navigate("/bots/flow/" + flowUid);
    }

    const handleDrop = async (event: any, rowData: any) => {
        setShowDelete(true);
        setDropBotData(rowData);
        event.stopPropagation();
    };

    const handleClickEdit = async (event: any, rowData: any) => {
        setEditBotData(rowData); // Store edit bot data in state
        setShowEdit(true);
        event.stopPropagation();
    };

    const ActionData = (cell: string, row: any) => {
        const { uid, status, bot } = row;
        return (
            <div>
                {status === 1 ? (
                    <>
                        <OverlayTrigger
                            placement='bottom-end'
                            overlay={
                                <Tooltip>
                                    Verify Your Bot
                                </Tooltip>
                            }
                        >
                            <Button variant='default' size='sm' className='p-0 me-2' onClick={(event) => onVerifyBotHandler(event, bot, uid)}>
                                <FiCheckCircle style={{ color: 'rgba(0, 126, 195, 1)' }} />
                            </Button>
                        </OverlayTrigger>
                    </>

                ) : null}
                <svg
                    width="16"
                    height="16"
                    onClick={(event: any) => handleClickEdit(event, row)}
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`${status === 1 ? 'ms-2' : 'ms-4'} `}
                >
                    <path
                        d="M7.5 3H3.22222C2.89807 3 2.58719 3.12877 2.35798 3.35798C2.12877 3.58719 2 3.89807 2 4.22222V12.7778C2 13.1019 2.12877 13.4128 2.35798 13.642C2.58719 13.8712 2.89807 14 3.22222 14H11.7778C12.1019 14 12.4128 13.8712 12.642 13.642C12.8712 13.4128 13 13.1019 13 12.7778V8.5"
                        stroke="#666E7D"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M11.9485 2.35199C12.1738 2.12661 12.4795 2 12.7982 2C13.117 2 13.4226 2.12661 13.648 2.35199C13.8734 2.57737 14 2.88304 14 3.20177C14 3.5205 13.8734 3.82617 13.648 4.05155L8.26608 9.43348L6 10L6.56652 7.73392L11.9485 2.35199Z"
                        stroke="#666E7D"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
                <MdOutlineContentCopy color='#666E7D' size={14} className='ms-2 me-2' onClick={(event) => copyFlowHandler(event, row)} />
                <svg
                    width="16"
                    height="16"
                    onClick={(event: any) => handleDrop(event, row)}
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2 4H3.33333H14"
                        stroke="#666E7D"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M12.6673 4.00016V13.3335C12.6673 13.6871 12.5268 14.0263 12.2768 14.2763C12.0267 14.5264 11.6876 14.6668 11.334 14.6668H4.66732C4.3137 14.6668 3.97456 14.5264 3.72451 14.2763C3.47446 14.0263 3.33398 13.6871 3.33398 13.3335V4.00016M5.33398 4.00016V2.66683C5.33398 2.31321 5.47446 1.97407 5.72451 1.72402C5.97456 1.47397 6.3137 1.3335 6.66732 1.3335H9.33398C9.68761 1.3335 10.0267 1.47397 10.2768 1.72402C10.5268 1.97407 10.6673 2.31321 10.6673 2.66683V4.00016"
                        stroke="#666E7D"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M6.66602 7.3335V11.3335"
                        stroke="#666E7D"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M9.33398 7.3335V11.3335"
                        stroke="#666E7D"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </div>
        );
    };

    const onRowClick = (row: any) => {
        const { uid } = row;
        goToDesignFlowHandler(uid);
    };

    const columns = [

        {
            Header: (
                <input
                    type="checkbox"
                    hidden={true}
                // checked={this.state.allChecked}
                // onChange={this.toggleAllCheckboxes}
                />
            ),
            accessor: "checkbox",
            sticky: "left",
        },

        {
            Header: "Name",
            accessor: "name",
            Cell: ({ cell, row }: any) => NameData(cell.value, row.original),
        },
        {
            Header: "Keywords",
            accessor: "flowInstances",
            Cell: ({ cell, row }: any) => KeywordsData(cell.value, row.original),
        },
        {
            Header: "Created by",
            accessor: "createdBy",
            Cell: ({ cell, row }: any) => CreatedByData(cell.value, row.original),
        },
        {
            Header: "Last Updated",
            accessor: "updatedAtFlow",
            Cell: ({ cell, row }: any) => moment(row?.original?.updatedAt).format("DD MMM hh.mm A"),
        },
        {
            Header: "Status",
            accessor: "flowStatus",
            Cell: ({ cell, row }: any) => StatusData(cell.value, row.original),
        },
        {
            Header: "Action",
            accessor: "uid",
            Cell: ({ cell, row }: any) => ActionData(cell.value, row.original),
        },

    ] as any;
    // const options = {
    //     headerAlign: "center",
    //     noDataText: "No records found",
    //     sortIndicator: true,
    //     hidePageListOnlyOnePage: true,
    //     clearSearch: false,
    //     alwaysShowAllBtns: true,
    //     withFirstAndLast: true,
    //     sizePerPage: 10,
    //     paginationPosition: "bottom",
    //     rowStyle: { cursor: "pointer" },
    //     onRowClick: onRowClick,
    // };

    return (
        <>
            {showEdit && (
                <CreateFlow
                    id={editBotData.uid}
                    show={showEdit}
                    onHide={() => setShowEdit(false)}
                    botId={botId}
                    editData={{
                        nameInput: editBotData.flowName,
                        flowType: editBotData.flowType,
                        flowInstances: editBotData.flowInstances,
                        welcomeTrigger: editBotData.welcomeTrigger,
                    }}
                    flow={editBotData}
                    onCreate={updateList}
                    showDelete={() => setShowDelete(true)}
                />
            )}
            {showDelete && (
                <DeleteFlow
                    uid={dropBotData.uid}
                    flowName={dropBotData.flowName}
                    show={showDelete}
                    onHide={() => setShowDelete(false)}
                    onDelete={updateList}
                />
            )}
            {/* <Table hover className='table table-hover table-bordered botflows-table'>
                <thead>
                    <tr>
                        <th style={{ width: 100 }}>Name</th>
                        <th style={{ width: 50 }}></th>
                        <th>Keywords</th>
                        <th>Created by</th>
                        <th>Last Updated</th>
                        <th>Status</th>
                        <th style={{ width: 15 }}></th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {botId && flows.length > 0 ? flows.map((flow: IFlow) => {
                        return (
                            <FlowsListItem
                                key={flow.uid}
                                flow={flow}
                                botId={botId}
                                onUpdate={refetch}
                            />
                        )
                    }) : null}
                </tbody>
            </Table> */}

            {/* { flows?.length === 0 && !isLoading ? (
                <Loader />
            ) : ( */}
                <BizTable
                    columns={columns}
                    // fetchData={fetchData}
                    counts={0}
                    refetchData={flows}
                    tableName="BOT_FLOW"
                    handleRowClick={onRowClick}
                /> 
                 {flows?.length > 0 ? '' : <ErrorImage />}
                {/* )} */}


            {/* <BootstrapTable
                data={flows}
                pagination={flows?.length > 10}
                version="4"
                striped
                hover
                options={options as any}
                containerStyle={{ textAlign: "left" }}
            >
                <TableHeaderColumn dataField="uid" isKey hidden>
                    #
                </TableHeaderColumn>
                <TableHeaderColumn dataField="flowName" dataAlign="left" width="100" dataFormat={NameData}
                  >
                    Name
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="flowInstances"
                    dataAlign="left"
                    width="100"
                    dataFormat={KeywordsData}
                >
                    Keywords
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="member.name"
                    dataAlign="left"
                    width="100"
                    dataFormat={CreatedByData}
                >
                    Created by
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="updatedAt"
                    dataAlign="center"
                    width="100"
                    dataFormat={(cell: string) => moment(cell).format("DD MMM hh.mm A")}
                >
                    Last Updated
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="uid"
                    dataAlign="center"
                    width="100"
                    dataFormat={StatusData}
                >
                    Status
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="uid"
                    dataAlign="center"
                    width="100"
                    dataFormat={ActionData}
                >
                    Action
                </TableHeaderColumn>
            </BootstrapTable> */}
            {/* {flows.length < 1 ? (
                <p style={{ textAlign: 'center', }} className='py-3'> No data found in the data table</p>
            ) : null} */}
        </>
    );
}


interface FlowsListItemProps {
    flow: IFlow;
    botId: string;
    onUpdate: () => void;
}

const FlowsListItem: React.FC<FlowsListItemProps> = ({ flow, botId, onUpdate }) => {
    const MySwal = withReactContent(Swal);
    const queryClient = useQueryClient();
    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const currentChannel = useAppSelector(state => state.meta.currentChannel);
    const memberUid = useAppSelector(state => state.meta.memberUid);
    const navigate = useNavigate();

    function generateInstanceText(flowInstances: { payload: string }[]): string {
        let instances: string = flowInstances.map((flowInstance: any) => `${flowInstance.keyword}, `).join('');
        instances = instances.substring(0, instances.length - 2);
        return instances;
    }

    const allInstances = generateInstanceText(flow.flowInstances);
    const previewInstances = generateInstanceText(flow.flowInstances.slice(0, 2));

    const instances = (
        <>
            {previewInstances}
            {flow.flowInstances.length > 2 ? (
                <>
                    <span className="dimmed">...</span>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{allInstances}</Tooltip>}
                        trigger="hover"
                    >
                        <Button variant='link' style={{ padding: 0 }}>
                            <MdAdd size={12} />
                        </Button>
                    </OverlayTrigger>
                </>
            ) : null}
        </>
    );

    const updateList = () => {
        setShowEdit(false);
        setShowDelete(false);
        onUpdate();
    }

    const onVerifyBotHandler = (bot: any, flowUid: string) => {
        MySwal.fire({
            title: <VerifyBot currentChannel={currentChannel} bot={bot} flowUid={flowUid} />,
            showConfirmButton: false,
            showCloseButton: true
        });
    }


    const copyFlowHandler = async (flow: any) => {
        if (memberUid) {
            MySwal.fire({
                title: (
                    <CopyFlow
                        flow={flow}
                        queryClient={queryClient}
                        memberUid={memberUid}
                        onUpdate={onUpdate}
                    />
                ),
                showConfirmButton: false,
                showCloseButton: true
            });
        }
    }

    const goToDesignFlowHandler = (flowUid: string) => {
        navigate("/bots/flow/" + flowUid);
    }

    return (
        <>
            <CreateFlow
                id={flow.uid}
                show={showEdit}
                onHide={() => setShowEdit(false)}
                botId={botId}
                editData={{
                    nameInput: flow.flowName,
                    flowType: flow.flowType,
                    flowInstances: flow.flowInstances,
                    welcomeTrigger: flow.welcomeTrigger,
                }}
                flow={flow}
                onCreate={updateList}
                showDelete={() => setShowDelete(true)}
            />
            <DeleteFlow
                uid={flow.uid}
                flowName={flow.flowName}
                show={showDelete}
                onHide={() => setShowDelete(false)}
                onDelete={updateList}
            />
            <tr style={{ cursor: "pointer", userSelect: "none" }}>
                <td style={{ width: 100 }} onClick={() => goToDesignFlowHandler(flow.uid)}>
                    <Link to={'/bots/flow/' + flow.uid}>{flow.flowName}</Link>
                    {flow.welcomeTrigger ? (
                        <OverlayTrigger
                            placement='right'
                            overlay={
                                <Tooltip>
                                    Welcome Trigger is Enabled for this flow
                                </Tooltip>
                            }
                        >
                            <span>
                                <FiCheckCircle style={{ marginLeft: '5px', color: 'green' }} />
                            </span>
                        </OverlayTrigger>
                    ) : null}
                </td>
                <td style={{ width: 50 }} onClick={() => goToDesignFlowHandler(flow.uid)}>
                    {flow.status !== 1 ? (
                        <span style={{ color: '#FFA523', fontSize: 20 }} className='ml-3'>
                            <PiWarningCircleBold />
                        </span>
                    ) : null}
                </td>
                <td onClick={() => goToDesignFlowHandler(flow.uid)}> {instances}</td>
                <td onClick={() => goToDesignFlowHandler(flow.uid)}>
                    {flow.member ? (
                        <>
                            <Avatar fgColor="#fff" name={flow.member.name} round size='25' textSizeRatio={3.5} />
                            <span style={{ marginLeft: '5px', color: '#2E3967' }}> {flow.member.name}</span>
                        </>
                    ) : null}
                </td>
                <td onClick={() => goToDesignFlowHandler(flow.uid)}>{moment(flow.updatedAt).format('DD MMM hh.mm A')}</td>
                <td onClick={() => goToDesignFlowHandler(flow.uid)}>
                    <h6>
                        {flow.status === 1 ? (
                            <div className={[styles.badge, styles.success].join(' ')}>Published</div>
                        ) : (
                            <div className={[styles.badge, styles.danger].join(' ')}>Draft</div>
                        )}
                    </h6>
                </td>
                <td style={{ width: 15 }}>
                    {flow.status === 1 ? (
                        <OverlayTrigger
                            placement='bottom-end'
                            overlay={
                                <Tooltip>
                                    Verify Your Bot
                                </Tooltip>
                            }
                        >
                            <Button variant='default' size='sm' onClick={() => onVerifyBotHandler(flow.bot, flow.uid)}>
                                <FiCheckCircle style={{ color: 'rgba(0, 126, 195, 1)' }} />
                            </Button>
                        </OverlayTrigger>
                    ) : null}
                </td>
                <td>
                    <OverlayTrigger
                        trigger="hover"
                        placement="bottom"
                        overlay={<Tooltip>Edit bot flow</Tooltip>}
                    >
                        <Button className='icon-btn' variant='link' onClick={() => setShowEdit(true)}><BsPencilSquare /></Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        trigger="hover"
                        placement="bottom"
                        overlay={<Tooltip>Copy bot flow</Tooltip>}
                    >
                        <Button className='icon-btn' variant='link' onClick={() => copyFlowHandler(flow)}><MdOutlineContentCopy /></Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        trigger="hover"
                        placement="bottom"
                        overlay={<Tooltip>Delete bot flow</Tooltip>}
                    >
                        <Button className='icon-btn' variant='link' onClick={() => setShowDelete(true)}><MdOutlineDelete /></Button>
                    </OverlayTrigger>
                </td>

            </tr>
        </>
    );
}


export default FlowsList;
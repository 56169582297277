import { FaTags } from 'react-icons/fa';
import Node from '../node';

function UpdateConversationTag(props: any) {
    let actionStatus;
    switch (props.data.actionStatus) {
        case "append":
            actionStatus = "Append tags";
            break;
        case "replace":
            actionStatus = "Replace tags";
            break;
        case "remove":
            actionStatus = "Remove all tags";
            break;
        default:
            actionStatus = "Add tags if Empty";
            break;
    }

    return (
        <Node {...props} label="Set Tags" content={(
            <>
                {props.data.contact.tags.length > 0 ? (
                    <div>
                        <FaTags size={8} className='mr-2' />
                        {props.data.contact.tags.map((tag: any) => `${tag.name}, `).join('').slice(0, -2)}
                    </div>
                ) : null}
            </>
        )} />
    );
}

export default UpdateConversationTag;
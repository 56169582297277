import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllIntegration } from "../../services/integrationService";
import {
  integrationTypes as initialIntegrationTypes,
  IntegrationType,
} from "./assets";
import "./main.scss";
import { Col, Row } from "reactstrap";
import { MdOutlineArrowOutward } from "react-icons/md";

// Define the type for the integration from the API response
interface Integration {
  title: string;
  imgSrc: string;
  name: string;
  id: number | null;
  uid: string;
  integrationType: string;
  connection: any | null;
  integrationName: string;
  connectionUid: string;
  integrationUid: string;
}

const Integration = () => {
  const businessId = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );
  const filterOptions = [
    "All",
    "Payment",
    "CRM",
    "Logistics",
    "E-Commerce",
    "Automation",
  ];
  const [allIntegration, setAllIntegration] = useState<Integration[]>([]);
  const [integrationTypes, setIntegrationTypes] = useState<IntegrationType[]>(
    initialIntegrationTypes
  );
  const [selectedFilter, setSelectedFilter] = useState<string>("All");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getAllIntegration(businessId).then((res:any) => {
      setAllIntegration(res);

      // Update the integrationTypes with connectionUid
      const updatedIntegrationTypes = initialIntegrationTypes.map((type) => {
        const matchingIntegration = res.find(
          (integration: any) =>
            integration.integrationType.toLowerCase() ===
            type.name.toLowerCase()
        );
        if (matchingIntegration) {
          return {
            ...type,
            connectionUid: matchingIntegration.connectionUid,
            integrationUid: matchingIntegration.uid,
            connectionName: matchingIntegration.integrationName
          };
        }
        return type;
      });
      setIntegrationTypes(updatedIntegrationTypes);
    });
  }, [businessId,location.state?.update]);

  const handleFilterChange = (filter: string) => {
    setSelectedFilter(filter);
  };

  const handleIntegrationClick = (integration: {
    name: string;
    imgSrc: string;
    title: string;
  }) => {
    // Navigate to the new link with the integration name and image
    navigate(`/integrations/${integration.name}`, {
      state: {
        imgSrc: integration.imgSrc,
        description: integration.title,
      },
    });
  };

  const handleNewClick = (
    integrationUid: string,
    integration: {
      name: string;
      imgSrc: string;
      title: string;
      connectionUid: string;
    }
  ) => {
    navigate(`/integrations/${integration.name}/edit/${integrationUid}`, {
      state: {
        imgSrc: integration.imgSrc,
        description: integration.title,
        connectionUid: integration.connectionUid,
      },
    });
  };

  const filteredIntegrations =
    selectedFilter === "All"
      ? integrationTypes
      : integrationTypes.filter((type) =>
          type.type
            ?.toLowerCase()
            .split(" || ")
            .includes(selectedFilter.toLowerCase())
        );

  return (
    <div>
      {allIntegration.length === 0 ? (
        <></>
      ) : (
        <div className="mt-2 mb-3">
          <div className="ms-3 mb-3">
            <h5 className="fw-bold">My Integrations</h5>
          </div>
          <Row>
            {allIntegration.map((integration) => {
              const integrationTypeFormatted =
                integration.integrationType.charAt(0).toLowerCase() +
                integration.integrationType.slice(1);
                

              const matchedIntegration = integrationTypes.find(
                (type) => type.name === integrationTypeFormatted
              );
              

              if (matchedIntegration) {
                return (
                  <Col md={3} key={integration.uid} className="ps-4 pe-4">
                    <div
                      key={integration.uid}
                      className="matchedIntegration-card"
                    >
                      <img src={matchedIntegration.imgSrc} alt="" />
                      <p className="header">{matchedIntegration.name === "sprocket"? "Ship Rocket" : matchedIntegration.name}</p>
                      <p className="body">{matchedIntegration.decription}</p>
                      <div
                        className="new-btn"
                        onClick={() =>
                          handleNewClick(
                            matchedIntegration.integrationUid || "",
                            {
                              name: matchedIntegration.name,
                              imgSrc: matchedIntegration.imgSrc,
                              title: matchedIntegration.title || "",
                              connectionUid:
                                matchedIntegration.connectionUid || "",
                            }
                          )
                        }
                      >
                        {matchedIntegration.connectionName}
                      </div>
                    </div>
                  </Col>
                );
              }

              return null;
            })}
          </Row>
        </div>
      )}
      <div>
        <h5 className="ms-3 mb-4 fw-bold">Available Integrations</h5>

        <div className="filter-options">
          {filterOptions.map((option) => (
            <button
              key={option}
              className={`option-result ${
                selectedFilter === option ? "selected" : ""
              }`}
              onClick={() => handleFilterChange(option)}
            >
              {option}
            </button>
          ))}
        </div>
      </div>
      <div>
        {/* complete integration list */}
        <Row>
          {filteredIntegrations.map((integration) => (
            <Col
              md={3}
              key={integration.id}
              className={`ps-4 pe-4 ${
                !(
                  integration.name === "sprocket" ||
                  integration.name === "Pabbly" || 
                  integration.name === "Sangam CRM" 
                )
                  ? "disabled"
                  : ""
              }`}
              onClick={() => {
                integration.name === "sprocket" &&
                  handleIntegrationClick({
                    name: integration.name,
                    imgSrc: integration.imgSrc,
                    title: integration.title || "",
                  });

                integration.name === "Pabbly" &&
                  window.open("https://connect.pabbly.com/", "_blank");

                integration.name === "Sangam CRM" &&
                window.open("https://sangamcrm.com/crm-integration/crm-whatsapp-integration/", "_blank");
              }}
              style={{
                cursor:
                  integration.name === "sprocket" ||
                  integration.name === "Pabbly"
                    ? "pointer"
                    : "default",
              }}
            >
              <div className="matchedIntegration-card">
                <img src={integration.imgSrc} alt="" />
                <div className="d-flex">
                  <p className="header">{integration.name === "sprocket" ? "Ship Rocket" : integration.name}</p>
                  {(integration.name === "Pabbly" || integration.name === "Sangam CRM") && (
                    <div className="d-flex align-items-center ms-2">
                      <MdOutlineArrowOutward size={18} color="#3182ce" />
                    </div>
                  )}
                </div>
                <p className="body">{integration.decription}</p>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default Integration;

import React, { useEffect, useMemo, useState } from 'react';
import { Form, Offcanvas, Button, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useFormikContext } from 'formik';
import Creatable from 'react-select/creatable';
import { useQuery } from '@tanstack/react-query';

import styles from './updateConversationTag.module.scss';
import { IEditorProps } from '../types';
import { useElmentEditor } from '../hooks';
import EditorCaption from '../editorCaption';
import { uuidv4 } from '../../../../utils/uuid';
import { setTagsIcon } from '../../../../icons';
import { useAppSelector } from '../../../../hooks';
import { createTag, getBotTags } from '../../../../services/contacts';
import { toast } from '../../../../../common/alert';

export interface IContactTag {
    name: string;
    id: number;
    uid: string;
}

interface ContactTagsInputProps {
    value: IContactTag[];
    error: undefined | { value: string }[];
    touched: undefined | { value: boolean }[];
}

export const ContactTagsInput: React.FC<ContactTagsInputProps> = (props) => {
    const formik = useFormikContext();
    const businessUid = useAppSelector((state) => state.meta.businessUid);
    const memberUid = useAppSelector((state) => state.meta.memberUid);
    const tagsQuery = useQuery({
        queryKey: ["contact-tags", businessUid],
        queryFn: () => {
            if (!businessUid) return new Promise(() => ([] as IContactTag[]));
            return getBotTags(businessUid);
        },
    });
    const tags = useMemo(() => {
        if (tagsQuery.isError || tagsQuery.isLoading) {
            return ([] as IContactTag[]);
        }
        return (tagsQuery.data as any).data.tagsDto.map((tag: IContactTag) => {
            return {
                ...tag,
                uid: uuidv4()
            }
        });
    }, [tagsQuery.data, tagsQuery.isLoading, tagsQuery.isError]);

    const createTagHandler = async (tagName: string,) => {
        if (businessUid && memberUid) {
            try {
                const createdTag = await createTag(businessUid, memberUid, tagName);
                const updatedTags = [...props.value];
                updatedTags.push({
                    uid: uuidv4(),
                    id: createdTag.data.id,
                    name: tagName
                });
                formik.setFieldValue(`contact.tags`, updatedTags);
            } catch (error) {
                toast("error", "Failed to create new tag");
            }
        }
    }

    if (tagsQuery.isLoading) return <Spinner />;
    if (tagsQuery.isError) return <p>Something went wrong, try again later.</p>;

    const transformTags = (tag: IContactTag) => {
        return { value: tag.id, label: tag.name };
    }

    return (
        <>
            <div className={styles.tagsContainer}>
                {props.value.map(tag => {
                    return (
                        <div className={styles.contactTag} key={tag.uid}>
                            {tag.name}
                            <button
                                type='button'
                                onClick={() => {
                                    const updatedTags = [
                                        ...props.value.filter(t => t.id !== tag.id)
                                    ];
                                    formik.setFieldValue(`contact.tags`, updatedTags);
                                }}
                            >&times;</button>
                        </div>
                    );
                })}
            </div>
            <Form.Label>Tags <span className="required"></span></Form.Label>
            <Creatable
                options={tags.map(transformTags)}
                isClearable={false}
                onChange={(selectItem: any) => {
                    const updatedTags = [
                        ...props.value.filter(tag => tag.id !== selectItem.value)
                    ];
                    updatedTags.push({
                        uid: uuidv4(),
                        id: selectItem.value,
                        name: selectItem.label
                    });
                    formik.setFieldValue(`contact.tags`, updatedTags);
                }}
                onCreateOption={createTagHandler}
            />
        </>
    );
}

interface FormData {
    contact: {
        tags: IContactTag[];
    }
};


function UpdateConversationTagEditor(props: IEditorProps) {
    const [formData, setFormData] = useState<FormData>({
        contact: {
            tags: []
        }
    });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'actions/update_contactTags',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);

    const tagScheme = yup.object().shape({
        id: yup.number(),
        name: yup.string().required('Tag is required')
    });
    const schema = yup.object().shape({
        contact: yup.object().shape({
            tags: yup.array()
                .of(tagScheme)
                .min(1, 'At least one tag is required')
                .required('At least one tag is required')
        })
    });

    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, values, touched, errors, setValues }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption="Set Tags" icon={<img width={20} alt='' src={setTagsIcon} />} />
                        <Offcanvas.Body>
                            <Form.Group className='mb-3'>
                                <ContactTagsInput
                                    value={values.contact.tags}
                                    error={errors.contact?.tags as undefined | { value: string }[]}
                                    touched={touched.contact?.tags as undefined | { value: boolean }[]}
                                />
                                {(touched.contact?.tags && errors.contact?.tags && !Array.isArray(errors.contact?.tags)) ? (
                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        {errors.contact?.tags}
                                    </div>
                                ) : null}
                            </Form.Group>
                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit'>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}
export default UpdateConversationTagEditor;

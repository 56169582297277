import "../styles.scss";
import { IoTicketOutline } from "react-icons/io5";
import { IoPersonOutline } from "react-icons/io5";
import { BsClockHistory } from "react-icons/bs";
import { useState } from "react";
import TicketUpdate from "../../ticketUpdate";
import RightSidebar from "../../../../common/rightSidebar";
import ContactDetails from "./ContactDetails";
import { CiCalendarDate } from "react-icons/ci";
import { UncontrolledTooltip } from "reactstrap";


interface TicketDetailsProps {
  selectedTicket: any;
  setLoadTicket: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  setSelectedTicket: React.Dispatch<React.SetStateAction<any>>;
  setIsMemberSelected: React.Dispatch<React.SetStateAction<boolean>>;
  isMemberSelected: boolean;
  sendPressed: boolean;
  setCallStatusCount: React.Dispatch<React.SetStateAction<boolean>>;
  loadTickets: (page: number, search?: string) => void;
}

const TicketDetails: React.FC<TicketDetailsProps> = (props) => {
  const [detailsType, setDetailsType] = useState<string>("moreDetails");
  const contact = props.selectedTicket?.contact ?? {};

  const contactDetails = [
    { title: "Full Name", value: contact.name ?? "-" },
    { title: "First Name", value: contact.name?.split(" ")[0] ?? "-" },
    { title: "Last Name", value: contact.name?.split(" ")[1] ?? "-" },
    { title: "Email", value: contact.email ?? "-" },
    { title: "Phone", value: contact.phoneNumber ?? "-" },
    { title: "Mobile", value: contact.mobileNumber ?? "-" },
    { title: "Job Title", value: contact.jobTitle ?? "-" },
    { title: "Created Date", value: contact.createdAt ?? "-" },
  ];

  let badgeStatus = props.selectedTicket?.fields
    ?.filter((f: any) => f.type === "status")[0]
    ?.value.toLowerCase();

  let badgeColor =
    badgeStatus === "closed"
      ? "#A7A9B2"
      : badgeStatus === "pending"
      ? "#DFCD24"
      : badgeStatus === "resolved"
      ? "#6EC77C"
      : badgeStatus === "open"
      ? "#007EC3"
      : "";

      function convertTimeFormat(timestamp: string): string {
        if (!timestamp) {
          return "-";
        }
        const date = new Date(timestamp);
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
    
        const day = date.getDate();
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? "PM" : "AM";
        const hours12 = hours % 12 || 12;
    
        return `${day} ${month} ${year} ${hours12}:${minutes
          .toString()
          .padStart(2, "0")} ${ampm}`;
      }

  return (
    <div className="ticketDetails">
      <div>
        <div className="ticket-details-header">
          <div className="more-details">More Details</div>
          <div className="ticket-details-functionalites">
            <div
              className="func-icons"
              id="requestedBy"
            >
              <IoPersonOutline size={17} />
            </div>
            <UncontrolledTooltip placement="top" target="requestedBy">
                  Requested By : {props.selectedTicket?.contact?.name || "-"}
            </UncontrolledTooltip>
            <div
              className="func-icons"
              id="requestedOn"
            >
              <CiCalendarDate size={17} />
            </div>
            <UncontrolledTooltip placement="top" target="requestedOn">
              Requested On : {convertTimeFormat(props.selectedTicket?.createdAt)}
            </UncontrolledTooltip>
            <div
              className="func-icons"
              onClick={() => setDetailsType("moreDetails")}
              style={{
                background: badgeColor,
                color: 'white'
              }}
              title={badgeStatus}
            >
              <IoTicketOutline size={17} />
            </div>
          </div>
        </div>

        <div className="ticket-details-body">
          <TicketUpdate
            selectedTicket={props.selectedTicket}
            setSelectedTicket={props.setSelectedTicket}
            setLoadTicket={props.setLoadTicket}
            isMemberSelected={props.isMemberSelected}
            setIsMemberSelected={props.setIsMemberSelected}
            sendPressed = {props.sendPressed}
            setCallStatusCount = {props.setCallStatusCount}
            loadTickets={props.loadTickets}
            
          />
        </div>
      </div>
      {/* 
      {detailsType === "contactDetails" && (
        <RightSidebar
          handleCancel={() => setDetailsType("moreDetails")}
          title="Contact Details"
        >
          <ContactDetails
            data={contactDetails}
            handleCancel={() => setDetailsType("moreDetails")}
          />
        </RightSidebar>
      )} */}
    </div>
  );
};

export default TicketDetails;

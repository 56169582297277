import io from 'socket.io-client';

let SOCKET_SERVER_URL: string;
if (window.location.hostname === "app.bizmagnets.ai") {
  SOCKET_SERVER_URL = "https://api.bizmagnets.ai/";
} else {
  SOCKET_SERVER_URL = "https://biz.vamosys.com/";
}

const socket = io(SOCKET_SERVER_URL, {
  reconnection: false,
  // reconnectionAttempts: Infinity,
  // reconnectionDelay: 1000,
  // reconnectionDelayMax: 5000,
  randomizationFactor: 0.5,
  timeout: 20000,
  extraHeaders: {
    business_uid: localStorage.getItem("businessId"),
  }
});

export { SOCKET_SERVER_URL, socket };

import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col, Offcanvas, FormControl } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FaMinusCircle } from 'react-icons/fa';
import ReactJson from 'react-json-view'
import Select from 'react-select';

import styles from './apiCalls.module.css'
import { IEditorProps } from './types';
import { useElmentEditor } from './hooks';
import { uuidv4 } from '../../../utils/uuid';
import EditorCaption from './editorCaption';
import VariableInputLabel from './variableInputLabel';
import { apiCallIcon } from '../../../icons';
import * as designFlowService from '../../../services/designFlow';

interface IKeyValue {
    id: string;
    key: string;
    value: string;
};

interface Variable {
    id: string;
    path: string;
    name: string;
    type: 'OBJECT' | 'TEXT' | 'NUMBER';
}

interface IApiData {
    url: string;
    method: string;
    body: any;
    params: IKeyValue[];
    headers: IKeyValue[];
}

interface FormData {
    apiData: IApiData,
    variables: Variable[];
};

function isJSONString(str: string): boolean {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
}


interface JSONInputProps {
    value: any;
    touched: boolean;
}

const JSONInput: React.FC<JSONInputProps> = props => {
    const formik = useFormikContext();
    const [error, setError] = useState(false);
    const [textValue, setTextValue] = useState('{}');
    useEffect(() => {
        setTimeout(() => {
            if (textValue === "{}")
                setTextValue(JSON.stringify(props.value));
        }, 200);
    }, [props.value]);

    const isInvalid = props.touched && error;

    return (
        <>
            <Form.Control
                as="textarea"
                value={textValue}
                isInvalid={isInvalid}
                isValid={props.touched && !error}
                onChange={e => {
                    const isJSON = isJSONString(e.target.value)
                    if (isJSON) {
                        formik.setFieldValue('apiData.body', JSON.parse(e.target.value));
                    } else {
                        formik.setFieldValue('apiData.body', null);
                    }
                    setError(!isJSON);
                    setTextValue(e.target.value);
                }}
                className={styles.customTextArea}
            />
            {isInvalid ? (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                    Invalid JSON
                </div>
            ) : null}
        </>
    )
}


interface KeyValueInputProps {
    name: 'body' | 'params' | 'headers';
    onChange: any;
    data: IKeyValue[];
    values: any;
    errors: any;
    touched: any;
    addLabel: string;
};

const KeyValueInput: React.FC<KeyValueInputProps> = props => {
    const formik = useFormikContext();

    function deleteElementHandler(id: string) {
        let newData: IKeyValue[] = JSON.parse(JSON.stringify(props.data));
        newData = newData.filter((keyVal: IKeyValue) => keyVal.id !== id);
        formik.setFieldValue(`apiData.${props.name}`, newData);
    }

    function createElement() {
        let newData: IKeyValue[] = JSON.parse(JSON.stringify(props.data));
        newData.push({ id: uuidv4(), key: '', value: '' });
        formik.setFieldValue(`apiData.${props.name}`, newData);
    }

    if (!Array.isArray(props.data)) {
        return null;
    }

    return (
        <div>
            {props.data.map((keyVal, index) => {
                const isError: boolean = (props.errors && props.errors.apiData && props.errors.apiData[props.name] && props.errors.apiData[props.name][index]) ? true : false;
                const isTouched: boolean = (props.touched && props.touched.apiData && props.touched.apiData[props.name] && props.touched.apiData[props.name][index]) ? true : false;

                return (
                    <div className={styles.keyValueContainer} key={keyVal.id}>
                        <Row style={{ alignItems: 'center' }}>
                            <Col xs={1}>
                                <Button
                                    size='sm'
                                    variant='default'
                                    className='text-danger'
                                    onClick={() => deleteElementHandler(keyVal.id)}
                                >
                                    <FaMinusCircle />
                                </Button>
                            </Col>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Control
                                        placeholder='Key'
                                        name={`apiData.${props.name}[${index}].key`}
                                        value={props.values.apiData[props.name][index].key}
                                        onChange={props.onChange}
                                        isInvalid={(isTouched && props.touched.apiData[props.name][index].key) && (isError && props.errors.apiData[props.name][index].key)}
                                        isValid={(isTouched && props.touched.apiData[props.name][index].key) && !(isError && props.errors.apiData[props.name][index].key)}
                                    />
                                </Form.Group>
                                <Form.Group className='mb-3'>
                                    <FormControl
                                        as='input'
                                        name={`apiData.${props.name}[${index}].value`}
                                        placeholder='Value'
                                        value={props.values.apiData[props.name][index].value}
                                        onChange={props.onChange}
                                        isInvalid={(isTouched && props.touched.apiData[props.name][index].value) && (isError && props.errors.apiData[props.name][index].value)}
                                        isValid={(isTouched && props.touched.apiData[props.name][index].value) && !(isError && props.errors.apiData[props.name][index].value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                )
            })}
            <Button onClick={createElement} className='sendButton'>+ Add {props.addLabel}</Button>
        </div>
    );
}


interface VariableInputProps {
    onChange: any;
    data: Variable[];
    values: any;
    errors: any;
    touched: any;
    variablePathSuggestions: string[];
};

const VariableInput: React.FC<VariableInputProps> = props => {
    const formik = useFormikContext();

    function deleteElementHandler(id: string) {
        let newData: Variable[] = JSON.parse(JSON.stringify(props.data));
        newData = newData.filter((variabel: Variable) => variabel.id !== id);
        formik.setFieldValue('variables', newData);
    }

    function createElement() {
        let newData: Variable[] = JSON.parse(JSON.stringify(props.data));
        newData.push({ id: uuidv4(), name: '', path: '', type: 'TEXT' });
        formik.setFieldValue('variables', newData);
    }

    return (
        <div>
            {props.data.map((keyVal, index) => {
                const isError: boolean = (props.errors && props.errors.variables && props.errors.variables[index]) ? true : false;
                const isTouched: boolean = (props.touched && props.touched.variables && props.touched.variables[index]) ? true : false;

                return (
                    <div className={styles.keyValueContainer} key={keyVal.id}>
                        <Row style={{ alignItems: 'center' }}>
                            <Col xs={1}>
                                <Button
                                    size='sm'
                                    variant='default'
                                    className='text-danger'
                                    onClick={() => deleteElementHandler(keyVal.id)}
                                >
                                    <FaMinusCircle />
                                </Button>
                            </Col>
                            <Col>
                                <VariableInputLabel
                                    label='Variable'
                                    required
                                >
                                    <Form.Control
                                        placeholder='Name'
                                        name={`variables[${index}].name`}
                                        id={`variables[${index}].name`}
                                        value={props.values.variables[index].name}
                                        onChange={props.onChange}
                                        isInvalid={(isTouched && props.touched.variables[index].name) && (isError && props.errors.variables[index].name)}
                                        isValid={(isTouched && props.touched.variables[index].name) && !(isError && props.errors.variables[index].name)}
                                    />
                                </VariableInputLabel>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Value/Path to the Value in the response <span className='required'></span></Form.Label>
                                    <Select
                                        name={`variables[${index}].path`}
                                        value={props.values.variables[index].path ? { label: props.values.variables[index].path, value: props.values.variables[index].path } : ''}
                                        options={props.variablePathSuggestions.map((path: string) => {
                                            return {
                                                label: path,
                                                value: path
                                            }
                                        })}
                                        onChange={({ value }: any) => {
                                            formik.setFieldValue(`variables[${index}].path`, value);
                                        }}
                                        menuPlacement="auto"
                                        maxMenuHeight={150}
                                    />
                                </Form.Group>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Variable type</Form.Label>
                                    <Form.Select
                                        name={`variables[${index}].type`}
                                        value={props.values.variables[index].type}
                                        onChange={props.onChange}
                                    >
                                        <option value="TEXT">Text</option>
                                        <option value="NUMBER">Number</option>
                                        <option value="BOOLEAN">Boolean</option>
                                        <option value="ARRAY">Array</option>
                                        <option value="OBJECT">Object</option>
                                        <option value="DATE">Date</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                )
            })}
            <Button onClick={createElement} className='sendButton'>+ Add variable</Button>
        </div>
    );
}

function transformFormData(data: FormData) {
    const handleKeyValue = (values: IKeyValue[]) => {
        return values.reduce((result: Record<string, string>, { key, value }) => {
            result[key] = value;
            return result;
        }, {});
    };

    const updatedHeaders = handleKeyValue(data.apiData.headers);
    const updatedParams = handleKeyValue(data.apiData.params);

    let updatedData: any = {
        ...data,
        apiData: {
            ...data.apiData,
            headers: updatedHeaders,
            params: updatedParams,
        },
    };

    if (
        'Content-Type' in updatedData.apiData.headers &&
        updatedData.apiData.headers['Content-Type'] === 'application/x-www-form-urlencoded'
    ) {
        updatedData.apiData.body = handleKeyValue(updatedData.apiData.body);
    }

    return updatedData;
}

const ApiCallsEditor: React.FC<IEditorProps> = props => {
    const [formData, setFormData] = useState<FormData>({
        apiData: {
            url: '',
            method: 'GET',
            params: [],
            body: {},
            headers: [
                { id: uuidv4(), key: "Content-Type", value: "application/json" }
            ]
        },
        variables: []
    });
    const [apiResponseUrl, setApiResponseUrl] = useState<null|string>(null);
    const [variablePathSuggestions, setVariablePathSuggestions] = useState<string[]>([]);
    const [apiResponse, setApiResponse] = useState<null | string>(null);
    const { init, saveElementChanges } = useElmentEditor({
        type: 'api_call',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);
    const [isJsonBody, setIsJsonBody] = useState(true);

    const keyValueSchema = yup.object().shape({
        id: yup.string(),
        key: yup.string().required('Key is required'),
        value: yup.string().required('Value is required'),
    });
    const variableSchema = yup.object().shape({
        id: yup.string(),
        name: yup.string().required('Variable name is required'),
        path: yup.string().required('Path is required'),
    });
    const schema = yup.object().shape({
        apiData: yup.object().shape({
            url: yup.string().required('URL is required'),
            method: yup
                .string()
                .oneOf(['GET', 'POST', 'PATCH', 'PUT', 'DELETE'])
                .required('Method is required'),
            body: isJsonBody ? yup.mixed().notOneOf([null], 'JSON is required') : yup.array().of(keyValueSchema),
            params: yup.array().of(keyValueSchema),
            headers: yup.array().of(keyValueSchema)
        }),
        variables: yup.array().of(variableSchema)
    });

    async function updateVariableSuggestion(values: IApiData) {
        const { url, method, headers, params } = values;
        if(url.trim() !== "") {
          const headersObj = Array.isArray(headers) ? headers.reduce((obj, header) => ({ ...obj, [header.key]: header.value }), {}) : headers;
          const paramsObj = Array.isArray(params) ? params.reduce((obj, params) => ({ ...obj, [params.key]: params.value }), {}) : params;
          const apiCallBody:any = {
            url,
            headers: headersObj,
            params: paramsObj,
            method
          };

          try {
            const response: any = await designFlowService.apiCall(apiCallBody);
            setApiResponseUrl(url);
            const data = response.data;

            setApiResponse(JSON.stringify(data));
            const constructPaths = (obj: any, currentPath: string, paths: string[]) => {
                if (Array.isArray(obj)) {
                    obj.forEach((item, index) => constructPaths(item, `${currentPath}.[${index}]`, paths));
                } else if (typeof obj === 'object') {
                    for (const key in obj) {
                        constructPaths(obj[key], `${currentPath}.${key}`, paths);
                    }
                } else {
                    paths.push(currentPath);
                }
            };

            const paths: string[] = ['response'];
            constructPaths(data, 'response', paths);
            setVariablePathSuggestions(
                paths.reduce((uniqueArray: string[], currentElement: string) => {
                    if (!uniqueArray.includes(currentElement)) {
                        uniqueArray.push(currentElement);
                    }
                    return uniqueArray;
                }, [])
            );
          } catch (error) {
            setApiResponse(null);
            console.error('API call error: ', error);
          }
      }
    }

    // Function to select the path from the preview
    const findJsonPath = (json: any, input: any): string | null => {
        const constructPathForNamespace = (obj: any, namespace: string[], name: string, value: any, currentPath: string): string | null => {
            for (let i = 0; i < namespace.length; i++) {
                const key = namespace[i];
                const isIndex = /^\d+$/.test(key);
                currentPath += isIndex ? `.[${key}]` : `.${key}`;

                obj = isIndex ? obj[parseInt(key)] : obj[key];
                if (obj === undefined) {
                    return null;
                }
            }

            const isNameIndex = /^\d+$/.test(name);
            if (isNameIndex) {
                const index = parseInt(name);
                if (Array.isArray(obj) && obj[index] === value) {
                    return `${currentPath}.[${name}]`;
                }
            } else {
                if (obj.hasOwnProperty(name) && obj[name] === value) {
                    return `${currentPath}.${name}`;
                }
            }
            return null;
        };

        if (typeof json === 'string') {
            json = JSON.parse(json);
        }

        return constructPathForNamespace(json, input.namespace, input.name, input.value, 'response');
    };

    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, handleChange, values, touched, errors, setValues, setFieldValue }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);
                useEffect(() => {
                    const setContentType = (value: string) => {
                        if (values.apiData.headers.find(header => header.key === 'Content-Type')) {
                            setFieldValue('apiData.headers', values.apiData.headers.map(header => {
                                if (header.key === 'Content-Type') {
                                    return {
                                        ...header,
                                        value
                                    };
                                }
                                return header;
                            }));
                        } else {
                            const updatedHeaders = [...values.apiData.headers];
                            updatedHeaders.push({ id: uuidv4(), key: 'Content-Type', value });
                            setFieldValue('apiData.headers', updatedHeaders);
                        }
                    }

                    if (isJsonBody) {
                        setContentType('application/json');
                    } else {
                        setContentType('application/x-www-form-urlencoded');
                    }
                }, [isJsonBody]);
                useEffect(() => {
                  updateVariableSuggestion(values.apiData);
                }, [values.apiData])
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} icon={<img alt='' style={{ width: 25 }} src={apiCallIcon} />} caption='API Call' />
                        <Offcanvas.Body>
                            <Form.Group className='mb-3'>
                                <Form.Label>HTTP Method</Form.Label>
                                <Form.Select
                                    name='apiData.method'
                                    value={values.apiData.method}
                                    onChange={handleChange}
                                >
                                    <option value="GET">Get</option>
                                    <option value="POST">Post</option>
                                    <option value="PATCH">Patch</option>
                                    <option value="PUT">Put</option>
                                    <option value="DELETE">Delete</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label>URL <span className='required'></span></Form.Label>
                                <Form.Control
                                    as='input'
                                    name='apiData.url'
                                    value={values.apiData.url}
                                    isInvalid={(touched.apiData?.url && errors.apiData?.url) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched.apiData?.url && !errors.apiData?.url}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.apiData?.url}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label>Headers  <span className='dimmed'>(Optional)</span></Form.Label>
                                <KeyValueInput
                                    name='headers'
                                    addLabel='Header'
                                    data={values.apiData.headers}
                                    errors={errors}
                                    values={values}
                                    onChange={handleChange}
                                    touched={touched}
                                />
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label>Query Params <span className='dimmed'>(Optional)</span></Form.Label>
                                <KeyValueInput
                                    name='params'
                                    addLabel='Query'
                                    data={values.apiData.params}
                                    errors={errors}
                                    values={values}
                                    onChange={handleChange}
                                    touched={touched}
                                />
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label>Body Content Type</Form.Label>
                                <Form.Select
                                    value={isJsonBody ? "0" : "1"}
                                    onChange={async (event) => {
                                        setFieldValue('apiData.body', event.target.value === "0" ? {} : []);
                                        setIsJsonBody(event.target.value === "0");
                                    }}
                                >
                                    <option value="0">JSON</option>
                                    <option value="1">URL-Encoded form</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className='mb-3'>
                                <Form.Label>Body  <span className='dimmed'>(Optional)</span></Form.Label>
                                {isJsonBody ? (
                                    <JSONInput
                                        value={values.apiData.body}
                                        touched={touched.apiData?.body ? true : false}
                                    />
                                ) : (
                                    <KeyValueInput
                                        name='body'
                                        addLabel='Body'
                                        data={Array.isArray(values.apiData.body) ? values.apiData.body : []}
                                        errors={errors}
                                        values={values}
                                        onChange={handleChange}
                                        touched={touched}
                                    />
                                )}
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label>Response from API</Form.Label>
                                <div className="dimmed mb-2">
                                    Click on the data from the API that you want to store as a variable.
                                </div>
                                {(apiResponse && apiResponseUrl && apiResponseUrl === values.apiData.url) ? (
                                    <ReactJson
                                        src={JSON.parse(apiResponse)}
                                        name='response'
                                        style={{
                                            border: '1px solid #E2E2E2',
                                            borderRadius: '0.375rem',
                                            padding: '0.375rem 0.75rem',
                                            height: '150px',
                                            minHeight: '150px',
                                            resize: 'vertical',
                                            overflowY: 'auto'
                                        }}
                                        onSelect={async (select) => {
                                            const varPath = findJsonPath(JSON.parse(apiResponse), select);
                                            if (varPath !== null) {
                                                const updatedVars: Variable[] = JSON.parse(JSON.stringify(values.variables));
                                                updatedVars.push({ id: uuidv4(), name: '', path: varPath, type: 'TEXT' });
                                                await setFieldValue('variables', updatedVars);
                                                const varNameEl = document.getElementById(`variables[${updatedVars.length - 1}].name`);
                                                if (varNameEl) {
                                                    varNameEl.scrollIntoView({ behavior: "smooth" });
                                                    varNameEl.focus();
                                                }
                                            }
                                        }}
                                        collapsed={2}
                                        enableClipboard={false}
                                    />
                                ) : (
                                    <div className='form-control dimmed'>No data fetched</div>
                                )}
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label>Store response in variables</Form.Label>
                                <VariableInput
                                    data={values.variables}
                                    errors={errors}
                                    values={values}
                                    onChange={handleChange}
                                    variablePathSuggestions={variablePathSuggestions}
                                    touched={touched}
                                />
                            </Form.Group>
                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button type='submit' className='sendButton'>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default ApiCallsEditor;

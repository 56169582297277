import React, { useEffect, useMemo, useState, useRef } from "react";
import { Formik, Form } from "formik";
import Select from "react-select";
import { Button, Row, Col, Popover } from "react-bootstrap";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { IsOptState, SourceMedia } from "../../common/category";
import { Label } from "reactstrap";
import {
  contactFilters,
  companyContactFilters,
} from "../../services/contactMemberService";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { MdOutlineDateRange } from "react-icons/md";
import * as originalMoment from "moment";
import { extendMoment } from "moment-range";
import {
  Overlay
} from "react-bootstrap";
import DateRangePicker from "react-daterange-picker";

const moment = extendMoment(originalMoment);

interface Props {
  tagsList: any;
  toggleQuickPopover: () => void;
  filterContacts: (filterData: any, filter: any, filterPayloads: any) => void;
  filterData: any;
  fetchContacts: () => void;
  isCompanyContacts: boolean;
  getCompanyContacts: (id: string) => void;
  companyCount: any;
  pageSize: any;
  // tagsAddRemove:(id:number,type:string)=>void;
}
// Define the Yup schema
const validationSchema = Yup.object({
  selectFilter: Yup.string().required("Sequence is required"),
  filterData: Yup.object().nullable().required("Select is required"),
});

const DateFilterType = {
  tags: [],
  optState: null,
  // channelId: {},
  channelUids:[],
  source: [],
  dateRangeValue: {
    start: null,
    end: null,
  },
};

const ContactFilter = (Props: Props) => {
  const {
    tagsList,
    toggleQuickPopover,
    filterContacts,
    fetchContacts,
    filterData,
    isCompanyContacts,
    getCompanyContacts,
    companyCount,
    pageSize
  } = Props;
  const businessUid = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const today = moment().clone().startOf("day");

  const [filter, setFilter] = useState<any>(DateFilterType);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [channelOpts, setChannelOpts] = useState([])
  const channelData = useSelector(
    (state: any) => state.cartreducer.channelData
  );
console.log(';channelUids11',filter)

  // const [dateRangeValue, setDateRangeValue] = useState(
  //   moment.range(
  //     today.clone().subtract(7, "days").startOf("day"),
  //     today.endOf("day")
  //   )
  // );

  const { start, end } = filter?.dateRangeValue as any;

  // const startDateFormatted = start != null ? start.format("YYYY-MM-DDTHH:mm:ss[Z]") : null;
  // const endDateFormatted = end != null ? end.utc().format("YYYY-MM-DDT23:59:59[Z]") : null;
  const startDateFormatted = start ? start.toISOString() : null; // Use toISOString() for full ISO 8601 compliance
  const endDateFormatted = end ? end.utc().endOf('day').toISOString() : null; // Set to the end of the day in UTC

  const { companyId } = useParams();

  useMemo(() => {
    if (filterData) {
      setFilter(filterData);
    }
  }, [filterData]);

  useEffect(() => {
    getChannels();
  }, [])

  const handleAddTag = (selectedOption: any) => {
    setFilter((prevState: any) => {
      const tagsAlreadySelected = prevState?.tags?.find(
        (tag: any) => tag.value === selectedOption.value
      );
      if (!tagsAlreadySelected) {
        return {
          ...prevState,
          tags: [...prevState.tags, selectedOption], // Add new tag
        };
      }
      return prevState; // Return the previous state if the tag is already added
    });
    const { id } = selectedOption.tagAll;

    // if(tagsAddRemove){
    //     tagsAddRemove(id,'ADD');
    // }
  };

  const handleRemoveTag = (tagToRemove: any) => {
    setFilter((prevState: any) => ({
      ...prevState,
      tags: prevState.tags.filter(
        (tag: any) => tag.value !== tagToRemove.value
      ), // Remove the tag
    }));
    const { id } = tagToRemove.tagAll;
    // if(tagsAddRemove){
    //     tagsAddRemove(id,'REMOVE');
    // }
  };

  const handleAddSource = (selectedOption: any) => {
    setFilter((prevState: any) => {
      // Check if the tag is already in the array to prevent duplicates
      const sourceAlreadySelected = prevState?.source?.find(
        (source: any) => source.value === selectedOption.value
      );
      if (!sourceAlreadySelected) {
        return {
          ...prevState,
          source: [...prevState.source, selectedOption], // Add new tag
        };
      }
      return prevState; // Return the previous state if the tag is already added
    });
  };

  const handleRemoveSource = (tagToRemove: any) => {
    setFilter((prevState: any) => ({
      ...prevState,
      source: prevState.source.filter(
        (source: any) => source.value !== tagToRemove.value
      ), // Remove the tag
    }));
  };

  // const handleAddChannels = (selectedOption: any) => {
  //   setFilter((prevState: any) => {
  //     const channelAlreadySelected = prevState?.source?.find(
  //       (ch: any) => ch.value === selectedOption.value
  //     );
  //     if (!channelAlreadySelected) {
  //       return {
  //         ...prevState,
  //         channelId: [...prevState.channelId, selectedOption],
  //       };
  //     }
  //     return prevState;
  //   });
  // };

  const handleRemoveChannels = (channelToRemove: any) => {
    setFilter((prevState: any) => ({
      ...prevState,
      channelId: prevState.channelId.filter(
        (channelId: any) => channelId.value !== channelToRemove
      ),
    }));
  };

  const { source, tags, optState, channelId } = filter;

  const initialValues = {
    tags: filter.tags ?? [],
    optState: filter.optState ?? "",
    source: filter.source ?? "",
    startDate: filter.dateRangeValue.start ?? "",
    endDate: filter.dateRangeValue.end ?? "",
    // channelId: filter.channelId ?? ""
    channelUids: filter.channelUids,
  };

  const resetData = async (setFieldValue: any) => {
    toggleQuickPopover();
    filterContacts([], null, null);
    setFilter(DateFilterType);
    setFieldValue("optState", "");
    if (isCompanyContacts && companyId) {
      getCompanyContacts(companyId);
    } else {
      fetchContacts();
    }
    //filterContacts(null,null)
  };
  const hasValues = Object.values(initialValues).some((value) => {
    if (typeof value === 'boolean') {
      return true;
    } else if (Array.isArray(value)) {
      return value.length > 0;
    } else if (typeof value === 'object' && value !== null) {
      return Object.keys(value).length > 0;
    } else {
      return !!value;
    }
  });



  const datepickerTarget = useRef<HTMLDivElement | null>(null);
  const datepickertarget = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (datepickerTarget.current && !datepickerTarget.current?.contains(event.target as Node)) {
        setShowDatePicker(false);
      }
    };
    if (showDatePicker) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDatePicker]);

  const currentDate = new Date();

  const formattedEndDate = end?.format("DD/MM/YYYY");

  const startDate = moment(start);
  const formattedStartDate = startDate.format('DD/MM/YYYY');

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isDisabled ? "#FBFBFB" : provided.backgroundColor,
      caretColor: "transparent",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isDisabled ? "#FBFBFB" : provided.backgroundColor,
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: state.isDisabled ? "black" : provided.color,
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      position: "absolute",
      top: "70%",
      left: "8%",
    }),
  };

  const sourceCustom = {
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isDisabled ? "#FBFBFB" : provided.backgroundColor,
      caretColor: "transparent",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isDisabled ? "#FBFBFB" : provided.backgroundColor,
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: state.isDisabled ? "black" : provided.color,
    }),
    menu: (provided: any, state: any) => {
      if (state.placement === "bottom") {
        return {
          ...provided,
          position: "absolute",
          top: "70%",
          left: "5%",
          height: "auto",
          width: "95%",
        };
      }
      if (state.placement === "top") {
        return {
          ...provided,
          position: "absolute",
          zIndex: 1000,
          maxHeight: "250px",
          overflow: "hidden",
          scroll: 'hidden',
          width: "95%",
        };
      }
      return provided;
    },
  };

  const [showScroll, setShowScroll] = useState(false);

  // const handleMouseEnter = () => {
  //   setShowScroll(true);
  // };

  // const handleMouseLeave = () => {
  //   setShowScroll(false);
  // };

  const getChannels = () => {
    let options;
    if (channelData?.length > 0) {
      options = channelData.map((channelId: any) => ({
        label: channelId.name,
        value: channelId.uid,
      }));
    }
    setChannelOpts(options)
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        // validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          toggleQuickPopover();
          if (!!isCompanyContacts) {
            const filterPayload = {
              companyUid: companyId,
              source: filter.source.map((source: any) => source.value) ?? "",
              optState: filter.optState,
              tags: filter.tags.map((tag: any) => tag.tagAll) ?? "",
              //tagname: name ?? '',
              page: 0,
              limit: companyCount
            };
            const response = await companyContactFilters(filterPayload);
            if (response) {
              filterContacts(response, filter, filterPayload);
            }
          } else {
            const channelUids = filter?.channelUids?.map((item:any)=>item.value)
            const filterPayload = {
              uid: businessUid,
              source: filter.source.map((source: any) => source.value) ?? "",
              optState: filter.optState,
              tags: filter.tags.map((tag: any) => tag.tagAll) ?? "",
              startDate: startDateFormatted,
              endDate: endDateFormatted,
              // channelId: filter?.channelId?.uid,
              channelUids : channelUids,
              page: 0,
              limit: pageSize ?? 20
              //tagname: name ?? '',
            };

            const response = await contactFilters(filterPayload);
            if (response) {
              filterContacts(response, filter, filterPayload);
            }
          }
          actions.setSubmitting(false);
        }}
        // enableReinitialize={true}
      >
        {({ isSubmitting, setFieldValue, handleSubmit, values }) => (
          <Form>
            <div style={{ background: "none" }} className="">
              <Row className="d-flex align-items-center">
                <Col className="p-0" md={8}>
                  <Label className="pt-2 ps-4">Filter</Label>
                </Col>
                {filterData && (
                  <Col className="p-0">
                    <Button
                      variant="link"
                      className="p-0"
                      onClick={() => resetData(setFieldValue)}
                    >
                      Reset
                    </Button>
                  </Col>
                )}
              </Row>
            </div>
            <div
              // onMouseEnter={handleMouseEnter}
              // onMouseLeave={handleMouseLeave}

              //     style={{
              //       background: 'none',
              //       overflowY: showScroll ? 'scroll' : 'hidden',
              //       // scrollbarWidth: showScroll ? 'thin' : undefined,
              //       transition: 'overflow 0.5s ease',
              //     }}
              className="filter-form"
            >
              <Row>
                <Col md={1}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-tag"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z" />{" "}
                    <path d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586 7 7L13.586 9l-7-7H2v4.586z" />{" "}
                  </svg>
                </Col>
                <Col>
                  <Label>Tags</Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Select
                    className="w-100 pt-2 pb-3"
                    placeholder={"Select Tag"}
                    name="tags"
                    // menuPlacement="auto"
                    //value={tagsList.find((tags:any) => tags.label === selectedOption )}
                    onChange={(selectedOption: any) => {
                      const { tagAll } = selectedOption;
                      handleAddTag(selectedOption);
                      setFieldValue("tags", selectedOption);
                    }}
                    value={tags?.length > 0 ? values.tags : ""}
                    options={tagsList as any}
                    styles={customStyles}
                  />
                </Col>
              </Row>
              <Row>
                {tags.map((tag: any) => (
                  <Row>
                    <Col className="d-flex justify-content-between align-items-center pb-2">
                      {/* {tag?.label} */}
                      <span style={{ maxWidth: "130px" }}>{tag?.label}</span>
                      <FontAwesomeIcon
                        icon={faXmarkCircle}
                        onClick={() => handleRemoveTag(tag)}
                      />
                    </Col>
                  </Row>
                ))}
              </Row>
              <Row>
                <Col md={1}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 512 512"
                  >
                    <path d="M414.73,97.1A222.14,222.14,0,0,0,256.94,32C134,32,33.92,131.58,33.87,254A220.61,220.61,0,0,0,63.65,365L32,480l118.25-30.87a223.63,223.63,0,0,0,106.6,27h.09c122.93,0,223-99.59,223.06-222A220.18,220.18,0,0,0,414.73,97.1ZM256.94,438.66h-.08a185.75,185.75,0,0,1-94.36-25.72l-6.77-4L85.56,427.26l18.73-68.09-4.41-7A183.46,183.46,0,0,1,71.53,254c0-101.73,83.21-184.5,185.48-184.5A185,185,0,0,1,442.34,254.14C442.3,355.88,359.13,438.66,256.94,438.66ZM358.63,300.47c-5.57-2.78-33-16.2-38.08-18.05s-8.83-2.78-12.54,2.78-14.4,18-17.65,21.75-6.5,4.16-12.07,1.38-23.54-8.63-44.83-27.53c-16.57-14.71-27.75-32.87-31-38.42s-.35-8.56,2.44-11.32c2.51-2.49,5.57-6.48,8.36-9.72s3.72-5.56,5.57-9.26.93-6.94-.46-9.71-12.54-30.08-17.18-41.19c-4.53-10.82-9.12-9.35-12.54-9.52-3.25-.16-7-.2-10.69-.2a20.53,20.53,0,0,0-14.86,6.94c-5.11,5.56-19.51,19-19.51,46.28s20,53.68,22.76,57.38,39.3,59.73,95.21,83.76a323.11,323.11,0,0,0,31.78,11.68c13.35,4.22,25.5,3.63,35.1,2.2,10.71-1.59,33-13.42,37.63-26.38s4.64-24.06,3.25-26.37S364.21,303.24,358.63,300.47Z" />
                  </svg>
                </Col>
                <Col>
                  <Label>Marketing Optin</Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Select
                    className="w-100 pt-2 pb-3"
                    placeholder={"Select Optin"}
                    name="optState"
                    value={optState !== "" ? values.optState : ""}
                    onChange={(selectedOption: any) => {
                      setFilter({ ...filter, optState: selectedOption?.value });
                      setFieldValue("optState", selectedOption);
                    }}
                    options={IsOptState as any}
                    isClearable={true}
                    // menuPlacement="bottom"
                    styles={customStyles}

                  />
                </Col>
              </Row>
              <Row>
                <Col md={1}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-report-search"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    {" "}
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />{" "}
                    <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697" />{" "}
                    <path d="M18 12v-5a2 2 0 0 0 -2 -2h-2" />{" "}
                    <rect x="8" y="3" width="6" height="4" rx="2" />{" "}
                    <path d="M8 11h4" /> <path d="M8 15h3" />{" "}
                    <circle cx="16.5" cy="17.5" r="2.5" />{" "}
                    <path d="M18.5 19.5l2.5 2.5" />{" "}
                  </svg>
                </Col>
                <Col>
                  <Label>Source</Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Select
                    className="w-100 pt-2 pb-3"
                    placeholder={"Select Source"}
                    name="source"
                    value={source?.length > 0 ? values.source : ""}
                    onChange={(selectedOption: any) => {
                      handleAddSource(selectedOption);
                      // setFilter({ ...filter, source: selectedOption?.value });
                      setFieldValue("source", selectedOption);
                    }}
                    options={SourceMedia as any}
                    menuPlacement="auto"
                    styles={sourceCustom}
                    menuPortalTarget={document.body}
                  />
                </Col>
              </Row>
              <Row>
                {source.map((source: any) => (
                  <Row>
                    <Col className="d-flex justify-content-between align-items-center pb-2">
                      {/* {tag?.label} */}
                      <span style={{ maxWidth: "130px" }}>{source?.label}</span>
                      <FontAwesomeIcon
                        icon={faXmarkCircle}
                        onClick={() => handleRemoveSource(source)}
                      />
                    </Col>
                  </Row>
                ))}
              </Row>
              {!isCompanyContacts && (
                <>
                  <Row>
                    <Col md={1}>
                      <MdOutlineDateRange size={20} />
                    </Col>
                    <Col>
                      <Label>Created Date</Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="p-1 ps-2 pe-1">
                      <Overlay
                        target={datepickertarget.current}
                        show={showDatePicker}
                        placement="top"
                        ref={datepickerTarget}
                      >
                        <Popover id="popover-basic" style={{ maxWidth: "350px" }}>
                          <DateRangePicker
                            value={filter.dateRangeValue}
                            maxDate={currentDate}
                            minDate={currentDate}
                            onSelect={(value: any) => {
                              const currentDate = moment();
                              let start = moment(value.start).startOf("day");
                              let end = moment(value.end).endOf("day");

                              if (start.isAfter(currentDate, 'day')) {
                                start = currentDate.clone().endOf('day');
                              }
                              if (end.isAfter(currentDate, 'day')) {
                                end = currentDate.clone().endOf('day');
                              }
                              const newMomentRange = moment.range(start, end);
                              setFilter({
                                ...filter,
                                dateRangeValue: newMomentRange,
                              } as any);
                              // setDateFilter(DateFilterType.CustomRange);
                              setShowDatePicker(false);
                              // campaignFilterQuery.refetch();
                            }}
                            singleDateRange={true}
                          />
                        </Popover>
                      </Overlay>
                      <div
                        ref={datepickertarget}
                        className=""
                        onClick={() => setShowDatePicker((current) => !current)}
                      >
                        {start && end ? (
                          <div className="p-2"
                            style={{
                              border: "2px solid #E2E2E2",
                              borderRadius: "4px",
                              width: '97%',
                            }}
                          >
                            {`${start?.format("DD/MM/YYYY")} - ${end?.format("DD/MM/YYYY")}`}
                          </div>
                        ) : (
                          <div
                            className="p-2"
                            style={{
                              color: "rgb(128 130 132)",
                              border: "2px solid #E2E2E2",
                              borderRadius: "4px",
                              width: '97%'
                            }}>
                            DD/MM/YYYY-DD/MM/YYYY
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={1}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 512 512"
                      >
                        <path d="M414.73,97.1A222.14,222.14,0,0,0,256.94,32C134,32,33.92,131.58,33.87,254A220.61,220.61,0,0,0,63.65,365L32,480l118.25-30.87a223.63,223.63,0,0,0,106.6,27h.09c122.93,0,223-99.59,223.06-222A220.18,220.18,0,0,0,414.73,97.1ZM256.94,438.66h-.08a185.75,185.75,0,0,1-94.36-25.72l-6.77-4L85.56,427.26l18.73-68.09-4.41-7A183.46,183.46,0,0,1,71.53,254c0-101.73,83.21-184.5,185.48-184.5A185,185,0,0,1,442.34,254.14C442.3,355.88,359.13,438.66,256.94,438.66ZM358.63,300.47c-5.57-2.78-33-16.2-38.08-18.05s-8.83-2.78-12.54,2.78-14.4,18-17.65,21.75-6.5,4.16-12.07,1.38-23.54-8.63-44.83-27.53c-16.57-14.71-27.75-32.87-31-38.42s-.35-8.56,2.44-11.32c2.51-2.49,5.57-6.48,8.36-9.72s3.72-5.56,5.57-9.26.93-6.94-.46-9.71-12.54-30.08-17.18-41.19c-4.53-10.82-9.12-9.35-12.54-9.52-3.25-.16-7-.2-10.69-.2a20.53,20.53,0,0,0-14.86,6.94c-5.11,5.56-19.51,19-19.51,46.28s20,53.68,22.76,57.38,39.3,59.73,95.21,83.76a323.11,323.11,0,0,0,31.78,11.68c13.35,4.22,25.5,3.63,35.1,2.2,10.71-1.59,33-13.42,37.63-26.38s4.64-24.06,3.25-26.37S364.21,303.24,358.63,300.47Z" />
                      </svg>
                    </Col>
                    <Col>
                      <Label>Channel</Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Select
                        className="w-100 pt-2 pb-3"
                        placeholder="Select Channel"
                        name="channelId"
                        options={channelOpts && channelOpts.map((channelId: any) =>
                        ({
                          value: channelId.value,
                          label: channelId?.label,
                          uid: channelId?.value,
                        }))}
                        onChange={(channel: any) => {
                          // const channelUid = channel.map((item:any)=>item.value) 
                          console.log('channelUids',filter)
                          setFilter({ ...filter, channelUids: channel });
                          setFieldValue("channelId", channel)
                        }}
                        menuPlacement="auto"
                        styles={sourceCustom}
                        isMulti={true}
                        menuPortalTarget={document.body}
                        value={filter?.channelUids ? filter?.channelUids : ''}
                        isClearable={true}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </div>
            {hasValues && (
              <footer>
                <div className="d-flex pt-2 footer-in-filter">
                  <Button
                    className="cancelButton p-1 me-2"
                    onClick={() => resetData(setFieldValue)}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" className={`sendButton w-50 p-0 ${isSubmitting && ('d-flex justify-content-evenly align-items-center')}`}
                    disabled={isSubmitting}
                  >
                    Apply {isSubmitting && (<div className="loadingVia" style={{ width: '20px', height: '20px' }}></div>)}
                  </Button>
                </div>
              </footer>
            )}
          </Form>
        )}
      </Formik>
      {/* {isLoading ? <p>Loading...</p> : filteredContacts.length === 0 ? <p>No results found.</p> : <ul />} */}
    </div>
  );
};

export default ContactFilter;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import QRCode from "qrcode.react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faPencil,
  faDownload,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import Select from "react-select";
import { useSelector } from "react-redux";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { saveAs } from "file-saver";
import styles from "./DashboardV2.module.scss";
import Modal from "../../common/modall";
import EditWhatsappLink from "./qrCode";
import Loader from "../../shade/Loaders/smallLoader";
import * as dashboardServices from "../../services/dashboardService";
import * as campaignServices from "../../services/campaignService";
import {
  formatCurrency,
  subscriptionPlanEnd,
} from "../Subscription/SubscriptionPage";
import { useNavigate } from "react-router-dom";
import wallet from "../../assets/img/Dashboard_wallet.svg";
import { planCodes } from "../../common/commonfns";
import TooltipReference from "../../common/tooltip";
import { BsInfoCircleFill } from "react-icons/bs";
import { FaCircleInfo } from "react-icons/fa6";
import {
  Breadcrumb,
  Collapse,
  OverlayTrigger,
  Tooltip,
  Row, Col 
} from "react-bootstrap";

const DashboardV2: React.FC<{}> = () => {
  const businessId = useSelector(
    (state: any) => state.cartreducer.business?.business?.id
  );
  const businessUid = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );
  const channels = useSelector((state: any) => state.cartreducer.channelData);
  const activeChannel = useSelector(
    (state: any) => state.cartreducer.channelUid
  );
  const userProfile = useSelector((state: any) => state.cartreducer.business);
  const isFreeTrail = useSelector(
    (state: any) => state.cartreducer.business?.business?.freeTrial
  )
    ? true
    : false;
  const currentPlan = useSelector(
    (state: any) => state.cartreducer.business?.business?.plan
  );
  const navigate = useNavigate();
  const [copyReferralCode, setCopyReferralCode] = useState(null);
  const business = useSelector((state: any) => state.cartreducer.business);
  const planExpire: any =
    business?.business?.plan.code === planCodes("proMonthly") ||
      business?.business?.plan.code === planCodes("proYearly") ||
      business?.business?.plan.code === planCodes("ultimateMonthly") ||
      business?.business?.plan.code === planCodes("ultimateYearly") ||
      business?.business?.plan.code === planCodes("proQuarterly") ||
      business?.business?.plan.code === planCodes("ultimateQuarterly") 
      ? true
      : false;
  const referralCode = business?.business?.referral?.code;


  const totalCreditsQuery = useQuery({
    queryKey: ["total-credits", businessId],
    queryFn: () => campaignServices.getTotalCredits(businessId),
  });
  const [selectedChannel, setSelectedChannel] = useState<null | {
    label: string;
    value: string;
    mobileNumber: string;
  }>(null);
  useEffect(() => {
    if (activeChannel) {
      setSelectedChannel({
        label: activeChannel.label,
        value: activeChannel.channelAllData?.uid,
        mobileNumber: activeChannel.channelAllData?.mobileNumber,
      });
    }
  }, [activeChannel]);
  const [copyLinkTooltip, setCopyLinkTooltip] = useState("Copy Link");
  const [editLinkOpen, setEditLinkOpen] = useState(false);

  // Fetch QR data for edit link
  const [qrData, setQrData] = useState<any>([]);
  const [qrDataLoading, setQrDataLoading] = useState(true);
  const fetchQRDataHandler = async () => {
    if (activeChannel.value) {
      try {
        const data = await dashboardServices.qrgenerator(activeChannel.value);
        setQrData(data);
        setQrDataLoading(false);
      } catch (error) {
        setQrDataLoading(false);
      }
    }
  };
  useEffect(() => {
    fetchQRDataHandler();
  }, [activeChannel]);

  useEffect(() => {
    if (businessUid) localStorage.setItem("businessId", businessUid);
  }, [businessUid]);

  // useEffect(() => {
  //   if (isFreeTrail)
  //     navigate(`${process.env.PUBLIC_URL}/welcome`);
  //   window.location.reload();
  // }, [isFreeTrail])
  // Animation
  const [playAnimation, setPlayAnimation] = useState(false);
  useEffect(() => {
    if (
      !(
        !userProfile ||
        !currentPlan ||
        totalCreditsQuery.isLoading ||
        !activeChannel
      )
    ) {
      setPlayAnimation(true);
    }
  }, [userProfile, currentPlan, totalCreditsQuery, activeChannel]);

  if (
    !userProfile ||
    !currentPlan ||
    totalCreditsQuery.isLoading ||
    !activeChannel
  ) {
    return <Loader />;
  }

  const copyWhatsappLinkHandler = async () => {
    if (selectedChannel) {
      try {
        await navigator.clipboard.writeText(`${qrData[0]?.link}`);
        setCopyLinkTooltip("✔ Copied");
      } catch (error) {
        setCopyLinkTooltip("✗ Fail to Copy");
      }
    }
  };

  const handleCopy = (referralCode: any) => {
    navigator.clipboard
      .writeText(referralCode)
      .then(() => {
        setCopyReferralCode(referralCode);
      })
      .catch((error) => {
        console.error("Failed to copy UID:", error);
      });
  };

  const getDateDifference = (dateString: string): number => {
    const givenDate = new Date(dateString);
    const today = new Date();
    const differenceInMs = givenDate.getTime() - today.getTime();
    const differenceInDays = Math.floor(differenceInMs / (1000 * 3600 * 24));
    return differenceInDays;
  };

  const downloadQRCodeHandler = () => {
    const canvas = document.getElementById("qrCode") as HTMLCanvasElement;
    const dataURL = canvas.toDataURL("image/png");
    saveAs(dataURL, "qrcode.png");
  };

  return (
    <div className={styles.dashboard}>
      <Modal
        isOpen={editLinkOpen}
        onClose={() => setEditLinkOpen(false)}
        title="Generate a personalized WhatsApp link for your business to direct chats to BizMagnets"
        size="md"
      >
        <EditWhatsappLink
          values={qrData}
          channels={activeChannel}
          isopen={setEditLinkOpen}
          fetchQRDataHandler={fetchQRDataHandler}
        />
      </Modal>
      <div className={styles.main_section}>
        <div className={styles.jumbotron}>
          <div>
            <span className={styles.title}>Hello, {userProfile.name}</span>
            <span className={styles.subtitle}>Welcome to BizMagnets</span>
            <span className={styles.subscription_status}>
              {isFreeTrail ? (
                <>
                  BizMagnets Trial Plan expires in{" "}
                  <b>
                    {moment(currentPlan.expireAt).diff(moment(), "days")} days
                  </b>
                  <Link
                    className="btn btn-link"
                    to={`${process.env.PUBLIC_URL}/subscription`}
                  >
                    Subscribe now
                  </Link>
                </>
              ) : (
                <>
                  {
                    moment(currentPlan.expireAt).diff(moment(), "days") < 0 ? (
                      <>
                        BizMagnets {currentPlan.name} expired on {moment(currentPlan.expireAt).format("DD MMM, YYYY")}
                      </>
                    ) :
                      (
                        <>
                          BizMagnets {currentPlan.name} expires on {moment(currentPlan.expireAt).format("DD MMM, YYYY")}
                        </>
                      )
                  }
                  <Link
                    className={styles.upgradeBtn}
                    to={`${process.env.PUBLIC_URL}/subscription`}
                  >
                    {moment(currentPlan.expireAt).diff(moment(), "days") < 0 ? 'Pay Now' : 'Renew Plan'}
                  </Link>
                  {/* {currentPlan?.code !== planCodes('free') && getDateDifference(currentPlan.expireAt) <= 7 ? 
                  <div className={styles.subscription_status_info}>
                    <div>
                      <FontAwesomeIcon icon={faClock} style={{ color: '#c29f45', marginLeft: '3px' }} />
                    </div>
                    <div className={styles.plan_notification}>Once your current plan expires, you will be switched to our Standard Free plan</div>
                  </div> : ''
                } */}
                </>
              )}
            </span>
          </div>
          <img
            alt=""
            src={`${process.env.PUBLIC_URL}/images/dashboard-v2/welcome.svg`}
          />
        </div>
        <div className={styles.features}>
          <h6 className="headerone">Explore More Exciting Features</h6>
          <div className="borderLine"></div>
          <Row className="mt-3">
            <Col sm={12} md={5} lg={5}>
              <Link
                style={{ animationDelay: `.25s` }}
                className={`${styles.feature_item}${playAnimation ? " " + styles.animation : ""
                  }`}
                to={`${process.env.PUBLIC_URL}/settings/memberTemplate/`}
              >
                <img
                  alt=""
                  src={`${process.env.PUBLIC_URL}/images/dashboard-v2/add_member.svg`}
                />
                <div className={styles.label}>
                  <span className={styles.caption}>Add Members</span>
                  <span className={styles.subcaption}>
                    Enlist team members to oversee and manage your forum during
                    your absence.
                  </span>
                </div>
              </Link>
            </Col>
            <Col sm={12} md={5} lg={5}>
              <Link
                style={{ animationDelay: `.5s` }}
                className={`${styles.feature_item}${playAnimation ? " " + styles.animation : ""
                  }`}
                to={`${process.env.PUBLIC_URL}/contacts/`}
              >
                <img
                  alt=""
                  src={`${process.env.PUBLIC_URL}/images/dashboard-v2/whatsapp_contact.svg`}
                />
                <div className={styles.label}>
                  <span className={styles.caption}>Add WhatsApp Contact</span>
                  <span className={styles.subcaption}>
                    Add WhatsApp contacts to start your conversation and improve
                    your business.
                  </span>
                </div>
              </Link>
            </Col>
            <Col sm={12} md={5} lg={5}>
              <Link
                style={{ animationDelay: `.75s` }}
                className={`${styles.feature_item}${playAnimation ? " " + styles.animation : ""
                  }`}
                to={`${process.env.PUBLIC_URL}/campaign/`}
              >
                <img
                  alt=""
                  src={`${process.env.PUBLIC_URL}/images/dashboard-v2/campaign.svg`}
                />
                <div className={styles.label}>
                  <span className={styles.caption}>
                    Create and Schedule Campaigns
                  </span>
                  <span className={styles.subcaption}>
                    Enhance your business communication and initiate
                    conversations by adding WhatsApp contacts.
                  </span>
                </div>
              </Link>
            </Col>
            <Col sm={12} md={5} lg={5}>
              <Link
                style={{ animationDelay: `1s` }}
                className={`${styles.feature_item}${playAnimation ? " " + styles.animation : ""
                  }`}
                to={`${process.env.PUBLIC_URL}/whatsappTemplate/`}
              >
                <img
                  alt=""
                  src={`${process.env.PUBLIC_URL}/images/dashboard-v2/template.svg`}
                />
                <div className={styles.label}>
                  <span className={styles.caption}>Add WhatsApp Template</span>
                  <span className={styles.subcaption}>
                    Streamline your business campaigns with WhatsApp templates
                    for efficient automation.
                  </span>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
        <div className={styles.features} style={{ marginTop: 20 }}>
          <h6 className="headerone">Additional Features</h6>
          <div className="borderLine"></div>
          <Row className="mt-3">
            <Col sm={12} md={5} lg={5}>
              <a style={{ animationDelay: `.25s` }} className={`${styles.feature_item}${playAnimation ? " " + styles.animation : ""}`} href="https://bizmagnets.ai/generate-whatsapp-link-effortlessly-and-drive-more-sales/"
                target="_blank">
                <img alt="" src={`${process.env.PUBLIC_URL}/images/dashboard-v2/whatsapp_chatlink.svg`} />
                <div className={styles.label}>
                  <span className={styles.caption}>
                    WhatsApp Chat Link Generator
                  </span>
                  <span className={styles.subcaption}>
                    Create a WhatsApp magic link to effortlessly kickstart
                    engaging conversations with your customers.
                  </span>
                </div>
              </a>
            </Col>
            <Col sm={12} md={5} lg={5}>
              <a style={{ animationDelay: `.5s` }} className={`${styles.feature_item}${playAnimation ? " " + styles.animation : ""}`} href="https://bizmagnets.ai/whatsapp-qr-code-generator/"
                target="_blank">
                <img alt="" src={`${process.env.PUBLIC_URL}/images/dashboard-v2/whatsapp_qr.svg`} />
                <div className={styles.label}>
                  <span className={styles.caption}>
                    WhatsApp QR Code Generator
                  </span>
                  <span className={styles.subcaption}>
                    Create a QR code with your WhatsApp number, making it simple
                    to share and expand your connections.
                  </span>
                </div>
              </a>
            </Col>
            <Col sm={12} md={5} lg={5}>
              <a style={{ animationDelay: `.75s` }} className={`${styles.feature_item}${playAnimation ? " " + styles.animation : ""}`} target="_blank" href="https://bizmagnets.ai/whatsapp-chat-button/">
                <img alt="" src={`${process.env.PUBLIC_URL}/images/dashboard-v2/whatsapp_livechat.svg`} />
                <div className={styles.label}>
                  <span className={styles.caption}>
                    WhatsApp Live Chat Generator
                  </span>
                  <span className={styles.subcaption}>
                    Experience real-time conversations by setting up a live chat
                    through your preferred WhatsApp number.
                  </span>
                </div>
              </a>
            </Col>
          </Row>
        </div>
      </div>
      {activeChannel?.value && (
        <div className={styles.side_section}>
          {planExpire && (
            <div className={styles.referal_poster}>
              <div className={styles.poster_content}>
                <h6 className="fw-bold">Earn
                  <span className="rupeeFont"> ₹1500 </span>
                  <span>FREE Credits Instantly!</span></h6>
              </div>
              <div className={styles.referral_link}>
                <div className="fw-bold">Join the referral revolution!</div>
                <small>
                  When your friends subscribe using your invite code, you can
                  earn up to
                  <span className="rupeeFont"> ₹1500</span>
                  <span> in FREE credits based on their plan. Don't
                    miss out—start sharing and watch your credits soar!</span>
                </small>
              </div>
              <div className={styles.referral}>
                <div>
                  <div className={styles.your_referral}>YOUR REFERRAL CODE</div>
                  <TooltipReference
                    placement="bottom"
                    content={
                      copyReferralCode === referralCode
                        ? "Copied"
                        : `Copy referral code`
                    }
                    tooltipId={`copy-to-clipboard_${referralCode}`}
                  >
                    <div
                      className={styles.referralCode}
                      onClick={() => handleCopy(referralCode)}
                    >
                      {referralCode}
                    </div>
                  </TooltipReference>
                </div>
              </div>
            </div>
          )}

          <div className={styles.credit_img}>
            <div className={styles.center}>
              <img alt="" src={wallet} className={styles.creditImg} />
            </div>

            <div className={styles.credit_balance}>
              <div className={styles.available_balance}>
                <span className={styles.amount}>
                  {formatCurrency(
                    (totalCreditsQuery?.data as any)?.totalCredits?.toFixed(2),
                    "INR"
                  )}
                </span>
                <span className={styles.label}>Available Balance</span>
              </div>
              <Link
                className={styles.cta}
                to={`${process.env.PUBLIC_URL}/credit`}
              >
                Add Credits
              </Link>
            </div>
          </div>
          <div className={styles.whatsapp_channel}>
            <div className={styles.row}>
              {selectedChannel ? (
                <div className={styles.qrCode}>
                  <QRCode size={70} value={`${qrData[0]?.link}`} id="qrCode" />
                </div>
              ) : null}
              <div>
                <span
                  className={styles.caption}
                  style={{ whiteSpace: "nowrap" }}
                >
                  Get the Quick Link for Your <br />
                  <span className="whatsapp-green">WhatsApp</span> Channel
                  <span className={styles.whatsappLinkInfo}>
                  <TooltipReference
                      icon={faCircleInfo}
                                  placement="auto"
                                  content={`Generate a personalized WhatsApp link for your business to direct conversations towards BizMagnets.`}
                                  tooltipId="whatsappLink"
                                ></TooltipReference>
                  </span>
                </span>
                {/* <Select
                  value={selectedChannel}
                  onChange={setSelectedChannel}
                  options={channels.map((channel: any) => {
                    return {
                      label: channel.displayName,
                      value: channel.uid,
                      mobileNumber: channel.mobileNumber
                    };
                  })}
                  className={styles.selectChannel}
                  classNames={{
                    control: () => styles.control
                  }}
                  formatOptionLabel={({ label }: any) => (
                    <>
                      <img alt="" src={`${process.env.PUBLIC_URL}/images/whatsapp-icon.svg`} width={20} />
                      <span className="ml-2">{label}</span>
                    </>
                  )}
                /> */}
                <div className="border rounded p-1 mt-2">
                  <img
                    alt=""
                    src={`${process.env.PUBLIC_URL}/images/whatsapp-icon.svg`}
                    width={20}
                  />
                  <span className="ml-2">{activeChannel.label}</span>
                </div>
              </div>
            </div>
            {selectedChannel ? (
              <div className={styles.linkEditor}>
                <div className={styles.options}>
                  <OverlayTrigger
                    overlay={<Tooltip>Edit Link</Tooltip>}
                    placement="bottom"
                  >
                    <button onClick={() => setEditLinkOpen(true)}>
                      <FontAwesomeIcon icon={faPencil} />
                    </button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    overlay={<Tooltip>{copyLinkTooltip}</Tooltip>}
                    placement="bottom"
                    onToggle={(isOpen) => {
                      if (!isOpen) {
                        setTimeout(() => {
                          setCopyLinkTooltip("Copy Link");
                        }, 1000);
                      }
                    }}
                  >
                    <button onClick={copyWhatsappLinkHandler}>
                      <FontAwesomeIcon icon={faCopy} />
                    </button>
                  </OverlayTrigger>
                  {/* <OverlayTrigger
                    overlay={<Tooltip>Edit Link</Tooltip>}
                    placement="bottom"
                  >
                    <button onClick={() => setEditLinkOpen(true)}>
                      <FontAwesomeIcon icon={faPencil} />
                    </button>
                  </OverlayTrigger> */}
                  <OverlayTrigger
                    overlay={<Tooltip>Download QR Code</Tooltip>}
                    placement="bottom"
                  >
                    <button onClick={downloadQRCodeHandler}>
                      <FontAwesomeIcon icon={faDownload} />
                    </button>
                  </OverlayTrigger>
                </div>
                <a
                  target="_blank"
                  href={`${qrData[0]?.link}`}
                  className="text-wrap"
                >
                  {qrData[0]?.link}
                </a>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardV2;

import React from "react";
import { Modal, ModalBody } from 'reactstrap';
import './modall.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: any;
  size?: string;
  // height?: string;
  component?: string;
  style?: any;
  closeOnOutsideClick?: boolean;
}

const Modall: React.FC<ModalProps> = ({ isOpen, onClose, title, children, size, component, style, closeOnOutsideClick = true }) => {

  const handleBackdropClick = (e: any) => {
    if (!closeOnOutsideClick) {
      e.stopPropagation();
    } else {
      onClose();
    }
  };

  const handleCloseModal = () => {
    onClose();
  };

  return (
    <div>
      <Modal size={size === 'modal-xxl' || size === 'modal-xl' ? '' : size} isOpen={isOpen} toggle={onClose} 
        backdrop={closeOnOutsideClick ? true : 'static'}
        className={`${size === 'modal-xxl' ? 'modal-xxl' : size === 'modal-xl' ? 'modal-xl' : ''}`} 
      >
        <div>
          {component !== 'conversation' && component !== 'initiateConversation' &&
            < div className="modal-header">
              <h6 className="title">{title}</h6>
              <div className="close-btn cursor-pointer" onClick={handleCloseModal}><FontAwesomeIcon icon={faTimes} aria-hidden="true" /></div>
            </div>}
          <ModalBody className={`${component !== 'ADDTAG_CONTACT' && component !== 'conversation' ? 'modalBody' : ''} ${component === 'ADDTAG_CONTACT' &&('pt-0')}`}>{children}</ModalBody>
        </div>
      </Modal >
    </div >
  );
};

export default Modall;

import React, { useEffect, useState,useCallback } from "react";

import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
//import StripeIndex from './StripeIndex';
import "./stripe.scss";
import {
  BootstrapTable,
  TableHeaderColumn,
} from "react-bootstrap-table";
import {
  paymentGetAll,
  getTotalCredits,
  createPaymentStatus,
  retrievePaymentIntent,
  cancelTransactionPending,
} from "../../services/PaymentGatwayService";
import moment from "moment";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { sweetalert, toast } from "../../common/alert";
import PendingTransation from "../Subscription/PendingTransation";
import withReactContent from "sweetalert2-react-content";
import { planCodes } from "../../common/commonfns";
import { Col, Row, Spinner } from "reactstrap";
import TooltipReference from "../../common/tooltip";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import Modall from "../../common/modall";
import BizTable  from "../../common/BizTable";


type Props = {};

export function formatCurrency(amount: number, currency = "INR") {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  }).format(amount);
}

const CreditIndex = ({ }: Props) => {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const [amount, setAmount] = useState<any>();
  const [minMax, setMinMax] = useState("");
  const [copyReferralCode, setCopyReferralCode] = useState(null);
  const [isNewPayment,setIsNewPayment] = useState(false);
  const [result, setResult] = useState('');
  const [GST, setGST] = useState('');
  const [paymentDetails, getPaymentDetails] = useState([]);
  const [isLoading,setLoading]= useState(false);
  const [count,setCount] = useState(0);

  const business = useSelector((state: any) => state.cartreducer.business);
  const planExpire: any =
    business?.business?.plan.code === planCodes("proMonthly") ||
      business?.business?.plan.code === planCodes("proQuarterly") ||
      business?.business?.plan.code === planCodes("proYearly") ||
      business?.business?.plan.code === planCodes("ultimateMonthly") ||
      business?.business?.plan.code === planCodes("ultimateQuarterly") ||
      business?.business?.plan.code === planCodes("ultimateYearly")
      ? true
      : false;
   
  const referralCode = business?.business?.referral?.code;
  // const [isLoading, setLoading] = useState(false);

    

 
const dateFormatter =(cell:any, row:any)=>{
return moment(cell).format("DD MMM YYYY")
}

const amountFormat = (cell:any,row:any)=>{
  return <p className="fontchange">{formatCurrency(cell, "INR")}</p>
}

const statusData = (status:any,row:any)=>{
  switch (status) {
    case "PENDING":
      return (
        <>
          <div className="">
            <div className="badgeCon pending">Pending</div>
          </div>
        </>
      );
    case "PAID":
      return (
        <div className="badgeCon completed">
          <div className="success">Paid</div>
        </div>
      );
    default:
      return (
        <div className="badgeCon">
          <div >{status ==='DELETED' ? 'Deleted' : status }</div>
        </div>
      );
  }
}

const pendingData = (status:any,row:any)=>{
  switch (row?.status) {
    case "PENDING":
      return (
        <Row>
          <Button
                        className="upload-select w-75 p-2 col me-2"
                        onClick={() => paymentNow(row)}
                      >
                        Pay Now
                      </Button>
                      <Button
                        className="cancelButton w-75 col"
                        onClick={() => cancelTransaction(row)}
                      >
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 1024 1024"
                          fill-rule="evenodd"
                          focusable="false"
                          aria-hidden="true"
                          className="me-2"
                          height="20"
                          width="20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M512 64c247.4 0 448 200.6 448 448S759.4 960 512 960 64 759.4 64 512 264.6 64 512 64Zm0 76c-205.4 0-372 166.6-372 372s166.6 372 372 372 372-166.6 372-372-166.6-372-372-372Zm128.013 198.826c.023.007.042.018.083.059l45.02 45.019c.04.04.05.06.058.083a.118.118 0 0 1 0 .07c-.007.022-.018.041-.059.082L557.254 512l127.861 127.862a.268.268 0 0 1 .05.06l.009.023a.118.118 0 0 1 0 .07c-.007.022-.018.041-.059.082l-45.019 45.02c-.04.04-.06.05-.083.058a.118.118 0 0 1-.07 0c-.022-.007-.041-.018-.082-.059L512 557.254 384.14 685.115c-.042.041-.06.052-.084.059a.118.118 0 0 1-.07 0c-.022-.007-.041-.018-.082-.059l-45.02-45.019c-.04-.04-.05-.06-.058-.083a.118.118 0 0 1 0-.07c.007-.022.018-.041.059-.082L466.745 512l-127.86-127.86a.268.268 0 0 1-.05-.061l-.009-.023a.118.118 0 0 1 0-.07c.007-.022.018-.041.059-.082l45.019-45.02c.04-.04.06-.05.083-.058a.118.118 0 0 1 .07 0c.022.007.041.018.082.059L512 466.745l127.862-127.86c.04-.041.06-.052.083-.059a.118.118 0 0 1 .07 0Z"></path>
                        </svg>
                        Cancel
                      </Button>
        </Row>
      );
      default:
        return (<></>)
    }}

  const  columns = [
    {
      Header: (
        <input
          type="checkbox"
          hidden={true}
        />
      ),
      accessor: "checkbox",
      sticky: "left",
    },
      {
        Header: "Description",
        accessor: "description",
        sticky: "left",
        Cell:'Message Credits Recharge'
      },
      {
        Header: "Created at",
        accessor: "createdAt",
        Cell: ({ cell, row }: any) => dateFormatter(cell.value, row.original),
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ cell, row }: any) => amountFormat(cell.value, row.original),
        grid: "third-column",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell, row }: any) => statusData(cell.value, row.original),
      },
      {
        Header: "",
        accessor: "pending",
        Cell: ({ cell, row }: any) => pendingData(cell.value, row.original),
      },
      {
        Header: "Actions",
        accessor: "edit",
        Cell: ({ cell, row }: any) => downloadInvoice(cell.value, row.original),
      },

    ] as any;

    
  const showPaymentStatus = async () => {
    // setLoading(true)
    // if (amount < 1) {
    //   setMinMax('Min amount is ₹100')
    // }
    // else if (amount > 100000) {
    //   setMinMax('Max amount is ₹1,00,000')
    // }

    if (isPending.length > 0) {
      const amount = isPending[0]?.amount;
      const date = isPending[0]?.createdAt;
      const formattedDate = moment(date).format("DD MMM YYYY");
      setMinMax("");
      MySwal.fire({
        title: (
          <PendingTransation
            amount={amount}
            createdAt={formattedDate}
            onPay={() => {
              MySwal.close();
              const paymentType = "OLD_PAYMENT";
              newPayment(paymentType);
              // setLoading(false);
            }}
          />
        ),
        showConfirmButton: false,
        showCloseButton: true,
      });
      // PayNowAlert(
      //   `You have one Pending Transaction for ₹${amount}.00 Created on ${formattedDate}`,
      //   () => {
      //     setLoading(false);
      //   },
      //   ()=>{ setLoading(false)}
      //    );
    } else {
      setIsNewPayment(true);
      const gstValue = parseFloat(amount) * 9 / 100;
      const calculation = gstValue * 2 + parseFloat(amount);
      setGST(formatResult(gstValue));
      setResult(formatResult(calculation));
      // const paymentType = "NEW_PAYMENT";
      // newPayment(paymentType);
    }
  };
  const formatResult = (value:any) => {
    const fixed = value.toFixed(2);
    return parseFloat(fixed).toString();
  };

  const newPayment = async (paymentType: string) => {
    setMinMax("");
    setLoading(true);
    const createPayload = {
      businessUid: businessUid,
      memberUid: memberUid,
      amount: amount,
      currency: "INR",
    };
    if (paymentType === "OLD_PAYMENT") {
      if (businessId) {
        if (isPending.length > 0) {
          setPending([]);
          setAmount(amount);
          const Id = isPending[0]?.id;
          const trans = await cancelTransactionPending(Id);
          if (trans) {
            const response = await createPaymentStatus(createPayload);
            if (response) {
              window.open(response.url, "_blank");
              // getAllPayment();
              // fetchData(0,20,'',0)
              // setTimeout(() => {
                window.location.reload();
            //     console.log('wn',window.location)
            // }, 500); 
            }
          }
        }
      }
    }
    if (paymentType === "NEW_PAYMENT") {
      const response = await createPaymentStatus(createPayload);
      if (response) {
        setLoading(false);
        ModalClose();
        window.open(response.url, "_blank");
        // getAllPayment();
        // fetchData(0,20,'',0)
        // setTimeout(() => {
          window.location.reload();
      // }, 500); 
      }
    }
  };

  const SidebarClose = () => {
    setAmount("");
    // setLoading(false)
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    const amt = parseInt(value);
    // if (amt < 1) {
    //   setMinMax('Min amount is ₹1')
    // }
    // else if (amt > 100000) {
    //   setMinMax('Max amount is ₹1,00,000')
    // }
    // else {
    //   setMinMax('')
    // }
    if (value === "0" || value === "0.") {
      setAmount("");
    } else {
      setAmount(value);
    }
  };

  const [totalCredit, setTotalCredits] = useState({
    totalCredits:0
  });
  const [isPending, setPending] = useState([] as any);

  const data = useSelector((state: any) => state.cartreducer);

  const businessId = business?.business?.id;
  const memberUid = business?.uid;
  const businessUid = business?.business?.uid;

  // const getAllPayment = async () => {
  //   const paymentPayload = {
  //     businessId: businessId,
  //     limit:100,
  //     page:0
  //   };
  //   if (businessId) {
  //     const getAll = await paymentGetAll(paymentPayload);
  //     const {paymentHistory} = getAll
  //     const isAnyPaymentNotPending = paymentHistory?.find(
  //       (payment: any) => payment.status === "PENDING"
  //     );
  //     isAnyPaymentNotPending
  //       ? setPending([isAnyPaymentNotPending])
  //       : setPending([]);

  //     getPaymentDetails(paymentHistory);
  //     const getTotalCredit = await getTotalCredits(businessId);
  //     setTotalCredits(getTotalCredit);
  //   }
  // };

  const fetchData = useCallback(async (
    pageIndex: number,
    pageSize: number,
    searchText: string,
    currentPage: number
  ): Promise<{ data: any; totalPages: number }> => {
      const paymentPayload = {
        businessId: businessId,
        limit:pageSize,
        page:currentPage
      };
      const getTotalCredit = await getTotalCredits(businessId);
        const getAll = await paymentGetAll(paymentPayload);
        
        const {paymentHistory,count} = getAll
        const isAnyPaymentNotPending = paymentHistory?.find(
          (payment: any) => payment.status === "PENDING"
        );
        setCount(count)
        isAnyPaymentNotPending
          ? setPending([isAnyPaymentNotPending])
          : setPending([]);
  
        if(getTotalCredit){
          setTotalCredits(getTotalCredit);
        }

    if (getAll && getAll.paymentHistory) {
      getPaymentDetails(paymentHistory);
      return {data:paymentHistory,totalPages:count}
    }
    else{
      getPaymentDetails([]);
      setCount(0)
      return {data:[],totalPages:0}
    }
  }, [businessId]);
  // useEffect(() => {
  //   getAllPayment();
  //   //retrievePaymentIntentData()
  // }, [businessId]);

  
  // const retrievePaymentIntentData = async () => {
  //   const paymentIntent = new URLSearchParams(window.location.search).get(
  //     "payment_intent"
  //   );
  //   const status = new URLSearchParams(window.location.search).get(
  //     "redirect_status"
  //   );
  //   if (!paymentIntent && !status) {
  //     return;
  //   }
  //   const data = {
  //     payment_intent: paymentIntent
  //   };

  //   if (data) {
  //     const response = await retrievePaymentIntent(data)
  //     if (response) {
  //       const amount = response.amount
  //       Swal.fire({
  //         icon: 'success',
  //         title: `₹ ${amount}`,
  //         text: `Credits added successfully`
  //       })
  //       navigate('/credit', { replace: true })
  //     }
  //   }
  // }


  const paymentNow = async (row: any) => {
    const { paymentId, amount } = row;
    setAmount(amount);
    if (paymentId) {
      const data = {
        paymentLinkId: paymentId,
      };
      const response = await retrievePaymentIntent(data);
      if (response) {
        window.open(response.url, "_blank");
      }
    }
  };

  // const paymentData =(cell:any,row:any) =>{
  //   if(  row.status === 'PENDING') {
  //     return(
  //       <div className='upload-select  w-50 p-0 m-auto'>
  //         <Button className='upload-select' onClick={()=>{paymentNow(row)}}>Pay Now</Button>

  //       </div>
  //     )
  //   }
  //   else{
  //     return ''
  //   }
  // }

  const cancelTransaction = async (row: any) => {
    const { id } = row;
    sweetalert(
      "warning",
      "Are you sure you want to delete this transaction?",
      async () => {
        if (id) {
          const response = await cancelTransactionPending(id);
          toast("success", "Transaction deleted successfully");
          if (response) {
            // getAllPayment();
            fetchData(0,20,'',0)
          }
        }
      },
      () => { }
    );
  };

  // const actionData =(cell:any,row:any) =>{
  //   if(  row.status === 'PENDING') {
  //     return(
  //       <>
  //       {/* <FontAwesomeIcon icon={faTrashCan} /> */}
  //       <Button className='cancelButton w-100' onClick={()=>cancelTransaction(cell,row)}>
  //       <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" fill-rule="evenodd" focusable="false" aria-hidden="true" className="me-2" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M512 64c247.4 0 448 200.6 448 448S759.4 960 512 960 64 759.4 64 512 264.6 64 512 64Zm0 76c-205.4 0-372 166.6-372 372s166.6 372 372 372 372-166.6 372-372-166.6-372-372-372Zm128.013 198.826c.023.007.042.018.083.059l45.02 45.019c.04.04.05.06.058.083a.118.118 0 0 1 0 .07c-.007.022-.018.041-.059.082L557.254 512l127.861 127.862a.268.268 0 0 1 .05.06l.009.023a.118.118 0 0 1 0 .07c-.007.022-.018.041-.059.082l-45.019 45.02c-.04.04-.06.05-.083.058a.118.118 0 0 1-.07 0c-.022-.007-.041-.018-.082-.059L512 557.254 384.14 685.115c-.042.041-.06.052-.084.059a.118.118 0 0 1-.07 0c-.022-.007-.041-.018-.082-.059l-45.02-45.019c-.04-.04-.05-.06-.058-.083a.118.118 0 0 1 0-.07c.007-.022.018-.041.059-.082L466.745 512l-127.86-127.86a.268.268 0 0 1-.05-.061l-.009-.023a.118.118 0 0 1 0-.07c.007-.022.018-.041.059-.082l45.019-45.02c.04-.04.06-.05.083-.058a.118.118 0 0 1 .07 0c.022.007.041.018.082.059L512 466.745l127.862-127.86c.04-.041.06-.052.083-.059a.118.118 0 0 1 .07 0Z"></path></svg>
  //         Cancel Transaction
  //         </Button>
  //       </>
  //     )
  //   }
  //   else{
  //     return ''
  //   }
  // }
  const ModalClose=()=>{
    setIsNewPayment(false);
  }
  const handleCopy = (referralCode: any) => {
    navigator.clipboard
      .writeText(referralCode)
      .then(() => {
        setCopyReferralCode(referralCode);
      })
      .catch((error) => {
        console.error("Failed to copy UID:", error);
      });
  };

  const downloadInvoice = (cell: any, row: any) => {
    const isPaymentPending = row.status === "PENDING" ? true : false;

    return (
      <div className="ml-4">
        {row?.metadata?.invoiceUrl ? (
          <>
            <TooltipReference
              content={isPaymentPending ? "Pay Now" : "Download Invoice"}
              tooltipId={`downloadCreditInvoice-${row.id}`}
              placement="bottom"
            >
              <FontAwesomeIcon
                className={`invoiceDownload ${isPaymentPending ? 'redirectInvoice' : ''}`}
                icon={isPaymentPending ? faWallet : faDownload}
                onClick={() => {
                  const fileUrl = isPaymentPending
                    ? row.metadata.invoiceUrl
                    : row.metadata.invoiceUrl.replace(
                      "/secure",
                      "/api/v1/clientinvoices/secure"
                    ) + "&accept=pdf";
                  window.open(fileUrl, "_blank");
                }}
              />
            </TooltipReference>
          </>
        ) : (
          "-"
        )}
      </div>
    );
  };

  const { totalCredits } = totalCredit as any;
  return (
    <div className="messageCredit">
      <Row>
        <Col lg={7}>
          <Row>
            <Col lg={12}>
              <div className="d-flex">
                <div className="creditHeader me-2">Message Credits</div>
                <div className="wrMsg">
                  <div className="notify-icon me-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                    >
                      <path
                        d="M8.55519 1.5C10.4118 1.5 12.1924 2.23755 13.5053 3.5504C14.8181 4.86325 15.5557 6.64385 15.5557 8.5005C15.5557 10.3571 14.8181 12.1378 13.5053 13.4506C12.1924 14.7634 10.4118 15.501 8.55519 15.501C6.69854 15.501 4.91793 14.7634 3.60509 13.4506C2.29224 12.1378 1.55469 10.3571 1.55469 8.5005C1.55469 6.64385 2.29224 4.86325 3.60509 3.5504C4.91793 2.23755 6.69854 1.5 8.55519 1.5ZM9.60519 5.798C10.1252 5.798 10.5472 5.437 10.5472 4.902C10.5472 4.367 10.1242 4.006 9.60519 4.006C9.08519 4.006 8.66519 4.367 8.66519 4.902C8.66519 5.437 9.08519 5.798 9.60519 5.798ZM9.78819 11.425C9.78819 11.318 9.82519 11.04 9.80419 10.882L8.98219 11.828C8.81219 12.007 8.59919 12.131 8.49919 12.098C8.45382 12.0813 8.4159 12.049 8.39226 12.0068C8.36861 11.9646 8.36078 11.9154 8.37019 11.868L9.74019 7.54C9.85219 6.991 9.54419 6.49 8.89119 6.426C8.20219 6.426 7.18819 7.125 6.57119 8.012C6.57119 8.118 6.55119 8.382 6.57219 8.54L7.39319 7.593C7.56319 7.416 7.76119 7.291 7.86119 7.325C7.91046 7.34268 7.95083 7.37898 7.97364 7.42609C7.99645 7.47321 7.99988 7.52739 7.98319 7.577L6.62519 11.884C6.46819 12.388 6.76519 12.882 7.48519 12.994C8.54519 12.994 9.17119 12.312 9.78919 11.425H9.78819Z"
                        fill="#A88300"
                      />
                    </svg>
                  </div>
                  <div className="credit-notification">
                    Message Credits is the pre-deposited amount added to your
                    account. Learn more.
                  </div>
                </div>
              </div>
            </Col>
            {/* <Col lg={4}>
              <div className="credit_img">
                <div>
                  <div className={"center"}>
                    <img alt="" src={wallet} className={"creditImg"} />
                  </div>

                  <div className="credit_balance d-flex justify-content-center">
                    <div>
                      <h4 className="mb-0 textAlign-center">
                        {formatCurrency(totalCredits ? totalCredits : 0)}
                      </h4>
                      <div>Available Balance</div>
                    </div>  
                  </div>
                </div>
              </div>
            </Col> */}
            <Col lg={11} className="pe-0">
              <div className="creditCardCon">
                <div className="total_credits mb-2">
                  <div className="me-2">
                    Available Balance:{" "}
                  </div>
                  <div className="credits rupeeFont">
                    {
                      totalCredit ? formatCurrency(totalCredits) : <Spinner color="success" size={'sm'}></Spinner>
                    }
                  </div>
                </div>
                {/* <div><span>Add Amount</span><span className='validationWrng'>Min ₹100 Max ₹1,00,000</span></div> */}
                <div className="amountContainer">
                  <input
                    placeholder="Enter Your Amount"
                    onChange={handleChange}
                    className="creaditAmountInput"
                    name="amount"
                    type="text"
                    maxLength={6}
                    value={amount}
                  />
                  <span className={`${!amount ? "INR" : ""}`}>INR</span>
                  {/* <div className='text-danger pt-1'>{minMax}</div> */}
                </div>
                <div className="amountBadgeCon">
                  <div
                    className="amountBadgeBtn"
                    onClick={() => setAmount(100)}
                  >
                    + ₹ 100
                  </div>
                  <div
                    className="amountBadgeBtn"
                    onClick={() => setAmount(500)}
                  >
                    + ₹ 500
                  </div>
                  <div
                    className="amountBadgeBtn"
                    onClick={() => setAmount(1000)}
                  >
                    + ₹ 1000
                  </div>
                  <div
                    className="amountBadgeBtn"
                    onClick={() => setAmount(10000)}
                  >
                    + ₹ 10000
                  </div>
                </div>

                <button
                  className="sendButton w-100 rounded"
                  disabled={amount ? amount <= 0 : true}
                  onClick={showPaymentStatus}
                >
                  Add Credits
                  {/* {isLoading && <div className='loadingVia float-right'></div>} */}
                </button>
              </div>
            </Col>
          </Row>
        </Col>
        <Col lg={5} className="ps-0">
          <div>
            <div className="credit_img">
              <div>
                {/* <div className={"center"}>
                    <img alt="" src={wallet} className={"creditImg"} />
                  </div> */}

                {/* <div className="credit_balance d-flex justify-content-center">
                  <div className="d-flex">
                    <div className="d-flex jutify-content-center align-items-center me-3">
                      <FontAwesomeIcon
                        icon={faWallet}
                        color="#007ec3"
                        // color="#00b598"
                        size="4x"
                      />
                    </div>
                    <div className="total_credits">
                      <h4 className="m-0">
                        {formatCurrency(totalCredits ? totalCredits : 0)}
                      </h4>
                      <div className="mb-0 textAlign-center">
                        Available Balance:{" "}
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div>
            {planExpire && (
              <div className={"referal_poster"}>
                <div className={"poster_content mt-1"}>
                  <h6 className="fw-bold">
                    Earn
                    <span className="rupeeFont"> ₹1500</span>
                    <span> FREE Credits Instantly!</span>
                  </h6>
                </div>
                <div className={"referral_link"}>
                  <h6 className="fw-bold mb-2">Join the referral revolution!</h6>
                  <small>
                    When your friends subscribe using your invite code, you can
                    earn up to
                    <span className="rupeeFont"> ₹1500</span>
                    <span className="rupeeFont"> in FREE credits based on their plan. Don't
                      miss out—start sharing and watch your credits soar!</span>
                  </small>
                </div>
                <div className={"referral"}>
                  <div>
                    <div className={"your_referral"}>YOUR REFERRAL CODE</div>
                    <TooltipReference
                      placement="bottom"
                      content={
                        copyReferralCode === referralCode
                          ? "Copied"
                          : `Copy referral code`
                      }
                      tooltipId={`copy-to-clipboard_${referralCode}`}
                    >
                      <div
                        className={"referralCode"}
                        onClick={() => handleCopy(referralCode)}
                      >
                        {referralCode}
                      </div>
                    </TooltipReference>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
        {isNewPayment&&(
              <>
          <Modall
            isOpen={isNewPayment}
            onClose={ModalClose}
            title={"Message Credits"}
            size="md"
          >
            <Row>
              <Col md={9}>
              Message Credits
              </Col>
              <Col  className="d-flex justify-content-end">
              <div className="nofont">
              ₹ {amount}
              </div>
              </Col>
            </Row>
          <h6 className="pt-2">GST</h6>
            <Row>
              <Col md={9}>
              CGST 9%
              </Col>
              <Col className="d-flex justify-content-end">
              <div className="nofont">
              ₹ {GST}
              </div>
              </Col>
            </Row>
            <Row className=" pb-2">
              <Col md={9}>
              SGST 9%
              </Col>
              <Col className="d-flex justify-content-end">
              <div className="nofont">
              ₹ {GST}
              </div>
              </Col>
            </Row>
            <hr className="pt-2" />
            <Row>
              <Col md={9} className="h6">
              Total amount to be paid
              </Col>
              <Col className="d-flex justify-content-end">
              <div className="nofont ">
              ₹ {result}
              </div>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end pt-2">
              <Button
                          type="button"
                          className="sendbutton w-25 p-1 "
                          onClick={() => newPayment("NEW_PAYMENT")}
                          disabled={isLoading}
                        >
                          Pay now
                        </Button>
              </Col>
            </Row>
          </Modall>
              </>
        )}
            <BizTable
              columns={columns}
              fetchData={fetchData}
              counts={count}
              refetchData={paymentDetails}
              tableName='CREDIT'
            /> 
      <div>
        {/* <BootstrapTable
          data={paymentDetails ? paymentDetails : []}
          // selectRow={selectRowProp}
          pagination
          version="4"
          striped
          hover
          options={
            {
              headerAlign: "center",
              noDataText: "No records found",
              sortIndicator: true,
              hidePageListOnlyOnePage: true,
              clearSearch: false,
              alwaysShowAllBtns: true,
              withFirstAndLast: true,
              sizePerPage: 10,
              paginationPosition: "bottom",
            } as any
          }
          containerStyle={{ textAlign: "left" }}
        // options={options as any}
        >
          <TableHeaderColumn dataField="id" isKey hidden>
            #
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="description"
            dataAlign="center"
            dataFormat={(description) => {
              return "Message Credits Recharge";
            }}
          >
            Description
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="createdAt"
            dataAlign="center"
            dataFormat={(createdAt) => moment(createdAt).format("DD MMM YYYY")}
          >
            Created at
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="amount"
            dataFormat={(amount) => (
              <p className="fontchange">{formatCurrency(amount, "INR")}</p>
            )}
            dataAlign="center"
          >
            Amount
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="status"
            dataFormat={(status) => {
              switch (status) {
                case "PENDING":
                  return (
                    <>
                      <div className="">
                        <div className="badgeCon pending">Pending</div>
                      </div>
                    </>
                  );
                case "PAID":
                  return (
                    <div className="badgeCon completed">
                      <div className="success">Paid</div>
                    </div>
                  );
                default:
                  return (
                    <div className="badgeCon">
                      <div >{status ==='DELETED' ? 'Deleted' : status }</div>
                    </div>
                  );
              }
            }}
            dataAlign="center"
          >
            Status
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="status"
            dataFormat={(status, row) => {
              switch (status) {
                case "PENDING":
                  return (
                    <div>
                      <Button
                        className="upload-select w-75 p-2 float-right"
                        onClick={() => paymentNow(row)}
                      >
                        Pay Now
                      </Button>
                    </div>
                  );
                default:
                  return <></>;
              }
            }} // Assuming paymentData doesn't need the cell argument to render the component
            dataAlign="center"
          />
          <TableHeaderColumn
            dataField="status"
            dataFormat={(status, row) => {
              switch (status) {
                case "PENDING":
                  return (
                    <div>
                      <Button
                        className="cancelButton w-75 rounded float-left"
                        onClick={() => cancelTransaction(row)}
                      >
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 1024 1024"
                          fill-rule="evenodd"
                          focusable="false"
                          aria-hidden="true"
                          className="me-2"
                          height="20"
                          width="20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M512 64c247.4 0 448 200.6 448 448S759.4 960 512 960 64 759.4 64 512 264.6 64 512 64Zm0 76c-205.4 0-372 166.6-372 372s166.6 372 372 372 372-166.6 372-372-166.6-372-372-372Zm128.013 198.826c.023.007.042.018.083.059l45.02 45.019c.04.04.05.06.058.083a.118.118 0 0 1 0 .07c-.007.022-.018.041-.059.082L557.254 512l127.861 127.862a.268.268 0 0 1 .05.06l.009.023a.118.118 0 0 1 0 .07c-.007.022-.018.041-.059.082l-45.019 45.02c-.04.04-.06.05-.083.058a.118.118 0 0 1-.07 0c-.022-.007-.041-.018-.082-.059L512 557.254 384.14 685.115c-.042.041-.06.052-.084.059a.118.118 0 0 1-.07 0c-.022-.007-.041-.018-.082-.059l-45.02-45.019c-.04-.04-.05-.06-.058-.083a.118.118 0 0 1 0-.07c.007-.022.018-.041.059-.082L466.745 512l-127.86-127.86a.268.268 0 0 1-.05-.061l-.009-.023a.118.118 0 0 1 0-.07c.007-.022.018-.041.059-.082l45.019-45.02c.04-.04.06-.05.083-.058a.118.118 0 0 1 .07 0c.022.007.041.018.082.059L512 466.745l127.862-127.86c.04-.041.06-.052.083-.059a.118.118 0 0 1 .07 0Z"></path>
                        </svg>
                        Cancel
                      </Button>
                    </div>
                  );
                default:
                  return <></>;
              }
            }} // Assuming paymentData doesn't need the cell argument to render the component
            dataAlign="center"
          />
          <TableHeaderColumn
            dataField="edit"
            dataFormat={downloadInvoice}
          >
            Actions
          </TableHeaderColumn>
        </BootstrapTable> */}
      </div>
      {/* {showPayment &&
        <RightSidebar
          handleCancel={SidebarClose}
          title={'Payment Details'}
          width="30%"
        >
          <StripeIndex amount={isPending.length > 0 ? (isPending[0]?.amount/100) : amount ? amount : 0} clientSecretKey={clientSecretKey} />
        </RightSidebar>
      } */}
    </div>
  );
};
export default CreditIndex;

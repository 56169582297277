import { useState, useEffect, useContext } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import * as flowService from '../services/flows';

import CreateFlow from '../components/flows/createFlow';
import FlowsList from '../components/flows/flowList';
import ListPageHeader from '../components/ui/listPageHeader';
import ErrorFallback from '../components/ui/errorFallback';
import Loader from '../../shade/Loaders/smallLoader';
import { useSelector } from 'react-redux';
import initialContext from '../components/designFlow/editor/useContext';

const FlowsPage: React.FC = () => {
    const { botId } = useParams();
    const [showCreate, setShowCreate] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const initial = useContext(initialContext);
    const [searchQuery, setSearchQuery] = useState('');
    const business = useSelector((state: any) => state.meta.profile);
    const [botName, setBotName] = useState('');
    const query = useQuery({
        queryKey: ['bots', botId],
        queryFn: () => flowService.getFlows(`${botId}`)
    });
    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setSearchQuery(searchInput);
    //     }, 500);
    //     return () => clearTimeout(timer);

    // }, [searchInput]);
    const [botList, setBotList] = useState([]);

    const searchBot = (searchTerm:any) => {
        if (!searchTerm || typeof searchTerm !== 'string') {
            setBotList(query.data);
            setSearchQuery(query.data);
            return;
        }
    
        const searchTermCheck = searchTerm.length > 0 ? searchTerm.toLowerCase() : null;
        
        if (query.data) { 
            const sort = query.data?.filter((bot: any) => {
                return bot.flowName?.toLowerCase().includes(searchTermCheck);
                // || contact.mobileNumber.toString().toLowerCase().includes(searchTermCheck);
            });
            
            setBotList(sort);
            setSearchQuery(sort);
        } else {
            setBotList(query.data);
            setSearchQuery(query.data);
        }
    }
    

    useEffect(() => {
        if(searchInput){
        const timer = setTimeout(() => {
            searchBot(searchInput);
        }, 500);
        return () => clearTimeout(timer);
    }
    if(query.data){
        setBotList(query.data);
        setBotName(query?.data[0]?.bot?.name)
        if(!query?.data[0]?.bot?.name) {
          setBotName(localStorage.getItem("botName") as string);
        }
    }
    }, [searchInput,query.data]);

    useEffect(() => {
        if (botList?.length === 0) {
             initial.isLoading = true;
        }
        else {
            initial.isLoading = false;
        }
    },[botList]);


    if (query.isLoading) {
        return (
            <Loader />
        )
    } else if (query.isError) {
        return (
            <Container>
                <ErrorFallback />
            </Container>
        )
    }


    return (
        // <Container>
        <>
            {query.isFetched ? (
                <>
                    {botId ? (
                        <CreateFlow
                            botId={botId}
                            show={showCreate}
                            onHide={() => setShowCreate(false)}
                            onCreate={() => query.refetch()}
                        />
                    ) : null}
                    <ListPageHeader
                        title={`Bot Flows`}
                        back='/bots/'
                        createLabel='Create New Flow'
                        placeholder='Search Bot'
                        onCreate={() => setShowCreate(true)}
                        value={searchInput}
                        onChange={(e: any) => setSearchInput(e.target.value)}
                        datalength={query.data}
                        business={business?.business}
                        botName ={botName}
                    />
                    {botId ? (
                        <FlowsList
                            flows={botList}
                            refetch={query.refetch}
                            botId={botId}
                            isLoading={initial.isLoading}
                        />
                    ) : null}
                </>
            ) : null}
            </>
        // </Container>
    );
}

export default FlowsPage;
import { BaseEdge, EdgeLabelRenderer, getBezierPath } from 'reactflow';

import classes from './edge.module.css';
import { useAppDispatch } from '../../../hooks';
import { deleteEdge } from '../../../store/graphSlice';
import { useContext } from 'react';
import flowContext from '../../../context/flowContext';
import { toast } from '../../../../common/alert';

export default function Edge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
    animated
}: any) {
    const dispatch = useAppDispatch();
    const flowCtx = useContext(flowContext);
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
        animated
    });
    
    function onEdgeClick(evt: any, id: any) {
        dispatch(deleteEdge({ id }));
        evt.stopPropagation();
        toast('success', 'Connection deleted');
    };

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} animated={true} />
            {!flowCtx.readOnly ? (
                <EdgeLabelRenderer>
                    <div
                        style={{
                            position: 'absolute',
                            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                            fontSize: 12,
                            pointerEvents: 'all',
                        }}
                        className="nodrag nopan"
                    >
                        <button className={classes.edgebutton} onClick={(event) => onEdgeClick(event, id)}>
                            ×
                        </button>
                    </div>
                </EdgeLabelRenderer>
            ) : null}
        </>
    );
}
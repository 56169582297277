import React, { useEffect, useState } from 'react';
import { Form, Offcanvas, Button } from 'react-bootstrap';
import { ErrorMessage, Formik, useFormikContext } from 'formik';
import * as yup from 'yup';

import { IEditorProps } from '../types';
import { useElmentEditor } from '../hooks';
import EditorCaption from '../editorCaption';
import { listIcon } from '../../../../icons';
import { useAppSelector } from '../../../../hooks';
import { getAllSequence } from '../../../../../services/contactMemberService';
import ReactSelect from 'react-select';
import { toast } from '../../../../../common/alert';

interface FormData {
    sequenceUid: string;
};

function TriggerSequenceEditor(props: IEditorProps) {
    const [formData, setFormData] = useState<FormData>({ sequenceUid: '' });
    const [sequenceList, setSequenceList] = useState<any[]>([]);
    const [count, setCount] = useState(0);

    const { init, saveElementChanges } = useElmentEditor({
        type: 'actions/trigger_Sequence',
        data: formData
    }, props);

    useEffect(() => init(setFormData), []);

    const channelId = useAppSelector((state) => state.meta.channelUid);

    const fetchSequence = async () => {
        if (channelId) {
            const response = await getAllSequence(channelId);
            setSequenceList(response);
        }
    }

    useEffect(() => {   
    if (channelId && !sequenceList?.length)
        fetchSequence();
        setCount(count + 1);
    }, [channelId, sequenceList]);

    if(sequenceList===undefined && count==2){
          toast('error','No sequence currently published');
          setCount(count+1);
    }
    const schema = yup.object().shape({
        sequenceUid: yup.string().required('A sequence must be selected')

    });

    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, values, touched, errors, setValues, setFieldValue }) => {
                useEffect(() => {
                    setValues(formData);
                    const sequence = sequenceList?.find((seq: any) => seq?.uid === formData?.sequenceUid);
                    if (sequence?.sequenceName && sequence?.uid) {
                        setSequenceName({ label: sequence?.sequenceName, value: sequence?.uid });
                    }
                }, [formData, setValues, sequenceList]);


                const [sequenceName, setSequenceName] = useState<null | any>(null);

                const handleChange = (option: any) => {
                    setSequenceName(option);
                    setFieldValue('sequenceUid', option.value);
                }

                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption='Trigger Sequence'  icon={<svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" height="1em" viewBox="0 0 512 512"><path fill="#5d8ca6" d="M139.61 35.5a12 12 0 0 0-17 0L58.93 98.81l-22.7-22.12a12 12 0 0 0-17 0L3.53 92.41a12 12 0 0 0 0 17l47.59 47.4a12.78 12.78 0 0 0 17.61 0l15.59-15.62L156.52 69a12.09 12.09 0 0 0 .09-17zm0 159.19a12 12 0 0 0-17 0l-63.68 63.72-22.7-22.1a12 12 0 0 0-17 0L3.53 252a12 12 0 0 0 0 17L51 316.5a12.77 12.77 0 0 0 17.6 0l15.7-15.69 72.2-72.22a12 12 0 0 0 .09-16.9zM64 368c-26.49 0-48.59 21.5-48.59 48S37.53 464 64 464a48 48 0 0 0 0-96zm432 16H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H208a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z"  width={14} /></svg>} />
                        <Offcanvas.Body>
                            <Form.Group className='mb-3'>
                                <Form.Label>Sequence <span className='required'></span></Form.Label>
                                <ReactSelect
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9800 }),
                                        menu: base => ({ ...base, width: 475 })
                                    }}
                                    options={sequenceList?.map((item: any) => ({ label: item?.sequenceName, value: item.uid }) as any)}
                                    className="w-100"
                                    placeholder="Select Sequence"
                                    onChange={handleChange}
                                    value={sequenceName}
                                />
                                <ErrorMessage name="sequenceUid" component="div" className='text-danger' />
                            </Form.Group>
                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit'>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}
export default TriggerSequenceEditor;
import React, { useEffect, useState,useCallback, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  UpdateCommunity,
  ContactsGetAll,
  deleteContactsInCommunity,
  getCommunityContacts,
} from "../../services/campaignService";
import { useSelector } from "react-redux";
import {
  SelectRow,
} from "react-bootstrap-table";
import TooltipReference from "../../common/tooltip";
import { sliceText } from "../../common/sliceText";
import { Label } from "reactstrap";
import {
  faArrowLeft,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmAlert } from "./../../common/alert";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { Button, Row, Col } from "react-bootstrap";
import * as Yup from "yup";
import { ContactIcon } from "./ContactIcon";
import Modall from "../../common/modall";
import Contacts from "../Conversations/contact";
import BizTable from "../../common/BizTable";
import SearchInput from "../../common/searchComponent";


const validationSchema = Yup.object().shape({
  CommunityName: Yup.string().required("Community name is required"),
});

type Props = {};

const EditCommunity = (props: Props) => {
  // const {name} = props
  const channelData = useSelector((state: any) => state.cartreducer.channelUid);
  const businessId = useSelector(
    (state: any) => state.cartreducer.business?.uid
  );
  const { communityId } = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  const { name } = location.state || {};

  const [EditFieldCommunity, setEditCommunity] = useState(false);
  // const [EditCommunityData, setEditCommunityData] = useState({
  //   uid: "",
  //   name: "",
  //   contacts: [],
  // });
  const [EditCommunityData, setEditCommunityData] = useState<any>();
  const [Name, setCommunityName] = useState<string>(name);
  const [isOpenModal, setOpenModal] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [searchTerm, setSearchTerm] = useState<string | null>();
  const [contactCount, setContactCount] = useState(0);
  // const currentPage = useRef(0);
  const paginationLimit = useRef(10);
  const totalPages = Math.ceil(contactCount / paginationLimit.current);

  const fetchData = useCallback(async (
    pageIndex: number,
    pageSize: number,
    searchText: string,
    currentPage: number
  ): Promise<{ data: any; totalPages: number }> => {

    const communityPayload = {
      limit: pageSize,
      page: searchTerm ? 0 : currentPage,
      search:searchTerm
    }
    if(communityId){
      const res = await getCommunityContacts(communityId,communityPayload);
      if(res){
        const { list, count } = res?.data;
       setEditCommunityData(list);
       const countData = searchTerm ? list?.length : count;
       setContactCount(countData)
       setEditCommunity(!EditFieldCommunity);
         return {data:list,totalPages:count}
       }
       else{
         return {data:[],totalPages:0}
       }
    }
    else{
      return {data:[],totalPages:0}
    }

  }, [businessId,searchTerm]);
 
  const checkboxCellRenderer = ({ row }: any) => {
    return (
      <input
        type="checkbox"
        checked={selectedUids.includes(row.uid)}
        onChange={(e) => handleRowSelect(row, e.target.checked)}
      />
    );
    }
    const selectedTableData = useSelector(
      (state: any) => state.tableReducer.selectedUids
    );

    const totalcount = Object.values(selectedTableData).reduce((acc, arr:any) => acc + arr?.length, 0);
    const initialSelected = Object.values(selectedTableData).flat();


  const  columns = [
    // {
    //   Header: (
    //     <input
    //       type="checkbox"
    //       // hidden={true}
    //       // checked={this.state.allChecked}
    //       // onChange={this.toggleAllCheckboxes}
    //     />
    //   ),
    //   accessor: "checkbox",
    //   Cell: checkboxCellRenderer,
    //   sticky: "left",
    // },
    {
      id: "selection",
      Header: ({
        getToggleAllRowsSelectedProps,
      }: {
        getToggleAllRowsSelectedProps: any;
      }) => (
        <div>
          <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
        </div>
      ),
      Cell: ({ row }: { row: any }) => (
        <div>
          <input type="checkbox" {...row.getToggleRowSelectedProps()} />
        </div>
      ),
      accessor: "checkbox",
    },
      {
        Header: "Name",
        accessor: "name",
        sticky: "left",
        // Cell: ({ cell, row }: any) => nameData(cell.value, row.original),
      },
      {
        Header: "Mobile Number",
        accessor: "mobileNumber",
        Cell: ({ cell, row }: any) => mobileData(cell.value, row.original),
      },
      {
        Header: "Tags",
        accessor: "tags",
        Cell: ({ cell, row }: any) => tagsData(cell.value, row.original),
      },
      {
        Header: "Email",
        accessor: "email",
        // Cell: ({ cell, row }: any) => emailData(cell.value, row.original),
      },
    ] as any;
  

  const handleShow = () => {
    // const numberMaskString = localStorage.getItem('numberMask');
    // const numberMask = numberMaskString === 'true';
    // if(!numberMask){
      setOpenModal(true);
    // }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const addNewContact = async (addContact: any) => {
    setContactList(addContact);

    const channelId = channelData?.value;
    const communityName = EditCommunityData.name;
    const nameData = Name ? Name : communityName;

    const updateCommunityPayload = {
      name: nameData,
      creatingContacts: addContact,
      uid: communityId,
      // Assuming this is an array of contact objects
    };

    // If you need to combine contacts from EditCommunityData with addContact,
    // you can do it here in a way that suits your data structure.
    // const combinedContacts = [...EditCommunityData.contacts, ...addContact];

    // const communityPayload = {
    //   ...EditCommunityData,
    //   ...updateCommunityPayload,
    //   contacts: combinedContacts, // or just use addContact if no combination needed
    // };

    if (channelId) {
      const response = await UpdateCommunity(updateCommunityPayload, channelId);
      if (response && communityId) {
        fetchData(0, 20, '', 0);
        // const res = await GetCommunity(communityId, channelId);
        // setEditCommunityData(res?.data);
        // const communityPayload = {
        //   limit: 100,
        //   page: 0,
        // }
        // const res = await getCommunityContacts(communityId,communityPayload);
        // setEditCommunityData(res?.data?.list);
        // const countData = searchTerm ? res?.data?.list?.length : res?.data?.count;
        // setContactCount(countData)
        setEditCommunity(!EditFieldCommunity);
      }
    }
  };

  const fetchContacts = async () => {
    const ContactList = {
      uid: businessId,
      limit: 1000,
      page: 0,
    };
    const response = await ContactsGetAll(ContactList as any);
    const list = response?.data?.list;
    setContactList(list);
    // this.setState({
    //   ContactLists: list,
    //   ContactCount: response?.data?.count,
    // });
  };

  // const EditCommunity = async () => {
  //   if (communityId) {
  //     const channelId = channelData?.value;
  //     // const response = await GetCommunity(communityId, channelId);
  //  const communityPayload = {
  //       limit: 100,
  //       page: 0,

  //     }
  //     const response = await getCommunityContacts(communityId,communityPayload);
  //     if (response) {
  //       // setEditCommunityData(response?.data);
  //       setEditCommunityData(response?.data?.list);
  //       setEditCommunity(!EditFieldCommunity);
  //     }
  //   }
  // };

  const tagsData = (cell: any, row: any) => {
    let title;
    if (!cell || cell.length === 0) {
      return <span className="">-</span>;
    } else {
      const tagNames = cell.map((tag: any) => tag.name);
      title = tagNames.join(", ");
    }

    return (
      <div className="d-flex">
        <div className="d-flex justify-content-end p-0">
          {/* {cell}
           */}
          {/* {cell.slice(0, 1).map((item: any) => (
                <span key={item} className="ms-1 tag-list-view">
                  {item.name ? sliceText(item.name, 10) : "-"}
                </span>
              ))} */}
          {cell &&
            cell.slice(0, 1).map((item: any) => (
              <span key={item} className="ms-1 tag-list-view">
                {Array.isArray(item)
                  ? item.join(", ")
                  : typeof item === "string"
                  ? item
                  : item && item.name
                  ? sliceText(item.name, 10)
                  : "-"}
              </span>
            ))}
        </div>
        <div className="d-flex justify-content-start tag-count">
          {/* {cell.length > 1 && <span className=""> +{cell.length - 1}</span>} */}
          {cell.length > 1 && (
            <TooltipReference
              placement="bottom"
              content={title}
              tooltipId={`tagData_${row.id}`}
            >
              +{cell.length - 1}
            </TooltipReference>
          )}
        </div>
      </div>
    );
  };

  const [selectedUids, setSelectedUids] = useState<any>([]);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  // const [checkboxSelected, setCheckboxSelected] = useState([]);

  const handleRowSelect = (row: any, isSelected: any) => {
    if (isSelected) {
      setSelectedUids((prevSelectedUids: any) => [
        ...prevSelectedUids,
        row.uid,
      ]);
      setSelectedRows((prevSelectedRows: any) => [...prevSelectedRows, row]);
      // setCheckboxSelected(prevCheckboxSelected => [...prevCheckboxSelected, row.id]);
    } else {
      setSelectedUids((prevSelectedUids: any) =>
        prevSelectedUids.filter((uid: string) => uid !== row.uid)
      );
      setSelectedRows((prevSelectedRows: any) =>
        prevSelectedRows.filter((selectedRow: any) => selectedRow !== row)
      );
      // setCheckboxSelected(prevCheckboxSelected => prevCheckboxSelected.filter(id => id !== row.id));
    }
  };

  const handleRowSelectAll = (isSelected: any, rows: any) => {
    if (isSelected) {
      setSelectedUids(rows.map((row: any) => row.uid));
      setSelectedRows(rows);
      // setCheckboxSelected(rows.map(row => row.id));
    } else {
      setSelectedUids([]);
      setSelectedRows([]);
    }
    return true;
  }; 

  // useEffect(() => {
  //   EditCommunity();
  // }, [communityId]);

  
  useEffect(() => {
    if (initialSelected) {
      console.log('initialSelected',initialSelected)
      setSelectedUids(initialSelected);
      setSelectedRows(initialSelected);
    }
  }, [selectedTableData]); 

  const options = {
    headerAlign: "center",
    noDataText: "No records found",
    sortIndicator: true,
    hidePageListOnlyOnePage: true,
    clearSearch: false,
    alwaysShowAllBtns: true,
    withFirstAndLast: true,
    sizePerPage: 10,
    sizePerPageList: [
      10,
      25,
      50,
      100,
      // { text: "All", value: EditCommunityData.contacts?.length },
      { text: "All", value: EditCommunityData },

    ]
    ,
    paginationPosition: "bottom",
  };

  const selectRowProp: SelectRow = {
    mode: "checkbox",
    // clickToSelect: false,
    clickToSelectAndEditCell: false,
    clickToExpand: false,
    // selected: checkboxSelected,
    onSelect: handleRowSelect,
    onSelectAll: handleRowSelectAll,
  };

  const dropContacts = async () => {
    const { uid } = EditCommunityData;
    const channelId = channelData?.value;
    const dropUid = selectedRows.map((row: any) => row.uid);
    const dropPayload = {
      uid: communityId,
      name: Name,
      removingContacts: dropUid
    };
    
    if (channelId) {
      confirmAlert(
        "warning",
        "Are you sure you want to delete this contact?",
        async () => {
          const response = await deleteContactsInCommunity(
            channelId,
            dropPayload
          );
          if (response) {
            setSelectedUids([]);
            setSelectedRows([]);
            fetchData(0, 20, '', 0);
            // const channelId = channelData?.value;
            // const response = await GetCommunity(uid, channelId);
            // if (response) {
            //   toast('success', 'Contacts deleted successfully');
             
            //   setContactList(response?.data?.contacts);
            //   setEditCommunityData(response?.data);
            //   setEditCommunity(!EditFieldCommunity);
            // }
          }
        },
        () => {}
      );
    }
  };

  const clearSelect = () => {
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      'input[type="checkbox"]'
    );
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        checkbox.click();
      }
    });
    setSelectedUids([]);
  };
  const handleSubmit = async (values: any) => {
    const communityName = EditCommunityData?.name;
    const nameData = values.CommunityName ?? communityName;
    const updateCommunityPayload = {
      name: nameData,
      uid: communityId,
      creatingContacts:[],
    };
    // const updated = { ...EditCommunityData, ...updateCommunityPayload };
    const channelId = channelData?.value;

    const response = await UpdateCommunity(updateCommunityPayload, channelId);
    if (response) {
      navigateTo(2);
    }
    // const status ='MANUAL';
    // const response = await CreateCommunity(community,selectedRows,status,channelId,businessUid)
  };
  const initialValues = {
    CommunityName: name,
  };

  const navigateTo = (tab: any) => {
    navigate(`/community/?tab=${tab}`);
  };

  

  const onSearchChange = (searchTerm: string) => {
    const searchTermCheck = searchTerm.length > 0 ? searchTerm : null;
    setSearchTerm(searchTermCheck);
  };

  const mobileData = (cell: any, rowData: any) => {
    const { countryCode } = rowData;
    const countryCodeString = countryCode?.toString(); // Ensure countryCode is a string
    const numberMaskString = localStorage.getItem('numberMask');
    const numberMask = numberMaskString === 'true';
    return (
      <div className={`${numberMask ? "numberMasking_Blur" :''}`}>
        {
              countryCodeString?.length > 0
                ? `+${countryCodeString} ${cell.substring(countryCodeString.length)}`
                : cell
        }

      </div>
    );
  };

  const handleKeyDown = (event: any) => {
    const { value } = event.target;
    if (event.key === " " && value.trim() === "") {
      event.preventDefault(); // Prevent space key from having its default behavior
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Row className="">
            <h5 className="mt-1">
              <FontAwesomeIcon
                icon={faArrowLeft}
                onClick={() => navigateTo(2)}
                className="ms-2 me-2"
              />
              Edit Community
            </h5>
            <Label className="community-name  pt-3">Community Name</Label>
            <Form className="">
              <Row className="pb-2">
                <Col md="4" lg="4" xl="4" className="community-name">
                  <Field
                    type="text"
                    name="CommunityName"
                    className="form-control "
                    placeholder="Enter your Community name"
                    onKeyDown={handleKeyDown}
                    maxLength={60}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e.target.value;
                      setCommunityName(value);
                      setFieldValue("CommunityName", e.target.value);
                    }}
                  />
                  <ErrorMessage
                    className="text-danger"
                    name="CommunityName"
                    component="div"
                  />
                </Col>
                <Col md="4" lg="4"></Col>
                <Col className="">
                  {selectedRows.length > 0 ? (
                    <>
                      <div className="d-flex">
                        <div
                          className="border rounded p-1 me-2"
                          onClick={handleShow}
                        >
                          <ContactIcon />
                        </div>
                        <Button
                          variant=""
                          type="button"
                          onClick={clearSelect}
                          className="cancelButton me-2 "
                        >
                          Clear Selection
                        </Button>
                        <Button
                          variant=""
                          type="button"
                          onClick={dropContacts}
                          className="sendButton w-50  "
                        >
                          <FontAwesomeIcon icon={faTrashCan} className="me-2" />{" "}
                          Delete selected ({selectedRows.length})
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="d-flex">
                        <div
                          className="border rounded p-1 me-2"
                          onClick={handleShow}
                        >
                          <ContactIcon />
                        </div>
                        <Button
                          variant=""
                          type="button"
                          onClick={() => navigateTo(2)}
                          className="cancelButton me-2 "
                        >
                          Cancel
                        </Button>
                        <Button
                          variant=""
                          type="submit"
                          className="sendButton w-50 "
                        >
                          Update Community
                        </Button>
                      </div>
                    </>
                  )}

                  {isOpenModal && (
                    <Modall
                      isOpen={isOpenModal}
                      onClose={handleClose}
                      title="New Conversation"
                      size="md"
                      component="conversation"
                    >
                      <Contacts
                        handleClose={handleClose}
                        status="CAMPAIGN"
                        EditCommunityList={contactList}
                        addNewContact={addNewContact}
                      ></Contacts>
                    </Modall>
                  )}
                </Col>
              </Row>
              <Row
                className="d-flex align-items-center pt-2 pb-2"
              >
                <Col>
                  <div className={`selected-contacts`}>
                    Selected :{" "}
                    <span>
                      {selectedUids.length} of{" "}
                      {contactCount} Contacts{" "}
                    </span>
                  </div>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                <SearchInput
                          component="editCommunity"
                          onSearchChange={onSearchChange}
                          placeHolder={"Search Contacts"}
                        />
                </Col>
              </Row>
            </Form>
          </Row>
        )}
      </Formik>

      {/* <BootstrapTable
        data={EditCommunityData}
        selectRow={selectRowProp}
        search
        pagination
        version="4"
        striped
        hover
        options={options as any}
        containerStyle={{ textAlign: "left" }}
      >
        <TableHeaderColumn dataField="id" isKey hidden></TableHeaderColumn>
        <TableHeaderColumn
          dataField="name"
          dataAlign="left"
          //   dataFormat={this.nameData}
          width="25%"
        >
          Name
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="mobileNumber"
          dataAlign="left"
          width="25%"
            dataFormat={mobileData}
        >
          Mobile number
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="tags"
          dataAlign="left"
          width="25%"
          dataFormat={tagsData}
        >
          Tags
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="email"
          dataAlign="left"
          // dataFormat={this.emailData}
        >
          Email
        </TableHeaderColumn>
      </BootstrapTable> */}
            <BizTable
              columns={columns}
              fetchData={fetchData}
              counts={contactCount}
              refetchData={EditCommunityData}
              tableName='EDITCOMMUNITY'
            /> 
    </div>
  );
};

export default EditCommunity;

import { useEffect, useState } from 'react';
import { Form, Offcanvas, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import { uuidv4 } from '../../../../utils/uuid';
import { IEditorProps } from '../types';
import { useElmentEditor } from '../hooks';
import OptionsInput, { IListOption } from './optionsInput';
import BodyInput from '../bodyInput';
import EditorCaption from '../editorCaption';
import { listIcon } from '../../../../icons';
import AdvancedSettings from '../askType/advancedSettings';
import VariableInputLabel from '../variableInputLabel';
import { SelectVariable } from '../setVariable';
import { extractVariableName, removeVariables } from '../../../../../common/commonfns';
import DynamicOptionType from '../dynamicOptionType';
import { IStorageVariable } from '../../../../entity/Variable';
import styles from './optionsInput.module.css';

export interface FormData {
    headerText: string;
    bodyText: string;
    footerText: string;
    options: IListOption[];
    attempt: number;
    errorMessage: string;
    endFlow: boolean;
    storageVariable: IStorageVariable<'TEXT'>;
    dynamic: boolean
    dynamicFields: {
        iterateVariable: IStorageVariable<'ARRAY'>,
        idPath: string,
        titlePath: string,
        descriptionPath: string
    } | null;
    listMessageButton: string
};


function SendListEditor(props: IEditorProps) {
    const [dynamic, setDynamic] = useState(false)
    const [optionsLength, setOptionsLength] = useState(0);
    const [formData, setFormData] = useState<FormData>({
        headerText: '',
        bodyText: '',
        footerText: '',
        options: [
            {
                id: uuidv4(),
                optionTitle: '',
                order: 1,
                list: [
                    {
                        id: null,
                        uid: uuidv4(),
                        title: '',
                        description: ''
                    }
                ]
            }
        ],
        attempt: 1,
        errorMessage: "",
        endFlow: true,
        storageVariable: {
            name: '',
            type: 'TEXT'
        },
        dynamic: false,
        dynamicFields: null,
        listMessageButton: 'Send'
    });
    const { init, saveElementChanges } = useElmentEditor({
        type: 'send_list',
        data: formData
    }, props);
    useEffect(() => init(setFormData), []);

    const listSchema = yup.object().shape({
        uid: yup.string(),
        title: yup.string().required('Item title required')
            .test('max-24', 'Item title should be within 24 characters long', (value) => {
                if (value) {
                    return removeVariables(value).length <= 24
                }
                return true
            }),
        description: yup.string()
        .test('max-72', 'Item title should be within 72 characters long', (value) => {
            if (value) {
                return removeVariables(value).length <= 72
            }
            return true
        }),
    });
    const optionSchema = yup.object().shape({
        id: yup.string().required(),
        optionTitle: optionsLength > 1 ? yup.string().required('Option title is required for more than one options') : 
                    yup.string().nullable(),
        list: yup.array().of(listSchema).min(1, 'At least one list item is required')
    });
    const storageVariableSchema = yup.object().shape({
        name: yup.string(),
    });
    const schema = yup.object().shape({
        errorMessage: yup.string().notRequired(),
        bodyText: yup.string().required('Question Text is a required field').test('is-double-space', 'Question Text is a required field', value => value !== '  '),
        headerText: yup.string(),
        footerText: yup.string(),
        attempt: yup.number().min(1, 'Attempt should be minimum 1').required('Attempt is a required'),
        options: dynamic ? yup.array() : yup.array().of(optionSchema).min(1, 'At least one button is required'),
        endFlow: yup.boolean().required("Action on Invalid Response required"),
        storageVariable: storageVariableSchema,
        dynamic: yup.boolean(),
        dynamicFields: dynamic ? yup.object().shape({
            iterateVariable: yup.object().shape({
                name: yup.string().required('Iterate Variable is required'),
            }),
            idPath: yup.string().required('Path to ID is required'),
            titlePath: yup.string().required('Path to Title is required'),
            descriptionPath: yup.string(),
        }).required() : yup.object().nullable(),
        listMessageButton: yup.string()
    });


    return (
        <Formik
            validationSchema={schema}
            onSubmit={saveElementChanges}
            initialValues={formData}
        >
            {({ handleSubmit, handleChange, values, touched, errors, setValues, setFieldValue }) => {
                useEffect(() => {
                    setValues(formData);
                }, [formData, setValues]);
                useEffect(() => {
                    setDynamic(values.dynamic)
                    if (values.dynamic && !values.dynamicFields) {
                        setFieldValue('dynamicFields', formData.dynamic ? formData.dynamicFields : {
                            iterateVariable: { name: '' },
                            idPath: '',
                            titlePath: '',
                            descriptionPath: ''
                        })
                    }
                }, [values.dynamic]);

                useEffect(() => {
                    setOptionsLength(values?.options?.length);
                },[values?.options]);

                
                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption='Ask List Option' icon={<img style={{ width: 16 }} alt='' src={listIcon} />} />
                        <Offcanvas.Body>
                            <Form.Group className='mb-3' style={{ position: 'relative' }}>
                                <Form.Label>Header <span className='dimmed'>(Optional)</span></Form.Label>
                                <Form.Control
                                    name='headerText'
                                    value={values.headerText}
                                    placeholder='Enter the header text'
                                    isInvalid={(touched.headerText && errors.headerText) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched.headerText && !errors.headerText}
                                    maxLength={60}
                                />
                                <div className={styles.textLength}>
                                        {values.headerText.length}/60
                                </div>
                                <Form.Control.Feedback type='invalid'>
                                    {errors.headerText}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='mb-3'>
                            <BodyInput
                                name='bodyText'
                                label={<>Question Text <span className='required'></span></>}
                                placeholder='What do you think?'
                                value={values.bodyText}
                                isInvalid={(touched.bodyText && errors.bodyText) ? true : false}
                                onChange={handleChange}
                                isValid={touched.bodyText && !errors.bodyText}
                                error={errors.bodyText}
                                limit={1024}
                            />
                            </Form.Group>
                            <Form.Group className='mb-3' style={{ position: 'relative' }}>
                                <Form.Label>Footer <span className='dimmed'>(Optional)</span></Form.Label>
                                <Form.Control
                                    name='footerText'
                                    placeholder='Enter the footer text'
                                    value={values.footerText}
                                    isInvalid={(touched.footerText && errors.footerText) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched.footerText && !errors.footerText}
                                    maxLength={60}
                                />
                                    <div style={{
                                        position: 'absolute',
                                        right: 0,
                                        bottom: -20,
                                        fontSize: '.8rem',
                                        fontWeight: 400,
                                        color: '#A3A3A3',
                                        padding: '1px 3px'
                                    }}>
                                        {values.footerText.length}/60
                                    </div>
                                <Form.Control.Feedback type='invalid'>
                                    {errors.footerText}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <DynamicOptionType>
                                <OptionsInput
                                    options={values.options}
                                    error={errors.options}
                                    isInvalid={(touched.options && errors.options) ? true : false}
                                    isValid={(touched.options && !errors.options) ? true : false}
                                    touched={touched.options}
                                />
                            </DynamicOptionType>
                            <Form.Group className='mb-4' style={{ position: 'relative' }}>
                                <Form.Label>Button Text <span className='dimmed'>(Optional)</span></Form.Label>
                                <Form.Control
                                    name='listMessageButton'
                                    placeholder='Enter the button text'
                                    value={values?.listMessageButton}
                                    isInvalid={(touched.listMessageButton && errors.listMessageButton) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched.listMessageButton && !errors.listMessageButton}
                                    maxLength={20}
                                />
                                <div className={styles.textLength}>
                                        {values?.listMessageButton?.length}/20
                                </div>
                                <Form.Control.Feedback type='invalid'>
                                        {errors.listMessageButton}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <AdvancedSettings />
                            <VariableInputLabel
                                error={(
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.storageVariable?.name}
                                    </Form.Control.Feedback>
                                )}>
                                <SelectVariable
                                    placeholder="Create or Select variable"
                                    name="storageVariable"
                                    value={values.storageVariable.name}
                                    onChange={handleChange}
                                    type="TEXT"
                                />
                            </VariableInputLabel>
                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit'>
                                Save
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default SendListEditor;

import React, { useState, ReactNode, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Button, Card, Popover } from 'react-bootstrap';
import { MdDataObject, MdDateRange, MdEmail, MdOutlineAccessTime, MdOutlineAnnouncement, MdOutlinePermContactCalendar, MdOutlineTextFields, MdSettingsInputComponent } from 'react-icons/md';
import { useFormikContext } from 'formik';
import { BsFillTelephoneFill, BsGlobeAmericas, BsTagsFill } from 'react-icons/bs';
import Radium, { StyleRoot, Style } from 'radium';

import classes from './addVariable.module.scss';
import { useAppSelector } from '../../../hooks';
import SearchBox from '../../ui/searchBox';

interface AddVariableProps {
    fieldName: string;
    fieldValue: string;
    static?: boolean;
    product?: boolean;
    limit?: any;
    onClick?: () => void;
    close?: boolean;
    handleChange?: (value: any) =>  void;
    singleValue?: boolean;
};

interface VariableRowProps {
    label: string;
    items: { id: string, title: string, icon: ReactNode, specialType?: string; payload?: string; }[];
    onClick: (id: string) => void;
};

const VariableRow: React.FC<VariableRowProps> = props => {
    if (props.items.length < 1) return null;
    return (
        <div className={classes.variableListItem}>
            <div className={classes.label}>{props.label}</div>
            <div className={classes.variableRow}>
                {props.items.map(item => {
                    return (
                        <div key={item.id} className={classes.gridItem} onClick={() => props.onClick(item.id)}>
                            <Card className={classes.iconCard}>
                                <div className={classes.icon}>
                                    {item.icon}
                                </div>
                            </Card>
                            <div className={classes.title}>
                                {item.title}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

const AddVariable: React.FC<AddVariableProps> = (props) => {
    const variables = useAppSelector((state) => state.graph.variables);
    const formik = useFormikContext();
    const [show, setShow] = useState(false);

    const [topSpacing, setTopSpacing] = useState(0);
    const [leftSpacing, setLeftSpacing] = useState(0);
    const addVariableBtnRef = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        function handleScroll() {
            if (addVariableBtnRef.current) {
                const rect = addVariableBtnRef.current.getBoundingClientRect();
                setLeftSpacing(rect.width);
                setTopSpacing(rect.top + 40);
            }
        }

        const flowBuilderEditor = document.getElementById("flowBuilder-Editor");

        if (flowBuilderEditor) {
            flowBuilderEditor.addEventListener('scroll', handleScroll);
            handleScroll();

            return () => {
                flowBuilderEditor.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);
    useEffect(() => {
        (window as any).ADDVARIABLE_POPUP = show;
        (window as any).ADDVARIABLE_POPUP_HIT = show ? 1 : 0;
    }, [show]);
    useEffect(() => {
        const rootEl = document.getElementById("flowBuilder-Editor");
        const offcanvasEl = document.getElementById("flowBuilder-Editor-Offcanvas-Offcanvas")
        const handleClick = () => {
            if ((window as any).ADDVARIABLE_POPUP && (window as any).ADDVARIABLE_POPUP_HIT === 1) {
                (window as any).ADDVARIABLE_POPUP_HIT = 2;
                return;
            }
            if ((window as any).ADDVARIABLE_POPUP && (window as any).ADDVARIABLE_POPUP_HIT === 2) {
                setShow(false);
            }
        }

        const handleScroll = () => {
            if ((window as any).ADDVARIABLE_POPUP) setShow(false);
        }

        if (rootEl && offcanvasEl) {
            rootEl.addEventListener("click", handleClick);
            offcanvasEl.addEventListener("scroll", handleScroll);
        }
        return () => {
            if (rootEl && offcanvasEl) {
                rootEl.removeEventListener("click", handleClick);
                offcanvasEl.removeEventListener("scroll", handleScroll);
            }
        }
    }, []);


    interface VariableItem {
        id: string;
        title: string;
        icon: React.ReactNode;
    }

    const AddVariablePopover = () => {
        const [search, setSearch] = useState('');
        const searchBoxRef = useRef<HTMLInputElement>(null);
        useEffect(() => {
            if (show && searchBoxRef.current) {
                searchBoxRef.current.focus();
            }
        }, []);


        const filterItems = (items: VariableItem[], searchTerm: string): VariableItem[] => {
            if (!searchTerm) return items;

            return items.filter(item =>
                item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.id.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }
        const offcanvasEl = document.getElementById("flowBuilder-Editor-Offcanvas-Offcanvas");
        return (
            <StyleRoot className={classes.addVariable}>
                <Style
                    scopeSelector=".AddvarPopover-Alignment"
                    rules={{
                        top: topSpacing - (offcanvasEl ? offcanvasEl.scrollTop : 0),
                        right: leftSpacing,
                        mediaQueries: {
                            "(max-width: 1366px)": {
                                top: topSpacing - (offcanvasEl ? offcanvasEl.scrollTop : 0) - 10,
                            },
                        }
                    }}
                />
                <Popover className={props.product ? classes.wrapper : classes.product}>
                    <Popover.Body className={classes.body}>
                        <SearchBox
                            id="addvariable-search"
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                            inputRef={searchBoxRef}
                            onClick={(e) => e.stopPropagation()}
                        />
                        <div onClick={() => setShow(false)}>
                            <VariableRow
                                label='Intent'
                                items={filterItems([
                                    { id: 'intent.text', title: 'Text', icon: <MdOutlineTextFields />, },
                                    { id: 'intent.payload', title: 'Payload', icon: <MdSettingsInputComponent />, },
                                    { id: '@time("hh:mm:ss")', title: 'Current Time', icon: <MdOutlineAccessTime/>, },
                                    { id: '@date("dd-MM-yyyy")', title: 'Current Date', icon: <MdDateRange/>, }
                                ], search)}
                                onClick={(contactVariable) => {
                                    if(props?.singleValue) {
                                        formik.setFieldValue(props.fieldName, `{{${contactVariable}}}`);
                                    }
                                    else if (props?.limit) {
                                        formik.setFieldValue(props.fieldName, `${props.fieldValue ? `${props.fieldValue} {{${contactVariable}}}`:`{{${contactVariable}}}`}`);
                                    }
                                    if(props.handleChange) {
                                        props.handleChange(`${props.fieldValue ? `${props.fieldValue} {{${contactVariable}}}`:`{{${contactVariable}}}`}`);
                                    }
                                }}
                            />
                            <VariableRow
                                label='Contact fields'
                                items={filterItems([
                                    { id: 'contact.name', title: 'Name', icon: <MdOutlinePermContactCalendar />, },
                                    { id: 'contact.email', title: 'Email', icon: <MdEmail />, },
                                    { id: 'contact.phone', title: 'Phone', icon: <BsFillTelephoneFill />, },
                                    { id: 'contact.tags', title: 'Tags', icon: <BsTagsFill />, },
                                    { id: 'contact.isMarketingOptIn', title: 'Marketing Opt in', icon: <MdOutlineAnnouncement />, },
                                ], search)}
                                onClick={(contactVariable) => {
                                    if(props?.singleValue) {
                                        formik.setFieldValue(props.fieldName, `{{${contactVariable}}}`);
                                    }
                                    else if (props?.limit) {
                                        formik.setFieldValue(props.fieldName, `${props.fieldValue ? `${props.fieldValue} {{${contactVariable}}}`:`{{${contactVariable}}}`}`);
                                    }
                                    if(props.handleChange) {
                                        props.handleChange(`${props.fieldValue ? `${props.fieldValue} {{${contactVariable}}}`:`{{${contactVariable}}}`}`);
                                    }
                                }}
                            />
                            <VariableRow
                                label='Recipient'
                                items={filterItems([
                                    {
                                        id: 'contact.countryCode', title: 'Country code', icon: <BsGlobeAmericas />,
                                    },
                                ], search)}
                                onClick={(contactVariable) => {
                                    if(props?.singleValue) {
                                        formik.setFieldValue(props.fieldName, `{{${contactVariable}}}`);
                                    }
                                    else if (props?.limit) {
                                        formik.setFieldValue(props.fieldName,`${props.fieldValue ? `${props.fieldValue} {{${contactVariable}}}`:`{{${contactVariable}}}`}`);
                                    }
                                    if(props.handleChange) {
                                        props.handleChange(`${props.fieldValue ? `${props.fieldValue} {{${contactVariable}}}`:`{{${contactVariable}}}`}`);
                                    }
                                }}
                            />
                            {variables?.length > 0 ? (
                                variables?.map((variable: any) => (
                                    variable?.specialType === 'appointment_Flow' ? (
                                    <VariableRow
                                        key={variable.name} 
                                        label={`Appointment variable - ${variable.name}`}
                                        items={filterItems(
                                            [
                                                {
                                                    id: `variable.${variable.name}.fromTime`,
                                                    title: `${variable.name} From Time`,
                                                    icon: <MdDataObject />,
                                                },
                                                {
                                                    id: `variable.${variable.name}.toTime`,
                                                    title: `${variable.name} To Time`,
                                                    icon: <MdDataObject />,
                                                },
                                                {
                                                    id: `variable.${variable.name}.name`,
                                                    title: `${variable.name} Name`,
                                                    icon: <MdDataObject />
                                                },
                                                {
                                                    id: `variable.${variable.name}.date`,
                                                    title: `${variable.name} Date`,
                                                    icon: <MdDataObject />
                                                },
                                                {
                                                    id: `variable.${variable.name}.memberName`,
                                                    title: `${variable.name} Member Name`,
                                                    icon: <MdDataObject />
                                                },
                                            ],
                                            search
                                        )}
                                        onClick={(variableItem) => {
                                            if(props?.singleValue) {
                                                formik.setFieldValue(props.fieldName, `{{${variableItem}}}`);
                                            }
                                            else if (props?.limit) {
                                                formik.setFieldValue(
                                                    props.fieldName,
                                                    `${props.fieldValue ? `${props.fieldValue} {{${variableItem}}}`:`{{${variableItem}}}`}`
                                                );
                                            }
                                            if(props.handleChange) {
                                                props.handleChange(`${props.fieldValue ? `${props.fieldValue} {{${variableItem}}}`:`{{${variableItem}}}`}`);
                                            }
                                        }}
                                    />
                                ) : null)) 
                            ) : null}
                             {variables?.length > 0 ? (
                                <VariableRow
                                    label='Custom Variables'
                                    items={filterItems(variables.reduce((acc: any[], variable: any) => {
                                        if (variable.specialType === 'location') {
                                            acc.push({
                                                id: `variable.${variable.name}.latitude`,
                                                title: `${variable.name} Latitude`,
                                                icon: <MdDataObject />,
                                            });
                                            acc.push({
                                                id: `variable.${variable.name}.longitude`,
                                                title: `${variable.name} Longitude`,
                                                icon: <MdDataObject />,
                                            });
                                        }
                                        else if (variable.specialType !== 'appointment_Flow') {
                                            acc.push({
                                                id: `variable.${variable.name}`,
                                                title: variable.name,
                                                icon: <MdDataObject />,
                                                specialType: variable.specialType
                                            });
                                        }
                                        return acc;
                                    }, []), search)}
                                    onClick={(variable) => {
                                        if(props?.singleValue) {
                                            formik.setFieldValue(props.fieldName, `{{${variable}}}`);
                                        }
                                        else if (props?.limit) {
                                            formik.setFieldValue(props.fieldName,  `${props.fieldValue ? `${props.fieldValue} {{${variable}}}`:`{{${variable}}}`}`);
                                        }
                                        if(props.handleChange) {
                                            props.handleChange( `${props.fieldValue ? `${props.fieldValue} {{${variable}}}`:`{{${variable}}}`}`);
                                        }
                                    }}
                                />
                            ) : null}

                        </div>
                    </Popover.Body>
                </Popover>
            </StyleRoot >
        );
    }
    const AddVariablePopoverWithRadium = Radium(AddVariablePopover);
    const variableOverlay = show ? createPortal(<AddVariablePopoverWithRadium />, document.body, "addvariable-portal") : null;
    const variableButton = (
        <Button
            className={props.static ? (!show ? classes.staticBtn : classes.checked) : (!show ? classes.addBtn : classes.addChecked)}
            size='sm'
            onClick={(event: any) => {
                setShow(!show);
                props.onClick && props.onClick();
            }}
            style={{ backgroundColor: 'white' }}
            ref={addVariableBtnRef}
        >
            Add variable
        </Button>
    );
    if (props.static) {
        return (
            <>
                <div className={classes.container}>
                    {variableOverlay}
                </div>
                {variableButton}
            </>
        )
    }
    return (
        <div className={classes.container}>
            {variableOverlay}
            {variableButton}
        </div>
    );

}

AddVariable.defaultProps = {
    static: false
};

export default AddVariable;
import React, { useEffect, useState, ReactNode, useContext } from 'react'
import { Form, Offcanvas, Button, Spinner, Alert, Dropdown, OverlayTrigger, FormControl } from 'react-bootstrap'
import { Field, Formik, useFormikContext } from 'formik'
import * as yup from 'yup'
import { useQuery } from '@tanstack/react-query'
import ReactSelect from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faAngleUp, faChevronDown, faChevronUp, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import styles from './sendOrderDetails.module.scss'
import * as designFlowServices from '../../../../services/designFlow'
import { IEditorProps } from '../types'
import { useElmentEditor } from '../hooks'
import EditorCaption from '../editorCaption'
import { sendProductIcon } from '../../../../icons'
import BodyInput from '../bodyInput'
import { useAppSelector } from '../../../../hooks'
import { SOMETHING_WENT_WRONG } from '../../../../../constants/errorMessage'
import { uuidv4 } from '../../../../utils/uuid'
import { DelayInput } from '../delayEditor'
import AdvancedSettings from '../askType/advancedSettings'
import { IStorageVariable } from '../../../../entity/Variable'
import { SelectVariable } from '../setVariable'
import { Label } from 'reactstrap'
import { BsFillTrashFill, BsInfoCircleFill } from 'react-icons/bs'
import { Tooltip } from 'react-bootstrap'
import FileInput, { IFile } from '../fileInput'
import FilePlaceholder from '../../nodes/sendMedia/filePlaceholder'
import * as mediaService from '../../../../services/media';
import AddVariable from '../addVariable'
import { NULL } from 'sass'
import initialContext from '../useContext';
import { init } from 'emoji-mart'
import { useParams } from 'react-router-dom'
import axios from '../../../../../utils/axios'


interface VariableInputLabelProps {
    children: ReactNode;
    label?: string;
    error?: ReactNode;
    required?: boolean;
};

const VariableInputLabel: React.FC<VariableInputLabelProps> = ({ children, error, label, required }) => {
    return (
        <Form.Group className='mb-3'>
            <Form.Label>
                {label} {required ? <span className='required'></span> : <span className='dimmed'>(Optional)</span>}
                <OverlayTrigger
                    placement='bottom'
                    overlay={label == 'Duration' ? <Tooltip> Duration should be more than 5 minutes </Tooltip> :
                        <Tooltip> Add variable to the field</Tooltip>}
                >
                    <Button size='sm' id={styles.round} variant='default'>
                        <BsInfoCircleFill />
                    </Button>
                </OverlayTrigger>
            </Form.Label>
            {children}
            {error ? (
                <div className='invalid-feedback' style={{ display: 'block' }}>
                    {error}
                </div>
            ) : null}
        </Form.Group>
    );
}

VariableInputLabel.defaultProps = {
    label: 'Duration',
    required: false
};

export interface DigitalProduct {
    id: number | null,
    uid: string | null,
    name: string,
    amount: string | number,
    quantity: number,
    saleAmount: number| string | null
}



interface FormData {

    bodyText: string,
    footerText: string | null,
    paymentConfig: null | designFlowServices.IPaymentMethod,
    digitalItems: [DigitalProduct] | [],
    expiringDuration: string,
    attempt: number,
    errorMessage: string,
    endFlow: boolean,
    dynamicFields: {
        iterateVariable: IStorageVariable<'ARRAY'>,
        productNamePath: string | null,
        amountPath: string | null,
        quantityPath: string | null,
        saleAmountPath: string | null
    },
    dynamic: boolean,
    media: IFile | null,
    mediaType: 'image',
    mediaUrl: string,
    headerType: string | null,
    tax: {
        amount: number | null,
        description: string | null,
        discountProgramName: string | null
    } | null,
    shipping: {
        amount: number | null,
        description: string | null,
        discountProgramName: string | null
    },
    discount: {
        amount: number | null,
        description: string | null,
        discountProgramName: string | null
    },
    isSameFlow: boolean
    ownFlowOrder: boolean
}

const SelectPaymentMethod: React.FC = () => {
    const formik = useFormikContext<FormData>()
    const channelUid = useAppSelector(state => state.meta.channelUid)
    const query = useQuery({
        queryKey: ['payment-method'],
        queryFn: () => designFlowServices.getPaymentMethods(channelUid || ''),
        enabled: false
    })
    useEffect(() => {
        if (channelUid && !query.isFetchedAfterMount) {
            query.refetch()
        }
    }, [channelUid])

    let formContent = <Spinner />

    if (!query.isLoading && !query.isError) {
        formContent = (
            <>
                <ReactSelect
                    styles={{
                        menuPortal: base => ({ ...base, zIndex: 9800 }),
                        menu: base => ({ ...base, width: 475 })
                    }}
                    value={(() => {
                        if (formik.values.paymentConfig) {
                            const myPayment = query.data.find(p => p.id === formik.values.paymentConfig?.id)
                            if (myPayment) {
                                return {
                                    label: myPayment?.method === 'upi' ? "UPI VPA ID" : myPayment?.method === "payu" ? "PayU" : "Razorpay",
                                    value: myPayment.id
                                }
                            }
                        }
                        return ''
                    })()}
                    options={(() => {
                        if (Array.isArray(query.data)) {
                            return query.data.map(p => ({
                                label: p?.method === 'upi' ? "UPI VPA ID" : p?.method === "payu" ? "PayU" : "Razorpay",
                                value: p.id
                            }))
                        }
                        return []
                    })()}
                    onChange={(selected: any) => {
                        if (selected) {
                            const myPayment = query.data.find(p => p.id === selected.value)
                            if (myPayment) {
                                formik.setFieldValue('paymentConfig', myPayment)
                            }
                        }
                    }}
                />
                {formik.touched.paymentConfig
                    && formik.errors.paymentConfig
                    && typeof formik.errors.paymentConfig === 'string' ? (
                    <div className='invalid-feedback d-block'>
                        {/* {formik.errors.paymentConfig} */}
                        Payment Method is required
                    </div>
                ) : null}
            </>
        )
    } else if (query.isError) {
        formContent = <Alert color='danger'>{SOMETHING_WENT_WRONG}</Alert>
    }

    return (
        <Form.Group className='mb-3'>
            <Form.Label>Payment Provider <span className='required'></span></Form.Label>
            {formContent}
        </Form.Group>
    )
}

type ProductItemProps = {
    index: number,
    uid: string | null,
    isOrder: boolean
}

const ProductItem: React.FC<ProductItemProps> = ({ index, uid, isOrder }) => {
    const [isStaticProduct, setIsStaticProduct] = useState(false)
    const [isDynamicProduct, setIsDynamicProduct] = useState(false)
    const [isStaticOptions, setIsStaticOptions] = useState(true)
    const [isQuantity, setIsQuantity] = useState(1)
    const [isStaticQuantity, setIsStaticQuantity] = useState(1)
    const { handleChange, setFieldValue, values } = useFormikContext<FormData>()
    const [updatedProductsBeforeTrimming, setUpdatedProductsBeforeTrimming] = useState([{}]);
    const [isName, setIsName] = useState('');
    const [isAmount, setIsAmount] = useState(1);
    const [saleAmount, setSaleAmount] = useState<any>(0);
    const [statics, setStatic] = useState(true);
    const [quantityVariable, setQuantityVariable] = useState(false);
    const [quantityDisable, setQuantityDisable] = useState(false);
    const initial = useContext(initialContext);
    const [handleDelete, setHandleDelete] = useState(false);
    const formik = useFormikContext<FormData>()
    const businessUid = useAppSelector(state => state.meta.businessUid);

    const request = {
        "search":null,
        "page": 0,
        "limit": 1000
    }    

    // const countQuery = useQuery({
    //     queryKey: ['digital-products-count'],
    //     queryFn: () => designFlowServices.getDigitalCount(businessUid || '', {search: null, page: 0, limit: 1}),
    //     enabled: false
    // })

    // useEffect(() => {
    //     if(countQuery?.data)
    //         {
    //             setCount(countQuery?.data);
    //         }
    // },[countQuery?.data])

    const query = useQuery({
        queryKey: ['digital-products'],
        queryFn:  () => designFlowServices.getDigitalProducts(businessUid || '', request),
        enabled: false
    })
    const disableDynamicOptions = index > 0;
    const disableOrderBased = index > 0;
    const options = [
        { label: 'Select Product', value: 'static' },
        { label: 'Add new Product', value: 'dynamic' },
    ];

    // useEffect(() => {
    //     if (businessUid && !countQuery.isFetchedAfterMount && (!countQuery.data || initial.create)) {
    //         countQuery.refetch()
    //     }
    // }, [businessUid])
    useEffect(() => {
        if (businessUid && !query.isFetchedAfterMount && (!query.data || initial.create)) {
            query.refetch()
            initial.create=false;
        }
    }, [businessUid])
    useEffect(() => {
        if (!values.dynamic) {
            setIsDynamicProduct(formik.values.digitalItems[index].id === null)
            setIsStaticProduct(formik.values.digitalItems[index].id !== null)
        }
    }, [])

    useEffect(() => {
        if (!values.dynamic && handleDelete) {
            setIsDynamicProduct(formik.values.digitalItems[index].id === null)
            setIsStaticProduct(formik.values.digitalItems[index].id !== null)
        }
    }, [handleDelete])
    const incrementQuantity = () => {
        formik.setFieldValue(
            `digitalItems[${index}].quantity`,
            Math.max(Number((formik.values.digitalItems[index]?.quantity || 0))) + 1
        );
    };

    const decrementQuantity = () => {
        if (formik.values.digitalItems[index]?.quantity > 1) {
            formik.setFieldValue(
                `digitalItems[${index}].quantity`,
                Math.max((formik.values.digitalItems[index]?.quantity || 0) - 1, 0)
            );
        }
    };
    useEffect(() => {
        if (formik.values.ownFlowOrder) {
            setIsStaticOptions(false);
            setIsStaticProduct(false);
            setQuantityVariable(false);
            setQuantityDisable(false);
        }
    }, [formik.values.ownFlowOrder])

    useEffect(() => {

        if (initial.edit) {
            const updatedData = values?.digitalItems?.map(item => ({
                ...item,
                amount: !Number.isNaN(Number(item.amount) / 100) && item.id === null ? Number(item.amount) / 100 : item.amount,
                saleAmount: !Number.isNaN(Number(item.saleAmount) / 100) && item.id === null ? Number(item.saleAmount) / 100 : item.saleAmount
            }));
            setFieldValue('digitalItems', updatedData);
            setFieldValue('tax.amount', (values?.tax?.amount ? values?.tax?.amount / 100 : null));
            setFieldValue('shipping.amount', (values?.shipping?.amount ? values?.shipping?.amount / 100 : null));
            setFieldValue('discount.amount', (values?.discount?.amount ? values?.discount?.amount / 100 : null));
            initial.edit = false;
        }
    }, [initial.edit])

    useEffect(() => {

        if (!formik.values.digitalItems[index]?.quantity) {
            setQuantityDisable(false);
            formik.setFieldValue(String('digitalItems[' + index + '].quantity'), '1');
        }
    }, [formik.values.digitalItems[index]?.quantity])


    useEffect(() => {

        if (!values.digitalItems[index].saleAmount) {

            formik.setFieldValue(String('digitalItems[' + index + '].saleAmount'), null)
        }

    },[formik.values.digitalItems[index]?.saleAmount])

    const handleAddVariableClick = () => {
        formik.setFieldValue(String('digitalItems[' + index + '].name'), '');
    };

    const handleAmountClick = () => {
        formik.setFieldValue(String('digitalItems[' + index + '].amount'), '');
    };

    const handleSaleVariableClick = () => {
        formik.setFieldValue(String('digitalItems[' + index + '].saleAmount'), '');
    };

    const handleQuantityVariableClick = () => {

        formik.setFieldValue(String('digitalItems[' + index + '].quantity'), ' ');
        setQuantityVariable(true);
        setQuantityDisable(true);
    };

    useEffect(() => {

        if (isNaN(Number(formik.values.digitalItems[index]?.quantity)) && !isOrder) {
            setQuantityDisable(true);
            setQuantityVariable(true);
            formik.setFieldValue(String('digitalItems[' + index + '].quantity'), String(formik.values.digitalItems[index]?.quantity).trim());
        }

    }, [formik.values?.digitalItems[index]?.quantity])

    const handleDeleteProduct = (index: number) => {

        setHandleDelete(true);
        const updatedDigitalItems = [...formik.values.digitalItems];
        updatedDigitalItems.splice(index, 1);
        formik.setFieldValue('digitalItems', updatedDigitalItems);
        if (index === 0) {
            formik.setFieldValue('dynamicFields', { iterateVariable: { name: '', type: 'ARRAY' } });
        }
    };

    let formContent = <Spinner />

    if (!isDynamicProduct && isStaticProduct && !values.dynamic) {
        if (!query.isLoading && !query.isError) {
            formContent = (
                <Form.Group className='mb-3'>
                    <Form.Label>Product <span className='required'></span></Form.Label>
                    <ReactSelect
                        name={String('digitalItems[' + index + '].name')}
                        styles={{
                            menuPortal: base => ({ ...base, zIndex: 9800 }),
                            menu: base => ({ ...base, width: 465 })
                        }}
                        maxMenuHeight={200}
                        value={(() => {
                            if (formik.values.digitalItems[index].id) {
                                const myProduct = query?.data.find(p => p.id === formik.values.digitalItems[index].id)
                                if (myProduct) {
                                    return {
                                        label: myProduct?.name,
                                        value: myProduct?.id
                                    }
                                }
                            }
                            return ''
                        })()}
                        options={(() => {
                            if (Array.isArray(query.data)) {
                                return query.data.map(p => ({
                                    label: p?.name,
                                    value: p.id
                                }))
                            }
                            return []
                        })() as any}
                        onChange={(selected: any) => {
                            if (selected) {
                                const myProduct = query?.data.find(p => p.id === selected.value)
                                if (myProduct) {
                                    formik.setFieldValue(String('digitalItems[' + index + '].id'), myProduct.id || 0)
                                    formik.setFieldValue(String('digitalItems[' + index + '].name'), myProduct?.name)
                                    formik.setFieldValue(String('digitalItems[' + index + '].amount'), myProduct?.amount || 1)
                                    formik.setFieldValue(String('digitalItems[' + index + '].saleAmount'), myProduct?.saleAmount)
                                    formik.setFieldValue(String('digitalItems[' + index + '].uid'), myProduct?.uid)
                                    initial.quantity = formik.values.digitalItems[index]?.quantity;
                                    formik.setFieldValue(String('digitalItems[' + index + '].quantity'), initial?.quantity);
                                    initial.id = myProduct.id ?? 0;
                                }
                            }
                        }}
                    />
                    {formik.touched.digitalItems
                        && Array.isArray(formik.errors.digitalItems)
                        && (formik.errors as any).digitalItems[index]?.name
                        && initial.id === 0
                        && typeof (formik.errors as any).digitalItems[index]?.name === 'string' ? (
                        <div className='invalid-feedback d-block'>
                            {(formik.errors as any).digitalItems[index]?.name}
                        </div>
                    ) : null}
                </Form.Group>
            )
        } else {
            formContent = <Alert color='danger'>{SOMETHING_WENT_WRONG}</Alert>
        }
    } else if (isDynamicProduct && !values.dynamic) {
        formContent = (
            <>
                <Form.Group className='mb-3'>
                    <Form.Label>Product Name <span className='required'></span></Form.Label>
                    <Form.Control
                        value={formik.values.digitalItems[index]?.name}
                        name={String('digitalItems[' + index + '].name')}
                        onChange={formik.handleChange}
                        placeholder='Enter product name'
                    />
                    <div>
                        <AddVariable static fieldName={String('digitalItems[' + index + '].name')} fieldValue={formik.values.digitalItems[index]?.name} onClick={handleAddVariableClick} />
                    </div>
                    {formik.touched.digitalItems
                        && Array.isArray(formik.errors.digitalItems)
                        && (formik.errors as any).digitalItems[index]?.name
                        && formik.values.digitalItems[index].id === null
                        && typeof (formik.errors as any).digitalItems[index]?.name === 'string' ? (
                        <div className='invalid-feedback d-block'>
                            {(formik.errors as any).digitalItems[index]?.name}
                        </div>
                    ) : null}
                </Form.Group>
                <Form.Group className='mb-3'>
                    <Form.Label>
                        <div>Amount <span className='required'></span></div>
                        <div><small>Amount variable should be in rupees and of type number</small></div>
                    </Form.Label>
                    <Form.Control
                        type='text'
                        placeholder="Enter the amount"
                        value={formik.values.digitalItems[index]?.amount}
                        name={String('digitalItems[' + index + '].amount')}
                        onChange={handleChange}
                        onKeyDown={(event) => {
                            const validKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Control', 'Unidentified', '-', '.'];
                            if (!/[0-9]/.test(event.key) && !validKeys.includes(event.key)) {
                                event.preventDefault();
                            } else if (event.key === '.' && event.currentTarget.value.includes('.')) {
                                event.preventDefault();
                            }
                            else if (event.key === '0' && (event.currentTarget.value === '' || event.currentTarget.value === '0')) {
                                event.preventDefault();
                            }
                        }}
                    />
                    <div>
                        <AddVariable static onClick={handleAmountClick} fieldName={String('digitalItems[' + index + '].amount')} fieldValue={String(formik.values.digitalItems[index]?.amount)} />
                    </div>
                    {formik.touched.digitalItems
                        && Array.isArray(formik.errors.digitalItems)
                        && (formik.errors as any).digitalItems[index]?.amount
                        && typeof (formik.errors as any).digitalItems[index]?.amount === 'string' ? (
                        <div className='invalid-feedback d-block'>
                            {(formik.errors as any).digitalItems[index]?.amount}
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group className='mb-3'>
                    <Form.Label> <div> Sale Amount <span className='dimmed'>(Optional)</span> </div>
                        <div><small>Sale amount should be less than amount and of type number</small></div>
                    </Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='Enter the sale amount'
                        value={formik.values.digitalItems[index]?.saleAmount ?? ''} // Display rupees
                        name={String('digitalItems[' + index + '].saleAmount')}
                        onChange={handleChange}
                        onKeyDown={(event) => {
                            const validKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Control', 'Unidentified', '-', '.'];
                            if (!/[0-9]/.test(event.key) && !validKeys.includes(event.key)) {
                                event.preventDefault();
                            } else if (event.key === '.' && event.currentTarget.value.includes('.')) {
                                event.preventDefault();
                            } else if (event.key === '0' && (event.currentTarget.value === '' || event.currentTarget.value === '0')) {
                                event.preventDefault();
                            }
                        }}
                    />
                    <div>
                        <AddVariable onClick={() => handleSaleVariableClick()} static fieldName={String('digitalItems[' + index + '].saleAmount')} fieldValue={String(formik.values.digitalItems[index]?.saleAmount)} />
                    </div>
                    {formik.touched.digitalItems
                        && Array.isArray(formik.errors.digitalItems)
                        && (formik.errors as any).digitalItems[index]?.saleAmount
                        && typeof (formik.errors as any).digitalItems[index]?.saleAmount === 'string' ? (
                        <div className='invalid-feedback d-block'>
                            {(formik.errors as any).digitalItems[index]?.saleAmount}
                        </div>
                    ) : null}
                </Form.Group>

            </>
        )
    }

    else if (values.dynamic === true) {

        formContent = (
            <div className={styles.optionBox} style={{ marginTop: "-10px" }}>
                <VariableInputLabel
                    label='Variable To Iterate'
                    required>
                    <SelectVariable
                        placeholder='Create or Select variable'
                        name='dynamicFields.iterateVariable'
                        value={String(values.dynamicFields?.iterateVariable?.name)}
                        onChange={handleChange}
                        type='ARRAY'
                    />
                </VariableInputLabel>
                {formik.touched.dynamicFields
                    && formik.touched.dynamicFields.iterateVariable
                    && formik.errors.dynamicFields
                    && formik.errors.dynamicFields.iterateVariable
                    && typeof formik.errors.dynamicFields.iterateVariable === 'object' ? (
                    <div className='invalid-feedback d-block'>
                        {formik.errors.dynamicFields.iterateVariable.name}
                    </div>
                ) : null}

                <Form.Group className='my-3'>
                    <Form.Label>Product Name Path <span className='required'></span></Form.Label>
                    <Form.Control
                        name='dynamicFields.productNamePath'
                        value={values.dynamicFields?.productNamePath || ''}
                        placeholder='Enter product name path'
                        onChange={handleChange}
                    />
                    {formik.touched.dynamicFields &&
                        formik.errors.dynamicFields && (
                            <div className='invalid-feedback d-block'>
                                {formik.errors.dynamicFields.productNamePath}
                            </div>
                        )}
                </Form.Group>

                <Form.Group className='my-3'>
                    <Form.Label> Amount Path <span className='required'></span></Form.Label>
                    <Form.Control
                        name='dynamicFields.amountPath'
                        value={values.dynamicFields?.amountPath || ''}
                        placeholder='Enter amount path'
                        onChange={handleChange}
                    />
                    {formik.touched.dynamicFields
                        && formik.errors.dynamicFields
                        && formik.errors.dynamicFields.amountPath
                        ? (
                            <div className='invalid-feedback d-block'>
                                {formik.errors.dynamicFields.amountPath}
                            </div>
                        ) : null}
                </Form.Group>

                <Form.Group className='my-3'>
                    <Form.Label> Sale Amount Path </Form.Label>
                    <Form.Control
                        name='dynamicFields.saleAmountPath'
                        value={values.dynamicFields?.saleAmountPath || ''}
                        placeholder='Enter sale amount path'
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group className='my-3'>
                    <Form.Label> Quantity Path <span className='required'></span></Form.Label>
                    <Form.Control
                        name='dynamicFields.quantityPath'
                        value={values.dynamicFields?.quantityPath || ''}
                        placeholder='Enter quantity path'
                        onChange={handleChange}
                    />
                    {formik.touched.dynamicFields
                        && formik.errors.dynamicFields
                        && formik.errors.dynamicFields.quantityPath
                        && typeof formik.errors.dynamicFields.quantityPath === 'string' ? (
                        <div className='invalid-feedback d-block'>
                            {formik.errors.dynamicFields.quantityPath}
                        </div>
                    ) : null}
                </Form.Group>


            </div>
        )

    }

    else {
        formContent = (
            <div>
            </div>)
    }
    return (
        <div className={styles.productItem} style={{ position: "relative" }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
                style={{ position: "absolute", top: 0, right: 10, marginTop: "15px", cursor: "pointer", color: "red" }}
                onClick={() => { handleDeleteProduct(index) }}
            >
                {" "}
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />{" "}
                <path
                    fill-rule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                />{" "}
            </svg>
            <Form.Group className='mb-3'>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Form.Check
                        inline
                        name={String('digitalItems[' + index + '].id')}
                        type='radio'
                        checked={isStaticOptions && !values.dynamic}
                        onChange={(event) => {
                            formik.setFieldValue('dynamic', false);
                            setIsStaticOptions(true);
                            setIsStaticProduct(true);
                            setFieldValue('digitalItems', updatedProductsBeforeTrimming);
                            formik.setFieldValue('ownFlowOrder', false);
                        }}
                    />
                    <Label style={{ marginLeft: "-17px", marginBottom: "4px", marginRight: "30px" }}>Static</Label>
                    {!disableDynamicOptions && (
                        <>
                            <Form.Check
                                inline
                                name='dynamic'
                                type='radio'
                                checked={formik.values.dynamic}
                                onChange={event => {
                                    formik.setFieldValue('dynamic', event.target.checked);
                                    if (event.target.checked) {
                                        setIsStaticProduct(false);
                                        setIsStaticOptions(false);
                                        const updatedProduct = [...values.digitalItems];
                                        setUpdatedProductsBeforeTrimming(updatedProduct);
                                        const updatedProducts = [values.digitalItems[0]];
                                        setFieldValue('digitalItems', updatedProducts);
                                        formik.setFieldValue('ownFlowOrder', false);
                                    }
                                }}
                                disabled={disableDynamicOptions}
                            />
                            <Label style={{ marginLeft: "-17px", marginBottom: "4px", marginRight: "30px" }}>Dynamic</Label>
                        </>
                    )}
                    {isOrder && !disableOrderBased && (
                        <>
                            <Form.Check
                                inline
                                name="ownFlowOrder"
                                type='radio'
                                checked={formik.values.ownFlowOrder}
                                onChange={event => {
                                    if (event.target.checked) {
                                        setIsStaticProduct(false);
                                        setIsStaticOptions(false);
                                        formik.setFieldValue('dynamic', false);
                                        formik.setFieldValue('ownFlowOrder', true);
                                        const updatedProduct = [...values.digitalItems];
                                        setUpdatedProductsBeforeTrimming(updatedProduct);
                                        const updatedProducts = [values.digitalItems[0]];
                                        setFieldValue('digitalItems', updatedProducts);
                                        setIsDynamicProduct(false);
                                    }
                                }}
                            />
                            <Label style={{marginBottom: "4px", marginLeft: "-15px"}}>Order Based</Label>
                        </>
                    )}
                </div>
            </Form.Group>
              
            {isStaticOptions && !values.dynamic && (
                <div style={{ marginBottom: "10px", marginTop: "-10px" }}>

                    <ReactSelect
                        styles={{
                            menuPortal: base => ({ ...base, zIndex: 9800 }),
                            menu: base => ({ ...base, width: 150 }),
                            control: base => ({ ...base, minheight: 20, width: 150 }),
                        }}
                        value={isDynamicProduct ? options[1] : isStaticProduct ? options[0] : null}
                        options={options}
                        onChange={(selectedOption: { label: string; value: string } | null) => {
                            if (selectedOption && selectedOption.value === 'static') {
                                setIsStaticProduct(true);
                                setIsDynamicProduct(false);
                                setQuantityDisable(false);
                                setIsAmount(Number(formik.values.digitalItems[index]?.amount));
                                setIsName(formik.values.digitalItems[index]?.name);
                                setSaleAmount(formik.values.digitalItems[index]?.saleAmount);
                                setQuantityVariable(false);
                                if (statics === false || quantityVariable === true) {
                                    setIsQuantity(formik.values.digitalItems[index]?.quantity);
                                    formik.setFieldValue(String('digitalItems[' + index + '].quantity'), isStaticQuantity);
                                    setIsStaticQuantity(formik.values.digitalItems[index]?.quantity);
                                }
                                setStatic(true);
                                initial.id = 0;
                                formik.setFieldValue(String('digitalItems[' + index + '].name'), undefined)
                            } else if (selectedOption && selectedOption.value === 'dynamic') {
                                setIsStaticProduct(false);
                                setIsDynamicProduct(true);
                                if (statics === true) {
                                    setIsStaticQuantity(formik.values.digitalItems[index]?.quantity);
                                }
                                formik.setFieldValue(`digitalItems[${index}].id`, null);
                                formik.setFieldValue('dynamic', false);
                                if (statics === true) {
                                    formik.setFieldValue(String('digitalItems[' + index + '].quantity'), isQuantity);
                                    formik.setFieldValue(String('digitalItems[' + index + '].name'), isName)
                                    formik.setFieldValue(String('digitalItems[' + index + '].amount'), isAmount)
                                    formik.setFieldValue(String('digitalItems[' + index + '].saleAmount'), saleAmount)
                                }
                                setStatic(false)
                                initial.id = -1;
                                formik.setFieldValue(String('digitalItems[' + index + '].name'), isName)
                            }
                        }}
                    />
                </div>
            )}

            {formContent}
            {(isStaticProduct || isDynamicProduct) && !values.dynamic && (
                <>
                    <Form.Group className='mb-3'>
                        <Form.Label> <div>Quantity <span className='required'></span></div>
                            {isDynamicProduct && (
                                <div><small>Quantity variable should be of type number</small></div>
                            )}
                        </Form.Label>

                        <div className="input-group" >
                            <div className={`${quantityDisable ? styles.disabled : ''}`}>
                                <input type="button" value="-" className="button-minus" onClick={decrementQuantity} data-field="quantity" />
                                <Field type="number" name={String('digitalItems[' + index + '].quantity')} step="1" min="1" max="" value={isNaN(Number(formik.values.digitalItems[index]?.quantity)) ? "0" : formik.values.digitalItems[index]?.quantity} className={styles.quantity} />
                                <input type="button" value="+" className="button-plus" onClick={incrementQuantity} data-field="quantity" />
                            </div>
                        </div>

                        {quantityVariable && !isOrder && (
                            <div style={{ marginTop: "10px" }}>
                                <Form.Control
                                    name={String('digitalItems[' + index + '].quantity')}
                                    value={isNaN(Number(formik.values.digitalItems[index]?.quantity)) ? formik.values.digitalItems[index]?.quantity : ""}
                                    onKeyDown={(e) => {
                                        if (e.keyCode !== 8) {
                                            e.preventDefault();
                                        }
                                    }}
                                    onChange={handleChange}
                                    disabled={!quantityDisable}
                                />
                            </div>
                        )}

                        {(isDynamicProduct) && (
                            <div id={styles.transition}>
                                <AddVariable onClick={() => handleQuantityVariableClick()} static fieldName={String('digitalItems[' + index + '].quantity')} fieldValue={String(formik.values.digitalItems[index]?.quantity)} />
                            </div>
                        )}
                        {formik.touched.digitalItems
                            && Array.isArray(formik.errors.digitalItems)
                            && (formik.errors as any).digitalItems[index]?.quantity
                            && typeof (formik.errors as any).digitalItems[index]?.quantity === 'string' ? (
                            <div className='invalid-feedback d-block'>
                                {(formik.errors as any).digitalItems[index]?.quantity}
                            </div>
                        ) : null}
                    </Form.Group>
                </>
            )
            }
        </div>
    )
}

const SendOrderDetails: React.FC<IEditorProps> = props => {
    const [formData, setFormData] = useState<FormData>({

        bodyText: '',
        footerText: null,
        paymentConfig: null,
        digitalItems: [],
        expiringDuration: '300000',
        attempt: 1,
        errorMessage: '',
        endFlow: false,
        dynamicFields: {
            iterateVariable: { name: '', type: 'ARRAY' },
            productNamePath: null,
            amountPath: null,
            quantityPath: null,
            saleAmountPath: null
        },
        dynamic: false,
        media: null,
        mediaType: 'image',
        mediaUrl: '',
        headerType: null,
        tax: {
            amount: null,
            description: null,
            discountProgramName: null
        },
        shipping: {
            amount: null,
            description: null,
            discountProgramName: null
        },
        discount: {
            amount: null,
            description: null,
            discountProgramName: null
        },
        isSameFlow: false,
        ownFlowOrder: false
    })
    const { init, saveElementChanges } = useElmentEditor({
        type: 'send_OrderDetails',
        data: formData
    }, props)
    useEffect(() => init(setFormData), [])
    const initial = useContext(initialContext);

    useEffect(() => {
        if (formData.isSameFlow) {
            setIsAdvancedVisible(formData.isSameFlow)
        }
    }, [formData.isSameFlow])

    const [isOrderBased, setIsOrderBased] = useState(false);

    const schema = yup.object().shape({
        headerType: yup.string().nullable(),
        headerText: yup.string().nullable(),
        errorMessage: yup.string().notRequired(),
        attempt: yup.number().min(1, 'Attempt should be minimum 1').required('Attempt is a required'),
        footerText: yup.string().nullable(),
        bodyText: yup.string().required('Body is required').test('is-double-space', 'Body is required', value => value !== '  '),
        paymentConfig: yup.object().required('Payment Method is required'),
        digitalItems: yup.array().of(
            yup.object().shape({
                id: yup.number().nullable(),
                name: yup.string().when(['$dynamic', '$ownFlowOrder'], {
                    is: (dynamic: any, ownFlowOrder: any) => !dynamic && !ownFlowOrder,
                    then: yup.string().test('conditional-name-test', '', function (value) {
                        const id = this.parent.id;
                        if (initial.id === 0 && value === undefined) {
                            return this.createError({ message: 'Product is required' });
                        } else if (initial.id === -1 && !value) {
                            return this.createError({ message: 'Product Name is required' });
                        }
                        return true;
                    }),
                    otherwise: yup.string(),
                }),
                amount: yup.mixed().when(['$dynamic', 'id'], {
                    is: (dynamic: any, id: any) => !dynamic && id == null,
                    then: yup.string().test('is-nonzero-number', 'Amount must be greater than zero', function (value) {
                        const numericValue = Number(value);
                        if (numericValue === 0) {
                            return false;
                        }
                        return true;
                    }).required('Amount is required'),
                    otherwise: yup.string()
                }),
                saleAmount: yup.mixed().when(['$dynamic', 'id'], {
                    is: (dynamic: any, id: any) => !dynamic && id == null,
                    then: yup.string().test('is-less-than-amount', 'Sale Amount must be less than Amount', function (value) {
                        const amount = this.parent.amount;
                        const saleAmount = Number(value);
                        if (isNaN(amount) || isNaN(saleAmount)) {
                            return true;
                        }
                        return saleAmount < amount;
                    }).nullable(),
                    otherwise: yup.string().nullable()
                })
            })
        ).when(['$dynamic', '$ownFlowOrder'], {
            is: (dynamic: any, ownFlowOrder: any) => !dynamic && !ownFlowOrder,
            then: yup.array().min(1, 'At least 1 Product is required'),
            otherwise: yup.array().notRequired(),
        }),
        expiringDuration: yup.string()
            .test('is-number', 'Invalid Duration', (value) => {
                if (value !== undefined && value.trim() !== '') {
                    return !isNaN(parseFloat(value));
                }
                return false;
            })
            .test('min-duration', 'Duration should be at least 5 minutes', (value) => {
                const durationInMilliseconds = parseFloat(value ?? '0');
                const fiveMinutesInMilliseconds = 5 * 60 * 1000;
                return durationInMilliseconds >= fiveMinutesInMilliseconds;
            })
            .required('Duration is a required'),
        dynamic: yup.boolean(),
        ownFlowOrder: yup.boolean(),
        dynamicFields: yup.object().shape({
            iterateVariable: yup.object().shape({
                name: yup.string().when('$dynamic', {
                    is: true,
                    then: yup.string().required('Variable to iterate is required').nullable(),
                    otherwise: yup.string().nullable(),
                }),
            }),
            productNamePath: yup.string().when('$dynamic', {
                is: true,
                then: yup.string().required('Product name path is required').nullable(),
                otherwise: yup.string().nullable(),
            }),
            amountPath: yup.string().when('$dynamic', {
                is: true,
                then: yup.string().required('Amount path is required').nullable(),
                otherwise: yup.string().nullable(),
            }),
            quantityPath: yup.string().when('$dynamic', {
                is: true,
                then: yup.string().required('Quantity path is required').nullable(),
                otherwise: yup.string().nullable(),
            }),
            saleAmountPath: yup.string().nullable()
        }),
        tax: yup.object().nullable(),
    })

    const allowedFormats = ['image/jpeg', 'image/png'];
    const [check, setCheck] = useState(false);

    const removeFileHandler = async (media: any) => {
        try {
            await mediaService.deleteMedia(media);
        } catch (error) {
            console.error(error);
        }
    }

    const [isTaxVisible, setIsTaxVisible] = useState(false);
    const [isShippingVisible, setIsShippingVisible] = useState(false);
    const [isDiscountVisible, setIsDiscountVisible] = useState(false);
    const [isAdvancedVisible, setIsAdvancedVisible] = useState(false);

    const toggleTaxForm = (value: string) => {
        value === "tax" && setIsTaxVisible(!isTaxVisible);
        value === "shipping" && setIsShippingVisible(!isShippingVisible);
        value === "discount" && setIsDiscountVisible(!isDiscountVisible);
    };

    const { flowId } = useParams<{ flowId: string }>();

    const loadFlow = async (flowId: string) => {
        try {
            const response = await axios.get(`bot/flow/${flowId}/getElements`);
            const order = response.data.flowType === 'order';
            setIsOrderBased(order);
            return response.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    useEffect(() => {
        if (flowId) {
            loadFlow(flowId);
        }
    }, [flowId])

    return (
        <Formik
            validationSchema={schema}
            onSubmit={(values, actions) => {
                saveElementChanges(values);
            }}
            initialValues={formData}
        >
            {({ handleSubmit, handleChange, values, touched, errors, setValues, setFieldValue }) => {
                useEffect(() => {
                    setValues(formData)
                }, [formData, setValues])


                useEffect(() => {
                    if (formData.ownFlowOrder) {
                        setIsOrderBased(true);
                        setFieldValue('digitalItems', [{  id: 0,
                            uid: null,
                            name: '',
                            amount: 1,
                            quantity: 1,
                            saleAmount: 0}]);
                    }
                }, [formData.ownFlowOrder])

                useEffect(() => {
                    if (values.isSameFlow) {
                        if (values.tax) {
                            setIsTaxVisible(true);
                        }
                        if (values.shipping) {
                            setIsShippingVisible(true);
                        }
                        if (values.discount) {
                            setIsDiscountVisible(true);
                        }
                    }
                }, [values.isSameFlow]);

                const addProductHandler = () => {
                    const updatedProducts = [...values.digitalItems]
                    updatedProducts.push({
                        id: 0,
                        uid: null,
                        name: '',
                        amount: 1,
                        quantity: 1,
                        saleAmount: 0
                    })
                    setFieldValue('digitalItems', updatedProducts)
                    setFieldValue('dynamic', false);
                    initial.addProduct = true;
                    initial.id = 0;
                }

                const isError = (values: any, errors: any) => {
                    if (errors !== undefined) {
                        for (let i = 0; i < errors?.length; i++) {
                            if (errors[i] !== undefined) {
                                if (errors[i]?.name !== 'Product is required') {
                                    return false;
                                }
                                if (values[i] && initial.id === 0) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                };

                const handleSubmission = () => {

                        if (Object.keys(errors)?.length === 0 && isError(values.digitalItems, errors.digitalItems)) {
                            const updatedData = values.digitalItems.map(item => ({
                                ...item,
                                amount: !Number.isNaN(Number(item.amount) * 100) && item.id === null ? Number(item.amount) * 100 : item.amount,
                                saleAmount: !Number.isNaN(Number(item.saleAmount) * 100) && item.id === null ? Number(item.saleAmount) * 100 : item.saleAmount,
                                quantity: String(item.quantity).trim()
                            }));
                            setFieldValue('digitalItems', updatedData);
                        }

                        setFieldValue('isSameFlow', isAdvancedVisible);


                        if (!values?.tax?.amount && !values?.shipping?.amount && !values?.discount?.amount) {
                            setFieldValue('isSameFlow', false);
                        }

                        if (Object.keys(errors)?.length === 0) {

                            if (values?.tax?.amount) {
                                setFieldValue('tax.amount', (values?.tax?.amount ?? 0) * 100);
                            }

                            if (values?.shipping?.amount) {
                                setFieldValue('shipping.amount', (values?.shipping?.amount ?? 0) * 100);
                            }

                            if (values?.discount?.amount) {
                                setFieldValue('discount.amount', (values?.discount?.amount ?? 0) * 100);
                            }

                        }

                        if (!values.dynamic) {
                            setFieldValue('dynamicFields', { iterateVariable: { name: '', type: 'ARRAY' }, productNamePath: null, amountPath: null, quantityPath: null })
                        }

                        if ( !values?.tax?.amount && !values?.tax?.description) {
                            setFieldValue('tax', null);
                        }

                        if (!values?.shipping?.amount && !values?.shipping?.description) {
                            setFieldValue('shipping', null);
                        }

                        if (!values?.discount?.amount && !values?.discount?.description && !values?.discount?.discountProgramName) {
                            setFieldValue('discount', null);
                        }

                        if (values.ownFlowOrder) {
                            setFieldValue('digitalItems', []);
                        }

                }

                useEffect(() => {
                    if (!values?.tax?.amount && values?.tax?.description) {
                        setFieldValue('tax.description', null);
                    }
                }, [values?.tax?.amount])

                useEffect(() => {
                    if (!values?.shipping?.amount && values?.shipping?.description) {
                        setFieldValue('shipping.description', null);
                    }
                }, [values?.shipping?.amount])

                useEffect(() => {
                    if (!values?.discount?.amount && values?.discount?.description ) {
                        setFieldValue('discount.description', null);
                        setFieldValue('discount.discountProgramName', null);
                    }
                }, [values?.discount?.amount])

                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <EditorCaption onHide={props.onClose} caption='Send Payments' icon={<img style={{ width: 16 }} alt='' src={sendProductIcon} />} />
                        <Offcanvas.Body>
                            <SelectPaymentMethod />
                            <VariableInputLabel label="Duration" required>
                                <DelayInput
                                    name='expiringDuration'
                                    value={String(values.expiringDuration)}
                                    isInvalid={(touched.expiringDuration && errors.expiringDuration) ? true : false}
                                    isValid={touched.expiringDuration && !errors.expiringDuration}
                                    error={errors?.expiringDuration}
                                />
                            </VariableInputLabel>
                            <Form.Group className='mb-3' style={{ position: "relative" }}>
                                <Form.Label name="headerType" value={values.headerType}>Header <span className='dimmed'>(Optional)</span></Form.Label>
                                {values.media === null ? (
                                    <FileInput
                                        mediaType={values.mediaType}
                                        acceptType={allowedFormats.join(',')}
                                        setFile={(media) => {
                                            setFieldValue('media', media);
                                            setFieldValue('mediaType', 'image')
                                        }}
                                        mediaUrl={values.mediaUrl}
                                        media={values.media}
                                        type='sendPayments' />
                                ) : (
                                    <>

                                        <div style={{ display: 'flex', alignItems: 'end' }}>
                                            <FilePlaceholder
                                                size='medium'
                                                mediaType={values?.mediaType}
                                                fileName={values?.media?.fileName}
                                                url={values?.media?.url}
                                            />
                                            <button onClick={() => {
                                                removeFileHandler(values?.media);
                                                setFieldValue('media', null);
                                            }} id={styles.deleteButton}>
                                                <BsFillTrashFill id={styles.icon} />
                                            </button>
                                        </div>
                                    </>
                                )}
                                {(touched.media && (errors.media && typeof errors.media) === 'string') ? (
                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        {errors.media}
                                    </div>
                                ) : null}

                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <BodyInput
                                    name='bodyText'
                                    value={values.bodyText}
                                    isInvalid={(touched.bodyText && errors.bodyText) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched.bodyText && !errors.bodyText}
                                    error={errors.bodyText}
                                    label='Body'
                                    required={true}
                                    limit={1024}
                                />
                                {(touched.bodyText && errors.bodyText) ? (
                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        {errors.bodyText}
                                    </div>
                                ) : null}
                            </Form.Group>
                            <Form.Group className='mb-3' style={{ position: "relative" }}>
                                <Form.Label>Footer <span className='dimmed'>(Optional)</span></Form.Label>
                                <Form.Control
                                    name='footerText'
                                    placeholder='Enter the footer text'
                                    value={values.footerText ?? ""}
                                    isInvalid={(touched.footerText && errors.footerText) ? true : false}
                                    onChange={handleChange}
                                    isValid={touched.footerText && !errors.footerText}
                                    maxLength={60}
                                />
                                <div style={{
                                    position: 'absolute',
                                    right: 0,
                                    bottom: -20,
                                    fontSize: '.8rem',
                                    fontWeight: 400,
                                    color: '#A3A3A3',
                                    padding: '1px 3px'
                                }}>{values?.footerText?.length}/60
                                </div>
                                <Form.Control.Feedback type='invalid'>
                                    {errors.footerText}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label>Order Products <span className='required'></span></Form.Label>
                                <div>
                                    {values.digitalItems.map((p, index) => (

                                        <ProductItem
                                            key={p.uid}
                                            uid={p.uid || null}
                                            index={index}
                                            isOrder={isOrderBased}
                                        />
                                    ))}

                                    {!values.dynamic && (!values.ownFlowOrder || values.digitalItems.length === 0) && (
                                        <Button
                                            onClick={addProductHandler}
                                            size='sm'
                                            className='addButton'>
                                            <FontAwesomeIcon icon={faAdd} />
                                        </Button>
                                    )}
                                </div>
                                {(touched.digitalItems
                                    && errors.digitalItems
                                    && typeof errors.digitalItems === 'string') ? (
                                    <div className='invalid-feedback' style={{ display: 'block' }}>
                                        {errors.digitalItems}
                                    </div>
                                ) : null}
                            </Form.Group>

                            <Form.Group className='mb-3'>
                                <Form.Group className='mb-3'>
                                    <div className="border p-2 rounded" id={styles.advancedBg}>
                                        <Form.Label className="d-flex align-items-center mb-0 justify-content-between">
                                            <div>
                                                Advanced <span className='dimmed'>(Optional)</span>
                                            </div>
                                            <div>
                                                <Form.Check
                                                    type="switch"
                                                    id="advanced-options-switch"
                                                    checked={isAdvancedVisible}
                                                    onChange={(event) => { setIsAdvancedVisible(!isAdvancedVisible); }}
                                                />
                                            </div>
                                        </Form.Label>
                                        {isAdvancedVisible && (
                                            <> <br></br><Form.Group className="mb-3">
                                                <div>
                                                    <div className={styles.dropdown}>
                                                        <button type="button" className={styles.dropbtn} onClick={() => { setIsTaxVisible(!isTaxVisible) }}>
                                                            Tax  <span className={styles.taxArrow}>{isTaxVisible ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</span>
                                                        </button>
                                                        {isTaxVisible && (
                                                            <div className={styles.dropdownContent}>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label>Tax Amount</Form.Label>
                                                                        <Form.Control
                                                                            name='tax.amount'
                                                                            type='text'
                                                                            placeholder='Enter the tax amount'
                                                                            onChange={handleChange}
                                                                            value={values?.tax?.amount ?? ''}
                                                                            onKeyDown={(event) => {
                                                                                const validKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Control', 'Unidentified']
                                                                                if (!/[0-9.]/.test(event.key) && !validKeys.includes(event.key)) {
                                                                                    event.preventDefault()
                                                                                } else if (event.key === '.' && event.currentTarget.value.includes('.')) {
                                                                                    event.preventDefault()
                                                                                }
                                                                            }} />
                                                                    </Form.Group>
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label>Tax Description</Form.Label>
                                                                        <Form.Control
                                                                            placeholder='Enter the tax description'
                                                                            name='tax.description'
                                                                            onChange={handleChange}
                                                                            value={values.tax?.description ?? ''}
                                                                            disabled={((values.tax?.amount ?? 0) <= 0) || (String(values?.tax?.amount) === "")} />
                                                                    </Form.Group>
                                                                </Form.Group>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                            </Form.Group><br></br><Form.Group className="mb-3">
                                                    <div>
                                                        <div className={styles.dropdown}>
                                                            <button type="button" className={styles.dropbtn} onClick={() => toggleTaxForm("shipping")}>
                                                                Shipping  <span className={styles.arrow}>{isShippingVisible ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</span>
                                                            </button>
                                                            {isShippingVisible && (
                                                                <div className={styles.dropdownContent}>
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label>Shipping Amount</Form.Label>
                                                                        <Form.Control
                                                                            placeholder='Enter the shipping amount'
                                                                            name='shipping.amount'
                                                                            onChange={handleChange}
                                                                            value={values?.shipping?.amount ?? ''}
                                                                            onKeyDown={(event) => {
                                                                                const validKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Control', 'Unidentified']
                                                                                if (!/[0-9.]/.test(event.key) && !validKeys.includes(event.key)) {
                                                                                    event.preventDefault()
                                                                                } else if (event.key === '.' && event.currentTarget.value.includes('.')) {
                                                                                    // Prevent multiple decimal points
                                                                                    event.preventDefault()
                                                                                }
                                                                            }} />
                                                                    </Form.Group>
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label>Shipping Description</Form.Label>
                                                                        <Form.Control
                                                                            placeholder='Enter the shipping description'
                                                                            name='shipping.description'
                                                                            onChange={handleChange}
                                                                            value={values.shipping?.description ?? ''}
                                                                            disabled={(values.shipping?.amount ?? 0) <= 0} />
                                                                    </Form.Group>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Form.Group><br></br><Form.Group className="mb-3">
                                                    <div>
                                                        <div className={styles.dropdown}>
                                                            <button type="button" className={styles.dropbtn} onClick={() => toggleTaxForm("discount")}>
                                                                Discount  <span className={styles.arrow}>{isDiscountVisible ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</span>
                                                            </button>
                                                            {isDiscountVisible && (
                                                                <div className={styles.dropdownContent}>
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label>Discount Amount</Form.Label>
                                                                        <Form.Control
                                                                            placeholder='Enter the discount amount'
                                                                            name='discount.amount'
                                                                            onChange={handleChange}
                                                                            value={values?.discount?.amount ?? ''}
                                                                            onKeyDown={(event) => {
                                                                                const validKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Control', 'Unidentified']
                                                                                if (!/[0-9.]/.test(event.key) && !validKeys.includes(event.key)) {
                                                                                    event.preventDefault()
                                                                                } else if (event.key === '.' && event.currentTarget.value.includes('.')) {
                                                                                    // Prevent multiple decimal points
                                                                                    event.preventDefault()
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Form.Group>
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label>Discount Description</Form.Label>
                                                                        <Form.Control
                                                                            placeholder='Enter the discount description'
                                                                            name='discount.description'
                                                                            onChange={handleChange}
                                                                            value={values.discount?.description ?? ''}
                                                                            disabled={(values.discount?.amount ?? 0) <= 0} />
                                                                    </Form.Group>
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label>Discount Program Name</Form.Label>
                                                                        <Form.Control
                                                                            placeholder='Enter the discount program name'
                                                                            name='discount.discountProgramName'
                                                                            onChange={handleChange}
                                                                            value={values.discount?.discountProgramName ?? ''}
                                                                            disabled={(values.discount?.amount ?? 0) <= 0} />
                                                                    </Form.Group>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Form.Group></>
                                        )}
                                    </div>
                                </Form.Group>
                            </Form.Group>
                        </Offcanvas.Body>
                        <div className="editor-footer">
                            <Button variant='outline-dark' onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button className='sendButton' type='submit' onClick={handleSubmission}>
                                Save
                            </Button>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default SendOrderDetails
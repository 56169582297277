import Avatar from "react-avatar";
import { CiCircleInfo } from "react-icons/ci";
import "../styles.scss";
import React from "react";
import moment from "moment";
import { MdOutlineSubject } from "react-icons/md";
import { RiTeamFill } from "react-icons/ri";
import { IoPerson, IoTicketOutline } from "react-icons/io5";

interface ticketCreatedProps {
  ticketdata: any;
}

const TicketCreatedComponent: React.FC<ticketCreatedProps> = (props) => {
  const createdAt = moment(props?.ticketdata?.createdAt);
  const now = moment();
  const duration = moment.duration(now.diff(createdAt));
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();

  let timeDifference;
  if (now.isSame(createdAt, "day")) {
    const hours = now.diff(createdAt, "hours");
    const minutes = now.diff(createdAt, "minutes") % 60;
    timeDifference = `${hours}h ago`;
  } else {
    const days = now.diff(createdAt, "days");
    timeDifference = `${days}d ago`;
  }

  const ticketDescription = props?.ticketdata?.fields?.filter(
    (f: any) => f.type === "description"
  );

  const ticketTeam = props?.ticketdata?.fields?.filter(
    (f: any) => f.type === "team"
  );

  const ticketMember = props?.ticketdata?.fields?.filter(
    (f: any) => f.type === "member"
  );

  const numberMask = localStorage.getItem('numberMask') === 'true';
  return (
    <>
      {props.ticketdata && (
        <div className="ticket-created">
          <div className="ticket-created-header">
            <div className="mail">
              <div>
                <span>
                  <Avatar
                    fgColor="#fff"
                    name={props.ticketdata?.contact?.name}
                    round
                    size="25"
                    textSizeRatio={2.5}
                  />
                </span>
              </div>
              <div className="name">
                <span>{props.ticketdata?.contact?.name}</span>
              </div>
              {props.ticketdata?.contact?.mobileNumber && (
                <div  className={`${numberMask ? "numberMasking_Blur" :'mail-id'}`}  >
                  {`<${
                    "+" +
                    props.ticketdata?.contact?.countryCode  +
                    props.ticketdata?.contact?.mobileNumber.slice(
                      props.ticketdata?.contact?.countryCode?.length
                    )
                  }>`}
                </div>
              )}
            </div>
            <div className="mail-received">{timeDifference}</div>
          </div>

          <div className="mb-2 d-flex align-items-center">
            {/* <span>
              <Avatar
                fgColor="#fff"
                name={props.ticketdata?.contact?.name}
                round
                size="35"
                textSizeRatio={2.5}
              />
            </span> */}
            <div className="ms-1">
              <IoTicketOutline size={20} color="#63686F" />
            </div>
            {ticketDescription?.length ? (
              <span className="pl-2" title={ticketDescription[0]?.value.split(' ').length > 25 ? ticketDescription[0]?.value: ''}>
              {ticketDescription[0]?.value.split(' ').length > 25
                ? `${ticketDescription[0]?.value.split(' ').slice(0, 25).join(' ')}...`
                : ticketDescription[0]?.value}
            </span>
            ) : (
              ""
            )}
          </div>

          <div className="ticket-created-body">
            <div>
              <CiCircleInfo fontSize={15} />{" "}
              {`Ticket created by ${props.ticketdata?.contact?.name} 
                on ${moment(props.ticketdata?.createdAt).format(
                  "DD MMM YYYY hh:mm a"
                )}`}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end mt-1">
            {ticketTeam?.length > 0 && (
              <div className="me-4 d-flex align-items-center" title="Team">
                <div className="func-icons-people">
                  <RiTeamFill size={14} color="#63686F" />
                </div>
                {ticketTeam[0]?.team?.name}
              </div>
            )}

            {ticketMember?.length > 0 && (
              <div className="d-flex align-items-center" title="Member">
                <div className="func-icons-people">
                  <IoPerson size={14} color="#63686F" />
                </div>
                {ticketMember[0]?.member?.name}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TicketCreatedComponent;

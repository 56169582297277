import React, { useState, useEffect } from "react";
import {
  Formik,
  Form,
  Field,
  FieldArray,
  ErrorMessage,
  useFormikContext,
} from "formik";
import Select from "react-select";
import * as Yup from "yup";
import { IoArrowBackOutline } from "react-icons/io5";
import "../main.scss";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { FaTimes, FaEdit } from "react-icons/fa";
import Modall from "../../../common/modall";
import TemplateList from "../../Template/templateList";
import { useData } from "../../../contexts/tempContext";
import { IoIosClose } from "react-icons/io";
import { LuPlus } from "react-icons/lu";
import { updateConnection, getConnection } from "../../../services/integrationService"; // Assuming getConnection is the service to fetch existing data
import { getAllMembers } from "../../../botFlows/components/designFlow/editor/actions/memberService";
import { getTags } from "../../../botFlows/services/contacts";
import { useSelector } from "react-redux";
import ChatCamTemplate from "../../Template/chatCamTemplate";

interface WorkFlowFormProps {
  id: number;
  title: string;
  description: string;
  setActiveWorkflowId: React.Dispatch<React.SetStateAction<number | null>>;
  connectionUid: string;
  channelDtoUid: string | undefined;
  integrationName: string | null;
  workflowData: any; 
  setCallDb: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Action {
  actionType: string;
  operationType: string;
  value?: string; 
  optIn?: boolean;
  tagDto?: { id: number };
  memberDto?: { uid: string };
}

interface Message {
  name?: string;
  [key: string]: any;
}

const actionOptions = [
  { value: "updateContactEmail", label: "Update Contact Email" },
  { value: "updateContactPhone", label: "Update Contact Phone" },
  { value: "updateContactName", label: "Update Contact Name" },
  { value: "updateContactOwner", label: "Update Contact Owner" },
  { value: "updateContactTags", label: "Update Contact Tags" },
  { value: "removeContactTags", label: "Remove Contact Tags" },
  {
    value: "updateContactMarketingInOpt",
    label: "Update Contact Marketing In Opt",
  },
];

const getOperationTypes = (actionType: string) => {
  switch (actionType) {
    case "updateContactEmail":
    case "updateContactPhone":
    case "updateContactName":
    case "updateContactOwner":
      return [
        { value: "replace", label: "Replace" },
        { value: "addIfEmpty", label: "Add If Empty" },
      ];
    case "updateContactTags":
      return [
        { value: "append", label: "Append" },
        { value: "addIfEmpty", label: "Add If Empty" },
        { value: "replace", label: "Replace" },
      ];
    case "removeContactTags":
      return [{ value: "remove", label: "Remove" }];
    case "updateContactMarketingInOpt":
      return [{ value: "replace", label: "Replace" }];
    default:
      return [];
  }
};

interface TemplateVar {
  componentType: string;
  position: number | null;
  ownValue: boolean;
  bodyText: string;
}

const WorkFlowForm: React.FC<WorkFlowFormProps> = (props) => {
  const { data, setData, clearData } = useData();
  const [isTemplatePick, IsTemplatePick] = useState(false);
  const [modalTempTitle, setmodalTempTitle] = useState("Integration Template");
  const [templateVar, setTemplateVar] = useState<TemplateVar[]>([]);
  const [members, setMembers] = useState<any[]>([]);
  const [tags, setTags] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const businessUid = useSelector(
    (state: any) => state.cartreducer.business?.business?.uid
  );   

  const transformActionForForm = (action:any) => {
    switch (action.actionType) {
      case "updateContactTags":
      case "removeContactTags":
        return {
          ...action,
          value: action.tagDto?.id || "",
        };
      case "updateContactOwner":
        return {
          ...action,
          value: action.memberDto?.uid || "",
        };
      default:
        return action;
    }
  };

  const initialValues = {
    message: props.workflowData?.template || ({} as Message),
    actions: props.workflowData?.actions?.map(transformActionForForm) || [
      { actionType: "", operationType: "", value: "", optIn: false },
    ],
  };

  const validationSchema = Yup.object().shape({
    message: Yup.object().shape({
      name: Yup.string().required("Message is required"),
    }),
    actions: Yup.array().of(
      Yup.object().shape({
        actionType: Yup.string().required("Action Type is required"),
        operationType: Yup.string().required("Operation Type is required"),
        value: Yup.string().when("actionType", {
          is: (actionType: string) =>
            [
              "updateContactEmail",
              "updateContactPhone",
              "updateContactName",
              "updateContactOwner",
              "updateContactTags",
              "removeContactTags",
            ].includes(actionType),
          then: Yup.string().required("Value is required").nullable(),
        }),
        optIn: Yup.boolean().when("actionType", {
          is: "updateContactMarketingInOpt",
          then: Yup.boolean().required("Opt In is required"),
        }),
      })
    ),
  });

  const templateOpen = () => {
    IsTemplatePick(true);
  };
  const templateClose = () => {
    IsTemplatePick(false);
  };

  function toCamelCase(str: string): string {
    return str.split(" ").reduce((acc, word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      } else {
        return acc + word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
    }, "");
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/\d/.test(event.key)) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      const membersData = await fetchMembers(0);
      const tagsData = await fetchTags(0);
      setMembers(membersData);
      setTags(tagsData);
    };

    fetchInitialData();
  }, [businessUid]);

  const fetchMembers = async (
    page: number = 0,
    search: string | null = null
  ) => {
    const response = await getAllMembers({
      businessUid,
      page,
      limit: 500,
      status: ["ACTIVE"],
      search,
    });
    return response?.dataDTO?.map((item: any) => ({
      label: item?.name,
      value: item?.uid,
    }));
  };

  const fetchTags = async (page: number = 0, search: string | null = null) => {
    const response = await getTags(businessUid, page, search);
    return response?.data?.tagsDto.map((tag: any) => ({
      label: tag?.name,
      value: tag?.id,
    }));
  };

  const loadOptions = async (inputValue: string, callback: any) => {
    setLoading(true);
    const newMembers = await fetchMembers(page, inputValue);
    const newTags = await fetchTags(page, inputValue);
    setMembers((prevMembers) => [...prevMembers, ...newMembers]);
    setTags((prevTags) => [...prevTags, ...newTags]);
    callback([...newMembers, ...newTags]);
    setLoading(false);
  };

  const reverseTemplateVariables = (templateVariables: any[]) => {
    return templateVariables.slice().reverse().map((variable, index) => ({
      Id: variable.position,
      value: variable.bodyText,
      Check: variable.ownValue,
      isButton: variable.componentType === "button",
    }));
  };

  // const urlIndices = data?.buttonColumns
  //   ?.map((button: any, index: number) =>
  //     button?.includes("URL1") || button?.includes("URL2") ? index : null
  //   )
  //   .filter((index: number | null) => index !== null);
  const urlIndices = data?.buttonColumns
  ?.map((button: any, index: number) => {
    if (typeof button === 'string' && button.trim() !== '') {
      return button.includes("URL1") || button.includes("URL2") ? index : null;
    } else {
      return null;
    }
  })
  .filter((index: number | null) => index !== null);

  const templateVariables = (
    tempVar: any,
    showLink: boolean,
    templateVariables: any
  ) => {
    const template: TemplateVar[] =
      templateVariables.map((variable: any, index: number) => ({
        componentType: variable?.isButton ? "button" : "body",
        position: variable?.isButton ? urlIndices?.shift() : index + 1,
        ownValue: variable.Check,
        bodyText: variable.value,
      })) || [];      
    setTemplateVar(template);
  };

  const FormikContext = () => {
    const { setFieldValue, values } = useFormikContext();

    useEffect(() => {
      if (data) {
        if (data && data.name) {
          setFieldValue("message", data);
        }
      } else {
        if (props.workflowData?.template) {
          setFieldValue("message", props.workflowData?.template);
        } else {
          setFieldValue("message", {});
        }
      }
    }, [data, setFieldValue, props.workflowData?.template]);

    return null;
  };



  const transformActionForPayload = (action:any) => {
    const baseAction = {
      actionType: action.actionType,
      operationType: action.operationType,
      value: action.value, 
      optIn: action.optIn,
    };

    switch (action.actionType) {
      case "updateContactTags":
      case "removeContactTags":
        return {
          ...baseAction,
          value: null,  
          tagDto: {
            id: action.value  
          }
        };
      case "updateContactOwner":
        return {
          ...baseAction,
          value: null,  
          memberDto: {
            uid: action.value 
          }
        };
      default:
        return baseAction;  
    }
  };

  return (
    <div className="workFlowComponent">
      <div className="workFlowForm-header">
        <div>
          <button
            className="back-btn"
            onClick={() => {
              props.setActiveWorkflowId(null);
              clearData();
            }}
          >
            <IoArrowBackOutline />
          </button>
        </div>
        <div className="p-2">
          <div
            className="cart-icon"
            style={{
              color: props.id !== 6 ? "rgb(47, 133, 90)" : "#e552b3",
              background: props.id !== 6 ? "rgb(198, 246, 213)" : "#ffe7f5",
            }}
          >
            <AiOutlineShoppingCart
              size={25}
              color={props.id !== 6 ? "rgb(47, 133, 90)" : "#e552b3"}
            />
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div>
            <div className="flow-title">{props.title}</div>
            <div className="flow-description">{props.description}</div>
          </div>
        </div>
      </div>
      <div className="workFlow-form">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={async (values, actions) => {
            const formattedActions = values.actions.map(transformActionForPayload);

            const payload = {
              workFlow: {
                workFlowType: toCamelCase(props.title),
                uid: props.workflowData?.uid || null,
                disabled: false,
                templateText: Object.keys(data).length > 0 ? templateVar : props.workflowData?.templateText,
                mediaDTO: {
                  mediaId: Object.keys(data).length > 0 ? (data?.media?.id || null) : (values.message.media?.id || null),
                },
                actions: formattedActions,
                templateId: Object.keys(data).length > 0 ? data.id : values.message.id,
              },
            };

            const response = await updateConnection(
              props.connectionUid,
              payload
            );
            props.setActiveWorkflowId(null);
            actions.resetForm();
            props.setCallDb(true);
            clearData();
          }}
        >
          {({ values, setFieldValue }) => (            
            <>
              <FormikContext />
              <Form>
                <div className="form-group">
                  <div className="form-label">
                    <label htmlFor="message" className="m-0">
                      Send Message
                        <span style={{ color: "red" }}>
                          *
                        </span>
                    </label>
                    <br />
                    {/* <small style={{ color: "red" }}>
                      {"("}Required{")"}
                    </small> */}
                  </div>
                  <div className="form-value">
                    {Object.keys(values.message).length > 0 &&
                    values.message.name ? (
                      <div className="d-flex align-items-center">
                        <div className="selected-template">
                          <span>{values.message.name}</span>
                          <button
                            type="button"
                            className="btn btn-link text-danger ms-2 p-0"
                            onClick={() => {
                              setFieldValue("message", {});
                              clearData();
                            }}
                          >
                            <FaTimes />
                          </button>
                        </div>
                        {
                          (templateVar?.length > 0 || props.workflowData?.templateText.length > 0 ) ? (
                            <div>
                          <button
                            type="button"
                            className="btn btn-link text-primary ms-2 p-0"
                            onClick={templateOpen}
                          >
                            <FaEdit />
                          </button>
                          {isTemplatePick && (
                            <Modall
                              isOpen={isTemplatePick}
                              onClose={templateClose}
                              size="xxl"
                              title={modalTempTitle}
                            >
                              {/* <TemplateList
                                component="SEQUENCE"
                                templateModal={isTemplatePick}
                                handleClose={templateClose}
                                modalTitle={setmodalTempTitle}
                                setSelectTemplateId={(templateId: string) => ""}
                                templateVariables={templateVariables}
                                setRemainderTemplateId={(templateId: string) =>
                                  ""
                                }
                              /> */}
                              <ChatCamTemplate
                                singleTempData={Object.keys(values.message).length > 0 ? values.message : props.workflowData.template}
                                handleClose={templateClose}
                                typeOfComponent={"SHIPROCKET"}
                                formValues={reverseTemplateVariables(templateVar?.length > 0 ? templateVar:props.workflowData?.templateText)}
                                setTemplateVar={setTemplateVar}
                              />
                            </Modall>
                          )}
                        </div>
                          ) : null
                        }
                        
                      </div>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="pick-template-btn"
                          onClick={templateOpen}
                        >
                          Pick Template
                          <LuPlus color="white" size={12} />
                        </button>
                        {isTemplatePick && (
                          <Modall
                            isOpen={isTemplatePick}
                            onClose={templateClose}
                            size="xxl"
                            title={modalTempTitle}
                          >
                            <TemplateList
                              component="SHIPROCKET"
                              templateModal={isTemplatePick}
                              handleClose={templateClose}
                              modalTitle={setmodalTempTitle}
                              setSelectTemplateId={(templateId: string) => ""}
                              templateVariables={templateVariables}
                              setRemainderTemplateId={(templateId: string) =>
                                ""
                              }
                            />
                          </Modall>
                        )}
                      </>
                    )}
                  </div>
                  <ErrorMessage
                    name="message.name"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <FieldArray name="actions">
                  {({ insert, remove, push }) => (
                    <>
                      <div className="form-group">
                        <div className="form-label">
                          <label htmlFor="">Action</label>
                        </div>
                        <div className="form-value">
                          {values.actions.length > 0 &&
                            values.actions.map((action: any, index: number) => (
                              <div className="nested-form" key={index}>
                                <div className="remove-action">
                                  <div
                                    onClick={() => remove(index)}
                                    className="d-flex align-items-center"
                                  >
                                    <div>
                                      <IoIosClose size={20} />
                                    </div>
                                    <div>Remove Action</div>
                                  </div>
                                </div>
                                <div className="nested-form-group">
                                  <div className="nested-form-label">
                                    <label
                                      htmlFor={`actions.${index}.actionType`}
                                    >
                                      Action Type
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                  </div>
                                  <div className="nested-form-value">
                                    <Select
                                      options={actionOptions}
                                      name={`actions.${index}.actionType`}
                                      value={actionOptions.find(
                                        (option) =>
                                          option.value === action.actionType
                                      )}
                                      onChange={(option) => {
                                        setFieldValue(
                                          `actions.${index}.actionType`,
                                          option?.value
                                        );
                                        setFieldValue(
                                          `actions.${index}.value`,
                                          ""
                                        );
                                        setFieldValue(
                                          `actions.${index}.operationType`,
                                          ""
                                        );
                                        setFieldValue(
                                          `actions.${index}.optIn`,
                                          false
                                        );
                                      }}
                                      className="action-select"
                                    />
                                    <ErrorMessage
                                      name={`actions.${index}.actionType`}
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                </div>
                                {action.actionType && (
                                  <>
                                    {(action.actionType ===
                                      "updateContactEmail" ||
                                      action.actionType ===
                                        "updateContactPhone" ||
                                      action.actionType ===
                                        "updateContactName") && (
                                      <div className="nested-form-group">
                                        <div className="nested-form-label">
                                          <label
                                            htmlFor={`actions.${index}.value`}
                                          >
                                            {action.actionType ===
                                              "updateContactEmail" &&
                                              "CONTACT EMAIL"}
                                            {action.actionType ===
                                              "updateContactPhone" &&
                                              "CONTACT PHONE"}
                                            {action.actionType ===
                                              "updateContactName" &&
                                              "CONTACT NAME"}
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </label>
                                        </div>
                                        <div className="nested-form-value">
                                          <Field
                                            name={`actions.${index}.value`}
                                            className="form-control"
                                            onKeyPress={
                                              action.actionType ===
                                              "updateContactPhone"
                                                ? handleKeyPress
                                                : undefined
                                            }
                                          />
                                          <ErrorMessage
                                            name={`actions.${index}.value`}
                                            component="div"
                                            className="text-danger"
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {action.actionType ===
                                      "updateContactMarketingInOpt" && (
                                      <div className="nested-form-group">
                                        <div className="nested-form-label">
                                          <label
                                            htmlFor={`actions.${index}.optIn`}
                                          >
                                            CONTACT MARKETING OPTIN
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </label>
                                        </div>
                                        <div
                                          className="nested-form-value"
                                          style={{ marginLeft: "1.5rem" }}
                                        >
                                          <Field
                                            type="checkbox"
                                            name={`actions.${index}.optIn`}
                                            className="form-check-input"
                                          />
                                          <ErrorMessage
                                            name={`actions.${index}.optIn`}
                                            component="div"
                                            className="text-danger"
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {(action.actionType ===
                                      "updateContactOwner" ||
                                      action.actionType ===
                                        "updateContactTags" ||
                                      action.actionType ===
                                        "removeContactTags") && (
                                      <div className="nested-form-group">
                                        <div className="nested-form-label">
                                          <label
                                            htmlFor={`actions.${index}.value`}
                                          >
                                            {action.actionType ===
                                              "updateContactOwner" &&
                                              "CONTACT OWNER"}
                                            {action.actionType ===
                                              "updateContactTags" &&
                                              "UPDATE CONTACT TAGS"}
                                            {action.actionType ===
                                              "removeContactTags" &&
                                              "REMOVE CONTACT TAGS"}
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </label>
                                        </div>
                                        <div className="nested-form-value">
                                          <Select
                                            options={
                                              action.actionType ===
                                              "updateContactOwner"
                                                ? members
                                                : tags
                                            }
                                            name={`actions.${index}.value`}
                                            value={(action.actionType ===
                                            "updateContactOwner"
                                              ? members
                                              : tags
                                            ).find(
                                              (option: any) =>
                                                option.value == action.value
                                            )}
                                            onChange={(option: any) =>
                                              setFieldValue(
                                                `actions.${index}.value`,
                                                option?.value
                                              )
                                            }
                                            onMenuScrollToBottom={() => {
                                              setPage(
                                                (prevPage) => prevPage + 1
                                              );
                                              loadOptions("", () => {});
                                            }}
                                            className="action-select"
                                          />
                                          <ErrorMessage
                                            name={`actions.${index}.value`}
                                            component="div"
                                            className="text-danger"
                                          />
                                        </div>
                                      </div>
                                    )}
                                    <div className="nested-form-group">
                                      <div className="nested-form-label">
                                        <label
                                          htmlFor={`actions.${index}.operationType`}
                                        >
                                          Operation Type
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                      </div>
                                      <div className="nested-form-value">
                                        {getOperationTypes(
                                          action.actionType
                                        ).map((opType, i) => (
                                          <div key={i} className="form-check">
                                            <Field
                                              type="radio"
                                              name={`actions.${index}.operationType`}
                                              value={opType.value}
                                              className="form-check-input"
                                            />
                                            <label className="form-check-label">
                                              {opType.label}
                                            </label>
                                          </div>
                                        ))}
                                        <ErrorMessage
                                          name={`actions.${index}.operationType`}
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            ))}

                          {values.actions.length === 0 && (
                            <div className="form-value">
                              <div className="addAction">
                                <div
                                  className="add-action"
                                  onClick={() =>
                                    push({
                                      actionType: "",
                                      operationType: "",
                                      value: "",
                                      optIn: false,
                                    })
                                  }
                                >
                                  Add Action
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {values.actions.length > 0 && (
                        <div className="form-group">
                          <div className="form-label"></div>
                          <div className="form-value">
                            <div className="addAction">
                              <div
                                className="add-action"
                                onClick={() =>
                                  push({
                                    actionType: "",
                                    operationType: "",
                                    value: "",
                                    optIn: false,
                                  })
                                }
                              >
                                Add Another Action
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </FieldArray>
                <div className="d-flex justify-content-center mt-3">
                  <button
                    className="cancel-btn"
                    onClick={() => {
                      props.setActiveWorkflowId(null);
                      setFieldValue("message", {});
                      clearData();
                    }}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default WorkFlowForm;

